import { Component, Input } from '@angular/core';
import { DocumentFieldNote, DocumentSectionType } from 'src/app/documents/shared';
import { DocumentSelectOptionType } from 'src/app/documents/shared/document-structure/document-select-option-type';

@Component({
  selector: 'app-multiselect-readonly-view',
  templateUrl: './multiselect-readonly-view.component.html',
  styleUrls: ['./multiselect-readonly-view.component.scss'],
})
export class MultiselectReadonlyViewComponent {
  @Input()
  title: string;

  @Input()
  values: StringMap<DocumentSelectOptionType>;

  @Input()
  selectedKeys: string[];

  @Input()
  sectionType: DocumentSectionType;

  @Input()
  documentId: string;

  @Input()
  notes: StringMap<DocumentFieldNote>;

  @Input()
  sectionFieldId: string;

  constructor() {}
}
