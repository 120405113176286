import { Component, Inject } from '@angular/core';
import { UserDetails } from '../shared/user-details';
import { validate, required, Assert } from 'src/app/shared';
import { UserWithPass } from '../shared/user-with-pass';
import { AuthDetails } from 'src/app/authentication/shared';
import { UserRole } from 'src/app/authentication/shared/user-role';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditUserDialogData } from '../shared/edit-user-dialog-data';
import { UsersType } from 'src/app/authentication/shared/users-type';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss'],
})
export class EditUserModalComponent {
  organizationId: string;
  currentUserAuthDetails: AuthDetails;
  userDetails: UserDetails;
  isEditMode: boolean;
  editedUserDetails: UserDetails;
  currentUserRole: UserRole;
  usersType: UsersType;

  get UsersType() {
    return UsersType;
  }

  constructor(
    private readonly snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public editUserDialogData: EditUserDialogData
  ) {
    this.userDetails = null;
    this.organizationId = editUserDialogData.organizationId;
    this.currentUserAuthDetails = editUserDialogData.currentUserAuthDetails;
    this.isEditMode = editUserDialogData.isEditMode;
    this.editedUserDetails = editUserDialogData.editedUserDetails;
    this.usersType = editUserDialogData.usersType;
  }

  @validate
  onUserCreated(@required userWithPass: UserWithPass) {
    this.userDetails = userWithPass.userDetails;
    this.showSnackBar(`Användare ${this.userDetails.user.firstName} ${this.userDetails.user.lastName} har skapats.`);
    this.onClose();
  }

  @validate
  onUserEdited(@required userDetails: UserDetails) {
    this.userDetails = userDetails;
    this.showSnackBar(`Användare ${this.userDetails.user.firstName} ${this.userDetails.user.lastName} har uppdaterats.`);
    this.onClose();
  }

  onClose() {
    Assert.isNotNull(this.userDetails, 'userDetails');
    this.dialogRef.close(this.userDetails);
  }

  onCancel() {
    this.userDetails = null;
    this.dialogRef.close(null);
  }

  private showSnackBar(@required message: string): void {
    this.snackBar.open(message, 'OK');
  }
}
