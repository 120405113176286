<div class="project-navigation d-flex flex-column pt-1" [ngClass]="{'is-closed': !(isLeftMenuOpened$ | async)}">
  <div *ngIf="isLeftMenuOpened$ | async" class="text-right">
    <button (click)="onMenuClick()" mat-button mat-icon-button title="Dölj navigering">
      <mat-icon>keyboard_double_arrow_left</mat-icon>
    </button>
  </div>
  <div *ngIf="!(isLeftMenuOpened$ | async)" class="text-center">
    <button (click)="onMenuClick()" mat-button mat-icon-button title="Visa navigering">
      <mat-icon>keyboard_double_arrow_right</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="isLeftMenuOpened$ | async">
    <ng-container *ngFor="let menu of menus; trackBy: trackByItem">
      <button
        type="button"
        class="btn btn-tab"
        [class.complete]="menu.isComplete"
        [routerLink]="getMenuRoute(menu.id)"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span class="btn-content">{{ menu.displayName }}</span>
      </button>
    </ng-container>
  </ng-container>
  <div class="flex-grow-1"></div>
</div>
