import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: string | Date | moment.Moment, ...args: unknown[]): string {
    const valueDate = moment.utc(value).toDate();
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - valueDate.getTime()) / 1000));
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));

    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 60) {
      return 'för mindre en minut sedan';
    } else if (minutes <= 45) {
      return `${minutes} minuter sedan`;
    } else if (minutes <= 90) {
      return 'en timme sedan';
    } else if (hours <= 22) {
      return `${hours} timmar sedan`;
    } else if (hours <= 36) {
      return 'i går';
    } else {
      return valueDate.toDateString();
    }
  }
}
