import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../core/api';
import { workerConfig } from './worker.config';
import { WorkerState } from './worker-state';
import { WorkerName } from './worker-name';

@Injectable({
  providedIn: 'root'
})
export class WorkerApiService {
  private readonly http: HttpClient;
  private readonly apiService: ApiService;

  constructor(http: HttpClient, apiService: ApiService) {
    this.http = http;
    this.apiService = apiService;
  }

  async getState(name: WorkerName): Promise<WorkerState> {
    const url = `api/${workerConfig.api.worker.url}/${name}`;
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    let response: any;
    try {
      response = await this.http.get<WorkerState>(noCacheUrl).toPromise();
      return response;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }
}
