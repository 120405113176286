import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentsInspectionConditionsService } from 'src/app/documents/documents-Inspection-conditions.service';
import { DocumentsValidationService } from 'src/app/documents/documents-validation.service';
import { DocumentsService } from 'src/app/documents/documents.service';
import { Document, DocumentInspectionConditions, DocumentSectionType } from 'src/app/documents/shared';
import { DocumentInspection } from 'src/app/documents/shared/document/document-inspection';
import { DocumentTabContentComponent } from '../project-tab-container/project-tab-container.component';
import { projectConfig } from '../project.config';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { coreConfig } from '../../core';

@Component({
  selector: 'app-project-tab-inspection',
  templateUrl: './project-tab-inspection.component.html',
  styleUrls: ['./project-tab-inspection.component.scss'],
})
export class ProjectTabInspectionComponent extends DocumentTabContentComponent implements OnInit {
  @ViewChild('tabForm')
  tabForm: NgForm;

  conditions: DocumentInspectionConditions;

  get previousPageName(): string {
    return projectConfig.routes.protectedObjects;
  }

  get nextPageName(): string {
    return null;
  }

  private setTimeoutHandler: NodeJS.Timeout;

  constructor(
    dialog: MatDialog,
    snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private documentsValidationService: DocumentsValidationService,
    private documentsInspectionConditionsService: DocumentsInspectionConditionsService,
  ) {
    super(DocumentSectionType.inspection, dialog, snackBar);

    this.conditions = new DocumentInspectionConditions();
  }

  ngOnInit(): void {}

  setDocument(document: Document): void {
    this.document = document;
    this.updateInspectionConditions();
  }

  async onCompleteForm(isCompleted: boolean): Promise<void> {
    this.document.inspection.isCompleted = isCompleted && this.document.inspection.isValid;
    await this.saveFormData(true);
  }

  onFormDataChanged(): void {
    clearTimeout(this.setTimeoutHandler);
    this.setTimeoutHandler = setTimeout(() => this.saveFormData(false), coreConfig.forms.saveTimeoutDelay);
  }

  private updateInspectionConditions(): void {
    this.conditions = this.documentsInspectionConditionsService.getDocumentInspectionConditions(this.document);
  }

  private async saveFormData(formCompletedExplicitly: boolean): Promise<void> {
    if (!this.hasEditPermissions) {
      return;
    }

    const isCompletedBeforeChange = this.document.inspection.isCompleted;
    this.document.inspection.isValid = this.documentsValidationService.isInspectionValid(this.document.inspection, this.document);
    this.document.inspection.isCompleted = this.document.inspection.isCompleted && this.document.inspection.isValid;
    const partialDocument = {
      inspection: new DocumentInspection({ ...this.document.inspection }),
    } as Document;

    try {
      await this.documentsService.patchDocumentAndSave(this.document, partialDocument);
    } catch (ex) {
      this.showErrorModal('Ett fel uppstod när ändringarna skulle sparas.', ex);
      return;
    }

    if (formCompletedExplicitly || (isCompletedBeforeChange && !this.document.inspection.isCompleted)) {
      this.showSectionCompletedSnackBar(DocumentSectionType.inspection, this.document.inspection.isCompleted);
    }
  }
}
