import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Assert } from 'src/app/shared';
import { map } from 'lodash';

export const uniqueValidationKey = 'unique';

export function uniqueValidator(values: string[], isIgnoreCase: boolean = true): ValidatorFn {
  Assert.isNotNull(values, 'values');
  Assert.isNotNull(isIgnoreCase, 'isIgnoreCase');

  const existingValues = isIgnoreCase ? map(values, (value) => value.toLowerCase()) : values;

  const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    Assert.isNotNull(control, 'control');

    const currentValue = isIgnoreCase && control.value ? control.value.toLowerCase() : control.value;
    const isValid = existingValues.indexOf(currentValue) === -1;
    return !isValid ? { [uniqueValidationKey]: true } : null;
  };

  return validator;
}
