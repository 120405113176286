import { v4 as uuid } from 'uuid';

export class OrganizationActivate {
  readonly organizationId: uuid;
  readonly activateWithoutUser;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly password: string;

  constructor({ organizationId, activateWithoutUser, firstName, lastName, email, password }: Partial<OrganizationActivate> = {}) {
    this.organizationId = organizationId;
    this.activateWithoutUser = activateWithoutUser;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.password = password;
  }
}
