import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { requireLowerCaseLetterValidator } from './require-lower-case-letter.validator';
import { required, validate } from 'src/app/shared';

@Directive({
  selector: '[appRequireLowerCaseLetter]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RequireLowerCaseLetterValidatorDirective, multi: true }],
})
export class RequireLowerCaseLetterValidatorDirective {
  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    const validator = requireLowerCaseLetterValidator();

    const result = validator(control);

    return result;
  }
}
