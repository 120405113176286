import { AuthDetails } from 'src/app/authentication/shared';
import { UsersType } from 'src/app/authentication/shared/users-type';
import { UserDetails } from '.';

export class EditUserDialogData {
  organizationId: string;
  currentUserAuthDetails: AuthDetails;
  userDetails: UserDetails;
  isEditMode: boolean;
  editedUserDetails: UserDetails | null;
  usersType: UsersType;

  constructor({
    organizationId,
    currentUserAuthDetails,
    userDetails,
    isEditMode,
    editedUserDetails,
    usersType,
  }: Partial<EditUserDialogData> = {}) {
    this.organizationId = organizationId;
    this.currentUserAuthDetails = currentUserAuthDetails;
    this.userDetails = userDetails;
    this.isEditMode = isEditMode;
    this.editedUserDetails = editedUserDetails || null;
    this.usersType = usersType;
  }
}
