import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsStoreModule } from './documents-store';
// tslint:disable-next-line:max-line-length
import { DeleteDocumentConfirmationModalComponent } from './delete-document-confirmation-modal/delete-document-confirmation-modal.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DocumentFieldNotesStoreModule } from './documents-notes-store';

@NgModule({
  declarations: [DeleteDocumentConfirmationModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    DocumentsStoreModule,
    DocumentFieldNotesStoreModule,
  ],
  exports: [DeleteDocumentConfirmationModalComponent]
})
export class DocumentsModule {}
