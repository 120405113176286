<div mat-dialog-content style="width: 900px">
  <form #createUserForm="ngForm" novalidate (keydown.enter)="onSaveUser()">
    <div class="modal-body pl-0 pt-1">
      <section class="w-100" *ngIf="state.isLoading">
        <p *ngIf="!isEditMode" class="font-weight-bold mb-4">Skapar användare ...</p>
        <p *ngIf="isEditMode" class="font-weight-bold mb-4">Redigerar användare ...</p>
        <div class="text-center m-4 text-muted text-extra-large">
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
      </section>

      <section *ngIf="!state.isLoading">
        <div class="row">
          <div class="col pr-1">
            <mat-form-field class="w-100">
              <mat-label>Förnamn</mat-label>
              <input
                matInput
                required
                [disabled]="state.isLoading"
                id="firstName"
                [(ngModel)]="userDetails.user.firstName"
                #firstName="ngModel"
                name="firstName"
                [appAllowedCharacters]="nameAllowedCharacters"
                [maxlength]="nameMaxLength"
                [ngClass]="{ 'is-invalid': firstName.dirty && firstName.invalid }"
                placeholder="Förnamn"
              />
              <mat-error *ngIf="firstName.errors">
                <div *ngIf="!!firstName.errors?.required">Ange ett förnamn</div>
                <div *ngIf="!!firstName.errors?.maxlength">Får inte vara mer än {{ nameMaxLength }} tecken.</div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col pl-1">
            <mat-form-field class="w-100">
              <mat-label>Efternamn</mat-label>
              <input
                matInput
                required
                [disabled]="state.isLoading"
                id="lastName"
                [(ngModel)]="userDetails.user.lastName"
                #lastName="ngModel"
                name="lastName"
                [appAllowedCharacters]="nameAllowedCharacters"
                [maxlength]="nameMaxLength"
                [ngClass]="{ 'is-invalid': lastName.dirty && lastName.invalid }"
                placeholder="Efternamn"
              />
              <mat-error *ngIf="lastName.errors">
                <div *ngIf="!!lastName.errors?.required">Ange ett efternamn</div>
                <div *ngIf="!!lastName.errors?.maxlength">Får inte vara mer än {{ nameMaxLength }} tecken.</div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <mat-form-field class="w-100">
          <mat-label>E-postadress</mat-label>
          <input
            matInput
            required
            [disabled]="state.isLoading"
            [(ngModel)]="userDetails.user.email"
            (change)="onEmailChanged()"
            #email="ngModel"
            name="email"
            [maxlength]="emailMaxLength"
            [class.is-invalid]="(email.dirty || email.touched) && email.invalid"
            placeholder="E-postadress"
            appEmailWithSwedishCharacters
          />
          <mat-error>
            <div *ngIf="!!email.errors?.required">Ange en e-postadress</div>
            <div *ngIf="!!email.errors?.maxlength">Får inte vara mer än {{ emailMaxLength }} tecken.</div>
            <div *ngIf="!!email.errors?.emailWithSwedishCharacters">E-postadress är ogiltig</div>
            <div *ngIf="!!email.errors?.emailExists">E-postadressen används redan<ng-container *ngIf="hasSuperAdminPermissions"> hos {{ state.uniqueEmail.organizationName }}</ng-container></div>
          </mat-error>
        </mat-form-field>
        <div class="alert alert-primary d-none" role="alert">Ett autogenererat lösenord skickas till användaren.</div>
      </section>

      <section *ngIf="!state.isLoading" class="row">
        <mat-form-field appearance="fill" class="col-6">
          <mat-label>Roll</mat-label>
          <mat-select name="usersRole" [(ngModel)]="userDetails.role" required>
            <mat-option *ngFor="let role of availableRoles" [value]="role.value">
              {{ role.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6" *ngIf="userDetails.role && userDetails.role === UserRole.customerReader">
          <mat-label>Läsarroller</mat-label>
          <mat-select multiple name="userPermissions" [(ngModel)]="userDetails.permissionsIds">
            <mat-option *ngFor="let permission of availablePermissions" [value]="permission.id">{{
              permission.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="showCheckboxCanReviewDocument" class="col-6 pl-2 pt-3">
          <mat-checkbox [(ngModel)]="userDetails.user.canReviewDocument" name="canReviewDocument">Godkänd som granskare</mat-checkbox>
        </div>
      </section>
      <hr />
    </div>
  </form>
</div>

<div mat-dialog-actions class="float-right">
  <button mat-raised-button (click)="onCancel()">Avbryt</button>
  <button
    class="save-btn"
    mat-raised-button
    (click)="onSaveUser()"
    cdkFocusInitial
    [disabled]="!canSubmitForm"
  >
    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="state.isLoading"></i>
    <ng-container *ngIf="!isEditMode">Skapa användare</ng-container>
    <ng-container *ngIf="isEditMode">Spara</ng-container>
  </button>
</div>
