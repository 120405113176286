<div class="d-flex justify-content-between mb-2">
  <h5 class="mt-1">Användare</h5>
  <div class="d-flex justify-content-end">
    <div *ngIf="hasSuperAdminPermissions" class="mr-3 pt-2">
      <mat-checkbox
        (change)="onChangeShowInactivateOrganizations($event.checked)"
      >
        Visa inaktiva kunder
      </mat-checkbox>
    </div>
    <div class="mr-4">
      <input
        type="text"
        value=""
        (input)="onFilterChanged($event.target.value)"
        class="form-control"
        placeholder="Sök"
      />
    </div>
    <div>
      <button *ngIf="hasSuperAdminPermissions" mat-flat-button class="button-border manage-btn mr-2" (click)="onCreateOrganization()">
        Aktivera ny kund
      </button>
      <button *ngIf="hasSuperAdminPermissions" mat-flat-button class="button-border manage-btn" (click)="onCreateNewInternalUser()">
        Skapa ny intern användare
      </button>
    </div>
    <div>
      <mat-paginator #paginator [length]="itemsCount" [pageIndex]="currentPage" [pageSize]="pageSize" [hidePageSize]="true" (page)="onPageChanged($event.pageIndex)">
      </mat-paginator>
    </div>
  </div>
</div>
