import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { required, validate } from 'src/app/shared';
import { DocumentTabContentComponent } from '../project-tab-container/project-tab-container.component';
import { Document, DocumentObject, DocumentSectionType, Renovation } from 'src/app/documents/shared';
import { projectConfig } from '../project.config';
import { filter, forEach, isNil, orderBy } from 'lodash';
import { DocumentsService } from 'src/app/documents/documents.service';
import { DocumentsValidationService } from 'src/app/documents/documents-validation.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentSectionCompletionUpdateService } from '../document-section-completion-update.service';

@Component({
  selector: 'app-project-tab-object',
  templateUrl: './project-tab-object.component.html',
  styleUrls: ['./project-tab-object.component.scss'],
})
export class ProjectTabObjectComponent extends DocumentTabContentComponent implements AfterViewInit {
  @ViewChild('tabForm')
  tabForm: NgForm;

  data: {
    renovationsYear: number;
    renovationsRemedy: string;
  };

  get previousPageName(): string {
    return projectConfig.routes.info;
  }

  get nextPageName(): string {
    return projectConfig.routes.building;
  }

  get minRenovationYearValue(): number {
    return this.document.object.constructionYear || projectConfig.object.renovationYear.minValue;
  }

  get maxRenovationYearValue(): number {
    return this.currentYear;
  }

  get maxConstructionYearValue(): number {
    return this.currentYear;
  }

  get renovationYears(): number[] | null {
    const renovations = this.document.object.renovations;
    if (!renovations.length)
      return null;

    return renovations.map(x => x.year);
  }

  private readonly currentYear: number;

  constructor(
    dialog: MatDialog,
    snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private documentsValidationService: DocumentsValidationService,
    private documentSectionCompletionUpdateService: DocumentSectionCompletionUpdateService,
  ) {
    super(DocumentSectionType.object, dialog, snackBar);

    this.data = {
      renovationsYear: null,
      renovationsRemedy: null,
    };

    this.currentYear = new Date().getFullYear();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.markComponentsAsTouched(), 0);
  }

  setDocument(document: Document): void {
    this.document = document;
  }

  async onCompleteForm(isCompleted: boolean): Promise<void> {
    this.document.object.isCompleted = isCompleted && this.document.object.isValid;
    await this.saveDocumentObject(this.document.object, true);
  }

  onFormDataChanged(): void {
    this.saveDocumentObject(this.document.object, false);
  }

  onAddRenovation(event: Event): void {
    event.preventDefault();

    const renovation = new Renovation({
      year: this.data.renovationsYear,
      remedy: this.data.renovationsRemedy,
    });

    const renovations = [...this.document.object.renovations, renovation];
    const orderedRenovations = orderBy(renovations, (x) => x.year);

    const object = new DocumentObject({
      ...this.document.object,
      renovations: orderedRenovations,
    });

    this.saveDocumentObject(object, false);

    this.data.renovationsYear = null;
    this.data.renovationsRemedy = null;
  }

  @validate
  onDeleteRenovation(@required renovation: Renovation): void {
    if (!this.document) {
      return;
    }

    const renovations = filter(this.document.object.renovations, (item) => item !== renovation);
    const object = new DocumentObject({
      ...this.document.object,
      renovations: renovations,
    });

    this.saveDocumentObject(object, false);
  }

  private markComponentsAsTouched(): void {
    forEach(this.tabForm.controls, (control: AbstractControl) => {
      if (control && !isNil(control.value)) {
        control.markAsTouched();
      }
    });
  }

  private async saveDocumentObject(object: DocumentObject, formCompletedExplicitly: boolean): Promise<void> {
    if (!this.hasEditPermissions) {
      return;
    }

    const isCompletedBeforeChange = object.isCompleted;
    object.isValid = this.documentsValidationService.isObjectValid(object, this.document);
    object.isCompleted = object.isCompleted && object.isValid;
    const partialDocument = {
      object: object,
    } as Document;

    this.documentSectionCompletionUpdateService.checkAndUpdate(this.document, DocumentSectionType.object);
    try {
      await this.documentsService.patchDocumentAndSave(this.document, partialDocument);
    } catch (ex) {
      this.showErrorModal('Ett fel uppstod när ändringarna skulle sparas.', ex);
      return;
    }

    if (formCompletedExplicitly || (isCompletedBeforeChange && !this.document.object.isCompleted)) {
      this.showSectionCompletedSnackBar(DocumentSectionType.object, this.document.object.isCompleted);
    }
  }
}
