import { validate, required, Assert, MathUtils, StringUtils } from '../../shared';
import { PasswordSettings } from './password-settings';
import { flatten, map, shuffle } from 'lodash';

export class PasswordUtils {
  @validate
  static generateRandomPassword(@required settings: PasswordSettings): string {
    Assert.isTrue(settings.minLength > 0, 'settings.minLength less or equal zero');
    Assert.isTrue(settings.maxLength >= settings.minLength, 'maxLength less minLength');
    Assert.isNotNull(settings.characters, 'settings.characters');
    Assert.isTrue(settings.characters.lowercase.length > 0, 'settings.characters.lowercase.length');
    Assert.isTrue(settings.characters.uppercase.length > 0, 'settings.characters.uppercase.length');
    Assert.isTrue(settings.characters.numbers.length > 0, 'settings.characters.numbers.length');

    const minLen = Math.ceil(settings.minLength);
    const maxLen = Math.floor(settings.maxLength);

    let length = maxLen;
    if (minLen !== maxLen) {
      length = MathUtils.getRandomIntRange(minLen, maxLen);
    }

    const charsetArray = [settings.characters.lowercase, settings.characters.uppercase, settings.characters.numbers];
    Assert.isTrue(settings.minLength > charsetArray.length, 'minLength cannot be less than rules count');

    const fullCharset = flatten(charsetArray).join('');

    const singleSimbolsArray = map(charsetArray, (collection: string) => {
      return StringUtils.getRandomString(collection, 1);
    });

    let partialPasswordArray: string[] = [];
    if (length - singleSimbolsArray.length > 0) {
      partialPasswordArray = StringUtils.getRandomString(fullCharset, length - singleSimbolsArray.length).split('');
    }

    const prePasswordArray = singleSimbolsArray.concat(partialPasswordArray);

    const password = shuffle(prePasswordArray).join('');

    return password;
  }
}
