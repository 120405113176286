import { IDocumentItem } from './document';

export class DocumentProtectedObjects implements IDocumentItem {
  worthyProtectedObjectTypeIds: string[];
  protectedObjectsDescription?: string;

  isCompleted: boolean;
  isValid: boolean;

  constructor({
    worthyProtectedObjectTypeIds,
    protectedObjectsDescription,
    isCompleted,
    isValid,
  }: Partial<DocumentProtectedObjects> = {}) {
    this.worthyProtectedObjectTypeIds = worthyProtectedObjectTypeIds || [];
    this.protectedObjectsDescription = protectedObjectsDescription;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
