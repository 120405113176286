import { DocumentFieldNote, DocumentSectionType } from 'src/app/documents/shared';

export class DocumentSectionNote {
  readonly sectionType: DocumentSectionType;
  readonly sectionFieldId: string;
  readonly notes: StringMap<DocumentFieldNote>;
  readonly isViewOnly: boolean;

  constructor({ sectionType, sectionFieldId, notes, isViewOnly }: Partial<DocumentSectionNote> = {}) {
    this.sectionType = sectionType;
    this.sectionFieldId = sectionFieldId;
    this.notes = notes;
    this.isViewOnly = isViewOnly;
  }
}
