<h1 mat-dialog-title>Välj en kund</h1>
<mat-dialog-content class="mat-typography h-100">
  <div class="overlay-loader h-100 w-100" *ngIf="state.isLoading"></div>
  <form #selectOrganizationForm="ngForm" novalidate (keydown.enter)="onSelect()">
    <mat-form-field class="w-100 d-block">
      <mat-label>Välj en kund</mat-label>
      <input
        type="text"
        placeholder="Välj en kund."
        matInput
        required
        [matAutocomplete]="auto"
        name="organizationId"
        [disabled]="state.isLoading"
        [(ngModel)]="organizationName"
        (ngModelChange)="filterOrganizations()"
        #organizationId="ngModel"
        [ngClass]="{ 'is-invalid': organizationId.dirty && organizationId.invalid }"
      >
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="organizationDisplayFn"
      >
        <mat-option
          *ngFor="let organization of filteredOrganizations"
          [value]="organization"
          (onSelectionChange)="onOrganizationSelectionChange($event)"
        >
          {{ organization.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-raised-button color="warn" (click)="onSelect()" [disabled]="!canSubmitForm">Välj</button>
</mat-dialog-actions>
