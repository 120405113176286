import { UserRole } from 'src/app/authentication/shared/user-role';
import { User } from './user';
import { UserStats } from './user-stats';

export class UserDetails {
  user: User;
  role: UserRole;
  stats: UserStats;
  permissionsIds: string[];

  constructor({ user, role, stats, permissionsIds }: Partial<UserDetails> = {}) {
    this.user = user || new User();
    this.role = role;
    this.stats = stats || new UserStats();
    this.permissionsIds = permissionsIds || [];
  }
}
