import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Assert } from 'src/app/shared';

export const requireLowerCaseLetterValidationKey = 'requireLowerCaseLetter';

export function requireLowerCaseLetterValidator(): ValidatorFn {
  const validationPattern = new RegExp('(?=.*[a-z])');

  const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    Assert.isNotNull(control, 'control');

    const isValid = validationPattern.test(control.value);
    return !isValid ? { [requireLowerCaseLetterValidationKey]: true } : null;
  };

  return validator;
}
