import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { required, validate } from 'src/app/shared';
import { Organization } from '../shared';

@Component({
  selector: 'app-update-organization-modal',
  templateUrl: './update-organization-modal.component.html',
  styleUrls: ['./update-organization-modal.component.scss'],
})
export class UpdateOrganizationModalComponent {
  constructor(
    public dialogRef: MatDialogRef<UpdateOrganizationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public organizationId: string
  ) {}

  onCanceled() {
    this.dialogRef.close(null);
  }

  @validate
  onUpdated(@required organization: Organization) {
    this.dialogRef.close(organization);
  }
}
