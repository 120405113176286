<section class="d-flex flex-column w-100">
  <app-top-menu></app-top-menu>

  <div class="d-flex">
    <app-project-navigation [documentId]="documentId"></app-project-navigation>

    <div class="main-container-parent w-100 mt-3">
      <div class="container-fluid main-container">
        <div class="container-fluid tab-container mt-2">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</section>
