<h1 mat-dialog-title>Något gick fel</h1>
<div mat-dialog-content>
  <div class="modal-body">
    <div>
      {{ errorModalDialogData.title }}
    </div>
    <div *ngIf="errorModalDialogData.details" class="text-small">
      {{ errorModalDialogData.details }}
    </div>
  </div>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-raised-button (click)="onClose()">OK</button>
</div>
