import { v4 as uuid } from 'uuid';
import { UserRole } from './user-role';

export interface IAuthDetails {
  accessToken: string;
  role: UserRole;
  userName: string;
  userId: string;
  organizationId: uuid;
  expiresInMinutes: number;
  expireDate: Date;
}

export class AuthDetails {
  readonly accessToken: string;
  readonly role: UserRole;
  readonly username: string;
  readonly userid: string;
  readonly organizationId: uuid;
  readonly expiresInMinutes: number;
  readonly expireDate: Date;

  constructor({
    accessToken,
    role,
    username,
    userid,
    organizationId,
    expiresInMinutes,
    expireDate,
  }: Partial<AuthDetails> = {}) {
    this.accessToken = accessToken || null;
    this.role = role || null;
    this.username = username || null;
    this.userid = userid || null;
    this.organizationId = organizationId || null;
    this.expiresInMinutes = expiresInMinutes || 0;
    this.expireDate = expireDate;
  }
}
