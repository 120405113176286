import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { UserDetails } from '../users-management/shared';
import { Document } from 'src/app/documents/shared';

@Injectable({
  providedIn: 'root',
})
export class OrganizationUsersManagementService {
  public createOrganization$: Subject<boolean>;
  public createInternalUser$: Subject<boolean>;

  constructor() {
    this.createOrganization$ = new Subject();
    this.createInternalUser$ = new Subject();
  }

  createOrganization() {
    this.createOrganization$.next();
  }

  createInternalUser() {
    this.createInternalUser$.next();
  }

  filterUsers(
    items: UserDetails[],
    search: string,
    showOnlySelectedUsers: boolean,
    selectedUsersIds: string[]
  ): UserDetails[] {
    const searchText = search.toLowerCase();
    let filteredItems = items;
    if (search) {
      filteredItems = _.filter(
        filteredItems,
        (item) =>
          item.user.firstName.toLowerCase().includes(searchText) ||
          item.user.lastName.toLowerCase().includes(searchText) ||
          item.user.email.toLowerCase().includes(searchText)
      );
    }
    if (!showOnlySelectedUsers) {
      return filteredItems;
    }
    filteredItems = _.filter(
      filteredItems,
      (item) => !_.isEmpty(_.find(selectedUsersIds, (userId) => userId === item.user.id))
    );

    return filteredItems;
  }

  filterDocuments(
    items: Document[],
    search: string,
    showOnlySelectedDocumets: boolean,
    selectedDocumentsIds: string[]
  ): Document[] {
    const searchText = search.toLowerCase();
    let filteredItems = items;
    if (search) {
      filteredItems = _.filter(
        filteredItems,
        (item) => item.info.buildingName.toLowerCase().includes(searchText)
      );
    }
    if (!showOnlySelectedDocumets) {
      return filteredItems;
    }
    filteredItems = _.filter(
      filteredItems,
      (item) => !_.isEmpty(_.find(selectedDocumentsIds, (documentId) => documentId === item.id))
    );
    selectedDocumentsIds;

    return filteredItems;
  }
}
