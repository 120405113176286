import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, OnInit } from '@angular/core';
import { Organization } from '../shared';
import { OrganizationApiService } from '../organization-api.service';
import { LoggerService } from 'src/app/core';
import { required, validate } from 'src/app/shared';
import { ErrorModalComponent } from 'src/app/core/error-modal/error-modal.component';
import { organizationConfig } from '../organization.config';
import { ErrorModalDialogData } from 'src/app/core/shared/error-modal-dialog-data';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-update-organization-form',
  templateUrl: './update-organization-form.component.html',
  styleUrls: ['./update-organization-form.component.scss'],
})
export class UpdateOrganizationFormComponent implements OnInit, OnChanges {
  private readonly organizationsApiService: OrganizationApiService;
  private readonly dialog: MatDialog;
  private readonly loggerService: LoggerService;

  name: string;

  @Input()
  organizationId: string;

  @Output()
  readonly updated: EventEmitter<Organization>;

  @Output()
  readonly canceled: EventEmitter<void>;

  state: {
    isLoading: boolean;
    isFailed: boolean;
    isUpdating: boolean;
  };

  get nameMaxLength() {
    return organizationConfig.create.validation.name.maxLength;
  }

  get nameAllowedCharacters() {
    return organizationConfig.create.validation.name.allowedCharacters;
  }

  constructor(organizationsApiService: OrganizationApiService, dialog: MatDialog, loggerService: LoggerService) {
    this.organizationsApiService = organizationsApiService;
    this.dialog = dialog;
    this.loggerService = loggerService;

    this.updated = new EventEmitter();
    this.canceled = new EventEmitter();

    this.state = {
      isLoading: false,
      isFailed: false,
      isUpdating: false,
    };
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<UpdateOrganizationFormComponent, SimpleChange>;
    const organizationIdChange = componentChanges.organizationId;
    if (!organizationIdChange) {
      return;
    }

    const organizationId = organizationIdChange.currentValue as string;
    if (!organizationId) {
      this.state.isFailed = true;
      return;
    }

    const previousOrganizationId = organizationIdChange.previousValue as string;

    const isOrganizationIdChanged = !previousOrganizationId || organizationId !== previousOrganizationId;
    if (isOrganizationIdChanged) {
      this.loadOrganization(organizationId);
    }
  }

  onTryLoadOrganization() {
    if (this.organizationId) {
      this.loadOrganization(this.organizationId);
    }
  }

  onCancel() {
    this.canceled.emit();
  }

  async onUpdate() {
    this.state.isUpdating = true;

    try {
      // const organization = await this.organizationsApiService.updateOrganization(this.organizationId, this.name);
      // this.updated.emit(organization);
    } catch (error) {
      this.onUpdateFailed('Failed to update the organization', error);
    } finally {
      this.state.isUpdating = false;
    }
  }

  @validate
  private async loadOrganization(@required organizationId: string) {
    this.state.isLoading = true;
    this.state.isFailed = false;
    try {
      const organization = await this.organizationsApiService.getOrganizationById(organizationId);
      this.name = organization.name;
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to load the organization', error);
    } finally {
      this.state.isLoading = false;
    }
  }

  @validate
  private onUpdateFailed(@required message: string, error: any) {
    this.loggerService.error(message, error);

    const errorModalDialogData = new ErrorModalDialogData({ title: message });
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: errorModalDialogData,
    });
  }
}
