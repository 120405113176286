import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService, ListResponse } from 'src/app/core/api';
import { DocumentUser, UniqueEmail, UserDetails } from './shared';
import { usersConfig } from './users.config';
import _, { map } from 'lodash';
import { validate, required } from 'src/app/shared';
import { organizationConfig } from '../organization/organization.config';
import { ChangePasswordDetails } from './shared/change-password-details';
import { UsersManagementService } from './users-management.service';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  private readonly http: HttpClient;
  private readonly apiService: ApiService;
  private readonly usersManagementService: UsersManagementService;

  constructor(http: HttpClient, apiService: ApiService, usersManagementService: UsersManagementService) {
    this.http = http;
    this.apiService = apiService;
    this.usersManagementService = usersManagementService;
  }

  async getUsersByOrganizationId(organizationId: string): Promise<UserDetails[]> {
    let url;
    if (_.isEmpty(organizationId) || organizationId === 'null') {
      url = `api/${organizationConfig.api.organizationUsers.urlFormat}`;
    } else {
      url = `api/${organizationConfig.api.organizationUsers.urlFormat}${organizationId}`;
    }
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    let response: ListResponse<any>;
    try {
      response = await this.http.get<ListResponse<any>>(noCacheUrl).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const users = map<any, UserDetails>(response.list, (userResponse: any) =>
      this.usersManagementService.mapUserDetailsResponse(userResponse)
    );

    return users;
  }

  async getUserByEmail(email: string): Promise<UserDetails> {
    const url = `api/${usersConfig.api.users.url}/${email}`;

    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    let response: any;
    try {
      response = await this.http.get<any>(noCacheUrl).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const user = this.usersManagementService.mapUserDetailsResponse(response);
    return user;
  }

  @validate
  async uniqueEmail(@required email: string): Promise<UniqueEmail>
  {
    const url = `api/${usersConfig.api.users.url}/unique-email?email=${email}`;

    try {
      const response = await this.http.get<UniqueEmail>(url).toPromise();

      return response;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  // async getDocumentReviewers(): Promise<DocumentUser[]> {
  //   const url = `api/${usersConfig.api.users.url}/${usersConfig.api.users.documentReviewers}`;
  //
  //   try {
  //     const response = await this.http.get<DocumentUser[]>(url).toPromise();
  //
  //     return response;
  //   } catch (error) {
  //     const errorResponse = this.apiService.handleError(error);
  //     throw errorResponse;
  //   }
  // }

  @validate
  async deleteUser(@required userId: string): Promise<void> {
    const url = `api/${usersConfig.api.users.url}/${userId}`;

    try {
      await this.http.delete(url).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async createUser(@required userDetails: UserDetails, @required password: string): Promise<string> {
    const url = `api/${usersConfig.api.users.url}`;
    const body = this.usersManagementService.mapUserDetailsWithPassToFormData(userDetails, password);

    let response: { id: string };
    try {
      response = await this.http.post<any>(url, body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    if (response && response.id) {
      return response.id;
    } else {
      throw new Error('Failed to create user.');
    }
  }

  @validate
  async editUser(@required userDetails: UserDetails): Promise<string> {
    const url = `api/${usersConfig.api.users.url}`;
    const body = this.usersManagementService.mapUserDetailsToFormData(userDetails);

    let response: { id: string };
    try {
      response = await this.http.put<any>(url, body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    if (response && response.id) {
      // todo: return updated user
      return response.id;
    } else {
      throw new Error('Failed to create user.');
    }
  }

  @validate
  async moveUserToOrganization(@required userId: string, @required organizationId: string): Promise<void> {
    const url = `api/${usersConfig.api.users.url}/${userId}`;

    const body = {
      meta: {
        oid: organizationId,
      },
    };

    try {
      await this.http.put<any>(url, body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async changePassword(@required passwordDetails: ChangePasswordDetails, @required userId: string): Promise<boolean> {
    const url = `api/${usersConfig.api.users.url}/${usersConfig.api.users.password}`;
    const body = this.usersManagementService.mapChangeUserPasswordToDto(passwordDetails, userId);

    let response: boolean;
    try {
      response = await this.http.post<boolean>(url, body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    if (response) {
      return response;
    } else {
      throw new Error('Failed to create user.');
    }
  }

  @validate
  async resetPassword(@required userId: string, @required newPassword: string): Promise<string> {
    const url = `api/${usersConfig.api.users.url}/${usersConfig.api.users.resetPassword}`;
    const body = this.usersManagementService.mapResetUserPassToFormData(userId, newPassword);

    let response;
    try {
      response = await this.http.post<any>(url, body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    if (response) {
      return response;
    } else {
      throw new Error('Failed to reset password.');
    }
  }
}
