import { StorageType } from './storage/storage-type';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AppState } from '../app.reducer';
import { MatDateFormats } from '@angular/material/core';

export const coreConfig = {
  state: {
    key: 'ui-state',
    featureHashKeyFormat: 'ui-state-hash:{0}',
    storage: StorageType.Local,
    ingoredStates: ['organizations', 'users', 'dashboards'] as FieldsOf<AppState>,
  },
  api: {
    params: {
      noCache: 'noCache',
      dataSourceState: {
        page: 'page',
        pageSize: 'pageSize',
        filter: 'filter',
        sort: {
          fieldsSeparator: ',',
          field: 'field',
          order: 'order',
        },
      },
    },
  },
  pagination: {
    defaultPageSize: 10,
    paginatorMaxSize: 3,
    pageSizeOptions: [10, 20, 30, 40, 50],
  },
  filter: {
    textFilterDebounceTime: 150,
  },
  modal: {
    defaultOptions: {
      size: 'lg',
    } as NgbModalOptions,
    extraLargeOptions: {
      size: 'xl' as any, // HACK: @ng-bootstrap types definition is missing 'xl' size.
    } as NgbModalOptions,
    staticOptions: {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    } as NgbModalOptions,
    fullScreenScrollableModal: {
      size: 'xl' as any, // HACK: @ng-bootstrap types definition is missing 'xl' size.
      windowClass: 'modal-scrollable full-screen-modal',
    } as NgbModalOptions,
  },
  matDateFormats: {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  } as MatDateFormats,
  dates: {
    dateFormat: 'MMMM D, YYYY',
    timeFormat: 'LT',
    dateTimeFormat: 'L, LT',
    shortDateFormat: 'MMMM D',
    relative: {
      maxDiff: 7 * 24 * 60 * 60 * 1000,
    },
    timeFormatLabels: [
      { step: 1000, label: 'ms' },
      { step: 60, label: 's' },
      { step: 60, label: 'm' },
      { step: 24, label: 'h' },
      { step: Number.MAX_SAFE_INTEGER, label: 'd' },
    ],
  },
  forms: {
    autoFocusDelay: 50, // miliseconds
    saveTimeoutDelay: 10, // miliseconds
    decimalDelimiter: ',',
  },
  confirm: {
    confirmationText: 'radera',
  },
};
