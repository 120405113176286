import { Injectable } from '@angular/core';
import _ from 'lodash';
import {
  BuildingCulvertType,
  DocumentBuildingOccuredActivityOptionTypes,
  DocumentAirTreatmentFireGasesProtectionOptionTypes,
  DocumentBuilding,
  DocumentGeneralSectionField,
  DocumentInstallations,
  DocumentInstallationsAlarmSignalingOccurOptionTypes,
  DocumentInstallationsAutomaticFireAlarmActivationOptionTypes,
  DocumentInstallationsAutomaticFireAlarmPowerSupplyOptionTypes,
  DocumentInstallationsExtinguishingEquipmentFormOptionTypes,
  DocumentLifts,
  DocumentOpportunities,
  DocumentOpportunitiesSolarPanelsLocationOptionTypes,
  DocumentSection,
  DocumentSectionField,
  DocumentSectionName,
  DocumentSectionType,
  DocumentFireTechnicalClassFirewallOptionTypes,
  DocumentRisksSpecialRiskOptionTypes,
  DocumentInstallationsAutomaticExtinguishingSystemOccurOptionTypes,
  DocumentBuildingLargeKitchenWithOptionTypes,
  DocumentBuildingProtectiveSolutionForLargeKitchenOptionTypes,
  DocumentBuildingMeetingRoomOccursInFormOfOptionTypes,
  DocumentBuildingNumberOfPeopleInMeetingRoomOptionTypes,
  DocumentFireCellMeetTechnicalClassOptionTypes,
  DocumentFireRequirementOptionTypes,
  DocumentInstallationsEvacuationAlarmsActivationOptionTypes,
  DocumentBuildingFireTechnicalBuildingOptionTypes,
  DocumentSpreadFacadeMaterialOptionTypes,
  DocumentInstallationsPowerSupplyEmergencyLightingOptionTypes,
  DocumentFireSectioningThroughFirewallOptionTypes,
} from './shared';
import { DocumentFieldType } from './shared';
import {
  DocumentSectionAirTreatment,
  DocumentSectionBuilding,
  DocumentSectionCapacity,
  DocumentSectionEvacuation,
  DocumentSectionFire,
  DocumentSectionInfo,
  DocumentSectionInstallations,
  DocumentSectionLifts,
  DocumentSectionObject,
  DocumentSectionOpportunities,
  DocumentSectionProtectedObjects,
  DocumentSectionRisks,
  DocumentSectionSpread,
} from './shared';
import { DocumentSelectOptionType } from './shared/document-structure/document-select-option-type';
import { DocumentSelectOptionGroupType } from './shared/document-structure/document-select-option-group-type';

@Injectable({
  providedIn: 'root',
})
export class DocumentsSectionDataService {
  constructor() {}

  getGeneralSectionFieldTypeBySectionType(type: DocumentSectionType): DocumentGeneralSectionField {
    switch (type) {
      case DocumentSectionType.info:
        return DocumentGeneralSectionField.info;
      case DocumentSectionType.object:
        return DocumentGeneralSectionField.object;
      case DocumentSectionType.building:
        return DocumentGeneralSectionField.building;
      case DocumentSectionType.evacuation:
        return DocumentGeneralSectionField.evacuation;
      case DocumentSectionType.fire:
        return DocumentGeneralSectionField.fire;
      case DocumentSectionType.spread:
        return DocumentGeneralSectionField.spread;
      case DocumentSectionType.capacity:
        return DocumentGeneralSectionField.capacity;
      case DocumentSectionType.installations:
        return DocumentGeneralSectionField.installations;
      case DocumentSectionType.airtreatment:
        return DocumentGeneralSectionField.airtreatment;
      case DocumentSectionType.lifts:
        return DocumentGeneralSectionField.lifts;
      case DocumentSectionType.opportunities:
        return DocumentGeneralSectionField.opportunities;
      case DocumentSectionType.risks:
        return DocumentGeneralSectionField.risks;
      case DocumentSectionType.protectedObjects:
        return DocumentGeneralSectionField.protectedObjects;
      case DocumentSectionType.inspection:
        return DocumentGeneralSectionField.inspection;

      default:
        throw new Error('DocumentSectionType is not defined: ' + type);
    }
  }

  getGeneralSectionFieldBySectionType(type: DocumentSectionType): DocumentSectionField {
    const generalField = new DocumentSectionField({
      fieldId: this.getGeneralSectionFieldTypeBySectionType(type),
      displayName: 'Generell notering',
      type: DocumentFieldType.string,
    });

    return generalField;
  }

  getDocumentSections(): DocumentSection[] {
    const documentGeneralFields = this.getDocumentGeneralFields();
    const documentInfoFields = this.getDocumentInfoFields();
    const documentObjectFields = this.getDocumentObjectFields();
    const documentBuildingFields = this.getDocumentBuildingFields();
    const documentEvacuationFields = this.getDocumentEvacuationFields();
    const documentFireFields = this.getDocumentFireFields();
    const documentSpreadFields = this.getDocumentSpreadFields();
    const documentCapacityFields = this.getDocumentCapacities();
    const documentInstallationsFields = this.getDocumentInstallationsFields();
    const documentAirtreatmentFields = this.getDocumentAirtreatmentFields();
    const documentLiftsFields = this.getDocumentLiftsFields();
    const documentOpportunitiesFields = this.getDocumentOpportunitiesFields();
    const documentRisksFields = this.getDocumentRisksFields();
    const documentProtectedObjectsFields = this.getDocumentProtectedObjectsFields();

    const documentSections = [
      new DocumentSection({
        sectionId: DocumentSectionType.common,
        displayName: '',
        fields: documentGeneralFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.info,
        displayName: 'ProjektInfo',
        fields: documentInfoFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.object,
        displayName: 'Objekt',
        fields: documentObjectFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.building,
        displayName: DocumentSectionName[DocumentSectionType.building],
        fields: documentBuildingFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.evacuation,
        displayName: 'Utrymning',
        fields: documentEvacuationFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.fire,
        displayName: 'Skydd Mot Brand',
        fields: documentFireFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.spread,
        displayName: 'Skydd Mot Brandspridning Mellan Byggnader',
        fields: documentSpreadFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.capacity,
        displayName: 'Bärförmåga',
        fields: documentCapacityFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.installations,
        displayName: 'Brandtekniska Installationer',
        fields: documentInstallationsFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.airtreatment,
        displayName: 'Luftbehandling',
        fields: documentAirtreatmentFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.lifts,
        displayName: 'Hissar',
        fields: documentLiftsFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.opportunities,
        displayName: 'Insatsmöjligheter',
        fields: documentOpportunitiesFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.risks,
        displayName: 'Brand, Särskilda Risker',
        fields: documentRisksFields,
      }),
      new DocumentSection({
        sectionId: DocumentSectionType.protectedObjects,
        displayName: 'Skyddsvärda objekt',
        fields: documentProtectedObjectsFields,
      }),
    ];

    return documentSections;
  }

  getDocumentStatusTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-work-document', 'Arbetshandling'),
      new DocumentSelectOptionType('120-preliminary', 'Preliminär'),
      new DocumentSelectOptionType('130-review-document', 'Granskningshandling'),
      new DocumentSelectOptionType('140-relationship-action', 'Relationshandling'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getDocumentUsers(): StringMap<DocumentSelectOptionGroupType> {
    // Document users we get from the loaded documents
    return null;
  }

  private getDocumentGeneralFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionInfo.creatorId,
        displayName: 'Upprättad av',
        type: DocumentFieldType.groupDropdown,
        values: this.getDocumentUsers(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInfo.reviserId,
        displayName: 'Reviderad av',
        type: DocumentFieldType.groupDropdown,
        values: this.getDocumentUsers(),
      }),
    ];

    return documentSectionFields;
  }

  private getDocumentInfoFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionInfo.buildingName,
        displayName: 'Byggnadsnamn',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInfo.objectNumber,
        displayName: 'Objektsnummer',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInfo.isManagementDrawingFireProtectionDrawnUp,
        displayName: 'Förvaltningsritningar brandskydd är upprättade',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInfo.isEvacuationPlanDrawnUp,
        displayName: 'Utrymningsplaner är upprättade',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInfo.isActionPlanDrawnUp,
        displayName: 'Insatsplaner är upprättade',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInfo.siteVisitDate,
        displayName: 'Datum platsbesök',
        type: DocumentFieldType.date,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInfo.propertyOwner,
        displayName: 'Fastighetsägare',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInfo.documentStatusId,
        displayName: 'Dokumentstatus',
        type: DocumentFieldType.dropdown,
        values: this.getDocumentStatusTypes(),
      }),
    ];

    return documentSectionFields;
  }

  private getDocumentObjectFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionObject.propertyName,
        displayName: 'Fastighetsbeteckning',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionObject.municipality,
        displayName: 'Kommun',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionObject.area,
        displayName: 'Område',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionObject.areaM2,
        displayName: 'Yta (m²)',
        type: DocumentFieldType.number,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionObject.building,
        displayName: 'Byggnad',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionObject.constructionYear,
        displayName: 'Byggår',
        type: DocumentFieldType.number,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionObject.renovations,
        displayName: 'Om-/tillbyggnader',
        type: DocumentFieldType.renovation,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionObject.changesDescription,
        displayName: 'Detaljbeskrivning avseende ändringar, underlag etc',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }

  getFireTechnicalBuildingClassTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentBuildingFireTechnicalBuildingOptionTypes.br0Id, 'Br0'),
      new DocumentSelectOptionType(DocumentBuildingFireTechnicalBuildingOptionTypes.br1Id, 'Br1'),
      new DocumentSelectOptionType(DocumentBuildingFireTechnicalBuildingOptionTypes.br2Id, 'Br2'),
      new DocumentSelectOptionType(DocumentBuildingFireTechnicalBuildingOptionTypes.br3Id, 'Br3'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getDimensioningFireLoadTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-less-250', '&#8804; 250 MJ/m² golvarea'),
      new DocumentSelectOptionType('120-250-800', '&#8804; 800 MJ/m² golvarea'),
      new DocumentSelectOptionType('130-800-1600', '&#8804; 1600 MJ/m² golvarea'),
      new DocumentSelectOptionType('140-greater-1600', '> 1600 MJ/m² golvarea'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getCulvertTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(BuildingCulvertType.transportCulvert, 'Transportkulvert'),
      new DocumentSelectOptionType(BuildingCulvertType.technicalType, 'Teknikkulvert'),
      new DocumentSelectOptionType(BuildingCulvertType.pipeCulvert, 'Rörkulvert'),
      new DocumentSelectOptionType(BuildingCulvertType.unspecified, 'Ej fastställd', true),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getFireTechnicalSolutionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-simplified-dimensioning', 'Förenklad dimensionering'),
      new DocumentSelectOptionType(DocumentBuilding.analyticalDimensioningId, 'Analytisk dimensionering'),
      new DocumentSelectOptionType('130-not-confirmed', 'Ej fastställt'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getOccuredBuildingActivityTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk1Id, 'Vk 1 - Kontor, industri mm.'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk2aId, 'Vk 2A - Lokal för högst 150 personer'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk2bId, 'Vk 2B - Samlingslokal för > 150 personer'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk2cId, 'Vk 2C - Samlingslokal för > 150 personer inkl. alkoholservering'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk3aId, 'Vk 3A - Bostäder'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk3bId, 'Vk 3B - Gemensamhetsboende'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk4Id, 'Vk 4 - Hotell, vandrarhem mm.'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk5aId, 'Vk 5A - Förskola, dagverksamhet mm.'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk5bId, 'Vk 5B - Behovsprövat särskilt boende'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk5cId, 'Vk 5C - Lokal för hälso- och sjukvård'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk5dId, 'Vk 5D - Häkte, fängelse, sluten psykiatriavdelning mm.'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.vk6Id, 'Vk 6 - Lokal med förhöjd brandrisk'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.separateBoilerRoomsId, 'Avskilda pannrum'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.largeKitchenId, 'Storkök'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.archiveId, 'Arkiv (enligt RA-FS)'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.garageId, 'Garage'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.helicopterLandingId, 'Helikopterlandningsplats'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.permanentWorkplaceWithHotJobsId, 'Fast arbetsplats med heta arbeten'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.otherSpaceWithHighRisksId, 'Övriga utrymmen med hög brandrisk'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.sensitiveProtectionPremisesId, 'Känsliga lokaler avseende produktionsskydd'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.premisesAgricultureId, 'Lokaler avseeda för lantbruk'),
      new DocumentSelectOptionType(DocumentBuildingOccuredActivityOptionTypes.otherSpecialActivitiesId, 'Annan särskild verksamhet, se detaljbeskrivning'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getLargeKitchenWithOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentBuildingLargeKitchenWithOptionTypes.withFryerId, 'Förekomst av fritös'),
      new DocumentSelectOptionType(DocumentBuildingLargeKitchenWithOptionTypes.withFryingTableId, 'Förekomst av stekbord'),
      new DocumentSelectOptionType(DocumentBuildingLargeKitchenWithOptionTypes.withoutIncreasedProbabilityOfFireId, 'Utan förhöjd sannolikhet för uppkomst av brand', true),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getProtectiveSolutionForLargeKitchenOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentBuildingProtectiveSolutionForLargeKitchenOptionTypes.analyticalDimensioningId, 'Analytisk dimensionering'),
      new DocumentSelectOptionType(DocumentBuildingProtectiveSolutionForLargeKitchenOptionTypes.fireCellDivisionId, 'Brandcellsindelning'),
      new DocumentSelectOptionType(DocumentBuildingProtectiveSolutionForLargeKitchenOptionTypes.pointProtectionId, 'Punktskydd'),
      new DocumentSelectOptionType(DocumentBuildingProtectiveSolutionForLargeKitchenOptionTypes.missingId, 'Saknas', true),

    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getMeetingRoomOccursInFormOfOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentBuildingMeetingRoomOccursInFormOfOptionTypes.privateMeetingRoomId, 'Enskild samlingslokal'),
      new DocumentSelectOptionType(DocumentBuildingMeetingRoomOccursInFormOfOptionTypes.groupOfPremisesWithFireCellId, 'Grupp av lokaler inom en brandcell'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getNumberOfPeopleInMeetingRoomOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentBuildingNumberOfPeopleInMeetingRoomOptionTypes.f151To300Id, '151-300'),
      new DocumentSelectOptionType(DocumentBuildingNumberOfPeopleInMeetingRoomOptionTypes.f301To600Id, '301-600'),
      new DocumentSelectOptionType(DocumentBuildingNumberOfPeopleInMeetingRoomOptionTypes.f601To1000Id, '601-1000'),
      new DocumentSelectOptionType(DocumentBuildingNumberOfPeopleInMeetingRoomOptionTypes.more1000Id, '>1000'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  private getDocumentBuildingFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.numberOfStoreys,
        displayName: 'Antal våningsplan',
        type: DocumentFieldType.number,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.fireTechnicalBuildingClassId,
        displayName: 'Brandteknisk byggnadsklass',
        type: DocumentFieldType.dropdown,
        values: this.getFireTechnicalBuildingClassTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.isBr0AnalysisEstablished,
        displayName: 'Br0-analys finns upprättad',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.dimensioningFireLoadId,
        displayName: 'Dimensionering brandbelastning',
        type: DocumentFieldType.dropdown,
        values: this.getDimensioningFireLoadTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.hasBasementFloor,
        displayName: 'Källarplan',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.isConsideredBasementFloorPlan,
        displayName: 'Källarplan - Betraktas som våningsplan',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.hasSeveralBasementFloors,
        displayName: 'Fler än ett källarplan',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.hasAtticFloor,
        displayName: 'Vindsplan',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.isConsideredAtticFloorPlan,
        displayName: 'Vindsplan - Betraktas som våningsplan',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.hasEntresolplan,
        displayName: 'Entresolplan',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.isConsideredEntresolplan,
        displayName: 'Entresolplan - Betraktas som våningsplan',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.isConsideredSmallerEntresolplan,
        displayName: 'Entresolplan - Betraktas som mindre entresolplan',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.hasSouterrainDesign,
        displayName: 'Suterrängutformning',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.isConsideredSouterrainDesignPlan,
        displayName: 'Suterrängutformning - Betraktas som våningsplan',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.hasCulvert,
        displayName: 'Kulvert',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.culvertTypeIds,
        displayName: 'Typ av kulvert',
        type: DocumentFieldType.multiple,
        values: this.getCulvertTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.buildingConnectionWith,
        displayName: 'Byggnaden är sammanbyggd med',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.buildingIsIncludedInSameBuildingComplex,
        displayName: 'Aktuell byggnadsdel ingår i samma byggnadskomplex tillsammans med följande byggnadsdelar',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.adjacentBuildings,
        displayName: 'Intilliggande byggnader finns i form av',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.buildingBuiltTogetherViaCulvert,
        displayName: 'Aktuell byggnad/byggnadskomplex är sammanbyggd via kulvert med annan byggnad/byggnadskomplex inom fastigheten.',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.fireTechnicalSolutionTypeId,
        displayName: 'Byggnadens brandtekniska lösning är projekterad med',
        type: DocumentFieldType.dropdown,
        values: this.getFireTechnicalSolutionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.isOwnGuidelinesAmbitionsApplied,
        displayName: 'Egna riktlinjer/ambitioner tillämpas',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.followingOwnGuidelineAmbitionsRelevant,
        displayName: 'Följande egna riktlinjer/ambitioner är aktuella',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.analyticalDimensioningDescription,
        displayName: 'Beskrivning av analytisk dimensionering',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.occuredBuildingActivityIds,
        displayName: 'Följande verksamheter förekommer i byggnaden',
        type: DocumentFieldType.multiple,
        values: this.getOccuredBuildingActivityTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.largeKitchenWithIds,
        displayName: 'Storkök med',
        type: DocumentFieldType.multiple,
        values: this.getLargeKitchenWithOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.protectiveSolutionForLargeKitchenIds,
        displayName: 'Skyddslösning för storkök med förhöjd sannolikhet för uppkomst av brand',
        type: DocumentFieldType.multiple,
        values: this.getProtectiveSolutionForLargeKitchenOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.meetingRoomOccursInFormOfIds,
        displayName: 'Samlingslokal förekommer i form av',
        type: DocumentFieldType.multiple,
        values: this.getMeetingRoomOccursInFormOfOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.numberOfPeopleInMeetingRoomIds,
        displayName: 'Dimensionerande personantal inom samlingslokal',
        type: DocumentFieldType.multiple,
        values: this.getNumberOfPeopleInMeetingRoomOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.isPublicPremises,
        displayName: 'Publika lokaler',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.isAppliedTemporaryOvernightAccommodation,
        displayName: 'Inom byggnaden tillämpas tillfällig övernattning (skolverksamhet)',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.frameConstructionConsists,
        displayName: 'Stommens konstruktion utgörs översiktligt av',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.beamConstructionConsists,
        displayName: 'Bjälklagets konstruktion utgörs översiktligt av',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.facadeConstructionConsists,
        displayName: 'Fasadens konstruktion utgörs översiktligt av',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.roofConstructionConsists,
        displayName: 'Yttertakets konstruktion utgörs översiktligt av',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionBuilding.extendedBuildingDescription,
        displayName: 'Utökad byggnads- och verksamhetsbeskrivning',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }

  getLockedDoorsTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-requires-card', 'Kräver kort-/tag-/nyckelöppning'), // Requires card / tag / key opening
      new DocumentSelectOptionType('120-delayed-opening', 'Fördröjd öppning'), // Delayed opening
      new DocumentSelectOptionType('130-fire-alarm-opens-only', 'Öppningsfunktion via brandlarm'), // Opens only via fire alarm
      new DocumentSelectOptionType('140-special-opening-button', 'Öppnas med nödöppningsknapp'), // Opens with a special opening
      new DocumentSelectOptionType('150-interlocked', 'Förreglas över väsentlig funktion'), // Interlocked over essential function
      new DocumentSelectOptionType('160-does-not-exist', 'Finns ej', true), // Does not exist
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  private getDocumentEvacuationFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isWindowRemoval,
        displayName: 'Fönsterutrymning',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isOnlyOneEscapeRoute,
        displayName: 'En enda utrymningsväg förekommer',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isEvacuationRescueService,
        displayName: 'Utrymning med hjälp av räddningtjänstens stegutrustning',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isEvacuationFromAnotherFireExit,
        displayName: 'Utrymning över annan brandcell',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isEvacuationFromAnotherPartOfBuilding,
        displayName: 'Utrymning över annan byggnadsdel',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isHorizontalEvacuation,
        displayName: 'Horisontell utrymning tillämpas',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isEvacuationViaTr1,
        displayName: 'Utrymning via Tr1 alt. Tr2-trapphus',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isOnlyEscapeRouteViaTr1,
        displayName: 'Utrymning via Tr1 alt. Tr2-trapphus - Utgör den enda utrymningsvägen',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isTemporaryEvacuationAFS20201,
        displayName: 'Tillfällig utrymningsplats enligt AFS 2020:1',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isReEntryRequirements,
        displayName: 'Krav för återinträde',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isEvacuationViaAutomaticHorizontalSlidingDoors,
        displayName: 'Utrymning via automatiska horisontella skjutdörrar',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isEvacuationViaEvacuationElevator,
        displayName: 'Utrymning via utrymningshiss',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isEvacuationViaRescueElevator,
        displayName: 'Utrymning via räddningshiss',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.lockedDoorsIds,
        displayName: 'Låsta dörrar',
        type: DocumentFieldType.multiple,
        values: this.getLockedDoorsTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isEvacuationForDisabilitiesPeople,
        displayName: 'Utrymningsplatser för personer med nedsatt rörelse- och orienteringsförmåga',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isAccessibleAndUsableHorizontalEvacuation,
        displayName: 'Tillgänglig och användbar utrymningsväg i horisontellt led',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isCommunicationEquipmentEvacuation,
        displayName: 'Kommunikationsutrustning utrymningsplats',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isEvacuationViaCommonPartOfSeparatedRoute,
        displayName: 'Utrymning via gemensam del (korridor) av i övrigt avskilda utrymningsvägar',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isMattressEvacuationOfBedriddenPatients,
        displayName: 'Madrassutrymning av sängliggande patienter',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isClassifiedEscapeRoute,
        displayName: 'Klassad utrymningsväg',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isEvacuationPlansInBuilding,
        displayName: 'Utrymningsplaner förekommer inom byggnaden',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.isImageRequirementsAccordingVk4,
        displayName: 'Utrymningsplaner förekommer inom byggnaden - Kravbild enligt Vk 4',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionEvacuation.extendedEvacuationDescription,
        displayName: 'Utökad beskrivning avseende utrymning',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }

  getFireRequirementOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentFireRequirementOptionTypes.requirement400El30Id, 'Kravbild för 400 m², EI 30'),
      new DocumentSelectOptionType(DocumentFireRequirementOptionTypes.requirementBr1Building1200El60Id,
        'Kravbild för Br1-byggnad för 1200 m², EI 60 (utöver kravbild för 400 m²)'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getSectioningThroughFirewallTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentFireSectioningThroughFirewallOptionTypes.betweenBuildingsId, 'Mellan byggnader'), // Between buildings
      new DocumentSelectOptionType(DocumentFireSectioningThroughFirewallOptionTypes.ofLargeBuildingId, 'Av stor byggnad'), // Of large building
      new DocumentSelectOptionType(DocumentFireSectioningThroughFirewallOptionTypes.doesNotExistId, 'Finns ej', true), // Does not exist
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getFireTechnicalClassFirewallTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentFireTechnicalClassFirewallOptionTypes.rei60mId, 'REI60-M'),
      new DocumentSelectOptionType(DocumentFireTechnicalClassFirewallOptionTypes.rei90mId, 'REI90-M'),
      new DocumentSelectOptionType(DocumentFireTechnicalClassFirewallOptionTypes.rei120mId, 'REI120-M'),
      new DocumentSelectOptionType(DocumentFireTechnicalClassFirewallOptionTypes.rei240mId, 'REI240-M'),
      new DocumentSelectOptionType(DocumentFireTechnicalClassFirewallOptionTypes.unspecifiedId, 'Ej fastställd', true),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getOlderFireTechnicalClassTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-doors', 'Dörrar'), // Doors
      new DocumentSelectOptionType('120-window', 'Fönster'), // Window
      new DocumentSelectOptionType('130-does-not-appear', 'Förekommer ej', true), // Not applicable
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getFireCellMeetTechnicalClassTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentFireCellMeetTechnicalClassOptionTypes.e15Id, 'E 15'),
      new DocumentSelectOptionType(DocumentFireCellMeetTechnicalClassOptionTypes.ei15Id, 'EI 15'),
      new DocumentSelectOptionType(DocumentFireCellMeetTechnicalClassOptionTypes.e30Id, 'E 30'),
      new DocumentSelectOptionType(DocumentFireCellMeetTechnicalClassOptionTypes.ei30Id, 'EI 30'),
      new DocumentSelectOptionType(DocumentFireCellMeetTechnicalClassOptionTypes.e60Id, 'E 60'),
      new DocumentSelectOptionType(DocumentFireCellMeetTechnicalClassOptionTypes.ei60Id, 'EI 60'),
      new DocumentSelectOptionType(DocumentFireCellMeetTechnicalClassOptionTypes.ei90Id, 'EI 90'),
      new DocumentSelectOptionType(DocumentFireCellMeetTechnicalClassOptionTypes.ei120Id, 'EI 120'),
      new DocumentSelectOptionType(DocumentFireCellMeetTechnicalClassOptionTypes.ei240Id, 'EI 240'),
      new DocumentSelectOptionType(DocumentFireCellMeetTechnicalClassOptionTypes.unclear, 'Oklart', true),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  private getDocumentFireFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionFire.isBuildingDividedIntoFireCells,
        displayName: 'Byggnaden är indelad i brandceller',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.isFireCellExceeds1250m2,
        displayName: 'Brandcell som överskrider 1250 m²',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.isMoreThanTwoPlanes,
        displayName: 'Fler än två plan i samma brandcell',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.isSpecialFireTechnicalClass,
        displayName: 'Särskild brandteknisk klass på avskiljande konstruktion',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.isSeveralBusinessClasses,
        displayName: 'Flera verksamhetsklasser inom samma brandcell',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasDoorsOrHatchesInFireSeparationConstruction,
        displayName: 'Dörrar och/eller luckor i brandavskiljande konstruktion',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasInstallationDeviceOnDoors,
        displayName: 'Uppställningsanordning på brandavskiljande dörrar',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasAutomaticOpeningOnDoors,
        displayName: 'Automatisk öppningsfunktion på brandavskiljande dörrar',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasWindowsInFireRetardantConstruction,
        displayName: 'Glasparti i brandavskiljande konstruktion',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasFireWindowAtAngle,
        displayName: 'Fönster i vinkel, parallellt alt. höjdled mellan olika brandceller i yttervägg',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasFireLowHighPart,
        displayName: 'Lågdel/högdel i brandcellsgräns',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasFireRetardantCeilingsFloors,
        displayName: 'Brandavskiljande undertak eller installationsgolv',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasAirFireLock,
        displayName: 'Luft- eller brandsluss',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasCoveredYardBalconies,
        displayName: 'Överbyggd gård, inglasade balkonger, loftgångar etc.',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasFireCellInAttic,
        displayName: 'Brandcellsindelning för uppdelning av vindsplanet',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasFireTechnicalSeparationOfAeratedEaves,
        displayName: 'Brandteknisk avskiljning av luftad takfot',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasWashingAndGarbageChute,
        displayName: 'Tvätt- och sopnedkast',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.hasPneumaticTube,
        displayName: 'Rörpost',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.fireRequirementIds,
        displayName: 'Kravbild',
        type: DocumentFieldType.multiple,
        values: this.getFireRequirementOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.sectioningThroughFirewallIds,
        displayName: 'Sektionering genom brandvägg',
        type: DocumentFieldType.multiple,
        values: this.getSectioningThroughFirewallTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.fireTechnicalClassFirewallIds,
        displayName: 'Brandteknisk klass avseende brandväggar',
        type: DocumentFieldType.multiple,
        values: this.getFireTechnicalClassFirewallTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.olderFireTechnicalClassIds,
        displayName: 'Äldre brandteknisk klass förekommer',
        type: DocumentFieldType.multiple,
        values: this.getOlderFireTechnicalClassTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.fireCellMeetTechnicalClassIds,
        displayName: 'Brandcellsgränser uppfyller brandteknisk klass',
        type: DocumentFieldType.multiple,
        values: this.getFireCellMeetTechnicalClassTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.fireSpecialConditionsDescription,
        displayName: 'Beskrivning kring särskilda förutsättningar gällande ytskikt, beklädnad och/eller golvbeläggning',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionFire.fireExtendedDescription,
        displayName: 'Utökad beskrivning avseende brandcellsindelning',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }

  getProtectionAgainstFireOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-safety-distance', 'Skyddsavstånd'), // Safety distance
      new DocumentSelectOptionType('111-analytical-dimensioning', 'Analytisk dimensionering'),
      new DocumentSelectOptionType('120-separating-construction', 'Avskiljande konstruktion'), // Separating construction
      new DocumentSelectOptionType('130-radiation-calculation-through-analytical-dimensioning-through-analytical-dimensioning', 'Strålningsberäkning genom analytisk dimensionering'), // Radiation calculation
      // Combination of protective distance / separating construction
      new DocumentSelectOptionType(
        '140-combination-protective-distance',
        'Kombination av skyddsavstånd/avskiljande konstruktion'
      ),
      new DocumentSelectOptionType('150-firewall', 'Brandvägg'), // Firewall
      new DocumentSelectOptionType('160-not-confirmed', 'Ej fastställt', true), // not confirmed
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getRoofingOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-paperboard', 'Papp'), // Paperboard
      new DocumentSelectOptionType('120-plate', 'Plåt'), // Plate
      new DocumentSelectOptionType('130-concrete-roof-tiles', 'Betongtakpannor'), // Concrete roof tiles
      new DocumentSelectOptionType('140-tiled-roof-tiles', 'Tegeltakpannor'), // Tiled roof tiles
      new DocumentSelectOptionType('150-rubber-cloth', 'Gummiduk'), // Rubber cloth
      new DocumentSelectOptionType('160-sedum-roof', 'Sedumtak'),
      new DocumentSelectOptionType('170-gravel-stone', 'Grus/sten'), // Gravel / stone
      new DocumentSelectOptionType('180-wood', 'Trä'), // Wood
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getBaseRoofingOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-combustible-material', 'Brännbart material'), // Combustible material
      new DocumentSelectOptionType('120-non-combustible-material', 'Icke brännbart material'), // Non-combustible material
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getFacadeMaterialOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-wood', 'Trä'), // Wood
      new DocumentSelectOptionType('120-tiles', 'Tegel'), // Tiles
      new DocumentSelectOptionType('130-puts', 'Puts'), // Puts
      new DocumentSelectOptionType('140-plate', 'Plåt'), // Plate
      new DocumentSelectOptionType('150-concrete', 'Betong'), // Concrete
      new DocumentSelectOptionType(DocumentSpreadFacadeMaterialOptionTypes.otherId, 'Annat'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  private getDocumentSpreadFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionSpread.protectionAgainstFireOptionIds,
        displayName: 'Skydd mot brandspridning mellan byggnader uppnås genom',
        type: DocumentFieldType.multiple,
        values: this.getProtectionAgainstFireOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionSpread.roofingOptionIds,
        displayName: 'Taktäckning på aktuell byggnad är utfört i',
        type: DocumentFieldType.multiple,
        values: this.getRoofingOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionSpread.baseRoofingIds,
        displayName: 'Underlag taktäckning',
        type: DocumentFieldType.multiple,
        values: this.getBaseRoofingOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionSpread.facadeMaterialOptionIds,
        displayName: 'Fasadmaterial på aktuell byggnad är utfört i',
        type: DocumentFieldType.multiple,
        values: this.getFacadeMaterialOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionSpread.facadeMaterialOptionOther,
        displayName: 'Fasadmaterial på aktuell byggnad är utfört i - Annat',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionSpread.protectionAgainstSpreadDescription,
        displayName: 'Utökad beskrivning avseende skydd mot spridning mellan byggnader',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }

  getFireCarryingCapacityTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-designed-by-classification', 'Utformats genom klassificering'), // Designed by classification
      // Designed through analytical verification in the form of calculation
      new DocumentSelectOptionType(
        '120-designed-by-analytical-verification',
        'Utformats genom analytisk verifiering i form av beräkning'
      ),
      new DocumentSelectOptionType('130-could-not-be-determined', 'Har ej kunnat fastställas'), // Could not be determined
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  private getDocumentCapacities(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionCapacity.fireCarryingCapacityId,
        displayName: 'Bärförmåga vid brand har',
        type: DocumentFieldType.dropdown,
        values: this.getFireCarryingCapacityTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionCapacity.isDocumentedConsiderationOfLoadCapacityWithHighFireLoad,
        displayName: 'Dokumenterad beaktning av bärförmåga m.h.t. hög brandbelastning',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionCapacity.isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem,
        displayName: 'Dokumenterad beaktning av bärförmåga m.h.t. tekniskt byte vid förekomst av automatisk vattensprinkleranläggning',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionCapacity.isDocumentedConsiderationOfProtectionNeedsForBr0Building,
        displayName: 'Dokumenterad beaktning av utökat skyddsbehov för Br0-byggnad',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionCapacity.loadBearingCapacityDescription,
        displayName: 'Utökad beskrivning av bärförmåga',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }

  getMarkingsEmergencyLightingOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      // Illuminated / illuminated signs with emergency power supply
      new DocumentSelectOptionType(
        DocumentInstallations.illuminatedWithEmergencyPowerId,
        'Belysta/genomlysta skyltar med nödströmförsörjning'
      ),
      new DocumentSelectOptionType('120-illuminated-signs', 'Efterlysande skyltar'), // Illuminated signs
      new DocumentSelectOptionType('130-illuminated-floor-markings', 'Efterlysande golvmarkeringar'), // Illuminated floor markings
      // Separate emergency lighting
      new DocumentSelectOptionType(DocumentInstallations.separateEmergencyLightingId, 'Separat nödbelysning'),
      new DocumentSelectOptionType('150-does-not-exist', 'Finns ej', true), // Does not exist
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  getPowerSupplyEmergencyLightingOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentInstallationsPowerSupplyEmergencyLightingOptionTypes.centralBatteryBackupId, 'Central batteribackup'), // Central battery backup
      new DocumentSelectOptionType(DocumentInstallationsPowerSupplyEmergencyLightingOptionTypes.localBatteryBackupId, 'Lokal batteribackup'), // Local battery backup
      new DocumentSelectOptionType(DocumentInstallationsPowerSupplyEmergencyLightingOptionTypes.reservePowerUnitId, 'Reservkraftsaggregat'), // Reserve power unit
      new DocumentSelectOptionType(DocumentInstallationsPowerSupplyEmergencyLightingOptionTypes.notEstablishedId, 'Ej fastställt', true),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getCoordinatedFunctionAutomaticFireAlarmRelevantOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-ventilation-unit', 'Ventilationsaggregat'), // Ventilation unit
      new DocumentSelectOptionType('120-fire-gas-ventilation', 'Brandgasventilation'), // Fire gas ventilation
      new DocumentSelectOptionType('121-fire-gas-ventilation-smoke-hatches', 'Brandgasventilation rökluckor'),
      new DocumentSelectOptionType('122-fire-gas-ventilation-fire-gas-fan', 'Brandgasventilation brandgasfläkt'),
      new DocumentSelectOptionType('130-elevator-regarding-recall-function', 'Hiss avseende återkallningsfunktion (evakueringsplan)'),
      new DocumentSelectOptionType('140-escalators', 'Rulltrappor'), // Escalators
      new DocumentSelectOptionType('150-operation-fire-fans', 'Fläktar i drift vid brand'), // Fans in operation in case of fire
      new DocumentSelectOptionType('160-installation-device-fire-door', 'Uppställningsanordning branddörr'),
      new DocumentSelectOptionType('170-fire-extinguisher', 'Släckanläggning(ar)'), // Fire extinguisher (s)
      new DocumentSelectOptionType('180-alarm-signal', 'Larmsignalering'),
      new DocumentSelectOptionType('190-fire-throttle', 'Brand-/Brandgasspjäll'), // Fire / Fire throttle
      new DocumentSelectOptionType('200-electrical-end-plate', 'Elslutbleck'), // Electrical end plate
      new DocumentSelectOptionType('210-emergency-lighting', 'Nödbelysning'),
      new DocumentSelectOptionType('220-key-cabinet', 'Nyckelskåp'),
      new DocumentSelectOptionType('230-sensor-function-fire-door', 'Sensorfunktion branddörr'),
      new DocumentSelectOptionType('240-alarm-transmission-alarm-receiver', 'Larmöverföring larmmottagare'),
      new DocumentSelectOptionType('250-ventilation-technical-functions', 'Ventilationstekniska funktioner'),
      new DocumentSelectOptionType('260-other', 'Övrigt')
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getAutomaticFireAlarmsMonitoringScopeOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-A', 'A - Fullständig övervakning av byggnad'), // A - Complete supervision of building
      new DocumentSelectOptionType('120-B', 'B - Fullständig övervakning av brandceller'), // B - Complete monitoring of fire cells
      // C - Complete monitoring of evacuation and communication routes
      new DocumentSelectOptionType('130-C', 'C - Fullständig övervakning av utrymnings- och kommunikationsväg'),
      new DocumentSelectOptionType('140-D', 'D - Fullständig övervakning av vissa utrymmen'), // D - Complete monitoring of certain areas
      new DocumentSelectOptionType('150-E', 'E - Endast manuell aktivering'), // E - Manual activation only
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getAutomaticFireAlarmDetekteringOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-smoke', 'Rök (RD)'), // Smoke (RD)
      new DocumentSelectOptionType('120-heating', 'Värme (VD)'), // Heating (CEO)
      new DocumentSelectOptionType('130-smoke-heat', 'Rök/värme (Kombi)'), // Smoke / heat (Combi)
      new DocumentSelectOptionType('140-line-smokedetector', 'Linjerökdetektor (RDL)'), // Line Smoke Detector (RDL)
      new DocumentSelectOptionType('150-aspiration', 'Aspirerande/sampling (SD)'), // Aspiration / sampling (SD)
      new DocumentSelectOptionType('160-flames', 'Flammor (FD)'), // Flames (FD)
      new DocumentSelectOptionType('170-sprinkler-system', 'Sprinklersystem'), // Sprinkler system
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getAutomaticFireAlarmActivationOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentInstallationsAutomaticFireAlarmActivationOptionTypes.fireAlarmButtonsId, 'Brandlarmknappar'),
      new DocumentSelectOptionType(DocumentInstallationsAutomaticFireAlarmActivationOptionTypes.detectorsId, 'Detektorer'),
      new DocumentSelectOptionType(DocumentInstallationsAutomaticFireAlarmActivationOptionTypes.extinguishingSystemId, 'Släcksystem'),
      new DocumentSelectOptionType(DocumentInstallationsAutomaticFireAlarmActivationOptionTypes.otherId, 'Annat')
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getAutomaticFireAlarmPowerSupplyOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentInstallationsAutomaticFireAlarmPowerSupplyOptionTypes.batteryUnitId, 'Batterienhet'),
      new DocumentSelectOptionType(DocumentInstallationsAutomaticFireAlarmPowerSupplyOptionTypes.chargingUnitId, 'Laddningsenhet'),
      new DocumentSelectOptionType(DocumentInstallationsAutomaticFireAlarmPowerSupplyOptionTypes.otherId, 'Annat'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getAutomaticFireAlarmsReceiverOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      // The rescue service, direct connection
      new DocumentSelectOptionType('110-direct-connection', 'Räddningstjänsten, direktkoppling'),
      // The rescue service, via alarm mediators
      new DocumentSelectOptionType('120-alarm-mediators', 'Räddningstjänsten, via larmförmedlare'),
      new DocumentSelectOptionType('130-alarm-company', 'Larmföretag'), // Alarm company
      new DocumentSelectOptionType('140-security-company', 'Vaktbolag'), // Security company
      new DocumentSelectOptionType('150-internally-staffed-place', 'Intern bemannad plats'), // Internally staffed place
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getAutomaticFireAlarmSpecialAdaptationOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-timer-shutdown', 'Tillfällig avställning via timer'), // Temporary shutdown via timer
      // Deviation from recommended detector density
      new DocumentSelectOptionType('120-detector-density', 'Avsteg från rekommenderad detektortäthet'),
      // Alarm storage of transmission to alarm receiver
      new DocumentSelectOptionType('130-alarm-receiver', 'Larmlagring av överföring till larmmottagare'),
      // Alarm storage of activation evacuation alarm
      new DocumentSelectOptionType('140-activation-evacuation-alarm', 'Larmlagring av aktivering utrymningslarm'),
      new DocumentSelectOptionType('150-alarm-signal', 'Larmsignal till kallelsesignalsystem'), // Alarm signal to call signal system
      // Alarm storage of transmission and evacuation alarms
      new DocumentSelectOptionType(
        '160-transmission-evacuation-alarms',
        'Larmlagring av överföring samt utrymningslarm'
      ),
      new DocumentSelectOptionType('170-does-not-exist', 'Finns ej', true), // Does not exist
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getAlarmSignalingOccurOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentInstallationsAlarmSignalingOccurOptionTypes.alarmForInternalOperationId, 'Larm för intern insats'),
      new DocumentSelectOptionType(DocumentInstallationsAlarmSignalingOccurOptionTypes.alarmForRescueServiceId, 'Larm för räddningstjänst eller annan insatsstyrka'),
      new DocumentSelectOptionType(DocumentInstallationsAlarmSignalingOccurOptionTypes.alarmForAttentionId, 'Larm för uppmärksamhet'),
      new DocumentSelectOptionType(DocumentInstallationsAlarmSignalingOccurOptionTypes.evacuationAlarmId, 'Utrymningslarm'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getEvacuationAlarmsPerceivedOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-whole-building', 'Hela byggnaden'), // The whole building
      new DocumentSelectOptionType('120-part-building', 'Del av byggnaden'), // Part of the building
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getEvacuationAlarmsActivationOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentInstallationsEvacuationAlarmsActivationOptionTypes.automaticFireAlarmId, 'Automatiskt brandlarm'), // Automatic fire alarm
      new DocumentSelectOptionType(
        DocumentInstallationsEvacuationAlarmsActivationOptionTypes.automaticFireAlarmAndPushButtonsId,
        'Automatiskt brandlarm samt larmtryckknappar'
      ),
      new DocumentSelectOptionType(DocumentInstallationsEvacuationAlarmsActivationOptionTypes.alarmPushButtonsId, 'Larmtryckknappar'), // Alarm pushbuttons
      new DocumentSelectOptionType(DocumentInstallationsEvacuationAlarmsActivationOptionTypes.sprinklerSystemId, 'Sprinklersystem'), // Sprinkler system
      new DocumentSelectOptionType(DocumentInstallationsEvacuationAlarmsActivationOptionTypes.otherId, 'Annat'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getEvacuationAlarmsSignalOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-optical-signal', 'Optisk signal'), // Optical signal
      new DocumentSelectOptionType('120-acoustic-signal', 'Akustisk signal'), // Acoustic signal
      new DocumentSelectOptionType('130-spoken-message', 'Talat meddelande'), // Spoken message
      new DocumentSelectOptionType('160-tactile-vibration', 'Taktilt/vibration'), // Tactile / vibration
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getEvacuationAlarmsSpecialAdaptationOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      // Music system is switched off when the alarm is activated
      new DocumentSelectOptionType('110-music-system-off', 'Musikanläggning stängs av vid aktiverat larm'),
      // Lighting comes on when the alarm is activated
      new DocumentSelectOptionType('120-lighting-comes', 'Belysning tänds vid aktiverat larm'),
      new DocumentSelectOptionType('130-alarm-storage', 'Larmlagring vid manuell aktivering'), // Alarm storage for manual activation
      new DocumentSelectOptionType('140-does-not-exist', 'Finns ej', true), // Does not exist
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getAutomaticExtinguishingSystemOccurOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentInstallationsAutomaticExtinguishingSystemOccurOptionTypes.automaticWaterSprinklerSystemId, 'Automatisk vattensprinkleranläggning'),
      new DocumentSelectOptionType(DocumentInstallationsAutomaticExtinguishingSystemOccurOptionTypes.residentialSprinklerId, 'Boendesprinkler'),
      new DocumentSelectOptionType(DocumentInstallationsAutomaticExtinguishingSystemOccurOptionTypes.notApplicableId, 'Ej aktuellt', true),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getAutomaticWaterSprinklerMonitoringOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-fully-protected-building', 'Helskydd byggnad'), // Fully protected building
      new DocumentSelectOptionType('120-partially-protected-building', 'Delskydd byggnad'), // Partially protected building
      new DocumentSelectOptionType('130-local-protection', 'Lokalskydd'), // Local protection
      new DocumentSelectOptionType('140-protective-sprinklers', 'Skyddssprinkler'), // Protective sprinklers
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getAutomaticWaterSprinklerAdjustmentOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-pre-release-system', 'Förutlösningssystem'), // Pre-release system
      new DocumentSelectOptionType('120-dry-pipe-system', 'Torrörsystem'), // Dry pipe system
      new DocumentSelectOptionType('130-wall-sprinklers', 'Väggsprinkler'), // Wall sprinklers
      new DocumentSelectOptionType('140-level-sprinklers', 'Nivåsprinkler'), // Level sprinklers
      new DocumentSelectOptionType('150-point-protection', 'Punktskydd ovan undertak'), // Point protection above suspended ceilings
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getAutomaticWaterSprinklerRiskClassOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-lh', 'LH'),
      new DocumentSelectOptionType('120-oh1', 'OH1'),
      new DocumentSelectOptionType('130-oh2', 'OH2'),
      new DocumentSelectOptionType('140-oh3', 'OH3'),
      new DocumentSelectOptionType('150-oh4', 'OH4'),
      new DocumentSelectOptionType('160-hhp1', 'HHP1'),
      new DocumentSelectOptionType('170-hhp2', 'HHP2'),
      new DocumentSelectOptionType('180-hhp3', 'HHP3'),
      new DocumentSelectOptionType('190-hhp4', 'HHP4'),
      new DocumentSelectOptionType('200-hhs1', 'HHS1'),
      new DocumentSelectOptionType('210-hhs2', 'HHS2'),
      new DocumentSelectOptionType('220-hhs3', 'HHS3'),
      new DocumentSelectOptionType('230-hhs4', 'HHS4'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getExtinguishingSystemOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-water-fog', 'Vattendimma'), // Water fog
      new DocumentSelectOptionType('120-gas-extinguishing-agent', 'Gassläckmedel'), // Gas extinguishing agent
      new DocumentSelectOptionType('130-foam-extinguishing-agent', 'Skumsläckmedel'), // Foam extinguishing agent
      new DocumentSelectOptionType('150-powder-extinguishing-agent', 'Pulversläckmedel'), // Powder extinguishing agent
      new DocumentSelectOptionType('160-pyrotechnic', 'Pyroteknisk'), // Pyrotechnic
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getExtinguishingEquipmentFormOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentInstallationsExtinguishingEquipmentFormOptionTypes.handFireExtinguisherId, 'Handbrandsläckare'), // Hand fire extinguisher
      new DocumentSelectOptionType(DocumentInstallationsExtinguishingEquipmentFormOptionTypes.indoorFireHydrantId, 'Inomhusbrandpost'), // Indoor fire hydrant
      new DocumentSelectOptionType(DocumentInstallationsExtinguishingEquipmentFormOptionTypes.fireBlanketId, 'Brandfilt'), // Fire blanket
      new DocumentSelectOptionType(DocumentInstallationsExtinguishingEquipmentFormOptionTypes.doesNotExistId, 'Finns ej', true), // Does not exist
      new DocumentSelectOptionType(DocumentInstallationsExtinguishingEquipmentFormOptionTypes.otherId, 'Annat'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getPremisesFireGasVentilationOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-stairwell', 'Trapphus'), // Stairwell
      new DocumentSelectOptionType('120-wind', 'Vind'), // Wind
      new DocumentSelectOptionType('130-elevator', 'Hiss'), // Elevator
      new DocumentSelectOptionType('140-basement', 'Källare'), // Basement
      new DocumentSelectOptionType('150-garage', 'Garage'), // Garage
      new DocumentSelectOptionType('160-culvert', 'Kulvert'),
      new DocumentSelectOptionType('170-large-section-fire-cell', 'Stor sektion/brandcell'),
      new DocumentSelectOptionType(DocumentInstallations.otherPremisesFireGasVentilationOptionId, 'Annat'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  private getDocumentInstallationsFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.markingsEmergencyLightingIds,
        displayName: 'Vägledande markeringar och nödbelysning förekommer genom',
        type: DocumentFieldType.multiple,
        values: this.getMarkingsEmergencyLightingOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.powerSupplyEmergencyLightingIds,
        displayName: 'Strömförsörjning av nödbelysning (såväl skyltar som separata armaturer) säkerställs genom',
        type: DocumentFieldType.multiple,
        values: this.getPowerSupplyEmergencyLightingOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.markingsEmergencyLightingDescription,
        displayName: 'Utökad beskrivning avseende vägledande markeringar och nödbelysning',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.isFireAlarmsOccur,
        displayName: 'Brandvarnare förekommer',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.hasFireAlarmsFormalRequirements,
        displayName: 'Brandvarnare förekommer - Formellt krav',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.hasFireAlarmsSeriesConnection,
        displayName: 'Seriekoppling',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.fireAlarmsDescription,
        displayName: 'Utökad beskrivning avseende automatiskt brandlarm',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.isAutomaticFireAlarmsOccur,
        displayName: 'Automatiskt brandlarm förekommer',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.hasAutomaticFireAlarmsFormalRequirements,
        displayName: 'Automatiskt brandlarm förekommer - Formellt krav',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning,
        displayName: 'Automatiskt brandlarm förekommer - Formellt krav enligt analytisk dimensionering',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.isAutomaticFireAlarmOwnAmbition,
        displayName: 'Automatiskt brandlarm förekommer - Egen ambition',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.coordinatedFunctionAutomaticFireAlarmRelevantIds,
        displayName: 'Samordnad funktion gentemot automatiskt brandlarm är aktuellt för',
        type: DocumentFieldType.multiple,
        values: this.getCoordinatedFunctionAutomaticFireAlarmRelevantOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmsMonitoringScopeIds,
        displayName: 'Övervakning/omfattning',
        type: DocumentFieldType.multiple,
        values: this.getAutomaticFireAlarmsMonitoringScopeOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmDetekteringIds,
        displayName: 'Detektering',
        type: DocumentFieldType.multiple,
        values: this.getAutomaticFireAlarmDetekteringOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmActivationIds,
        displayName: 'Automatiskt brandlarm förekommer - Aktivering',
        type: DocumentFieldType.multiple,
        values: this.getAutomaticFireAlarmActivationOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmActivationOther,
        displayName: 'Automatiskt brandlarm förekommer - Aktivering - Annat',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmPowerSupplyIds,
        displayName: 'Strömförsörjning',
        type: DocumentFieldType.multiple,
        values: this.getAutomaticFireAlarmPowerSupplyOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmPowerSupplyOther,
        displayName: 'Strömförsörjning - Annat',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmsReceiverIds,
        displayName: 'Larmmottagare',
        type: DocumentFieldType.multiple,
        values: this.getAutomaticFireAlarmsReceiverOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmSpecialAdaptationIds,
        displayName: 'Automatiskt brandlarm förekommer - Särskild anpassning',
        type: DocumentFieldType.multiple,
        values: this.getAutomaticFireAlarmSpecialAdaptationOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmCurrentRegulations,
        displayName: 'Automatiskt brandlarm förekommer - Gällande regelverk',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmConstructionCertificate,
        displayName: 'Anläggarintyg',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmServiceOrientationDrawings,
        displayName: 'Service-/orienteringsritningar',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.locationOfFireProtectionPanelFireAlarmCenter,
        displayName: 'Placering av brandförsvarstablå/brandlarmcentral',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.locationOfInformationTableForFireAlarms,
        displayName: 'Placering av informationstablå(er) för brandlarm (verksamhet)',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.locationOfAlarmStorageTablesForFireAlarms,
        displayName: 'Placering av larmlagringstablå(er) för brandlarm (verksamhet)',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticFireAlarmsDescription,
        displayName: 'Utökad beskrivning avseende automatiskt brandlarm',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.isAlarmSignalingOccur,
        displayName: 'Larmsignalering förekommer',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.hasEvacuationAlarmsFormalRequirements,
        displayName: 'Larmsignalering förekommer - Formellt krav',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning,
        displayName: 'Larmsignalering förekommer - Formellt krav enligt analytisk dimensionering',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.isAlarmSignalingOwnAmbition,
        displayName: 'Larmsignalering förekommer - Egen ambition',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.hasEmergencyMicrophoneForEmergencyService,
        displayName: 'Utrymningsmikrofon för räddningstjänst',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.alarmSignalingOccurIds,
        displayName: 'Larmsignalering förekommer genom',
        type: DocumentFieldType.multiple,
        values: this.getAlarmSignalingOccurOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.alarmSignalPerceivedIds,
        displayName: 'Larmsignalering kan uppfattas i',
        type: DocumentFieldType.multiple,
        values: this.getEvacuationAlarmsPerceivedOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.evacuationAlarmsActivationIds,
        displayName: 'Larmsignalering förekommer - Aktivering',
        type: DocumentFieldType.multiple,
        values: this.getEvacuationAlarmsActivationOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.evacuationAlarmsActivationOther,
        displayName: 'Larmsignalering förekommer - Aktivering - Annat',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.evacuationAlarmsSignalIds,
        displayName: 'Larmsignal',
        type: DocumentFieldType.multiple,
        values: this.getEvacuationAlarmsSignalOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.evacuationAlarmsSpecialAdaptationIds,
        displayName: 'Larmsignalering förekommer - Särskild anpassning',
        type: DocumentFieldType.multiple,
        values: this.getEvacuationAlarmsSpecialAdaptationOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.alarmSignalingCurrentRegulations,
        displayName: 'Larmsignalering förekommer - Gällande regelverk',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.alarmSignalingConstructionCertificate,
        displayName: 'Anläggarintyg larmsignalering',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel,
        displayName: 'Placering av don som tystar larmsignalering vid aktivering av personal',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.evacuationAlarmsDescription,
        displayName: 'Utökad beskrivning avseende utrymningslarm',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.isAutomaticExtinguishingSystemOccur,
        displayName: 'Automatiska släcksystem förekommer',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.hasAutomaticWaterSprinklerFormalRequirements,
        displayName: 'Automatiska släcksystem förekommer - Formellt krav',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.isAutomaticExtinguishingSystemOwnAmbition,
        displayName: 'Automatiska släcksystem förekommer - Egen ambition',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticExtinguishingSystemOccurIds,
        displayName: 'Automatiska släcksystem förekommer genom',
        type: DocumentFieldType.multiple,
        values: this.getAutomaticExtinguishingSystemOccurOptionTypes()
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.monitoringAutomaticExtinguishingSystemIds,
        displayName: 'Övervakning automatiska släcksystem',
        type: DocumentFieldType.multiple,
        values: this.getAutomaticWaterSprinklerMonitoringOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticWaterSprinklerAdjustmentIds,
        displayName: 'Anpassning automatisk vattensprinkler',
        type: DocumentFieldType.multiple,
        values: this.getAutomaticWaterSprinklerAdjustmentOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticWaterSprinklerRiskClassIds,
        displayName: 'Riskklass/sprinklertyp automatisk vattensprinkler',
        type: DocumentFieldType.multiple,
        values: this.getAutomaticWaterSprinklerRiskClassOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.automaticWaterSprinklerOther,
        displayName: 'Övrigt',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.extinguishingSystems,
        displayName: 'Andra typer av släcksystem',
        type: DocumentFieldType.extinguishingSystems,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.extinguishingEquipmentFormIds,
        displayName: 'Släckutrustning förekommer i form av',
        type: DocumentFieldType.multiple,
        values: this.getExtinguishingEquipmentFormOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.extinguishingEquipmentFormOther,
        displayName: 'Släckutrustning förekommer i form av - Annat',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.isFireGasVentilationOccur,
        displayName: 'Brandgasventilation förekommer',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.hasFireGasVentilationFormalRequirements,
        displayName: 'Brandgasventilation förekommer - Formellt krav',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.isFireGasVentilationOwnAmbition,
        displayName: 'Brandgasventilation förekommer - Egen ambition',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.premisesFireGasVentilationIds,
        displayName: 'Följande lokaler har brandgasventilation',
        type: DocumentFieldType.multiple,
        values: this.getPremisesFireGasVentilationOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.premisesFireGasVentilationOther,
        displayName: 'Följande lokaler har brandgasventilation - Annat',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.fireGasVentilationDescription,
        displayName: 'Utökad beskrivning avseende brandgasventilation',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.isOtherFireTechnicalInstallationsOccur,
        displayName: 'Övriga brandtekniska installationer förekommer',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.hasOtherFireTechnicalInstallationsFormalRequirements,
        displayName: 'Övriga brandtekniska installationer förekommer - Formellt krav',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.isOtherFireTechnicalInstallationsOwnAmbition,
        displayName: 'Övriga brandtekniska installationer förekommer - Egen ambition',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionInstallations.otherFireTechnicalInstallationsDescription,
        displayName: 'Utökad beskrivning avseende övriga brandtekniska installationer',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }

  getFireGasesProtectionOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      // Separate ventilation systems for each fire cell
      new DocumentSelectOptionType(
        DocumentAirTreatmentFireGasesProtectionOptionTypes.separateVentilationSystemsForFireCellId,
        'Separata ventilationssystem för varje brandcell'
      ),
      // Fire gas damper alt. Fire / fire damper
      new DocumentSelectOptionType(DocumentAirTreatmentFireGasesProtectionOptionTypes.fireGasDamperAltFireId, 'Brandgasspjäll alt. Brand-/brandgasspjäll'),
      new DocumentSelectOptionType(DocumentAirTreatmentFireGasesProtectionOptionTypes.fansInOperationInCaseOfFireId, 'Fläktar i drift vid brand'), // Fans in operation in case of fire
      new DocumentSelectOptionType(DocumentAirTreatmentFireGasesProtectionOptionTypes.pressureReliefOfDuctSystemId, 'Tryckavlastning av kanalsystem'), // Pressure relief of duct system
      new DocumentSelectOptionType(DocumentAirTreatmentFireGasesProtectionOptionTypes.notConfirmedId, 'Ej fastställt', true), // not confirmed
      new DocumentSelectOptionType(DocumentAirTreatmentFireGasesProtectionOptionTypes.doesNotExistId, 'Finns ej', true), // Does not exist
      new DocumentSelectOptionType(DocumentAirTreatmentFireGasesProtectionOptionTypes.otherId, 'Annat'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getFireControlVentilationSystemOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('10-automatic-fire-alarm', 'Automatiskt brandlarm'), // Automatic fire alarm
      // Detectors belonging to the ventilation system
      new DocumentSelectOptionType('20-ventilation-detectors', 'Detektorer tillhörande ventilationssystemet'),
      new DocumentSelectOptionType('30-no-fire-controls', 'Inga brandstyrningar förekommer'), // There are no fire controls
      new DocumentSelectOptionType('not-established', 'Ej fastställt', true),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  private getDocumentAirtreatmentFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionAirTreatment.fireGasesProtectionOptionIds,
        displayName: 'Följande skyddsmetod tillämpas för skydd mot spridning av brandgaser mellan brandceller',
        type: DocumentFieldType.multiple,
        values: this.getFireGasesProtectionOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionAirTreatment.fireGasesProtectionOther,
        displayName: 'Följande skyddsmetod tillämpas för skydd mot spridning av brandgaser mellan brandceller - Annat',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionAirTreatment.isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs,
        displayName: 'Dokumenterad analytisk dimensionering av tryckavlastning via beräkning förekommer',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionAirTreatment.fireControlVentilationSystemOptionIds,
        displayName: 'Brandstyrning av ventilationssystemet sker via',
        type: DocumentFieldType.multiple,
        values: this.getFireControlVentilationSystemOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionAirTreatment.isSmokeDuctsInside,
        displayName: 'Imkanaler förekommer inom byggnaden',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionAirTreatment.hasDocumentedProtectionSolutionForImkanal,
        displayName: 'Dokumenterad skyddslösning för imkanaler förekommer',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionAirTreatment.isOperatingCardFlowChartFireProtectionFunctionAvailable,
        displayName: 'Driftkort/flödesschema som beskriver ventilationstekniska brandskyddsfunktioner finns tillgängliga',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionAirTreatment.airTreatmentDescription,
        displayName: 'Utökad beskrivning avseende luftbehandlingsinstallationer',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }

  getElevatorFireProtectionOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      // Elevator shaft incl. all openings are included in the same fire cell as stairwells
      new DocumentSelectOptionType(
        '110-elevator-all-openings-included',
        'Hisschakt inkl. samtliga öppningar ingår i samma brandcell som trapphus'
      ),
      // Elevator shaft incl. all openings are part of the same fire cell in two levels
      new DocumentSelectOptionType(
        '120-elevator-all-openings-part',
        'Hisschakt inkl. samtliga öppningar ingår i samma brandcell i två plan'
      ),
      // Elevator shaft is designed as a separate fire cell through elevator doors with fire resistance and fire gas ventilation
      new DocumentSelectOptionType(
        '130-elevator-separate-fire-gas-ventilation',
        'Hisschakt är utformat som egen brandcell genom hissdörrar med brandmotstånd samt brandgasventilation'
      ),
      // Elevator shaft is designed as a separate fire cell through elevator doors with fire resistance and air locks
      new DocumentSelectOptionType(
        '140-elevator-separate-air-locks',
        'Hisschakt är utformat som egen brandcell genom hissdörrar med brandmotstånd samt luftsluss'
      ),
      // Elevator shaft is designed as its own fire cell with fire-rated swing doors
      new DocumentSelectOptionType(
        '150-elevator-fire-rated-swing-doors-analytical-dimensioning',
        'Hisschakt är utformat som egen brandcell med brandklassade slagdörrar (analytisk dimensionering)'
      ),
      // Could not be determined
      new DocumentSelectOptionType('160-could-not-be-determined', 'Har ej kunnat fastställas'),
      new DocumentSelectOptionType(DocumentLifts.otherElevatorFireProtectionOptionId, 'Annat'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getLiftSteeringFunctionOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-lift-steering-function-ja', 'Ja'),
      new DocumentSelectOptionType('120-lift-steering-function-nej', 'Nej'),
      new DocumentSelectOptionType('130-lift-steering-function-not-confirmed', 'Ej fastställt'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getRecallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneOccursOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('lift-recall-function-via-fire-alarm-ja', 'Ja'),
      new DocumentSelectOptionType('lift-recall-function-via-fire-alarm-nej', 'Nej'),
      new DocumentSelectOptionType('lift-recall-function-via-fire-alarm-not-confirmed', 'Ej fastställt'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getLiftFireproofWiringOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-lift-fireproof-wiring-ja', 'Ja'),
      new DocumentSelectOptionType('120-lift-fireproof-wiring-nej', 'Nej'),
      new DocumentSelectOptionType('130-lift-fireproof-wiring-not-confirmed', 'Ej fastställt'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  private getDocumentLiftsFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionLifts.isElevatorExist,
        displayName: 'Hiss finns',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionLifts.elevatorFireProtectionOptionIds,
        displayName: 'Följande lösning tillämpas för skydd mot brand- och brandgasspridning via hisschakt',
        type: DocumentFieldType.multiple,
        values: this.getElevatorFireProtectionOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionLifts.elevatorFireProtectionOther,
        displayName: 'Följande lösning tillämpas för skydd mot brand- och brandgasspridning via hisschakt - Annat',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionLifts.liftSteeringFunctionId,
        displayName: 'Styrfunktion, hissen går vid strömavbrott till närmaste stannplan',
        type: DocumentFieldType.dropdown,
        values: this.getLiftSteeringFunctionOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionLifts.recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId,
        displayName: 'Återkallningsfunktion via brandlarm som styr hissen till evakueringsplan förekommer',
        type: DocumentFieldType.dropdown,
        values: this.getRecallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneOccursOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionLifts.liftFireproofWiringId,
        displayName: 'Brandsäkert förlagt kablage',
        type: DocumentFieldType.dropdown,
        values: this.getLiftFireproofWiringOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionLifts.liftsDesignDescription,
        displayName: 'Utökad beskrivning avseende utformning av hissar',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }

  getRescueServiceResponseTimeOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-less-10-mins', 'Mindre än 10min'), // Less than 10 min
      new DocumentSelectOptionType('120-less-20-mins', 'Mindre än 20min'), // Less than 20 min
      new DocumentSelectOptionType('130-more-20-mins', 'Mer än 20min'), // More than 20 min
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getOpportunitiesAccessibilityOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-street-network', 'Gatunät'), // Street network
      // Street network and special escape route
      new DocumentSelectOptionType('120-special-escape-route', 'Gatunät samt särskild räddningsväg'),
      // Street network, special rescue route and parking space
      new DocumentSelectOptionType('130-parking-space', 'Gatunät, särskild räddningsväg samt uppställningsplats'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getWindAccessOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-hatches', 'Via luckor i tak'), // Via hatches in the ceiling
      new DocumentSelectOptionType('120-facade-doors', 'Via luckor i fasad/gavel'), // Via doors in the facade / gable
      // Via fire-technically separated internal access road
      new DocumentSelectOptionType('130-internal-access-road', 'Via brandteknisk avskild invändig tillträdesväg'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getSolarPanelsLocationOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentOpportunitiesSolarPanelsLocationOptionTypes.facadeId, 'Fasad'),
      new DocumentSelectOptionType(DocumentOpportunitiesSolarPanelsLocationOptionTypes.ceilingId, 'Tak'),
      new DocumentSelectOptionType(DocumentOpportunitiesSolarPanelsLocationOptionTypes.doesNotExistId, 'Finns ej', true),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getExtinguishingWaterAccessOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-municipal-fire-hydrants', 'Kommunala brandposter'), // Municipal fire hydrants
      new DocumentSelectOptionType('120-internal-fire-hydrants', 'Interna brandposter'), // Internal fire hydrants
      new DocumentSelectOptionType('130-extinguishing-water-access-does-not-exist', 'Finns ej', true), // Does not exist
      new DocumentSelectOptionType(DocumentOpportunities.otherExtinguishingWaterAccessOptionId, 'Annat'),
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  private getDocumentOpportunitiesFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.rescueServiceResponseTimeId,
        displayName: 'Räddningstjänstens insatstid till byggnaden är',
        type: DocumentFieldType.dropdown,
        values: this.getRescueServiceResponseTimeOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.opportunitiesAccessibilityId,
        displayName: 'Åtkomlighet sker genom',
        type: DocumentFieldType.dropdown,
        values: this.getOpportunitiesAccessibilityOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.isKeyAccess,
        displayName: 'Tillträde till byggnaden sker med insatsnyckel',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.isKeyStoringOnObject,
        displayName: 'Förvaring av insatsnyckel sker i nyckelskåp på objektet',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.locationOfKeyCabinet,
        displayName: 'Placering av nyckelskåp',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.isCommunicationEquipmentForEvacuationInBuilding,
        displayName: 'Kommunikationsutrustning för utrymningsplatser förekommer inom byggnaden',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.windAccessId,
        displayName: 'Tillträde till vind sker genom',
        type: DocumentFieldType.dropdown,
        values: this.getWindAccessOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.solarPanelsLocationIds,
        displayName: 'Solcellspaneler finns placerade på',
        type: DocumentFieldType.multiple,
        values: this.getSolarPanelsLocationOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.isEmergencyServiceSwitchForSolarCellSystemApplied,
        displayName: 'Räddningstjänstbrytare för solcellsanläggning tillämpas',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.locationOfEmergencyServiceSwitch,
        displayName: 'Placering av räddningstjänstbrytare',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.hasBasementSpecialRequirementsAccess,
        displayName: 'Särskilt krav på tillträde till källare under översta källarplanet',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.hasSpecialRequirementsForMaxDistanceOf50M,
        displayName: 'Särskilda krav på maximalt avstånd 50 meter från angreppsväg vid Vk 5C',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.hasRescueLiftInBuilding,
        displayName: 'Räddningshiss finns i byggnaden',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.isEscapeRouteAvailable,
        displayName: 'Räddningsväg finns',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.isActionPlansAvailable,
        displayName: 'Insatsplaner finns till byggnaden',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.isFireDustIsApplied,
        displayName: 'Branddamm tillämpas',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.hasAscensionLine,
        displayName: 'Stigarledning',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.isAscensionLinePressurized,
        displayName: 'Trycksatt',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.extinguishingWaterAccessIds,
        displayName: 'Tillgång till släckvatten finns genom',
        type: DocumentFieldType.multiple,
        values: this.getExtinguishingWaterAccessOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.extinguishingWaterAccessOther,
        displayName: 'Tillgång till släckvatten finns genom - Annat',
        type: DocumentFieldType.string,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionOpportunities.rescueServicesInterventionDescription,
        displayName: 'Utökad beskrivning avseende räddningstjänstens insatsmöjligheter',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }

  getRisksHeatingOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-district-heating', 'Fjärrvärme'), // District heating
      new DocumentSelectOptionType('120-rock-heat', 'Bergvärme'), // Rock heat
      new DocumentSelectOptionType('130-electric-boiler', 'Elpanna'), // Electric boiler
      new DocumentSelectOptionType('140-oil-pan', 'Oljepanna'), // Oil pan
      new DocumentSelectOptionType('150-gas-boiler', 'Gaspanna'), // Gas boiler
      new DocumentSelectOptionType('160-direct-electricity', 'Direktverkande el'), // Direct electricity
      new DocumentSelectOptionType('170-district-heating-does-not-exist', 'Finns ej', true), // Does not exist
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;

    return result;
  }

  getSpecialRiskOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.otherRiskId, 'Annan risk enligt beskrivning'), // Other risk as described
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.batteryChargingPointId, 'Batteriladdningsplats'), // Battery charging point
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.batteryStorageDevicesId, 'Batterilagringsenheter'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.centralStorageOfInfectiousWasteId, 'Central förvaring av smittförande avfall'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.indoorCisternStorageId, 'Cisternförvaring inomhus'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.outdoorCisternStorageId, 'Cisternförvaring utomhus'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.fumeCupboardId, 'Dragskåp'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.fireplaceId, 'Eldstad'), // Fireplace
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.hazardousChemicalsPersonEnvironmentId, 'Farliga kemikalier (person/miljö)'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.fixedGasSystemsPipelinesId, 'Fasta gassystem, gasledningar'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.handlingFlammableGoodsId, 'Hantering av brandfarlig vara'), // Handling of flammable goods
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.handlingLpgBottlesId, 'Hantering av gasflaskor'), // Handling of LPG bottles
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.highVoltageId, 'Högspänning över 1kV'), // High voltage over 1kV
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.chemicalStorageId, 'Kemikalieförråd'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.laboratoryActivitiesId, 'Laboratorieverksamhet'), // Laboratory activities
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.magnetismId, 'Magnetism'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.cookingDeviceId, 'Matlagningsanordning'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.boilerRoomOver60vmId, 'Pannrum över 60 kW'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.radioactiveSubstancesId, 'Radioaktiva ämnen'),
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.chipSuctionId, 'Spånsug'), // Chip suction
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.externalEditionsId, 'Utvändiga upplag'), // External Editions
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.openLoadingDockId, 'Öppen lastkaj'), // Open loading dock
      new DocumentSelectOptionType(DocumentRisksSpecialRiskOptionTypes.notApplicableId, 'Förekommer ej', true), // Not applicable
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  private getDocumentRisksFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionRisks.risksHeatingTypeIds,
        displayName: 'Uppvärmning sker genom',
        type: DocumentFieldType.multiple,
        values: this.getRisksHeatingOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionRisks.specialRiskTypeIds,
        displayName: 'Särskilda risker förekommer i form av',
        type: DocumentFieldType.multiple,
        values: this.getSpecialRiskOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionRisks.hasTimerOrStoveGuard,
        displayName: 'Timer/Spisvakt',
        type: DocumentFieldType.boolean,
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionRisks.specialRisksDescription,
        displayName: 'Utökad beskrivning avseende skydd mot uppkomst av brand och särskilda risker',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }

  getWorthyProtectedObjectOptionTypes(): StringMap<DocumentSelectOptionType> {
    const types = [
      new DocumentSelectOptionType('110-data', 'Data/IT'), // Data / IT
      new DocumentSelectOptionType('120-tele', 'Tele'), // Tele
      new DocumentSelectOptionType('130-electronics', 'Elektronik'), // Electronics
      new DocumentSelectOptionType('140-electricity-generation', 'Elproduktion'), // Electricity generation
      new DocumentSelectOptionType('150-heat-production', 'Värmeproduktion'), // Heat production
      new DocumentSelectOptionType('160-renrum', 'Renrum'), // Renrum
      new DocumentSelectOptionType('170-medical-equipment', 'Medicinsk utrustning'), // Medical equipment
      new DocumentSelectOptionType('180-process-control', 'Processtyrning'), // Process control
      new DocumentSelectOptionType('190-economic-values', 'Ekonomiska värden'), // Economic values
      new DocumentSelectOptionType('200-cultural-value', 'Kulturvärde'), // Cultural value
      new DocumentSelectOptionType('210-other-activities', 'Annan verksamhet, se detaljnotering'), // Other activities, see detailed listing
      new DocumentSelectOptionType('230-not-present', 'Förekommer ej', true), // Not applicable
    ];

    const result = _.keyBy(types, 'id') as StringMap<DocumentSelectOptionType>;
    return result;
  }

  private getDocumentProtectedObjectsFields(): DocumentSectionField[] {
    const documentSectionFields: DocumentSectionField[] = [
      new DocumentSectionField({
        fieldId: DocumentSectionProtectedObjects.worthyProtectedObjectTypeIds,
        displayName: 'Särskilt skyddsvärda objekt förekommer i form av',
        type: DocumentFieldType.multiple,
        values: this.getWorthyProtectedObjectOptionTypes(),
      }),
      new DocumentSectionField({
        fieldId: DocumentSectionProtectedObjects.protectedObjectsDescription,
        displayName: 'Utökad beskrivning avseende särskilt skyddsvärda objekt',
        type: DocumentFieldType.string,
      }),
    ];

    return documentSectionFields;
  }
}
