<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'Objekt'"
    [documentId]="document?.id"
    [isCompleted]="document?.object.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Fastighetsbeteckning">
              <mat-label title="Name Of Property"
                >Fastighetsbeteckning</mat-label
              >
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.object.propertyName"
                #propertyName="ngModel"
                name="propertyName"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[propertyName.name]"-->
<!--                [class.text-secondary-primary]="notes[propertyName.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[propertyName.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="propertyName.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Kommun">
              <mat-label title="Municipality">Kommun</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.object.municipality"
                #municipality="ngModel"
                name="municipality"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[municipality.name]"-->
<!--                [class.text-secondary-primary]="notes[municipality.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[municipality.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="municipality.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Område">
              <mat-label title="Area">Område</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.object.area"
                #area="ngModel"
                name="area"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[area.name]"-->
<!--                [class.text-secondary-primary]="notes[area.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[area.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="area.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Byggnad">
              <mat-label title="Building">Byggnad</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.object.building"
                #building="ngModel"
                name="building"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[building.name]"-->
<!--                [class.text-secondary-primary]="notes[building.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[building.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="building.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Yta (m²)">
              <mat-label title="Area (m²)">Yta (m²)</mat-label>
              <input
                matInput
                type="text"
                required
                appFloatDigitOnly
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.object.areaM2"
                #areaM2="ngModel"
                name="areaM2"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[areaM2.name]"-->
<!--                [class.text-secondary-primary]="notes[areaM2.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[areaM2.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="areaM2.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Byggår">
              <mat-label title="Construction Year"
                >Byggår</mat-label
              >
              <input
                matInput
                type="number"
                min="0"
                step="1"
                required
                appDigitOnly
                [max]="maxConstructionYearValue"
                [appMax]="maxConstructionYearValue"
                [appMaxList]="renovationYears"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.object.constructionYear"
                #constructionYear="ngModel"
                name="constructionYear"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[constructionYear.name]"-->
<!--                [class.text-secondary-primary]="notes[constructionYear.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[constructionYear.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="constructionYear.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
              <mat-error>
                <div *ngIf="!!constructionYear.errors?.max">Maxvärde: {{ maxConstructionYearValue }}</div>
                <div *ngIf="!!constructionYear.errors?.maxList">Byggår måste vara tidigare än Om/tillbyggnadsår.</div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12">
            <div title="Renovations / extensions">Om-/tillbyggnadsår</div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="d-flex w-100">
              <div class="flex-grow-1 d-flex flex-wrap">
                <mat-form-field class="renovations-year mr-3" title="År">
                  <mat-label title="Renovations Year"
                    >År</mat-label
                  >
                  <input
                    matInput
                    type="number"
                    appDigitOnly
                    [disabled]="!hasEditPermissions"
                    [(ngModel)]="data.renovationsYear"
                    [min]="minRenovationYearValue"
                    [max]="maxRenovationYearValue"
                    [appMin]="minRenovationYearValue"
                    [appMax]="maxRenovationYearValue"
                    #renovationsYear="ngModel"
                    id="renovationsYear"
                    name="renovationsYear"
                  />
                  <mat-error>
                    <div *ngIf="!!renovationsYear.errors?.min">Årtal måste vara senare än Byggår.</div>
                    <div *ngIf="!!renovationsYear.errors?.max">Maxvärde: {{ maxRenovationYearValue }}</div>
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="renovation-remedy-container flex-grow-1" title="Åtgärd">
                  <mat-label title="Renovations Remedy"
                    >Åtgärd</mat-label
                  >
                  <input
                    matInput
                    [disabled]="!hasEditPermissions"
                    [(ngModel)]="data.renovationsRemedy"
                    #renovationsRemedy="ngModel"
                    id="renovationsRemedy"
                    name="renovationsRemedy"
                  />
                </mat-form-field>
              </div>

              <button
                mat-icon-button
                (click)="onAddRenovation($event)"
                class="add-button"
                [disabled]="
                  !data.renovationsYear || !data.renovationsRemedy || !!renovationsYear.errors || !hasEditPermissions
                "
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card mt-1">
              <div class="card-body p-3">
                <div
                  class="w-100 d-flex border rounded px-3 py-2 mb-2 mr-3"
                  *ngFor="let renovation of document.object.renovations"
                  [ngClass]="{'invalid-field': renovation.year < document.object.constructionYear}"
                >
                  <div class="flex-grow-1">{{ renovation.year }} - {{ renovation.remedy }}</div>

                  <button
                    mat-icon-button
                    *ngIf="hasEditPermissions"
                    (click)="onDeleteRenovation(renovation)"
                    class="small-button align-self-center"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>

                <div *ngIf="document.object.renovations | isEmpty" class="w-100 text-muted text-center">
                  Om-/Tillbyggnader förekommer ej
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12">
            <mat-form-field class="w-100" title="Detaljbeskrivning avseende ändringar, underlag etc">
              <mat-label
                title="Detailed description regarding changes, documentation, etc."
                >Detaljbeskrivning avseende ändringar, underlag etc
              </mat-label>
              <textarea
                matInput
                rows="10"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.object.changesDescription"
                #changesDescription="ngModel"
                name="changesDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[changesDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[changesDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[changesDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="changesDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
