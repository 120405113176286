<h1 mat-dialog-title>Återaktivera kund</h1>
<div mat-dialog-content>
  <div class="modal-body">
    <div>
      <div>
        Vill du återaktivera "{{ organization?.name }}"?
      </div>
      <div class="mt-4">
        <div class="form-inline">
          <label class="mr-2" for="confirmationText">
            <ng-container>Skriv </ng-container>
            "{{ expectedConfirmationText }}"
            <ng-container> för att återaktivera</ng-container>
          </label>
          <input
            type="text"
            class="form-control form-control-sm"
            [(ngModel)]="confirmationText"
            name="confirmationText"
            id="confirmationText"
            cdkFocusInitial
            (keydown.enter)="onActivate()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-raised-button (click)="onCancel()">Avbryt</button>
  <button class="activate-btn" mat-raised-button (click)="onActivate()" [disabled]="!isConfirmed">
    Återaktivera
  </button>
</div>
