<h1 mat-dialog-title>Vill du spara?</h1>
<div mat-dialog-content class="discard-data-dialog">
  <div class="modal-body">
    <div class="mb-3">
      Dina ändringar kommer att gå förlorade.
    </div>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
  <button mat-raised-button (click)="onDoNotSave()">Spara inte</button>
  <div>
    <button mat-raised-button (click)="onCancel()">Avbryt</button>
    <button class="confirm-btn" mat-raised-button (click)="onSave()" cdkFocusInitial>Spara</button>
  </div>
</div>
