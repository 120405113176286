import { IDocumentItem } from './document';

export class DocumentFire implements IDocumentItem {
  isBuildingDividedIntoFireCells?: boolean;
  isFireCellExceeds1250m2?: boolean;
  isMoreThanTwoPlanes?: boolean;
  isSpecialFireTechnicalClass?: boolean;
  isSeveralBusinessClasses?: boolean;
  hasDoorsOrHatchesInFireSeparationConstruction?: boolean;
  hasInstallationDeviceOnDoors?: boolean;
  hasAutomaticOpeningOnDoors?: boolean;
  hasWindowsInFireRetardantConstruction?: boolean;
  hasFireWindowAtAngle?: boolean;
  hasFireLowHighPart?: boolean;
  hasFireRetardantCeilingsFloors?: boolean;
  hasAirFireLock?: boolean;
  hasCoveredYardBalconies?: boolean;
  hasFireCellInAttic?: boolean;
  hasFireTechnicalSeparationOfAeratedEaves?: boolean;
  hasWashingAndGarbageChute?: boolean;
  hasPneumaticTube?: boolean;
  fireRequirementIds: string[];

  sectioningThroughFirewallIds: string[];
  fireTechnicalClassFirewallIds: string[];
  olderFireTechnicalClassIds: string[];
  fireCellMeetTechnicalClassIds: string[];

  fireSpecialConditionsDescription?: string;
  fireExtendedDescription?: string;
  isCompleted: boolean;
  isValid: boolean;

  constructor({
    isBuildingDividedIntoFireCells,
    isFireCellExceeds1250m2,
    isMoreThanTwoPlanes,
    isSpecialFireTechnicalClass,
    isSeveralBusinessClasses,
    hasDoorsOrHatchesInFireSeparationConstruction,
    hasInstallationDeviceOnDoors,
    hasAutomaticOpeningOnDoors,
    hasWindowsInFireRetardantConstruction,
    hasFireWindowAtAngle,
    hasFireLowHighPart,
    hasFireRetardantCeilingsFloors,
    hasAirFireLock,
    hasCoveredYardBalconies,
    hasFireCellInAttic,
    hasFireTechnicalSeparationOfAeratedEaves,
    hasWashingAndGarbageChute,
    hasPneumaticTube,
    fireRequirementIds,
    sectioningThroughFirewallIds,
    fireTechnicalClassFirewallIds,
    olderFireTechnicalClassIds,
    fireCellMeetTechnicalClassIds,
    fireSpecialConditionsDescription,
    fireExtendedDescription,
    isCompleted,
    isValid,
  }: Partial<DocumentFire> = {}) {
    this.isBuildingDividedIntoFireCells = isBuildingDividedIntoFireCells;
    this.isFireCellExceeds1250m2 = isFireCellExceeds1250m2;
    this.isMoreThanTwoPlanes = isMoreThanTwoPlanes;
    this.isSpecialFireTechnicalClass = isSpecialFireTechnicalClass;
    this.isSeveralBusinessClasses = isSeveralBusinessClasses;
    this.hasDoorsOrHatchesInFireSeparationConstruction = hasDoorsOrHatchesInFireSeparationConstruction;
    this.hasInstallationDeviceOnDoors = hasInstallationDeviceOnDoors;
    this.hasAutomaticOpeningOnDoors = hasAutomaticOpeningOnDoors;
    this.hasWindowsInFireRetardantConstruction = hasWindowsInFireRetardantConstruction;
    this.hasFireWindowAtAngle = hasFireWindowAtAngle;
    this.hasFireLowHighPart = hasFireLowHighPart;
    this.hasFireRetardantCeilingsFloors = hasFireRetardantCeilingsFloors;
    this.hasAirFireLock = hasAirFireLock;
    this.hasCoveredYardBalconies = hasCoveredYardBalconies;
    this.hasFireCellInAttic = hasFireCellInAttic;
    this.hasFireTechnicalSeparationOfAeratedEaves = hasFireTechnicalSeparationOfAeratedEaves;
    this.hasWashingAndGarbageChute = hasWashingAndGarbageChute;
    this.hasPneumaticTube = hasPneumaticTube;
    this.fireRequirementIds = fireRequirementIds || [];
    this.sectioningThroughFirewallIds = sectioningThroughFirewallIds || [];
    this.fireTechnicalClassFirewallIds = fireTechnicalClassFirewallIds || [];
    this.olderFireTechnicalClassIds = olderFireTechnicalClassIds || [];
    this.fireCellMeetTechnicalClassIds = fireCellMeetTechnicalClassIds || [];
    this.fireSpecialConditionsDescription = fireSpecialConditionsDescription;
    this.fireExtendedDescription = fireExtendedDescription;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
