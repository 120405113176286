import _ from 'lodash';
import {
  DocumentInspectionConditions,
  DocumentInstallationsExtinguishingEquipmentFormOptionTypes,
  DocumentRisksSpecialRiskOptionTypes
} from './shared';
import {
  Document,
  DocumentActionOpportunitiesAccessibilityOptionTypes,
  DocumentActionOpportunitiesExtinguishingWaterAccessOptionTypes,
  DocumentActionOpportunitiesSolarPanelsLocationOptionTypes,
  DocumentActionOpportunitiesWindAccessOptionTypes,
  DocumentBuildingOccuredActivityOptionTypes,
  DocumentAirTreatmentFireGasesProtectionOptionTypes,
  DocumentInstallationsMarkingsEmergencyLightingOptionTypes,
  DocumentInstallationsPowerSupplyEmergencyLightingOptionTypes,
} from 'src/app/documents/shared';

export class DocumentsInspectionConditionsBuilder {
  private conditions: DocumentInspectionConditions;

  constructor(private document: Document) {
    this.conditions = new DocumentInspectionConditions();
  }

  build(): DocumentInspectionConditions {
    const condition = this.buildSection_1_1()
      .buildSection_2_1()
      .buildSection_2_2()
      .buildSection_3_1()
      .buildSection_3_2()
      .buildSection_3_3()
      .buildSection_3_4()
      .buildSection_3_5()
      .buildSection_3_6()
      .buildSection_4_1()
      .buildSection_5_1()
      .buildSection_5_2()
      .buildSection_5_3()
      .buildSection_5_4()
      .buildSection_5_5()
      .buildSection_6_2()
      .buildSection_6_3()
      .buildSection_6_4().conditions;
    return condition;
  }

  private buildSection_1_1(): DocumentsInspectionConditionsBuilder {
    // 1.1
    this.conditions.hasFunctionOfEscapeRoutesIndicativeMarkingCondition = this.document.evacuation.isEvacuationForDisabilitiesPeople;
    this.conditions.hasFunctionOfEscapeRoutesAccessCondition = this.document.evacuation.isEvacuationForDisabilitiesPeople;
    this.conditions.hasFunctionOfEscapeRoutesCommunicationEquipmentCondition = this.document.evacuation.isCommunicationEquipmentEvacuation;
    this.conditions.hasFunctionOfEscapeRoutesMarkingOfEvacuationSiteCondition = this.document.evacuation.isEvacuationForDisabilitiesPeople;
    this.conditions.hasEvacuationPlanReadabilityMechanicalImpactCondition = this.isContainAnyValues(
      this.document.building.occuredBuildingActivityIds,
      DocumentBuildingOccuredActivityOptionTypes.vk1Id,
      DocumentBuildingOccuredActivityOptionTypes.vk2aId,
      DocumentBuildingOccuredActivityOptionTypes.vk2bId,
      DocumentBuildingOccuredActivityOptionTypes.vk2cId,
      DocumentBuildingOccuredActivityOptionTypes.vk4Id,
      DocumentBuildingOccuredActivityOptionTypes.vk5aId,
      DocumentBuildingOccuredActivityOptionTypes.vk5bId,
      DocumentBuildingOccuredActivityOptionTypes.vk5cId
    );
    this.conditions.hasFunctionOfEscapeRoutesCondition =
      this.conditions.hasFunctionOfEscapeRoutesIndicativeMarkingCondition ||
      this.conditions.hasFunctionOfEscapeRoutesAccessCondition ||
      this.conditions.hasFunctionOfEscapeRoutesCommunicationEquipmentCondition ||
      this.conditions.hasFunctionOfEscapeRoutesMarkingOfEvacuationSiteCondition ||
      this.conditions.hasEvacuationPlanReadabilityMechanicalImpactCondition;

    return this;
  }

  private buildSection_2_1(): DocumentsInspectionConditionsBuilder {
    // 2.1
    this.conditions.hasRegularControlOfChangeThroughWallCoveringsCondition = this.document.fire.isBuildingDividedIntoFireCells;
    this.conditions.hasFireSpreadWithinFireCellsCondition = this.conditions.hasRegularControlOfChangeThroughWallCoveringsCondition;

    return this;
  }

  private buildSection_2_2(): DocumentsInspectionConditionsBuilder {
    // 2.2
    this.conditions.hasInstallationDeviceOnDoorsWithinFireCellBoundaryCondition = this.document.fire.hasInstallationDeviceOnDoors;
    this.conditions.hasAutomaticDoorOpeningFunctionOnDoorsWithinFireCellBoundaryCondition = this.document.fire.hasAutomaticOpeningOnDoors;
    this.conditions.hasGlassSectionsWithinFireCellBoundaryCondition = this.document.fire.hasWindowsInFireRetardantConstruction;
    this.conditions.hasVentilationDuctsCondition = this.document.fire.isBuildingDividedIntoFireCells;
    this.conditions.hasGlandsForCablesPipesDuctsCondition = this.document.fire.isBuildingDividedIntoFireCells;
    this.conditions.hasWallsAndLoadBearingStructuresCondition = this.document.fire.isBuildingDividedIntoFireCells;
    this.conditions.hasFireSpreadBetweenFireCellsCondition =
      this.conditions.hasInstallationDeviceOnDoorsWithinFireCellBoundaryCondition ||
      this.conditions.hasAutomaticDoorOpeningFunctionOnDoorsWithinFireCellBoundaryCondition ||
      this.conditions.hasGlassSectionsWithinFireCellBoundaryCondition ||
      this.conditions.hasVentilationDuctsCondition ||
      this.conditions.hasGlandsForCablesPipesDuctsCondition ||
      this.conditions.hasWallsAndLoadBearingStructuresCondition;

    return this;
  }

  private buildSection_3_1(): DocumentsInspectionConditionsBuilder {
    // 3.1
    this.conditions.hasSignageEmergencyLightingIndicativeMarkingsCondition = this.isContainAnyValues(
      this.document.installations.markingsEmergencyLightingIds,
      DocumentInstallationsMarkingsEmergencyLightingOptionTypes.illuminatedWithEmergencyPowerId,
      DocumentInstallationsMarkingsEmergencyLightingOptionTypes.illuminatedSignsId,
      DocumentInstallationsMarkingsEmergencyLightingOptionTypes.illuminatedFloorMarkingsId
    );

    this.conditions.hasSignageEmergencyLightingSeparateEmergencyLightinguminairesCondition = this.isContainAnyValues(
      this.document.installations.powerSupplyEmergencyLightingIds,
      DocumentInstallationsPowerSupplyEmergencyLightingOptionTypes.localBatteryBackupId
    );

    this.conditions.hasSignageEmergencyLightingEmergencyPowerSupplyBatteryBackupCondition = this.isContainAnyValues(
      this.document.installations.powerSupplyEmergencyLightingIds,
      DocumentInstallationsPowerSupplyEmergencyLightingOptionTypes.centralBatteryBackupId
    );

    this.conditions.hasSignageEmergencyLightingEmergencyPowerSupplyReservePowerSupplyCondition = this.isContainAnyValues(
      this.document.installations.powerSupplyEmergencyLightingIds,
      DocumentInstallationsPowerSupplyEmergencyLightingOptionTypes.reservePowerUnitId
    );

    this.conditions.hasGeneralLightingStaircasesAndCorridorsInBuildingsWithMoreThanTwoStoreysCondition =
      this.document.building.numberOfStoreys > 0;

    this.conditions.hasGeneralLightingLightingOfIlluminatedSignsCondition = this.isContainAnyValues(
      this.document.installations.markingsEmergencyLightingIds,
      DocumentInstallationsMarkingsEmergencyLightingOptionTypes.illuminatedSignsId,
      DocumentInstallationsMarkingsEmergencyLightingOptionTypes.illuminatedFloorMarkingsId
    );

    this.conditions.hasWarningMarkingSignageAndEmergencyLightingCondition =
      this.conditions.hasSignageEmergencyLightingIndicativeMarkingsCondition ||
      this.conditions.hasSignageEmergencyLightingSeparateEmergencyLightinguminairesCondition ||
      this.conditions.hasSignageEmergencyLightingEmergencyPowerSupplyBatteryBackupCondition ||
      this.conditions.hasSignageEmergencyLightingEmergencyPowerSupplyReservePowerSupplyCondition ||
      this.conditions.hasGeneralLightingStaircasesAndCorridorsInBuildingsWithMoreThanTwoStoreysCondition ||
      this.conditions.hasGeneralLightingLightingOfIlluminatedSignsCondition;

    return this;
  }

  private buildSection_3_2(): DocumentsInspectionConditionsBuilder {
    // 3.2
    this.conditions.hasAutomaticFireAlarmAndEvacuationAlarmCondition = this.document.installations.isAutomaticFireAlarmsOccur;

    return this;
  }

  private buildSection_3_3(): DocumentsInspectionConditionsBuilder {
    // 3.3
    this.conditions.hasAutomaticWaterSprinklerSystemCondition = this.document.installations.isAutomaticExtinguishingSystemOccur;

    return this;
  }

  private buildSection_3_4(): DocumentsInspectionConditionsBuilder {
    // 3.4
    this.conditions.hasFixedExtinguishingSystemsForIncreasedPropertyProtectionCondition =
      this.document.installations.extinguishingSystems?.length > 0;

    return this;
  }

  private buildSection_3_5(): DocumentsInspectionConditionsBuilder {
    // 3.5
    this.conditions.hasFireFightingEquipmentForPersonsOnSiteCondition = this.doesNotContainAnyValues(
      this.document.installations.extinguishingEquipmentFormIds,
      DocumentInstallationsExtinguishingEquipmentFormOptionTypes.otherId,
      DocumentInstallationsExtinguishingEquipmentFormOptionTypes.doesNotExistId,
    );

    return this;
  }

  private buildSection_3_6(): DocumentsInspectionConditionsBuilder {
    // 3.6
    this.conditions.hasFireGasVentilationCondition = this.document.installations.isFireGasVentilationOccur;

    return this;
  }

  private buildSection_4_1(): DocumentsInspectionConditionsBuilder {
    // 4.1
    this.conditions.hasVentilationSystemAnnualReviewOfFireProtectionFunctionsCondition = this.doesNotContainAnyValues(
      this.document.airtreatment.fireGasesProtectionOptionIds,
      DocumentAirTreatmentFireGasesProtectionOptionTypes.separateVentilationSystemsForFireCellId,
      DocumentAirTreatmentFireGasesProtectionOptionTypes.notConfirmedId,
      DocumentAirTreatmentFireGasesProtectionOptionTypes.doesNotExistId
    );

    this.conditions.hasVentilationSystemFireDamperCondition = this.isContainAnyValues(
      this.document.airtreatment.fireGasesProtectionOptionIds,
      DocumentAirTreatmentFireGasesProtectionOptionTypes.fireGasDamperAltFireId
    );

    this.conditions.hasVentilationSystemMistAndSmokeDuctsCondition = this.document.airtreatment.isSmokeDuctsInside;

    this.conditions.hasFanFunctionForFansInOperationCondition = this.isContainAnyValues(
      this.document.airtreatment.fireGasesProtectionOptionIds,
      DocumentAirTreatmentFireGasesProtectionOptionTypes.fansInOperationInCaseOfFireId
    );

    this.conditions.hasVentilationSystemCondition =
      this.conditions.hasVentilationSystemAnnualReviewOfFireProtectionFunctionsCondition ||
      this.conditions.hasVentilationSystemFireDamperCondition ||
      this.conditions.hasVentilationSystemMistAndSmokeDuctsCondition ||
      this.conditions.hasFanFunctionForFansInOperationCondition;

    return this;
  }

  private buildSection_5_1(): DocumentsInspectionConditionsBuilder {
    // 5.1
    this.conditions.hasAccessingBuildingsDrivewaysAndParkingSpacesForRescueServiceCondition = this.isContainAnyValues(
      [this.document.opportunities.opportunitiesAccessibilityId],
      DocumentActionOpportunitiesAccessibilityOptionTypes.streetNetworkAndSpecialEscapeRouteId,
      DocumentActionOpportunitiesAccessibilityOptionTypes.streetNetworkSpecialRescueRouteAndParkingSpaceId
    );

    this.conditions.hasAccessingBuildingsBetKeyIsAppliedCondition = this.document.opportunities.isKeyAccess;

    this.conditions.hasAccessingBuildingsRoofSurfacesCondition = this.isContainAnyValues(
      [this.document.opportunities.windAccessId],
      DocumentActionOpportunitiesWindAccessOptionTypes.viaHatchesInTheCeilingId
    );

    this.conditions.hasAccessingBuildingsCondition =
      this.conditions.hasAccessingBuildingsDrivewaysAndParkingSpacesForRescueServiceCondition ||
      this.conditions.hasAccessingBuildingsBetKeyIsAppliedCondition ||
      this.conditions.hasAccessingBuildingsRoofSurfacesCondition;

    return this;
  }

  private buildSection_5_2(): DocumentsInspectionConditionsBuilder {
    // 5.2
    this.conditions.hasRescueElevatorCondition = this.document.opportunities.hasRescueLiftInBuilding;

    return this;
  }

  private buildSection_5_3(): DocumentsInspectionConditionsBuilder {
    // 5.3
    this.conditions.hasActionPlansCondition = this.document.opportunities.isActionPlansAvailable;

    return this;
  }

  private buildSection_5_4(): DocumentsInspectionConditionsBuilder {
    // 5.4
    this.conditions.hasExtinguishingWaterFireHydrantsForRescueServiceCondition = this.isContainAnyValues(
      this.document.opportunities.extinguishingWaterAccessIds,
      DocumentActionOpportunitiesExtinguishingWaterAccessOptionTypes.internalFireHydrantsId
    );

    this.conditions.hasExtinguishingWaterFirePondAndParkingSpaceCondition = this.document.opportunities.isFireDustIsApplied;

    this.conditions.hasExtinguishingWaterSignageAndMarkingsCondition =
      this.document.opportunities.isFireDustIsApplied ||
      this.isContainAnyValues(
        this.document.opportunities.extinguishingWaterAccessIds,
        DocumentActionOpportunitiesExtinguishingWaterAccessOptionTypes.internalFireHydrantsId
      );

    this.conditions.hasExtinguishingWaterCondition =
      this.conditions.hasExtinguishingWaterFireHydrantsForRescueServiceCondition ||
      this.conditions.hasExtinguishingWaterFirePondAndParkingSpaceCondition ||
      this.conditions.hasExtinguishingWaterSignageAndMarkingsCondition;

    return this;
  }

  private buildSection_5_5(): DocumentsInspectionConditionsBuilder {
    // 5.5
    this.conditions.hasLaddersDryRiserLineCondition = this.document.opportunities.hasAscensionLine;
    this.conditions.hasLaddersPressurizedRiserLineAuditInspectionCondition = this.document.opportunities.isAscensionLinePressurized;
    this.conditions.hasLaddersPressurizedRiserLineServiceAndMaintenanceCondition = this.document.opportunities.isAscensionLinePressurized;
    this.conditions.hasLaddersConnectionAndOutletPointsForExtinguishingWaterCondition = this.document.opportunities.hasAscensionLine;
    this.conditions.hasLaddersSignageAndMarkingsCondition = this.document.opportunities.hasAscensionLine;

    this.conditions.hasLaddersCondition =
      this.conditions.hasLaddersDryRiserLineCondition ||
      this.conditions.hasLaddersPressurizedRiserLineAuditInspectionCondition ||
      this.conditions.hasLaddersPressurizedRiserLineServiceAndMaintenanceCondition ||
      this.conditions.hasLaddersConnectionAndOutletPointsForExtinguishingWaterCondition ||
      this.conditions.hasLaddersSignageAndMarkingsCondition;

    return this;
  }

  private buildSection_6_2(): DocumentsInspectionConditionsBuilder {
    // 6.2
    this.conditions.hasSpecialRisksCondition = this.document.risks.specialRiskTypeIds.length !== 0
      ? this.document.risks.specialRiskTypeIds.some(riskType => riskType !== DocumentRisksSpecialRiskOptionTypes.notApplicableId)
      : this.conditions.hasSpecialRisksCondition;

    this.conditions.hasRisksHandlingFlammableGoodsCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.handlingFlammableGoodsId
    );

    this.conditions.hasRisksHandlingLpgBottlesCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.handlingLpgBottlesId
    );

    this.conditions.hasRisksLaboratoryActivitiesCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.laboratoryActivitiesId
    );

    this.conditions.hasRisksBatteryChargingPointCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.batteryChargingPointId
    );

    this.conditions.hasRisksOpenLoadingDockCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.openLoadingDockId
    );

    this.conditions.hasRisksChipSuctionCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.chipSuctionId
    );

    this.conditions.hasRisksExternalEditionsCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.externalEditionsId
    );

    this.conditions.hasRisksFireplaceCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.fireplaceId
    );

    this.conditions.hasRisksHighVoltageCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.highVoltageId
    );

    this.conditions.hasRisksOtherRiskCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.otherRiskId
    );

    this.conditions.hasRisksFumeCupboardCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.fumeCupboardId
    );

    this.conditions.hasRisksIndoorCisternStorageCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.indoorCisternStorageId
    );

    this.conditions.hasRisksOutdoorCisternStorageCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.outdoorCisternStorageId
    );

    this.conditions.hasRisksFixedGasSystemsPipelinesCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.fixedGasSystemsPipelinesId
    );

    this.conditions.hasRisksBoilerRoomOver60vmCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.boilerRoomOver60vmId
    );

    this.conditions.hasRisksRadioactiveSubstancesCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.radioactiveSubstancesId
    );

    this.conditions.hasRisksBatteryStorageDevicesCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.batteryStorageDevicesId
    );

    this.conditions.hasRisksHazardousChemicalsPersonEnvironmentCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.hazardousChemicalsPersonEnvironmentId
    );

    this.conditions.hasRisksMagnetismCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.magnetismId
    );

    this.conditions.hasRisksCookingDeviceCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.cookingDeviceId
    );

    this.conditions.hasRisksChemicalStorageCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.chemicalStorageId
    );

    this.conditions.hasRisksCentralStorageOfInfectiousWasteCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.centralStorageOfInfectiousWasteId
    );

    return this;
  }

  private buildSection_6_3(): DocumentsInspectionConditionsBuilder {
    // 6.3
    this.conditions.hasElectricalInstallationsBatteryChargingPointsCanGenerateGasCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.batteryChargingPointId
    );

    this.conditions.hasElectricalInstallationsSolarCellSystemStatusOfInstallationCondition = this.isContainAnyValues(
      this.document.opportunities.solarPanelsLocationIds,
      DocumentActionOpportunitiesSolarPanelsLocationOptionTypes.ceilingId,
      DocumentActionOpportunitiesSolarPanelsLocationOptionTypes.facadeId
    );

    this.conditions.hasElectricalInstallationsCondition =
      this.conditions.hasElectricalInstallationsBatteryChargingPointsCanGenerateGasCondition ||
      this.conditions.hasElectricalInstallationsSolarCellSystemStatusOfInstallationCondition;

    return this;
  }

  private buildSection_6_4(): DocumentsInspectionConditionsBuilder {
    // 6.4
    this.conditions.hasExternalStorageFireContainersCompactorsWarehousesEnvironmentalStationsCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.externalEditionsId
    );

    this.conditions.hasExternalStorageFireCargoBerthCondition = this.isContainAnyValues(
      this.document.risks.specialRiskTypeIds,
      DocumentRisksSpecialRiskOptionTypes.openLoadingDockId
    );

    this.conditions.hasExternalStorageFireCondition =
      this.conditions.hasExternalStorageFireContainersCompactorsWarehousesEnvironmentalStationsCondition ||
      this.conditions.hasExternalStorageFireCargoBerthCondition;

    return this;
  }

  private isContainAnyValues(list: string[], ...values: string[]) {
    if (list == null || list.length === 0 || values == null) {
      return false;
    }
    const presents = _.intersectionWith<string, string>(list, values, _.isEqual);

    return presents.length > 0;
  }

  private doesNotContainAnyValues(list: string[], ...values: string[]) {
    if (list == null || list.length === 0 || values == null) {
      return false;
    }
    return !this.isContainAnyValues(list, ...values);
  }
}
