import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StringUtils } from 'src/app/shared';
import { documentsConfig } from '../documents.config';
import { Document } from '../shared';

@Component({
  selector: 'app-delete-document-confirmation-modal',
  templateUrl: './delete-document-confirmation-modal.component.html',
  styleUrls: ['./delete-document-confirmation-modal.component.scss'],
})
export class DeleteDocumentConfirmationModalComponent {
  confirmationText: string;
  
  get documentName(): string {
    if (this.document.info.buildingName)
      return this.document.info.buildingName;

    return 'dokumentet';
  }

  constructor(
    private dialogRef: MatDialogRef<DeleteDocumentConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public document: Document
  ) {
    this.confirmationText = '';
  }

  get expectedConfirmationText(): string {
    return documentsConfig.delete.confirmationText;
  }

  get isConfirmed(): boolean {
    const isConfirmed = StringUtils.equalsIgnoreCase(this.confirmationText, this.expectedConfirmationText);
    return isConfirmed;
  }

  onDelete() {
    if (!this.isConfirmed)
      return;

    this.dialogRef.close(true);
  }
}
