import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { find } from 'lodash';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/core/forms/confirm-dialog/confirm-dialog.component';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { DocumentFieldNote, DocumentSectionField, DocumentSectionType } from 'src/app/documents/shared';
import { DocumentSectionNote } from '../shared/document-section-note';
import { DocumentSectionNoteResult } from '../shared/document-section-note-result';

@Component({
  selector: 'app-project-note-modal',
  templateUrl: './project-note-modal.component.html',
  styleUrls: ['./project-note-modal.component.scss'],
})
export class ProjectNoteModalComponent implements OnInit {
  selectedFieldId: string;

  data: {
    sectionType: DocumentSectionType;
    fields: DocumentSectionField[];
    isViewOnly: boolean;
    isFieldIdDisabled: boolean;
    originalFieldId: string;
    selectedFieldId: string;
    notes: StringMap<DocumentFieldNote>;
    note: string;
  };

  get DocumentSectionType(): typeof DocumentSectionType {
    return DocumentSectionType;
  }

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProjectNoteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public input: DocumentSectionNote,
    private readonly projectSectionDataService: DocumentsSectionDataService
  ) {
    const note = (input.sectionFieldId && input.notes && input.notes[input.sectionFieldId]?.note) || '';

    this.data = {
      sectionType: input.sectionType,
      fields: [],
      isViewOnly: input.isViewOnly,
      isFieldIdDisabled: Boolean(input.sectionFieldId),
      originalFieldId: input.sectionFieldId,
      selectedFieldId: input.sectionFieldId,
      notes: input.notes,
      note: note,
    };
    this.selectedFieldId = input.sectionFieldId;
  }

  ngOnInit(): void {
    const sections = this.projectSectionDataService.getDocumentSections();
    const currentSection = find(sections, (section) => section.sectionId === this.data.sectionType);
    const generalSection = this.projectSectionDataService.getGeneralSectionFieldBySectionType(this.data.sectionType);
    const fieldsList = [generalSection, ...currentSection.fields];

    this.data.fields = fieldsList;
    if (!this.data.selectedFieldId) {
      this.data.selectedFieldId = generalSection.fieldId;
      this.data.originalFieldId = generalSection.fieldId;
      this.refreshNoteValue();
    }
  }

  onSave(): void {
    const result = new DocumentSectionNoteResult({
      fieldId: this.data.originalFieldId,
      note: this.data.note,
    });

    this.dialogRef.close(result);
  }

  async onClose(noteControl: UntypedFormControl): Promise<void> {
    if (!noteControl.dirty) {
      this.dialogRef.close();
      return;
    }

    const isConfiremed = await this.confirmDialog();
    if (isConfiremed) {
      this.dialogRef.close();
    }
  }

  async onSelectedFieldChanged(noteControl: UntypedFormControl): Promise<boolean> {
    if (noteControl.dirty) {
      const isConfiremed = await this.confirmDialog();
      if (!isConfiremed) {
        this.data.selectedFieldId = this.data.originalFieldId;

        return false;
      }
      noteControl.markAsPristine();
    }
    this.data.originalFieldId = this.data.selectedFieldId;
    this.refreshNoteValue();
  }

  private refreshNoteValue(): void {
    this.data.note =
      (this.data.originalFieldId && this.data.notes && this.data.notes[this.data.originalFieldId]?.note) || '';
  }

  private confirmDialog(): Promise<boolean> {
    const result = new Promise<boolean>((resolve) => {
      const title = 'Är du säker?';
      const message = `Anteckningen har ändrats. Vill du lämna utan att spara ändringarna?`;

      const dialogData = new ConfirmDialogModel(title, message);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
    return result;
  }
}
