import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import _ from 'lodash';
import { PermissionsApiService } from 'src/app/authentication/permissions-api.service';
import { Permission } from 'src/app/authentication/shared/permission';
import { UserRole } from 'src/app/authentication/shared/user-role';
import { coreConfig, LoggerService } from 'src/app/core';
import { DataSource, DataSourceService, DataSourceState } from 'src/app/core/collections';
import { ConfirmationModalComponent } from 'src/app/core/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalDialogData } from 'src/app/core/shared/confirmation-modal-dialog-data';
import { SaveDataOptions } from 'src/app/core/shared/save-data-options-enum';
import { DocumentsApiService } from 'src/app/documents/documents-api.service';
import { documentsConfig } from 'src/app/documents/documents.config';
import { Document } from 'src/app/documents/shared';
import { UserDetails } from 'src/app/users-management/shared';
import { UsersApiService } from 'src/app/users-management/users-api.service';
import { usersConfig } from 'src/app/users-management/users.config';
import { permissionGroupsConfig } from '../../users-management/permissionGroups.config';
import { DiscardDataConfirmationModalComponent } from '../discard-data-confirmation-modal/discard-data-confirmation-modal.component';
import { OrganizationUsersManagementService } from '../organization-users-management.service';
import { required } from '../../shared';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manage-permissions-modal',
  templateUrl: './manage-permissions-modal.component.html',
  styleUrls: ['./manage-permissions-modal.component.scss'],
})
export class ManagePermissionsModalComponent implements OnInit {
  usersDataSource: DataSource<UserDetails>;
  usersDataSourceState: DataSourceState<UserDetails>;

  documentsDataSource: DataSource<Document>;
  documentsDataSourceState: DataSourceState<Document>;

  permissionsDataSource: DataSource<Permission>;
  permissionsDataSourceState: DataSourceState<Permission>;

  showOnlySelectedDocuments: boolean;
  showOnlySelectedUsers: boolean;
  selectedPermissionGroup: Permission;

  usersFilter: string;
  documentsFilter: string;

  state: {
    isLoading: boolean;
    isFailed: boolean;
    isRefreshing: boolean;
    isDeleting: boolean;
    isActionProcessing: boolean;
  };

  private copySelectedPermisionGroup: Permission;

  get hasUnsavedChanges() {
    if (!this.selectedPermissionGroup) {
      return false;
    }
    if (this.selectedPermissionGroup.id === null) {
      return true;
    }
    const currentPermissionGroup = _.find(
      this.permissionsDataSource.allItems,
      (item) => item.id === this.selectedPermissionGroup.id
    );
    const hasChanges = !this.arePermissionGroupsEqual(currentPermissionGroup, this.selectedPermissionGroup);
    return hasChanges;
  }

  constructor(
    public dialogRef: MatDialogRef<ManagePermissionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public organizationId: string,
    private readonly documentsApiService: DocumentsApiService,
    private readonly usersApiService: UsersApiService,
    private readonly permissionsApiService: PermissionsApiService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly dataSourceService: DataSourceService,
    private readonly loggerService: LoggerService,
    private readonly organizationUsersManagementService: OrganizationUsersManagementService
  ) {
    this.usersFilter = '';
    this.documentsFilter = '';

    this.usersDataSource = new DataSource<UserDetails>();
    this.usersDataSourceState = new DataSourceState<UserDetails>({
      filter: '',
      customFilter: (userDetails: UserDetails[], filter: string) =>
        this.organizationUsersManagementService.filterUsers(
          userDetails,
          filter,
          this.showOnlySelectedUsers,
          this.selectedPermissionGroup?.userIds
        ),
      searchableFields: usersConfig.filter.searchableFields,
      sortOrder: null,
      page: 0,
      pageSize: coreConfig.pagination.defaultPageSize,
    });

    this.documentsDataSource = new DataSource<Document>();
    this.documentsDataSourceState = new DataSourceState<Document>({
      filter: '',
      customFilter: (documents: Document[], filter: string) =>
        this.organizationUsersManagementService.filterDocuments(
          documents,
          filter,
          this.showOnlySelectedDocuments,
          this.selectedPermissionGroup?.documentIds
        ),
      searchableFields: documentsConfig.filter.searchableFields,
      sortOrder: null,
      page: 0,
      pageSize: coreConfig.pagination.defaultPageSize,
    });

    this.permissionsDataSource = new DataSource<Permission>();
    this.permissionsDataSourceState = new DataSourceState<Permission>({
      filter: '',
      searchableFields: permissionGroupsConfig.filter.searchableFields,
      sortOrder: null,
      page: 0,
      pageSize: coreConfig.pagination.defaultPageSize,
    });

    this.state = {
      isLoading: false,
      isFailed: false,
      isRefreshing: false,
      isDeleting: false,
      isActionProcessing: false,
    };
  }

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    this.state.isLoading = true;
    this.state.isFailed = false;

    this.usersDataSource = new DataSource<UserDetails>();
    this.documentsDataSource = new DataSource<Document>();
    this.permissionsDataSource = new DataSource<Permission>();

    try {
      const documentsPromise = this.documentsApiService.getDocumentsByOrganizationId(this.organizationId);
      const userDetailsListPromise = this.usersApiService.getUsersByOrganizationId(this.organizationId);
      const permissionsPromise = this.permissionsApiService.getPermissionGroups(this.organizationId);
      const [documents, usersDetails, permissions] = await Promise.all([
        documentsPromise,
        userDetailsListPromise,
        permissionsPromise,
      ]);
      this.documentsDataSource = this.dataSourceService.createDataSource(documents, this.documentsDataSourceState);

      const customerReadersUsers = _.filter(usersDetails, (user) => user.role === UserRole.customerReader);
      this.usersDataSource = this.dataSourceService.createDataSource(customerReadersUsers, this.usersDataSourceState);
      this.permissionsDataSource = this.dataSourceService.createDataSource(
        permissions,
        this.permissionsDataSourceState
      );
      if (this.permissionsDataSource.filteredItems.length > 0) {
        this.selectedPermissionGroup = _.cloneDeep(_.first(this.permissionsDataSource.filteredItems));
      }
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to load data', error);
    } finally {
      this.state.isLoading = false;
    }
  }

  async onDeletePermission(event: MouseEvent, permission: Permission) {
    event.stopPropagation();

    const confirmationModalDialogData = new ConfirmationModalDialogData({
      title: 'Ta bort läsarrättighet',
      message: `Är du säker på att du vill ta bort ${permission.name}?`,
      useDoubleConfirmation: true,
    });
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: confirmationModalDialogData,
    });

    dialogRef.afterClosed().subscribe(async (deleteResult: boolean) => {
      if (deleteResult) {
        await this.permissionsApiService.deletePermissionGroup(permission.id);
        this.removePermissionGroupFromDataSource(permission);
        this.showSnackBar(`Läsarrättighet "${permission.name}" borttaget.`);

        if (this.selectedPermissionGroup?.id === permission.id) {
          this.selectedPermissionGroup = null;
        }
      }
    });
  }

  async onAddPermissionGroup() {
    if (this.hasUnsavedChanges) {
      const result = await this.confirmRedirectWithUnsavedData();
      if (!result) {
        return;
      }
    }
    const newPermissionGroup = new Permission({
      id: null,
      name: 'Ny läsarrättighet',
      organizationId: this.organizationId,
      documentIds: [],
      userIds: [],
    });
    this.permissionsDataSource = this.dataSourceService.addItemToDataSource(
      this.permissionsDataSource,
      this.permissionsDataSourceState,
      newPermissionGroup
    );
    this.selectedPermissionGroup = _.cloneDeep(newPermissionGroup);
  }

  async onPermissionClick(permission: Permission) {
    if (this.selectedPermissionGroup && permission.id === this.selectedPermissionGroup.id) {
      return;
    }
    if (this.hasUnsavedChanges) {
      const result = await this.confirmRedirectWithUnsavedData();
      if (!result) {
        return;
      }
    }
    this.selectedPermissionGroup = _.cloneDeep(permission);
    this.usersFilter = '';
    this.documentsFilter = '';
    this.onUsersFilterChanged(this.usersFilter);
    this.onDocumentsFilterChanged(this.documentsFilter);
  }

  async onSavePermissionGroup(): Promise<void> {
    if (this.selectedPermissionGroup.id) {
      await this.updatePermissionGroup();
      return;
    }

    await this.createPermissionGroup();
  }

  onReset() {
    if (!this.selectedPermissionGroup) {
      return;
    }
    const confirmationModalDialogData = new ConfirmationModalDialogData({
      title: 'Vill du återställa?',
      message: 'Dina ändringar kommer att gå förlorade?',
      confirmButtonText: 'Återställ',
    });
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: confirmationModalDialogData,
    });
    dialogRef.afterClosed().subscribe(async (reset: boolean) => {
      if (reset) {
        this.discardChanges();
        return true;
      }
    });
  }

  onPermissionGroupsFilterChanged(filter: string) {
    this.permissionsDataSourceState.page = 0;
    this.permissionsDataSourceState.filter = filter;
    this.permissionsDataSource = this.dataSourceService.filterDataSource(
      this.permissionsDataSource,
      this.permissionsDataSourceState
    );
  }

  onUsersFilterChanged(filter: string) {
    if (this.usersFilter === '') {
      this.copySelectedPermisionGroup = _.cloneDeep(this.selectedPermissionGroup);
    }
    this.usersFilter = filter;
    this.usersDataSourceState.page = 0;
    this.usersDataSourceState.filter = filter;
    this.usersDataSource = this.dataSourceService.filterDataSource(this.usersDataSource, this.usersDataSourceState);
    this.refreshCurrentPermissionGroup();
  }

  onDocumentsFilterChanged(filter: string) {
    if (this.documentsFilter === '') {
      this.copySelectedPermisionGroup = _.cloneDeep(this.selectedPermissionGroup);
    }
    this.documentsFilter = filter;
    this.documentsDataSourceState.page = 0;
    this.documentsDataSourceState.filter = filter;
    this.documentsDataSource = this.dataSourceService.filterDataSource(
      this.documentsDataSource,
      this.documentsDataSourceState
    );
    this.refreshCurrentPermissionGroup();
  }

  onShowOnlySelectedUsersChanged() {
    if (this.usersFilter === '') {
      this.copySelectedPermisionGroup = _.cloneDeep(this.selectedPermissionGroup);
    }
    this.usersDataSource = this.dataSourceService.filterDataSource(this.usersDataSource, this.usersDataSourceState);
    this.refreshCurrentPermissionGroup();
  }

  onShowOnlySelectedDocumentsChanged() {
    if (this.documentsFilter === '') {
      this.copySelectedPermisionGroup = _.cloneDeep(this.selectedPermissionGroup);
    }
    this.documentsDataSource = this.dataSourceService.filterDataSource(
      this.documentsDataSource,
      this.documentsDataSourceState
    );
    this.refreshCurrentPermissionGroup();
  }

  permissionGroupNameChanged() {
    this.selectedPermissionGroup.name = _.trim(this.selectedPermissionGroup.name);
  }

  async onClose() {
    if (this.hasUnsavedChanges) {
      const result = await this.confirmRedirectWithUnsavedData();
      if (!result) {
        return;
      }
    }
    this.dialogRef.close();
  }

  private async createPermissionGroup() {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      const newPermissionGroup = await this.permissionsApiService.createPermissionGroup(this.selectedPermissionGroup);
      const createdPermissionGroup = _.find(this.permissionsDataSource.allItems, (item) => item.id === null);
      createdPermissionGroup.id = newPermissionGroup.id;

      const permissionsComparator = (first: Permission, second: Permission) => first.id === second.id;

      this.permissionsDataSource = this.dataSourceService.updateItemInDataSource(
        this.permissionsDataSource,
        this.permissionsDataSourceState,
        newPermissionGroup,
        permissionsComparator
      );
      this.selectedPermissionGroup = _.cloneDeep(newPermissionGroup);

      this.onPermissionCreated(newPermissionGroup);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to create permission group', error);
    } finally {
      this.state.isLoading = false;
    }
  }

  private async updatePermissionGroup() {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      await this.permissionsApiService.updatePermissionGroup(this.selectedPermissionGroup);
      const updatedPermissionGroup = _.cloneDeep(this.selectedPermissionGroup);

      const permissionsComparator = (first: Permission, second: Permission) => first.id === second.id;

      this.permissionsDataSource = this.dataSourceService.updateItemInDataSource(
        this.permissionsDataSource,
        this.permissionsDataSourceState,
        updatedPermissionGroup,
        permissionsComparator
      );
      this.onPermissionUpdated(updatedPermissionGroup);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to update pernission group', error);
    } finally {
      this.state.isLoading = false;
    }
  }

  private refreshCurrentPermissionGroup() {
    this.selectedPermissionGroup = _.cloneDeep(
      _.find(this.permissionsDataSource.allItems, (item) => item.id === this.selectedPermissionGroup.id)
    );
    if (this.copySelectedPermisionGroup) {
      this.selectedPermissionGroup.userIds = this.copySelectedPermisionGroup.userIds;
      this.selectedPermissionGroup.documentIds = this.copySelectedPermisionGroup.documentIds;
    }
  }

  private removePermissionGroupFromDataSource(permissionGroup: Permission) {
    const permissionsComparator = (first: Permission, second: Permission) => first.id === second.id;
    this.permissionsDataSource = this.dataSourceService.deleteItemFromDataSource(
      this.permissionsDataSource,
      this.permissionsDataSourceState,
      permissionGroup,
      permissionsComparator
    );
  }

  private discardChanges() {
    if (!this.selectedPermissionGroup) {
      return;
    }
    if (this.selectedPermissionGroup.id === null) {
      this.removePermissionGroupFromDataSource(this.selectedPermissionGroup);

      this.selectedPermissionGroup = null;
      return;
    }

    const currentPermissionGroup = _.find(
      this.permissionsDataSource.allItems,
      (item) => item.id === this.selectedPermissionGroup.id
    );
    this.selectedPermissionGroup = _.cloneDeep(currentPermissionGroup);
  }

  private async confirmRedirectWithUnsavedData(): Promise<boolean> {
    const dialogRef = this.dialog.open(DiscardDataConfirmationModalComponent, {
      disableClose: true,
    });
    const result = await dialogRef.afterClosed().toPromise();
    switch (result) {
      case SaveDataOptions.Cancel:
        return false;
      case SaveDataOptions.DoNotSave:
        this.discardChanges();
        return true;
      case SaveDataOptions.Save:
        await this.onSavePermissionGroup();
        return true;
      default:
        return false;
    }
  }

  private arePermissionGroupsEqual(permissionGroup1: Permission, permissionGroup2: Permission) {
    const areIdsEqual = _.isEqual(permissionGroup1.id, permissionGroup2.id);
    const areNamesEqual = _.isEqual(permissionGroup1.name, permissionGroup2.name);
    const areUserIdsEqual = _.isEqual(permissionGroup1.userIds, permissionGroup2.userIds);
    const areDocumentIdsEqual = _.isEqual(permissionGroup1.documentIds, permissionGroup2.documentIds);

    const areEqual = areIdsEqual && areNamesEqual && areUserIdsEqual && areDocumentIdsEqual;
    return areEqual;
  }

  private onPermissionCreated(permission: Permission): void {
    this.showSnackBar(`Läsarrättighet "${permission.name}" har skapats.`);
  }

  private onPermissionUpdated(permission: Permission): void {
    this.showSnackBar(`Läsarrättighet "${permission.name}" har uppdaterats.`);
  }

  private showSnackBar(@required message: string): void {
    this.snackBar.open(message, 'OK');
  }
}
