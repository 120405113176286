import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'lodash';
import { ApiService, ListResponse } from '../core/api';
import { required, validate } from '../shared';
import { organizationConfig } from './organization.config';
import { Organization, OrganizationActivate, OrganizationItem } from './shared';
import { v4 as uuid } from 'uuid';
import { WorkerState } from '../shared/worker/worker-state';
import { OrganizationDetailsPageRequest } from '../core/collections/page-response';

@Injectable({
  providedIn: 'root',
})
export class OrganizationApiService {
  private readonly http: HttpClient;
  private readonly apiService: ApiService;

  constructor(http: HttpClient, apiService: ApiService) {
    this.http = http;
    this.apiService = apiService;
  }

  async getOrganizations(): Promise<Organization[]> {
    const url = `api/${organizationConfig.api.organization.url}`;
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    let response: any[];
    try {
      response = await this.http.get<any>(noCacheUrl).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const organizations = this.mapOrganizationsResponse(response);

    return organizations;
  }

  async getNewOrganizations(): Promise<Organization[]> {
    const url = `api/${organizationConfig.api.organization.url}/${organizationConfig.api.new.url}`;
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    let response: any[];
    try {
      response = await this.http.get<any>(noCacheUrl).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const organizations = this.mapOrganizationsResponse(response);

    return organizations;
  }

  async getOrganizationsWithDetails(request: OrganizationDetailsPageRequest<OrganizationItem>): Promise<ListResponse<OrganizationItem>> {
    const url = `api/${organizationConfig.api.organization.url}/${organizationConfig.api.details.url}`;
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);
    const params = new HttpParams()
      .set('page', request.page.toString())
      .set('pageSize', request.pageSize.toString())
      .set('filter', request.filter.toString())
      .set('showDeactivatedOrganizations', request.showDeactivatedOrganizations.toString())
      .set('sortField', request.sort?.fieldName?.toString() ?? '')
      .set('sortOrder', request.sort?.order?.toString() ?? '');

    let response: ListResponse<OrganizationItem>;
    try {
      response = await this.http.get<ListResponse<OrganizationItem>>(noCacheUrl, { params }).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const organizations = this.mapOrganizationItemsResponse(response.list);

    return { list: organizations, total: response.total };
  }

  async synchronizeOrganizations(): Promise<WorkerState> {
    const url = `api/${organizationConfig.api.organization.url}/${organizationConfig.api.synchronize.url}`;

    try {
      const response = await this.http.post<WorkerState>(url, null).toPromise();
      return response;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async getOrganizationById(id: uuid): Promise<Organization> {
    const url = `api/${organizationConfig.api.organization.url}/${id}`;
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    let response: any;
    try {
      response = await this.http.get<any>(noCacheUrl).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const organization = this.mapOrganizationResponse(response);

    return organization;
  }

  @validate
  async activateOrganization(@required request: OrganizationActivate): Promise<void> {
    const url = `api/${organizationConfig.api.organization.url}/${organizationConfig.api.activate.url}`;

    try {
      await this.http.post<any>(url, request).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  // async updateOrganization(id: uuid, name: string): Promise<OrganizationItem> {
  //   const url = `api/${organizationConfig.api.organization.url}/${organizationConfig.api.details.url}/${id}`;
  //
  //   const body = {
  //     name: name,
  //   };
  //
  //   let response: any[];
  //   try {
  //     response = await this.http.put<any>(url, body).toPromise();
  //   } catch (error) {
  //     const errorResponse = this.apiService.handleError(error);
  //     throw errorResponse;
  //   }
  //
  //   const organization = this.mapOrganizationItemResponse(response);
  //
  //   return organization;
  // }

  async reactivateOrganization(id: uuid): Promise<boolean> {
    const url = `api/${organizationConfig.api.organization.url}/${organizationConfig.api.reactivate.url}/${id}`;

    try {
      return await this.http.put<boolean>(url, {}).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async deactivateOrganization(id: uuid): Promise<boolean> {
    const url = `api/${organizationConfig.api.organization.url}/${organizationConfig.api.deactivate.url}/${id}`;

    try {
      return await this.http.delete<boolean>(url).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  private mapOrganizationsResponse(@required response: any[]): Organization[] {
    if (response.length === 0) {
      return [];
    }

    const organizations = map(response, (organization: any) => this.mapOrganizationResponse(organization));

    return organizations;
  }

  @validate
  private mapOrganizationResponse(@required organizationResponse: any): Organization {
    const organization = new Organization({
      id: organizationResponse.id,
      externalId: organizationResponse.externalId,
      name: organizationResponse.name,
      status: organizationResponse.status
    });

    return organization;
  }

  @validate
  private mapOrganizationItemsResponse(@required response: any[]): OrganizationItem[] {
    if (response.length === 0) {
      return [];
    }

    const organizations = map(response, (organization: any) => this.mapOrganizationItemResponse(organization));

    return organizations;
  }

  @validate
  private mapOrganizationItemResponse(@required organizationResponse: any): OrganizationItem {
    const organization = new OrganizationItem({
      id: organizationResponse.id,
      name: organizationResponse.name,
      status: organizationResponse.status,
      docsCount: organizationResponse.docsCount,
      usersCount: organizationResponse.usersCount,
      updatedDate: organizationResponse.updatedDate,
    });

    return organization;
  }
}
