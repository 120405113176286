<table class="organizations-table table data-table alt-table-white table-align-middle">
  <thead>
    <tr>
      <th>
        <app-sortable-table-header
          [fieldName]="fieldNames.name"
          [fieldSortOrder]="sortOrder"
          (sortOrderChanged)="onSortOrderChanged($event)"
        >
          <ng-container>Företag</ng-container>
        </app-sortable-table-header>
      </th>
      <th class="icon-column">
        <app-sortable-table-header
          [fieldName]="fieldNames.usersCount"
          [fieldSortOrder]="sortOrder"
          (sortOrderChanged)="onSortOrderChanged($event)"
        >
          <ng-container>Användare</ng-container>
        </app-sortable-table-header>
      </th>
      <th class="icon-column">
        <app-sortable-table-header
          [fieldName]="fieldNames.docsCount"
          [fieldSortOrder]="sortOrder"
          (sortOrderChanged)="onSortOrderChanged($event)"
        >
          <ng-container>Dokument</ng-container>
        </app-sortable-table-header>
      </th>
      <th class="icon-column">
        <app-sortable-table-header
          [fieldName]="fieldNames.updatedDate"
          [fieldSortOrder]="sortOrder"
          (sortOrderChanged)="onSortOrderChanged($event)"
        >
          <ng-container>Senast uppdaterad</ng-container>
        </app-sortable-table-header>
      </th>
      <th class="action-column"></th>
    </tr>
  </thead>

  <tbody *ngIf="items | isEmpty">
    <tr>
      <td colspan="4" class="text-center text-muted">Inget att visa.</td>
    </tr>
  </tbody>

  <tbody
    app-organizations-table-row
    *ngFor="let item of items; trackBy: trackByItem"
    [isDisabled]="isDisabled"
    [item]="item"
    [hasSuperAdminPermissions]="hasSuperAdminPermissions"
    (activateOrganization)="onActivateOrganization($event)"
    (deactivateOrganization)="onDeactivateOrganization($event)"
    (organizationUpdated)="onOrganizationUpdated($event)"
  ></tbody>
</table>
