import { createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { DocumentsState } from './documents.reducer';
import { documentsConfig } from '../documents.config';
import { AppState } from 'src/app/app.reducer';
import { map } from 'lodash';
import { v4 as uuid } from 'uuid';

export const selectDocumentsState: MemoizedSelector<AppState, DocumentsState> = createFeatureSelector(
  documentsConfig.store.documentsKey
);

export const selectDocuments = createSelector(selectDocumentsState, (state: DocumentsState) =>
  map(state.allIds, (id: uuid) => state.byId[id])
);

export const selectDocumentById = createSelector(
  selectDocumentsState,
  (state: DocumentsState, props: { id: uuid }) => state.byId[props.id]
);
