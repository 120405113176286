import { Pipe, PipeTransform } from '@angular/core';
import { truncate, TruncateOptions } from 'lodash';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, maxLength: number, omission: string = '...', separator?: string): string {
    if (!value || !maxLength) {
      return '';
    }

    const options: TruncateOptions = {
      length: maxLength,
    };

    if (omission) {
      options.omission = omission;
    }

    if (separator) {
      options.separator = separator;
    }

    const truncatedText = truncate(value, options);

    return truncatedText;
  }
}
