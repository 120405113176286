import { IDocumentItem } from './document';

export class Renovation {
  year: number;
  remedy: string;

  constructor({ year, remedy }: Partial<Renovation> = {}) {
    this.year = year;
    this.remedy = remedy || '';
  }
}

export class DocumentObject implements IDocumentItem {
  propertyName: string;
  municipality: string;
  area: string;
  areaM2: string;
  building: string;
  constructionYear: number;
  renovations: Renovation[];
  changesDescription: string;
  isCompleted: boolean;
  isValid: boolean;

  constructor({
    propertyName,
    municipality,
    area,
    areaM2,
    building,
    constructionYear,
    renovations,
    changesDescription,
    isCompleted,
    isValid,
  }: Partial<DocumentObject> = {}) {
    this.propertyName = propertyName;
    this.municipality = municipality;
    this.area = area;
    this.areaM2 = areaM2;
    this.building = building;
    this.constructionYear = constructionYear;
    this.renovations = renovations || [];
    this.changesDescription = changesDescription;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
