import { OrganizationItem } from './shared';

export const organizationConfig = {
  routes: {
    organizationsRoot: '',
    organizations: 'anvandare',
    params: {
      organizationId: 'id',
    },
  },
  api: {
    organization: {
      url: 'organization',
    },
    new: {
      url: 'new',
    },
    details: {
      url: 'details',
    },
    activate: {
      url: 'activate',
    },
    reactivate: {
      url: 'reactivate',
    },
    deactivate: {
      url: 'deactivate',
    },
    organizationUsers: {
      urlFormat: `users/organization/`,
    },
    synchronize: {
      url: 'synchronize',
    }
  },
  filter: {
    searchableFields: ['name'] as FieldsOf<OrganizationItem>,
  },
  list: {
    minRefreshTimeout: 1000,
  },
  create: {
    validation: {
      name: {
        maxLength: 64,
        allowedCharacters: ['-', '_', '.', 'å', 'ä', 'ö', 'é'],
      },
    },
  },
  activate: {
    confirmationText: 'bekräfta',
  },
  deactivate: {
    confirmationText: 'bekräfta',
  },
  allOrganizationsItem: new OrganizationItem({ id: null, name: 'Alla kunder' }),
};
