import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import _ from 'lodash';
import { organizationConfig } from '../organization.config';

@Component({
  selector: 'app-organization-page',
  templateUrl: './organization-page.component.html',
  styleUrls: ['./organization-page.component.scss'],
})
export class OrganizationPageComponent implements OnInit {
  private readonly route: ActivatedRoute;

  organizationId: string;

  constructor(route: ActivatedRoute) {
    this.route = route;
    this.organizationId = null;
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = params.get(organizationConfig.routes.params.organizationId);
      if (_.isEmpty(id) || id === 'null') {
        id = null;
      }
      this.organizationId = id;
    });
  }
}
