<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'SYSTEMATISKA BRANDSKYDDSKONTROLLER'"
    [documentId]="document?.id"
    [isCompleted]="document?.inspection.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <!-- 1. UTRYMNING -->
        <ng-container>
          <div class="row">
            <div class="col-12">
              <div>1. UTRYMNING</div>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Utrymningsvägars funktion och utrymningsstödjande installationer'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasFunctionOfEscapeRoutesCondition"
                [(ngModel)]="document.inspection.hasFunctionOfEscapeRoutes"
                (ngModelChange)="onFormDataChanged()"
                name="hasFunctionOfEscapeRoutes"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12">Dörrar, fönster och passager till och i utrymningsväg:</div>
                </div>

                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Dörrar, fönster och passager som nyttjas för utrymning'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFunctionOfEscapeRoutes || conditions.hasFunctionOfEscapeRoutesCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasDoorsWindowsEscapeRotes"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasDoorsWindowsEscapeRotes"
                    ></app-document-radio-button>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="col-12">Utrymningsplatser:</div>
                </div>

                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Vägledande markering'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFunctionOfEscapeRoutes || conditions.hasFunctionOfEscapeRoutesCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasFunctionOfEscapeRoutesIndicativeMarkingCondition"
                      [(ngModel)]="document.inspection.hasFunctionOfEscapeRoutesIndicativeMarking"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasFunctionOfEscapeRoutesIndicativeMarking"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Åtkomst'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFunctionOfEscapeRoutes || conditions.hasFunctionOfEscapeRoutesCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasFunctionOfEscapeRoutesAccessCondition"
                      [(ngModel)]="document.inspection.hasFunctionOfEscapeRoutesAccess"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasFunctionOfEscapeRoutesAccess"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Kommunikationsutrustning'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFunctionOfEscapeRoutes || conditions.hasFunctionOfEscapeRoutesCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasFunctionOfEscapeRoutesCommunicationEquipmentCondition"
                      [(ngModel)]="document.inspection.hasFunctionOfEscapeRoutesCommunicationEquipment"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasFunctionOfEscapeRoutesCommunicationEquipment"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Markering av utrymningsplats'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFunctionOfEscapeRoutes || conditions.hasFunctionOfEscapeRoutesCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasFunctionOfEscapeRoutesMarkingOfEvacuationSiteCondition"
                      [(ngModel)]="document.inspection.hasFunctionOfEscapeRoutesMarkingOfEvacuationSite"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasFunctionOfEscapeRoutesMarkingOfEvacuationSite"
                    ></app-document-radio-button>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="col-12">Utrymningsplaner:</div>
                </div>

                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Läsbarhet, mekanisk påverkan samt aktualitet'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFunctionOfEscapeRoutes || conditions.hasFunctionOfEscapeRoutesCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasEvacuationPlanReadabilityMechanicalImpactCondition"
                      [(ngModel)]="document.inspection.hasEvacuationPlanReadabilityMechanicalImpact"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasEvacuationPlanReadabilityMechanicalImpact"
                    ></app-document-radio-button>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="col-12">Flyktmasker:</div>
                </div>

                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Status på utrustning och skyltning'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFunctionOfEscapeRoutes || conditions.hasFunctionOfEscapeRoutesCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasStatusOfEquipmentAndSignage"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasStatusOfEquipmentAndSignage"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- 2. SKYDD MOT BRANDSPRIDNING -->
        <ng-container>
          <hr />
          <div class="row">
            <div class="col-12">
              <div>2. SKYDD MOT BRANDSPRIDNING</div>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Brandspridning inom brandceller'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasFireSpreadWithinFireCellsCondition"
                [(ngModel)]="document.inspection.hasFireSpreadWithinFireCells"
                (ngModelChange)="onFormDataChanged()"
                name="hasFireSpreadWithinFireCells"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Regelbunden kontroll av förändring genom väggbeklädnader mm.'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireSpreadWithinFireCells ||
                          conditions.hasFireSpreadWithinFireCellsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRegularControlOfChangeThroughWallCoveringsCondition"
                      [(ngModel)]="document.inspection.hasRegularControlOfChangeThroughWallCoverings"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRegularControlOfChangeThroughWallCoverings"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Brandspridning mellan brandceller'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasFireSpreadBetweenFireCellsCondition"
                [(ngModel)]="document.inspection.hasFireSpreadBetweenFireCells"
                (ngModelChange)="onFormDataChanged()"
                name="hasFireSpreadBetweenFireCells"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12">Dörrar och luckor i brandcellsgräns:</div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Dörrar och luckor i brandcellsgräns'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireSpreadBetweenFireCells ||
                          conditions.hasFireSpreadBetweenFireCellsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasDoorsWithinFireCellBoundary"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasDoorsWithinFireCellBoundary"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Uppställningsanordning på dörrar i brandcellsgräns'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireSpreadBetweenFireCells ||
                          conditions.hasFireSpreadBetweenFireCellsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasInstallationDeviceOnDoorsWithinFireCellBoundaryCondition"
                      [(ngModel)]="document.inspection.hasInstallationDeviceOnDoorsWithinFireCellBoundary"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasInstallationDeviceOnDoorsWithinFireCellBoundary"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Automatisk dörröppningsfunktion på dörrar i brandcellsgräns'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireSpreadBetweenFireCells ||
                          conditions.hasFireSpreadBetweenFireCellsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="
                        conditions.hasAutomaticDoorOpeningFunctionOnDoorsWithinFireCellBoundaryCondition
                      "
                      [(ngModel)]="document.inspection.hasAutomaticDoorOpeningFunctionOnDoorsWithinFireCellBoundary"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasAutomaticDoorOpeningFunctionOnDoorsWithinFireCellBoundary"
                    ></app-document-radio-button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">Glaspartier:</div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Glaspartier i brandcellsgräns'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireSpreadBetweenFireCells ||
                          conditions.hasFireSpreadBetweenFireCellsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasGlassSectionsWithinFireCellBoundaryCondition"
                      [(ngModel)]="document.inspection.hasGlassSectionsWithinFireCellBoundary"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasGlassSectionsWithinFireCellBoundary"
                    ></app-document-radio-button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">Genombrott och skador:</div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Ventilationskanaler'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireSpreadBetweenFireCells ||
                          conditions.hasFireSpreadBetweenFireCellsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasVentilationDuctsCondition"
                      [(ngModel)]="document.inspection.hasVentilationDucts"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasVentilationDucts"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Genomföringar för kablar, rör, kanaler mm'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireSpreadBetweenFireCells ||
                          conditions.hasFireSpreadBetweenFireCellsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasGlandsForCablesPipesDuctsCondition"
                      [(ngModel)]="document.inspection.hasGlandsForCablesPipesDucts"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasGlandsForCablesPipesDucts"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Väggar samt bärande/avskiljande konstruktioner generellt'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireSpreadBetweenFireCells ||
                          conditions.hasFireSpreadBetweenFireCellsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasWallsAndLoadBearingStructuresCondition"
                      [(ngModel)]="document.inspection.hasWallsAndLoadBearingStructures"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasWallsAndLoadBearingStructures"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- 3. BRANDTEKNISKA INSTALLATIONER -->
        <ng-container>
          <hr />
          <div class="row">
            <div class="col-12">
              <div>3. BRANDTEKNISKA INSTALLATIONER</div>
            </div>
          </div>

          <hr />
          <!-- 3.1. Varselmärkning, skyltning och nödbelysning -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Varselmärkning, skyltning och nödbelysning'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasWarningMarkingSignageAndEmergencyLightingCondition"
                [(ngModel)]="document.inspection.hasWarningMarkingSignageAndEmergencyLighting"
                (ngModelChange)="onFormDataChanged()"
                name="hasWarningMarkingSignageAndEmergencyLighting"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12">
                    <div>Skyltning, nödbelysning:</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Vägledande markeringar'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasWarningMarkingSignageAndEmergencyLighting ||
                          conditions.hasWarningMarkingSignageAndEmergencyLightingCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasSignageEmergencyLightingIndicativeMarkingsCondition"
                      [(ngModel)]="document.inspection.hasSignageEmergencyLightingIndicativeMarkings"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasSignageEmergencyLightingIndicativeMarkings"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Separata nödbelysningsarmaturer'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasWarningMarkingSignageAndEmergencyLighting ||
                          conditions.hasWarningMarkingSignageAndEmergencyLightingCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="
                        conditions.hasSignageEmergencyLightingSeparateEmergencyLightinguminairesCondition
                      "
                      [(ngModel)]="document.inspection.hasSignageEmergencyLightingSeparateEmergencyLightinguminaires"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasSignageEmergencyLightingSeparateEmergencyLightinguminaires"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Nödströmsförsörjning, batteribackup'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasWarningMarkingSignageAndEmergencyLighting ||
                          conditions.hasWarningMarkingSignageAndEmergencyLightingCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="
                        conditions.hasSignageEmergencyLightingEmergencyPowerSupplyBatteryBackupCondition
                      "
                      [(ngModel)]="document.inspection.hasSignageEmergencyLightingEmergencyPowerSupplyBatteryBackup"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasSignageEmergencyLightingEmergencyPowerSupplyBatteryBackup"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Nödströmsförsörjning, reservkraftaggregat'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasWarningMarkingSignageAndEmergencyLighting ||
                          conditions.hasWarningMarkingSignageAndEmergencyLightingCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="
                        conditions.hasSignageEmergencyLightingEmergencyPowerSupplyReservePowerSupplyCondition
                      "
                      [(ngModel)]="
                        document.inspection.hasSignageEmergencyLightingEmergencyPowerSupplyReservePowerSupply
                      "
                      (ngModelChange)="onFormDataChanged()"
                      name="hasSignageEmergencyLightingEmergencyPowerSupplyReservePowerSupply"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Batterilampa i fast laddningsenhet'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasWarningMarkingSignageAndEmergencyLighting ||
                          conditions.hasWarningMarkingSignageAndEmergencyLightingCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasBatteryLampInFixedChargingUnit"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasBatteryLampInFixedChargingUnit"
                    ></app-document-radio-button>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="col-12">
                    <div>Allmänbelysning:</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Trapphus och korridorer i byggnader med fler än två våningsplan'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasWarningMarkingSignageAndEmergencyLighting ||
                          conditions.hasWarningMarkingSignageAndEmergencyLightingCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="
                        conditions.hasGeneralLightingStaircasesAndCorridorsInBuildingsWithMoreThanTwoStoreysCondition
                      "
                      [(ngModel)]="
                        document.inspection.hasGeneralLightingStaircasesAndCorridorsInBuildingsWithMoreThanTwoStoreys
                      "
                      (ngModelChange)="onFormDataChanged()"
                      name="hasGeneralLightingStaircasesAndCorridorsInBuildingsWithMoreThanTwoStoreys"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Belysning av efterlysande skyltar'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasWarningMarkingSignageAndEmergencyLighting ||
                          conditions.hasWarningMarkingSignageAndEmergencyLightingCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasGeneralLightingLightingOfIlluminatedSignsCondition"
                      [(ngModel)]="document.inspection.hasGeneralLightingLightingOfIlluminatedSigns"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasGeneralLightingLightingOfIlluminatedSigns"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 3.2. Automatiskt brandlarm och utrymningslarm -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Automatiskt brandlarm och utrymningslarm'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasAutomaticFireAlarmAndEvacuationAlarmCondition"
                [(ngModel)]="document.inspection.hasAutomaticFireAlarmAndEvacuationAlarm"
                (ngModelChange)="onFormDataChanged()"
                name="hasAutomaticFireAlarmAndEvacuationAlarm"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Revisionsbesiktning'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasAutomaticFireAlarmAndEvacuationAlarm ||
                          conditions.hasAutomaticFireAlarmAndEvacuationAlarmCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasAutomaticFireAlarmAndEvacuationAlarmAuditInspection"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasAutomaticFireAlarmAndEvacuationAlarmAuditInspection"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Systemunderhåll'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasAutomaticFireAlarmAndEvacuationAlarm ||
                          conditions.hasAutomaticFireAlarmAndEvacuationAlarmCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasAutomaticFireAlarmAndEvacuationAlarmSystemMaintenance"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasAutomaticFireAlarmAndEvacuationAlarmSystemMaintenance"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Enklare kontroller och provningar'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasAutomaticFireAlarmAndEvacuationAlarm ||
                          conditions.hasAutomaticFireAlarmAndEvacuationAlarmCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasAutomaticFireAlarmAndEvacuationAlarmSimplerChecksAndTests"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasAutomaticFireAlarmAndEvacuationAlarmSimplerChecksAndTests"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 3.3. Automatiskt vattensprinklersystem -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Automatiskt vattensprinklersystem'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasAutomaticWaterSprinklerSystemCondition"
                [(ngModel)]="document.inspection.hasAutomaticWaterSprinklerSystem"
                (ngModelChange)="onFormDataChanged()"
                name="hasAutomaticWaterSprinklerSystem"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12">Automatisk vattensprinkleranläggning:</div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Revisionsbesiktning'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasAutomaticWaterSprinklerSystem ||
                          conditions.hasAutomaticWaterSprinklerSystemCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasAutomaticWaterSprinklerSystemAuditInspection"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasAutomaticWaterSprinklerSystemAuditInspection"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Enklare kontroller och provningar genom anläggningsskötare'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasAutomaticWaterSprinklerSystem ||
                          conditions.hasAutomaticWaterSprinklerSystemCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasAutomaticWaterSprinklerSystemSimplerChecksAndTests"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasAutomaticWaterSprinklerSystemSimplerChecksAndTests"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Skötsel och underhåll genom installatör eller annat kompetent företag'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasAutomaticWaterSprinklerSystem ||
                          conditions.hasAutomaticWaterSprinklerSystemCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasAutomaticWaterSprinklerSystemCareAndMaintenanceByInstaller"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasAutomaticWaterSprinklerSystemCareAndMaintenanceByInstaller"
                    ></app-document-radio-button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">Boendesprinkler:</div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Skötsel och underhåll genom installatör eller annat kompetent företag'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasAutomaticWaterSprinklerSystem ||
                          conditions.hasAutomaticWaterSprinklerSystemCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasResidentialSprinklerCareAndMaintenanceByInstaller"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasResidentialSprinklerCareAndMaintenanceByInstaller"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 3.4. Fasta släcksystem för ökat egendomsskydd -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Fasta släcksystem för ökat egendomsskydd'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasFixedExtinguishingSystemsForIncreasedPropertyProtectionCondition"
                [(ngModel)]="document.inspection.hasFixedExtinguishingSystemsForIncreasedPropertyProtection"
                (ngModelChange)="onFormDataChanged()"
                name="hasFixedExtinguishingSystemsForIncreasedPropertyProtection"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Systemöversyn av besiktningsman/anläggarfirma eller driftentreprenör'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFixedExtinguishingSystemsForIncreasedPropertyProtection ||
                          conditions.hasFixedExtinguishingSystemsForIncreasedPropertyProtectionCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="
                        document.inspection.hasSystemReviewByInspectorConstructionCompanyOrOperatingContractor
                      "
                      (ngModelChange)="onFormDataChanged()"
                      name="hasSystemReviewByInspectorConstructionCompanyOrOperatingContractor"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Enklare kontroller och provningar enligt anläggarfirmans anvisningar'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFixedExtinguishingSystemsForIncreasedPropertyProtection ||
                          conditions.hasFixedExtinguishingSystemsForIncreasedPropertyProtectionCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="
                        document.inspection.hasSimplerChecksAndTestsAccordingToConstructionCompanyInstructions
                      "
                      (ngModelChange)="onFormDataChanged()"
                      name="hasSimplerChecksAndTestsAccordingToConstructionCompanyInstructions"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 3.5. Brandsläckningsutrustning för personer på plats -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Brandsläckningsutrustning för personer på plats'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasFireFightingEquipmentForPersonsOnSiteCondition"
                [(ngModel)]="document.inspection.hasFireFightingEquipmentForPersonsOnSite"
                (ngModelChange)="onFormDataChanged()"
                name="hasFireFightingEquipmentForPersonsOnSite"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Årligt underhåll genom utbildad serviceman'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireFightingEquipmentForPersonsOnSite ||
                          conditions.hasFireFightingEquipmentForPersonsOnSiteCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasFireFightingEquipmentForPersonsOnSiteAnnualMaintenance"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasFireFightingEquipmentForPersonsOnSiteAnnualMaintenance"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Rutinkontroll genom enklare okulär besiktning'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireFightingEquipmentForPersonsOnSite ||
                          conditions.hasFireFightingEquipmentForPersonsOnSiteCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasFireFightingEquipmentForPersonsOnSiteRoutineInspection"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasFireFightingEquipmentForPersonsOnSiteRoutineInspection"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 3.6. Brandgasventilation -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Brandgasventilation'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasFireGasVentilationCondition"
                [(ngModel)]="document.inspection.hasFireGasVentilation"
                (ngModelChange)="onFormDataChanged()"
                name="hasFireGasVentilation"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Manuellt mekaniskt öppnade luckor, dörrar och fönster'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireGasVentilation || conditions.hasFireGasVentilationCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasFireGasVentilationManuallyMechanicallyOpenedDoorsWindows"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasFireGasVentilationManuallyMechanicallyOpenedDoorsWindows"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Fjärrstyrd och/eller automatisk öppning av luckor, dörrar och fönster'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireGasVentilation || conditions.hasFireGasVentilationCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="
                        document.inspection.hasFireGasVentilationRemoteControlledAutomaticOpeningDoorsWindows
                      "
                      (ngModelChange)="onFormDataChanged()"
                      name="hasFireGasVentilationRemoteControlledAutomaticOpeningDoorsWindows"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Fjärrstyrd och/eller automatisk aktivering av mekanisk brandgasventilation'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasFireGasVentilation || conditions.hasFireGasVentilationCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="
                        document.inspection.hasFireGasVentilationRemoteControlledAutomaticActivationMechanical
                      "
                      (ngModelChange)="onFormDataChanged()"
                      name="hasFireGasVentilationRemoteControlledAutomaticActivationMechanical"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- 4. VENTILATIONSSYSTEM -->
        <ng-container>
          <hr />
          <div class="row">
            <div class="col-12">
              <div>4. VENTILATIONSSYSTEM</div>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Ventilationssystem'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasVentilationSystemCondition"
                [(ngModel)]="document.inspection.hasVentilationSystem"
                (ngModelChange)="onFormDataChanged()"
                name="hasVentilationSystem"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Översyn av system, brandskyddsfunktioner och styrningar'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasVentilationSystem || conditions.hasVentilationSystemCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="
                        conditions.hasVentilationSystemAnnualReviewOfFireProtectionFunctionsCondition
                      "
                      [(ngModel)]="document.inspection.hasVentilationSystemAnnualReviewOfFireProtectionFunctions"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasVentilationSystemAnnualReviewOfFireProtectionFunctions"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Brand-/brandgasspjäll'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasVentilationSystem || conditions.hasVentilationSystemCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasVentilationSystemFireDamperCondition"
                      [(ngModel)]="document.inspection.hasVentilationSystemFireDamper"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasVentilationSystemFireDamper"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Im- och rökkanaler'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasVentilationSystem || conditions.hasVentilationSystemCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasVentilationSystemMistAndSmokeDuctsCondition"
                      [(ngModel)]="document.inspection.hasVentilationSystemMistAndSmokeDucts"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasVentilationSystemMistAndSmokeDucts"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Fläktfunktion för fläktar i drift'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasVentilationSystem || conditions.hasVentilationSystemCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasFanFunctionForFansInOperationCondition"
                      [(ngModel)]="document.inspection.hasFanFunctionForFansInOperation"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasFanFunctionForFansInOperation"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- 5. INSATSFÖRUTSÄTTNINGAR  -->
        <ng-container>
          <hr />
          <div class="row">
            <div class="col-12">
              <div>5. INSATSFÖRUTSÄTTNINGAR</div>
            </div>
          </div>

          <hr />
          <!-- 5.1 Åtkomst och tillträde till byggnader -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Åtkomst och tillträde till byggnader'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasAccessingBuildingsCondition"
                [(ngModel)]="document.inspection.hasAccessingBuildings"
                (ngModelChange)="onFormDataChanged()"
                name="hasAccessingBuildings"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Körvägar och uppställningsplatser för räddningstjänsten'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasAccessingBuildings || conditions.hasAccessingBuildingsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="
                        conditions.hasAccessingBuildingsDrivewaysAndParkingSpacesForRescueServiceCondition
                      "
                      [(ngModel)]="document.inspection.hasAccessingBuildingsDrivewaysAndParkingSpacesForRescueService"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasAccessingBuildingsDrivewaysAndParkingSpacesForRescueService"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Dörrar, luckor och passager för tillträde'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasAccessingBuildings || conditions.hasAccessingBuildingsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasAccessingBuildingsDoorsHatchesPassagesForAccess"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasAccessingBuildingsDoorsHatchesPassagesForAccess"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Insatsnyckel tillämpas'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasAccessingBuildings || conditions.hasAccessingBuildingsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="document.opportunities.isKeyAccess"
                      [(ngModel)]="document.inspection.hasAccessingBuildingsBetKeyIsApplied"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasAccessingBuildingsBetKeyIsApplied"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Tillträde takytor'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasAccessingBuildings || conditions.hasAccessingBuildingsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasAccessingBuildingsRoofSurfacesCondition"
                      [(ngModel)]="document.inspection.hasAccessingBuildingsRoofSurfaces"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasAccessingBuildingsRoofSurfaces"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 5.2 Räddningshiss -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Räddningshiss'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasRescueElevatorCondition"
                [(ngModel)]="document.inspection.hasRescueElevator"
                (ngModelChange)="onFormDataChanged()"
                name="hasRescueElevator"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Egenkontroll enligt gällande underhållskrav från hissinstallatör'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasRescueElevator || conditions.hasRescueElevatorCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasRescueElevatorSelfInspectionElevatorInstaller"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRescueElevatorSelfInspectionElevatorInstaller"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Skyltning och markeringar'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasRescueElevator || conditions.hasRescueElevatorCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasRescueElevatorSignageAndMarkings"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRescueElevatorSignageAndMarkings"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 5.3 Insatsplaner -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Insatsplaner'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasActionPlansCondition"
                [(ngModel)]="document.inspection.hasActionPlans"
                (ngModelChange)="onFormDataChanged()"
                name="hasActionPlans"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Regelbunden översyn'"
                      [hasEditPermissions]="
                        hasEditPermissions && (document.inspection.hasActionPlans || conditions.hasActionPlansCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasActionPlansRegularReview"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasActionPlansRegularReview"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 5.4 Släckvatten -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Släckvatten'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasExtinguishingWaterCondition"
                [(ngModel)]="document.inspection.hasExtinguishingWater"
                (ngModelChange)="onFormDataChanged()"
                name="hasExtinguishingWater"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Brandposter för räddningstjänstens släckvattenuttag'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasExtinguishingWater || conditions.hasExtinguishingWaterCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasExtinguishingWaterFireHydrantsForRescueServiceCondition"
                      [(ngModel)]="document.inspection.hasExtinguishingWaterFireHydrantsForRescueService"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasExtinguishingWaterFireHydrantsForRescueService"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Branddamm och/eller uppställningsplats för motorspruta'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasExtinguishingWater || conditions.hasExtinguishingWaterCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="document.opportunities.isFireDustIsApplied"
                      [(ngModel)]="document.inspection.hasExtinguishingWaterFirePondAndParkingSpace"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasExtinguishingWaterFirePondAndParkingSpace"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Skyltning och markeringar'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasExtinguishingWater || conditions.hasExtinguishingWaterCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasExtinguishingWaterSignageAndMarkingsCondition"
                      [(ngModel)]="document.inspection.hasExtinguishingWaterSignageAndMarkings"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasExtinguishingWaterSignageAndMarkings"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 5.5 Stigarledningar -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Stigarledningar'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasLaddersCondition"
                [(ngModel)]="document.inspection.hasLadders"
                (ngModelChange)="onFormDataChanged()"
                name="hasLadders"
              ></app-document-radio-button>
            </div>
            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Torr stigarledning för extern trycksättning genom insatspersonal'"
                      [hasEditPermissions]="
                        hasEditPermissions && (document.inspection.hasLadders || conditions.hasLaddersCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasLaddersDryRiserLineCondition"
                      [(ngModel)]="document.inspection.hasLaddersDryRiserLine"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasLaddersDryRiserLine"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Trycksatt stigarledning, revisionsbesiktning'"
                      [hasEditPermissions]="
                        hasEditPermissions && (document.inspection.hasLadders || conditions.hasLaddersCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasLaddersPressurizedRiserLineAuditInspectionCondition"
                      [(ngModel)]="document.inspection.hasLaddersPressurizedRiserLineAuditInspection"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasLaddersPressurizedRiserLineAuditInspection"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Trycksatt stigarledning, service och underhåll'"
                      [hasEditPermissions]="
                        hasEditPermissions && (document.inspection.hasLadders || conditions.hasLaddersCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasLaddersPressurizedRiserLineServiceAndMaintenanceCondition"
                      [(ngModel)]="document.inspection.hasLaddersPressurizedRiserLineServiceAndMaintenance"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasLaddersPressurizedRiserLineServiceAndMaintenance"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Anslutnings- och uttagspunkter för släckvatten'"
                      [hasEditPermissions]="
                        hasEditPermissions && (document.inspection.hasLadders || conditions.hasLaddersCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="
                        conditions.hasLaddersConnectionAndOutletPointsForExtinguishingWaterCondition
                      "
                      [(ngModel)]="document.inspection.hasLaddersConnectionAndOutletPointsForExtinguishingWater"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasLaddersConnectionAndOutletPointsForExtinguishingWater"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Skyltning och markeringar'"
                      [hasEditPermissions]="
                        hasEditPermissions && (document.inspection.hasLadders || conditions.hasLaddersCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasLaddersSignageAndMarkingsCondition"
                      [(ngModel)]="document.inspection.hasLaddersSignageAndMarkings"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasLaddersSignageAndMarkings"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 5.6 Fasta släcksystem med funktion som släckhjälp till insatsstyrka -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Fasta släcksystem med funktion som släckhjälp till insatsstyrka'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [(ngModel)]="document.inspection.hasFixedExtinguishingSystemsWithAidForTaskForce"
                (ngModelChange)="onFormDataChanged()"
                name="hasFixedExtinguishingSystemsWithAidForTaskForce"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Systemöversyn genom anläggarfirma eller driftentreprenör'"
                      [hasEditPermissions]="
                        hasEditPermissions && document.inspection.hasFixedExtinguishingSystemsWithAidForTaskForce
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasSystemReviewByConstructionCompanyOrOperatingContractor"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasSystemReviewByConstructionCompanyOrOperatingContractor"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Enklare kontroller och provningar'"
                      [hasEditPermissions]="
                        hasEditPermissions && document.inspection.hasFixedExtinguishingSystemsWithAidForTaskForce
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasSimplerChecksAndTests"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasSimplerChecksAndTests"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- 6. SÄRSKILDA RISKER -->
        <ng-container>
          <hr />
          <div class="row">
            <div class="col-12">
              <div>6. SÄRSKILDA RISKER</div>
            </div>
          </div>

          <hr />
          <!-- 6.1 Uppvärmningssystem och eldstäder -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Uppvärmningssystem och eldstäder'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [(ngModel)]="document.inspection.hasHeatingSystem"
                (ngModelChange)="onFormDataChanged()"
                name="hasHeatingSystem"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Olje-, gas- eller fastbränslebaserat uppvärmningssystem (panna)'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasHeatingSystem"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasHeatingSystemOilGasSolidFuelBased"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasHeatingSystemOilGasSolidFuelBased"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Eldstad, kamin ansluten till skorsten, rök- eller avgaskanal'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasHeatingSystem"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasHeatingSystemFireplaceStoveConnectedToChimney"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasHeatingSystemFireplaceStoveConnectedToChimney"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Sotning och brandskyddskontroll'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasHeatingSystem"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasHeatingSystemSweepingAndFireProtectionControl"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasHeatingSystemSweepingAndFireProtectionControl"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 6.2 Särskilda risker förekommer i form av -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Särskilda risker förekommer'"
                [hasEditPermissions]="hasEditPermissions && document.risks.specialRiskTypeIds.length === 0"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasSpecialRisksCondition"
                [(ngModel)]="document.inspection.hasSpecialRisks"
                (ngModelChange)="onFormDataChanged()"
                name="hasSpecialRisks"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Hantering av brandfarlig vara'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksHandlingFlammableGoodsCondition"
                      [(ngModel)]="document.inspection.hasRisksHandlingFlammableGoods"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksHandlingFlammableGoods"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Hantering av gasflaskor'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksHandlingLpgBottlesCondition"
                      [(ngModel)]="document.inspection.hasRisksHandlingLpgBottles"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksHandlingLpgBottles"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Laboratorieverksamhet'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksLaboratoryActivitiesCondition"
                      [(ngModel)]="document.inspection.hasRisksLaboratoryActivities"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksLaboratoryActivities"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Batteriladdningsplats'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksBatteryChargingPointCondition"
                      [(ngModel)]="document.inspection.hasRisksBatteryChargingPoint"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksBatteryChargingPoint"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Öppen lastkaj'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksOpenLoadingDockCondition"
                      [(ngModel)]="document.inspection.hasRisksOpenLoadingDock"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksOpenLoadingDock"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Spånsug'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksChipSuctionCondition"
                      [(ngModel)]="document.inspection.hasRisksChipSuction"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksChipSuction"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Utvändiga upplag'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksExternalEditionsCondition"
                      [(ngModel)]="document.inspection.hasRisksExternalEditions"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksExternalEditions"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Eldstad'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksFireplaceCondition"
                      [(ngModel)]="document.inspection.hasRisksFireplace"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksFireplace"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Högspänning över 1kV'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksHighVoltageCondition"
                      [(ngModel)]="document.inspection.hasRisksHighVoltage"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksHighVoltage"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Annan risk enligt beskrivning'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksOtherRiskCondition"
                      [(ngModel)]="document.inspection.hasRisksOtherRisk"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksOtherRisk"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Dragskåp'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksFumeCupboardCondition"
                      [(ngModel)]="document.inspection.hasRisksFumeCupboard"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksFumeCupboard"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Cisternförvaring inomhus'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksIndoorCisternStorageCondition"
                      [(ngModel)]="document.inspection.hasRisksIndoorCisternStorage"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksIndoorCisternStorage"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Cisternförvaring utomhus'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksOutdoorCisternStorageCondition"
                      [(ngModel)]="document.inspection.hasRisksOutdoorCisternStorage"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksOutdoorCisternStorage"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Fasta gassystem, gasledningar'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksFixedGasSystemsPipelinesCondition"
                      [(ngModel)]="document.inspection.hasRisksFixedGasSystemsPipelines"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksFixedGasSystemsPipelines"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Pannrum över 60 kW'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksBoilerRoomOver60vmCondition"
                      [(ngModel)]="document.inspection.hasRisksBoilerRoomOver60vm"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksBoilerRoomOver60vm"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Radioaktiva ämnen'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksRadioactiveSubstancesCondition"
                      [(ngModel)]="document.inspection.hasRisksRadioactiveSubstances"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksRadioactiveSubstances"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Batterilagringsenheter'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksBatteryStorageDevicesCondition"
                      [(ngModel)]="document.inspection.hasRisksBatteryStorageDevices"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksBatteryStorageDevices"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Farliga kemikalier (person/miljö)'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksHazardousChemicalsPersonEnvironmentCondition"
                      [(ngModel)]="document.inspection.hasRisksHazardousChemicalsPersonEnvironment"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksHazardousChemicalsPersonEnvironment"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Magnetism'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksMagnetismCondition"
                      [(ngModel)]="document.inspection.hasRisksMagnetism"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksMagnetism"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Matlagningsanordning'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksCookingDeviceCondition"
                      [(ngModel)]="document.inspection.hasRisksCookingDevice"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksCookingDevice"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Kemikalieförråd'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksChemicalStorageCondition"
                      [(ngModel)]="document.inspection.hasRisksChemicalStorage"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksChemicalStorage"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Central förvaring av smittförande avfall'"
                      [hasEditPermissions]="hasEditPermissions && document.inspection.hasSpecialRisks"
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasRisksCentralStorageOfInfectiousWasteCondition"
                      [(ngModel)]="document.inspection.hasRisksCentralStorageOfInfectiousWaste"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasRisksCentralStorageOfInfectiousWaste"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 6.3 Elektriska installationer -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Elektriska installationer'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasElectricalInstallationsCondition"
                [(ngModel)]="document.inspection.hasElectricalInstallations"
                (ngModelChange)="onFormDataChanged()"
                name="hasElectricalInstallations"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Kontroll genom auktoriserad besiktningsman'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasElectricalInstallations ||
                          conditions.hasElectricalInstallationsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasElectricalInstallationsInspectionByAuthorizedInspector"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasElectricalInstallationsInspectionByAuthorizedInspector"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Belysningsarmaturer och heta ytor'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasElectricalInstallations ||
                          conditions.hasElectricalInstallationsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasElectricalInstallationsLightingFixturesAndHotSurfaces"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasElectricalInstallationsLightingFixturesAndHotSurfaces"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Elcentraler och driftrum'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasElectricalInstallations ||
                          conditions.hasElectricalInstallationsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="document.inspection.hasElectricalInstallationsPowerPlants"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasElectricalInstallationsPowerPlants"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Batteriladdningsplatser som kan alstra brandfarlig gas'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasElectricalInstallations ||
                          conditions.hasElectricalInstallationsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="
                        conditions.hasElectricalInstallationsBatteryChargingPointsCanGenerateGasCondition
                      "
                      [(ngModel)]="document.inspection.hasElectricalInstallationsBatteryChargingPointsCanGenerateGas"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasElectricalInstallationsBatteryChargingPointsCanGenerateGas"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Batterilagringsplatser, status på lokal och utrustning'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasElectricalInstallations ||
                          conditions.hasElectricalInstallationsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [(ngModel)]="
                        document.inspection.hasElectricalInstallationsBatteryStorageLocationsStatusOfPremises
                      "
                      (ngModelChange)="onFormDataChanged()"
                      name="hasElectricalInstallationsBatteryStorageLocationsStatusOfPremises"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Solcellsanläggning, status på installation och skyddsfunktioner'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasElectricalInstallations ||
                          conditions.hasElectricalInstallationsCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="
                        conditions.hasElectricalInstallationsSolarCellSystemStatusOfInstallationCondition
                      "
                      [(ngModel)]="document.inspection.hasElectricalInstallationsSolarCellSystemStatusOfInstallation"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasElectricalInstallationsSolarCellSystemStatusOfInstallation"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <!-- 6.4 Utvändig lagring, anlagd brand -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <app-document-radio-button
                [fieldName]="'Utvändig lagring, anlagd brand'"
                [hasEditPermissions]="hasEditPermissions"
                [sectionType]="sectionType"
                [document]="document"
                [notes]="notes"
                [isMasterConditionTrue]="conditions.hasExternalStorageFireCondition"
                [(ngModel)]="document.inspection.hasExternalStorageFire"
                (ngModelChange)="onFormDataChanged()"
                name="hasExternalStorageFire"
              ></app-document-radio-button>
            </div>

            <div class="col-12 col-md-6 col-lg-9">
              <div class="vertical-line">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Containrar, komprimatorer, upplag, miljöstationer'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasExternalStorageFire || conditions.hasExternalStorageFireCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="
                        conditions.hasExternalStorageFireContainersCompactorsWarehousesEnvironmentalStationsCondition
                      "
                      [(ngModel)]="
                        document.inspection.hasExternalStorageFireContainersCompactorsWarehousesEnvironmentalStations
                      "
                      (ngModelChange)="onFormDataChanged()"
                      name="hasExternalStorageFireContainersCompactorsWarehousesEnvironmentalStations"
                    ></app-document-radio-button>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-3">
                    <app-document-radio-button
                      [fieldName]="'Lastkajer'"
                      [hasEditPermissions]="
                        hasEditPermissions &&
                        (document.inspection.hasExternalStorageFire || conditions.hasExternalStorageFireCondition)
                      "
                      [sectionType]="sectionType"
                      [document]="document"
                      [notes]="notes"
                      [isMasterConditionTrue]="conditions.hasExternalStorageFireCargoBerthCondition"
                      [(ngModel)]="document.inspection.hasExternalStorageFireCargoBerth"
                      (ngModelChange)="onFormDataChanged()"
                      name="hasExternalStorageFireCargoBerth"
                    ></app-document-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </main>
</section>
