import { Action } from '@ngrx/store';
import { Assert } from 'src/app/shared';
import { DocumentFieldNote } from '../shared';
import { v4 as uuid } from 'uuid';

export enum DocumentsNotesActionType {
  AddNote = '[DocumentsNotes] AddNote',
  AddNotes = '[DocumentsNotes] AddNotes',
  DeleteNote = '[DocumentsNotes] DeleteNote',
  Reset = '[DocumentsNotes] Reset',
}

export class AddDocumentNoteAction implements Action {
  readonly type = DocumentsNotesActionType.AddNote;

  readonly documentId: uuid;
  readonly item: DocumentFieldNote;

  constructor(documentId: uuid, item: DocumentFieldNote) {
    Assert.isNotNull(documentId, 'documentId');
    Assert.isNotNull(item, 'item');

    this.documentId = documentId;
    this.item = item;
  }
}

export class AddDocumentNotesAction implements Action {
  readonly type = DocumentsNotesActionType.AddNotes;

  readonly documentId: uuid;
  readonly items: StringMap<DocumentFieldNote>;

  constructor(documentId: uuid, items: StringMap<DocumentFieldNote>) {
    Assert.isNotNull(documentId, 'documentId');
    Assert.isNotNull(items, 'items');

    this.documentId = documentId;
    this.items = items;
  }
}

export class DeleteDocumentNoteAction implements Action {
  readonly type = DocumentsNotesActionType.DeleteNote;

  readonly documentId: uuid;
  readonly fieldId: string;

  constructor(documentId: uuid, fieldId: string) {
    Assert.isNotNull(documentId, 'documentId');
    Assert.isNotNull(fieldId, 'fieldId');

    this.documentId = documentId;
    this.fieldId = fieldId;
  }
}

export class ResetDocumentNotesAction implements Action {
  readonly type = DocumentsNotesActionType.Reset;
}

export type DocumentsNotesActions =
  | AddDocumentNoteAction
  | AddDocumentNotesAction
  | DeleteDocumentNoteAction
  | ResetDocumentNotesAction;
