<h1 mat-dialog-title>{{ confirmationModalDialogData.title }}</h1>
<div mat-dialog-content *ngIf="confirmationModalDialogData.message || confirmationModalDialogData.useDoubleConfirmation">
  <div class="modal-body">
    <div>
      {{ confirmationModalDialogData.message }}
    </div>

    <div class="mt-4" *ngIf="confirmationModalDialogData.useDoubleConfirmation">
      <div class="form-inline">
        <label class="mr-2" for="confirmationText">
          <ng-container>Skriv </ng-container>
          "{{ expectedConfirmationText }}"
          <ng-container> för att bekräfta</ng-container>
        </label>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="confirmationText"
          name="confirmationText"
          id="confirmationText"
          (keydown.enter)="onConfirm()"
        />
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-raised-button (click)="onCancel()">Avbryt</button>
  <button class="confirm-btn" mat-raised-button (click)="onConfirm()" cdkFocusInitial [disabled]="!isConfirmed">
    <ng-container *ngIf="confirmationModalDialogData.confirmButtonText">
      {{ confirmationModalDialogData.confirmButtonText }}
    </ng-container>
    <ng-container *ngIf="!confirmationModalDialogData.confirmButtonText">Radera</ng-container>
  </button>
</div>
