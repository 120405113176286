export class User {
  id: string;
  email: string;

  firstName: string;
  lastName: string;
  organizationId: string;
  canReviewDocument: boolean;

  constructor({ id, email, firstName, lastName, organizationId, canReviewDocument }: Partial<User> = {}) {
    this.id = id;
    this.email = email;

    this.firstName = firstName;
    this.lastName = lastName;
    this.organizationId = organizationId;
    this.canReviewDocument = canReviewDocument || false;
  }
}
