import { UserDetails } from '../shared';

export class UserWithPass {
  readonly userDetails: UserDetails;
  readonly password: string;

  constructor({ userDetails, password }: Partial<UserWithPass> = {}) {
    this.userDetails = userDetails;
    this.password = password;
  }
}
