<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'Skydd mot brandspridning mellan byggnader'"
    [documentId]="document?.id"
    [isCompleted]="document?.spread.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Skydd mot brandspridning mellan byggnader uppnås genom"
              [values]="protectionAgainstFireOptionTypes"
              [selectedKeys]="document.spread.protectionAgainstFireOptionIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="protectionAgainstFireOptionIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Skydd mot brandspridning mellan byggnader uppnås genom"
              *ngIf="hasEditPermissions"
            >
              <mat-label title="Protection against the spread of fire between buildings is achieved through"
                >Skydd mot brandspridning mellan byggnader uppnås genom</mat-label
              >

              <mat-select
                required
                [(ngModel)]="document.spread.protectionAgainstFireOptionIds"
                #protectionAgainstFireOptionIds="ngModel"
                name="protectionAgainstFireOptionIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="protectionAgainstFireOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let protectionAgainstFireOptionType of protectionAgainstFireOptionTypes | keyvalue"
                  [value]="protectionAgainstFireOptionType.key"
                  [title]="protectionAgainstFireOptionType.value.displayName"
                  [disabled]="protectionAgainstFireOptionType.value.isDisabled"
                  >{{ protectionAgainstFireOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[protectionAgainstFireOptionIds.name]"-->
<!--                [class.text-secondary-primary]="notes[protectionAgainstFireOptionIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[protectionAgainstFireOptionIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="protectionAgainstFireOptionIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Taktäckning på aktuell byggnad är utfört i"
              [values]="roofingOptionTypes"
              [selectedKeys]="document.spread.roofingOptionIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="roofingOptionIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Taktäckning på aktuell byggnad är utfört i"
              *ngIf="hasEditPermissions"
            >
              <mat-label title="Roofing on the current building is done in"
                >Taktäckning på aktuell byggnad är utfört i</mat-label
              >

              <mat-select
                required
                [(ngModel)]="document.spread.roofingOptionIds"
                #roofingOptionIds="ngModel"
                name="roofingOptionIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="roofingOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let roofingOptionType of roofingOptionTypes | keyvalue"
                  [value]="roofingOptionType.key"
                  [title]="roofingOptionType.value.displayName"
                  [disabled]="roofingOptionType.value.isDisabled"
                  >{{ roofingOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[roofingOptionIds.name]"-->
<!--                [class.text-secondary-primary]="notes[roofingOptionIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[roofingOptionIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="roofingOptionIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Underlag taktäckning"
              [values]="baseRoofingOptionTypes"
              [selectedKeys]="document.spread.baseRoofingIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="baseRoofingIds"
            ></app-multiselect-readonly-view>

            <mat-form-field floatLabel="always" class="w-100" title="Underlag taktäckning" *ngIf="hasEditPermissions">
              <mat-label title="Base roofing">Underlag taktäckning</mat-label>

              <mat-select
                required
                [(ngModel)]="document.spread.baseRoofingIds"
                #baseRoofingIds="ngModel"
                name="baseRoofingIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="baseRoofingOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let baseRoofingOptionType of baseRoofingOptionTypes | keyvalue"
                  [value]="baseRoofingOptionType.key"
                  [title]="baseRoofingOptionType.value.displayName"
                  [disabled]="baseRoofingOptionType.value.isDisabled"
                  >{{ baseRoofingOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[baseRoofingIds.name]"-->
<!--                [class.text-secondary-primary]="notes[baseRoofingIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[baseRoofingIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="baseRoofingIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Fasadmaterial på aktuell byggnad är utfört i"
              [values]="facadeMaterialOptionTypes"
              [selectedKeys]="document.spread.facadeMaterialOptionIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="facadeMaterialOptionIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Fasadmaterial på aktuell byggnad är utfört i"
              *ngIf="hasEditPermissions"
            >
              <mat-label title="Facade material on the current building is made of"
                >Fasadmaterial på aktuell byggnad är utfört i</mat-label
              >

              <mat-select
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.spread.facadeMaterialOptionIds"
                #facadeMaterialOptionIds="ngModel"
                name="facadeMaterialOptionIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="facadeMaterialOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let facadeMaterialOptionType of facadeMaterialOptionTypes | keyvalue"
                  [value]="facadeMaterialOptionType.key"
                  [title]="facadeMaterialOptionType.value.displayName"
                  [disabled]="facadeMaterialOptionType.value.isDisabled"
                  >{{ facadeMaterialOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[facadeMaterialOptionIds.name]"-->
<!--                [class.text-secondary-primary]="notes[facadeMaterialOptionIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[facadeMaterialOptionIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="facadeMaterialOptionIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3">
            <mat-form-field class="w-100" *ngIf="isFacadeMaterialOptionOtherEnabled">
              <mat-label>Annat</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.spread.facadeMaterialOptionOther"
                #facadeMaterialOptionOther="ngModel"
                name="facadeMaterialOptionOther"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende skydd mot spridning mellan byggnader">
              <mat-label title="Extended description regarding protection against spread between buildings"
                >Utökad beskrivning avseende skydd mot spridning mellan byggnader</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.spread.protectionAgainstSpreadDescription"
                #protectionAgainstSpreadDescription="ngModel"
                name="protectionAgainstSpreadDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[protectionAgainstSpreadDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[protectionAgainstSpreadDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[protectionAgainstSpreadDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="protectionAgainstSpreadDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
