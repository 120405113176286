<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'RÄDDNINGSTJÄNSTENS INSATSMÖJLIGHETER'"
    [documentId]="document?.id"
    [isCompleted]="document?.opportunities.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Räddningstjänstens insatstid till byggnaden är"
              [values]="rescueServiceResponseTimeOptionTypes"
              [selectedKeys]="[document.opportunities.rescueServiceResponseTimeId]"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="rescueServiceResponseTimeId"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Räddningstjänstens insatstid till byggnaden är"
              *ngIf="hasEditPermissions"
            >
              <mat-label title="The rescue service's response time to the building is"
                >Räddningstjänstens insatstid till byggnaden är</mat-label
              >

              <mat-select
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.rescueServiceResponseTimeId"
                #rescueServiceResponseTimeId="ngModel"
                name="rescueServiceResponseTimeId"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let rescueServiceResponseTimeOptionType of rescueServiceResponseTimeOptionTypes | keyvalue"
                  [value]="rescueServiceResponseTimeOptionType.key"
                  [title]="rescueServiceResponseTimeOptionType.value.displayName"
                  >{{ rescueServiceResponseTimeOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[rescueServiceResponseTimeId.name]"-->
<!--                [class.text-secondary-primary]="notes[rescueServiceResponseTimeId.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[rescueServiceResponseTimeId.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="rescueServiceResponseTimeId.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Åtkomlighet sker genom"
              [values]="opportunitiesAccessibilityOptionTypes"
              [selectedKeys]="[document.opportunities.opportunitiesAccessibilityId]"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="opportunitiesAccessibilityId"
            ></app-multiselect-readonly-view>

            <mat-form-field floatLabel="always" class="w-100" title="Åtkomlighet sker genom" *ngIf="hasEditPermissions">
              <mat-label title="Accessibility is through">Åtkomlighet sker genom</mat-label>

              <mat-select
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.opportunitiesAccessibilityId"
                #opportunitiesAccessibilityId="ngModel"
                name="opportunitiesAccessibilityId"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let opportunitiesAccessibilityOptionType of opportunitiesAccessibilityOptionTypes | keyvalue"
                  [value]="opportunitiesAccessibilityOptionType.key"
                  [title]="opportunitiesAccessibilityOptionType.value.displayName"
                  >{{ opportunitiesAccessibilityOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[opportunitiesAccessibilityId.name]"-->
<!--                [class.text-secondary-primary]="notes[opportunitiesAccessibilityId.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[opportunitiesAccessibilityId.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="opportunitiesAccessibilityId.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Tillträde till byggnaden sker med insatsnyckel">
              <mat-label title="Access to the building is by key"
                >Tillträde till byggnaden sker med insatsnyckel</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="opportunitiesKeyAccess"
                #isKeyAccess="ngModel"
                name="isKeyAccess"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="isKeyAccessRadio"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.opportunities.isKeyAccess"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>

                <div class="d-flex ml-3">
                  <span class="text-medium mt-1" [class.text-disabled]="!document.opportunities.isKeyAccess"
                    >Förvaring av insatsnyckel sker i nyckelskåp på objektet:</span
                  >
                  <mat-radio-group
                    class="d-block ml-3"
                    name="isKeyStoringOnObject"
                    #isKeyStoringOnObject="ngModel"
                    [disabled]="!document.opportunities.isKeyAccess || !hasEditPermissions"
                    [(ngModel)]="document.opportunities.isKeyStoringOnObject"
                    (ngModelChange)="onFormDataChanged()"
                  >
                    <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                    <mat-radio-button [value]="false">Nej</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
<!--              <div matSuffix class="d-flex">-->
<!--                <button-->
<!--                  mat-icon-button-->
<!--                  *ngIf="hasEditPermissions || notes[isKeyAccess.name]"-->
<!--                  [class.text-secondary-primary]="notes[isKeyAccess.name]"-->
<!--                  appSectionFieldNote-->
<!--                  [ngbTooltip]="notes[isKeyAccess.name]?.note"-->
<!--                  [documentId]="document?.id"-->
<!--                  [sectionType]="sectionType"-->
<!--                  [sectionFieldId]="isKeyAccess.name"-->
<!--                  [notes]="notes"-->
<!--                  [isViewOnly]="!hasEditPermissions"-->
<!--                  tooltipClass="medium-large-size-tooltip"-->
<!--                  container="body"-->
<!--                >-->
<!--                  <mat-icon>attach_file</mat-icon>-->
<!--                </button>-->

<!--                <button-->
<!--                  mat-icon-button-->
<!--                  *ngIf="hasEditPermissions || notes[isKeyStoringOnObject.name]"-->
<!--                  [class.text-secondary-primary]="notes[isKeyStoringOnObject.name]"-->
<!--                  appSectionFieldNote-->
<!--                  [ngbTooltip]="notes[isKeyStoringOnObject.name]?.note"-->
<!--                  [documentId]="document?.id"-->
<!--                  [sectionType]="sectionType"-->
<!--                  [sectionFieldId]="isKeyStoringOnObject.name"-->
<!--                  [notes]="notes"-->
<!--                  [isViewOnly]="!hasEditPermissions"-->
<!--                  tooltipClass="medium-large-size-tooltip"-->
<!--                  container="body"-->
<!--                >-->
<!--                  <mat-icon>attach_file</mat-icon>-->
<!--                </button>-->
<!--              </div>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Placering av nyckelskåp">
              <mat-label>Placering av nyckelskåp</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions || !document.opportunities.isKeyStoringOnObject"
                [(ngModel)]="document.opportunities.locationOfKeyCabinet"
                #locationOfKeyCabinet="ngModel"
                name="locationOfKeyCabinet"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Kommunikationsutrustning för utrymningsplatser förekommer inom byggnaden">
              <mat-label>Kommunikationsutrustning för utrymningsplatser förekommer inom byggnaden</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.isCommunicationEquipmentForEvacuationInBuilding"
                #isCommunicationEquipmentForEvacuationInBuilding="ngModel"
                name="isCommunicationEquipmentForEvacuationInBuilding"
              />
              <mat-radio-group
                class="d-block"
                name="isCommunicationEquipmentForEvacuationInBuildingRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.isCommunicationEquipmentForEvacuationInBuilding"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Tillträde till vind sker genom"
              [values]="windAccessOptionTypes"
              [selectedKeys]="[document.opportunities.windAccessId]"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="windAccessId"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              [class.form-field-disabled-hint]="!document.building.hasAtticFloor"
              title="Tillträde till vind sker genom"
              *ngIf="hasEditPermissions"
            >
              <mat-label>Tillträde till vind sker genom</mat-label>

              <mat-select
                required
                [disabled]="!hasEditPermissions || !document.building.hasAtticFloor"
                [(ngModel)]="document.opportunities.windAccessId"
                #windAccessId="ngModel"
                name="windAccessId"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let windAccessOptionType of windAccessOptionTypes | keyvalue"
                  [value]="windAccessOptionType.key"
                  [title]="windAccessOptionType.value.displayName"
                  >{{ windAccessOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[windAccessId.name]"-->
<!--                [class.text-secondary-primary]="notes[windAccessId.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[windAccessId.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="windAccessId.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
              <div *ngIf="!document.building.hasAtticFloor" class="text-xs text-grey">
                Definiera vindsplan under fliken Byggnads- och verksamhetsbeskrivning - Vindsplan
              </div>
            </mat-form-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Solcellspaneler finns placerade på"
              [values]="solarPanelsLocationOptionTypes"
              [selectedKeys]="document.opportunities.solarPanelsLocationIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="solarPanelsLocationIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Solcellspaneler finns placerade på"
              *ngIf="hasEditPermissions"
            >
              <mat-label title="Solar panels are located on">Solcellspaneler finns placerade på</mat-label>

              <mat-select
                required
                [(ngModel)]="document.opportunities.solarPanelsLocationIds"
                #solarPanelsLocationIds="ngModel"
                name="solarPanelsLocationIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="solarPanelsLocationOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let solarPanelsLocationOptionType of solarPanelsLocationOptionTypes | keyvalue"
                  [value]="solarPanelsLocationOptionType.key"
                  [title]="solarPanelsLocationOptionType.value.displayName"
                  [disabled]="solarPanelsLocationOptionType.value.isDisabled"
                >{{ solarPanelsLocationOptionType.value.displayName }}</mat-option
                >
              </mat-select>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[solarPanelsLocationIds.name]"-->
              <!--                [class.text-secondary-primary]="notes[solarPanelsLocationIds.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[solarPanelsLocationIds.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="solarPanelsLocationIds.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Räddningstjänstbrytare för solcellsanläggning tillämpas">
              <mat-label>Räddningstjänstbrytare för solcellsanläggning tillämpas</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !isEmergencyServiceSwitchForSolarCellSystemAppliedEnabled"
                [(ngModel)]="document.opportunities.isEmergencyServiceSwitchForSolarCellSystemApplied"
                #isEmergencyServiceSwitchForSolarCellSystemApplied="ngModel"
                name="isEmergencyServiceSwitchForSolarCellSystemApplied"
              />
              <mat-radio-group
                class="d-block"
                name="isEmergencyServiceSwitchForSolarCellSystemAppliedRadio"
                [disabled]="!hasEditPermissions || !isEmergencyServiceSwitchForSolarCellSystemAppliedEnabled"
                [(ngModel)]="document.opportunities.isEmergencyServiceSwitchForSolarCellSystemApplied"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Placering av räddningstjänstbrytare">
              <mat-label>Placering av räddningstjänstbrytare</mat-label>
              <input
                type="text"
                matInput
                required
                [disabled]="!hasEditPermissions || !document.opportunities.isEmergencyServiceSwitchForSolarCellSystemApplied"
                [(ngModel)]="document.opportunities.locationOfEmergencyServiceSwitch"
                #locationOfEmergencyServiceSwitch="ngModel"
                name="locationOfEmergencyServiceSwitch"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Särskilt krav på tillträde till källare under översta källarplanet"
            >
              <mat-label title="Special requirements for access to basements below the top basement level"
                >Särskilt krav på tillträde till källare under översta källarplanet</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.hasBasementSpecialRequirementsAccess"
                #hasBasementSpecialRequirementsAccess="ngModel"
                name="hasBasementSpecialRequirementsAccess"
              />
              <mat-radio-group
                class="d-block"
                name="hasBasementSpecialRequirementsAccessRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.hasBasementSpecialRequirementsAccess"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasBasementSpecialRequirementsAccess.name]"-->
<!--                [class.text-secondary-primary]="notes[hasBasementSpecialRequirementsAccess.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasBasementSpecialRequirementsAccess.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasBasementSpecialRequirementsAccess.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Särskilda krav på maximalt avstånd 50 meter från angreppsväg vid Vk 5C">
              <mat-label>Särskilda krav på maximalt avstånd 50 meter från angreppsväg vid Vk 5C</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !isHasSpecialRequirementsForMaxDistanceOf50MEnabled"
                [(ngModel)]="document.opportunities.hasSpecialRequirementsForMaxDistanceOf50M"
                #hasSpecialRequirementsForMaxDistanceOf50M="ngModel"
                name="hasSpecialRequirementsForMaxDistanceOf50M"
              />
              <mat-radio-group
                class="d-block"
                name="hasSpecialRequirementsForMaxDistanceOf50MRadio"
                [disabled]="!hasEditPermissions || !isHasSpecialRequirementsForMaxDistanceOf50MEnabled"
                [(ngModel)]="document.opportunities.hasSpecialRequirementsForMaxDistanceOf50M"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <div *ngIf="!isHasSpecialRequirementsForMaxDistanceOf50MEnabled" class="text-xs text-grey">
                Definiera verksamhetsklass Vk 5C under fliken Byggnad- och verksamhetsbeskrivning - Följande verksamheter förekommer i byggnaden
              </div>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Räddningshiss finns i byggnaden">
              <mat-label title="A rescue lift is available in the building">Räddningshiss finns i byggnaden</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.hasRescueLiftInBuilding"
                #hasRescueLiftInBuilding="ngModel"
                name="hasRescueLiftInBuilding"
              />
              <mat-radio-group
                class="d-block"
                name="hasRescueLiftInBuildingRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.hasRescueLiftInBuilding"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasRescueLiftInBuilding.name]"-->
<!--                [class.text-secondary-primary]="notes[hasRescueLiftInBuilding.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasRescueLiftInBuilding.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasRescueLiftInBuilding.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Räddningsväg finns">
              <mat-label title="An escape route is available">Räddningsväg finns</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.isEscapeRouteAvailable"
                #isEscapeRouteAvailable="ngModel"
                name="isEscapeRouteAvailable"
              />
              <mat-radio-group
                class="d-block"
                name="isEscapeRouteAvailableRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.isEscapeRouteAvailable"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isEscapeRouteAvailable.name]"-->
<!--                [class.text-secondary-primary]="notes[isEscapeRouteAvailable.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isEscapeRouteAvailable.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isEscapeRouteAvailable.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Insatsplaner finns till byggnaden">
              <mat-label title="Action plans are available for the building"
              >Insatsplaner finns till byggnaden</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.isActionPlansAvailable"
                #isActionPlansAvailable="ngModel"
                name="isActionPlansAvailable"
              />
              <mat-radio-group
                class="d-block"
                name="isActionPlansAvailableRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.isActionPlansAvailable"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[isActionPlansAvailable.name]"-->
              <!--                [class.text-secondary-primary]="notes[isActionPlansAvailable.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[isActionPlansAvailable.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="isActionPlansAvailable.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Branddamm tillämpas">
              <mat-label title="Fire dust is applied">Branddamm tillämpas</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.isFireDustIsApplied"
                #isFireDustIsApplied="ngModel"
                name="isFireDustIsApplied"
              />
              <mat-radio-group
                class="d-block"
                name="isFireDustIsApplied"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.isFireDustIsApplied"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isFireDustIsApplied.name]"-->
<!--                [class.text-secondary-primary]="notes[isFireDustIsApplied.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isFireDustIsApplied.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isFireDustIsApplied.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Stigarledning">
              <mat-label title="Ascension line">Stigarledning</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.hasAscensionLine"
                #hasAscensionLine="ngModel"
                name="hasAscensionLine"
              />
              <mat-radio-group
                class="d-block"
                name="hasAscensionLine"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.hasAscensionLine"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasAscensionLine.name]"-->
<!--                [class.text-secondary-primary]="notes[hasAscensionLine.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasAscensionLine.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasAscensionLine.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Trycksatt">
              <mat-label title="Pressurized">Trycksatt</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.opportunities.hasAscensionLine || !hasEditPermissions"
                [(ngModel)]="document.opportunities.isAscensionLinePressurized"
                #isAscensionLinePressurized="ngModel"
                name="isAscensionLinePressurized"
              />
              <mat-radio-group
                class="d-block"
                name="isAscensionLinePressurized"
                [disabled]="!document.opportunities.hasAscensionLine || !hasEditPermissions"
                [(ngModel)]="document.opportunities.isAscensionLinePressurized"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isAscensionLinePressurized.name]"-->
<!--                [class.text-secondary-primary]="notes[isAscensionLinePressurized.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isAscensionLinePressurized.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isAscensionLinePressurized.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-sm-9 col-xl-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Tillgång till släckvatten finns genom"
              [values]="extinguishingWaterAccessOptionTypes"
              [selectedKeys]="document.opportunities.extinguishingWaterAccessIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="extinguishingWaterAccessIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Tillgång till släckvatten finns genom"
              *ngIf="hasEditPermissions"
            >
              <mat-label title="Access to extinguishing water is available through"
                >Tillgång till släckvatten finns genom</mat-label
              >

              <mat-select
                required
                [(ngModel)]="document.opportunities.extinguishingWaterAccessIds"
                #extinguishingWaterAccessIds="ngModel"
                name="extinguishingWaterAccessIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="extinguishingWaterAccessOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let extinguishingWaterAccessOptionType of extinguishingWaterAccessOptionTypes | keyvalue"
                  [value]="extinguishingWaterAccessOptionType.key"
                  [title]="extinguishingWaterAccessOptionType.value.displayName"
                  [disabled]="extinguishingWaterAccessOptionType.value.isDisabled"
                  >{{ extinguishingWaterAccessOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[extinguishingWaterAccessIds.name]"-->
<!--                [class.text-secondary-primary]="notes[extinguishingWaterAccessIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[extinguishingWaterAccessIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="extinguishingWaterAccessIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3">
            <mat-form-field class="w-100" *ngIf="isExtinguishingWaterAccessOtherEnabled">
              <mat-label>Annat</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.extinguishingWaterAccessOther"
                #extinguishingWaterAccessOther="ngModel"
                name="extinguishingWaterAccessOther"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[extinguishingWaterAccessOther.name]"-->
<!--                [class.text-secondary-primary]="notes[extinguishingWaterAccessOther.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[extinguishingWaterAccessOther.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="extinguishingWaterAccessOther.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende räddningstjänstens insatsmöjligheter">
              <mat-label title="Extended description regarding the rescue service's intervention possibilities"
                >Utökad beskrivning avseende räddningstjänstens insatsmöjligheter</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.opportunities.rescueServicesInterventionDescription"
                #rescueServicesInterventionDescription="ngModel"
                name="rescueServicesInterventionDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[rescueServicesInterventionDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[rescueServicesInterventionDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[rescueServicesInterventionDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="rescueServicesInterventionDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
