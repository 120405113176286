<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'Luftbehandlingsinstallationer'"
    [documentId]="document?.id"
    [isCompleted]="document?.airtreatment.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-9 col-xl-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Följande skyddsmetod tillämpas för skydd mot spridning av brandgaser mellan brandceller"
              [values]="fireGasesProtectionOptionTypes"
              [selectedKeys]="document.airtreatment.fireGasesProtectionOptionIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="fireGasesProtectionOptionIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Följande skyddsmetod tillämpas för skydd mot spridning av brandgaser mellan brandceller"
              *ngIf="hasEditPermissions"
            >
              <mat-label>Följande skyddsmetod tillämpas för skydd mot spridning av brandgaser mellan brandceller</mat-label>
              <mat-select
                required
                [(ngModel)]="document.airtreatment.fireGasesProtectionOptionIds"
                #fireGasesProtectionOptionIds="ngModel"
                name="fireGasesProtectionOptionIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="fireGasesProtectionOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let fireGasesProtectionOptionType of fireGasesProtectionOptionTypes | keyvalue"
                  [value]="fireGasesProtectionOptionType.key"
                  [title]="fireGasesProtectionOptionType.value.displayName"
                  [disabled]="fireGasesProtectionOptionType.value.isDisabled"
                  >{{ fireGasesProtectionOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[fireGasesProtectionOptionIds.name]"-->
<!--                [class.text-secondary-primary]="notes[fireGasesProtectionOptionIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[fireGasesProtectionOptionIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="fireGasesProtectionOptionIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3">
            <mat-form-field class="w-100" *ngIf="isFireGasesProtectionOtherEnabled">
              <mat-label>Annat</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.airtreatment.fireGasesProtectionOther"
                #fireGasesProtectionOther="ngModel"
                name="fireGasesProtectionOther"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[fireGasesProtectionOther.name]"-->
<!--                [class.text-secondary-primary]="notes[fireGasesProtectionOther.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[fireGasesProtectionOther.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="fireGasesProtectionOther.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Dokumenterad analytisk dimensionering av tryckavlastning via beräkning förekommer">
              <mat-label>Dokumenterad analytisk dimensionering av tryckavlastning via beräkning förekommer</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccursEnabled"
                [(ngModel)]="document.airtreatment.isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs"
                #isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs="ngModel"
                name="isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs"
              />
              <mat-radio-group
                class="d-block"
                name="isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccursRadio"
                [disabled]="!hasEditPermissions || !isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccursEnabled"
                [(ngModel)]="document.airtreatment.isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[isSmokeDuctsInside.name]"-->
              <!--                [class.text-secondary-primary]="notes[isSmokeDuctsInside.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[isSmokeDuctsInside.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="isSmokeDuctsInside.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Brandstyrning av ventilationssystemet sker via"
              [values]="fireControlVentilationSystemOptionTypes"
              [selectedKeys]="document.airtreatment.fireControlVentilationSystemOptionIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="fireControlVentilationSystemOptionIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Brandstyrning av ventilationssystemet sker via"
              *ngIf="hasEditPermissions"
            >
              <mat-label>Brandstyrning av ventilationssystemet sker via</mat-label>
              <mat-select
                required
                [(ngModel)]="document.airtreatment.fireControlVentilationSystemOptionIds"
                #fireControlVentilationSystemOptionIds="ngModel"
                name="fireControlVentilationSystemOptionIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="fireControlVentilationSystemOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let fireControlVentilationSystemOptionType of fireControlVentilationSystemOptionTypes | keyvalue
                  "
                  [value]="fireControlVentilationSystemOptionType.key"
                  [title]="fireControlVentilationSystemOptionType.value.displayName"
                  [disabled]="fireControlVentilationSystemOptionType.value.isDisabled"
                  >{{ fireControlVentilationSystemOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[fireControlVentilationSystemOptionIds.name]"-->
<!--                [class.text-secondary-primary]="notes[fireControlVentilationSystemOptionIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[fireControlVentilationSystemOptionIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="fireControlVentilationSystemOptionIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Imkanaler förekommer inom byggnaden">
              <mat-label>Imkanaler förekommer inom byggnaden</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.airtreatment.isSmokeDuctsInside"
                #isSmokeDuctsInside="ngModel"
                name="isSmokeDuctsInside"
              />
              <mat-radio-group
                class="d-block"
                name="isSmokeDuctsInsideRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.airtreatment.isSmokeDuctsInside"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isSmokeDuctsInside.name]"-->
<!--                [class.text-secondary-primary]="notes[isSmokeDuctsInside.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isSmokeDuctsInside.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isSmokeDuctsInside.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Dokumenterad skyddslösning för imkanaler förekommer">
              <mat-label>Dokumenterad skyddslösning för imkanaler förekommer</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !document.airtreatment.isSmokeDuctsInside"
                [(ngModel)]="document.airtreatment.hasDocumentedProtectionSolutionForImkanal"
                #hasDocumentedProtectionSolutionForImkanal="ngModel"
                name="hasDocumentedProtectionSolutionForImkanal"
              />
              <mat-radio-group
                class="d-block"
                name="hasDocumentedProtectionSolutionForImkanalRadio"
                [disabled]="!hasEditPermissions || !document.airtreatment.isSmokeDuctsInside"
                [(ngModel)]="document.airtreatment.hasDocumentedProtectionSolutionForImkanal"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[isSmokeDuctsInside.name]"-->
              <!--                [class.text-secondary-primary]="notes[isSmokeDuctsInside.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[isSmokeDuctsInside.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="isSmokeDuctsInside.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Driftkort/flödesschema som beskriver ventilationstekniska brandskyddsfunktioner finns tillgängliga">
              <mat-label>Driftkort/flödesschema som beskriver ventilationstekniska brandskyddsfunktioner finns tillgängliga</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.airtreatment.isOperatingCardFlowChartFireProtectionFunctionAvailable"
                #isOperatingCardFlowChartFireProtectionFunctionAvailable="ngModel"
                name="isOperatingCardFlowChartFireProtectionFunctionAvailable"
              />
              <mat-radio-group
                class="d-block"
                name="isOperatingCardFlowChartFireProtectionFunctionAvailableRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.airtreatment.isOperatingCardFlowChartFireProtectionFunctionAvailable"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende luftbehandlingsinstallationer">
              <mat-label>Utökad beskrivning avseende luftbehandlingsinstallationer</mat-label>
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.airtreatment.airTreatmentDescription"
                #airTreatmentDescription="ngModel"
                name="airTreatmentDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[airTreatmentDescription.name]"-->
              <!--                [class.text-secondary-primary]="notes[airTreatmentDescription.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[airTreatmentDescription.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="airTreatmentDescription.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
