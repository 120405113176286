import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Assert } from 'src/app/shared';

export const forbiddenCharactersValidationKey = 'forbiddenCharacters';

export function forbiddenCharactersValidator(forbiddenCharacters: string[]): ValidatorFn {
  Assert.isNotNull(forbiddenCharacters, 'forbiddenCharacters');

  const characters = '\\' + forbiddenCharacters.join('\\');
  const validationPattern = new RegExp(`^[^${characters}]*$`, 'i');

  const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    Assert.isNotNull(control, 'control');

    const isValid = validationPattern.test(control.value);
    return !isValid ? { [forbiddenCharactersValidationKey]: true } : null;
  };

  return validator;
}
