import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UserDetails } from '../shared';
import { FieldSortOrder } from 'src/app/core/collections';
import { validate, required } from 'src/app/shared';
import { AuthDetails } from 'src/app/authentication/shared';
import { UsersType } from 'src/app/authentication/shared/users-type';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent {
  readonly fieldNames: {
    firstName: string;
    role: string;
    email: string;
    docsCount: string;
  };

  @Input()
  items: UserDetails[];

  @Input()
  sortOrder: FieldSortOrder;

  @Input()
  isDisabled: boolean;

  @Input()
  currentUserAuthDetails: AuthDetails;

  @Input()
  organizationId: string;

  @Input()
  usersType: UsersType;

  @Output()
  readonly userRoleChange: EventEmitter<UserDetails>;

  @Output()
  readonly passwordReset: EventEmitter<UserDetails>;

  @Output()
  readonly deleteUser: EventEmitter<UserDetails>;

  @Output()
  readonly sortOrderChanged: EventEmitter<FieldSortOrder>;

  @Output()
  readonly userMoved: EventEmitter<UserDetails>;

  @Output()
  readonly userEdited: EventEmitter<UserDetails>;

  constructor() {
    this.sortOrderChanged = new EventEmitter();
    this.passwordReset = new EventEmitter();
    this.userRoleChange = new EventEmitter();
    this.deleteUser = new EventEmitter();
    this.userMoved = new EventEmitter();
    this.userEdited = new EventEmitter();

    this.fieldNames = {
      firstName: 'user.firstName',
      role: 'role',
      email: 'user.email',
      docsCount: 'stats.docsCount',
    };
  }

  @validate
  trackByItem(index: number, @required item: UserDetails): string {
    return item.user.id;
  }

  onSortOrderChanged(sortOrder: FieldSortOrder) {
    this.sortOrderChanged.emit(sortOrder);
  }

  @validate
  onUserRoleChange(@required userWithRole: UserDetails) {
    this.userRoleChange.emit(userWithRole);
  }

  @validate
  onForcePasswordReset(@required item: UserDetails) {
    this.passwordReset.emit(item);
  }

  @validate
  onDeleteUser(@required item: UserDetails) {
    this.deleteUser.emit(item);
  }

  @validate
  onUserMoved(@required item: UserDetails) {
    this.userMoved.emit(item);
  }

  onUserEdited(item: UserDetails): void {
    this.userEdited.next(item);
  }
}
