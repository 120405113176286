import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Unsubscribe } from '../core/decorators';
import { DocumentSelectOptionType } from '../documents/shared/document-structure/document-select-option-type';

@Unsubscribe()
@Directive({
  selector: '[appDocumentNotApplicableSelectOptions][ngModel]',
})
export class DocumentNotApplicableSelectOptionsDirective implements OnInit, OnDestroy {
  // tslint:disable-next-line: no-input-rename
  @Input('appDocumentNotApplicableSelectOptions')
  options: StringMap<DocumentSelectOptionType>;

  @Output()
  ngModelChange: EventEmitter<any> = new EventEmitter();

  private modelSubscription: Subscription;

  constructor(private model: NgModel) {}

  ngOnInit(): void {
    this.modelSubscription = this.model.control.valueChanges.subscribe((selectedKeys: string[]) => {
      this.updateDisabledStatus(selectedKeys, this.options);
    });
  }
  ngOnDestroy(): void {}

  @HostListener('selectionChange', ['$event'])
  onInputChange($event) {
    const selectedKeys = ($event?.value as string[]) || [];

    const blockerOption = this.filterBlockerOption(selectedKeys, this.options);
    const hasBlockerOption = !!blockerOption;
    if (!hasBlockerOption) {
      return;
    }

    const singleBlockerValue = [blockerOption.id];
    this.ngModelChange.emit(singleBlockerValue);
  }

  private filterBlockerOption(
    selectedKeys: string[],
    options: StringMap<DocumentSelectOptionType>
  ): DocumentSelectOptionType {
    const blockerOption = _.chain(options)
      .map((value) => value)
      .filter((value) => _.includes(selectedKeys, value.id))
      .filter((value) => value.isBlockerOption)
      .first()
      .value();

    return blockerOption;
  }

  private updateDisabledStatus(selectedKeys: string[], options: StringMap<DocumentSelectOptionType>): void {
    const blockerOption = this.filterBlockerOption(selectedKeys, options);
    const hasBlockerOption = !!blockerOption;
    _.forOwn(options, (option) => {
      option.isDisabled = hasBlockerOption && option !== blockerOption;
    });
  }
}
