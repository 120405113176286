import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { validate, required } from 'src/app/shared';
import { Document } from '../shared';
import { v4 as uuid } from 'uuid';
import { AddDocumentAction, DeleteDocumentAction, ResetDocumentsAction } from './documents.actions';
import { selectDocumentById, selectDocuments } from './documents.selectors';

@Injectable({
  providedIn: 'root',
})
export class DocumentsStoreService {
  private readonly store: Store<AppState>;

  constructor(store: Store<AppState>) {
    this.store = store;
  }

  @validate
  addDocument(@required document: Document) {
    this.store.dispatch(new AddDocumentAction(document));
  }

  @validate
  deleteDocument(@required documentId: uuid) {
    this.store.dispatch(new DeleteDocumentAction(documentId));
  }

  resetDocuments() {
    this.store.dispatch(new ResetDocumentsAction());
  }

  getDocuments(): Observable<Document[]> {
    const selectDocuments$ = this.store.select(selectDocuments);

    return selectDocuments$;
  }

  @validate
  getDocumentById(@required documentId: uuid): Observable<Document> {
    const selectDocumentById$ = this.store.select(selectDocumentById, { id: documentId });

    return selectDocumentById$;
  }
}
