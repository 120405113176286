import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  SimpleChanges,
  OnChanges,
  SimpleChange,
} from '@angular/core';
import { UserDetails } from '../shared';
import { LoggerService, coreConfig } from 'src/app/core';
import { DataSourceService, DataSource, DataSourceState, FieldSortOrder } from 'src/app/core/collections';
import { usersConfig } from '../users.config';
import { validate, required, PromiseUtils, Assert } from 'src/app/shared';
import { ErrorModalComponent } from 'src/app/core/error-modal/error-modal.component';
import { UsersApiService } from '../users-api.service';
import { AuthenticationStoreService } from 'src/app/authentication/authentication-store';
import { takeUntil } from 'rxjs/operators';
import { AuthDetails } from 'src/app/authentication/shared';
import { Subject, Subscription } from 'rxjs';
import _ from 'lodash';
import { UserRole } from 'src/app/authentication/shared/user-role';
import { UsersType } from 'src/app/authentication/shared/users-type';
import { OrganizationUsersManagementService } from 'src/app/organization/organization-users-management.service';
import { MatDialog } from '@angular/material/dialog';
import { EditUserModalComponent } from '../edit-user-modal/edit-user-modal.component';
import { EditUserDialogData } from '../shared/edit-user-dialog-data';
import { ErrorModalDialogData } from 'src/app/core/shared/error-modal-dialog-data';
import { Unsubscribe } from 'src/app/core/decorators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Unsubscribe()
@Component({
  selector: 'app-internal-users',
  templateUrl: './internal-users.component.html',
  styleUrls: ['./internal-users.component.scss'],
})
export class InternalUsersComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  filter: string;

  @Input()
  currentPage: number;

  @Output()
  readonly userCreated: EventEmitter<UserDetails>;

  @Output()
  readonly itemsCountChanged: EventEmitter<number>;

  @Output()
  readonly currentPageChanged: EventEmitter<number>;

  state: {
    isLoading: boolean;
    isFailed: boolean;
    isRefreshing: boolean;
    isDeleting: boolean;
    isActionProcessing: boolean;
  };

  dataSource: DataSource<UserDetails>;
  dataSourceState: DataSourceState<UserDetails>;

  currentUserAuthDetails: AuthDetails;

  get UsersType() {
    return UsersType;
  }

  private unsubscribeAll: Subject<any>;
  private createInternalUserSubscription: Subscription;

  constructor(
    private readonly usersApiService: UsersApiService,
    private readonly loggerService: LoggerService,
    private readonly dataSourceService: DataSourceService,
    private authenticationStoreService: AuthenticationStoreService,
    private readonly organizationUsersManagementService: OrganizationUsersManagementService,
    private dialog: MatDialog,
    private readonly snackBar: MatSnackBar
  ) {
    this.userCreated = new EventEmitter();
    this.itemsCountChanged = new EventEmitter();
    this.currentPageChanged = new EventEmitter<number>();
    this.unsubscribeAll = new Subject();

    this.dataSource = new DataSource<UserDetails>();

    this.dataSourceState = new DataSourceState<UserDetails>({
      filter: '',
      searchableFields: usersConfig.filter.searchableFields,
      sortOrder: null,
      page: 0,
      pageSize: coreConfig.pagination.defaultPageSize,
    });

    this.state = {
      isLoading: false,
      isFailed: false,
      isRefreshing: false,
      isDeleting: false,
      isActionProcessing: false,
    };
  }

  async ngOnInit(): Promise<void> {
    this.authenticationStoreService
      .getAuthDetails()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(async (details: AuthDetails) => {
        this.currentUserAuthDetails = details;
      });

    this.loadUsers();

    this.createInternalUserSubscription = this.organizationUsersManagementService.createInternalUser$.subscribe(() => {
      this.onCreateInternalUser();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<InternalUsersComponent, SimpleChange>;

    const filterChange = componentChanges.filter;
    if (filterChange) {
      this.onFilterChanged(filterChange.currentValue);
    }

    const currentPageChange = componentChanges.currentPage;
    if (currentPageChange && currentPageChange.currentValue !== undefined) {
      this.onPageChanged(currentPageChange.currentValue);
    }
  }

  ngOnDestroy(): void {}

  onSortOrderChanged(sortOrder: FieldSortOrder): void {
    this.dataSourceState.page = 0;
    this.dataSourceState.sortOrder = sortOrder;
    this.dataSource = this.dataSourceService.sortDataSource(this.dataSource, this.dataSourceState);

    this.currentPageChanged.emit(0);
  }

  @validate
  onPageChanged(@required page: number) {
    this.dataSourceState.page = page;
    this.dataSource = this.dataSourceService.paginateDataSource(this.dataSource, this.dataSourceState);
  }

  onFilterChanged(filter: string) {
    this.dataSourceState.page = 0;
    this.dataSourceState.filter = filter;
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
  }

  @validate
  onUserCreated(@required userDetails: UserDetails) {
    this.dataSource = this.dataSourceService.addItemToDataSource(this.dataSource, this.dataSourceState, userDetails);
    this.userCreated.emit(userDetails);
    this.itemsCountChanged.emit(this.dataSource.filteredItems.length);
  }

  @validate
  async onDeleteUser(@required userDetails: UserDetails) {
    Assert.isNotNull(userDetails.user, 'userDetails.user');

    this.state.isDeleting = true;
    try {
      await this.usersApiService.deleteUser(userDetails.user.id);
      this.onUserDeleted(userDetails);
    } catch (error) {
      this.onFailedModal('Det gick inte att ta bort användaren - försök igen senare.', error);
    } finally {
      this.state.isDeleting = false;
    }
  }

  @validate
  onUserEdited(@required userDetails: UserDetails) {
    const usersComparator = (first: UserDetails, second: UserDetails) => first.user.id === second.user.id;

    this.dataSource = this.dataSourceService.updateItemInDataSource(
      this.dataSource,
      this.dataSourceState,
      userDetails,
      usersComparator
    );
  }

  @validate
  onUserMoved(@required userDetails: UserDetails) {
    this.onUserDeleted(userDetails);
  }

  onTryLoadUsers() {
    this.loadUsers();
  }

  async onRefreshUsers() {
    this.state.isRefreshing = true;
    try {
      const refreshPromise = this.refreshUsers();
      const minTimeoutPromise = PromiseUtils.delay(usersConfig.list.minRefreshTimeout);

      await Promise.all([refreshPromise, minTimeoutPromise]);
    } catch {
      // ignore
    } finally {
      this.state.isRefreshing = false;
    }
  }

  @validate
  private onUserDeleted(@required userDetails: UserDetails) {
    const usersComparator = (first: UserDetails, second: UserDetails) => first.user.id === second.user.id;
    this.dataSource = this.dataSourceService.deleteItemFromDataSource(
      this.dataSource,
      this.dataSourceState,
      userDetails,
      usersComparator
    );
    this.itemsCountChanged.emit(this.dataSource.filteredItems.length);
    this.showSnackBar(`Användare ${userDetails.user.firstName} ${userDetails.user.lastName} borttagen.`);
  }

  private async refreshUsers() {
    try {
      this.dataSource = await this.getDataSource(this.dataSourceState);
    } catch (error) {
      this.loggerService.error('Failed to refresh users', error);
    }
  }

  private async loadUsers() {
    this.state.isLoading = true;
    this.state.isFailed = false;

    this.dataSource = new DataSource<UserDetails>();

    try {
      this.dataSource = await this.getDataSource(this.dataSourceState);
      this.itemsCountChanged.emit(this.dataSource.filteredItems.length);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to load users', error);
    } finally {
      this.state.isLoading = false;
    }
  }

  @validate
  private async getDataSource(
    @required dataSourceState: DataSourceState<UserDetails>
  ): Promise<DataSource<UserDetails>> {
    let users = await this.usersApiService.getUsersByOrganizationId(null);
    users = _.filter(users, (item) => item.role === UserRole.superAdmin || item.role === UserRole.author);
    const dataSource = this.dataSourceService.createDataSource(users, dataSourceState);

    return dataSource;
  }

  @validate
  private onFailedModal(@required message: string, error: any) {
    this.loggerService.error(message, error);
    const errorModalDialogData = new ErrorModalDialogData({ title: message });
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: errorModalDialogData,
    });
  }

  private onCreateInternalUser() {
    Assert.isNotNull(this.dataSource, 'dataSource');

    const editUserDialogData = new EditUserDialogData({
      currentUserAuthDetails: this.currentUserAuthDetails,
      usersType: UsersType.InternalUsers,
      isEditMode: false,
    });
    const dialogRef = this.dialog.open(EditUserModalComponent, {
      data: editUserDialogData,
    });

    dialogRef.afterClosed().subscribe((userDetails: UserDetails) => {
      if (userDetails) {
        this.onUserCreated(userDetails);
      }
    });
  }

  private showSnackBar(@required message: string): void {
    this.snackBar.open(message, 'OK');
  }
}
