import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionSelectionChange } from '@angular/material/core';
import { find, keyBy } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { coreConfig } from 'src/app/core';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { DocumentFieldType } from 'src/app/documents/shared/document-structure/document-field-type';
import {
  DocumentSection,
  DocumentSectionField,
  DocumentSectionType
} from 'src/app/documents/shared/document-structure/document-section';
import { FilterPredicate } from 'src/app/documents/shared/document-structure/filter-predicate';
import { DocumentSectionFilter, DocumentSectionInfo } from '../../documents/shared';
import { DocumentUser } from '../../users-management/shared';
import { DocumentSelectOptionType } from '../../documents/shared/document-structure/document-select-option-type';
import { DocumentSelectOptionGroupType } from '../../documents/shared/document-structure/document-select-option-group-type';
import { DocumentOrganization } from '../../users-management/shared/document-organization';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-dashboard-header-filter',
  templateUrl: './dashboard-header-filter.component.html',
  styleUrls: ['./dashboard-header-filter.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: coreConfig.matDateFormats },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class DashboardHeaderFilterComponent implements OnInit, OnChanges {
  @Input()
  filter: DocumentSectionFilter;

  @Input()
  documentCreators: DocumentOrganization[];

  @Input()
  documentRevisers: DocumentOrganization[];

  @Output()
  readonly sectionFilterChanged: EventEmitter<DocumentSectionFilter>;

  @Output()
  readonly sectionFilterDeleted: EventEmitter<void>;

  data: {
    sections: DocumentSection[];
    filteredSections: DocumentSection[];

    fieldText: string;
    selectedSection: DocumentSection;
    selectedField: DocumentSectionField;
    selectedValue: any;
    selectedPredicate: FilterPredicate;
  };

  state: {
    filter$: Subject<string>;
  };

  get DocumentFieldType(): typeof DocumentFieldType {
    return DocumentFieldType;
  }

  get FilterPredicate(): typeof FilterPredicate {
    return FilterPredicate;
  }

  constructor(private readonly projectSectionDataService: DocumentsSectionDataService) {
    this.sectionFilterChanged = new EventEmitter();
    this.sectionFilterDeleted = new EventEmitter();

    this.state = {
      filter$: new Subject(),
    };

    this.data = {
      sections: [],
      filteredSections: [],

      fieldText: null,
      selectedSection: null,
      selectedField: null,
      selectedValue: null,
      selectedPredicate: 0,
    };
  }

  ngOnInit(): void {
    this.data.sections = this.projectSectionDataService.getDocumentSections();
    this.updateDocumentSectionFieldValues();

    this.state.filter$
      .pipe(debounceTime(coreConfig.filter.textFilterDebounceTime), distinctUntilChanged())
      .subscribe((filter: string) => {
        this.data.filteredSections = this.filterSections(filter);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<DashboardHeaderFilterComponent, SimpleChange>;

    const filterChange = componentChanges.filter;
    if (!filterChange) {
      return;
    }

    const newFilter = filterChange.currentValue as DocumentSectionFilter;
    if (!newFilter) {
      this.clearComponentValues();
      return;
    }

    const previousFilter = filterChange.previousValue as DocumentSectionFilter;
    const isFilterChanged = !previousFilter || previousFilter.id !== newFilter.id;

    if (isFilterChanged) {
      this.initComponentValues(newFilter, this.data.sections);
    }
  }

  private updateDocumentSectionFieldValues() {
    // Document creators
    const creatorsSection = this.data.sections.find((section: DocumentSection) => section.sectionId === DocumentSectionType.common)
      .fields
      .find((field: DocumentSectionField) => field.fieldId === DocumentSectionInfo.creatorId);
    const creatorsSelectOptionTypes = this.documentCreators.map((documentOrganization: DocumentOrganization) => {
      const selectOptions = documentOrganization.documentUsers.map((documentUser: DocumentUser) => new DocumentSelectOptionType(documentUser.id, documentUser.name));
      return new DocumentSelectOptionGroupType(documentOrganization.organizationName, selectOptions);
    });
    creatorsSection.values = keyBy(creatorsSelectOptionTypes, 'displayName') as StringMap<DocumentSelectOptionGroupType>;

    // Document revisers
    const revisersSection = this.data.sections.find((section: DocumentSection) => section.sectionId === DocumentSectionType.common)
      .fields
      .find((field: DocumentSectionField) => field.fieldId === DocumentSectionInfo.reviserId);
    const revisersSelectOptionType = this.documentRevisers.map((documentOrganization: DocumentOrganization) => {
      const selectOptions = documentOrganization.documentUsers.map((documentUser: DocumentUser) => new DocumentSelectOptionType(documentUser.id, documentUser.name));
      return new DocumentSelectOptionGroupType(documentOrganization.organizationName, selectOptions);
    });
    revisersSection.values = keyBy(revisersSelectOptionType, 'displayName') as StringMap<DocumentSelectOptionGroupType>;
  }

  onFilterChanged(filter: string) {
    this.state.filter$.next(filter);
  }

  onFieldSelected(section: any, field: any, event: MatOptionSelectionChange): void {
    if (!event.isUserInput) {
      return;
    }

    this.data.selectedSection = section;
    this.data.selectedField = field;
    this.data.selectedValue = null;
    this.data.selectedPredicate = FilterPredicate.Less;
  }

  onValueChanged(): void {
    const filter = new DocumentSectionFilter({
      id: this.filter.id,
      sectionId: this.data.selectedSection ? this.data.selectedSection.sectionId : null,
      fieldId: this.data.selectedField ? this.data.selectedField.fieldId : null,
      type: this.data.selectedField ? this.data.selectedField.type : null,
      value: this.data.selectedValue,
      predicate: this.data.selectedPredicate,
    });

    this.sectionFilterChanged.next(filter);
  }

  onClearSelectedField(): void {
    this.data.fieldText = null;
    this.data.selectedSection = null;
    this.data.selectedField = null;
    this.data.selectedValue = null;

    this.onValueChanged();
  }

  onClearSelectedValue(): void {
    this.data.selectedValue = null;

    this.onValueChanged();
  }

  onDeleteRow(): void {
    this.sectionFilterDeleted.next();
  }

  private clearComponentValues(): void {
    this.data.fieldText = null;
    this.data.selectedSection = null;
    this.data.selectedField = null;
    this.data.selectedValue = null;
  }

  private initComponentValues(documentSectionFilter: DocumentSectionFilter, sections: DocumentSection[]) {
    this.data.selectedSection = documentSectionFilter.sectionId
      ? find(sections, (section) => section.sectionId === documentSectionFilter.sectionId)
      : null;
    this.data.selectedField =
      documentSectionFilter.fieldId && this.data.selectedSection
        ? find(this.data.selectedSection.fields, (field) => field.fieldId === documentSectionFilter.fieldId)
        : null;
    this.data.selectedValue = this.data.selectedSection && this.data.selectedField ? documentSectionFilter.value : null;
    this.data.fieldText = this.data.selectedField ? this.data.selectedField.displayName : null;
  }

  private filterSections(value: string): DocumentSection[] {
    if (value) {
      return this.data.sections
        .map(
          (section) =>
            new DocumentSection({
              sectionId: section.sectionId,
              displayName: section.displayName,
              fields: this.filterFields(section.fields, value),
            })
        )
        .filter((section) => section.fields.length > 0);
    }

    return this.data.sections;
  }

  private filterFields(opt: DocumentSectionField[], value: string): DocumentSectionField[] {
    const filterValue = value.toLowerCase();

    return opt.filter((item) => item.displayName.toLowerCase().indexOf(filterValue) === 0);
  }
}
