<table class="users-table table data-table alt-table-white table-align-middle">
  <thead>
    <tr>
      <th>
        <app-sortable-table-header
          [fieldName]="fieldNames.firstName"
          [fieldSortOrder]="sortOrder"
          (sortOrderChanged)="onSortOrderChanged($event)"
        >
          <ng-container>Användarnamn</ng-container>
        </app-sortable-table-header>
      </th>

      <th class="email-column">
        <app-sortable-table-header
          [fieldName]="fieldNames.email"
          [fieldSortOrder]="sortOrder"
          (sortOrderChanged)="onSortOrderChanged($event)"
        >
          <ng-container>E-post</ng-container>
        </app-sortable-table-header>
      </th>

      <th class="role-column">
        <app-sortable-table-header
          [fieldName]="fieldNames.role"
          [fieldSortOrder]="sortOrder"
          (sortOrderChanged)="onSortOrderChanged($event)"
        >
          <ng-container>Roll</ng-container>
        </app-sortable-table-header>
      </th>

      <th class="action-column"></th>
    </tr>
  </thead>

  <tbody *ngIf="items | isEmpty">
    <tr>
      <td colspan="3" class="text-center text-muted">Inget att visa.</td>
    </tr>
  </tbody>

  <tbody
    app-users-table-row
    *ngFor="let item of items; let i = index; trackBy: trackByItem"
    [item]="item"
    [isDisabled]="isDisabled"
    [organizationId]="organizationId"
    [currentUserAuthDetails]="currentUserAuthDetails"
    [usersType]="usersType"
    (deleteUser)="onDeleteUser($event)"
    (userMoved)="onUserMoved($event)"
    (userEdited)="onUserEdited($event)"
  ></tbody>
</table>
