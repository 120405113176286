import { DocumentSelectOptionType } from './document-select-option-type';

export class DocumentSelectOptionGroupType {
  readonly displayName: string;
  readonly options: DocumentSelectOptionType[];

  constructor(displayName: string, options: DocumentSelectOptionType[]) {
    this.displayName = displayName;
    this.options = options;
  }
}
