import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { DataSourceState } from './data-source-state';
import { validate, required, RouterUtils, StringUtils } from 'src/app/shared';
import { coreConfig } from '../core.config';
import { SortOrder } from './sort-order';
import { FieldSortOrder } from './field-sort-order';
import { isArray, includes, isNumber, isString, every, isUndefined } from 'lodash';
import { StorageType, StorageFactoryService } from '../storage';
import { LoggerService } from '../logger.service';

@Injectable({
  providedIn: 'root',
})
export class DataSourceStateService {
  private readonly storageFactoryService: StorageFactoryService;
  private readonly loggerService: LoggerService;

  constructor(storageFactoryService: StorageFactoryService, loggerService: LoggerService) {
    this.storageFactoryService = storageFactoryService;
    this.loggerService = loggerService;
  }

  @validate
  serializeToStringMap<T = any>(@required dataSourceState: DataSourceState<T>): StringMap<string | number> {
    const queryParams: StringMap<string | number> = {};
    if (isNumber(dataSourceState.page)) {
      queryParams[coreConfig.api.params.dataSourceState.page] = dataSourceState.page;
    }

    if (isNumber(dataSourceState.pageSize)) {
      queryParams[coreConfig.api.params.dataSourceState.pageSize] = dataSourceState.pageSize;
    }

    if (isString(dataSourceState.filter) && dataSourceState.filter.length > 0) {
      queryParams[coreConfig.api.params.dataSourceState.filter] = dataSourceState.filter;
    }

    if (dataSourceState.sortOrder) {
      const field = isArray(dataSourceState.sortOrder.fieldName)
        ? (dataSourceState.sortOrder.fieldName as string[]).join(
            coreConfig.api.params.dataSourceState.sort.fieldsSeparator
          )
        : (dataSourceState.sortOrder.fieldName as string);

      queryParams[coreConfig.api.params.dataSourceState.sort.field] = field;
      queryParams[coreConfig.api.params.dataSourceState.sort.order] = dataSourceState.sortOrder.order;
    }

    return queryParams;
  }

  @validate
  deserializeFromStringMap<T = any>(
    @required serializedDataSourceState: StringMap<string | string[] | number>
  ): DataSourceState<T> {
    let sortField: string | string[] = serializedDataSourceState[
      coreConfig.api.params.dataSourceState.sort.field
    ] as string;

    if (includes(sortField, coreConfig.api.params.dataSourceState.sort.fieldsSeparator)) {
      sortField = (sortField as string).split(coreConfig.api.params.dataSourceState.sort.fieldsSeparator);
    }

    const sortOrder = serializedDataSourceState[coreConfig.api.params.dataSourceState.sort.order] as SortOrder;

    const fieldSortOrder = sortField ? new FieldSortOrder(sortField, sortOrder) : undefined;

    let filter = serializedDataSourceState[coreConfig.api.params.dataSourceState.filter] as string;
    if (StringUtils.isNullOrEmpty(filter)) {
      filter = undefined;
    }

    let page = +serializedDataSourceState[coreConfig.api.params.dataSourceState.page];
    if (isNaN(page)) {
      page = undefined;
    }

    let pageSize = +serializedDataSourceState[coreConfig.api.params.dataSourceState.pageSize];
    if (isNaN(pageSize) || !includes(coreConfig.pagination.pageSizeOptions, pageSize)) {
      pageSize = undefined;
    }

    const isEmpty = every([fieldSortOrder, filter, page, pageSize], (value: any) => isUndefined(value));

    let dataSourceState: DataSourceState<T>;

    if (!isEmpty) {
      dataSourceState = new DataSourceState<T>({
        filter: filter,
        page: page,
        pageSize: pageSize,
        sortOrder: fieldSortOrder,
      });
    } else {
      dataSourceState = null;
    }

    return dataSourceState;
  }

  @validate
  deserializeFromParamMap<T = any>(@required params: ParamMap): DataSourceState<T> {
    const stringMap = RouterUtils.convertParamMapToStringMap(params);

    const dataSourceState = this.deserializeFromStringMap(stringMap);

    return dataSourceState;
  }

  @validate
  save<T = any>(
    @required dataSourceState: DataSourceState<T>,
    @required key: string,
    @required storageType: StorageType
  ) {
    const serializedDataSourceState = this.serializeToStringMap(dataSourceState);

    const storage = this.storageFactoryService.getStorage(storageType);

    storage.set(key, serializedDataSourceState);
  }

  @validate
  restore<T = any>(
    @required key: string,
    @required storageType: StorageType,
    isRemoveFromStore: boolean = false
  ): DataSourceState<T> {
    const storage = this.storageFactoryService.getStorage(storageType);
    if (!storage.has(key)) {
      return null;
    }

    const serializedDataSourceState = storage.get<StringMap<string | number>>(key);

    let dataSourceState: DataSourceState<T>;
    try {
      dataSourceState = this.deserializeFromStringMap<T>(serializedDataSourceState);
    } catch (error) {
      dataSourceState = null;
      this.loggerService.warning(`Failed to restore data source state by key "${key}" from ${storageType}.`, error);
    }

    if (dataSourceState && isRemoveFromStore) {
      storage.remove(key);
    }

    return dataSourceState;
  }
}
