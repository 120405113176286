import { IDocumentItem } from './document';

export class DocumentInspection implements IDocumentItem {
  hasFunctionOfEscapeRoutes: boolean; //1.1
  hasDoorsWindowsEscapeRotes: boolean; //1.1.01
  hasFunctionOfEscapeRoutesIndicativeMarking: boolean; //1.1.11
  hasFunctionOfEscapeRoutesAccess: boolean; //1.1.12
  hasFunctionOfEscapeRoutesCommunicationEquipment: boolean; //1.1.13
  hasFunctionOfEscapeRoutesMarkingOfEvacuationSite: boolean; //1.1.14
  hasEvacuationPlanReadabilityMechanicalImpact: boolean; //1.1.21
  hasStatusOfEquipmentAndSignage: boolean; //1.1.31

  hasFireSpreadWithinFireCells: boolean; //2.1
  hasRegularControlOfChangeThroughWallCoverings: boolean; //2.1.01
  hasFireSpreadBetweenFireCells: boolean; //2.2
  hasDoorsWithinFireCellBoundary: boolean; //2.2.01
  hasInstallationDeviceOnDoorsWithinFireCellBoundary: boolean; //2.2.02
  hasAutomaticDoorOpeningFunctionOnDoorsWithinFireCellBoundary: boolean; //2.2.03
  hasGlassSectionsWithinFireCellBoundary: boolean; //2.2.11
  hasVentilationDucts: boolean; //2.2.21
  hasGlandsForCablesPipesDucts: boolean; //2.2.22
  hasWallsAndLoadBearingStructures: boolean; //2.2.23

  hasWarningMarkingSignageAndEmergencyLighting: boolean; //3.1
  hasSignageEmergencyLightingIndicativeMarkings: boolean; //3.1.01
  hasSignageEmergencyLightingSeparateEmergencyLightinguminaires: boolean; //3.1.02
  hasSignageEmergencyLightingEmergencyPowerSupplyBatteryBackup: boolean; //3.1.03
  hasSignageEmergencyLightingEmergencyPowerSupplyReservePowerSupply: boolean; //3.1.04
  hasBatteryLampInFixedChargingUnit: boolean; //3.1.05
  hasGeneralLightingStaircasesAndCorridorsInBuildingsWithMoreThanTwoStoreys: boolean; //3.1.11
  hasGeneralLightingLightingOfIlluminatedSigns: boolean; //3.1.12

  hasAutomaticFireAlarmAndEvacuationAlarm: boolean; //3.2
  hasAutomaticFireAlarmAndEvacuationAlarmAuditInspection: boolean; //3.2.01
  hasAutomaticFireAlarmAndEvacuationAlarmSystemMaintenance: boolean; //3.2.02
  hasAutomaticFireAlarmAndEvacuationAlarmSimplerChecksAndTests: boolean; //3.2.03

  hasAutomaticWaterSprinklerSystem: boolean; //3.3
  hasAutomaticWaterSprinklerSystemAuditInspection: boolean; //3.3.11
  hasAutomaticWaterSprinklerSystemSimplerChecksAndTests: boolean; //3.3.12
  hasAutomaticWaterSprinklerSystemCareAndMaintenanceByInstaller: boolean; //3.3.13
  hasResidentialSprinklerCareAndMaintenanceByInstaller: boolean; //3.3.21

  hasFixedExtinguishingSystemsForIncreasedPropertyProtection: boolean; //3.4
  hasSystemReviewByInspectorConstructionCompanyOrOperatingContractor: boolean; //3.4.01
  hasSimplerChecksAndTestsAccordingToConstructionCompanyInstructions: boolean; //3.4.02

  hasFireFightingEquipmentForPersonsOnSite: boolean; //3.5
  hasFireFightingEquipmentForPersonsOnSiteAnnualMaintenance: boolean; //3.5.01
  hasFireFightingEquipmentForPersonsOnSiteRoutineInspection: boolean; //3.5.02

  hasFireGasVentilation: boolean; //3.6
  hasFireGasVentilationManuallyMechanicallyOpenedDoorsWindows; //3.6.01
  hasFireGasVentilationRemoteControlledAutomaticOpeningDoorsWindows; //3.6.02
  hasFireGasVentilationRemoteControlledAutomaticActivationMechanical; //3.6.03

  hasVentilationSystem: boolean; //4.1
  hasVentilationSystemAnnualReviewOfFireProtectionFunctions: boolean; //4.1.01
  hasVentilationSystemFireDamper: boolean; //4.1.02
  hasVentilationSystemMistAndSmokeDucts: boolean; //4.1.03
  hasFanFunctionForFansInOperation: boolean; //4.1.04

  hasAccessingBuildings: boolean; //5.1
  hasAccessingBuildingsDrivewaysAndParkingSpacesForRescueService: boolean; //5.1.01
  hasAccessingBuildingsDoorsHatchesPassagesForAccess: boolean; //5.1.02
  hasAccessingBuildingsBetKeyIsApplied: boolean; //5.1.03
  hasAccessingBuildingsRoofSurfaces: boolean; //5.1.04

  hasRescueElevator: boolean; //5.2
  hasRescueElevatorSelfInspectionElevatorInstaller: boolean; //5.2.01
  hasRescueElevatorSignageAndMarkings: boolean; //5.2.02

  hasActionPlans: boolean; //5.3
  hasActionPlansRegularReview: boolean; //5.3.01

  hasExtinguishingWater: boolean; //5.4
  hasExtinguishingWaterFireHydrantsForRescueService: boolean; //5.4.01
  hasExtinguishingWaterFirePondAndParkingSpace: boolean; //5.4.02
  hasExtinguishingWaterSignageAndMarkings: boolean; //5.4.03

  hasLadders: boolean; //5.5
  hasLaddersDryRiserLine: boolean; //5.5.01
  hasLaddersPressurizedRiserLineAuditInspection: boolean; //5.5.02
  hasLaddersPressurizedRiserLineServiceAndMaintenance: boolean; //5.5.03
  hasLaddersConnectionAndOutletPointsForExtinguishingWater: boolean; //5.5.04
  hasLaddersSignageAndMarkings: boolean; //5.5.05

  hasFixedExtinguishingSystemsWithAidForTaskForce: boolean; //5.6
  hasSystemReviewByConstructionCompanyOrOperatingContractor: boolean; //5.6.01
  hasSimplerChecksAndTests: boolean; //5.6.02

  hasHeatingSystem: boolean; //6.1
  hasHeatingSystemOilGasSolidFuelBased: boolean; //6.1.01
  hasHeatingSystemFireplaceStoveConnectedToChimney: boolean; //6.1.02
  hasHeatingSystemSweepingAndFireProtectionControl: boolean; //6.1.03

  hasSpecialRisks: boolean; //6.2
  hasRisksHandlingFlammableGoods: boolean; //6.2.01
  hasRisksHandlingLpgBottles: boolean; //6.2.02
  hasRisksLaboratoryActivities: boolean; //6.2.03
  hasRisksBatteryChargingPoint: boolean; //6.2.04
  hasRisksOpenLoadingDock: boolean; //6.2.05
  hasRisksChipSuction: boolean; //6.2.06
  hasRisksExternalEditions: boolean; //6.2.07
  hasRisksFireplace: boolean; //6.2.08
  hasRisksHighVoltage: boolean; //6.2.09
  hasRisksOtherRisk: boolean; //6.2.10
  hasRisksFumeCupboard: boolean; //6.2.11
  hasRisksIndoorCisternStorage: boolean; //6.2.12
  hasRisksOutdoorCisternStorage: boolean; //6.2.13
  hasRisksFixedGasSystemsPipelines: boolean; //6.2.14
  hasRisksBoilerRoomOver60vm: boolean; //6.2.15
  hasRisksRadioactiveSubstances: boolean; //6.2.16
  hasRisksBatteryStorageDevices: boolean; //6.2.17
  hasRisksHazardousChemicalsPersonEnvironment: boolean; //6.2.18
  hasRisksMagnetism: boolean; //6.2.19
  hasRisksCookingDevice: boolean; //6.2.20
  hasRisksChemicalStorage: boolean; //6.2.21
  hasRisksCentralStorageOfInfectiousWaste: boolean; //6.2.22

  hasElectricalInstallations: boolean; //6.3
  hasElectricalInstallationsInspectionByAuthorizedInspector: boolean; //6.3.01
  hasElectricalInstallationsLightingFixturesAndHotSurfaces: boolean; //6.3.02
  hasElectricalInstallationsPowerPlants: boolean; //6.3.03
  hasElectricalInstallationsBatteryChargingPointsCanGenerateGas: boolean; //6.3.04
  hasElectricalInstallationsBatteryStorageLocationsStatusOfPremises: boolean; //6.3.05
  hasElectricalInstallationsSolarCellSystemStatusOfInstallation: boolean; //6.3.06

  hasExternalStorageFire: boolean; //6.4
  hasExternalStorageFireContainersCompactorsWarehousesEnvironmentalStations: boolean; //6.4.01
  hasExternalStorageFireCargoBerth: boolean; //6.4.02

  isCompleted: boolean;
  isValid: boolean;

  constructor({
    hasFunctionOfEscapeRoutes,
    hasDoorsWindowsEscapeRotes,
    hasFunctionOfEscapeRoutesIndicativeMarking,
    hasFunctionOfEscapeRoutesAccess,
    hasFunctionOfEscapeRoutesCommunicationEquipment,
    hasFunctionOfEscapeRoutesMarkingOfEvacuationSite,
    hasEvacuationPlanReadabilityMechanicalImpact,
    hasStatusOfEquipmentAndSignage,
    hasFireSpreadWithinFireCells,
    hasRegularControlOfChangeThroughWallCoverings,
    hasFireSpreadBetweenFireCells,
    hasDoorsWithinFireCellBoundary,
    hasInstallationDeviceOnDoorsWithinFireCellBoundary,
    hasAutomaticDoorOpeningFunctionOnDoorsWithinFireCellBoundary,
    hasGlassSectionsWithinFireCellBoundary,
    hasVentilationDucts,
    hasGlandsForCablesPipesDucts,
    hasWallsAndLoadBearingStructures,
    hasWarningMarkingSignageAndEmergencyLighting,
    hasSignageEmergencyLightingIndicativeMarkings,
    hasSignageEmergencyLightingSeparateEmergencyLightinguminaires,
    hasSignageEmergencyLightingEmergencyPowerSupplyBatteryBackup,
    hasSignageEmergencyLightingEmergencyPowerSupplyReservePowerSupply,
    hasBatteryLampInFixedChargingUnit,
    hasGeneralLightingStaircasesAndCorridorsInBuildingsWithMoreThanTwoStoreys,
    hasGeneralLightingLightingOfIlluminatedSigns,
    hasAutomaticFireAlarmAndEvacuationAlarm,
    hasAutomaticFireAlarmAndEvacuationAlarmAuditInspection,
    hasAutomaticFireAlarmAndEvacuationAlarmSystemMaintenance,
    hasAutomaticFireAlarmAndEvacuationAlarmSimplerChecksAndTests,
    hasAutomaticWaterSprinklerSystem,
    hasAutomaticWaterSprinklerSystemAuditInspection,
    hasAutomaticWaterSprinklerSystemSimplerChecksAndTests,
    hasAutomaticWaterSprinklerSystemCareAndMaintenanceByInstaller,
    hasResidentialSprinklerCareAndMaintenanceByInstaller,
    hasFixedExtinguishingSystemsForIncreasedPropertyProtection,
    hasSystemReviewByInspectorConstructionCompanyOrOperatingContractor,
    hasSimplerChecksAndTestsAccordingToConstructionCompanyInstructions,
    hasFireFightingEquipmentForPersonsOnSite,
    hasFireFightingEquipmentForPersonsOnSiteAnnualMaintenance,
    hasFireFightingEquipmentForPersonsOnSiteRoutineInspection,
    hasFireGasVentilation,
    hasFireGasVentilationManuallyMechanicallyOpenedDoorsWindows,
    hasFireGasVentilationRemoteControlledAutomaticOpeningDoorsWindows,
    hasFireGasVentilationRemoteControlledAutomaticActivationMechanical,
    hasVentilationSystem,
    hasVentilationSystemAnnualReviewOfFireProtectionFunctions,
    hasVentilationSystemFireDamper,
    hasVentilationSystemMistAndSmokeDucts,
    hasFanFunctionForFansInOperation,
    hasAccessingBuildings,
    hasAccessingBuildingsDrivewaysAndParkingSpacesForRescueService,
    hasAccessingBuildingsDoorsHatchesPassagesForAccess,
    hasAccessingBuildingsBetKeyIsApplied,
    hasAccessingBuildingsRoofSurfaces,
    hasRescueElevator,
    hasRescueElevatorSelfInspectionElevatorInstaller,
    hasRescueElevatorSignageAndMarkings,
    hasActionPlans,
    hasActionPlansRegularReview,
    hasExtinguishingWater,
    hasExtinguishingWaterFireHydrantsForRescueService,
    hasExtinguishingWaterFirePondAndParkingSpace,
    hasExtinguishingWaterSignageAndMarkings,
    hasLadders,
    hasLaddersDryRiserLine,
    hasLaddersPressurizedRiserLineAuditInspection,
    hasLaddersPressurizedRiserLineServiceAndMaintenance,
    hasLaddersConnectionAndOutletPointsForExtinguishingWater,
    hasLaddersSignageAndMarkings,
    hasFixedExtinguishingSystemsWithAidForTaskForce,
    hasSystemReviewByConstructionCompanyOrOperatingContractor,
    hasSimplerChecksAndTests,
    hasHeatingSystem,
    hasHeatingSystemOilGasSolidFuelBased,
    hasHeatingSystemFireplaceStoveConnectedToChimney,
    hasHeatingSystemSweepingAndFireProtectionControl,
    hasSpecialRisks,
    hasRisksHandlingFlammableGoods,
    hasRisksHandlingLpgBottles,
    hasRisksLaboratoryActivities,
    hasRisksBatteryChargingPoint,
    hasRisksOpenLoadingDock,
    hasRisksChipSuction,
    hasRisksExternalEditions,
    hasRisksFireplace,
    hasRisksHighVoltage,
    hasRisksOtherRisk,
    hasRisksFumeCupboard,
    hasRisksIndoorCisternStorage,
    hasRisksOutdoorCisternStorage,
    hasRisksFixedGasSystemsPipelines,
    hasRisksBoilerRoomOver60vm,
    hasRisksRadioactiveSubstances,
    hasRisksBatteryStorageDevices,
    hasRisksHazardousChemicalsPersonEnvironment,
    hasRisksMagnetism,
    hasRisksCookingDevice,
    hasRisksChemicalStorage,
    hasRisksCentralStorageOfInfectiousWaste,
    hasElectricalInstallations,
    hasElectricalInstallationsInspectionByAuthorizedInspector,
    hasElectricalInstallationsLightingFixturesAndHotSurfaces,
    hasElectricalInstallationsPowerPlants,
    hasElectricalInstallationsBatteryChargingPointsCanGenerateGas,
    hasElectricalInstallationsBatteryStorageLocationsStatusOfPremises,
    hasElectricalInstallationsSolarCellSystemStatusOfInstallation,
    hasExternalStorageFire,
    hasExternalStorageFireContainersCompactorsWarehousesEnvironmentalStations,
    hasExternalStorageFireCargoBerth,
    isCompleted,
    isValid,
  }: Partial<DocumentInspection> = {}) {
    this.hasFunctionOfEscapeRoutes = hasFunctionOfEscapeRoutes;
    this.hasDoorsWindowsEscapeRotes = hasDoorsWindowsEscapeRotes;
    this.hasFunctionOfEscapeRoutesIndicativeMarking = hasFunctionOfEscapeRoutesIndicativeMarking;
    this.hasFunctionOfEscapeRoutesAccess = hasFunctionOfEscapeRoutesAccess;
    this.hasFunctionOfEscapeRoutesCommunicationEquipment = hasFunctionOfEscapeRoutesCommunicationEquipment;
    this.hasFunctionOfEscapeRoutesMarkingOfEvacuationSite = hasFunctionOfEscapeRoutesMarkingOfEvacuationSite;
    this.hasEvacuationPlanReadabilityMechanicalImpact = hasEvacuationPlanReadabilityMechanicalImpact;
    this.hasStatusOfEquipmentAndSignage = hasStatusOfEquipmentAndSignage;
    this.hasFireSpreadWithinFireCells = hasFireSpreadWithinFireCells;
    this.hasRegularControlOfChangeThroughWallCoverings = hasRegularControlOfChangeThroughWallCoverings;
    this.hasFireSpreadBetweenFireCells = hasFireSpreadBetweenFireCells;
    this.hasDoorsWithinFireCellBoundary = hasDoorsWithinFireCellBoundary;
    this.hasInstallationDeviceOnDoorsWithinFireCellBoundary = hasInstallationDeviceOnDoorsWithinFireCellBoundary;
    this.hasAutomaticDoorOpeningFunctionOnDoorsWithinFireCellBoundary = hasAutomaticDoorOpeningFunctionOnDoorsWithinFireCellBoundary;
    this.hasGlassSectionsWithinFireCellBoundary = hasGlassSectionsWithinFireCellBoundary;
    this.hasVentilationDucts = hasVentilationDucts;
    this.hasGlandsForCablesPipesDucts = hasGlandsForCablesPipesDucts;
    this.hasWallsAndLoadBearingStructures = hasWallsAndLoadBearingStructures;
    this.hasWarningMarkingSignageAndEmergencyLighting = hasWarningMarkingSignageAndEmergencyLighting;
    this.hasSignageEmergencyLightingIndicativeMarkings = hasSignageEmergencyLightingIndicativeMarkings;
    this.hasSignageEmergencyLightingSeparateEmergencyLightinguminaires = hasSignageEmergencyLightingSeparateEmergencyLightinguminaires;
    this.hasSignageEmergencyLightingEmergencyPowerSupplyBatteryBackup = hasSignageEmergencyLightingEmergencyPowerSupplyBatteryBackup;
    this.hasSignageEmergencyLightingEmergencyPowerSupplyReservePowerSupply = hasSignageEmergencyLightingEmergencyPowerSupplyReservePowerSupply;
    this.hasBatteryLampInFixedChargingUnit = hasBatteryLampInFixedChargingUnit;
    this.hasGeneralLightingStaircasesAndCorridorsInBuildingsWithMoreThanTwoStoreys = hasGeneralLightingStaircasesAndCorridorsInBuildingsWithMoreThanTwoStoreys;
    this.hasGeneralLightingLightingOfIlluminatedSigns = hasGeneralLightingLightingOfIlluminatedSigns;
    this.hasAutomaticFireAlarmAndEvacuationAlarm = hasAutomaticFireAlarmAndEvacuationAlarm;
    this.hasAutomaticFireAlarmAndEvacuationAlarmAuditInspection = hasAutomaticFireAlarmAndEvacuationAlarmAuditInspection;
    this.hasAutomaticFireAlarmAndEvacuationAlarmSystemMaintenance = hasAutomaticFireAlarmAndEvacuationAlarmSystemMaintenance;
    this.hasAutomaticFireAlarmAndEvacuationAlarmSimplerChecksAndTests = hasAutomaticFireAlarmAndEvacuationAlarmSimplerChecksAndTests;
    this.hasAutomaticWaterSprinklerSystem = hasAutomaticWaterSprinklerSystem;
    this.hasAutomaticWaterSprinklerSystemAuditInspection = hasAutomaticWaterSprinklerSystemAuditInspection;
    this.hasAutomaticWaterSprinklerSystemSimplerChecksAndTests = hasAutomaticWaterSprinklerSystemSimplerChecksAndTests;
    this.hasAutomaticWaterSprinklerSystemCareAndMaintenanceByInstaller = hasAutomaticWaterSprinklerSystemCareAndMaintenanceByInstaller;
    this.hasResidentialSprinklerCareAndMaintenanceByInstaller = hasResidentialSprinklerCareAndMaintenanceByInstaller;
    this.hasFixedExtinguishingSystemsForIncreasedPropertyProtection = hasFixedExtinguishingSystemsForIncreasedPropertyProtection;
    this.hasSystemReviewByInspectorConstructionCompanyOrOperatingContractor = hasSystemReviewByInspectorConstructionCompanyOrOperatingContractor;
    this.hasSimplerChecksAndTestsAccordingToConstructionCompanyInstructions = hasSimplerChecksAndTestsAccordingToConstructionCompanyInstructions;
    this.hasFireFightingEquipmentForPersonsOnSite = hasFireFightingEquipmentForPersonsOnSite;
    this.hasFireFightingEquipmentForPersonsOnSiteAnnualMaintenance = hasFireFightingEquipmentForPersonsOnSiteAnnualMaintenance;
    this.hasFireFightingEquipmentForPersonsOnSiteRoutineInspection = hasFireFightingEquipmentForPersonsOnSiteRoutineInspection;
    this.hasFireGasVentilation = hasFireGasVentilation;
    this.hasFireGasVentilationManuallyMechanicallyOpenedDoorsWindows = hasFireGasVentilationManuallyMechanicallyOpenedDoorsWindows;
    this.hasFireGasVentilationRemoteControlledAutomaticOpeningDoorsWindows = hasFireGasVentilationRemoteControlledAutomaticOpeningDoorsWindows;
    this.hasFireGasVentilationRemoteControlledAutomaticActivationMechanical = hasFireGasVentilationRemoteControlledAutomaticActivationMechanical;
    this.hasVentilationSystem = hasVentilationSystem;
    this.hasVentilationSystemAnnualReviewOfFireProtectionFunctions = hasVentilationSystemAnnualReviewOfFireProtectionFunctions;
    this.hasVentilationSystemFireDamper = hasVentilationSystemFireDamper;
    this.hasVentilationSystemMistAndSmokeDucts = hasVentilationSystemMistAndSmokeDucts;
    this.hasFanFunctionForFansInOperation = hasFanFunctionForFansInOperation;
    this.hasAccessingBuildings = hasAccessingBuildings;
    this.hasAccessingBuildingsDrivewaysAndParkingSpacesForRescueService = hasAccessingBuildingsDrivewaysAndParkingSpacesForRescueService;
    this.hasAccessingBuildingsDoorsHatchesPassagesForAccess = hasAccessingBuildingsDoorsHatchesPassagesForAccess;
    this.hasAccessingBuildingsBetKeyIsApplied = hasAccessingBuildingsBetKeyIsApplied;
    this.hasAccessingBuildingsRoofSurfaces = hasAccessingBuildingsRoofSurfaces;
    this.hasRescueElevator = hasRescueElevator;
    this.hasRescueElevatorSelfInspectionElevatorInstaller = hasRescueElevatorSelfInspectionElevatorInstaller;
    this.hasRescueElevatorSignageAndMarkings = hasRescueElevatorSignageAndMarkings;
    this.hasActionPlans = hasActionPlans;
    this.hasActionPlansRegularReview = hasActionPlansRegularReview;
    this.hasExtinguishingWater = hasExtinguishingWater;
    this.hasExtinguishingWaterFireHydrantsForRescueService = hasExtinguishingWaterFireHydrantsForRescueService;
    this.hasExtinguishingWaterFirePondAndParkingSpace = hasExtinguishingWaterFirePondAndParkingSpace;
    this.hasExtinguishingWaterSignageAndMarkings = hasExtinguishingWaterSignageAndMarkings;
    this.hasLadders = hasLadders;
    this.hasLaddersDryRiserLine = hasLaddersDryRiserLine;
    this.hasLaddersPressurizedRiserLineAuditInspection = hasLaddersPressurizedRiserLineAuditInspection;
    this.hasLaddersPressurizedRiserLineServiceAndMaintenance = hasLaddersPressurizedRiserLineServiceAndMaintenance;
    this.hasLaddersConnectionAndOutletPointsForExtinguishingWater = hasLaddersConnectionAndOutletPointsForExtinguishingWater;
    this.hasLaddersSignageAndMarkings = hasLaddersSignageAndMarkings;
    this.hasFixedExtinguishingSystemsWithAidForTaskForce = hasFixedExtinguishingSystemsWithAidForTaskForce;
    this.hasSystemReviewByConstructionCompanyOrOperatingContractor = hasSystemReviewByConstructionCompanyOrOperatingContractor;
    this.hasSimplerChecksAndTests = hasSimplerChecksAndTests;
    this.hasHeatingSystem = hasHeatingSystem;
    this.hasHeatingSystemOilGasSolidFuelBased = hasHeatingSystemOilGasSolidFuelBased;
    this.hasHeatingSystemFireplaceStoveConnectedToChimney = hasHeatingSystemFireplaceStoveConnectedToChimney;
    this.hasHeatingSystemSweepingAndFireProtectionControl = hasHeatingSystemSweepingAndFireProtectionControl;
    this.hasSpecialRisks = hasSpecialRisks;
    this.hasRisksHandlingFlammableGoods = hasRisksHandlingFlammableGoods;
    this.hasRisksHandlingLpgBottles = hasRisksHandlingLpgBottles;
    this.hasRisksLaboratoryActivities = hasRisksLaboratoryActivities;
    this.hasRisksBatteryChargingPoint = hasRisksBatteryChargingPoint;
    this.hasRisksOpenLoadingDock = hasRisksOpenLoadingDock;
    this.hasRisksChipSuction = hasRisksChipSuction;
    this.hasRisksExternalEditions = hasRisksExternalEditions;
    this.hasRisksFireplace = hasRisksFireplace;
    this.hasRisksHighVoltage = hasRisksHighVoltage;
    this.hasRisksOtherRisk = hasRisksOtherRisk;
    this.hasRisksFumeCupboard = hasRisksFumeCupboard;
    this.hasRisksIndoorCisternStorage = hasRisksIndoorCisternStorage;
    this.hasRisksOutdoorCisternStorage = hasRisksOutdoorCisternStorage;
    this.hasRisksFixedGasSystemsPipelines = hasRisksFixedGasSystemsPipelines;
    this.hasRisksBoilerRoomOver60vm = hasRisksBoilerRoomOver60vm;
    this.hasRisksRadioactiveSubstances = hasRisksRadioactiveSubstances;
    this.hasRisksBatteryStorageDevices = hasRisksBatteryStorageDevices;
    this.hasRisksHazardousChemicalsPersonEnvironment = hasRisksHazardousChemicalsPersonEnvironment;
    this.hasRisksMagnetism = hasRisksMagnetism;
    this.hasRisksCookingDevice = hasRisksCookingDevice;
    this.hasRisksChemicalStorage = hasRisksChemicalStorage;
    this.hasRisksCentralStorageOfInfectiousWaste = hasRisksCentralStorageOfInfectiousWaste;
    this.hasElectricalInstallations = hasElectricalInstallations;
    this.hasElectricalInstallationsInspectionByAuthorizedInspector = hasElectricalInstallationsInspectionByAuthorizedInspector;
    this.hasElectricalInstallationsLightingFixturesAndHotSurfaces = hasElectricalInstallationsLightingFixturesAndHotSurfaces;
    this.hasElectricalInstallationsPowerPlants = hasElectricalInstallationsPowerPlants;
    this.hasElectricalInstallationsBatteryChargingPointsCanGenerateGas = hasElectricalInstallationsBatteryChargingPointsCanGenerateGas;
    this.hasElectricalInstallationsBatteryStorageLocationsStatusOfPremises = hasElectricalInstallationsBatteryStorageLocationsStatusOfPremises;
    this.hasElectricalInstallationsSolarCellSystemStatusOfInstallation = hasElectricalInstallationsSolarCellSystemStatusOfInstallation;
    this.hasExternalStorageFire = hasExternalStorageFire;
    this.hasExternalStorageFireContainersCompactorsWarehousesEnvironmentalStations = hasExternalStorageFireContainersCompactorsWarehousesEnvironmentalStations;
    this.hasExternalStorageFireCargoBerth = hasExternalStorageFireCargoBerth;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
