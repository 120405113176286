import { AbstractControl, ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { map, values, some } from 'lodash';
import { Assert } from 'src/app/shared';

export const requireSelectCheckboxGroupValidatorKey = 'requireSelectCheckboxGroup';

export function requireSelectCheckboxGroupValidator(): ValidatorFn {
  const validator: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
    Assert.isNotNull(group, 'group');

    const validatedControls = values(group.controls);

    const checkboxValues: boolean[] = map(validatedControls, (checkboxControl: AbstractControl) =>
      checkboxControl ? !!checkboxControl.value : false
    );

    const hasOneSelection = some(checkboxValues);

    if (!hasOneSelection) {
      return { [requireSelectCheckboxGroupValidatorKey]: true };
    }

    return null;
  };

  return validator;
}
