import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { required, validate } from 'src/app/shared';
import { organizationConfig } from '../organization.config';
import { OrganizationItem, OrganizationStatus } from '../shared';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-organizations-table-row]',
  templateUrl: './organizations-table-row.component.html',
  styleUrls: ['./organizations-table-row.component.scss'],
})
export class OrganizationsTableRowComponent implements OnChanges {
  @Input()
  item: OrganizationItem;

  @Input()
  isDisabled: boolean;

  @Input()
  hasSuperAdminPermissions: boolean;

  @Output()
  readonly activateOrganization: EventEmitter<OrganizationItem>;

  @Output()
  readonly deactivateOrganization: EventEmitter<OrganizationItem>;

  @Output()
  readonly organizationUpdated: EventEmitter<OrganizationItem>;

  isOrganizationExpired: boolean;

  get isActive() {
    return this.item.status === OrganizationStatus.Active;
  }

  get isInactive() {
    return this.item.status === OrganizationStatus.Deactivated;
  }

  constructor() {
    this.activateOrganization = new EventEmitter<OrganizationItem>();
    this.deactivateOrganization = new EventEmitter();
    this.organizationUpdated = new EventEmitter();

    this.isOrganizationExpired = false;
  }

  @validate
  onActivateOrganization(@required organization: OrganizationItem) {
    this.activateOrganization.emit(organization);
  }

  @validate
  onDeactivateOrganization(@required organization: OrganizationItem) {
    this.deactivateOrganization.emit(organization);
  }

  // @validate
  // onOrganizationUpdated(@required updatedOrganization: OrganizationItem) {
  //   this.organizationUpdated.emit(updatedOrganization);
  // }

  @validate
  getOrganizationRouterLink(@required organization: OrganizationItem): string[] {
    return ['/', organizationConfig.routes.organizations, organization.id];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<OrganizationsTableRowComponent, SimpleChange>;

    const organizationChange = componentChanges.item;
    if (!organizationChange) {
      return;
    }

    // const organization = organizationChange.currentValue as Organization;
  }
}
