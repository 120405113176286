<h1 mat-dialog-title>Inaktivera kund</h1>
<div mat-dialog-content>
  <div class="modal-body">
    <div>
      <div>
        Vill du inaktivera "{{ organization?.name }}"?
      </div>
      <div class="text-small">
        <i class="fas text-danger fa-exclamation-triangle"></i>
        <ng-container> Kundens användare kommer inte längre att kunna logga in. </ng-container>
      </div>

      <div class="mt-4">
        <div class="form-inline">
          <label class="mr-2" for="confirmationText">
            <ng-container>Skriv </ng-container>
            "{{ expectedConfirmationText }}"
            <ng-container> för att inaktivera</ng-container>
          </label>
          <input
            type="text"
            class="form-control form-control-sm"
            [(ngModel)]="confirmationText"
            name="confirmationText"
            id="confirmationText"
            cdkFocusInitial
            (keydown.enter)="onDelete()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-raised-button (click)="onCancel()">Avbryt</button>
  <button class="delete-btn" mat-raised-button (click)="onDelete()" [disabled]="!isConfirmed">
    Inaktivera
  </button>
</div>
