import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentTabContentComponent } from '../project-tab-container/project-tab-container.component';
import {
  Document,
  DocumentAirTreatment,
  DocumentAirTreatmentFireGasesProtectionOptionTypes,
  DocumentSectionType
} from 'src/app/documents/shared';
import { projectConfig } from '../project.config';
import { some } from 'lodash';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { DocumentsService } from 'src/app/documents/documents.service';
import { DocumentsValidationService } from 'src/app/documents/documents-validation.service';
import { DocumentSelectOptionType } from 'src/app/documents/shared/document-structure/document-select-option-type';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentUpdateValuesRuleService } from '../document-update-values-rule.service';

@Component({
  selector: 'app-project-tab-air-treatment',
  templateUrl: './project-tab-air-treatment.component.html',
  styleUrls: ['./project-tab-air-treatment.component.scss'],
})
export class ProjectTabAirTreatmentComponent extends DocumentTabContentComponent implements OnInit {
  @ViewChild('tabForm')
  tabForm: NgForm;

  fireGasesProtectionOptionTypes: StringMap<DocumentSelectOptionType>;
  fireControlVentilationSystemOptionTypes: StringMap<DocumentSelectOptionType>;

  isFireGasesProtectionOtherEnabled: boolean;

  get previousPageName(): string {
    return projectConfig.routes.installations;
  }

  get nextPageName(): string {
    return projectConfig.routes.lifts;
  }

  get isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccursEnabled(): boolean {
    return this.document.airtreatment.fireGasesProtectionOptionIds.some(id => id === DocumentAirTreatmentFireGasesProtectionOptionTypes.pressureReliefOfDuctSystemId);
  }

  constructor(
    dialog: MatDialog,
    snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private documentsValidationService: DocumentsValidationService,
    private documentsSectionDataService: DocumentsSectionDataService,
    private documentUpdateValuesRuleService: DocumentUpdateValuesRuleService,
  ) {
    super(DocumentSectionType.airtreatment, dialog, snackBar);
  }

  ngOnInit(): void {
    this.fireGasesProtectionOptionTypes = this.documentsSectionDataService.getFireGasesProtectionOptionTypes();
    this.fireControlVentilationSystemOptionTypes = this.documentsSectionDataService.getFireControlVentilationSystemOptionTypes();
  }

  async onCompleteForm(isCompleted: boolean): Promise<void> {
    this.document.airtreatment.isCompleted = isCompleted && this.document.airtreatment.isValid;
    await this.saveFormData(true);
  }

  setDocument(document: Document): void {
    this.document = document;

    const isOtherSelected =
      !this.document ||
      some(
        this.document.airtreatment.fireGasesProtectionOptionIds,
        (id) => id === DocumentAirTreatmentFireGasesProtectionOptionTypes.otherId
      );
    this.isFireGasesProtectionOtherEnabled = isOtherSelected;
    if (!isOtherSelected) {
      this.document.airtreatment.fireGasesProtectionOther = null;
    }
  }

  onFormDataChanged(): void {
    this.saveFormData(false);
  }

  private async saveFormData(formCompletedExplicitly: boolean): Promise<void> {
    if (!this.hasEditPermissions) {
      return;
    }

    this.documentUpdateValuesRuleService.processAirTreatmentRules(this.document);
    const isCompletedBeforeChange = this.document.airtreatment.isCompleted;
    this.document.airtreatment.isValid = this.documentsValidationService.isAirTreatmentValid(
      this.document.airtreatment,
      this.document
    );
    this.document.airtreatment.isCompleted = this.document.airtreatment.isCompleted && this.document.airtreatment.isValid;
    const partialDocument = {
      airtreatment: new DocumentAirTreatment({ ...this.document.airtreatment }),
    } as Document;

    try {
      await this.documentsService.patchDocumentAndSave(this.document, partialDocument);
    } catch (ex) {
      this.showErrorModal('Ett fel uppstod när ändringarna skulle sparas.', ex);
      return;
    }

    if (formCompletedExplicitly || (isCompletedBeforeChange && !this.document.airtreatment.isCompleted)) {
      this.showSectionCompletedSnackBar(DocumentSectionType.airtreatment, this.document.airtreatment.isCompleted);
    }
  }
}
