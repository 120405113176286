<div class="select-db-env-combobox">
  <div
    ngbDropdown
    #dropdown="ngbDropdown"
    class="dropdown-block dropdown-combobox floating-label"
    autoClose="outside"
    (openChange)="onDropdownOpenChanged($event)"
    [class.disabled]="isReadonly || isDisabled"
    [class.has-value]="selectedOrganization"
  >
    <button
      mat-flat-button
      class="button-border"
      ngbDropdownToggle
      [disabled]="isReadonly || isDisabled || state.isFailed || state.isLoading"
    >
      <span *ngIf="selectedOrganization">{{ selectedOrganization.name }} </span>
      <label
        >{{ placeholder || '' }}
        <span class="text-muted ml-1" *ngIf="state.isLoading"><i class="fas fa-spinner fa-pulse"></i></span
      ></label>
    </button>

    <div ngbDropdownMenu>
      <div class="form-group search-input-container m-1">
        <input
          type="text"
          class="form-control form-control-sm search-input"
          autofocus
          [(ngModel)]="dataSourceState.filter"
          (ngModelChange)="onFilterChanged()"
          placeholder="Organisation"
        />
      </div>

      <div class="dropdown-divider" *ngIf="dataSourceState.filter && dataSource.filteredItems.length > 0"></div>

      <div class="dropdown-items">
        <button
          class="dropdown-item"
          *ngFor="let item of dataSource.filteredItems; trackBy: trackByItem"
          (click)="onItemSelected(item)"
          [title]="item.name"
        >
          <div>
            <div>{{ item.name }}</div>
            <!-- <div class="text-muted">
              <span class="badge badge-secondary text-uppercase text-xs" *ngIf="item.payrollDbName">+ payroll</span>
              <span class="float-right" *ngIf="sqlServers && sqlServers[item.serverId]">
                on<span class="badge badge-info text-uppercase text-xs ml-1">
                  {{ sqlServers[item.serverId].name }}</span
                >
              </span>
            </div> -->
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
