import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { required, validate } from '../shared';
import { organizationConfig } from './organization.config';
import { Organization } from './shared';
import { UpdateOrganizationModalComponent } from './update-organization-modal/update-organization-modal.component';

@Directive({
  selector: '[appUpdateOrganizationTrigger]',
})
export class UpdateOrganizationTriggerDirective {
  private readonly dialog: MatDialog;
  private readonly router: Router;

  @Input()
  organizationId: string;

  @Input()
  redirectToOrganization: boolean;

  @Output()
  readonly updated: EventEmitter<Organization>;

  constructor(dialog: MatDialog, router: Router) {
    this.dialog = dialog;
    this.router = router;

    this.updated = new EventEmitter();
  }

  @HostListener('click')
  onClick() {
    if (this.organizationId) {
      this.updateOrganization(this.organizationId);
    }
  }

  @validate
  updateOrganization(@required organizationId: string) {
    const dialogRef = this.dialog.open(UpdateOrganizationModalComponent, {
      data: organizationId,
    });

    dialogRef.afterClosed().subscribe((organization: Organization) => {
      if (organization) {
        this.onOrganizationUpdated(organization);
      }
    });
  }

  @validate
  private onOrganizationUpdated(@required organization: Organization) {
    this.updated.next(organization);

    if (this.redirectToOrganization) {
      this.router.navigate(['/', organizationConfig.routes.organizations, organization.id]);
    }
  }
}
