import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import { LoggerService } from 'src/app/core';
import { ErrorResponse, StatusCode } from 'src/app/core/api';
import { ErrorModalComponent } from 'src/app/core/error-modal/error-modal.component';
import { ErrorModalDialogData } from 'src/app/core/shared/error-modal-dialog-data';
import { required, validate } from 'src/app/shared';
import { ChangePasswordDetails } from '../shared/change-password-details';
import { UsersApiService } from '../users-api.service';
import { usersConfig } from '../users.config';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
})
export class ChangePasswordFormComponent {
  @ViewChild('changePasswordForm', { static: false })
  changePasswordForm: NgForm;

  @Input()
  userId: string;

  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  changePasswordDetails: ChangePasswordDetails;

  @Output()
  readonly passwordChanged: EventEmitter<boolean>;

  @Output()
  readonly cancel: EventEmitter<void>;

  get passwordMinLength() {
    return usersConfig.profile.validation.password.minLength;
  }

  get canSubmitForm(): boolean {
    if (!this.changePasswordForm)
      return false;

    return !this.state.isLoading && this.changePasswordForm.valid && this.changePasswordForm.dirty && this.isNewPasswordEqual();
  }

  constructor(
    private usersApiService: UsersApiService,
    private loggerService: LoggerService,
    private dialog: MatDialog
  ) {
    this.passwordChanged = new EventEmitter();
    this.cancel = new EventEmitter();

    this.state = {
      isLoading: false,
      isFailed: false,
    };

    this.changePasswordDetails = new ChangePasswordDetails();
  }

  async onPasswordChange() {
    if (!this.canSubmitForm)
      return;

    const isFormValid = this.validateFields();
    if (!isFormValid) {
      return;
    }

    this.state.isLoading = true;

    try {
      await this.usersApiService.changePassword(this.changePasswordDetails, this.userId);

      this.passwordChanged.emit(true);
    } catch (error) {
      this.onChangePasswordFailed(error);
    } finally {
      this.state.isLoading = false;
    }
  }

  isNewPasswordEqual(): boolean {
    const isNewPasswordEqual = _.isEqual(
      this.changePasswordDetails.newPassword,
      this.changePasswordDetails.repeatNewPassword
    );
    return isNewPasswordEqual;
  }

  private onChangePasswordFailed(error: any) {
    const isErrorHandled = this.tryHandleError(error);
    if (!isErrorHandled) {
      this.loggerService.error('Failed to change the password', error);
      this.openErrorModal('Det gick inte att ändra lösenord, försök igen senare.');
    }
  }

  private tryHandleError(error: any): boolean {
    if (error && error instanceof ErrorResponse) {
      switch (error.status) {
        case StatusCode.Conflict:
          return true;
        case StatusCode.Unauthorized:
          this.onCancel();
          return true;
      }
    }
    return false;
  }

  @validate
  private openErrorModal(@required title: string) {
    const errorModalDialogData = new ErrorModalDialogData({ title: title });
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: errorModalDialogData,
    });
  }

  onCancel() {
    this.cancel.emit();
  }

  private validateFields(): boolean {
    this.changePasswordDetails.currentPassword = _.trim(this.changePasswordDetails.currentPassword);
    this.changePasswordDetails.newPassword = _.trim(this.changePasswordDetails.newPassword);
    this.changePasswordDetails.repeatNewPassword = _.trim(this.changePasswordDetails.repeatNewPassword);

    this.changePasswordForm.form.controls.currentPassword?.setValue(this.changePasswordDetails.currentPassword);
    this.changePasswordForm.form.controls.newPassword?.setValue(this.changePasswordDetails.newPassword);
    this.changePasswordForm.form.controls.repeatNewPassword?.setValue(this.changePasswordDetails.repeatNewPassword);
    this.changePasswordForm.form.updateValueAndValidity();

    if (!this.changePasswordForm.valid) {
      return false;
    }

    return true;
  }
}
