import { IDocumentItem } from './document';

export class DocumentOpportunities implements IDocumentItem {
  public static otherExtinguishingWaterAccessOptionId = 'other';

  rescueServiceResponseTimeId?: string;
  opportunitiesAccessibilityId?: string;
  isKeyAccess?: boolean;
  isKeyStoringOnObject?: boolean;
  locationOfKeyCabinet?: string;
  isCommunicationEquipmentForEvacuationInBuilding?: boolean;
  windAccessId?: string;
  solarPanelsLocationIds: string[];
  isEmergencyServiceSwitchForSolarCellSystemApplied?: boolean;
  locationOfEmergencyServiceSwitch?: string;
  hasBasementSpecialRequirementsAccess?: boolean;
  hasSpecialRequirementsForMaxDistanceOf50M?: boolean;
  hasRescueLiftInBuilding?: boolean;
  isEscapeRouteAvailable?: boolean;
  isActionPlansAvailable?: boolean;
  isFireDustIsApplied?: boolean;
  hasAscensionLine?: boolean;
  isAscensionLinePressurized?: boolean;
  extinguishingWaterAccessIds: string[];
  extinguishingWaterAccessOther?: string;
  rescueServicesInterventionDescription?: string;
  isCompleted: boolean;
  isValid: boolean;

  constructor({
    rescueServiceResponseTimeId,
    opportunitiesAccessibilityId,
    isKeyAccess,
    isKeyStoringOnObject,
    locationOfKeyCabinet,
    isCommunicationEquipmentForEvacuationInBuilding,
    windAccessId,
    solarPanelsLocationIds,
    isEmergencyServiceSwitchForSolarCellSystemApplied,
    locationOfEmergencyServiceSwitch,
    hasBasementSpecialRequirementsAccess,
    hasSpecialRequirementsForMaxDistanceOf50M,
    hasRescueLiftInBuilding,
    isEscapeRouteAvailable,
    isActionPlansAvailable,
    isFireDustIsApplied,
    hasAscensionLine,
    isAscensionLinePressurized,
    extinguishingWaterAccessIds,
    extinguishingWaterAccessOther,
    rescueServicesInterventionDescription,
    isCompleted,
    isValid,
  }: Partial<DocumentOpportunities> = {}) {
    this.rescueServiceResponseTimeId = rescueServiceResponseTimeId;
    this.opportunitiesAccessibilityId = opportunitiesAccessibilityId;
    this.isKeyAccess = isKeyAccess;
    this.isKeyStoringOnObject = isKeyStoringOnObject;
    this.locationOfKeyCabinet = locationOfKeyCabinet;
    this.isCommunicationEquipmentForEvacuationInBuilding = isCommunicationEquipmentForEvacuationInBuilding;
    this.windAccessId = windAccessId;
    this.solarPanelsLocationIds = solarPanelsLocationIds || [];
    this.isEmergencyServiceSwitchForSolarCellSystemApplied = isEmergencyServiceSwitchForSolarCellSystemApplied;
    this.locationOfEmergencyServiceSwitch = locationOfEmergencyServiceSwitch;
    this.hasBasementSpecialRequirementsAccess = hasBasementSpecialRequirementsAccess;
    this.hasSpecialRequirementsForMaxDistanceOf50M = hasSpecialRequirementsForMaxDistanceOf50M;
    this.hasRescueLiftInBuilding = hasRescueLiftInBuilding;
    this.isEscapeRouteAvailable = isEscapeRouteAvailable;
    this.isActionPlansAvailable = isActionPlansAvailable;
    this.isFireDustIsApplied = isFireDustIsApplied;
    this.hasAscensionLine = hasAscensionLine;
    this.isAscensionLinePressurized = isAscensionLinePressurized;
    this.extinguishingWaterAccessIds = extinguishingWaterAccessIds;
    this.extinguishingWaterAccessOther = extinguishingWaterAccessOther;
    this.rescueServicesInterventionDescription = rescueServicesInterventionDescription;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
