import { Injectable } from '@angular/core';
import { intersection, isEmpty, isNil, isNumber, some } from 'lodash';
import {
  Document,
  DocumentAirTreatment,
  DocumentAirTreatmentFireGasesProtectionOptionTypes,
  DocumentBuilding, DocumentBuildingFireTechnicalBuildingOptionTypes,
  DocumentBuildingLargeKitchenWithOptionTypes,
  DocumentBuildingOccuredActivityOptionTypes,
  DocumentCapacity,
  DocumentEvacuation,
  DocumentFire,
  DocumentFireSectioningThroughFirewallOptionTypes,
  DocumentInfo,
  DocumentInstallations,
  DocumentInstallationsAutomaticFireAlarmActivationOptionTypes,
  DocumentInstallationsAutomaticFireAlarmPowerSupplyOptionTypes,
  DocumentInstallationsEvacuationAlarmsActivationOptionTypes,
  DocumentInstallationsExtinguishingEquipmentFormOptionTypes,
  DocumentLifts,
  DocumentObject,
  DocumentOpportunities,
  DocumentOpportunitiesSolarPanelsLocationOptionTypes,
  DocumentProtectedObjects,
  DocumentRisks,
  DocumentRisksSpecialRiskOptionTypes,
  DocumentSpread,
  DocumentSpreadFacadeMaterialOptionTypes,
} from './shared';
import { DocumentInspection } from './shared/document/document-inspection';

@Injectable({
  providedIn: 'root',
})
export class DocumentsValidationService {
  isInfoValid(value: DocumentInfo, document: Document): boolean {
    const isValid =
      Boolean(value.buildingName?.trim()) &&
      Boolean(value.objectNumber) &&
      // !!value.reviewerId &&
      !isNil(value.documentStatusId) &&
      !isNil(value.isManagementDrawingFireProtectionDrawnUp) &&
      !isNil(value.isEvacuationPlanDrawnUp) &&
      !isNil(value.isActionPlanDrawnUp) &&
      !isEmpty(value.propertyOwner?.trim()) &&
      Boolean(value.siteVisitDate); // &&
    // !isNaN(value.siteVisitDate.getTime())

    return isValid;
  }

  isObjectValid(value: DocumentObject, document: Document): boolean {
    const currentYear = new Date().getFullYear();

    const isValid =
      Boolean(value.propertyName) &&
      Boolean(value.municipality) &&
      !isEmpty(value.area?.trim()) &&
      !isEmpty(value.areaM2?.trim()) &&
      !isEmpty(value.building) &&
      isNumber(value.constructionYear) &&
      value.constructionYear <= currentYear;

    return isValid;
  }

  isBuildingValid(value: DocumentBuilding, document: Document): boolean {
    const isValid =
      !isNil(value.numberOfStoreys) &&
      !isNil(value.fireTechnicalBuildingClassId) &&
      !isNil(value.dimensioningFireLoadId) &&
      (
        value.fireTechnicalBuildingClassId !== DocumentBuildingFireTechnicalBuildingOptionTypes.br0Id || !isNil(value.isBr0AnalysisEstablished)
      ) &&
      (
        !isNil(value.hasBasementFloor) && (!value.hasBasementFloor || !isNil(value.isConsideredBasementFloorPlan))
      ) &&
      (
        !value.hasBasementFloor || !isNil(value.hasSeveralBasementFloors)
      ) &&
      (
        !isNil(value.hasAtticFloor) && (!value.hasAtticFloor || !isNil(value.isConsideredAtticFloorPlan))
      ) &&
      (
        !isNil(value.hasEntresolplan) &&
        (!value.hasEntresolplan || !isNil(value.isConsideredEntresolplan)) &&
        (!value.hasEntresolplan || value.isConsideredEntresolplan !== false || !isNil(value.isConsideredSmallerEntresolplan))
      ) &&
      (
        !isNil(value.hasSouterrainDesign) && (!value.hasSouterrainDesign || !isNil(value.isConsideredSouterrainDesignPlan))
      ) &&
      (
        !isNil(value.hasCulvert) && (!value.hasCulvert || !isEmpty(value.culvertTypeIds))
      ) &&
      !isNil(value.fireTechnicalSolutionTypeId) &&
      (
        value.fireTechnicalSolutionTypeId !== DocumentBuilding.analyticalDimensioningId
        || Boolean(value.analyticalDimensioningDescription)
      ) &&
      !isNil(value.isOwnGuidelinesAmbitionsApplied) &&
      (
        !value.isOwnGuidelinesAmbitionsApplied || !isEmpty(value.followingOwnGuidelineAmbitionsRelevant?.trim())
      ) &&
      !isNil(value.isPublicPremises) &&
      !isNil(value.isAppliedTemporaryOvernightAccommodation) &&
      !isEmpty(value.frameConstructionConsists?.trim()) &&
      !isEmpty(value.beamConstructionConsists?.trim()) &&
      !isEmpty(value.facadeConstructionConsists?.trim()) &&
      !isEmpty(value.roofConstructionConsists?.trim()) &&
      !isEmpty(value.occuredBuildingActivityIds) &&
      (
        !value.occuredBuildingActivityIds.some(id => id === DocumentBuildingOccuredActivityOptionTypes.largeKitchenId) ||
        !isEmpty(value.largeKitchenWithIds)
      ) &&
      (
        !(value.occuredBuildingActivityIds.some(id => id === DocumentBuildingOccuredActivityOptionTypes.largeKitchenId) &&
          !value.largeKitchenWithIds.some(id => id === DocumentBuildingLargeKitchenWithOptionTypes.withoutIncreasedProbabilityOfFireId)) ||
        !isEmpty(value.protectiveSolutionForLargeKitchenIds)
      ) &&
      (
        !value.occuredBuildingActivityIds.some(id => id === DocumentBuildingOccuredActivityOptionTypes.vk2bId ||
          id === DocumentBuildingOccuredActivityOptionTypes.vk2cId) ||
        (!isEmpty(value.meetingRoomOccursInFormOfIds) && !isEmpty(value.numberOfPeopleInMeetingRoomIds))
      );

    return isValid;
  }

  isEvacuationValid(value: DocumentEvacuation, document: Document): boolean {
    const hasOccuredBuildingActivityIdVk5c = document.building.occuredBuildingActivityIds
      .some((activityId: string) => activityId === DocumentBuildingOccuredActivityOptionTypes.vk5cId);
    const isValid =
      !isNil(value.isWindowRemoval) &&
      !isNil(value.isOnlyOneEscapeRoute) &&
      !isNil(value.isEvacuationRescueService) &&
      !isNil(value.isEvacuationFromAnotherFireExit) &&
      (
        !hasOccuredBuildingActivityIdVk5c || !isNil(value.isHorizontalEvacuation)
      ) &&
      !isNil(value.isEvacuationViaTr1) &&
      (
        !value.isEvacuationViaTr1 || !isNil(value.isOnlyEscapeRouteViaTr1)
      ) &&
      !isNil(value.isReEntryRequirements) &&
      !isNil(value.isEvacuationViaEvacuationElevator) &&
      !isEmpty(value.lockedDoorsIds) &&
      !isNil(value.isEvacuationForDisabilitiesPeople) &&
      (value.isEvacuationForDisabilitiesPeople === false || !isNil(value.isCommunicationEquipmentEvacuation)) &&
      !isNil(value.isEvacuationFromAnotherPartOfBuilding) &&
      !isNil(value.isTemporaryEvacuationAFS20201) &&
      !isNil(value.isEvacuationViaAutomaticHorizontalSlidingDoors) &&
      !isNil(value.isEvacuationViaRescueElevator) &&
      !isNil(value.isAccessibleAndUsableHorizontalEvacuation) &&
      (
        !hasOccuredBuildingActivityIdVk5c || !isNil(value.isMattressEvacuationOfBedriddenPatients)
      ) &&
      !isNil(value.isEvacuationViaCommonPartOfSeparatedRoute) &&
      !isNil(value.isClassifiedEscapeRoute) &&
      (
        !isNil(value.isEvacuationPlansInBuilding) && (!value.isEvacuationPlansInBuilding || !isNil(value.isImageRequirementsAccordingVk4))
      );

    return isValid;
  }

  isFireValid(value: DocumentFire, document: Document): boolean {
    const isValid =
      !isNil(value.isBuildingDividedIntoFireCells) &&
      (!value.isBuildingDividedIntoFireCells ||
        ((
            !document.object.areaM2 || Number(document.object.areaM2) <= 1250 || !isNil(value.isFireCellExceeds1250m2)
          ) &&
          !isNil(value.isMoreThanTwoPlanes) &&
          !isNil(value.isSpecialFireTechnicalClass) &&
          !isNil(value.isSeveralBusinessClasses) &&
          !isNil(value.hasDoorsOrHatchesInFireSeparationConstruction) &&
          !isNil(value.hasInstallationDeviceOnDoors) &&
          !isNil(value.hasAutomaticOpeningOnDoors) &&
          !isNil(value.hasWindowsInFireRetardantConstruction) &&
          !isNil(value.hasFireWindowAtAngle) &&
          !isNil(value.hasFireLowHighPart) &&
          !isNil(value.hasFireRetardantCeilingsFloors) &&
          !isNil(value.hasAirFireLock) &&
          !isNil(value.hasCoveredYardBalconies) &&
          (
            !document.building.hasAtticFloor || !isNil(value.hasFireCellInAttic)
          ) &&
          !isNil(value.hasFireTechnicalSeparationOfAeratedEaves) &&
          (
            !value.hasFireCellInAttic || !isEmpty(value.fireRequirementIds)
          ) &&
          (
            !value.sectioningThroughFirewallIds.some(id =>
              id === DocumentFireSectioningThroughFirewallOptionTypes.betweenBuildingsId ||
              id === DocumentFireSectioningThroughFirewallOptionTypes.ofLargeBuildingId) ||
            !isEmpty(value.fireTechnicalClassFirewallIds)
          ) &&
          !isEmpty(value.olderFireTechnicalClassIds) &&
          !isEmpty(value.fireCellMeetTechnicalClassIds)
        )
      ) &&
      !isNil(value.hasWashingAndGarbageChute) &&
      !isNil(value.hasPneumaticTube) &&
      !isEmpty(value.sectioningThroughFirewallIds);

    return isValid;
  }

  isSpreadValid(value: DocumentSpread, document: Document): boolean {
    const isValid =
      !isEmpty(value.protectionAgainstFireOptionIds) &&
      !isEmpty(value.roofingOptionIds) &&
      !isEmpty(value.baseRoofingIds) &&
      !isEmpty(value.facadeMaterialOptionIds) &&
      (
        !value.facadeMaterialOptionIds.some(id => id === DocumentSpreadFacadeMaterialOptionTypes.otherId) ||
        !isEmpty(value.facadeMaterialOptionOther?.trim())
      );

    return isValid;
  }

  isCapacityValid(value: DocumentCapacity, document: Document): boolean {
    const isValid = !isNil(value.fireCarryingCapacityId) &&
      !isNil(value.isDocumentedConsiderationOfLoadCapacityWithHighFireLoad) &&
      !isNil(value.isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem) &&
      (
        document.building.fireTechnicalBuildingClassId !== DocumentBuildingFireTechnicalBuildingOptionTypes.br0Id ||
        !isNil(value.isDocumentedConsiderationOfProtectionNeedsForBr0Building)
      );

    return isValid;
  }

  isInstallationsValid(value: DocumentInstallations, document: Document): boolean {
    const isMarkingEmergencyLightingValid =
      !isEmpty(value.markingsEmergencyLightingIds) &&
      (!isEmpty(value.powerSupplyEmergencyLightingIds) ||
        intersection(value.markingsEmergencyLightingIds, [
          DocumentInstallations.illuminatedWithEmergencyPowerId,
          DocumentInstallations.separateEmergencyLightingId,
        ]).length === 0);

    const isFireAlarmsOccurValid =
      !isNil(value.isFireAlarmsOccur) &&
      !isNil(value.hasFireAlarmsFormalRequirements) &&
      (!value.isFireAlarmsOccur || !isNil(value.hasFireAlarmsSeriesConnection));

    const isAutomaticFireAlarmsOccurrValid =
      !isNil(value.isAutomaticFireAlarmsOccur) &&
      !isNil(value.hasAutomaticFireAlarmsFormalRequirements) &&
      (!value.isAutomaticFireAlarmsOccur ||
        (!isNil(value.hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning) &&
          !isNil(value.isAutomaticFireAlarmOwnAmbition) &&
          !isEmpty(value.coordinatedFunctionAutomaticFireAlarmRelevantIds) &&
          !isEmpty(value.automaticFireAlarmsMonitoringScopeIds) &&
          !isEmpty(value.automaticFireAlarmDetekteringIds) &&
          !isEmpty(value.automaticFireAlarmsReceiverIds) &&
          !isEmpty(value.automaticFireAlarmSpecialAdaptationIds) &&
          !isEmpty(value.automaticFireAlarmCurrentRegulations?.trim()) &&
          !isEmpty(value.automaticFireAlarmPowerSupplyIds) &&
          (
            !value.automaticFireAlarmPowerSupplyIds.some(id => id === DocumentInstallationsAutomaticFireAlarmPowerSupplyOptionTypes.otherId) ||
            !isEmpty(value.automaticFireAlarmPowerSupplyOther?.trim())
          ) &&
          !isEmpty(value.automaticFireAlarmActivationIds) &&
          (
            !value.automaticFireAlarmActivationIds.some(id => id === DocumentInstallationsAutomaticFireAlarmActivationOptionTypes.otherId) ||
            !isEmpty(value.automaticFireAlarmActivationOther?.trim())
          ) &&
          !isEmpty(value.locationOfFireProtectionPanelFireAlarmCenter?.trim()) &&
          !isEmpty(value.locationOfInformationTableForFireAlarms?.trim()) &&
          !isEmpty(value.locationOfAlarmStorageTablesForFireAlarms?.trim()) &&
          !isEmpty(value.automaticFireAlarmConstructionCertificate?.trim()) &&
          !isEmpty(value.automaticFireAlarmServiceOrientationDrawings?.trim())
        )
      );

    const isEvacuationAlarmsOccurValid =
      !isNil(value.isAlarmSignalingOccur) &&
      !isNil(value.hasEvacuationAlarmsFormalRequirements) &&
      (!value.isAlarmSignalingOccur ||
        (!isEmpty(value.alarmSignalPerceivedIds) &&
          !isEmpty(value.evacuationAlarmsActivationIds) &&
          (
            !value.evacuationAlarmsActivationIds.some(id => id === DocumentInstallationsEvacuationAlarmsActivationOptionTypes.otherId) ||
            !isEmpty(value.evacuationAlarmsActivationOther?.trim())
          ) &&
          !isEmpty(value.evacuationAlarmsSignalIds) &&
          !isEmpty(value.evacuationAlarmsSpecialAdaptationIds) &&
          !isNil(value.hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning) &&
          !isNil(value.isAlarmSignalingOwnAmbition) &&
          !isNil(value.hasEmergencyMicrophoneForEmergencyService) &&
          !isEmpty(value.alarmSignalingOccurIds) &&
          !isEmpty(value.alarmSignalingCurrentRegulations?.trim()) &&
          !isEmpty(value.alarmSignalingConstructionCertificate?.trim()) &&
          !isEmpty(value.locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel?.trim())
        )
      );

    const isAutomaticWaterSprinklerOccurValid =
      !isNil(value.isAutomaticExtinguishingSystemOccur) &&
      !isNil(value.hasAutomaticWaterSprinklerFormalRequirements) &&
      (!value.isAutomaticExtinguishingSystemOccur ||
        (!isEmpty(value.monitoringAutomaticExtinguishingSystemIds) &&
          !isEmpty(value.automaticWaterSprinklerAdjustmentIds) &&
          !isEmpty(value.automaticWaterSprinklerRiskClassIds) &&
          !isNil(value.isAutomaticExtinguishingSystemOwnAmbition) &&
          !isEmpty(value.automaticExtinguishingSystemOccurIds)
        )
      );

    const isExtinguishingEquipmentFormValid =
      !isEmpty(value.extinguishingEquipmentFormIds) &&
      (!some(
        value.extinguishingEquipmentFormIds,
        (id) => id === DocumentInstallationsExtinguishingEquipmentFormOptionTypes.otherId
      ) ||
        !!value.extinguishingEquipmentFormOther);

    const isFireGasVentilationOccurValid =
      !isNil(value.isFireGasVentilationOccur) &&
      !isNil(value.hasFireGasVentilationFormalRequirements) &&
      (!value.isFireGasVentilationOccur ||
        (!isEmpty(value.premisesFireGasVentilationIds) &&
          (
            !some(value.premisesFireGasVentilationIds, (id) => id === DocumentInstallations.otherPremisesFireGasVentilationOptionId) ||
            !isEmpty(value.premisesFireGasVentilationOther?.trim())
          ) &&
          !isNil(value.isFireGasVentilationOwnAmbition)
        )
      );

    const isOtherFireTechnicalInstallationsOccurValid =
      !isNil(value.isOtherFireTechnicalInstallationsOccur) &&
      !isNil(value.hasOtherFireTechnicalInstallationsFormalRequirements) &&
      (!value.isOtherFireTechnicalInstallationsOccur ||
        (!isNil(value.isOtherFireTechnicalInstallationsOwnAmbition))
      );

    const isValid =
      isMarkingEmergencyLightingValid &&
      isFireAlarmsOccurValid &&
      isAutomaticFireAlarmsOccurrValid &&
      isEvacuationAlarmsOccurValid &&
      isAutomaticWaterSprinklerOccurValid &&
      isExtinguishingEquipmentFormValid &&
      isFireGasVentilationOccurValid &&
      isOtherFireTechnicalInstallationsOccurValid;

    return isValid;
  }

  isAirTreatmentValid(value: DocumentAirTreatment, document: Document): boolean {
    const isValid =
      !isEmpty(value.fireControlVentilationSystemOptionIds) &&
      (
        !value.fireGasesProtectionOptionIds.some(id => id === DocumentAirTreatmentFireGasesProtectionOptionTypes.pressureReliefOfDuctSystemId) ||
        !isNil(value.isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs)
      ) &&
      !isNil(value.isSmokeDuctsInside) &&
      (
        !value.isSmokeDuctsInside || !isNil(value.hasDocumentedProtectionSolutionForImkanal)
      ) &&
      !isNil(value.isOperatingCardFlowChartFireProtectionFunctionAvailable) &&
      !isEmpty(value.fireGasesProtectionOptionIds) &&
      (!some(
        value.fireGasesProtectionOptionIds,
        (id) => id === DocumentAirTreatmentFireGasesProtectionOptionTypes.otherId
      ) ||
        !!value.fireGasesProtectionOther);

    return isValid;
  }

  isLiftsValid(value: DocumentLifts, document: Document): boolean {
    const isValid =
      !isNil(value.isElevatorExist) &&
      (!value.isElevatorExist ||
        (!isNil(value.liftSteeringFunctionId) &&
          !isNil(value.recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId) &&
          !isNil(value.liftFireproofWiringId) &&
          !isEmpty(value.elevatorFireProtectionOptionIds) &&
          (!some(
            value.elevatorFireProtectionOptionIds,
            (id) => id === DocumentLifts.otherElevatorFireProtectionOptionId
          ) ||
            !!value.elevatorFireProtectionOther)));

    return isValid;
  }

  isOpportunitiesValid(value: DocumentOpportunities, document: Document): boolean {
    const isValid =
      !isNil(value.rescueServiceResponseTimeId) &&
      !isNil(value.opportunitiesAccessibilityId) &&
      !isNil(value.isKeyAccess) &&
      (
        (value.isKeyAccess && !isNil(value.isKeyStoringOnObject))
        || (!value.isKeyAccess && isNil(value.isKeyStoringOnObject))
      ) &&
      (
        (document.building.hasAtticFloor && !isNil(value.windAccessId))
        || (!document.building.hasAtticFloor && isNil(value.windAccessId))
      ) &&
      (
        !value.isKeyStoringOnObject || !isEmpty(value.locationOfKeyCabinet?.trim())
      ) &&
      !isNil(value.isCommunicationEquipmentForEvacuationInBuilding) &&
      (
        !value.solarPanelsLocationIds.some(id => id === DocumentOpportunitiesSolarPanelsLocationOptionTypes.facadeId ||
          id === DocumentOpportunitiesSolarPanelsLocationOptionTypes.ceilingId) ||
        !isNil(value.isEmergencyServiceSwitchForSolarCellSystemApplied)
      ) &&
      (
        !value.isEmergencyServiceSwitchForSolarCellSystemApplied || !isEmpty(value.locationOfEmergencyServiceSwitch?.trim())
      ) &&
      !isEmpty(value.solarPanelsLocationIds) &&
      !isNil(value.hasBasementSpecialRequirementsAccess) &&
      (
        !document.building.occuredBuildingActivityIds.some(id => id === DocumentBuildingOccuredActivityOptionTypes.vk5cId) ||
        !isNil(value.hasSpecialRequirementsForMaxDistanceOf50M)
      ) &&
      !isNil(value.hasRescueLiftInBuilding) &&
      !isNil(value.isEscapeRouteAvailable) &&
      !isNil(value.isActionPlansAvailable) &&
      !isNil(value.isFireDustIsApplied) &&
      !isNil(value.hasAscensionLine) &&
      (!value.hasAscensionLine || !isNil(value.isAscensionLinePressurized)) &&
      !isEmpty(value.extinguishingWaterAccessIds) &&
      (!some(
        value.extinguishingWaterAccessIds,
        (id) => id === DocumentOpportunities.otherExtinguishingWaterAccessOptionId
      ) ||
        !!value.extinguishingWaterAccessOther);

    return isValid;
  }

  isRisksValid(value: DocumentRisks, document: Document): boolean {
    const isValid =
      !isEmpty(value.risksHeatingTypeIds) &&
      !isEmpty(value.specialRiskTypeIds) &&
      (
        !document.risks.specialRiskTypeIds.some(id => id === DocumentRisksSpecialRiskOptionTypes.cookingDeviceId) ||
        !isNil(value.hasTimerOrStoveGuard)
      );

    return isValid;
  }

  isProtectedObjectsValid(value: DocumentProtectedObjects, document: Document): boolean {
    const isValid = !isEmpty(value.worthyProtectedObjectTypeIds);

    return isValid;
  }

  isInspectionValid(value: DocumentInspection, document: Document): boolean {
    const isEvacuationValid =
      //1.1
      !isNil(value.hasFunctionOfEscapeRoutes) &&
      (!value.hasFunctionOfEscapeRoutes ||
        (
          !isNil(value.hasDoorsWindowsEscapeRotes) && //1.1.01
          !isNil(value.hasFunctionOfEscapeRoutesIndicativeMarking) && //1.1.11
          !isNil(value.hasFunctionOfEscapeRoutesAccess) && //1.1.12
          !isNil(value.hasFunctionOfEscapeRoutesCommunicationEquipment) && //1.1.13
          !isNil(value.hasFunctionOfEscapeRoutesMarkingOfEvacuationSite) && //1.1.14
          !isNil(value.hasEvacuationPlanReadabilityMechanicalImpact) && //1.1.21
          !isNil(value.hasStatusOfEquipmentAndSignage) //1.1.31
        )
      );

    const isSpreadValid =
      //2.1
      !isNil(value.hasFireSpreadWithinFireCells) &&
      (!value.hasFireSpreadWithinFireCells ||
        (
          !isNil(value.hasRegularControlOfChangeThroughWallCoverings) //2.1.01
        )
      ) &&
      //2.2
      !isNil(value.hasFireSpreadBetweenFireCells) &&
      (!value.hasFireSpreadBetweenFireCells ||
        (
          !isNil(value.hasDoorsWithinFireCellBoundary) && //2.2.01
          !isNil(value.hasInstallationDeviceOnDoorsWithinFireCellBoundary) && //2.2.02
          !isNil(value.hasAutomaticDoorOpeningFunctionOnDoorsWithinFireCellBoundary) && //2.2.03
          !isNil(value.hasGlassSectionsWithinFireCellBoundary) && //2.2.11
          !isNil(value.hasVentilationDucts) && //2.2.21
          !isNil(value.hasGlandsForCablesPipesDucts) && //2.2.22
          !isNil(value.hasWallsAndLoadBearingStructures) //2.2.23
        )
      );

    const isInstallationsValid =
      //3.1
      !isNil(value.hasWarningMarkingSignageAndEmergencyLighting) &&
      (!value.hasWarningMarkingSignageAndEmergencyLighting ||
        (
          !isNil(value.hasSignageEmergencyLightingIndicativeMarkings) && //3.1.01
          !isNil(value.hasSignageEmergencyLightingSeparateEmergencyLightinguminaires) && //3.1.02
          !isNil(value.hasSignageEmergencyLightingEmergencyPowerSupplyBatteryBackup) && //3.1.03
          !isNil(value.hasSignageEmergencyLightingEmergencyPowerSupplyReservePowerSupply) && //3.1.04
          !isNil(value.hasBatteryLampInFixedChargingUnit) && //3.1.05
          !isNil(value.hasGeneralLightingStaircasesAndCorridorsInBuildingsWithMoreThanTwoStoreys) && //3.1.11
          !isNil(value.hasGeneralLightingLightingOfIlluminatedSigns) //3.1.12
        )
      ) &&
      //3.2
      !isNil(value.hasAutomaticFireAlarmAndEvacuationAlarm) &&
      (!value.hasAutomaticFireAlarmAndEvacuationAlarm ||
        (
          !isNil(value.hasAutomaticFireAlarmAndEvacuationAlarmAuditInspection) && //3.2.01
          !isNil(value.hasAutomaticFireAlarmAndEvacuationAlarmSystemMaintenance) && //3.2.02
          !isNil(value.hasAutomaticFireAlarmAndEvacuationAlarmSimplerChecksAndTests) //3.2.03
        )
      ) &&
      //3.3
      !isNil(value.hasAutomaticWaterSprinklerSystem) &&
      (!value.hasAutomaticWaterSprinklerSystem ||
        (
          !isNil(value.hasAutomaticWaterSprinklerSystemAuditInspection) && //3.3.11
          !isNil(value.hasAutomaticWaterSprinklerSystemSimplerChecksAndTests) && //3.3.12
          !isNil(value.hasAutomaticWaterSprinklerSystemCareAndMaintenanceByInstaller) && //3.3.13
          !isNil(value.hasResidentialSprinklerCareAndMaintenanceByInstaller) //3.3.21
        )
      ) &&
      //3.4
      !isNil(value.hasFixedExtinguishingSystemsForIncreasedPropertyProtection) &&
      (!value.hasFixedExtinguishingSystemsForIncreasedPropertyProtection ||
        (
          !isNil(value.hasSystemReviewByInspectorConstructionCompanyOrOperatingContractor) && //3.4.01
          !isNil(value.hasSimplerChecksAndTestsAccordingToConstructionCompanyInstructions) //3.4.02
        )
      ) &&
      //3.5
      !isNil(value.hasFireFightingEquipmentForPersonsOnSite) &&
      (!value.hasFireFightingEquipmentForPersonsOnSite ||
        (
          !isNil(value.hasFireFightingEquipmentForPersonsOnSiteAnnualMaintenance) && //3.5.01
          !isNil(value.hasFireFightingEquipmentForPersonsOnSiteRoutineInspection) //3.5.02
        )
      ) &&
      //3.6
      !isNil(value.hasFireGasVentilation) &&
      (!value.hasFireGasVentilation ||
        (
          !isNil(value.hasFireGasVentilationManuallyMechanicallyOpenedDoorsWindows) && //3.6.01
          !isNil(value.hasFireGasVentilationRemoteControlledAutomaticOpeningDoorsWindows) && //3.6.02
          !isNil(value.hasFireGasVentilationRemoteControlledAutomaticActivationMechanical) //3.6.03
        )
      );

    const isVentilationSystemValid =
      //4.1
      !isNil(value.hasVentilationSystem) &&
      (!value.hasVentilationSystem ||
        (
          !isNil(value.hasVentilationSystemAnnualReviewOfFireProtectionFunctions) && //4.1.01
          !isNil(value.hasVentilationSystemFireDamper) && //4.1.02
          !isNil(value.hasVentilationSystemMistAndSmokeDucts) && //4.1.03
          !isNil(value.hasFanFunctionForFansInOperation) //4.1.04
        )
      );

    const isOpportunitiesValid =
      //5.1
      !isNil(value.hasAccessingBuildings) &&
      (!value.hasAccessingBuildings ||
        (
          !isNil(value.hasAccessingBuildingsDrivewaysAndParkingSpacesForRescueService) && //5.1.01
          !isNil(value.hasAccessingBuildingsDoorsHatchesPassagesForAccess) && //5.1.02
          !isNil(value.hasAccessingBuildingsBetKeyIsApplied) && //5.1.03
          !isNil(value.hasAccessingBuildingsRoofSurfaces) //5.1.04
        )
      ) &&
      //5.2
      !isNil(value.hasRescueElevator) &&
      (!value.hasRescueElevator ||
        (
          !isNil(value.hasRescueElevatorSelfInspectionElevatorInstaller) && //5.2.01
          !isNil(value.hasRescueElevatorSignageAndMarkings) //5.2.02
        )
      ) &&
      //5.3
      !isNil(value.hasActionPlans) &&
      (!value.hasActionPlans ||
        (
          !isNil(value.hasActionPlansRegularReview) //5.3.01
        )
      ) &&
      //5.4
      !isNil(value.hasExtinguishingWater) &&
      (!value.hasExtinguishingWater ||
        (
          !isNil(value.hasExtinguishingWaterFireHydrantsForRescueService) && //5.4.01
          !isNil(value.hasExtinguishingWaterFirePondAndParkingSpace) && //5.4.02
          !isNil(value.hasExtinguishingWaterSignageAndMarkings) //5.4.03
        )
      ) &&
      //5.5
      !isNil(value.hasLadders) &&
      (!value.hasLadders ||
        (
          !isNil(value.hasLaddersDryRiserLine) && //5.5.01
          !isNil(value.hasLaddersPressurizedRiserLineAuditInspection) && //5.5.02
          !isNil(value.hasLaddersPressurizedRiserLineServiceAndMaintenance) && //5.5.03
          !isNil(value.hasLaddersConnectionAndOutletPointsForExtinguishingWater) && //5.5.04
          !isNil(value.hasLaddersSignageAndMarkings) //5.5.05
        )
      ) &&
      //5.6
      !isNil(value.hasFixedExtinguishingSystemsWithAidForTaskForce) &&
      (!value.hasFixedExtinguishingSystemsWithAidForTaskForce ||
        (
          !isNil(value.hasSystemReviewByConstructionCompanyOrOperatingContractor) && //5.6.01
          !isNil(value.hasSimplerChecksAndTests) //5.6.02
        )
      );

    const isSpecialRisksValid =
      //6.1
      !isNil(value.hasHeatingSystem) &&
      (!value.hasHeatingSystem ||
        (
          !isNil(value.hasHeatingSystemOilGasSolidFuelBased) && //6.1.01
          !isNil(value.hasHeatingSystemFireplaceStoveConnectedToChimney) && //6.1.02
          !isNil(value.hasHeatingSystemSweepingAndFireProtectionControl) //6.1.03
        )
      ) &&
      //6.2
      !isNil(value.hasSpecialRisks) &&
      (!value.hasSpecialRisks ||
        (
          !isNil(value.hasRisksHandlingFlammableGoods) && //6.2.01
          !isNil(value.hasRisksHandlingLpgBottles) && //6.2.02
          !isNil(value.hasRisksLaboratoryActivities) && //6.2.03
          !isNil(value.hasRisksBatteryChargingPoint) && //6.2.04
          !isNil(value.hasRisksOpenLoadingDock) && //6.2.05
          !isNil(value.hasRisksChipSuction) && //6.2.06
          !isNil(value.hasRisksExternalEditions) && //6.2.07
          !isNil(value.hasRisksFireplace) && //6.2.08
          !isNil(value.hasRisksHighVoltage) && //6.2.09
          !isNil(value.hasRisksOtherRisk) && //6.2.10
          !isNil(value.hasRisksFumeCupboard) && //6.2.11
          !isNil(value.hasRisksIndoorCisternStorage) && //6.2.12
          !isNil(value.hasRisksOutdoorCisternStorage) && //6.2.13
          !isNil(value.hasRisksFixedGasSystemsPipelines) && //6.2.14
          !isNil(value.hasRisksBoilerRoomOver60vm) && //6.2.15
          !isNil(value.hasRisksRadioactiveSubstances) && //6.2.16
          !isNil(value.hasRisksBatteryStorageDevices) && //6.2.17
          !isNil(value.hasRisksHazardousChemicalsPersonEnvironment) && //6.2.18
          !isNil(value.hasRisksMagnetism) && //6.2.19
          !isNil(value.hasRisksCookingDevice) && //6.2.20
          !isNil(value.hasRisksChemicalStorage) && //6.2.21
          !isNil(value.hasRisksCentralStorageOfInfectiousWaste) //6.2.22
        )
      ) &&
      //6.3
      !isNil(value.hasElectricalInstallations) &&
      (!value.hasElectricalInstallations ||
        (
          !isNil(value.hasElectricalInstallationsInspectionByAuthorizedInspector) && //6.3.01
          !isNil(value.hasElectricalInstallationsLightingFixturesAndHotSurfaces) && //6.3.02
          !isNil(value.hasElectricalInstallationsPowerPlants) && //6.3.03
          !isNil(value.hasElectricalInstallationsBatteryChargingPointsCanGenerateGas) && //6.3.04
          !isNil(value.hasElectricalInstallationsBatteryStorageLocationsStatusOfPremises) && //6.3.05
          !isNil(value.hasElectricalInstallationsSolarCellSystemStatusOfInstallation) //6.3.06
        )
      ) &&
      //6.4
      !isNil(value.hasExternalStorageFire) &&
      (!value.hasExternalStorageFire ||
        (
          !isNil(value.hasExternalStorageFireContainersCompactorsWarehousesEnvironmentalStations) && //6.4.01
          !isNil(value.hasExternalStorageFireCargoBerth) //6.4.02
        )
      );

    const isValid = isEvacuationValid &&
      isSpreadValid &&
      isInstallationsValid &&
      isVentilationSystemValid &&
      isOpportunitiesValid &&
      isSpecialRisksValid;

    return isValid;
  }
}
