import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  SimpleChanges,
  OnChanges,
  SimpleChange,
} from '@angular/core';
import { UserDetails } from '../shared';
import { LoggerService, coreConfig } from 'src/app/core';
import { DataSourceService, DataSource, DataSourceState, FieldSortOrder } from 'src/app/core/collections';
import { usersConfig } from '../users.config';
import { validate, required, PromiseUtils, Assert } from 'src/app/shared';
import { ErrorModalComponent } from 'src/app/core/error-modal/error-modal.component';
import { UsersApiService } from '../users-api.service';
import { AuthenticationStoreService } from 'src/app/authentication/authentication-store';
import { takeUntil } from 'rxjs/operators';
import { AuthDetails } from 'src/app/authentication/shared';
import { Subject } from 'rxjs';
import _ from 'lodash';
import { UserRole } from 'src/app/authentication/shared/user-role';
import { UsersType } from 'src/app/authentication/shared/users-type';
import { ErrorModalDialogData } from 'src/app/core/shared/error-modal-dialog-data';
import { MatDialog } from '@angular/material/dialog';
import { ManagePermissionsModalComponent } from '../../organization/manage-permissions-modal/manage-permissions-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  organizationId: string;

  @Input()
  usersRole: UserRole;

  @Input()
  usersType: UsersType;

  @Output()
  readonly userCreated: EventEmitter<UserDetails>;

  @Output()
  readonly userDeleted: EventEmitter<UserDetails>;

  state: {
    isLoading: boolean;
    isFailed: boolean;
    isRefreshing: boolean;
    isDeleting: boolean;
    isActionProcessing: boolean;
  };

  dataSource: DataSource<UserDetails>;
  dataSourceState: DataSourceState<UserDetails>;

  currentUserAuthDetails: AuthDetails;

  private unsubscribeAll: Subject<any>;

  constructor(
    private readonly usersApiService: UsersApiService,
    private readonly loggerService: LoggerService,
    private readonly dataSourceService: DataSourceService,
    private authenticationStoreService: AuthenticationStoreService,
    private dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
  ) {
    this.usersApiService = usersApiService;
    this.loggerService = loggerService;
    this.dataSourceService = dataSourceService;

    this.userCreated = new EventEmitter();
    this.userDeleted = new EventEmitter();
    this.unsubscribeAll = new Subject();

    this.dataSource = new DataSource<UserDetails>();

    this.dataSourceState = new DataSourceState<UserDetails>({
      filter: '',
      searchableFields: usersConfig.filter.searchableFields,
      sortOrder: null,
      page: 0,
      pageSize: coreConfig.pagination.defaultPageSize,
    });

    this.state = {
      isLoading: false,
      isFailed: false,
      isRefreshing: false,
      isDeleting: false,
      isActionProcessing: false,
    };
  }

  async ngOnInit(): Promise<void> {
    this.authenticationStoreService
      .getAuthDetails()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(async (details: AuthDetails) => {
        this.currentUserAuthDetails = details;
      });

    this.loadUsers();
  }

  ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<UsersComponent, SimpleChange>;
    const organizationIdChange = componentChanges.organizationId;

    if (organizationIdChange) {
      this.loadUsers();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onSortOrderChanged(sortOrder: FieldSortOrder): void {
    this.dataSourceState.page = 0;
    this.dataSourceState.sortOrder = sortOrder;
    this.dataSource = this.dataSourceService.sortDataSource(this.dataSource, this.dataSourceState);
  }

  onManagePermissions() {
    Assert.isNotNull(this.dataSource, 'dataSource');

    const dialogRef = this.dialog.open(ManagePermissionsModalComponent, {
      data: this.organizationId,
      panelClass: 'doc-app-dialog',
      disableClose: true,
      width: '100%',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadUsers();
    });
  }

  @validate
  onPageChanged(@required page: number) {
    this.dataSourceState.page = page;
    this.dataSource = this.dataSourceService.paginateDataSource(this.dataSource, this.dataSourceState);
  }

  onFilterChanged(filter: string) {
    this.dataSourceState.page = 0;
    this.dataSourceState.filter = filter;
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
  }

  @validate
  onPageSizeChanged(@required pageSize: number) {
    this.dataSourceState.page = 0;
    this.dataSourceState.pageSize = pageSize;
    this.dataSource = this.dataSourceService.paginateDataSource(this.dataSource, this.dataSourceState);
  }

  @validate
  onUserCreated(@required userDetails: UserDetails) {
    if (!_.isEmpty(this.organizationId) && userDetails.user.organizationId !== this.organizationId) {
      return;
    }
    this.dataSource = this.dataSourceService.addItemToDataSource(this.dataSource, this.dataSourceState, userDetails);
    this.userCreated.emit(userDetails);
  }

  @validate
  async onDeleteUser(@required userDetails: UserDetails) {
    Assert.isNotNull(userDetails.user, 'userDetails.user');

    this.state.isDeleting = true;
    try {
      await this.usersApiService.deleteUser(userDetails.user.id);
      this.onUserDeleted(userDetails);
    } catch (error) {
      this.onFailedModal('Det gick inte att ta bort användaren - försök igen senare.', error);
    } finally {
      this.state.isDeleting = false;
    }
  }

  @validate
  onUserEdited(@required userDetails: UserDetails) {
    const usersComparator = (first: UserDetails, second: UserDetails) => first.user.id === second.user.id;

    this.dataSource = this.dataSourceService.updateItemInDataSource(
      this.dataSource,
      this.dataSourceState,
      userDetails,
      usersComparator
    );
  }

  @validate
  onUserMoved(@required userDetails: UserDetails) {
    this.onUserDeleted(userDetails);
  }

  onTryLoadUsers() {
    this.loadUsers();
  }

  async onRefreshUsers() {
    this.state.isRefreshing = true;
    try {
      const refreshPromise = this.refreshUsers();
      const minTimeoutPromise = PromiseUtils.delay(usersConfig.list.minRefreshTimeout);

      await Promise.all([refreshPromise, minTimeoutPromise]);
    } catch {
      // ignore
    } finally {
      this.state.isRefreshing = false;
    }
  }

  @validate
  private onUserDeleted(@required userDetails: UserDetails) {
    const usersComparator = (first: UserDetails, second: UserDetails) => first.user.id === second.user.id;
    this.dataSource = this.dataSourceService.deleteItemFromDataSource(
      this.dataSource,
      this.dataSourceState,
      userDetails,
      usersComparator
    );
    this.snackBar.open(`Användare ${userDetails.user.firstName} ${userDetails.user.lastName} borttagen.`, 'OK');
    this.userDeleted.emit(userDetails);
  }

  private async refreshUsers() {
    try {
      this.dataSource = await this.getDataSource(this.dataSourceState);
    } catch (error) {
      this.loggerService.error('Failed to refresh users', error);
    }
  }

  private async loadUsers() {
    this.state.isLoading = true;
    this.state.isFailed = false;

    this.dataSource = new DataSource<UserDetails>();

    try {
      this.dataSource = await this.getDataSource(this.dataSourceState);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to load users', error);
    } finally {
      this.state.isLoading = false;
    }
  }

  @validate
  private async getDataSource(
    @required dataSourceState: DataSourceState<UserDetails>
  ): Promise<DataSource<UserDetails>> {
    let users = await this.usersApiService.getUsersByOrganizationId(this.organizationId);
    if (this.usersType === UsersType.InternalUsers) {
      users = _.filter(users, (item) => item.role === UserRole.superAdmin || item.role === UserRole.author);
    } else if (this.usersType === UsersType.Customers) {
      users = _.filter(
        users,
        (item) =>
          item.role === UserRole.customerAdmin ||
          item.role === UserRole.customerAuthor ||
          item.role === UserRole.customerReader
      );
    }
    const dataSource = this.dataSourceService.createDataSource(users, dataSourceState);

    return dataSource;
  }

  @validate
  private onFailedModal(@required message: string, error: any) {
    this.loggerService.error(message, error);

    const errorModalDialogData = new ErrorModalDialogData({ title: message });
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: errorModalDialogData,
    });
  }
}
