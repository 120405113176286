import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, take, switchMap } from 'rxjs/operators';
import { authenticationConfig } from './authentication.config';
import { AuthenticationService } from './authentication.service';
import { AuthenticationStoreService } from './authentication-store';
import { validate, required } from '../shared';
import { StatusCode } from '../core/api';
import { AuthenticationApiService } from './authentication-api.service';
import * as moment from 'moment';

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {
  private readonly authenticationService: AuthenticationService;
  private readonly authenticationStoreService: AuthenticationStoreService;
  private readonly router: Router;
  private readonly authenticationApiService: AuthenticationApiService;

  constructor(
    authenticationService: AuthenticationService,
    authenticationStoreService: AuthenticationStoreService,
    router: Router,
    authenticationApiService: AuthenticationApiService
  ) {
    this.authenticationService = authenticationService;
    this.authenticationStoreService = authenticationStoreService;
    this.router = router;
    this.authenticationApiService = authenticationApiService;
  }

  @validate
  intercept(request: HttpRequest<any>, @required next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authenticationStoreService.getAuthDetails().pipe(
      take(1),
      switchMap((authDetails) => {
        const isRenewTokenRequest = request.url.indexOf('token/renew') > 0;

        if (!authDetails || !authDetails.accessToken || isRenewTokenRequest) {
          return next.handle(request);
        }
        this.checkAndRenewToken();

        const newReq = request.clone({
          setHeaders: {
            Authorization: `Bearer ${authDetails.accessToken}`,
          },
        });
        return next.handle(newReq);
      }),
      tap({
        error: (error: any) => this.onError(error),
      })
    );
  }

  private async onError(error: any) {
    if (!error) {
      return;
    }

    if (error.status === StatusCode.Unauthorized) {
      await this.authenticationService.setReturnUrl(this.router.url, false);
      this.authenticationService.expireAuthentication();
      this.router.navigate(['/', authenticationConfig.routes.login]);
    }
  }

  private async checkAndRenewToken() {
    const authDetails = await this.authenticationStoreService.getAuthDetails().pipe(take(1)).toPromise();
    if (!authDetails) {
      return;
    }
    const diff = moment(authDetails.expireDate).diff(moment(), 'minutes');
    if (diff > authenticationConfig.renewUntilTokenExpiredMinutes) {
      return;
    }

    const newAuthDetails = await this.authenticationApiService.renew(authDetails.accessToken);
    this.authenticationStoreService.authenticate(newAuthDetails);
  }
}
