import { DocumentFieldType } from 'src/app/documents/shared/document-structure/document-field-type';
import { FilterPredicate } from './filter-predicate';
export { Document } from '../document';

export class DocumentSectionFilter {
  id: string;
  sectionId: string;
  fieldId: string;
  type: DocumentFieldType;
  value: any;
  predicate: FilterPredicate;

  constructor({ id, sectionId, fieldId, type, value, predicate }: Partial<DocumentSectionFilter> = {}) {
    this.id = id;
    this.sectionId = sectionId || null;
    this.fieldId = fieldId || null;
    this.type = type || null;
    this.value = value;
    this.predicate = predicate;
  }
}
