<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'Brandtekniska installationer'"
    [documentId]="document?.id"
    [isCompleted]="document?.installations.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <!-- Emergency Lighting -->
        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Vägledande markeringar och nödbelysning förekommer genom"
              [values]="markingsEmergencyLightingOptionTypes"
              [selectedKeys]="document.installations.markingsEmergencyLightingIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="markingsEmergencyLightingIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Vägledande markeringar och nödbelysning förekommer genom"
              *ngIf="hasEditPermissions"
            >
              <mat-label title="Indicative markings and emergency lighting occur through"
                >Vägledande markeringar och nödbelysning förekommer genom</mat-label
              >

              <mat-select
                required
                [(ngModel)]="document.installations.markingsEmergencyLightingIds"
                #markingsEmergencyLightingIds="ngModel"
                name="markingsEmergencyLightingIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="markingsEmergencyLightingOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let markingsEmergencyLightingOptionType of markingsEmergencyLightingOptionTypes | keyvalue"
                  [value]="markingsEmergencyLightingOptionType.key"
                  [title]="markingsEmergencyLightingOptionType.value.displayName"
                  [disabled]="markingsEmergencyLightingOptionType.value.isDisabled"
                  >{{ markingsEmergencyLightingOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[markingsEmergencyLightingIds.name]"-->
<!--                [class.text-secondary-primary]="notes[markingsEmergencyLightingIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[markingsEmergencyLightingIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="markingsEmergencyLightingIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Strömförsörjning av nödbelysning (såväl skyltar som separata armaturer) säkerställs genom"
              [values]="powerSupplyEmergencyLightingOptionTypes"
              [selectedKeys]="document.installations.powerSupplyEmergencyLightingIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="powerSupplyEmergencyLightingIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Strömförsörjning av nödbelysning (såväl skyltar som separata armaturer) säkerställs genom"
              *ngIf="hasEditPermissions"
            >
              <mat-label>Strömförsörjning av nödbelysning (såväl skyltar som separata armaturer) säkerställs genom</mat-label>

              <mat-select
                required
                [disabled]="!isPowerSupplyEmergencyLightingEnabled"
                [(ngModel)]="document.installations.powerSupplyEmergencyLightingIds"
                #powerSupplyEmergencyLightingIds="ngModel"
                name="powerSupplyEmergencyLightingIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="powerSupplyEmergencyLightingOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let powerSupplyEmergencyLightingOptionType of powerSupplyEmergencyLightingOptionTypes | unordKeyValue
                  "
                  [value]="powerSupplyEmergencyLightingOptionType.key"
                  [title]="powerSupplyEmergencyLightingOptionType.value.displayName"
                  [disabled]="powerSupplyEmergencyLightingOptionType.value.isDisabled"
                  >{{ powerSupplyEmergencyLightingOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[powerSupplyEmergencyLightingIds.name]"-->
<!--                [class.text-secondary-primary]="notes[powerSupplyEmergencyLightingIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[powerSupplyEmergencyLightingIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="powerSupplyEmergencyLightingIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende vägledande markeringar och nödbelysning">
              <mat-label title="Extended description regarding indicative markings and emergency lighting"
                >Utökad beskrivning avseende vägledande markeringar och nödbelysning</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.markingsEmergencyLightingDescription"
                #markingsEmergencyLightingDescription="ngModel"
                name="markingsEmergencyLightingDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[markingsEmergencyLightingDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[markingsEmergencyLightingDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[markingsEmergencyLightingDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="markingsEmergencyLightingDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />
        <!-- Fire Alarms -->

        <div class="row">
          <div class="col-12 col-sm-4 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Brandvarnare förekommer">
              <mat-label title="Fire alarms occur">Brandvarnare förekommer</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isFireAlarmsOccur"
                #isFireAlarmsOccur="ngModel"
                name="isFireAlarmsOccur"
              />
              <mat-radio-group
                class="d-block"
                name="isFireAlarmsOccurRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isFireAlarmsOccur"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isFireAlarmsOccur.name]"-->
<!--                [class.text-secondary-primary]="notes[isFireAlarmsOccur.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isFireAlarmsOccur.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isFireAlarmsOccur.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-4 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Formellt krav">
              <mat-label title="Formal requirements">Formellt krav</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasFireAlarmsFormalRequirements"
                #hasFireAlarmsFormalRequirements="ngModel"
                name="hasFireAlarmsFormalRequirements"
              />
              <mat-radio-group
                class="d-block"
                name="hasFireAlarmsFormalRequirementsRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasFireAlarmsFormalRequirements"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasFireAlarmsFormalRequirements.name]"-->
<!--                [class.text-secondary-primary]="notes[hasFireAlarmsFormalRequirements.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasFireAlarmsFormalRequirements.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasFireAlarmsFormalRequirements.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-4 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Seriekoppling">
              <mat-label title="Series connection">Seriekoppling</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.installations.isFireAlarmsOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.hasFireAlarmsSeriesConnection"
                #hasFireAlarmsSeriesConnection="ngModel"
                name="hasFireAlarmsSeriesConnection"
              />
              <mat-radio-group
                class="d-block"
                name="hasFireAlarmsSeriesConnectionRadio"
                [(ngModel)]="document.installations.hasFireAlarmsSeriesConnection"
                [disabled]="!document.installations.isFireAlarmsOccur || !hasEditPermissions"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasFireAlarmsSeriesConnection.name]"-->
<!--                [class.text-secondary-primary]="notes[hasFireAlarmsSeriesConnection.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasFireAlarmsSeriesConnection.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasFireAlarmsSeriesConnection.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende brandvarnare">
              <mat-label title="Extended description regarding fire alarms"
                >Utökad beskrivning avseende brandvarnare</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.fireAlarmsDescription"
                #fireAlarmsDescription="ngModel"
                name="fireAlarmsDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[fireAlarmsDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[fireAlarmsDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[fireAlarmsDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="fireAlarmsDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />
        <!-- Automatic Fire Alarms -->

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Automatiskt brandlarm förekommer">
              <mat-label title="Automatic fire alarm occurs">Automatiskt brandlarm förekommer</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isAutomaticFireAlarmsOccur"
                #isAutomaticFireAlarmsOccur="ngModel"
                name="isAutomaticFireAlarmsOccur"
              />
              <mat-radio-group
                class="d-block"
                name="isAutomaticFireAlarmsOccurRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isAutomaticFireAlarmsOccur"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isAutomaticFireAlarmsOccur.name]"-->
<!--                [class.text-secondary-primary]="notes[isAutomaticFireAlarmsOccur.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isAutomaticFireAlarmsOccur.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isAutomaticFireAlarmsOccur.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Formellt krav">
              <mat-label title="Formal requirements">Formellt krav</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasAutomaticFireAlarmsFormalRequirements"
                #hasAutomaticFireAlarmsFormalRequirements="ngModel"
                name="hasAutomaticFireAlarmsFormalRequirements"
              />
              <mat-radio-group
                class="d-block"
                name="hasAutomaticFireAlarmsFormalRequirementsRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasAutomaticFireAlarmsFormalRequirements"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasAutomaticFireAlarmsFormalRequirements.name]"-->
<!--                [class.text-secondary-primary]="notes[hasAutomaticFireAlarmsFormalRequirements.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasAutomaticFireAlarmsFormalRequirements.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasAutomaticFireAlarmsFormalRequirements.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Formellt krav enligt analytisk dimensionering">
              <mat-label title="Formellt krav enligt analytisk dimensionering">Formellt krav enligt analytisk dimensionering</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur"
                [(ngModel)]="document.installations.hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning"
                #hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning="ngModel"
                name="hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning"
              />
              <mat-radio-group
                class="d-block"
                name="hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioningRadio"
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur"
                [(ngModel)]="document.installations.hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Egen ambition">
              <mat-label title="Egen ambition">Egen ambition</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur"
                [(ngModel)]="document.installations.isAutomaticFireAlarmOwnAmbition"
                #isAutomaticFireAlarmOwnAmbition="ngModel"
                name="isAutomaticFireAlarmOwnAmbition"
              />
              <mat-radio-group
                class="d-block"
                name="isAutomaticFireAlarmOwnAmbitionRadio"
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur"
                [(ngModel)]="document.installations.isAutomaticFireAlarmOwnAmbition"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Samordnad funktion gentemot automatiskt brandlarm är aktuellt för"
              [values]="coordinatedFunctionAutomaticFireAlarmRelevantOptionTypes"
              [selectedKeys]="document.installations.coordinatedFunctionAutomaticFireAlarmRelevantIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="coordinatedFunctionAutomaticFireAlarmRelevantIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Samordnad funktion gentemot automatiskt brandlarm är aktuellt för"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label>Samordnad funktion gentemot automatiskt brandlarm är aktuellt för</mat-label>

              <mat-select
                required
                [disabled]="!document.installations.isAutomaticFireAlarmsOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.coordinatedFunctionAutomaticFireAlarmRelevantIds"
                #coordinatedFunctionAutomaticFireAlarmRelevantIds="ngModel"
                name="coordinatedFunctionAutomaticFireAlarmRelevantIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="coordinatedFunctionAutomaticFireAlarmRelevantOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let controlAutomaticFireAlarmRelevantOptionType of coordinatedFunctionAutomaticFireAlarmRelevantOptionTypes
                      | keyvalue
                  "
                  [value]="controlAutomaticFireAlarmRelevantOptionType.key"
                  [title]="controlAutomaticFireAlarmRelevantOptionType.value.displayName"
                  [disabled]="controlAutomaticFireAlarmRelevantOptionType.value.isDisabled"
                >{{ controlAutomaticFireAlarmRelevantOptionType.value.displayName }}</mat-option
                >
              </mat-select>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[coordinatedFunctionAutomaticFireAlarmRelevantIds.name]"-->
              <!--                [class.text-secondary-primary]="notes[coordinatedFunctionAutomaticFireAlarmRelevantIds.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[coordinatedFunctionAutomaticFireAlarmRelevantIds.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="coordinatedFunctionAutomaticFireAlarmRelevantIds.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Övervakning/omfattning"
              [values]="automaticFireAlarmsMonitoringScopeOptionTypes"
              [selectedKeys]="document.installations.automaticFireAlarmsMonitoringScopeIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="automaticFireAlarmsMonitoringScopeIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Övervakning/omfattning"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label title="Monitoring / scope">Övervakning/omfattning</mat-label>

              <mat-select
                required
                [disabled]="!document.installations.isAutomaticFireAlarmsOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.automaticFireAlarmsMonitoringScopeIds"
                #automaticFireAlarmsMonitoringScopeIds="ngModel"
                name="automaticFireAlarmsMonitoringScopeIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="automaticFireAlarmsMonitoringScopeOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let automaticFireAlarmsMonitoringScopeOptionType of automaticFireAlarmsMonitoringScopeOptionTypes
                      | keyvalue
                  "
                  [value]="automaticFireAlarmsMonitoringScopeOptionType.key"
                  [title]="automaticFireAlarmsMonitoringScopeOptionType.value.displayName"
                  [disabled]="automaticFireAlarmsMonitoringScopeOptionType.value.isDisabled"
                >{{ automaticFireAlarmsMonitoringScopeOptionType.value.displayName }}</mat-option
                >
              </mat-select>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[automaticFireAlarmsMonitoringScopeIds.name]"-->
              <!--                [class.text-secondary-primary]="notes[automaticFireAlarmsMonitoringScopeIds.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[automaticFireAlarmsMonitoringScopeIds.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="automaticFireAlarmsMonitoringScopeIds.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Detektering"
              [values]="automaticFireAlarmDetekteringOptionTypes"
              [selectedKeys]="document.installations.automaticFireAlarmDetekteringIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="automaticFireAlarmDetekteringIds"
            ></app-multiselect-readonly-view>

            <mat-form-field floatLabel="always" class="w-100" title="Detektering" [class.d-none]="!hasEditPermissions">
              <mat-label title="Detection">Detektering</mat-label>

              <mat-select
                required
                [disabled]="!document.installations.isAutomaticFireAlarmsOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.automaticFireAlarmDetekteringIds"
                #automaticFireAlarmDetekteringIds="ngModel"
                name="automaticFireAlarmDetekteringIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="automaticFireAlarmDetekteringOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let automaticFireAlarmDetekteringOptionType of automaticFireAlarmDetekteringOptionTypes | keyvalue
                  "
                  [value]="automaticFireAlarmDetekteringOptionType.key"
                  [title]="automaticFireAlarmDetekteringOptionType.value.displayName"
                  [disabled]="automaticFireAlarmDetekteringOptionType.value.isDisabled"
                  >{{ automaticFireAlarmDetekteringOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[automaticFireAlarmDetekteringIds.name]"-->
<!--                [class.text-secondary-primary]="notes[automaticFireAlarmDetekteringIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[automaticFireAlarmDetekteringIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="automaticFireAlarmDetekteringIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-9 col-xl-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Aktivering"
              [values]="automaticFireAlarmActivationOptionTypes"
              [selectedKeys]="document.installations.automaticFireAlarmActivationIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="automaticFireAlarmActivationIds"
            ></app-multiselect-readonly-view>

            <mat-form-field class="w-100" title="Aktivering" floatLabel="always">
              <mat-label title="Aktivering">Aktivering</mat-label>
              <mat-select
                required
                multiple
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur"
                [(ngModel)]="document.installations.automaticFireAlarmActivationIds"
                #automaticFireAlarmActivationIds="ngModel"
                name="automaticFireAlarmActivationIds"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let automaticFireAlarmActivationOptionType of automaticFireAlarmActivationOptionTypes | unordKeyValue"
                  [value]="automaticFireAlarmActivationOptionType.key"
                  [title]="automaticFireAlarmActivationOptionType.value.displayName"
                >{{ automaticFireAlarmActivationOptionType.value.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3">
            <mat-form-field class="w-100" *ngIf="isAutomaticFireAlarmActivationOtherEnabled">
              <mat-label>Annat</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur"
                [(ngModel)]="document.installations.automaticFireAlarmActivationOther"
                #automaticFireAlarmActivationOther="ngModel"
                name="automaticFireAlarmActivationOther"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-9 col-xl-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Strömförsörjning"
              [values]="automaticFireAlarmPowerSupplyOptionTypes"
              [selectedKeys]="document.installations.automaticFireAlarmPowerSupplyIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="automaticFireAlarmPowerSupplyIds"
            ></app-multiselect-readonly-view>

            <mat-form-field floatLabel="always" class="w-100" title="Strömförsörjning">
              <mat-label title="Strömförsörjning">Strömförsörjning</mat-label>

              <mat-select
                required
                multiple
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur"
                [(ngModel)]="document.installations.automaticFireAlarmPowerSupplyIds"
                #automaticFireAlarmPowerSupplyIds="ngModel"
                name="automaticFireAlarmPowerSupplyIds"
                [appDocumentNotApplicableSelectOptions]="automaticFireAlarmPowerSupplyOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let automaticFireAlarmPowerSupplyOptionType of automaticFireAlarmPowerSupplyOptionTypes | keyvalue"
                  [value]="automaticFireAlarmPowerSupplyOptionType.key"
                  [title]="automaticFireAlarmPowerSupplyOptionType.value.displayName"
                  [disabled]="automaticFireAlarmPowerSupplyOptionType.value.isDisabled"
                >{{ automaticFireAlarmPowerSupplyOptionType.value.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3">
            <mat-form-field class="w-100" *ngIf="isAutomaticFireAlarmPowerSupplyOtherEnabled">
              <mat-label>Annat</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur"
                [(ngModel)]="document.installations.automaticFireAlarmPowerSupplyOther"
                #automaticFireAlarmPowerSupplyOther="ngModel"
                name="automaticFireAlarmPowerSupplyOther"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Larmmottagare"
              [values]="automaticFireAlarmsReceiverOptionTypes"
              [selectedKeys]="document.installations.automaticFireAlarmsReceiverIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="automaticFireAlarmsReceiverIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Larmmottagare"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label title="Alarm receiver">Larmmottagare</mat-label>

              <mat-select
                required
                [disabled]="!document.installations.isAutomaticFireAlarmsOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.automaticFireAlarmsReceiverIds"
                #automaticFireAlarmsReceiverIds="ngModel"
                name="automaticFireAlarmsReceiverIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="automaticFireAlarmsReceiverOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let automaticFireAlarmsReceiverOptionType of automaticFireAlarmsReceiverOptionTypes | keyvalue
                  "
                  [value]="automaticFireAlarmsReceiverOptionType.key"
                  [title]="automaticFireAlarmsReceiverOptionType.value.displayName"
                  [disabled]="automaticFireAlarmsReceiverOptionType.value.isDisabled"
                  >{{ automaticFireAlarmsReceiverOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[automaticFireAlarmsReceiverIds.name]"-->
<!--                [class.text-secondary-primary]="notes[automaticFireAlarmsReceiverIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[automaticFireAlarmsReceiverIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="automaticFireAlarmsReceiverIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Särskild anpassning"
              [values]="automaticFireAlarmSpecialAdaptationOptionTypes"
              [selectedKeys]="document.installations.automaticFireAlarmSpecialAdaptationIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="automaticFireAlarmSpecialAdaptationIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Särskild anpassning"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label
                title="Särskild anpassning"
                >Särskild anpassning</mat-label
              >

              <mat-select
                required
                [disabled]="!document.installations.isAutomaticFireAlarmsOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.automaticFireAlarmSpecialAdaptationIds"
                #automaticFireAlarmSpecialAdaptationIds="ngModel"
                name="automaticFireAlarmSpecialAdaptationIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="automaticFireAlarmSpecialAdaptationOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let automaticFireAlarmSpecialAdaptationOptionType of automaticFireAlarmSpecialAdaptationOptionTypes
                      | keyvalue
                  "
                  [value]="automaticFireAlarmSpecialAdaptationOptionType.key"
                  [title]="automaticFireAlarmSpecialAdaptationOptionType.value.displayName"
                  [disabled]="automaticFireAlarmSpecialAdaptationOptionType.value.isDisabled"
                  >{{ automaticFireAlarmSpecialAdaptationOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[automaticFireAlarmSpecialAdaptationIds.name]"-->
<!--                [class.text-secondary-primary]="notes[automaticFireAlarmSpecialAdaptationIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[automaticFireAlarmSpecialAdaptationIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="automaticFireAlarmSpecialAdaptationIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Gällande regelverk">
              <mat-label title="Gällande regelverk">Gällande regelverk</mat-label>
              <input
                type="text"
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur "
                [(ngModel)]="document.installations.automaticFireAlarmCurrentRegulations"
                #automaticFireAlarmCurrentRegulations="ngModel"
                name="automaticFireAlarmCurrentRegulations"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Anläggarintyg">
              <mat-label title="Anläggarintyg">Anläggarintyg</mat-label>
              <input
                type="text"
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur "
                [(ngModel)]="document.installations.automaticFireAlarmConstructionCertificate"
                #automaticFireAlarmConstructionCertificate="ngModel"
                name="automaticFireAlarmConstructionCertificate"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Service-/orienteringsritningar">
              <mat-label title="Service-/orienteringsritningar">Service-/orienteringsritningar</mat-label>
              <input
                type="text"
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur "
                [(ngModel)]="document.installations.automaticFireAlarmServiceOrientationDrawings"
                #automaticFireAlarmServiceOrientationDrawings="ngModel"
                name="automaticFireAlarmServiceOrientationDrawings"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Placering av brandförsvarstablå/brandlarmcentral">
              <mat-label title="Placering av brandförsvarstablå/brandlarmcentral">Placering av brandförsvarstablå/brandlarmcentral</mat-label>
              <input
                type="text"
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur "
                [(ngModel)]="document.installations.locationOfFireProtectionPanelFireAlarmCenter"
                #locationOfFireProtectionPanelFireAlarmCenter="ngModel"
                name="locationOfFireProtectionPanelFireAlarmCenter"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Placering av informationstablå(er) för brandlarm (verksamhet)">
              <mat-label title="Placering av informationstablå(er) för brandlarm (verksamhet)">
                Placering av informationstablå(er) för brandlarm (verksamhet)
              </mat-label>
              <input
                type="text"
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur "
                [(ngModel)]="document.installations.locationOfInformationTableForFireAlarms"
                #locationOfInformationTableForFireAlarms="ngModel"
                name="locationOfInformationTableForFireAlarms"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Placering av larmlagringstablå(er) för brandlarm (verksamhet)">
              <mat-label title="Placering av larmlagringstablå(er) för brandlarm (verksamhet)">
                Placering av larmlagringstablå(er) för brandlarm (verksamhet)
              </mat-label>
              <input
                type="text"
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticFireAlarmsOccur "
                [(ngModel)]="document.installations.locationOfAlarmStorageTablesForFireAlarms"
                #locationOfAlarmStorageTablesForFireAlarms="ngModel"
                name="locationOfAlarmStorageTablesForFireAlarms"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende automatiskt brandlarm">
              <mat-label
                title="Utökad beskrivning avseende automatiskt brandlarm"
                >Utökad beskrivning avseende automatiskt brandlarm</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.automaticFireAlarmsDescription"
                #automaticFireAlarmsDescription="ngModel"
                name="automaticFireAlarmsDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[automaticFireAlarmsDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[automaticFireAlarmsDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[automaticFireAlarmsDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="automaticFireAlarmsDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />
        <!-- Evacuation Alarms -->

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Larmsignalering förekommer">
              <mat-label>Larmsignalering förekommer</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isAlarmSignalingOccur"
                #isAlarmSignalingOccur="ngModel"
                name="isAlarmSignalingOccur"
              />
              <mat-radio-group
                class="d-block"
                name="isEvacuationAlarmsOccurRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isAlarmSignalingOccur"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isAlarmSignalingOccur.name]"-->
<!--                [class.text-secondary-primary]="notes[isAlarmSignalingOccur.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isAlarmSignalingOccur.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isAlarmSignalingOccur.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Formellt krav">
              <mat-label>Formellt krav</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasEvacuationAlarmsFormalRequirements"
                #hasEvacuationAlarmsFormalRequirements="ngModel"
                name="hasEvacuationAlarmsFormalRequirements"
              />
              <mat-radio-group
                class="d-block"
                name="hasEvacuationAlarmsFormalRequirementsRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasEvacuationAlarmsFormalRequirements"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasEvacuationAlarmsFormalRequirements.name]"-->
<!--                [class.text-secondary-primary]="notes[hasEvacuationAlarmsFormalRequirements.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasEvacuationAlarmsFormalRequirements.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasEvacuationAlarmsFormalRequirements.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Formellt krav enligt analytisk dimensionering">
              <mat-label>Formellt krav enligt analytisk dimensionering</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !document.installations.isAlarmSignalingOccur"
                [(ngModel)]="document.installations.hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning"
                #hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning="ngModel"
                name="hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning"
              />
              <mat-radio-group
                class="d-block"
                name="hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioningRadio"
                [disabled]="!hasEditPermissions || !document.installations.isAlarmSignalingOccur"
                [(ngModel)]="document.installations.hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Egen ambition">
              <mat-label>Egen ambition</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !document.installations.isAlarmSignalingOccur"
                [(ngModel)]="document.installations.isAlarmSignalingOwnAmbition"
                #isAlarmSignalingOwnAmbition="ngModel"
                name="isAlarmSignalingOwnAmbition"
              />
              <mat-radio-group
                class="d-block"
                name="isAlarmSignalingOwnAmbitionRadio"
                [disabled]="!hasEditPermissions || !document.installations.isAlarmSignalingOccur"
                [(ngModel)]="document.installations.isAlarmSignalingOwnAmbition"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Larmsignalering förekommer genom"
              [values]="alarmSignalingOccurOptionTypes"
              [selectedKeys]="document.installations.alarmSignalingOccurIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="alarmSignalingOccurIds"
            ></app-multiselect-readonly-view>
<!-- TODO: Why do we need class.d-none on no hasEditPermissions?  -->
            <mat-form-field floatLabel="always" class="w-100" title="Larmsignalering förekommer genom" [class.d-none]="!hasEditPermissions">
              <mat-label title="Larmsignalering förekommer genom">Larmsignalering förekommer genom</mat-label>

              <mat-select
                required
                [disabled]="!document.installations.isAlarmSignalingOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.alarmSignalingOccurIds"
                #alarmSignalingOccurIds="ngModel"
                name="alarmSignalingOccurIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="alarmSignalingOccurOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let alarmSignalingOccurOptionType of alarmSignalingOccurOptionTypes | unordKeyValue
                  "
                  [value]="alarmSignalingOccurOptionType.key"
                  [title]="alarmSignalingOccurOptionType.value.displayName"
                  [disabled]="alarmSignalingOccurOptionType.value.isDisabled"
                >{{ alarmSignalingOccurOptionType.value.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Utrymningsmikrofon för räddningstjänst">
              <mat-label>Utrymningsmikrofon för räddningstjänst</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !document.installations.isAlarmSignalingOccur"
                [(ngModel)]="document.installations.hasEmergencyMicrophoneForEmergencyService"
                #hasEmergencyMicrophoneForEmergencyService="ngModel"
                name="hasEmergencyMicrophoneForEmergencyService"
              />
              <mat-radio-group
                class="d-block"
                name="hasEmergencyMicrophoneForEmergencyServiceRadio"
                [disabled]="!hasEditPermissions || !document.installations.isAlarmSignalingOccur"
                [(ngModel)]="document.installations.hasEmergencyMicrophoneForEmergencyService"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Larmsignalering kan uppfattas i"
              [values]="evacuationAlarmsPerceivedOptionTypes"
              [selectedKeys]="document.installations.alarmSignalPerceivedIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="alarmSignalPerceivedIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Larmsignalering kan uppfattas i"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label
                title="Larmsignalering kan uppfattas i"
                >Larmsignalering kan uppfattas i</mat-label
              >

              <mat-select
                required
                [disabled]="!document.installations.isAlarmSignalingOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.alarmSignalPerceivedIds"
                #alarmSignalPerceivedIds="ngModel"
                name="alarmSignalPerceivedIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="evacuationAlarmsPerceivedOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let evacuationAlarmsPerceivedOptionType of evacuationAlarmsPerceivedOptionTypes | keyvalue"
                  [value]="evacuationAlarmsPerceivedOptionType.key"
                  [title]="evacuationAlarmsPerceivedOptionType.value.displayName"
                  [disabled]="evacuationAlarmsPerceivedOptionType.value.isDisabled"
                  >{{ evacuationAlarmsPerceivedOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[alarmSignalPerceivedIds.name]"-->
<!--                [class.text-secondary-primary]="notes[alarmSignalPerceivedIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[alarmSignalPerceivedIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="alarmSignalPerceivedIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Aktivering"
              [values]="evacuationAlarmsActivationOptionTypes"
              [selectedKeys]="document.installations.evacuationAlarmsActivationIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="evacuationAlarmsActivationIds"
            ></app-multiselect-readonly-view>

            <mat-form-field floatLabel="always" class="w-100" title="Aktivering" [class.d-none]="!hasEditPermissions">
              <mat-label>Aktivering</mat-label>

              <mat-select
                required
                [disabled]="!document.installations.isAlarmSignalingOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.evacuationAlarmsActivationIds"
                #evacuationAlarmsActivationIds="ngModel"
                name="evacuationAlarmsActivationIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="evacuationAlarmsActivationOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let evacuationAlarmsActivationOptionType of evacuationAlarmsActivationOptionTypes | unordKeyValue"
                  [value]="evacuationAlarmsActivationOptionType.key"
                  [title]="evacuationAlarmsActivationOptionType.value.displayName"
                  [disabled]="evacuationAlarmsActivationOptionType.value.isDisabled"
                >{{ evacuationAlarmsActivationOptionType.value.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3">
            <mat-form-field class="w-100" *ngIf="isEvacuationAlarmsActivationOtherEnabled">
              <mat-label>Annat</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAlarmSignalingOccur"
                [(ngModel)]="document.installations.evacuationAlarmsActivationOther"
                #evacuationAlarmsActivationOther="ngModel"
                name="evacuationAlarmsActivationOther"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Larmsignal"
              [values]="evacuationAlarmsSignalOptionTypes"
              [selectedKeys]="document.installations.evacuationAlarmsSignalIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="evacuationAlarmsSignalIds"
            ></app-multiselect-readonly-view>

            <mat-form-field floatLabel="always" class="w-100" title="Larmsignal" [class.d-none]="!hasEditPermissions">
              <mat-label title="Larmsignal"
                >Larmsignal</mat-label
              >

              <mat-select
                required
                [disabled]="!document.installations.isAlarmSignalingOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.evacuationAlarmsSignalIds"
                #evacuationAlarmsSignalIds="ngModel"
                name="evacuationAlarmsSignalIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="evacuationAlarmsSignalOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let evacuationAlarmsSignalOptionType of evacuationAlarmsSignalOptionTypes | keyvalue"
                  [value]="evacuationAlarmsSignalOptionType.key"
                  [title]="evacuationAlarmsSignalOptionType.value.displayName"
                  [disabled]="evacuationAlarmsSignalOptionType.value.isDisabled"
                  >{{ evacuationAlarmsSignalOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[evacuationAlarmsSignalIds.name]"-->
<!--                [class.text-secondary-primary]="notes[evacuationAlarmsSignalIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[evacuationAlarmsSignalIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="evacuationAlarmsSignalIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Särskild anpassning"
              [values]="evacuationAlarmsSpecialAdaptationOptionTypes"
              [selectedKeys]="document.installations.evacuationAlarmsSpecialAdaptationIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="evacuationAlarmsSpecialAdaptationIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Särskild anpassning"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label
                title="Särskild anpassning"
                >Särskild anpassning</mat-label
              >

              <mat-select
                required
                [disabled]="!document.installations.isAlarmSignalingOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.evacuationAlarmsSpecialAdaptationIds"
                #evacuationAlarmsSpecialAdaptationIds="ngModel"
                name="evacuationAlarmsSpecialAdaptationIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="evacuationAlarmsSpecialAdaptationOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let evacuationAlarmsSpecialAdaptationOptionType of evacuationAlarmsSpecialAdaptationOptionTypes
                      | keyvalue
                  "
                  [value]="evacuationAlarmsSpecialAdaptationOptionType.key"
                  [title]="evacuationAlarmsSpecialAdaptationOptionType.value.displayName"
                  [disabled]="evacuationAlarmsSpecialAdaptationOptionType.value.isDisabled"
                  >{{ evacuationAlarmsSpecialAdaptationOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[evacuationAlarmsSpecialAdaptationIds.name]"-->
<!--                [class.text-secondary-primary]="notes[evacuationAlarmsSpecialAdaptationIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[evacuationAlarmsSpecialAdaptationIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="evacuationAlarmsSpecialAdaptationIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Gällande regelverk">
              <mat-label title="Gällande regelverk">Gällande regelverk</mat-label>
              <input
                type="text"
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAlarmSignalingOccur"
                [(ngModel)]="document.installations.alarmSignalingCurrentRegulations"
                #alarmSignalingCurrentRegulations="ngModel"
                name="alarmSignalingCurrentRegulations"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Anläggarintyg larmsignalering">
              <mat-label title="Anläggarintyg larmsignalering">Anläggarintyg larmsignalering</mat-label>
              <input
                type="text"
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAlarmSignalingOccur"
                [(ngModel)]="document.installations.alarmSignalingConstructionCertificate"
                #alarmSignalingConstructionCertificate="ngModel"
                name="alarmSignalingConstructionCertificate"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende automatiskt brandlarm">
              <mat-label
                title="Extended description regarding automatic fire alarm"
                >Utökad beskrivning avseende utrymningslarm</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.evacuationAlarmsDescription"
                #evacuationAlarmsDescription="ngModel"
                name="evacuationAlarmsDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[evacuationAlarmsDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[evacuationAlarmsDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[evacuationAlarmsDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="evacuationAlarmsDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Placering av don som tystar larmsignalering vid aktivering av personal">
              <mat-label title="Placering av don som tystar larmsignalering vid aktivering av personal">
                Placering av don som tystar larmsignalering vid aktivering av personal
              </mat-label>
              <input
                type="text"
                matInput
                required
                [disabled]="!hasEditPermissions || !document.installations.isAlarmSignalingOccur "
                [(ngModel)]="document.installations.locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel"
                #locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel="ngModel"
                name="locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <hr />
        <!-- Automatic Water Sprinkler -->

        <div class="row">
          <div class="col-12 col-sm-6 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Automatiska släcksystem förekommer">
              <mat-label>Automatiska släcksystem förekommer</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isAutomaticExtinguishingSystemOccur"
                #isAutomaticExtinguishingSystemOccur="ngModel"
                name="isAutomaticExtinguishingSystemOccur"
              />
              <mat-radio-group
                class="d-block"
                name="isAutomaticWaterSprinklerOccurRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isAutomaticExtinguishingSystemOccur"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isAutomaticExtinguishingSystemOccur.name]"-->
<!--                [class.text-secondary-primary]="notes[isAutomaticExtinguishingSystemOccur.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isAutomaticExtinguishingSystemOccur.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isAutomaticExtinguishingSystemOccur.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Formellt krav">
              <mat-label>Formellt krav</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasAutomaticWaterSprinklerFormalRequirements"
                #hasAutomaticWaterSprinklerFormalRequirements="ngModel"
                name="hasAutomaticWaterSprinklerFormalRequirements"
              />
              <mat-radio-group
                class="d-block"
                name="hasAutomaticWaterSprinklerFormalRequirementsRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasAutomaticWaterSprinklerFormalRequirements"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasAutomaticWaterSprinklerFormalRequirements.name]"-->
<!--                [class.text-secondary-primary]="notes[hasAutomaticWaterSprinklerFormalRequirements.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasAutomaticWaterSprinklerFormalRequirements.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasAutomaticWaterSprinklerFormalRequirements.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Egen ambition">
              <mat-label>Egen ambition</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticExtinguishingSystemOccur"
                [(ngModel)]="document.installations.isAutomaticExtinguishingSystemOwnAmbition"
                #isAutomaticExtinguishingSystemOwnAmbition="ngModel"
                name="isAutomaticExtinguishingSystemOwnAmbition"
              />
              <mat-radio-group
                class="d-block"
                name="isAutomaticExtinguishingSystemOwnAmbitionRadio"
                [disabled]="!hasEditPermissions || !document.installations.isAutomaticExtinguishingSystemOccur"
                [(ngModel)]="document.installations.isAutomaticExtinguishingSystemOwnAmbition"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Automatiska släcksystem förekommer genom"
              [values]="automaticExtinguishingSystemOccurOptionTypes"
              [selectedKeys]="document.installations.automaticExtinguishingSystemOccurIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="automaticExtinguishingSystemOccurIds"
            ></app-multiselect-readonly-view>

            <mat-form-field floatLabel="always" class="w-100" title="Automatiska släcksystem förekommer genom" [class.d-none]="!hasEditPermissions">
              <mat-label>Automatiska släcksystem förekommer genom</mat-label>
              <mat-select
                required
                [disabled]="!document.installations.isAutomaticExtinguishingSystemOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.automaticExtinguishingSystemOccurIds"
                #automaticExtinguishingSystemOccurIds="ngModel"
                name="automaticExtinguishingSystemOccurIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="automaticExtinguishingSystemOccurOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let automaticExtinguishingSystemOccurOptionType of automaticExtinguishingSystemOccurOptionTypes
                      | unordKeyValue
                  "
                  [value]="automaticExtinguishingSystemOccurOptionType.key"
                  [title]="automaticExtinguishingSystemOccurOptionType.value.displayName"
                  [disabled]="automaticExtinguishingSystemOccurOptionType.value.isDisabled"
                  >{{ automaticExtinguishingSystemOccurOptionType.value.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Övervakning automatiska släcksystem"
              [values]="automaticWaterSprinklerMonitoringOptionTypes"
              [selectedKeys]="document.installations.monitoringAutomaticExtinguishingSystemIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="monitoringAutomaticExtinguishingSystemIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Övervakning automatiska släcksystem"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label
                title="Automatic water sprinkler monitoring"
              >Övervakning automatiska släcksystem</mat-label
              >

              <mat-select
                required
                [disabled]="!document.installations.isAutomaticExtinguishingSystemOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.monitoringAutomaticExtinguishingSystemIds"
                #monitoringAutomaticExtinguishingSystemIds="ngModel"
                name="monitoringAutomaticExtinguishingSystemIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="automaticWaterSprinklerMonitoringOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let automaticWaterSprinklerMonitoringOptionType of automaticWaterSprinklerMonitoringOptionTypes
                      | keyvalue
                  "
                  [value]="automaticWaterSprinklerMonitoringOptionType.key"
                  [title]="automaticWaterSprinklerMonitoringOptionType.value.displayName"
                  [disabled]="automaticWaterSprinklerMonitoringOptionType.value.isDisabled"
                >{{ automaticWaterSprinklerMonitoringOptionType.value.displayName }}</mat-option
                >
              </mat-select>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[monitoringAutomaticExtinguishingSystemIds.name]"-->
              <!--                [class.text-secondary-primary]="notes[monitoringAutomaticExtinguishingSystemIds.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[monitoringAutomaticExtinguishingSystemIds.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="monitoringAutomaticExtinguishingSystemIds.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Anpassning automatisk vattensprinkler"
              [values]="automaticWaterSprinklerAdjustmentOptionTypes"
              [selectedKeys]="document.installations.automaticWaterSprinklerAdjustmentIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="automaticWaterSprinklerAdjustmentIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Anpassning automatisk vattensprinkler"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label
                title="Automatic water sprinkler adjustment"
                >Anpassning automatisk vattensprinkler</mat-label
              >

              <mat-select
                required
                [disabled]="!document.installations.isAutomaticExtinguishingSystemOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.automaticWaterSprinklerAdjustmentIds"
                #automaticWaterSprinklerAdjustmentIds="ngModel"
                name="automaticWaterSprinklerAdjustmentIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="automaticWaterSprinklerAdjustmentOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let automaticWaterSprinklerAdjustmentOptionType of automaticWaterSprinklerAdjustmentOptionTypes
                      | keyvalue
                  "
                  [value]="automaticWaterSprinklerAdjustmentOptionType.key"
                  [title]="automaticWaterSprinklerAdjustmentOptionType.value.displayName"
                  [disabled]="automaticWaterSprinklerAdjustmentOptionType.value.isDisabled"
                  >{{ automaticWaterSprinklerAdjustmentOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[automaticWaterSprinklerAdjustmentIds.name]"-->
<!--                [class.text-secondary-primary]="notes[automaticWaterSprinklerAdjustmentIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[automaticWaterSprinklerAdjustmentIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="automaticWaterSprinklerAdjustmentIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Riskklass/sprinklertyp automatisk vattensprinkler"
              [values]="automaticWaterSprinklerRiskClassOptionTypes"
              [selectedKeys]="document.installations.automaticWaterSprinklerRiskClassIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="automaticWaterSprinklerRiskClassIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Riskklass/sprinklertyp automatisk vattensprinkler"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label
                title="Risk class / sprinkler type automatic water sprinkler"
              >Riskklass/sprinklertyp automatisk vattensprinkler</mat-label
              >

              <mat-select
                required
                [disabled]="!document.installations.isAutomaticExtinguishingSystemOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.automaticWaterSprinklerRiskClassIds"
                #automaticWaterSprinklerRiskClassIds="ngModel"
                name="automaticWaterSprinklerRiskClassIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="automaticWaterSprinklerRiskClassOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="
                    let automaticWaterSprinklerRiskClassOptionType of automaticWaterSprinklerRiskClassOptionTypes
                      | keyvalue
                  "
                  [value]="automaticWaterSprinklerRiskClassOptionType.key"
                  [title]="automaticWaterSprinklerRiskClassOptionType.value.displayName"
                  [disabled]="automaticWaterSprinklerRiskClassOptionType.value.isDisabled"
                >{{ automaticWaterSprinklerRiskClassOptionType.value.displayName }}</mat-option
                >
              </mat-select>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[automaticWaterSprinklerRiskClassIds.name]"-->
              <!--                [class.text-secondary-primary]="notes[automaticWaterSprinklerRiskClassIds.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[automaticWaterSprinklerRiskClassIds.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="automaticWaterSprinklerRiskClassIds.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Övrigt">
              <mat-label title="Other"
                >Övrigt</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!document.installations.isAutomaticExtinguishingSystemOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.automaticWaterSprinklerOther"
                #automaticWaterSprinklerOther="ngModel"
                name="automaticWaterSprinklerOther"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[automaticWaterSprinklerOther.name]"-->
<!--                [class.text-secondary-primary]="notes[automaticWaterSprinklerOther.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[automaticWaterSprinklerOther.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="automaticWaterSprinklerOther.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12">
            <div>Andra typer av släcksystem</div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="d-flex">
              <div class="flex-grow-1 d-flex flex-wrap">
                <mat-form-field class="flex-grow-1 mr-3 extinguishing-system-container" title="Släcksystem">
                  <mat-label>Släcksystem</mat-label>
                  <mat-select
                    [disabled]="!hasEditPermissions"
                    [(ngModel)]="data.extinguishingSystemId"
                    #extinguishingSystemId="ngModel"
                    name="extinguishingSystemId"
                  >
                    <mat-option
                      *ngFor="let extinguishingSystemOptionType of extinguishingSystemOptionTypes | keyvalue"
                      [value]="extinguishingSystemOptionType.key"
                      >{{ extinguishingSystemOptionType.value.displayName }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-grow-1 mr-3 extinguishing-system-container" title="Placering">
                  <mat-label title="Investment"
                    >Placering</mat-label
                  >
                  <input
                    matInput
                    [disabled]="!hasEditPermissions"
                    [(ngModel)]="data.extinguishingSystemInvestment"
                    #extinguishingSystemInvestment="ngModel"
                    id="extinguishingSystemInvestment"
                    name="extinguishingSystemInvestment"
                  />
                </mat-form-field>
              </div>

              <button
                mat-icon-button
                *ngIf="hasEditPermissions"
                (click)="onAddExtinguishingSystem($event)"
                class="add-button"
                [disabled]="!isExtinguishingSystemValid"
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>

          <div class="col-12 col-md-6 pb-4 mb-2">
            <div class="card mt-1 h-100">
              <div class="card-body p-3">
                <div
                  class="w-100 d-flex border rounded px-3 py-2 mb-2 mr-3"
                  *ngFor="let extinguishingSystem of document.installations.extinguishingSystems"
                >
                  <div class="flex-grow-1">
                    {{ extinguishingSystemOptionTypes[extinguishingSystem.extinguishingSystemId]?.displayName }} -
                    {{ extinguishingSystem.extinguishingSystemInvestment }}
                  </div>

                  <button
                    mat-icon-button
                    *ngIf="hasEditPermissions"
                    (click)="onDeleteExtinguishingSystem(extinguishingSystem)"
                    class="small-button align-self-center"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>

                <div *ngIf="document.installations.extinguishingSystems | isEmpty" class="w-100 text-muted text-center">
                  Andra typer av släcksystem förekommer ej
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-9 col-xl-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Släckutrustning förekommer i form av"
              [values]="extinguishingEquipmentFormOptionTypes"
              [selectedKeys]="document.installations.extinguishingEquipmentFormIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="extinguishingEquipmentFormIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Släckutrustning förekommer i form av"
              *ngIf="hasEditPermissions"
            >
              <mat-label
                title="Extinguishing equipment comes in the form of"
                >Släckutrustning förekommer i form av</mat-label
              >

              <mat-select
                required
                [(ngModel)]="document.installations.extinguishingEquipmentFormIds"
                #extinguishingEquipmentFormIds="ngModel"
                name="extinguishingEquipmentFormIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="extinguishingEquipmentFormOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let extinguishingEquipmentFormOptionType of extinguishingEquipmentFormOptionTypes | keyvalue"
                  [value]="extinguishingEquipmentFormOptionType.key"
                  [title]="extinguishingEquipmentFormOptionType.value.displayName"
                  [disabled]="extinguishingEquipmentFormOptionType.value.isDisabled"
                  >{{ extinguishingEquipmentFormOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[extinguishingEquipmentFormIds.name]"-->
<!--                [class.text-secondary-primary]="notes[extinguishingEquipmentFormIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[extinguishingEquipmentFormIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="extinguishingEquipmentFormIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3">
            <mat-form-field class="w-100" *ngIf="isExtinguishingEquipmentFormOtherEnabled">
              <mat-label>Annat</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.extinguishingEquipmentFormOther"
                #extinguishingEquipmentFormOther="ngModel"
                name="extinguishingEquipmentFormOther"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[extinguishingEquipmentFormOther.name]"-->
<!--                [class.text-secondary-primary]="notes[extinguishingEquipmentFormOther.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[extinguishingEquipmentFormOther.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="extinguishingEquipmentFormOther.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-sm-6 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Brandgasventilation förekommer">
              <mat-label>Brandgasventilation förekommer</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isFireGasVentilationOccur"
                #isFireGasVentilationOccur="ngModel"
                name="isFireGasVentilationOccur"
              />
              <mat-radio-group
                class="d-block"
                name="isFireGasVentilationOccurRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isFireGasVentilationOccur"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isFireGasVentilationOccur.name]"-->
<!--                [class.text-secondary-primary]="notes[isFireGasVentilationOccur.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isFireGasVentilationOccur.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isFireGasVentilationOccur.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Formellt krav">
              <mat-label>Formellt krav</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasFireGasVentilationFormalRequirements"
                #hasFireGasVentilationFormalRequirements="ngModel"
                name="hasFireGasVentilationFormalRequirements"
              />
              <mat-radio-group
                class="d-block"
                name="hasFireGasVentilationFormalRequirementsRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasFireGasVentilationFormalRequirements"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasFireGasVentilationFormalRequirements.name]"-->
<!--                [class.text-secondary-primary]="notes[hasFireGasVentilationFormalRequirements.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasFireGasVentilationFormalRequirements.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasFireGasVentilationFormalRequirements.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Egen ambition">
              <mat-label>Egen ambition</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !document.installations.isFireGasVentilationOccur"
                [(ngModel)]="document.installations.isFireGasVentilationOwnAmbition"
                #isFireGasVentilationOwnAmbition="ngModel"
                name="isFireGasVentilationOwnAmbition"
              />
              <mat-radio-group
                class="d-block"
                name="isFireGasVentilationOwnAmbitionRadio"
                [disabled]="!hasEditPermissions || !document.installations.isFireGasVentilationOccur"
                [(ngModel)]="document.installations.isFireGasVentilationOwnAmbition"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-9 col-md-4">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Följande lokaler har brandgasventilation"
              [values]="premisesFireGasVentilationOptionTypes"
              [selectedKeys]="document.installations.premisesFireGasVentilationIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="premisesFireGasVentilationIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Följande lokaler har brandgasventilation"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label
                title="The following premises have fire gas ventilation"
                >Följande lokaler har brandgasventilation</mat-label
              >

              <mat-select
                required
                [disabled]="!document.installations.isFireGasVentilationOccur || !hasEditPermissions"
                [(ngModel)]="document.installations.premisesFireGasVentilationIds"
                #premisesFireGasVentilationIds="ngModel"
                name="premisesFireGasVentilationIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="premisesFireGasVentilationOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let premisesFireGasVentilationOptionType of premisesFireGasVentilationOptionTypes | keyvalue"
                  [value]="premisesFireGasVentilationOptionType.key"
                  [title]="premisesFireGasVentilationOptionType.value.displayName"
                  [disabled]="premisesFireGasVentilationOptionType.value.isDisabled"
                  >{{ premisesFireGasVentilationOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[premisesFireGasVentilationIds.name]"-->
<!--                [class.text-secondary-primary]="notes[premisesFireGasVentilationIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[premisesFireGasVentilationIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="premisesFireGasVentilationIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3 col-md-2">
            <mat-form-field class="w-100" [class.d-none]="!isPremisesFireGasVentilationOtherEnabled">
              <mat-label>Annat</mat-label>
              <input
                matInput
                required
                [disabled]="
                  !document.installations.isFireGasVentilationOccur ||
                  !isPremisesFireGasVentilationOtherEnabled ||
                  !hasEditPermissions
                "
                [(ngModel)]="document.installations.premisesFireGasVentilationOther"
                #premisesFireGasVentilationOther="ngModel"
                name="premisesFireGasVentilationOther"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[premisesFireGasVentilationOther.name]"-->
<!--                [class.text-secondary-primary]="notes[premisesFireGasVentilationOther.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[premisesFireGasVentilationOther.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="premisesFireGasVentilationOther.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende brandgasventilation">
              <mat-label
                title="Extended description regarding fire gas ventilation"
                >Utökad beskrivning avseende brandgasventilation</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.fireGasVentilationDescription"
                #fireGasVentilationDescription="ngModel"
                name="fireGasVentilationDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[fireGasVentilationDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[fireGasVentilationDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[fireGasVentilationDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="fireGasVentilationDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-sm-6 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Övriga brandtekniska installationer förekommer">
              <mat-label
                title="Other fire technical installations occur"
                >Övriga brandtekniska installationer förekommer</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isOtherFireTechnicalInstallationsOccur"
                #isOtherFireTechnicalInstallationsOccur="ngModel"
                name="isOtherFireTechnicalInstallationsOccur"
              />
              <mat-radio-group
                class="d-block"
                name="isOtherFireTechnicalInstallationsOccurRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.isOtherFireTechnicalInstallationsOccur"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isOtherFireTechnicalInstallationsOccur.name]"-->
<!--                [class.text-secondary-primary]="notes[isOtherFireTechnicalInstallationsOccur.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isOtherFireTechnicalInstallationsOccur.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isOtherFireTechnicalInstallationsOccur.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Formellt krav">
              <mat-label
                title="Formal requirements"
                >Formellt krav</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasOtherFireTechnicalInstallationsFormalRequirements"
                #hasOtherFireTechnicalInstallationsFormalRequirements="ngModel"
                name="hasOtherFireTechnicalInstallationsFormalRequirements"
              />
              <mat-radio-group
                class="d-block"
                name="hasOtherFireTechnicalInstallationsFormalRequirementsRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.hasOtherFireTechnicalInstallationsFormalRequirements"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasOtherFireTechnicalInstallationsFormalRequirements.name]"-->
<!--                [class.text-secondary-primary]="notes[hasOtherFireTechnicalInstallationsFormalRequirements.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasOtherFireTechnicalInstallationsFormalRequirements.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasOtherFireTechnicalInstallationsFormalRequirements.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-2">
            <mat-form-field floatLabel="always" class="w-100" title="Egen ambition">
              <mat-label>Egen ambition</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !document.installations.isOtherFireTechnicalInstallationsOccur"
                [(ngModel)]="document.installations.isOtherFireTechnicalInstallationsOwnAmbition"
                #isOtherFireTechnicalInstallationsOwnAmbition="ngModel"
                name="isOtherFireTechnicalInstallationsOwnAmbition"
              />
              <mat-radio-group
                class="d-block"
                name="isOtherFireTechnicalInstallationsOwnAmbitionRadio"
                [disabled]="!hasEditPermissions || !document.installations.isOtherFireTechnicalInstallationsOccur"
                [(ngModel)]="document.installations.isOtherFireTechnicalInstallationsOwnAmbition"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende övriga brandtekniska installationer">
              <mat-label
                title="Extended description regarding fire gas ventilation"
                >Utökad beskrivning avseende övriga brandtekniska installationer</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.installations.otherFireTechnicalInstallationsDescription"
                #otherFireTechnicalInstallationsDescription="ngModel"
                name="otherFireTechnicalInstallationsDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[otherFireTechnicalInstallationsDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[otherFireTechnicalInstallationsDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[otherFireTechnicalInstallationsDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="otherFireTechnicalInstallationsDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
