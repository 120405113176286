<div class="row" *ngIf="editedUser">
  <div class="col-6 offset-md-3">
    <div class="card p-3">
      <form #userProfileForm="ngForm" novalidate>
        <div class="d-flex justify-content-center">
          <mat-icon class="user-profile-icon">account_circle</mat-icon>
          <div class="text-center my-4 text-muted text-extra-large" *ngIf="state.isLoading">
            <i class="fas fa-spinner fa-pulse"></i>
          </div>
          <div
            class="change-password"
            [class.disabled-field]="state.isLoading"
            appChangePasswordTrigger
            [userId]="user.user.id"
          >
            Ändra lösenord
          </div>
        </div>
        <div [class.disabled-field]="state.isLoading">
          <mat-form-field class="w-100">
            <mat-label>Förnamn</mat-label>
            <input
              matInput
              required
              [disabled]="state.isLoading"
              [(ngModel)]="editedUser.user.firstName"
              #firstName="ngModel"
              name="firstName"
              [appAllowedCharacters]="nameAllowedCharacters"
              [maxlength]="nameMaxLength"
              [class.is-invalid]="(firstName.dirty || firstName.touched) && firstName.invalid"
              placeholder="Förnamn"
            />
            <mat-error>
              <div *ngIf="!!firstName.errors?.required">Ange ett förnamn</div>

              <div *ngIf="!!firstName.errors?.maxlength">Får inte vara mer än {{ nameMaxLength }} tecken.</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div [class.disabled-field]="state.isLoading">
          <mat-form-field class="w-100">
            <mat-label>Efternamn</mat-label>
            <input
              matInput
              required
              [disabled]="state.isLoading"
              [(ngModel)]="editedUser.user.lastName"
              #lastName="ngModel"
              name="lastName"
              [appAllowedCharacters]="nameAllowedCharacters"
              [maxlength]="nameMaxLength"
              [class.is-invalid]="(lastName.dirty || lastName.touched) && lastName.invalid"
              placeholder="Efternamn"
            />
            <mat-error>
              <div *ngIf="!!lastName.errors?.required">Ange ett efternamn</div>

              <div *ngIf="!!lastName.errors?.maxlength">Får inte vara mer än {{ nameMaxLength }} tecken.</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div [class.disabled-field]="state.isLoading">
          <mat-form-field class="w-100">
            <mat-label>E-postadress</mat-label>
            <input
              matInput
              required
              [disabled]="state.isLoading"
              [(ngModel)]="editedUser.user.email"
              #email="ngModel"
              name="email"
              [maxlength]="emailMaxLength"
              [class.is-invalid]="(email.dirty || email.touched) && email.invalid"
              placeholder="E-postadress"
              email
            />
            <mat-error>
              <div *ngIf="!!email.errors?.required">Ange en e-postadress</div>
              <div *ngIf="!!email.errors?.maxlength">Får inte vara mer än {{ emailMaxLength }} tecken.</div>
              <div *ngIf="!!email.errors?.email">E-postadress är ogiltig</div>
            </mat-error>
          </mat-form-field>
        </div>
        <button
          class="btn btn-secondary-green align-self-start float-right"
          type="submit"
          [disabled]="userProfileForm.invalid || state.isLoading || userProfileForm.pristine || !hasUserChanged()"
          (click)="onSaveUser()"
        >
          <i class="fas fa-spinner fa-pulse mr-1" *ngIf="state.isLoading"></i>
          <ng-container>Spara</ng-container>
        </button>
      </form>
    </div>
  </div>
</div>
