import { StoreConfig } from '@ngrx/store/src/store_module';
import { META_REDUCERS, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { FactoryProvider } from '@angular/core';
import { Assert } from 'src/app/shared';
import { StoreStateService } from './store-state.service';
import { getMetaReducers } from './meta.redurers';

export function featureStoreConfigFactory<TState>(
  featureStoreKey: string,
  featureStoreInitialState: TState,
  storeStateService: StoreStateService
): StoreConfig<TState> {
  Assert.isNotNull(featureStoreKey, 'featureStoreKey');
  Assert.isNotNull(featureStoreInitialState, 'featureStoreInitialState');
  Assert.isNotNull(storeStateService, 'storeStateService');

  const state = storeStateService.restoreFeatureState<TState>(featureStoreKey, featureStoreInitialState);

  return { initialState: state };
}

export function getMetaReducersProvider(): FactoryProvider {
  return {
    provide: USER_PROVIDED_META_REDUCERS,
    deps: [StoreStateService],
    useFactory: getMetaReducers,

    // provide: META_REDUCERS,
    // deps: [StoreStateService],
    // useFactory: getMetaReducers,
    // multi: true,
  };
}
