import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StringUtils } from 'src/app/shared';
import { User } from '../shared/user';
import { usersConfig } from '../users.config';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.scss'],
})
export class DeleteUserModalComponent {
  confirmationText: string;

  constructor(public dialogRef: MatDialogRef<DeleteUserModalComponent>, @Inject(MAT_DIALOG_DATA) public user: User) {}

  get expectedConfirmationText(): string {
    return usersConfig.delete.confirmationText;
  }

  get isConfirmed(): boolean {
    const isConfirmed = StringUtils.equalsIgnoreCase(this.confirmationText, this.expectedConfirmationText);
    return isConfirmed;
  }

  onDelete() {
    if (!this.isConfirmed)
      return;

    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
