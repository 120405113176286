import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { projectConfig } from '../project.config';
import { v4 as uuid } from 'uuid';
import { Unsubscribe } from 'src/app/core/decorators';

@Unsubscribe()
@Component({
  selector: 'app-project-layout',
  templateUrl: './project-layout.component.html',
  styleUrls: ['./project-layout.component.scss'],
})
export class ProjectLayoutComponent implements OnInit, OnDestroy {
  documentId: uuid;

  private routeParamsSubscription: Subscription;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.routeParamsSubscription = this.route.params.subscribe((params) => {
      this.documentId = params[projectConfig.routes.params.documentId] || null;
    });
  }

  ngOnDestroy(): void {}
}
