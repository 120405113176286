import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { OpenLeftMenuAction } from './layout.actions';
import { selectIsLeftMenuOpened } from './layout.selectors';

@Injectable({
  providedIn: 'root',
})
export class LayoutStoreService {
  private readonly store: Store<AppState>;

  constructor(store: Store<AppState>) {
    this.store = store;
  }

  getIsLeftMenuOpened(): Observable<boolean> {
    const selectIsLeftMenuOpened$ = this.store.select(selectIsLeftMenuOpened);

    return selectIsLeftMenuOpened$;
  }

  openLeftMenu(isLeftMenuOpened: boolean) {
    this.store.dispatch(new OpenLeftMenuAction(isLeftMenuOpened));
  }
}
