import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { AuthenticationStoreService } from '../authentication-store';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationError } from '../shared';
import { appConfig } from 'src/app/app.config';
import * as _ from 'lodash';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  private readonly authenticationService: AuthenticationService;
  private readonly authenticationStoreService: AuthenticationStoreService;
  private readonly router: Router;
  private readonly route: ActivatedRoute;
  private readonly modal: NgbModal;
  private readonly dialog: MatDialog;

  state: {
    oauthError: AuthenticationError;
  };

  constructor(
    authenticationStoreService: AuthenticationStoreService,
    authenticationService: AuthenticationService,
    router: Router,
    route: ActivatedRoute,
    modal: NgbModal,
    dialog: MatDialog,
  ) {
    this.authenticationService = authenticationService;
    this.authenticationStoreService = authenticationStoreService;
    this.router = router;
    this.route = route;
    this.modal = modal;
    this.dialog = dialog;

    this.state = {
      oauthError: null,
    };
  }

  ngOnInit() {
    this.modal.dismissAll();
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {}

  onLoggedIn() {
    this.state.oauthError = null;
    this.authenticate();
  }

  onLogInFailed() {
    this.state.oauthError = null;
  }

  private async authenticate() {
    const returnUrl = await this.authenticationService.getReturnUrl();
    if (returnUrl) {
      const parsedUrl = this.router.parseUrl(returnUrl);

      const urlWithoutParams = _.split(returnUrl, '?');
      this.router.navigate([urlWithoutParams[0]], { queryParams: parsedUrl.queryParams });

      this.authenticationService.clearReturnUrl();
    } else {
      this.router.navigate([appConfig.routes.home]);
    }
  }
}
