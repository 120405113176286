import { Assert } from 'src/app/shared';
import { LayoutActions, LayoutActionType } from './layout.actions';

export interface LayoutState {
  isLeftMenuOpened: boolean;
}

export const layoutInitialState: LayoutState = {
  isLeftMenuOpened: true,
};

export function layoutReducer(state: LayoutState, action: LayoutActions): LayoutState {
  Assert.isNotNull(action, 'action');

  switch (action.type) {
    case LayoutActionType.OpenLeftMenu:
      return {
        ...state,
        isLeftMenuOpened: action.isLeftMenuOpened,
      };

    default:
      return state;
  }
}
