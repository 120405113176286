import { IDocumentItem } from './document';

export class ExtinguishingSystem {
  extinguishingSystemId: string;
  extinguishingSystemInvestment: string;

  constructor({ extinguishingSystemId, extinguishingSystemInvestment }: Partial<ExtinguishingSystem> = {}) {
    this.extinguishingSystemId = extinguishingSystemId;
    this.extinguishingSystemInvestment = extinguishingSystemInvestment || '';
  }
}

export class DocumentInstallations implements IDocumentItem {
  public static otherPremisesFireGasVentilationOptionId = 'other';

  public static illuminatedWithEmergencyPowerId = '110-illuminated-with-emergency-power';
  public static separateEmergencyLightingId = '140-separate-emergency-lighting';

  markingsEmergencyLightingIds?: string[];
  powerSupplyEmergencyLightingIds?: string[];
  markingsEmergencyLightingDescription?: string;

  isFireAlarmsOccur?: boolean;
  hasFireAlarmsFormalRequirements?: boolean;
  hasFireAlarmsSeriesConnection?: boolean;
  fireAlarmsDescription?: string;

  isAutomaticFireAlarmsOccur?: boolean;
  hasAutomaticFireAlarmsFormalRequirements?: boolean;
  hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning?: boolean;
  isAutomaticFireAlarmOwnAmbition?: boolean;
  coordinatedFunctionAutomaticFireAlarmRelevantIds: string[];
  automaticFireAlarmsMonitoringScopeIds: string[];
  automaticFireAlarmDetekteringIds: string[];
  automaticFireAlarmActivationIds: string[];
  automaticFireAlarmActivationOther?: string;
  automaticFireAlarmPowerSupplyIds: string[];
  automaticFireAlarmPowerSupplyOther?: string;
  automaticFireAlarmsReceiverIds: string[];
  automaticFireAlarmSpecialAdaptationIds: string[];
  automaticFireAlarmCurrentRegulations?: string;
  automaticFireAlarmConstructionCertificate?: string;
  automaticFireAlarmServiceOrientationDrawings?: string;
  locationOfFireProtectionPanelFireAlarmCenter?: string;
  locationOfInformationTableForFireAlarms?: string;
  locationOfAlarmStorageTablesForFireAlarms?: string;
  automaticFireAlarmsDescription?: string;

  isAlarmSignalingOccur?: boolean;
  hasEvacuationAlarmsFormalRequirements?: boolean;
  hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning?: boolean;
  isAlarmSignalingOwnAmbition?: boolean;
  hasEmergencyMicrophoneForEmergencyService?: boolean;
  alarmSignalingOccurIds: string[];
  alarmSignalPerceivedIds: string[];
  evacuationAlarmsActivationIds: string[];
  evacuationAlarmsActivationOther?: string;
  evacuationAlarmsSignalIds: string[];
  evacuationAlarmsSpecialAdaptationIds: string[];
  alarmSignalingCurrentRegulations?: string;
  alarmSignalingConstructionCertificate?: string;
  locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel?: string;
  evacuationAlarmsDescription?: string;

  isAutomaticExtinguishingSystemOccur?: boolean;
  hasAutomaticWaterSprinklerFormalRequirements?: boolean;
  isAutomaticExtinguishingSystemOwnAmbition?: boolean;
  automaticExtinguishingSystemOccurIds: string[];
  monitoringAutomaticExtinguishingSystemIds: string[];
  automaticWaterSprinklerAdjustmentIds: string[];
  automaticWaterSprinklerRiskClassIds: string[];
  automaticWaterSprinklerOther?: string;

  extinguishingSystems: ExtinguishingSystem[];

  extinguishingEquipmentFormIds: string[];
  extinguishingEquipmentFormOther?: string;

  isFireGasVentilationOccur?: boolean;
  hasFireGasVentilationFormalRequirements?: boolean;
  isFireGasVentilationOwnAmbition?: boolean;
  premisesFireGasVentilationIds: string[];
  premisesFireGasVentilationOther?: string;
  fireGasVentilationDescription?: string;

  isOtherFireTechnicalInstallationsOccur?: boolean;
  hasOtherFireTechnicalInstallationsFormalRequirements?: boolean;
  isOtherFireTechnicalInstallationsOwnAmbition?: boolean;
  otherFireTechnicalInstallationsDescription?: string;

  isCompleted: boolean;
  isValid: boolean;

  constructor({
    markingsEmergencyLightingIds,
    powerSupplyEmergencyLightingIds,
    markingsEmergencyLightingDescription,

    isFireAlarmsOccur,
    hasFireAlarmsFormalRequirements,
    hasFireAlarmsSeriesConnection,
    fireAlarmsDescription,

    isAutomaticFireAlarmsOccur,
    hasAutomaticFireAlarmsFormalRequirements,
    hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning,
    isAutomaticFireAlarmOwnAmbition,

    coordinatedFunctionAutomaticFireAlarmRelevantIds,
    automaticFireAlarmCurrentRegulations,
    automaticFireAlarmsMonitoringScopeIds,
    automaticFireAlarmDetekteringIds,
    automaticFireAlarmPowerSupplyIds,
    automaticFireAlarmPowerSupplyOther,
    automaticFireAlarmActivationIds,
    automaticFireAlarmActivationOther,
    automaticFireAlarmsReceiverIds,
    automaticFireAlarmSpecialAdaptationIds,
    locationOfFireProtectionPanelFireAlarmCenter,
    locationOfInformationTableForFireAlarms,
    locationOfAlarmStorageTablesForFireAlarms,
    automaticFireAlarmConstructionCertificate,
    automaticFireAlarmServiceOrientationDrawings,
    automaticFireAlarmsDescription,

    isAlarmSignalingOccur,
    hasEvacuationAlarmsFormalRequirements,
    hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning,
    isAlarmSignalingOwnAmbition,
    hasEmergencyMicrophoneForEmergencyService,
    alarmSignalingOccurIds,
    alarmSignalPerceivedIds,
    evacuationAlarmsActivationIds,
    evacuationAlarmsActivationOther,
    evacuationAlarmsSignalIds,
    evacuationAlarmsSpecialAdaptationIds,
    alarmSignalingCurrentRegulations,
    alarmSignalingConstructionCertificate,
    locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel,
    evacuationAlarmsDescription,

    isAutomaticExtinguishingSystemOccur,
    hasAutomaticWaterSprinklerFormalRequirements,
    isAutomaticExtinguishingSystemOwnAmbition,
    automaticExtinguishingSystemOccurIds,
    monitoringAutomaticExtinguishingSystemIds,
    automaticWaterSprinklerAdjustmentIds,
    automaticWaterSprinklerRiskClassIds,
    automaticWaterSprinklerOther,

    extinguishingSystems,

    extinguishingEquipmentFormIds,
    extinguishingEquipmentFormOther,

    isFireGasVentilationOccur,
    hasFireGasVentilationFormalRequirements,
    isFireGasVentilationOwnAmbition,
    premisesFireGasVentilationIds,
    premisesFireGasVentilationOther,
    fireGasVentilationDescription,

    isOtherFireTechnicalInstallationsOccur,
    hasOtherFireTechnicalInstallationsFormalRequirements,
    isOtherFireTechnicalInstallationsOwnAmbition,
    otherFireTechnicalInstallationsDescription,

    isCompleted,
    isValid,
  }: Partial<DocumentInstallations> = {}) {
    this.markingsEmergencyLightingIds = markingsEmergencyLightingIds || [];
    this.powerSupplyEmergencyLightingIds = powerSupplyEmergencyLightingIds || [];
    this.markingsEmergencyLightingDescription = markingsEmergencyLightingDescription;
    this.isFireAlarmsOccur = isFireAlarmsOccur;
    this.hasFireAlarmsFormalRequirements = hasFireAlarmsFormalRequirements;
    this.hasFireAlarmsSeriesConnection = hasFireAlarmsSeriesConnection;
    this.fireAlarmsDescription = fireAlarmsDescription;
    this.isAutomaticFireAlarmsOccur = isAutomaticFireAlarmsOccur;
    this.hasAutomaticFireAlarmsFormalRequirements = hasAutomaticFireAlarmsFormalRequirements;
    this.hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning = hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning;
    this.isAutomaticFireAlarmOwnAmbition = isAutomaticFireAlarmOwnAmbition;
    this.coordinatedFunctionAutomaticFireAlarmRelevantIds = coordinatedFunctionAutomaticFireAlarmRelevantIds || [];
    this.automaticFireAlarmCurrentRegulations = automaticFireAlarmCurrentRegulations;
    this.automaticFireAlarmsMonitoringScopeIds = automaticFireAlarmsMonitoringScopeIds || [];
    this.automaticFireAlarmDetekteringIds = automaticFireAlarmDetekteringIds || [];
    this.automaticFireAlarmPowerSupplyIds = automaticFireAlarmPowerSupplyIds || [];
    this.automaticFireAlarmPowerSupplyOther = automaticFireAlarmPowerSupplyOther;
    this.automaticFireAlarmActivationIds = automaticFireAlarmActivationIds || [];
    this.automaticFireAlarmActivationOther = automaticFireAlarmActivationOther;
    this.automaticFireAlarmsReceiverIds = automaticFireAlarmsReceiverIds || [];
    this.automaticFireAlarmSpecialAdaptationIds = automaticFireAlarmSpecialAdaptationIds || [];
    this.locationOfFireProtectionPanelFireAlarmCenter = locationOfFireProtectionPanelFireAlarmCenter;
    this.locationOfInformationTableForFireAlarms = locationOfInformationTableForFireAlarms;
    this.locationOfAlarmStorageTablesForFireAlarms = locationOfAlarmStorageTablesForFireAlarms;
    this.automaticFireAlarmServiceOrientationDrawings = automaticFireAlarmServiceOrientationDrawings;
    this.automaticFireAlarmConstructionCertificate = automaticFireAlarmConstructionCertificate;
    this.automaticFireAlarmsDescription = automaticFireAlarmsDescription;
    this.isAlarmSignalingOccur = isAlarmSignalingOccur;
    this.hasEvacuationAlarmsFormalRequirements = hasEvacuationAlarmsFormalRequirements;
    this.hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning = hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning;
    this.isAlarmSignalingOwnAmbition = isAlarmSignalingOwnAmbition;
    this.hasEmergencyMicrophoneForEmergencyService = hasEmergencyMicrophoneForEmergencyService;
    this.alarmSignalingOccurIds = alarmSignalingOccurIds || [];
    this.alarmSignalPerceivedIds = alarmSignalPerceivedIds || [];
    this.evacuationAlarmsActivationIds = evacuationAlarmsActivationIds || [];
    this.evacuationAlarmsActivationOther = evacuationAlarmsActivationOther;
    this.evacuationAlarmsSignalIds = evacuationAlarmsSignalIds || [];
    this.evacuationAlarmsSpecialAdaptationIds = evacuationAlarmsSpecialAdaptationIds || [];
    this.alarmSignalingCurrentRegulations = alarmSignalingCurrentRegulations;
    this.alarmSignalingConstructionCertificate = alarmSignalingConstructionCertificate;
    this.locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel = locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel;
    this.evacuationAlarmsDescription = evacuationAlarmsDescription;
    this.isAutomaticExtinguishingSystemOccur = isAutomaticExtinguishingSystemOccur;
    this.hasAutomaticWaterSprinklerFormalRequirements = hasAutomaticWaterSprinklerFormalRequirements;
    this.isAutomaticExtinguishingSystemOwnAmbition = isAutomaticExtinguishingSystemOwnAmbition;
    this.automaticExtinguishingSystemOccurIds = automaticExtinguishingSystemOccurIds || [];
    this.monitoringAutomaticExtinguishingSystemIds = monitoringAutomaticExtinguishingSystemIds || [];
    this.automaticWaterSprinklerAdjustmentIds = automaticWaterSprinklerAdjustmentIds || [];
    this.automaticWaterSprinklerRiskClassIds = automaticWaterSprinklerRiskClassIds || [];
    this.automaticWaterSprinklerOther = automaticWaterSprinklerOther;
    this.extinguishingSystems = extinguishingSystems || [];
    this.extinguishingEquipmentFormIds = extinguishingEquipmentFormIds || [];
    this.extinguishingEquipmentFormOther = extinguishingEquipmentFormOther;
    this.isFireGasVentilationOccur = isFireGasVentilationOccur;
    this.hasFireGasVentilationFormalRequirements = hasFireGasVentilationFormalRequirements;
    this.isFireGasVentilationOwnAmbition = isFireGasVentilationOwnAmbition;
    this.premisesFireGasVentilationIds = premisesFireGasVentilationIds || [];
    this.premisesFireGasVentilationOther = premisesFireGasVentilationOther;
    this.fireGasVentilationDescription = fireGasVentilationDescription;
    this.isOtherFireTechnicalInstallationsOccur = isOtherFireTechnicalInstallationsOccur;
    this.hasOtherFireTechnicalInstallationsFormalRequirements = hasOtherFireTechnicalInstallationsFormalRequirements;
    this.isOtherFireTechnicalInstallationsOwnAmbition = isOtherFireTechnicalInstallationsOwnAmbition;
    this.otherFireTechnicalInstallationsDescription = otherFireTechnicalInstallationsDescription;

    this.isCompleted = isCompleted || false;
    this.isValid = isValid;
  }
}
