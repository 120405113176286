export class AuthUser {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  access_token?: string;
  language: string;

  constructor({ id, username, password, firstName, lastName, role, access_token, language }: Partial<AuthUser> = {}) {
    this.id = id || null;
    this.username = username || null;
    this.password = password || null;
    this.firstName = firstName || null;
    this.lastName = lastName || null;
    this.role = role || null;
    this.access_token = access_token || null;
    this.language = language || null;
  }
}
