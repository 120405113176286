import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthenticationStoreService } from './authentication-store';
import { authenticationConfig } from './authentication.config';
import { AuthenticationService } from './authentication.service';
import { AuthDetails } from './shared';
import * as moment from 'moment';
import { appConfig } from '../app.config';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  private readonly authenticationStoreService: AuthenticationStoreService;
  private readonly authenticationService: AuthenticationService;
  private readonly router: Router;

  constructor(
    authenticationStoreService: AuthenticationStoreService,
    authenticationService: AuthenticationService,
    router: Router
  ) {
    this.authenticationStoreService = authenticationStoreService;
    this.authenticationService = authenticationService;
    this.router = router;
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let dataRole: string;
    const authDetails = await this.authenticationStoreService.getAuthDetails().pipe(take(1)).toPromise();
    try {
      dataRole = next.data.roles.find((role: string) => role.toLowerCase() === authDetails.role.toLowerCase());
    } catch {
      dataRole = '';
    }

    const isInvalidRole = next.data.roles && _.isEmpty(dataRole);
    if (isInvalidRole) {
      this.router.navigate(['']);
      return false;
    }
    return !!authDetails;
  }

  isTokenExpired(authDetails: AuthDetails): boolean {
    const sessionExpirationDate: Date = authDetails.expireDate;
    const secondsSessionExpiredAfter: number = moment(sessionExpirationDate).diff(moment(), 'seconds');

    return secondsSessionExpiredAfter <= appConfig.renewUntilSessionExpiredSeconds;
  }
}
