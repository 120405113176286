import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StringUtils } from 'src/app/shared';
import { organizationConfig } from '../organization.config';
import { OrganizationItem } from '../shared';

@Component({
  selector: 'app-activate-organization-modal',
  templateUrl: './activate-organization-modal.component.html',
  styleUrls: ['./activate-organization-modal.component.scss'],
})
export class ActivateOrganizationModalComponent {
  confirmationText: string;

  constructor(
    public dialogRef: MatDialogRef<ActivateOrganizationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public organization: OrganizationItem
  ) {
    this.confirmationText = '';
  }

  get expectedConfirmationText(): string {
    return organizationConfig.activate.confirmationText;
  }

  get isConfirmed(): boolean {
    const isConfirmed = StringUtils.equalsIgnoreCase(this.confirmationText, this.expectedConfirmationText);
    return isConfirmed;
  }

  onActivate() {
    if (!this.isConfirmed)
      return;

    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
