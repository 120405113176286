import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { required, validate } from '../shared';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';

@Directive({
  selector: '[appChangePasswordTrigger]',
})
export class ChangePasswordTriggerDirective {
  private readonly dialog: MatDialog;

  @Input()
  userId: string;

  @Output()
  readonly changed: EventEmitter<void>;

  constructor(dialog: MatDialog) {
    this.dialog = dialog;

    this.changed = new EventEmitter();
  }

  @HostListener('click')
  onClick() {
    if (this.userId) {
      this.changePassword(this.userId);
    }
  }

  @validate
  changePassword(@required userId: string) {
    const dialogRef = this.dialog.open(ChangePasswordModalComponent, {
      data: userId,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.onPasswordChanged();
      }
    });
  }

  @validate
  private onPasswordChanged() {
    this.changed.next();
  }
}
