<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'Utrymning'"
    [documentId]="document?.id"
    [isCompleted]="document?.evacuation.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Fönsterutrymning">
              <mat-label title="Window removal">Fönsterutrymning</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isWindowRemoval"
                #isWindowRemoval="ngModel"
                name="isWindowRemoval"
              />
              <mat-radio-group
                class="d-block"
                name="isWindowRemovalRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isWindowRemoval"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isWindowRemoval.name]"-->
<!--                [class.text-secondary-primary]="notes[isWindowRemoval.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isWindowRemoval.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isWindowRemoval.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="En enda utrymningsväg förekommer">
              <mat-label title="There is only one escape route">En enda utrymningsväg förekommer</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isOnlyOneEscapeRoute"
                #isOnlyOneEscapeRoute="ngModel"
                name="isOnlyOneEscapeRoute"
              />
              <mat-radio-group
                class="d-block"
                name="isOnlyOneEscapeRouteRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isOnlyOneEscapeRoute"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isOnlyOneEscapeRoute.name]"-->
<!--                [class.text-secondary-primary]="notes[isOnlyOneEscapeRoute.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isOnlyOneEscapeRoute.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isOnlyOneEscapeRoute.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Utrymning med hjälp av räddningtjänstens stegutrustning"
            >
              <mat-label title="Evacuation with the help of the rescue service's step equipment"
                >Utrymning med hjälp av räddningtjänstens stegutrustning</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationRescueService"
                #isEvacuationRescueService="ngModel"
                name="isEvacuationRescueService"
              />
              <mat-radio-group
                class="d-block"
                name="isEvacuationRescueServiceRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationRescueService"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isEvacuationRescueService.name]"-->
<!--                [class.text-secondary-primary]="notes[isEvacuationRescueService.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isEvacuationRescueService.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isEvacuationRescueService.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" [class.another-evacuation-field]="document.fire.isBuildingDividedIntoFireCells === false" class="w-100" title="Utrymning över annan brandcell">
              <mat-label title="Evacuation over another fire cell">Utrymning över annan brandcell</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationFromAnotherFireExit"
                #isEvacuationFromAnotherFireExit="ngModel"
                name="isEvacuationFromAnotherFireExit"
              />
              <mat-radio-group
                class="d-block"
                name="isEvacuationFromAnotherFireExitRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationFromAnotherFireExit"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3" [disabled]="document.fire.isBuildingDividedIntoFireCells === false">
                  Ja
                </mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isEvacuationFromAnotherFireExit.name]"-->
<!--                [class.text-secondary-primary]="notes[isEvacuationFromAnotherFireExit.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isEvacuationFromAnotherFireExit.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isEvacuationFromAnotherFireExit.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
              <div *ngIf="document.fire.isBuildingDividedIntoFireCells === false" class="text-xs text-grey">
                Brandceller är inte definierade under fliken Skydd mot brand - Byggnaden är indelad i brandceller
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Utrymning över annan byggnadsdel">
              <mat-label title="Utrymning över annan byggnadsdel">Utrymning över annan byggnadsdel</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationFromAnotherPartOfBuilding"
                #isEvacuationFromAnotherPartOfBuilding="ngModel"
                name="isEvacuationFromAnotherPartOfBuilding"
              />
              <mat-radio-group
                class="d-block"
                name="isEvacuationFromAnotherPartOfBuildingRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationFromAnotherPartOfBuilding"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Horisontell utrymning tillämpas">
              <mat-label>Horisontell utrymning tillämpas</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !isHorizontalEvacuationEnabled"
                [(ngModel)]="document.evacuation.isHorizontalEvacuation"
                #isHorizontalEvacuation="ngModel"
                name="isHorizontalEvacuation"
              />
              <mat-radio-group
                class="d-block"
                name="isHorizontalEvacuationRadio"
                [disabled]="!hasEditPermissions || !isHorizontalEvacuationEnabled"
                [(ngModel)]="document.evacuation.isHorizontalEvacuation"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <div *ngIf="!isHorizontalEvacuationEnabled" class="text-xs text-grey">
                Definiera verksamhetsklass Vk 5C under fliken Byggnad- och verksamhetsbeskrivning - Följande verksamheter förekommer i byggnaden
              </div>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isHorizontalEvacuation.name]"-->
<!--                [class.text-secondary-primary]="notes[isHorizontalEvacuation.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isHorizontalEvacuation.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isHorizontalEvacuation.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Utrymning via Tr1 alt. Tr2-trapphus">
              <mat-label>Utrymning via Tr1 alt. Tr2-trapphus</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="evacuationViaTr1"
                #isEvacuationViaTr1="ngModel"
                name="isEvacuationViaTr1"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="isEvacuationViaTr1Radio"
                  #isEvacuationViaTr1Radio="ngModel"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.evacuation.isEvacuationViaTr1"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>

                <div class="d-flex ml-3">
                  <span class="text-medium mt-1" [class.text-disabled]="!document.evacuation.isEvacuationViaTr1"
                  >Utgör den enda utrymningsvägen:</span
                  >
                  <mat-radio-group
                    class="d-block ml-3"
                    name="isOnlyEscapeRouteViaTr1Radio"
                    #isOnlyEscapeRouteViaTr1Radio="ngModel"
                    [required]="document.evacuation.isEvacuationViaTr1"
                    [disabled]="!hasEditPermissions || !document.evacuation.isEvacuationViaTr1"
                    [(ngModel)]="document.evacuation.isOnlyEscapeRouteViaTr1"
                    (ngModelChange)="onFormDataChanged()"
                  >
                    <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                    <mat-radio-button [value]="false">Nej</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Tillfällig utrymningsplats enligt AFS 2020:1">
              <mat-label title="Tillfällig utrymningsplats enligt AFS 2020:1">Tillfällig utrymningsplats enligt AFS 2020:1</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isTemporaryEvacuationAFS20201"
                #isTemporaryEvacuationAFS20201="ngModel"
                name="isTemporaryEvacuationAFS20201"
              />
              <mat-radio-group
                class="d-block"
                name="isTemporaryEvacuationAFS20201Radio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isTemporaryEvacuationAFS20201"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Krav för återinträde">
              <mat-label title="Requirements for re-entry">Krav för återinträde</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isReEntryRequirements"
                #isReEntryRequirements="ngModel"
                name="isReEntryRequirements"
              />
              <mat-radio-group
                class="d-block"
                name="isReEntryRequirementsRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isReEntryRequirements"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[isReEntryRequirements.name]"-->
              <!--                [class.text-secondary-primary]="notes[isReEntryRequirements.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[isReEntryRequirements.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="isReEntryRequirements.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Utrymning via automatiska horisontella skjutdörrar">
              <mat-label title="Utrymning via automatiska horisontella skjutdörrar">Utrymning via automatiska horisontella skjutdörrar</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationViaAutomaticHorizontalSlidingDoors"
                #isEvacuationViaAutomaticHorizontalSlidingDoors="ngModel"
                name="isEvacuationViaAutomaticHorizontalSlidingDoors"
              />
              <mat-radio-group
                class="d-block"
                name="isEvacuationViaAutomaticHorizontalSlidingDoorsRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationViaAutomaticHorizontalSlidingDoors"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Utrymning via utrymningshiss">
              <mat-label title="Evacuation via evacuation elevator">Utrymning via utrymningshiss</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationViaEvacuationElevator"
                #isEvacuationViaEvacuationElevator="ngModel"
                name="isEvacuationViaEvacuationElevator"
              />
              <mat-radio-group
                class="d-block"
                name="isEvacuationViaEvacuationElevatorRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationViaEvacuationElevator"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[isEvacuationViaEvacuationElevator.name]"-->
              <!--                [class.text-secondary-primary]="notes[isEvacuationViaEvacuationElevator.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[isEvacuationViaEvacuationElevator.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="isEvacuationViaEvacuationElevator.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Utrymning via räddningshiss">
              <mat-label title="Utrymning via räddningshiss">Utrymning via räddningshiss</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationViaRescueElevator"
                #isEvacuationViaRescueElevator="ngModel"
                name="isEvacuationViaRescueElevator"
              />
              <mat-radio-group
                class="d-block"
                name="isEvacuationViaRescueElevatorRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationViaRescueElevator"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Låsta dörrar"
              [values]="lockedDoorsTypes"
              [selectedKeys]="document.evacuation.lockedDoorsIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="lockedDoorsIds"
            ></app-multiselect-readonly-view>

            <mat-form-field floatLabel="always" class="w-100" title="Låsta dörrar" *ngIf="hasEditPermissions">
              <mat-label title="Locked doors">Låsta dörrar</mat-label>

              <mat-select
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.lockedDoorsIds"
                #lockedDoorsIds="ngModel"
                name="lockedDoorsIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="lockedDoorsTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let lockedDoorsType of lockedDoorsTypes | keyvalue"
                  [value]="lockedDoorsType.key"
                  [title]="lockedDoorsType.value.displayName"
                  [disabled]="lockedDoorsType.value.isDisabled"
                  >{{ lockedDoorsType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[lockedDoorsIds.name]"-->
<!--                [class.text-secondary-primary]="notes[lockedDoorsIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[lockedDoorsIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="lockedDoorsIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Utrymningsplatser för personer med nedsatt rörelse- och orienteringsförmåga"
            >
              <mat-label title="Evacuation places for people with disabilities"
                >Utrymningsplatser för personer med nedsatt rörelse- och orienteringsförmåga</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationForDisabilitiesPeople"
                #isEvacuationForDisabilitiesPeople="ngModel"
                name="isEvacuationForDisabilitiesPeople"
              />
              <mat-radio-group
                class="d-block"
                name="isEvacuationForDisabilitiesPeopleRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationForDisabilitiesPeople"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isEvacuationForDisabilitiesPeople.name]"-->
<!--                [class.text-secondary-primary]="notes[isEvacuationForDisabilitiesPeople.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isEvacuationForDisabilitiesPeople.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isEvacuationForDisabilitiesPeople.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Tillgänglig och användbar utrymningsväg i horisontellt led">
              <mat-label title="Tillgänglig och användbar utrymningsväg i horisontellt led">Tillgänglig och användbar utrymningsväg i horisontellt led</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isAccessibleAndUsableHorizontalEvacuation"
                #isAccessibleAndUsableHorizontalEvacuation="ngModel"
                name="isAccessibleAndUsableHorizontalEvacuation"
              />
              <mat-radio-group
                class="d-block"
                name="isAccessibleAndUsableHorizontalEvacuationRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isAccessibleAndUsableHorizontalEvacuation"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Utrymningsplaner förekommer inom byggnaden">
              <mat-label title="Utrymningsplaner förekommer inom byggnaden">Utrymningsplaner förekommer inom byggnaden</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="evacuationIsEvacuationPlansInBuilding"
                #isEvacuationPlansInBuilding="ngModel"
                name="isEvacuationPlansInBuilding"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="isEvacuationPlansInBuildingRadio"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.evacuation.isEvacuationPlansInBuilding"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>

                <div class="d-flex ml-3">
                  <span class="text-medium mt-1" [class.text-disabled]="!document.evacuation.isEvacuationPlansInBuilding"
                  >Kravbild enligt Vk 4:</span
                  >
                  <mat-radio-group
                    class="d-block ml-3"
                    name="isImageRequirementsAccordingVk4"
                    #isImageRequirementsAccordingVk4="ngModel"
                    [required]="document.evacuation.isEvacuationPlansInBuilding"
                    [disabled]="!document.evacuation.isEvacuationPlansInBuilding || !hasEditPermissions"
                    [(ngModel)]="document.evacuation.isImageRequirementsAccordingVk4"
                    (ngModelChange)="onFormDataChanged()"
                  >
                    <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                    <mat-radio-button [value]="false">Nej</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Kommunikationsutrustning utrymningsplats">
              <mat-label title="Communication equipment evacuation site"
                >Kommunikationsutrustning utrymningsplats</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.evacuation.isEvacuationForDisabilitiesPeople || !hasEditPermissions"
                [(ngModel)]="document.evacuation.isCommunicationEquipmentEvacuation"
                #isCommunicationEquipmentEvacuation="ngModel"
                name="isCommunicationEquipmentEvacuation"
              />
              <mat-radio-group
                class="d-block"
                name="isCommunicationEquipmentEvacuationRadio"
                [disabled]="!document.evacuation.isEvacuationForDisabilitiesPeople || !hasEditPermissions"
                [(ngModel)]="document.evacuation.isCommunicationEquipmentEvacuation"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isCommunicationEquipmentEvacuation.name]"-->
<!--                [class.text-secondary-primary]="notes[isCommunicationEquipmentEvacuation.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isCommunicationEquipmentEvacuation.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isCommunicationEquipmentEvacuation.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Utrymning via gemensam del (korridor) av i övrigt avskilda utrymningsvägar">
              <mat-label title="Utrymning via gemensam del (korridor) av i övrigt avskilda utrymningsvägar">
                Utrymning via gemensam del (korridor) av i övrigt avskilda utrymningsvägar
              </mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationViaCommonPartOfSeparatedRoute"
                #isEvacuationViaCommonPartOfSeparatedRoute="ngModel"
                name="isEvacuationViaCommonPartOfSeparatedRoute"
              />
              <mat-radio-group
                class="d-block"
                name="isEvacuationViaCommonPartOfSeparatedRouteRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isEvacuationViaCommonPartOfSeparatedRoute"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Madrassutrymning av sängliggande patienter">
              <mat-label title="Madrassutrymning av sängliggande patienter">Madrassutrymning av sängliggande patienter</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !isMattressEvacuationOfBedriddenPatientsEnabled"
                [(ngModel)]="document.evacuation.isMattressEvacuationOfBedriddenPatients"
                #isMattressEvacuationOfBedriddenPatients="ngModel"
                name="isMattressEvacuationOfBedriddenPatients"
              />
              <mat-radio-group
                class="d-block"
                name="isMattressEvacuationOfBedriddenPatientsRadio"
                [disabled]="!hasEditPermissions || !isMattressEvacuationOfBedriddenPatientsEnabled"
                [(ngModel)]="document.evacuation.isMattressEvacuationOfBedriddenPatients"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <div *ngIf="!isMattressEvacuationOfBedriddenPatientsEnabled" class="text-xs text-grey">
                Definiera verksamhetsklass Vk 5C under fliken Byggnad- och verksamhetsbeskrivning - Följande verksamheter förekommer i byggnaden
              </div>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Klassad utrymningsväg">
              <mat-label title="Klassad utrymningsväg">Klassad utrymningsväg</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isClassifiedEscapeRoute"
                #isClassifiedEscapeRoute="ngModel"
                name="isClassifiedEscapeRoute"
              />
              <mat-radio-group
                class="d-block"
                name="isClassifiedEscapeRouteRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.isClassifiedEscapeRoute"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende utrymning">
              <mat-label title="Extended description regarding evacuation"
                >Utökad beskrivning avseende utrymning</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.evacuation.extendedEvacuationDescription"
                #extendedEvacuationDescription="ngModel"
                name="extendedEvacuationDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[extendedEvacuationDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[extendedEvacuationDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[extendedEvacuationDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="extendedEvacuationDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
