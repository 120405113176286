<h2 mat-dialog-title class="text-uppercase">Noteringar för projektinformation</h2>
<mat-dialog-content class="mat-typography">
  <form #projectInfoNoteForm="ngForm" novalidate [class.readonly-container]="data.isViewOnly">
    <mat-form-field class="w-100 d-block">
      <mat-label *ngIf="!data.isFieldIdDisabled">Välj fält</mat-label>
      <mat-label *ngIf="data.isFieldIdDisabled">Vält fält</mat-label>
      <mat-select
        [(ngModel)]="data.selectedFieldId"
        [disabled]="data.isViewOnly || data.isFieldIdDisabled"
        #selectField="ngModel"
        name="selectField"
        (selectionChange)="onSelectedFieldChanged(note.control)"
      >
        <ng-container *ngFor="let field of data.fields">
          <mat-option [value]="field.fieldId" [title]="field.displayName">{{ field.displayName }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-100 d-block" floatLabel="always">
      <mat-label>Notering</mat-label>
      <textarea
        matInput
        [(ngModel)]="data.note"
        #note="ngModel"
        name="note"
        rows="20"
        [disabled]="data.isViewOnly"
      ></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onClose(note.control)">Avbryt</button>
  <button mat-raised-button color="warn" [disabled]="data.isViewOnly" (click)="onSave()">Spara</button>
</mat-dialog-actions>
