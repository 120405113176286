import { DocumentSectionType } from 'src/app/documents/shared';

export class DocumentSectionNoteResult {
  readonly fieldId: string;
  readonly note: string;

  constructor({ fieldId, note }: Partial<DocumentSectionNoteResult> = {}) {
    this.fieldId = fieldId;
    this.note = note;
  }
}
