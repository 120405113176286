<mat-form-field floatLabel="always" class="w-100" [title]="fieldName">
  <mat-label>{{ fieldName }}</mat-label>
  <input
    matInput
    class="d-none"
    [required]="!isMasterConditionTrue"
    [disabled]="!hasEditPermissions || isMasterConditionTrue"
    [(ngModel)]="value"
    [name]="'valueInput' + noteName"
  />

  <!-- If master condition is set to FALSE then writable-->
  <mat-radio-group
    *ngIf="!isMasterConditionTrue"
    class="d-block"
    [name]="'valueInputGroup' + noteName"
    [disabled]="!hasEditPermissions"
    [(ngModel)]="value"
  >
    <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
    <mat-radio-button [value]="false">Nej</mat-radio-button>
  </mat-radio-group>

  <!-- If master condition is set to TRUE then readonly-->
  <mat-radio-group
    *ngIf="isMasterConditionTrue"
    class="d-block"
    [name]="'valueInputGroup' + noteName"
    [disabled]="true"
    [(ngModel)]="isMasterConditionTrue"
  >
    <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
    <mat-radio-button [value]="false">Nej</mat-radio-button>
  </mat-radio-group>
<!--  <button-->
<!--    mat-icon-button-->
<!--    matSuffix-->
<!--    *ngIf="hasEditPermissions || notes[noteName]"-->
<!--    [class.text-secondary-primary]="notes[noteName]"-->
<!--    appSectionFieldNote-->
<!--    [ngbTooltip]="notes[noteName]?.note"-->
<!--    [documentId]="document?.id"-->
<!--    [sectionType]="sectionType"-->
<!--    [sectionFieldId]="noteName"-->
<!--    [notes]="notes"-->
<!--    [isViewOnly]="!hasEditPermissions"-->
<!--    tooltipClass="medium-large-size-tooltip"-->
<!--    container="body"-->
<!--  >-->
<!--    <mat-icon>attach_file</mat-icon>-->
<!--  </button>-->
</mat-form-field>
