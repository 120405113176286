import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { OrganizationApiService } from '../organization-api.service';
import { LoggerService } from 'src/app/core';
import { required, validate } from 'src/app/shared';
import { Organization, OrganizationStatus } from '../shared';
import { Subject, Subscription, timer } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';
import { takeUntil } from 'rxjs/operators';
import { AuthDetails } from 'src/app/authentication/shared';
import { AuthenticationStoreService } from 'src/app/authentication/authentication-store';
import { UserRole } from 'src/app/authentication/shared/user-role';
import _ from 'lodash';
import { UsersType } from 'src/app/authentication/shared/users-type';

@Unsubscribe()
@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit, OnChanges, OnDestroy {

  currentUserAuthDetails: AuthDetails;
  private unsubscribeAll: Subject<any>;
  private refreshTimerSubscription: Subscription;

  get UserRole() {
    return UserRole;
  }

  get UsersType() {
    return UsersType;
  }

  get isInactive() {
    return this.organization?.status === OrganizationStatus.Deactivated;
  }

  @Input()
  organizationId: string;

  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  organization: Organization;

  constructor(
    private readonly organizationApiService: OrganizationApiService,
    private readonly loggerService: LoggerService,
    private readonly authenticationStoreService: AuthenticationStoreService
  ) {
    this.organizationApiService = organizationApiService;
    this.loggerService = loggerService;

    this.state = {
      isLoading: false,
      isFailed: false,
    };
    this.unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.authenticationStoreService
      .getAuthDetails()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(async (details: AuthDetails) => {
        this.currentUserAuthDetails = details;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<OrganizationComponent, SimpleChange>;
    const organizationIdChange = componentChanges.organizationId;

    if (organizationIdChange) {
      const organizationId = organizationIdChange.currentValue;
      this.onOrganizationIdChanged(organizationId);
    }
  }

  ngOnDestroy(): void {}

  onTryLoadOrganization() {
    this.loadOrganization(this.organizationId);
  }

  @validate
  onOrganizationUpdated(@required organization: Organization) {
    this.organization = organization;
    this.loadOrganization(organization.id);
  }

  onUserCreated() {
    if (!this.organization) {
      return;
    }

    this.organization = new Organization({...this.organization});
  }

  onUserDeleted() {
    if (!this.organization) {
      return;
    }

    this.organization = new Organization({...this.organization});
  }

  private onOrganizationIdChanged(organizationId: string) {
    if (_.isNull(organizationId) || organizationId === 'null') {
      this.organization = new Organization();
      return;
    }
    this.loadOrganization(organizationId);
  }

  @validate
  private async loadOrganization(@required organizationId: string, isSilent: boolean = false) {
    this.state.isFailed = false;

    if (!isSilent) {
      this.state.isLoading = true;
    }

    try {
      this.organization = await this.organizationApiService.getOrganizationById(organizationId);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to load organization', error);
    } finally {
      if (!isSilent) {
        this.state.isLoading = false;
      }
    }
  }

  @validate
  private startRefreshTimer(@required organizationId: string, @required refreshInterval: number) {
    this.cancelRefreshTimerSubscription();
    this.refreshTimerSubscription = timer(refreshInterval, refreshInterval).subscribe(() =>
      this.loadOrganization(organizationId, true)
    );
  }

  private cancelRefreshTimerSubscription() {
    if (this.refreshTimerSubscription) {
      this.refreshTimerSubscription.unsubscribe();
      this.refreshTimerSubscription = null;
    }
  }
}
