import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldSortOrder } from 'src/app/core/collections';
import { required, validate } from 'src/app/shared';
import { OrganizationItem } from '../shared';

@Component({
  selector: 'app-organizations-table',
  templateUrl: './organizations-table.component.html',
  styleUrls: ['./organizations-table.component.scss'],
})
export class OrganizationsTableComponent {
  readonly fieldNames: {
    name: keyof OrganizationItem;
    docsCount: keyof OrganizationItem;
    usersCount: keyof OrganizationItem;
    updatedDate: keyof OrganizationItem;
  };

  @Input()
  items: OrganizationItem[];

  @Input()
  sortOrder: FieldSortOrder;

  @Input()
  isDisabled: boolean;

  @Input()
  hasSuperAdminPermissions: boolean;

  @Output()
  readonly sortOrderChanged: EventEmitter<FieldSortOrder>;

  @Output()
  readonly activateOrganization: EventEmitter<OrganizationItem>;

  @Output()
  readonly deactivateOrganization: EventEmitter<OrganizationItem>;

  @Output()
  readonly organizationUpdated: EventEmitter<OrganizationItem>;

  constructor() {
    this.sortOrderChanged = new EventEmitter();
    this.activateOrganization = new EventEmitter<OrganizationItem>();
    this.deactivateOrganization = new EventEmitter();
    this.organizationUpdated = new EventEmitter();

    this.fieldNames = {
      name: 'name',
      docsCount: 'docsCount',
      usersCount: 'usersCount',
      updatedDate: 'updatedDate',
    };
  }

  @validate
  trackByItem(index: number, @required item: OrganizationItem): string {
    return item.id;
  }

  onSortOrderChanged(sortOrder: FieldSortOrder) {
    this.sortOrderChanged.emit(sortOrder);
  }

  @validate
  onActivateOrganization(@required organization: OrganizationItem) {
    this.activateOrganization.emit(organization);
  }

  @validate
  onDeactivateOrganization(@required organization: OrganizationItem) {
    this.deactivateOrganization.emit(organization);
  }

  @validate
  onOrganizationUpdated(@required updatedOrganization: OrganizationItem) {
    this.organizationUpdated.emit(updatedOrganization);
  }
}
