import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { from, Subscription } from 'rxjs';
import { UserRole } from 'src/app/authentication/shared/user-role';
import { UsersType } from 'src/app/authentication/shared/users-type';
import { LoggerService } from 'src/app/core';
import { DataSourceService, DataSourceState, FieldSortOrder, SortOrder } from 'src/app/core/collections';
import { Unsubscribe } from 'src/app/core/decorators';
import { ErrorModalComponent } from 'src/app/core/error-modal/error-modal.component';
import { ErrorModalDialogData } from 'src/app/core/shared/error-modal-dialog-data';
import { Assert, required, validate } from 'src/app/shared';
import { CreateOrganizationModalComponent } from '../create-organization-modal/create-organization-modal.component';
import { DeleteOrganizationModalComponent } from '../delete-organization-modal/delete-organization-modal.component';
import { OrganizationApiService } from '../organization-api.service';
import { OrganizationUsersManagementService } from '../organization-users-management.service';
import { OrganizationItem, OrganizationStatus } from '../shared';
import PaginationDataSource from '../../core/collections/pagination-data-source';
import { OrganizationDetailsPageRequest } from '../../core/collections/page-response';
import { ActivateOrganizationModalComponent } from '../activate-organization-modal/activate-organization-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Unsubscribe()
@Component({
  selector: 'app-organizations-data',
  templateUrl: './organizations-data.component.html',
  styleUrls: ['./organizations-data.component.scss'],
})
export class OrganizationsDataComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  filter: string;

  @Input()
  showDeactivatedOrganizations: boolean;

  @Input()
  currentPage: number;

  @Input()
  pageSize: number;

  @Input()
  hasSuperAdminPermissions: boolean;

  @Output()
  readonly created: EventEmitter<OrganizationItem>;

  @Output()
  readonly itemsCountChanged: EventEmitter<number>;

  @Output()
  readonly currentPageChanged: EventEmitter<number>;

  state: {
    isLoading: boolean;
    isFailed: boolean;
    isRefreshing: boolean;
    isDeactivating: boolean;
  };

  dataSource: PaginationDataSource<OrganizationItem>;
  dataSourceItems: OrganizationItem[] = [];
  pageRequest: OrganizationDetailsPageRequest<OrganizationItem>;

  dataSourceState: DataSourceState<OrganizationItem>;

  private createOrganizationSubscription: Subscription;
  private dataSourceDataSubscription: Subscription;
  private dataSourceLoadingSubscription: Subscription;

  get UserRole() {
    return UserRole;
  }

  get UsersType() {
    return UsersType;
  }

  constructor(
    private readonly organizationsApiService: OrganizationApiService,
    private readonly loggerService: LoggerService,
    private readonly dataSourceService: DataSourceService,
    private readonly organizationUsersManagementService: OrganizationUsersManagementService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
  ) {
    this.organizationsApiService = organizationsApiService;
    this.loggerService = loggerService;
    this.dataSourceService = dataSourceService;
    this.organizationUsersManagementService = organizationUsersManagementService;
    this.dialog = dialog;

    this.created = new EventEmitter();
    this.itemsCountChanged = new EventEmitter();
    this.currentPageChanged = new EventEmitter<number>();

    this.state = {
      isLoading: false,
      isFailed: false,
      isRefreshing: false,
      isDeactivating: false,
    };
  }

  ngOnInit() {
    this.dataSource = new PaginationDataSource<OrganizationItem>(
      request => from(this.organizationsApiService.getOrganizationsWithDetails(request as OrganizationDetailsPageRequest<OrganizationItem>))
    );
    this.dataSourceDataSubscription = this.dataSource.data$.subscribe(pageResponse => {
      this.dataSourceItems = pageResponse.list;
      this.itemsCountChanged.emit(pageResponse.total);
    });
    this.dataSourceLoadingSubscription = this.dataSource.loading$.subscribe(isLoading => this.state.isLoading = isLoading);
    this.createOrganizationSubscription = this.organizationUsersManagementService.createOrganization$.subscribe(() => {
      this.onCreateOrganization();
    });
    this.resetPageRequest();
    this.loadOrganizations();
  }

  ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<OrganizationsDataComponent, SimpleChange>;

    const filterChange = componentChanges.filter;
    if (filterChange && filterChange.currentValue !== undefined) {
      this.onFilterChanged(filterChange.currentValue);
    }

    const showDeactivatedOrganizationsChange = componentChanges.showDeactivatedOrganizations;
    if (showDeactivatedOrganizationsChange && showDeactivatedOrganizationsChange.currentValue !== undefined) {
      this.onShowDeactivatedOrganizationsChange(showDeactivatedOrganizationsChange.currentValue);
    }

    const currentPageChange = componentChanges.currentPage;
    // page starts from 0
    if (currentPageChange && currentPageChange.currentValue !== undefined) {
      this.onPageChanged(currentPageChange.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.dataSourceDataSubscription.unsubscribe();
    this.dataSourceLoadingSubscription.unsubscribe();
    this.createOrganizationSubscription.unsubscribe();
    this.dataSource.disconnect();
  }

  onSortOrderChanged(sortOrder: FieldSortOrder): void {
    this.pageRequest.page = 0;
    this.pageRequest.sort = sortOrder;
    this.dataSource.fetch(this.pageRequest);

    this.currentPageChanged.emit(0);
  }

  @validate
  onPageChanged(@required page: number) {
    this.pageRequest.page = page;
    this.dataSource.fetch(this.pageRequest);
  }

  onFilterChanged(filter: string) {
    this.pageRequest.page = 0;
    this.pageRequest.filter = filter;
    this.dataSource.fetch(this.pageRequest);
  }

  onShowDeactivatedOrganizationsChange(show: boolean) {
    this.pageRequest.page = 0;
    this.pageRequest.showDeactivatedOrganizations = show;
    this.dataSource.fetch(this.pageRequest);
  }

  @validate
  onPageSizeChanged(@required pageSize: number) {
    this.dataSourceState.page = 0;
    this.dataSourceState.pageSize = pageSize;
  }

  onCreateOrganization() {
    Assert.isNotNull(this.dataSource, 'dataSource');

    const dialogRef = this.dialog.open(CreateOrganizationModalComponent, {
      minWidth: '800px',
    });

    dialogRef.afterClosed().subscribe((organization: OrganizationItem) => {
      if (organization) {
        this.onOrganizationCreated(organization);
      }
    });
  }

  @validate
  onActivateOrganization(@required organization: OrganizationItem) {
    const dialogRef = this.dialog.open(ActivateOrganizationModalComponent, {
      data: organization,
    });
    dialogRef.afterClosed().subscribe((activate: boolean) => {
      if (activate) {
        this.activateOrganization(organization);
      }
    });
  }

  @validate
  onDeactivateOrganization(@required organization: OrganizationItem) {
    const dialogRef = this.dialog.open(DeleteOrganizationModalComponent, {
      data: organization,
    });
    dialogRef.afterClosed().subscribe((deleteOrganization: boolean) => {
      if (deleteOrganization) {
        this.deactivateOrganization(organization);
      }
    });
  }

  onTryLoadOrganizations() {
    this.loadOrganizations();
  }

  @validate
  onOrganizationUpdated(@required updatedOrganization: OrganizationItem) {
    const organizationsComparator = (first: OrganizationItem, second: OrganizationItem) => first.id === second.id;

    // this.dataSource = this.dataSourceService.updateItemInDataSource(
    //   this.dataSource,
    //   this.dataSourceState,
    //   updatedOrganization,
    //   organizationsComparator
    // );
    // this.itemsCountChanged.emit(this.dataSource.filteredItems.length);
  }

  private async loadOrganizations() {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      this.dataSource.fetch(this.pageRequest);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to load organizations', error);
    }
  }

  @validate
  private async activateOrganization(@required organization: OrganizationItem) {
    this.state.isDeactivating = true;
    try {
      await this.organizationsApiService.reactivateOrganization(organization.id);
      this.onOrganizationActivated(organization);
    } catch (error) {
      this.onFailedModal('Failed to reactivate organization', error);
    } finally {
      this.state.isDeactivating = false;
    }
  }

  @validate
  private async deactivateOrganization(@required organization: OrganizationItem) {
    this.state.isDeactivating = true;
    try {
      await this.organizationsApiService.deactivateOrganization(organization.id);
      this.onOrganizationDeactivated(organization);
    } catch (error) {
      this.onFailedModal('Failed to deactivate the customer', error);
    } finally {
      this.state.isDeactivating = false;
    }
  }

  @validate
  private onOrganizationCreated(@required organization: OrganizationItem) {
    this.showSnackBar(`"${organization.name}" aktiverad.`);
    this.resetPageRequest();
    this.dataSource.fetch(this.pageRequest);
    this.created.emit(organization);
  }

  @validate
  private onOrganizationActivated(@required activatedOrganization: OrganizationItem) {
    this.showSnackBar(`"${activatedOrganization.name}" återaktiverad.`);
    this.dataSourceItems.forEach((organizationItem: OrganizationItem) => {
      if (organizationItem.id === activatedOrganization.id)
        organizationItem.status = OrganizationStatus.Active;
    });
  }

  @validate
  private onOrganizationDeactivated(@required deactivatedOrganization: OrganizationItem) {
    this.showSnackBar(`"${deactivatedOrganization.name}" inaktiverad.`);
    this.dataSourceItems.forEach((organizationItem: OrganizationItem) => {
      if (organizationItem.id === deactivatedOrganization.id)
        organizationItem.status = OrganizationStatus.Deactivated;
    });
    // Used to hide deactivated customer if `Show deactivated customers` is unchecked
    this.dataSource.fetch(this.pageRequest);
  }

  @validate
  private onFailedModal(@required message: string, error: any) {
    this.loggerService.error(message, error);
    const errorModalDialogData = new ErrorModalDialogData({ title: message });
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: errorModalDialogData,
    });
  }

  private resetPageRequest() {
    this.pageRequest = {
      page: 0,
      pageSize: this.pageSize,
      filter: '',
      sort: new FieldSortOrder('updatedDate', SortOrder.desc),
      showDeactivatedOrganizations: false
    };
  }

  private showSnackBar(@required message: string): void {
    this.snackBar.open(message, 'OK');
  }
}
