<h1 mat-dialog-title>
  {{ title }}
</h1>

<div mat-dialog-content>
  <div class="modal-body">{{ message }}</div>
</div>

<div mat-dialog-actions class="float-right">
  <button mat-raised-button (click)="onDismiss()">Nej</button>
  <button mat-raised-button (click)="onConfirm()" cdkFocusInitial class="confirm-btn">Ja</button>
</div>
