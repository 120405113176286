import { ValidatorFn, ValidationErrors, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Assert, StringUtils } from 'src/app/shared';
import { map, isEmpty, isString, values } from 'lodash';

export const requireToMatchValidationKey = 'requireToMatch';

export function requireToMatchValidator(
  ignoreCase: boolean = false,
  controls?: string[] | AbstractControl[]
): ValidatorFn {
  const validator: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
    Assert.isNotNull(group, 'group');

    let validatedControls: AbstractControl[];
    if (isEmpty(controls)) {
      validatedControls = values(group.controls);
    } else {
      validatedControls = map(controls, (control: string | AbstractControl) =>
        isString(control) ? group.controls[control] : control
      );
    }

    if (validatedControls.length < 2) {
      return null;
    }

    for (let i = 1; i < validatedControls.length; i++) {
      const firstControl = validatedControls[i - 1];
      const secondControl = validatedControls[i];

      const firstValue = firstControl ? firstControl.value : null;
      const secondValue = secondControl ? secondControl.value : null;

      const isValid = ignoreCase
        ? StringUtils.equalsIgnoreCase(firstValue, secondValue)
        : StringUtils.equals(firstValue, secondValue);

      if (!isValid) {
        return { [requireToMatchValidationKey]: true };
      }

      return null;
    }
  };

  return validator;
}
