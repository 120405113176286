import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { requireNotToMatchValidator } from './require-not-to-match.validator';
import { required, validate } from 'src/app/shared';

@Directive({
  selector: '[appRequireNotToMatch]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RequireNotToMatchValidatorDirective, multi: true }],
})
export class RequireNotToMatchValidatorDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('requireNotToMatchIgnoreCase')
  ignoreCase: boolean;

  @Input('appRequireNotToMatch')
  controls: string[] | AbstractControl[];

  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    const validator = requireNotToMatchValidator(this.ignoreCase, this.controls);

    const result = validator(control);

    return result;
  }
}
