import { IDocumentItem } from './document';

export class DocumentBuilding implements IDocumentItem {
  static readonly analyticalDimensioningId: string = '120-analytical-dimensioning';

  numberOfStoreys?: number;
  fireTechnicalBuildingClassId?: string;
  dimensioningFireLoadId?: string;
  isBr0AnalysisEstablished?: boolean;

  hasBasementFloor?: boolean;
  isConsideredBasementFloorPlan?: boolean;

  hasSeveralBasementFloors?: boolean;

  hasAtticFloor?: boolean;
  isConsideredAtticFloorPlan?: boolean;

  hasEntresolplan?: boolean;
  isConsideredEntresolplan?: boolean;

  isConsideredSmallerEntresolplan?: boolean;

  hasSouterrainDesign?: boolean;
  isConsideredSouterrainDesignPlan?: boolean;

  hasCulvert?: boolean;
  culvertTypeIds: string[];

  buildingConnectionWith?: string;
  buildingIsIncludedInSameBuildingComplex?: string;
  adjacentBuildings?: string;
  buildingBuiltTogetherViaCulvert?: string;

  fireTechnicalSolutionTypeId?: string;
  isOwnGuidelinesAmbitionsApplied?: boolean;
  followingOwnGuidelineAmbitionsRelevant?: string;
  analyticalDimensioningDescription?: string;

  occuredBuildingActivityIds: string[];
  largeKitchenWithIds: string[];
  protectiveSolutionForLargeKitchenIds: string[];
  meetingRoomOccursInFormOfIds: string[];
  numberOfPeopleInMeetingRoomIds: string[];
  isPublicPremises?: boolean;
  isAppliedTemporaryOvernightAccommodation?: boolean;
  frameConstructionConsists?: string;
  beamConstructionConsists?: string;
  facadeConstructionConsists?: string;
  roofConstructionConsists?: string;
  extendedBuildingDescription: string;
  isCompleted: boolean;
  isValid: boolean;

  constructor({
    numberOfStoreys,
    fireTechnicalBuildingClassId,
    dimensioningFireLoadId,
    isBr0AnalysisEstablished,
    hasBasementFloor,
    isConsideredBasementFloorPlan,
    hasSeveralBasementFloors,
    hasAtticFloor,
    isConsideredAtticFloorPlan,
    hasEntresolplan,
    isConsideredEntresolplan,
    isConsideredSmallerEntresolplan,
    hasSouterrainDesign,
    isConsideredSouterrainDesignPlan,
    hasCulvert,
    culvertTypeIds,
    buildingConnectionWith,
    buildingIsIncludedInSameBuildingComplex,
    adjacentBuildings,
    buildingBuiltTogetherViaCulvert,
    fireTechnicalSolutionTypeId,
    isOwnGuidelinesAmbitionsApplied,
    followingOwnGuidelineAmbitionsRelevant,
    analyticalDimensioningDescription,
    occuredBuildingActivityIds,
    largeKitchenWithIds,
    protectiveSolutionForLargeKitchenIds,
    meetingRoomOccursInFormOfIds,
    numberOfPeopleInMeetingRoomIds,
    isPublicPremises,
    isAppliedTemporaryOvernightAccommodation,
    frameConstructionConsists,
    beamConstructionConsists,
    facadeConstructionConsists,
    roofConstructionConsists,
    extendedBuildingDescription,
    isCompleted,
    isValid,
  }: Partial<DocumentBuilding> = {}) {
    this.numberOfStoreys = numberOfStoreys;
    this.fireTechnicalBuildingClassId = fireTechnicalBuildingClassId;
    this.dimensioningFireLoadId = dimensioningFireLoadId;
    this.isBr0AnalysisEstablished = isBr0AnalysisEstablished;
    this.hasBasementFloor = hasBasementFloor;
    this.isConsideredBasementFloorPlan = isConsideredBasementFloorPlan;
    this.hasSeveralBasementFloors = hasSeveralBasementFloors;
    this.hasAtticFloor = hasAtticFloor;
    this.isConsideredAtticFloorPlan = isConsideredAtticFloorPlan;
    this.hasEntresolplan = hasEntresolplan;
    this.isConsideredEntresolplan = isConsideredEntresolplan;
    this.isConsideredSmallerEntresolplan = isConsideredSmallerEntresolplan;
    this.hasSouterrainDesign = hasSouterrainDesign;
    this.isConsideredSouterrainDesignPlan = isConsideredSouterrainDesignPlan;
    this.hasCulvert = hasCulvert;
    this.culvertTypeIds = culvertTypeIds || [];
    this.buildingConnectionWith = buildingConnectionWith;
    this.buildingIsIncludedInSameBuildingComplex = buildingIsIncludedInSameBuildingComplex;
    this.buildingBuiltTogetherViaCulvert = buildingBuiltTogetherViaCulvert;
    this.adjacentBuildings = adjacentBuildings;
    this.fireTechnicalSolutionTypeId = fireTechnicalSolutionTypeId;
    this.isOwnGuidelinesAmbitionsApplied = isOwnGuidelinesAmbitionsApplied;
    this.followingOwnGuidelineAmbitionsRelevant = followingOwnGuidelineAmbitionsRelevant;
    this.analyticalDimensioningDescription = analyticalDimensioningDescription;
    this.occuredBuildingActivityIds = occuredBuildingActivityIds || [];
    this.largeKitchenWithIds = largeKitchenWithIds || [];
    this.protectiveSolutionForLargeKitchenIds = protectiveSolutionForLargeKitchenIds || [];
    this.meetingRoomOccursInFormOfIds = meetingRoomOccursInFormOfIds || [];
    this.numberOfPeopleInMeetingRoomIds = numberOfPeopleInMeetingRoomIds || [];
    this.isPublicPremises = isPublicPremises;
    this.isAppliedTemporaryOvernightAccommodation = isAppliedTemporaryOvernightAccommodation;
    this.frameConstructionConsists = frameConstructionConsists;
    this.beamConstructionConsists = beamConstructionConsists;
    this.facadeConstructionConsists = facadeConstructionConsists;
    this.roofConstructionConsists = roofConstructionConsists;
    this.extendedBuildingDescription = extendedBuildingDescription;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
