<h1 mat-dialog-title>
  <span class="dialog-header-text"> Hantera läsarrättigheter </span>
  <button mat-icon-button aria-label="close dialog" class="close-btn" (click)="onClose()">
    <mat-icon>cancel</mat-icon>
  </button>
</h1>
<div class="manage-permissions-dialog" mat-dialog-content>
  <div class="row">
    <div class="col-4 permission-groups pr-2">
      <div class="permission-list">
        <mat-form-field class="w-100 permissions-input-field-value">
          <mat-label>Sök läsarrättigheter</mat-label>
          <input
            matInput
            name="permissionGroupsFilter"
            [disabled]="state.isLoading"
            (input)="onPermissionGroupsFilterChanged($event.target.value)"
            placeholder="Sök läsarrättigheter"
          />
        </mat-form-field>

        <div>
          <div class="permission-groups-block">
            <div
              class="mb-2 permission-group"
              *ngFor="let permission of permissionsDataSource.filteredItems"
              [class.selected-permission]="selectedPermissionGroup && permission.id === selectedPermissionGroup.id"
              [class.disabled-permission-group]="state.isLoading"
              (click)="onPermissionClick(permission)"
            >
              <div class="permission-group-name">
                {{ permission.name }}
              </div>
              <div>
                <span class="badge badge-secondary text-medium" [ngbTooltip]="permissionUsersCountTooltipContent">
                  {{ permission.userIds.length }}
                </span>
                <ng-template #permissionUsersCountTooltipContent>
                  <span> {{ permission.userIds.length }} Användare </span>
                </ng-template>
                <button mat-icon-button *ngIf="permission.id !== null" (click)="onDeletePermission($event, permission)"
                        class="text-danger"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
            <div>
              <ng-container *ngIf="permissionsDataSource.filteredItems.length === 0">
                <div class="text-center">
                  <i class="fas fa-spinner fa-2x fa-pulse mr-1" *ngIf="state.isLoading"></i>
                  <ng-container *ngIf="!state.isLoading">Det finns inga rättigheter att visa</ng-container>
                </div>
              </ng-container>
            </div>
          </div>
          <hr class="my-2" />
          <button
            mat-flat-button
            class="button-border add-permission-btn float-right"
            [disabled]="state.isLoading"
            (click)="onAddPermissionGroup()"
          >
            Skapa ny läsarrättighet
          </button>
        </div>
      </div>
    </div>
    <div class="col-8 right-panel pl-2">
      <ng-container *ngIf="!selectedPermissionGroup">
        <div class="text-center no-selected-permission">
          <i class="fas fa-spinner fa-2x fa-pulse mr-1" *ngIf="state.isLoading"></i>
          <ng-container *ngIf="!state.isLoading">Ingen läsarrättighet är vald.</ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedPermissionGroup">
        <form #editPermissionGroupForm="ngForm">
          <mat-form-field class="w-100 permissions-input-field-value">
            <input
              name="permissionGroupName"
              matInput
              [(ngModel)]="selectedPermissionGroup.name"
              placeholder="Namn"
              (ngModelChange)="permissionGroupNameChanged()"
              required
            />
          </mat-form-field>
          <mat-tab-group class="manage-block">
            <mat-tab label="Inkluderade dokument">
              <div class="d-flex justify-content-end">
                <mat-checkbox
                  name="showOnlySelectedDocuments"
                  class="mr-2 mt-3"
                  [(ngModel)]="showOnlySelectedDocuments"
                  (ngModelChange)="onShowOnlySelectedDocumentsChanged()"
                  >Visa endast inkluderade dokument</mat-checkbox
                >
                <mat-form-field class="permissions-filter-value permissions-input-field-value">
                  <input
                    matInput
                    name="documentsFilter"
                    [value]="documentsFilter"
                    (input)="onDocumentsFilterChanged($event.target.value)"
                    placeholder="Sök dokument"
                  />
                </mat-form-field>
              </div>
              <mat-selection-list
                name="permissionDocuments"
                class="manage-list"
                [(ngModel)]="selectedPermissionGroup.documentIds"
              >
                <div class="text-center" *ngIf="documentsDataSource.filteredItems.length === 0">
                  <ng-container *ngIf="!showOnlySelectedDocuments">Det finns inga dokument</ng-container>
                  <ng-container *ngIf="showOnlySelectedDocuments">Det finns inga dokument att visa</ng-container>
                </div>
                <mat-list-option
                  *ngFor="let document of documentsDataSource.filteredItems"
                  [value]="document.id"
                  class="mb-1 settings-list-option"
                >
                  {{ document.info.buildingName || '(Inget namn ännu)' }}
                </mat-list-option>
              </mat-selection-list>
            </mat-tab>
            <mat-tab label="Användare">
              <div class="d-flex justify-content-end">
                <mat-checkbox
                  name="showOnlySelectedUsers"
                  class="mr-2 mt-3"
                  [(ngModel)]="showOnlySelectedUsers"
                  (ngModelChange)="onShowOnlySelectedUsersChanged()"
                  >Visa endast tilldelade användare</mat-checkbox
                >
                <mat-form-field class="permissions-filter-value permissions-input-field-value">
                  <input
                    matInput
                    name="usersFilter"
                    [value]="usersFilter"
                    (input)="onUsersFilterChanged($event.target.value)"
                    placeholder="Sök användare"
                  />
                </mat-form-field>
              </div>
              <mat-selection-list
                name="permissionUsers"
                class="manage-list"
                [(ngModel)]="selectedPermissionGroup.userIds"
                (ngModelChange)="onShowOnlySelectedUsersChanged($event)"
              >
                <div class="text-center" *ngIf="usersDataSource.filteredItems.length === 0">
                  <ng-container *ngIf="!showOnlySelectedUsers">Det finns inga användare</ng-container>
                  <ng-container *ngIf="showOnlySelectedUsers">Det finns inga användare att visa</ng-container>
                </div>
                <mat-list-option
                  *ngFor="let detail of usersDataSource.filteredItems"
                  [value]="detail.user.id"
                  class="mb-1 settings-list-option"
                >
                  {{ detail.user.firstName }} {{ detail.user.lastName }}</mat-list-option
                >
              </mat-selection-list>
            </mat-tab>
          </mat-tab-group>
        </form>
        <div mat-dialog-actions class="float-right mt-1">
          <button mat-raised-button (click)="onReset()" [disabled]="!hasUnsavedChanges">Återställ</button>
          <button
            class="save-btn"
            mat-raised-button
            (click)="onSavePermissionGroup()"
            cdkFocusInitial
            [disabled]="
              state.isLoading || !selectedPermissionGroup || editPermissionGroupForm.invalid || !hasUnsavedChanges
            "
          >
            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="state.isLoading"></i>
            <ng-container>Spara</ng-container>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
