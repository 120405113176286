export enum DocumentFieldType {
  string = 'string',
  number = 'number',
  date = 'date',
  dropdown = 'dropdown',
  groupDropdown = 'groupDropdown',
  multiple = 'multiple',
  boolean = 'boolean',
  renovation = 'renovation',
  extinguishingSystems = 'extinguishingSystems',
}
