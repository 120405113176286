import { Injectable } from '@angular/core';
import { required, validate } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  @validate
  error(@required message: string, ...details: any[]): void {
    // tslint:disable-next-line: no-console
    console.error(message, ...details);
  }

  @validate
  warning(@required message: string, ...details: any[]): void {
    // tslint:disable-next-line: no-console
    console.warn(message, ...details);
  }

  @validate
  info(@required message: string, ...details: any[]): void {
    // tslint:disable-next-line: no-console
    console.info(message, ...details);
  }

  captureException(exception: any) {
    if (!exception) {
      this.warning('Cannot capture emtpy exception.');
      return;
    }

    // try {
    //   const eventId = Sentry.captureException(exception.originalError || exception);
    //   this.info(`Captured exception ID: "${eventId}".`, exception);
    // } catch (error) {
    this.error('Failed to capute exception.', exception);
    // }
  }
}
