import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardModule } from './dashboard/dashboard.module';
import { StoreModule } from '@ngrx/store';
import { appReducer, metaReducersProvider } from './app.reducer';
import { CoreModule } from './core/core.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { DocumentsModule } from './documents/documents.module';
import { ProjectModule } from './project/project.module';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { OrganizationModule } from './organization/organization.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { appConfig } from './app.config';
import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig, MatSnackBarModule } from '@angular/material/snack-bar';
registerLocaleData(localeSv);

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'always',
};
const snackBarOptions: MatSnackBarConfig = {
  duration: 4000,
  horizontalPosition: 'end',
  verticalPosition: 'bottom',
  panelClass: ['app-snack-bar-container'],
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MatSnackBarModule,
    AuthenticationModule,
    CoreModule,
    StoreModule.forRoot(appReducer, {
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: false,
      },
    }),
    DashboardModule,
    AppRoutingModule,
    NgbModule,
    OrganizationModule,
    DocumentsModule,
    ProjectModule,
    LayoutModule,
    BrowserAnimationsModule,
  ],
  providers: [
    metaReducersProvider,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: snackBarOptions,
    },
    { provide: MAT_DATE_LOCALE, useValue: appConfig.locale },
    { provide: LOCALE_ID, useValue: appConfig.locale },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
