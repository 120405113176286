<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'UTFORMNING AV HISSAR'"
    [documentId]="document?.id"
    [isCompleted]="document?.lifts.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Hiss finns">
              <mat-label title="Elevator exist">Hiss finns</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.lifts.isElevatorExist"
                #isElevatorExist="ngModel"
                name="isElevatorExist"
              />
              <mat-radio-group
                class="d-block"
                name="isElevatorExistRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.lifts.isElevatorExist"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isElevatorExist.name]"-->
<!--                [class.text-secondary-primary]="notes[isElevatorExist.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isElevatorExist.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isElevatorExist.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-9 col-xl-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Följande lösning tillämpas för skydd mot brand- och brandgasspridning via hisschakt"
              [values]="elevatorFireProtectionOptionTypes"
              [selectedKeys]="document.lifts.elevatorFireProtectionOptionIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="elevatorFireProtectionOptionIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Följande lösning tillämpas för skydd mot brand- och brandgasspridning via hisschakt"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label
                title="The following solution is applied for protection against fire and fire gas spread via elevator shafts"
                >Följande lösning tillämpas för skydd mot brand- och brandgasspridning via hisschakt</mat-label
              >

              <mat-select
                required
                [(ngModel)]="document.lifts.elevatorFireProtectionOptionIds"
                [disabled]="!document.lifts.isElevatorExist"
                #elevatorFireProtectionOptionIds="ngModel"
                name="elevatorFireProtectionOptionIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="elevatorFireProtectionOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let elevatorFireProtectionOptionType of elevatorFireProtectionOptionTypes | keyvalue"
                  [value]="elevatorFireProtectionOptionType.key"
                  [title]="elevatorFireProtectionOptionType.value.displayName"
                  [disabled]="elevatorFireProtectionOptionType.value.isDisabled"
                  >{{ elevatorFireProtectionOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[elevatorFireProtectionOptionIds.name]"-->
<!--                [class.text-secondary-primary]="notes[elevatorFireProtectionOptionIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[elevatorFireProtectionOptionIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="elevatorFireProtectionOptionIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3">
            <mat-form-field class="w-100" [class.d-none]="!isElevatorFireProtectionOtherEnabled">
              <mat-label>Annat</mat-label>
              <input
                matInput
                required
                [(ngModel)]="document.lifts.elevatorFireProtectionOther"
                [disabled]="
                  !document.lifts.isElevatorExist || !hasEditPermissions || !isElevatorFireProtectionOtherEnabled
                "
                #elevatorFireProtectionOther="ngModel"
                name="elevatorFireProtectionOther"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[elevatorFireProtectionOther.name]"-->
<!--                [class.text-secondary-primary]="notes[elevatorFireProtectionOther.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[elevatorFireProtectionOther.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="elevatorFireProtectionOther.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Styrfunktion, hissen går vid strömavbrott till närmaste stannplan"
            >
              <mat-label>Styrfunktion, hissen går vid strömavbrott till närmaste stannplan</mat-label>
              <input
                matInput
                class="d-none"
                required
                [(ngModel)]="document.lifts.liftSteeringFunctionId"
                [disabled]="!document.lifts.isElevatorExist || !hasEditPermissions"
                #liftSteeringFunctionId="ngModel"
                name="liftSteeringFunctionId"
              />
              <mat-radio-group
                class="d-block"
                name="liftSteeringFunctionIdRadio"
                [(ngModel)]="document.lifts.liftSteeringFunctionId"
                [disabled]="!document.lifts.isElevatorExist || !hasEditPermissions"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button
                  *ngFor="let liftSteeringFunctionOptionType of liftSteeringFunctionOptionTypes | keyvalue"
                  [value]="liftSteeringFunctionOptionType.key"
                  [title]="liftSteeringFunctionOptionType.value.displayName"
                  class="mr-3"
                  >{{ liftSteeringFunctionOptionType.value.displayName }}</mat-radio-button
                >
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[liftSteeringFunctionId.name]"-->
<!--                [class.text-secondary-primary]="notes[liftSteeringFunctionId.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[liftSteeringFunctionId.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="liftSteeringFunctionId.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Återkallningsfunktion via brandlarm som styr hissen till evakueringsplan förekommer"
            >
              <mat-label>Återkallningsfunktion via brandlarm som styr hissen till evakueringsplan förekommer</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.lifts.isElevatorExist || !hasEditPermissions"
                [(ngModel)]="document.lifts.recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId"
                #recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId="ngModel"
                name="recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId"
              />
              <mat-radio-group
                class="d-block"
                name="recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneIdRadio"
                [disabled]="!document.lifts.isElevatorExist || !hasEditPermissions"
                [(ngModel)]="document.lifts.recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button
                  *ngFor="let liftSteeringFunctionOptionType of recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneOptionTypes | keyvalue"
                  [value]="liftSteeringFunctionOptionType.key"
                  [title]="liftSteeringFunctionOptionType.value.displayName"
                  class="mr-3"
                >{{ liftSteeringFunctionOptionType.value.displayName }}</mat-radio-button
                >
              </mat-radio-group>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[liftSteeringFunctionId.name]"-->
              <!--                [class.text-secondary-primary]="notes[liftSteeringFunctionId.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[liftSteeringFunctionId.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="liftSteeringFunctionId.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Brandsäkert förlagt kablage">
              <mat-label title="Fireproof wiring"
                >Brandsäkert förlagt kablage</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [(ngModel)]="document.lifts.liftFireproofWiringId"
                [disabled]="!document.lifts.isElevatorExist || !hasEditPermissions"
                #liftFireproofWiringId="ngModel"
                name="liftFireproofWiringId"
              />
              <mat-radio-group
                class="d-block"
                name="liftFireproofWiringIdRadio"
                [(ngModel)]="document.lifts.liftFireproofWiringId"
                [disabled]="!document.lifts.isElevatorExist || !hasEditPermissions"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button
                  *ngFor="let liftFireproofWiringOptionType of liftFireproofWiringOptionTypes | keyvalue"
                  [value]="liftFireproofWiringOptionType.key"
                  [title]="liftFireproofWiringOptionType.value.displayName"
                  class="mr-3"
                  >{{ liftFireproofWiringOptionType.value.displayName }}</mat-radio-button
                >
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[liftFireproofWiringId.name]"-->
<!--                [class.text-secondary-primary]="notes[liftFireproofWiringId.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[liftFireproofWiringId.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="liftFireproofWiringId.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende utformning av hissar">
              <mat-label>Utökad beskrivning avseende utformning av hissar</mat-label>
              <textarea
                rows="10"
                matInput
                [(ngModel)]="document.lifts.liftsDesignDescription"
                [disabled]="!document.lifts.isElevatorExist || !hasEditPermissions"
                #liftsDesignDescription="ngModel"
                name="liftsDesignDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[liftsDesignDescription.name]"-->
              <!--                [class.text-secondary-primary]="notes[liftsDesignDescription.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[liftsDesignDescription.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="liftsDesignDescription.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
