<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'Skydd mot utveckling och spridning av brand och brandgaser inom byggnad'"
    [documentId]="document?.id"
    [isCompleted]="document?.fire.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" [class.form-field-disabled-hint]="document.evacuation.isEvacuationFromAnotherFireExit" title="Byggnaden är indelad i brandceller">
              <mat-label
                title="The building is divided into fire cells"
                >Byggnaden är indelad i brandceller</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.fire.isBuildingDividedIntoFireCells"
                #isBuildingDividedIntoFireCells="ngModel"
                name="isBuildingDividedIntoFireCells"
              />
              <mat-radio-group
                class="d-block"
                name="isBuildingDividedIntoFireCellsGroup"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.fire.isBuildingDividedIntoFireCells"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false" [disabled]="document.evacuation.isEvacuationFromAnotherFireExit">
                  Nej
                </mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isBuildingDividedIntoFireCells.name]"-->
<!--                [class.text-secondary-primary]="notes[isBuildingDividedIntoFireCells.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isBuildingDividedIntoFireCells.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isBuildingDividedIntoFireCells.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
              <div *ngIf="document.evacuation.isEvacuationFromAnotherFireExit" class="text-xs text-grey">
                Brandceller är redan definierade under fliken Utrymning - Utrymning över annan brandcell
              </div>
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Brandcell som överskrider 1250 m²">
              <mat-label>Brandcell som överskrider 1250 m²</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !isFireCellExceeds1250m2Enabled || !hasEditPermissions"
                [(ngModel)]="document.fire.isFireCellExceeds1250m2"
                #isFireCellExceeds1250m2="ngModel"
                name="isFireCellExceeds1250m2"
              />
              <mat-radio-group
                class="d-block"
                name="isFireCellExceeds1250m2Radio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !isFireCellExceeds1250m2Enabled || !hasEditPermissions"
                [(ngModel)]="document.fire.isFireCellExceeds1250m2"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Fler än två plan i samma brandcell">
              <mat-label
                title="More than two planes in the same fire cell"
                >Fler än två plan i samma brandcell</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.isMoreThanTwoPlanes"
                #isMoreThanTwoPlanes="ngModel"
                name="isMoreThanTwoPlanes"
              />
              <mat-radio-group
                class="d-block"
                name="isMoreThanTwoPlanesRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.isMoreThanTwoPlanes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isMoreThanTwoPlanes.name]"-->
<!--                [class.text-secondary-primary]="notes[isMoreThanTwoPlanes.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isMoreThanTwoPlanes.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isMoreThanTwoPlanes.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Särskild brandteknisk klass på avskiljande konstruktion"
            >
              <mat-label
                title="Special fire technical class on separating construction"
                >Särskild brandteknisk klass på avskiljande konstruktion</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.isSpecialFireTechnicalClass"
                #isSpecialFireTechnicalClass="ngModel"
                name="isSpecialFireTechnicalClass"
              />
              <mat-radio-group
                class="d-block"
                name="isSpecialFireTechnicalClassRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.isSpecialFireTechnicalClass"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isSpecialFireTechnicalClass.name]"-->
<!--                [class.text-secondary-primary]="notes[isSpecialFireTechnicalClass.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isSpecialFireTechnicalClass.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isSpecialFireTechnicalClass.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Flera verksamhetsklasser inom samma brandcell">
              <mat-label
                title="Several business classes within the same fire cell"
                >Flera verksamhetsklasser inom samma brandcell</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.isSeveralBusinessClasses"
                #isSeveralBusinessClasses="ngModel"
                name="isSeveralBusinessClasses"
              />
              <mat-radio-group
                class="d-block"
                name="isSeveralBusinessClassesRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.isSeveralBusinessClasses"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isSeveralBusinessClasses.name]"-->
<!--                [class.text-secondary-primary]="notes[isSeveralBusinessClasses.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isSeveralBusinessClasses.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isSeveralBusinessClasses.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Dörrar och/eller luckor i brandavskiljande konstruktion">
              <mat-label>Dörrar och/eller luckor i brandavskiljande konstruktion</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasDoorsOrHatchesInFireSeparationConstruction"
                #hasDoorsOrHatchesInFireSeparationConstruction="ngModel"
                name="hasDoorsOrHatchesInFireSeparationConstruction"
              />
              <mat-radio-group
                class="d-block"
                name="hasDoorsOrHatchesInFireSeparationConstructionRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasDoorsOrHatchesInFireSeparationConstruction"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[hasInstallationDeviceOnDoors.name]"-->
              <!--                [class.text-secondary-primary]="notes[hasInstallationDeviceOnDoors.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[hasInstallationDeviceOnDoors.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="hasInstallationDeviceOnDoors.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Uppställningsanordning på brandavskiljande dörrar">
              <mat-label>Uppställningsanordning på brandavskiljande dörrar</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasInstallationDeviceOnDoors"
                #hasInstallationDeviceOnDoors="ngModel"
                name="hasInstallationDeviceOnDoors"
              />
              <mat-radio-group
                class="d-block"
                name="hasInstallationDeviceOnDoorsRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasInstallationDeviceOnDoors"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[hasInstallationDeviceOnDoors.name]"-->
              <!--                [class.text-secondary-primary]="notes[hasInstallationDeviceOnDoors.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[hasInstallationDeviceOnDoors.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="hasInstallationDeviceOnDoors.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Automatisk öppningsfunktion på brandavskiljande dörrar"
            >
              <mat-label
                title="Automatic opening function on fire-separating doors"
                >Automatisk öppningsfunktion på brandavskiljande dörrar</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasAutomaticOpeningOnDoors"
                #hasAutomaticOpeningOnDoors="ngModel"
                name="hasAutomaticOpeningOnDoors"
              />
              <mat-radio-group
                class="d-block"
                name="hasAutomaticOpeningOnDoorsRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasAutomaticOpeningOnDoors"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasAutomaticOpeningOnDoors.name]"-->
<!--                [class.text-secondary-primary]="notes[hasAutomaticOpeningOnDoors.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasAutomaticOpeningOnDoors.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasAutomaticOpeningOnDoors.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Glasparti i brandavskiljande konstruktion">
              <mat-label
                title="Windows in fire-retardant construction"
                >Glasparti i brandavskiljande konstruktion</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasWindowsInFireRetardantConstruction"
                #hasWindowsInFireRetardantConstruction="ngModel"
                name="hasWindowsInFireRetardantConstruction"
              />
              <mat-radio-group
                class="d-block"
                name="hasWindowsInFireRetardantConstructionRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasWindowsInFireRetardantConstruction"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasWindowsInFireRetardantConstruction.name]"-->
<!--                [class.text-secondary-primary]="notes[hasWindowsInFireRetardantConstruction.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasWindowsInFireRetardantConstruction.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasWindowsInFireRetardantConstruction.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Fönster i vinkel, parallellt alt. höjdled mellan olika brandceller i yttervägg"
            >
              <mat-label
                title="Window at an angle alt. in height between different fire cells"
              >Fönster i vinkel, parallellt alt. höjdled mellan olika brandceller i yttervägg</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasFireWindowAtAngle"
                #hasFireWindowAtAngle="ngModel"
                name="hasFireWindowAtAngle"
              />
              <mat-radio-group
                class="d-block"
                name="hasFireWindowAtAngleRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasFireWindowAtAngle"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[hasFireWindowAtAngle.name]"-->
              <!--                [class.text-secondary-primary]="notes[hasFireWindowAtAngle.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[hasFireWindowAtAngle.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="hasFireWindowAtAngle.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Lågdel/högdel i brandcellsgräns">
              <mat-label title="Evacuation via evacuation elevator"
              >Lågdel/högdel i brandcellsgräns</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasFireLowHighPart"
                #hasFireLowHighPart="ngModel"
                name="hasFireLowHighPart"
              />
              <mat-radio-group
                class="d-block"
                name="hasFireLowHighPartRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasFireLowHighPart"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[hasFireLowHighPart.name]"-->
              <!--                [class.text-secondary-primary]="notes[hasFireLowHighPart.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[hasFireLowHighPart.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="hasFireLowHighPart.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Brandavskiljande undertak eller installationsgolv">
              <mat-label
                title="Fire-retardant suspended ceilings or installation floors"
                >Brandavskiljande undertak eller installationsgolv</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasFireRetardantCeilingsFloors"
                #hasFireRetardantCeilingsFloors="ngModel"
                name="hasFireRetardantCeilingsFloors"
              />
              <mat-radio-group
                class="d-block"
                name="hasFireRetardantCeilingsFloorsRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasFireRetardantCeilingsFloors"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasFireRetardantCeilingsFloors.name]"-->
<!--                [class.text-secondary-primary]="notes[hasFireRetardantCeilingsFloors.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasFireRetardantCeilingsFloors.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasFireRetardantCeilingsFloors.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Luft- eller brandsluss">
              <mat-label title="Air or fire lock"
                >Luft- eller brandsluss</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasAirFireLock"
                #hasAirFireLock="ngModel"
                name="hasAirFireLock"
              />
              <mat-radio-group
                class="d-block"
                name="hasAirFireLockRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasAirFireLock"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasAirFireLock.name]"-->
<!--                [class.text-secondary-primary]="notes[hasAirFireLock.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasAirFireLock.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasAirFireLock.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Överbyggd gård, inglasade balkonger, loftgångar etc."
            >
              <mat-label
                title="Covered yard, glazed balconies, attic corridors, etc."
                >Överbyggd gård, inglasade balkonger, loftgångar etc.</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasCoveredYardBalconies"
                #hasCoveredYardBalconies="ngModel"
                name="hasCoveredYardBalconies"
              />
              <mat-radio-group
                class="d-block"
                name="hasCoveredYardBalconies"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasCoveredYardBalconies"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[hasCoveredYardBalconies.name]"-->
<!--                [class.text-secondary-primary]="notes[hasCoveredYardBalconies.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[hasCoveredYardBalconies.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="hasCoveredYardBalconies.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Brandteknisk avskiljning av luftad takfot">
              <mat-label title="Brandteknisk avskiljning av luftad takfot">Brandteknisk avskiljning av luftad takfot</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasFireTechnicalSeparationOfAeratedEaves"
                #hasFireTechnicalSeparationOfAeratedEaves="ngModel"
                name="hasFireTechnicalSeparationOfAeratedEaves"
              />
              <mat-radio-group
                class="d-block"
                name="hasFireTechnicalSeparationOfAeratedEavesRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.hasFireTechnicalSeparationOfAeratedEaves"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>
        </div>

        <div class="row">

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Brandcellsindelning för uppdelning av vindsplanet"
              [class.form-field-disabled-hint]="document.fire.isBuildingDividedIntoFireCells && !document.building.hasAtticFloor"
            >
              <mat-label
              >Brandcellsindelning för uppdelning av vindsplanet</mat-label
              >
              <input
                matInput
                class="d-none"
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !document.building.hasAtticFloor || !hasEditPermissions"
                [(ngModel)]="document.fire.hasFireCellInAttic"
                #hasFireCellInAttic="ngModel"
                name="hasFireCellInAttic"
              />
              <mat-radio-group
                class="d-block"
                name="hasFireCellInAtticRadio"
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !document.building.hasAtticFloor || !hasEditPermissions"
                [(ngModel)]="document.fire.hasFireCellInAttic"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[hasFireCellInAttic.name]"-->
              <!--                [class.text-secondary-primary]="notes[hasFireCellInAttic.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[hasFireCellInAttic.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="hasFireCellInAttic.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
              <div *ngIf="document.fire.isBuildingDividedIntoFireCells && !document.building.hasAtticFloor" class="text-xs text-grey">
                Definiera vindsplan under fliken Byggnads- och verksamhetsbeskrivning - Vindsplan
              </div>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Kravbild"
              [values]="fireRequirementOptionTypes"
              [selectedKeys]="document.fire.fireRequirementIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="fireRequirementIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Kravbild"
            >
              <mat-label title="Kravbild">Kravbild</mat-label>

              <mat-select
                required
                [disabled]="!hasEditPermissions || !document.fire.isBuildingDividedIntoFireCells || !document.fire.hasFireCellInAttic"
                [(ngModel)]="document.fire.fireRequirementIds"
                #fireRequirementIds="ngModel"
                name="fireRequirementIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="fireRequirementOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let fireRequirementOptionType of fireRequirementOptionTypes | unordKeyValue"
                  [value]="fireRequirementOptionType.key"
                  [title]="fireRequirementOptionType.value.displayName"
                  [disabled]="fireRequirementOptionType.value.isDisabled"
                >{{ fireRequirementOptionType.value.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Tvätt- och sopnedkast">
              <mat-label title="Tvätt- och sopnedkast">Tvätt- och sopnedkast</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.fire.hasWashingAndGarbageChute"
                #hasWashingAndGarbageChute="ngModel"
                name="hasWashingAndGarbageChute"
              />
              <mat-radio-group
                class="d-block"
                name="hasWashingAndGarbageChuteRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.fire.hasWashingAndGarbageChute"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field floatLabel="always" class="w-100" title="Rörpost">
              <mat-label title="Rörpost">Rörpost</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.fire.hasPneumaticTube"
                #hasPneumaticTube="ngModel"
                name="hasPneumaticTube"
              />
              <mat-radio-group
                class="d-block"
                name="hasPneumaticTubeRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.fire.hasPneumaticTube"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Sektionering genom brandvägg"
              [values]="sectioningThroughFirewallTypes"
              [selectedKeys]="document.fire.sectioningThroughFirewallIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="sectioningThroughFirewallIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Sektionering genom brandvägg"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label>Sektionering genom brandvägg</mat-label>
              <mat-select
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.fire.sectioningThroughFirewallIds"
                #sectioningThroughFirewallIds="ngModel"
                name="sectioningThroughFirewallIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="sectioningThroughFirewallTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let sectioningThroughFirewallType of sectioningThroughFirewallTypes | keyvalue"
                  [value]="sectioningThroughFirewallType.key"
                  [title]="sectioningThroughFirewallType.value.displayName"
                  [disabled]="sectioningThroughFirewallType.value.isDisabled"
                  >{{ sectioningThroughFirewallType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[sectioningThroughFirewallIds.name]"-->
<!--                [class.text-secondary-primary]="notes[sectioningThroughFirewallIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[sectioningThroughFirewallIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="sectioningThroughFirewallIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Brandteknisk klass avseende brandväggar"
              [values]="fireTechnicalClassFirewallTypes"
              [selectedKeys]="document.fire.fireTechnicalClassFirewallIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="fireTechnicalClassFirewallIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Brandteknisk klass avseende brandväggar"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label>Brandteknisk klass avseende brandväggar</mat-label>

              <mat-select
                required
                [disabled]="!hasEditPermissions || !isFireTechnicalClassFirewallIdsEnabled"
                [(ngModel)]="document.fire.fireTechnicalClassFirewallIds"
                #fireTechnicalClassFirewallIds="ngModel"
                name="fireTechnicalClassFirewallIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="fireTechnicalClassFirewallTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let fireTechnicalClassFirewallType of fireTechnicalClassFirewallTypes | keyvalue"
                  [value]="fireTechnicalClassFirewallType.key"
                  [title]="fireTechnicalClassFirewallType.value.displayName"
                  [disabled]="fireTechnicalClassFirewallType.value.isDisabled"
                >{{ fireTechnicalClassFirewallType.value.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Äldre brandteknisk klass förekommer"
              [values]="olderFireTechnicalClassTypes"
              [selectedKeys]="document.fire.olderFireTechnicalClassIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="olderFireTechnicalClassIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Äldre brandteknisk klass förekommer"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label
                title="Older fire technical class occurs"
              >Äldre brandteknisk klass förekommer</mat-label
              >

              <mat-select
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.olderFireTechnicalClassIds"
                #olderFireTechnicalClassIds="ngModel"
                name="olderFireTechnicalClassIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="olderFireTechnicalClassTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let olderFireTechnicalClassType of olderFireTechnicalClassTypes | keyvalue"
                  [value]="olderFireTechnicalClassType.key"
                  [title]="olderFireTechnicalClassType.value.displayName"
                  [disabled]="olderFireTechnicalClassType.value.isDisabled"
                >{{ olderFireTechnicalClassType.value.displayName }}</mat-option
                >
              </mat-select>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[olderFireTechnicalClassIds.name]"-->
              <!--                [class.text-secondary-primary]="notes[olderFireTechnicalClassIds.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[olderFireTechnicalClassIds.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="olderFireTechnicalClassIds.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Brandcellsgränser uppfyller brandteknisk klass"
              [values]="fireCellMeetTechnicalClassTypes"
              [selectedKeys]="document.fire.fireCellMeetTechnicalClassIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="fireCellMeetTechnicalClassIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Brandcellsgränser uppfyller brandteknisk klass"
              [class.d-none]="!hasEditPermissions"
            >
              <mat-label>Brandcellsgränser uppfyller brandteknisk klass</mat-label>
              <mat-select
                required
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.fireCellMeetTechnicalClassIds"
                #fireCellMeetTechnicalClassIds="ngModel"
                name="fireCellMeetTechnicalClassIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="fireCellMeetTechnicalClassTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let fireCellMeetTechnicalClassType of fireCellMeetTechnicalClassTypes | unordKeyValue"
                  [value]="fireCellMeetTechnicalClassType.key"
                  [title]="fireCellMeetTechnicalClassType.value.displayName"
                  [disabled]="fireCellMeetTechnicalClassType.value.isDisabled"
                  >{{ fireCellMeetTechnicalClassType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[fireCellMeetTechnicalClassIds.name]"-->
<!--                [class.text-secondary-primary]="notes[fireCellMeetTechnicalClassIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[fireCellMeetTechnicalClassIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="fireCellMeetTechnicalClassIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field
              class="w-100"
              title="Beskrivning kring särskilda förutsättningar gällande ytskikt, beklädnad och/eller golvbeläggning"
            >
              <mat-label
                title="Description of special conditions regarding surface layers, cladding and / or floor coverings"
                >Beskrivning kring särskilda förutsättningar gällande ytskikt, beklädnad och/eller
                golvbeläggning</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.fireSpecialConditionsDescription"
                #fireSpecialConditionsDescription="ngModel"
                name="fireSpecialConditionsDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[fireSpecialConditionsDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[fireSpecialConditionsDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[fireSpecialConditionsDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="fireSpecialConditionsDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende brandcellsindelning">
              <mat-label
                title="Extended description regarding fire cell division"
                >Utökad beskrivning avseende brandcellsindelning</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!document.fire.isBuildingDividedIntoFireCells || !hasEditPermissions"
                [(ngModel)]="document.fire.fireExtendedDescription"
                #fireExtendedDescription="ngModel"
                name="fireExtendedDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[fireExtendedDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[fireExtendedDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[fireExtendedDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="fireExtendedDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
