<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'Projektinformation'"
    [documentId]="document?.id"
    [isCompleted]="document?.info.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Byggnadsnamn">
              <mat-label title="Building Name">Byggnadsnamn</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.info.buildingName"
                #buildingName="ngModel"
                name="buildingName"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[buildingName.name]"-->
<!--                [class.text-secondary-primary]="notes[buildingName.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[buildingName.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="buildingName.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Objektsnummer">
              <mat-label title="Object number">Objektsnummer</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.info.objectNumber"
                #objectNumber="ngModel"
                name="objectNumber"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[objectNumber.name]"-->
<!--                [class.text-secondary-primary]="notes[objectNumber.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[objectNumber.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="objectNumber.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Förvaltningsritningar brandskydd är upprättade">
              <mat-label>Förvaltningsritningar brandskydd är upprättade</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.info.isManagementDrawingFireProtectionDrawnUp"
                #isManagementDrawingFireProtectionDrawnUp="ngModel"
                name="isManagementDrawingFireProtectionDrawnUp"
              />
              <mat-radio-group
                class="d-block"
                name="isManagementDrawingFireProtectionDrawnUpRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.info.isManagementDrawingFireProtectionDrawnUp"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[isManagementDrawingFireProtectionDrawnUp.name]"-->
<!--                [class.text-secondary-primary]="notes[isManagementDrawingFireProtectionDrawnUp.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[isManagementDrawingFireProtectionDrawnUp.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="isManagementDrawingFireProtectionDrawnUp.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Utrymningsplaner är upprättade">
              <mat-label>Utrymningsplaner är upprättade</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.info.isEvacuationPlanDrawnUp"
                #isEvacuationPlanDrawnUp="ngModel"
                name="isEvacuationPlanDrawnUp"
              />
              <mat-radio-group
                class="d-block"
                name="isEvacuationPlanDrawnUpRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.info.isEvacuationPlanDrawnUp"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Insatsplaner är upprättade">
              <mat-label>Insatsplaner är upprättade</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.info.isActionPlanDrawnUp"
                #isActionPlanDrawnUp="ngModel"
                name="isActionPlanDrawnUp"
              />
              <mat-radio-group
                class="d-block"
                name="isActionPlanDrawnUpRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.info.isActionPlanDrawnUp"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field title="Datum platsbesök" class="w-100">
              <mat-label title="Site Visit Date">Datum platsbesök</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.info.siteVisitDate"
                #siteVisitDate="ngModel"
                name="siteVisitDate"
                (click)="picker.open()"
                (keyup.enter)="picker.close()"
                (change)="onFormDataChanged()"
              />
              <div matSuffix class="d-flex">
                <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
<!--                <button-->
<!--                  mat-icon-button-->
<!--                  *ngIf="hasEditPermissions || notes[siteVisitDate.name]"-->
<!--                  [class.text-secondary-primary]="notes[siteVisitDate.name]"-->
<!--                  appSectionFieldNote-->
<!--                  [ngbTooltip]="notes[siteVisitDate.name]?.note"-->
<!--                  [documentId]="document?.id"-->
<!--                  [sectionType]="sectionType"-->
<!--                  [sectionFieldId]="siteVisitDate.name"-->
<!--                  [notes]="notes"-->
<!--                  [isViewOnly]="!hasEditPermissions"-->
<!--                  tooltipClass="medium-large-size-tooltip"-->
<!--                  container="body"-->
<!--                >-->
<!--                  <mat-icon>attach_file</mat-icon>-->
<!--                </button>-->
              </div>

              <mat-datepicker #picker (closed)="onFormDataChanged()"></mat-datepicker>
            </mat-form-field>
          </div>

<!--          <div class="col-12 col-md-6">-->
<!--            <mat-form-field class="w-100" title="Granskad av">-->
<!--              <mat-label title="Reviewed by">Granskad av</mat-label>-->
<!--              <mat-select-->
<!--                [(ngModel)]="document.info.reviewerId"-->
<!--                (selectionChange)="onFormDataChanged()"-->
<!--                name="reviewerId"-->
<!--                required-->
<!--                [disabled]="!canReviewDocument"-->
<!--                #reviewerId="ngModel"-->
<!--              >-->
<!--                <mat-option *ngFor="let documentReviewer of allDocumentReviewers" [value]="documentReviewer.id">-->
<!--                  {{ documentReviewer.name }}-->
<!--                </mat-option>-->
<!--              </mat-select>-->
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[reviewerId.name]"-->
<!--                [class.text-secondary-primary]="notes[reviewerId.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[reviewerId.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="reviewerId.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!canReviewDocument"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
<!--            </mat-form-field>-->
<!--          </div>-->
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Fastighetsägare">
              <mat-label title="Property Owners">Fastighetsägare</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.info.propertyOwner"
                #propertyOwner="ngModel"
                name="propertyOwner"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[propertyOwner.name]"-->
<!--                [class.text-secondary-primary]="notes[propertyOwner.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[propertyOwner.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="propertyOwner.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Dokumentstatus"
              [values]="documentStatusTypes"
              [selectedKeys]="[document.info.documentStatusId]"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="documentStatusId"
            ></app-multiselect-readonly-view>

            <mat-form-field class="w-100" title="Dokumentstatus" floatLabel="always" *ngIf="hasEditPermissions">
              <mat-label title="Document status">Dokumentstatus</mat-label>
              <mat-select
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.info.documentStatusId"
                #documentStatusId="ngModel"
                name="documentStatusId"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let documentStatusType of documentStatusTypes | keyvalue"
                  [value]="documentStatusType.key"
                  [title]="documentStatusType.value.displayName"
                  >{{ documentStatusType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[documentStatusId.name]"-->
<!--                [class.text-secondary-primary]="notes[documentStatusId.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[documentStatusId.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="documentStatusId.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
