import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OrganizationApiService } from '../organization-api.service';
import { Organization } from '../shared';
import { MatOptionSelectionChange } from '@angular/material/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-select-organization-modal',
  templateUrl: './select-organization-modal.component.html',
  styleUrls: ['./select-organization-modal.component.scss'],
})
export class SelectOrganizationModalComponent implements OnInit {
  @ViewChild('selectOrganizationForm')
  selectOrganizationForm: NgForm;

  organizations: Organization[];
  organizationName: string | Organization;
  filteredOrganizations: Organization[] = [];
  selectedOrganization: Organization;

  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  get canSubmitForm(): boolean {
    if (!this.selectOrganizationForm)
      return false;

    return !this.state.isLoading && this.selectOrganizationForm.valid;
  }

  constructor(
    private organizationApiService: OrganizationApiService,
    private dialogRef: MatDialogRef<SelectOrganizationModalComponent>
  ) {
    this.state = {
      isLoading: false,
      isFailed: false,
    };

    this.organizations = [];
    this.selectedOrganization = null;
  }

  ngOnInit(): void {
    this.loadOrganizations();
  }

  onSelect(): void {
    if (!this.canSubmitForm)
      return;

    this.dialogRef.close(this.selectedOrganization);
  }

  filterOrganizations() {
    // this.organizationName could be Organization type when selects an organization from the list
    if (typeof this.organizationName !== 'string')
      return;

    const name = this.organizationName.toLowerCase();
    this.filteredOrganizations = this.organizations.filter((organizationItem: Organization) => organizationItem.name.toLowerCase().includes(name));

    this.selectedOrganization = null;
    this.selectOrganizationForm.controls['organizationId'].setErrors({ required: true });
  }

  organizationDisplayFn(organization: Organization): string {
    return organization ? organization.name : '';
  }

  onOrganizationSelectionChange(event: MatOptionSelectionChange) {
    this.selectedOrganization = event.source.value;
  }

  private async loadOrganizations() {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      this.organizations = await this.organizationApiService.getOrganizations();
      this.filteredOrganizations = this.organizations.slice()
        .sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }
}
