import { v4 as uuid } from 'uuid';
import { DocumentAirTreatment } from './document-airtreatment';
import { DocumentBuilding } from './document-building';
import { DocumentCapacity } from './document-capacity';
import { DocumentEvacuation } from './document-evacuation';
import { DocumentFire } from './document-fire';
import { DocumentInfo } from './document-info';
import { DocumentInspection } from './document-inspection';
import { DocumentInstallations } from './document-installations';
import { DocumentLifts } from './document-lifts';
import { DocumentObject } from './document-object';
import { DocumentOpportunities } from './document-opportunities';
import { DocumentProtectedObjects } from './document-protected-objects';
import { DocumentRisks } from './document-risks';
import { DocumentSpread } from './document-spread';
import { Moment } from 'moment';

export interface IDocumentItem {
  isValid: boolean;
  isCompleted: boolean;
}

export class Document {
  readonly id: uuid;
  readonly organizationId: uuid;
  readonly organizationName: string;
  readonly creatorId: string;
  readonly creatorName: string;
  readonly creatorOrganizationId: string | null;
  readonly reviserId?: string;
  readonly reviserName?: string;
  readonly reviserOrganizationId?: string | null;
  isPublished?: boolean;
  readonly createdDate: Moment;
  readonly updatedDate: Moment;
  readonly revisedDate: Moment;
  deletedDate?: Moment;

  readonly info: DocumentInfo;
  readonly object: DocumentObject;
  readonly building: DocumentBuilding;
  readonly evacuation: DocumentEvacuation;
  readonly fire: DocumentFire;
  readonly spread: DocumentSpread;
  readonly capacity: DocumentCapacity;
  readonly installations: DocumentInstallations;
  readonly airtreatment: DocumentAirTreatment;
  readonly lifts: DocumentLifts;
  readonly opportunities: DocumentOpportunities;
  readonly risks: DocumentRisks;
  readonly protectedObjects: DocumentProtectedObjects;
  readonly inspection: DocumentInspection;

  get isCompleted(): boolean {
    const isCompleted =
      this.info.isCompleted &&
      this.object.isCompleted &&
      this.building.isCompleted &&
      this.evacuation.isCompleted &&
      this.fire.isCompleted &&
      this.spread.isCompleted &&
      this.capacity.isCompleted &&
      this.installations.isCompleted &&
      this.airtreatment.isCompleted &&
      this.lifts.isCompleted &&
      this.opportunities.isCompleted &&
      this.risks.isCompleted &&
      this.protectedObjects.isCompleted &&
      this.inspection.isCompleted;

    return isCompleted;
  }

  constructor({
    id,
    organizationId,
    organizationName,
    creatorId,
    creatorName,
    creatorOrganizationId,
    reviserId,
    reviserName,
    reviserOrganizationId,
    isPublished,
    createdDate,
    updatedDate,
    revisedDate,
    deletedDate,
    info,
    object,
    building,
    evacuation,
    fire,
    spread,
    capacity,
    installations,
    airtreatment,
    lifts,
    opportunities,
    risks,
    protectedObjects,
    inspection,
  }: Partial<Document> = {}) {
    this.id = id;
    this.organizationId = organizationId;
    this.organizationName = organizationName;
    this.creatorId = creatorId;
    this.creatorName = creatorName;
    this.creatorOrganizationId = creatorOrganizationId;
    this.reviserId = reviserId;
    this.reviserName = reviserName;
    this.reviserOrganizationId = reviserOrganizationId;
    this.isPublished = isPublished;
    this.createdDate = createdDate;
    this.updatedDate = updatedDate;
    this.revisedDate = revisedDate;
    this.deletedDate = deletedDate;

    this.info = info || null;
    this.object = object || null;
    this.building = building || null;
    this.evacuation = evacuation || null;
    this.fire = fire || null;
    this.spread = spread || null;
    this.capacity = capacity || null;
    this.installations = installations || null;
    this.airtreatment = airtreatment || null;
    this.lifts = lifts || null;
    this.opportunities = opportunities || null;
    this.risks = risks || null;
    this.protectedObjects = protectedObjects || null;
    this.inspection = inspection || null;
  }
}
