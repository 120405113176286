import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator } from '@angular/forms';
import { forbiddenCharactersValidator } from './forbidden-characters.validator';
import { validate, required } from 'src/app/shared';

@Directive({
  selector: '[appForbiddenCharacters]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ForbiddenCharactersValidatorDirective, multi: true }],
})
export class ForbiddenCharactersValidatorDirective implements Validator {
  @Input('appForbiddenCharacters')
  forbiddenCharacters: string[];

  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    const validator = forbiddenCharactersValidator(this.forbiddenCharacters);

    const result = validator(control);

    return result;
  }
}
