import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Assert } from 'src/app/shared';

export const emailWithSwedishCharactersValidationKey = 'emailWithSwedishCharacters';

export function emailWithSwedishCharactersValidator(): ValidatorFn {
  const validationPattern = new RegExp(
    `[A-Za-z_\u00C4\u00E4\u00C5\u00E5\u00D6\u00F60-9._%+-]+@[A-Za-z_\u00C4\u00E4\u00C5\u00E5\u00D6\u00F60-9.-]+\.[A-Za-z]{2,3}$`,
    'i'
  );

  const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    Assert.isNotNull(control, 'control');

    const isValid = validationPattern.test(control.value);
    return !isValid ? { [emailWithSwedishCharactersValidationKey]: true } : null;
  };

  return validator;
}
