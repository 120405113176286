export class PasswordCharacters {
  readonly lowercase: string;
  readonly uppercase: string;
  readonly numbers: string;
  readonly specials: string;

  constructor({ lowercase, uppercase, numbers, specials }: Partial<PasswordCharacters> = {}) {
    this.lowercase = lowercase || '';
    this.uppercase = uppercase || '';
    this.numbers = numbers || '';
    this.specials = specials || '';
  }
}
