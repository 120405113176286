import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationHttpInterceptor } from './authentication-http.interceptor';
import { FormsModule } from '@angular/forms';
import { AuthenticationStoreModule } from './authentication-store';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { AppCurrentUserNameComponent } from './app-current-user-name/app-current-user-name.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [LoginPageComponent, LoginFormComponent, AppCurrentUserNameComponent],
  imports: [
    CommonModule,
    FormsModule,
    AuthenticationStoreModule,
    CoreModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationHttpInterceptor,
      multi: true,
    },
  ],
  exports: [LoginPageComponent, AppCurrentUserNameComponent]
})
export class AuthenticationModule {}
