import { Action } from '@ngrx/store';
import { Assert } from 'src/app/shared';
import { AuthDetails } from '../shared/auth-details';
import { UserRole } from '../shared/user-role';

export enum AuthenticationActionType {
  Authenticate = '[Authentication] Authenticate',
  ExpireAuthentication = '[Authentication] ExpireAuthentication',
  SetReturnUrl = '[Authentication] SetReturnUrl',
  UpdateUserName = '[Authentication] UpdateUserName',
  UpdateUserRole = '[Authentication] UpdateUserRole',
}

export class AuthenticateAction implements Action {
  readonly type = AuthenticationActionType.Authenticate;

  readonly authDetails: AuthDetails;

  constructor(authDetails: AuthDetails) {
    Assert.isNotNull(authDetails, 'authDetails');
    this.authDetails = authDetails;
  }
}

export class ExpireAuthenticationAction implements Action {
  readonly type = AuthenticationActionType.ExpireAuthentication;
}

export class SetReturnUrlAction implements Action {
  readonly type = AuthenticationActionType.SetReturnUrl;

  readonly returnUrl: string;

  constructor(returnUrl: string) {
    this.returnUrl = returnUrl;
  }
}

export class UpdateUserNameAction implements Action {
  readonly type = AuthenticationActionType.UpdateUserName;
  readonly username: string;

  constructor(username: string) {
    Assert.isNotNull(username, 'username');
    this.username = username;
  }
}

export class UpdateUserRoleAction implements Action {
  readonly type = AuthenticationActionType.UpdateUserRole;
  readonly role: UserRole;

  constructor(role: UserRole) {
    Assert.isNotNull(role, 'role');
    this.role = role;
  }
}

export type AuthenticationActions =
  | AuthenticateAction
  | ExpireAuthenticationAction
  | SetReturnUrlAction
  | UpdateUserNameAction
  | UpdateUserRoleAction;
