<div class="card card-form">
  <div class="card-body">
    <div class="row">
      <div class="col">
        <div class="logo-container text-center mb-4">
          <img src="assets/images/logo.png" width="200" alt="Logo" title="Logo" />
        </div>
        <form #loginForm="ngForm" novalidate autocomplete="on">
          <mat-form-field class="w-100">
            <mat-label>Användarnamn</mat-label>
            <input
              matInput
              title="Användarnamn"
              required
              [(ngModel)]="credentials.username"
              #username="ngModel"
              name="username"
              [disabled]="state.isLoading"
              autocomplete="username"
            />
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label>Lösenord</mat-label>
            <input
              type="password"
              matInput
              title="Lösenord"
              required
              [(ngModel)]="credentials.password"
              #password="ngModel"
              name="password"
              [disabled]="state.isLoading"
              autocomplete="current-password"
            />
          </mat-form-field>

          <button
            class="btn btn-lg btn-sign-in btn-block"
            type="submit"
            [disabled]="loginForm.invalid || state.isLoading"
            (click)="onLogin()"
            title="Logga in"
          >
            Logga in
          </button>
        </form>
      </div>
    </div>
    <div class="row mt-4" *ngIf="state.authError">
      <div class="col">
        <div class="alert alert-danger text-center">Fel användarnamn eller lösenord.</div>
      </div>
    </div>
  </div>
</div>
