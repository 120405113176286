<tr class="users-table-row">
  <td>
    <div class="data-table-title-column-container">
      <div [title]="item.user.firstName">{{ item.user.firstName }} {{ item.user.lastName }}</div>
    </div>
  </td>

  <td class="no-break">
    {{ item.user.email }}
  </td>

  <td>
    {{ getUserRoleName(item.role) }}
  </td>

  <td>
    <div class="d-flex">
      <button
        mat-button
        matSuffix
        mat-icon-button
        class="mr-1"
        [class.disabled-element]="isDisabled"
        appResetPasswordTrigger
        [user]="item.user"
        title="Återställ lösenord"
      >
        <mat-icon>vpn_key</mat-icon>
      </button>
      <button
        mat-button
        matSuffix
        mat-icon-button
        class="mr-1"
        [class.disabled-element]="isDisabled"
        appEditUserTrigger
        [organizationId]="organizationId"
        [currentUserAuthDetails]="currentUserAuthDetails"
        [isEditMode]="true"
        [editedUserDetails]="item"
        [usersType]="usersType"
        (edited)="onUserEdited($event)"
        title="Redigera användare"
      >
        <mat-icon>edit</mat-icon>
      </button>

      <button
        mat-button
        matSuffix
        mat-icon-button
        class="mr-1 text-danger"
        [class.disabled-element]="isDisabled"
        (click)="onDeleteUser(item)"
        title="Ta bort användare"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </td>
</tr>
