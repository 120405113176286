export class Permission {
  id: string;
  name: string;
  organizationId: string;
  documentIds: string[];
  userIds: string[];

  constructor({ id, name, organizationId, documentIds, userIds }: Partial<Permission> = {}) {
    this.id = id || null;
    this.name = name || null;
    this.organizationId = organizationId || null;
    this.documentIds = documentIds || null;
    this.userIds = userIds || [];
  }
}
