<h1 mat-dialog-title>Återställ lösenord för {{ user.firstName }} {{ user.lastName }}</h1>
<div mat-dialog-content class="reset-password-modal">
  <div class="modal-body">
    <div>
      Ett nytt lösenord genereras och skickas till användarens e-post <b>{{ user.email }}</b>.
    </div>
  </div>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-raised-button (click)="onCancel()">Avbryt</button>
  <button
    mat-raised-button
    [disabled]="state.isLoading"
    class="reset-btn"
    (click)="onResetPassword()"
    cdkFocusInitial
  >
    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="state.isLoading"></i>
    <ng-container>Återställ</ng-container>
  </button>
</div>
