export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const baseApiUrl = getBaseUrl();
const apiUrlSection = 'api';
const apiVersion = 'v1';

const baseUrl = `${baseApiUrl}${apiUrlSection}`;

export const appConfig = {
  api: {
    version: apiVersion,
    baseUrl: baseUrl,
  },
  routes: {
    home: '/',
  },
  renewUntilSessionExpiredSeconds: 60,
  locale: 'sv-SE',
};
