import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { requireToMatchValidator } from './require-to-match.validator';
import { required, validate } from 'src/app/shared';

@Directive({
  selector: '[appRequireToMatch]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RequireToMatchValidatorDirective, multi: true }],
})
export class RequireToMatchValidatorDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('requireToMatchIgnoreCase')
  ignoreCase: boolean;

  @Input('appRequireToMatch')
  controls: string[] | AbstractControl[];

  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    const validator = requireToMatchValidator(this.ignoreCase, this.controls);

    const result = validator(control);

    return result;
  }
}
