<header class="sticky-top tab-top-header">
  <div class="d-flex justify-content-between">
    <h6 class="text-uppercase mb-0 mt-2 ml-3">
      <span>{{ header }}</span
      ><button
        mat-icon-button
        matSuffix
        *ngIf="notes && notes[generalSectionField]"
        appSectionFieldNote
        [ngbTooltip]="notes[generalSectionField]?.note"
        [documentId]="documentId"
        [sectionType]="sectionType"
        [sectionFieldId]="generalSectionField"
        [notes]="notes"
        [isViewOnly]="disabled"
        tooltipClass="medium-large-size-tooltip"
        container="body"
        class="general-note text-secondary-primary"
      >
        <mat-icon>attach_file</mat-icon>
      </button>
    </h6>

    <div>
      <button mat-icon-button (click)="onPreviousPage()" *ngIf="previousPageName" title="Gå tillbaka">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <button mat-icon-button (click)="onNextPage()" *ngIf="nextPageName" title="Gå till nästa">
        <mat-icon>arrow_forward</mat-icon>
      </button>
      <a href="/api/report/{{ documentId }}" mat-icon-button target="_blank" title="Exportera rapport">
        <mat-icon fontSet="fas" fontIcon="fa-file-word" class="text-large"></mat-icon>
      </a>
<!--      <button-->
<!--        mat-icon-button-->
<!--        appSectionFieldNote-->
<!--        title="Noteringar"-->
<!--        [documentId]="documentId"-->
<!--        [sectionType]="sectionType"-->
<!--        [notes]="notes"-->
<!--        [isViewOnly]="disabled"-->
<!--        [disabled]="disabled"-->
<!--      >-->
<!--        <mat-icon>description</mat-icon>-->
<!--      </button>-->
      <button mat-icon-button (click)="onPublish(!isPublished)" [disabled]="disabled || !isPublishEnabled" [attr.title]="publishTitleText">
        <mat-icon [class.text-valid]="isPublished">publish</mat-icon>
      </button>
      <button mat-icon-button (click)="onSwitchComplete()" [disabled]="disabled" title="Klarmarkera sektion">
        <mat-icon [class.text-valid]="isCompleted">check_circle</mat-icon>
      </button>
    </div>
  </div>
  <hr />
</header>
