import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { requireSpecialCharacterValidator } from './require-special-character.validator';
import { required, validate } from 'src/app/shared';

@Directive({
  selector: '[appRequireSpecialCharacter]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RequireSpecialCharacterValidatorDirective, multi: true }],
})
export class RequireSpecialCharacterValidatorDirective {
  @Input('appRequireSpecialCharacter')
  specialCharacters: string[];

  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    const validator = requireSpecialCharacterValidator(this.specialCharacters);

    const result = validator(control);

    return result;
  }
}
