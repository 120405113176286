import { Permission } from '../authentication/shared/permission';

export const permissionGroupsConfig = {
  delete: {
    confirmationText: 'delete',
  },
  filter: {
    searchableFields: ['name'] as FieldsOf<Permission>,
  },
};
