<div class="d-flex">
  <div class="flex-grow-1 mr-3">
    <mat-form-field class="w-100" appearance="fill">
      <mat-label title="field">Fält</mat-label>
      <input
        type="text"
        matInput
        (input)="onFilterChanged($event.target.value)"
        (focus)="onFilterChanged('')"
        [(ngModel)]="data.fieldText"
        [matAutocomplete]="autoGroup"
        autocomplete="off"
      />
      <button
        mat-button
        [class.invisible]="!data.fieldText"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="onClearSelectedField()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #autoGroup="matAutocomplete">
        <mat-optgroup *ngFor="let section of data.filteredSections" class="filter-mat-optgroup" [label]="section.displayName">
          <mat-option
            *ngFor="let field of section.fields"
            [title]="field.displayName"
            [class.mat-active]="field === data.selectedField"
            [value]="field.displayName"
            (onSelectionChange)="onFieldSelected(section, field, $event)"
          >
            {{ field.displayName }}
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="flex-grow-1 mr-3 d-flex">
    <!-- String -->
    <ng-container
      *ngIf="
        !data.selectedField ||
        data.selectedField?.type === DocumentFieldType.string ||
        data.selectedField?.type === DocumentFieldType.extinguishingSystems ||
        data.selectedField?.type === DocumentFieldType.renovation
      "
    >
      <mat-form-field class="w-100" title="Värde" appearance="fill">
        <mat-label title="Value">Värde</mat-label>
        <input
          matInput
          [disabled]="!data.selectedField"
          [(ngModel)]="data.selectedValue"
          (ngModelChange)="onValueChanged()"
          autocomplete="off"
        />
        <button
          mat-button
          [class.invisible]="!data.selectedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onClearSelectedValue()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </ng-container>

    <!-- Number -->
    <ng-container *ngIf="data.selectedField && data.selectedField.type === DocumentFieldType.number">
      <mat-form-field appearance="fill">
        <mat-select [(ngModel)]="data.selectedPredicate" (ngModelChange)="onValueChanged()">
          <mat-option [value]="FilterPredicate.Less">Mindre eller lika med</mat-option>
          <mat-option [value]="FilterPredicate.Greater">Större eller lika med</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field title="Värde" appearance="fill" class="flex-grow-1 ml-2">
        <mat-label title="Value">Värde</mat-label>
        <input
          matInput
          type="text"
          appFloatDigitOnly
          [(ngModel)]="data.selectedValue"
          (ngModelChange)="onValueChanged()"
          autocomplete="off"
        />
        <button
          mat-button
          [class.invisible]="!data.selectedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onClearSelectedValue()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </ng-container>

    <!-- Boolean -->
    <ng-container *ngIf="data.selectedField && data.selectedField.type === DocumentFieldType.boolean">
      <mat-form-field class="w-100" title="Värde" appearance="fill">
        <mat-label title="Value">Värde</mat-label>
        <mat-select [(ngModel)]="data.selectedValue" (ngModelChange)="onValueChanged()">
          <mat-option [value]="true">Ja</mat-option>
          <mat-option [value]="false">Nej</mat-option>
        </mat-select>
        <button
          mat-button
          [class.invisible]="!data.selectedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onClearSelectedValue()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </ng-container>

    <!-- Dropdown -->
    <ng-container *ngIf="data.selectedField && data.selectedField.type === DocumentFieldType.dropdown">
      <mat-form-field class="w-100" title="Värde" appearance="fill">
        <mat-label title="Value">Värde</mat-label>
        <mat-select [(ngModel)]="data.selectedValue" (ngModelChange)="onValueChanged()">
          <mat-option
            *ngFor="let sectionType of data.selectedField.values | unordKeyValue"
            [value]="sectionType.key"
            [title]="sectionType.value.displayName"
            [innerHtml]="sectionType.value.displayName"
          ></mat-option>
        </mat-select>
        <button
          mat-button
          [class.invisible]="!data.selectedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onClearSelectedValue()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </ng-container>

    <!-- GroupDropdown -->
    <ng-container *ngIf="data.selectedField && data.selectedField.type === DocumentFieldType.groupDropdown">
      <mat-form-field class="w-100" title="Värde" appearance="fill">
        <mat-label title="Value">Värde</mat-label>
        <mat-select [(ngModel)]="data.selectedValue" (ngModelChange)="onValueChanged()">
          <mat-optgroup
            *ngFor="let sectionType of data.selectedField.values | unordKeyValue"
            [label]="sectionType.key"
            class="filter-group-dropdown-mat-optgroup">
              <mat-option
                *ngFor="let selectOptionType of sectionType.value.options"
                [value]="selectOptionType.id"
                [title]="selectOptionType.displayName"
                [innerHtml]="selectOptionType.displayName"
              ></mat-option>
          </mat-optgroup>
        </mat-select>
        <button
          mat-button
          [class.invisible]="!data.selectedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onClearSelectedValue()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </ng-container>

    <!-- Multiselect -->
    <ng-container *ngIf="data.selectedField && data.selectedField.type === DocumentFieldType.multiple">
      <mat-form-field class="w-100" title="Värde" appearance="fill">
        <mat-label title="Value">Värde</mat-label>
        <mat-select [(ngModel)]="data.selectedValue" multiple (ngModelChange)="onValueChanged()">
          <mat-option
            *ngFor="let sectionType of data.selectedField.values | unordKeyValue"
            [value]="sectionType.key"
            [title]="sectionType.value.displayName"
            >{{ sectionType.value.displayName }}</mat-option
          >
        </mat-select>
        <button
          mat-button
          [class.invisible]="!data.selectedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onClearSelectedValue()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </ng-container>

    <!-- Date -->
    <ng-container *ngIf="data.selectedField && data.selectedField.type === DocumentFieldType.date">
      <mat-form-field class="w-100" title="Värde" appearance="fill">
        <mat-label title="Value">Värde</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="data.selectedValue"
          (click)="picker.open()"
          (keyup.enter)="picker.close()"
          (change)="onValueChanged()"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker (closed)="onValueChanged()"></mat-datepicker>
      </mat-form-field>
    </ng-container>
  </div>

  <div class="mt-2 pt-1">
    <button mat-button mat-icon-button aria-label="Clear" color="warn" (click)="onDeleteRow()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
