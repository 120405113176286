<div mat-dialog-content>
  <form #updateOrganizationForm="ngForm" novalidate>
    <div class="modal-body pl-0 pt-4" [class.overlay-loader]="state.isLoading" *ngIf="!state.isFailed">
      <mat-form-field class="w-100">
        <mat-label>Organisations namn</mat-label>
        <input
          matInput
          required
          [disabled]="state.isLoading"
          id="name"
          [(ngModel)]="name"
          #organizationName="ngModel"
          name="name"
          [appAllowedCharacters]="nameAllowedCharacters"
          [maxlength]="nameMaxLength"
          [ngClass]="{ 'is-invalid': organizationName.dirty && organizationName.invalid }"
          placeholder="Organisations namn"
        />
        <mat-error *ngIf="organizationName.errors">
          <div *ngIf="!!organizationName.errors?.required">Ange ett organisationsnamn.</div>

          <div *ngIf="!!organizationName.errors?.maxlength">Får inte vara mer än {{ nameMaxLength }} tecken.</div>

          <div *ngIf="!!organizationName.errors?.unique">Organisation med samma namn finns redan.</div>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="modal-body" *ngIf="state.isFailed">
      <div class="text-center m-4">
        <ng-container>Det gick inte att ladda organisationen.</ng-container>
        <div class="mt-2">
          <ng-container>Click</ng-container
          ><button
            type="button"
            (click)="onTryLoadOrganization()"
            class="btn btn-sm btn-outline-primary mx-1"
            title="Uppdatera"
          >
            <i class="fas fa-sync-alt mr-1"></i><ng-container>Uppdatera</ng-container></button
          ><ng-container>att försöka igen.</ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-raised-button (click)="onCancel()">Avbryt</button>
  <button
    class="activate-btn"
    mat-raised-button
    (click)="onUpdate()"
    cdkFocusInitial
    [disabled]="
      updateOrganizationForm.invalid ||
      updateOrganizationForm.pristine ||
      state.isLoading ||
      state.isFailed ||
      state.isUpdating
    "
  >
    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="state.isUpdating"></i><ng-container>Spara</ng-container>
  </button>
</div>
