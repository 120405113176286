import { Directive, Input, HostListener } from '@angular/core';
import { NgModel, AbstractControl } from '@angular/forms';
import { each, isArray } from 'lodash';

@Directive({
  selector: '[appResetValuesIfFalse]',
  providers: [NgModel],
})
export class ResetValuesIfFalseDirective {
  @Input('appResetValuesIfFalse')
  controls: AbstractControl[];

  @HostListener('ngModelChange', ['$event'])
  onModelChange(value: boolean) {
    if (value === false) {
      each(this.controls, (control: AbstractControl) => {
        if (!control) {
          return;
        }
        isArray(control.value) ? control.setValue([]) : control.setValue(undefined);
      });
    }
  }
}
