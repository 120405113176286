import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { required, validate } from 'src/app/shared';
import { Organization } from '../shared';

@Component({
  selector: 'app-create-organization-modal',
  templateUrl: './create-organization-modal.component.html',
  styleUrls: ['./create-organization-modal.component.scss'],
})
export class CreateOrganizationModalComponent {
  constructor(
    public dialogRef: MatDialogRef<CreateOrganizationModalComponent>
  ) {}

  onCanceled() {
    this.dialogRef.close(null);
  }

  @validate
  onCreated(@required organization: Organization) {
    this.dialogRef.close(organization);
  }
}
