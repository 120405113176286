import {
  AddDocumentAction,
  DeleteDocumentAction,
  DocumentsActions,
  DocumentsActionType,
  ResetDocumentsAction,
} from './documents.actions';
import { Assert } from 'src/app/shared';
import { cloneDeep, omit, union, without } from 'lodash';
import { Document } from '../shared';

export interface DocumentsState {
  allIds: string[];
  byId: StringMap<Document>;
}

export const documentsInitialState: DocumentsState = {
  allIds: [],
  byId: {},
};

export function documentsReducer(state: DocumentsState, action: DocumentsActions): DocumentsState {
  Assert.isNotNull(action, 'action');

  switch (action.type) {
    case DocumentsActionType.Add:
      return addDocumentActionReducer(state, action);
    case DocumentsActionType.Delete:
      return deleteDocumentActionReducer(state, action);
    case DocumentsActionType.Reset:
      return resetDocumentsActionReducer(state, action);

    default:
      return state;
  }
}

export function addDocumentActionReducer(state: DocumentsState, action: AddDocumentAction): DocumentsState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const newState: DocumentsState = {
    ...state,
    allIds: union(state.allIds, [action.item.id]),
    byId: {
      ...state.byId,
      [action.item.id]: action.item,
    },
  };

  return newState;
}

export function deleteDocumentActionReducer(state: DocumentsState, action: DeleteDocumentAction): DocumentsState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const newState: DocumentsState = {
    allIds: without(state.allIds, action.itemId),
    byId: omit(state.byId, action.itemId),
  };

  return newState;
}

export function resetDocumentsActionReducer(state: DocumentsState, action: ResetDocumentsAction): DocumentsState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const newState: DocumentsState = cloneDeep(documentsInitialState);

  return newState;
}
