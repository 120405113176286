<h1 mat-dialog-title>
  <ng-container *ngIf="!isEditMode && usersType === UsersType.InternalUsers">Skapa ny intern användare</ng-container>
  <ng-container *ngIf="!isEditMode && usersType !== UsersType.InternalUsers">Skapa ny användare</ng-container>
  <ng-container *ngIf="isEditMode">Redigera användare</ng-container>
</h1>
<ng-container>
  <app-edit-user-form
    [organizationId]="organizationId"
    [currentUserAuthDetails]="currentUserAuthDetails"
    [isEditMode]="isEditMode"
    [usersType]="usersType"
    [editedUserDetails]="editedUserDetails"
    (userCreated)="onUserCreated($event)"
    (userEdited)="onUserEdited($event)"
    (cancel)="onCancel()"
  ></app-edit-user-form>
</ng-container>
