import { Injectable } from '@angular/core';
import {
  Document, DocumentAirTreatmentFireGasesProtectionOptionTypes,
  DocumentBuildingFireTechnicalBuildingOptionTypes,
  DocumentBuildingOccuredActivityOptionTypes,
  DocumentKeyof,
  DocumentRisksSpecialRiskOptionTypes
} from '../documents/shared';

type UpdateProperty = {
  newValue: any,
  propertyPath: DocumentKeyof[] | DocumentKeyof[][]
};

type UpdateRule = {
  predicate: (document: Document) => boolean,
  properties: UpdateProperty[],
};

@Injectable({
  providedIn: 'root'
})
export class DocumentUpdateValuesRuleService {

  private readonly buildingRules: UpdateRule[] = [
    {
      predicate: (document: Document) => document.building.fireTechnicalBuildingClassId !== DocumentBuildingFireTechnicalBuildingOptionTypes.br0Id,
      properties: [
        {
          newValue: null,
          propertyPath: ['building', 'isBr0AnalysisEstablished']
        }
      ]
    },
    {
      predicate: (document: Document) => !Boolean(document.building.hasAtticFloor),
      properties: [
        {
          newValue: null,
          propertyPath: ['fire', 'hasFireCellInAttic']
        },
        {
          newValue: null,
          propertyPath: ['opportunities', 'windAccessId'],
        }
      ],
    },
    {
      predicate: (document: Document) => document.building.hasBasementFloor === false,
      properties: [
        {
          newValue: null,
          propertyPath: [
            ['building', 'isConsideredBasementFloorPlan'],
            ['building', 'hasSeveralBasementFloors']
          ]
        }
      ]
    },
    {
      predicate: (document: Document) => document.building.hasAtticFloor === false,
      properties: [
        {
          newValue: null,
          propertyPath: ['building', 'isConsideredAtticFloorPlan']
        }
      ]
    },
    {
      predicate: (document: Document) => document.building.hasEntresolplan === false,
      properties: [
        {
          newValue: null,
          propertyPath: [
            ['building', 'isConsideredEntresolplan'],
            ['building', 'isConsideredSmallerEntresolplan'],
          ]
        }
      ]
    },
    {
      predicate: (document: Document) => document.building.hasSouterrainDesign === false,
      properties: [
        {
          newValue: null,
          propertyPath: ['building', 'isConsideredSouterrainDesignPlan']
        }
      ]
    },
    {
      predicate: (document: Document) => document.building.hasCulvert === false,
      properties: [
        {
          newValue: [],
          propertyPath: ['building', 'culvertTypeIds']
        }
      ]
    },
    {
      predicate: (document: Document) => !document.building.occuredBuildingActivityIds.some((activityType: string) => activityType === DocumentBuildingOccuredActivityOptionTypes.vk5cId),
      properties: [
        {
          newValue: null,
          propertyPath: [
            ['evacuation', 'isMattressEvacuationOfBedriddenPatients'],
            ['opportunities', 'hasSpecialRequirementsForMaxDistanceOf50M']
          ],
        }
      ]
    },
  ];

  private readonly evacuationRules: UpdateRule[] = [
    {
      predicate: (document: Document) => document.evacuation.isEvacuationPlansInBuilding === false,
      properties: [
        {
          newValue: null,
          propertyPath: ['evacuation', 'isImageRequirementsAccordingVk4'],
        }
      ]
    },
    {
      predicate: (document: Document) => document.evacuation.isEvacuationForDisabilitiesPeople === false,
      properties: [
        {
          newValue: null,
          propertyPath: ['evacuation', 'isCommunicationEquipmentEvacuation'],
        }
      ]
    },
    {
      predicate: (document: Document) => document.evacuation.isEvacuationViaTr1 === false,
      properties: [
        {
          newValue: null,
          propertyPath: ['evacuation', 'isOnlyEscapeRouteViaTr1'],
        }
      ]
    }
  ];

  private readonly airTreatmentRules: UpdateRule[] = [
    {
      predicate: (document: Document) => !document.airtreatment.fireGasesProtectionOptionIds.some(id => id === DocumentAirTreatmentFireGasesProtectionOptionTypes.pressureReliefOfDuctSystemId),
      properties: [
        {
          newValue: null,
          propertyPath: ['airtreatment', 'isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs']
        }
      ]
    }
  ];

  private readonly opportunitiesRules: UpdateRule[] = [
    {
      predicate: (document: Document) => !Boolean(document.opportunities.isKeyAccess),
      properties: [
        {
          newValue: null,
          propertyPath: ['opportunities', 'isKeyStoringOnObject']
        },
      ]
    }
  ];

  private readonly risksRules: UpdateRule[] = [
    {
      predicate: (document: Document) => document.risks.specialRiskTypeIds.some(riskTypeId => riskTypeId === DocumentRisksSpecialRiskOptionTypes.notApplicableId),
      properties: [
        {
          newValue: false,
          propertyPath: ['inspection', 'hasSpecialRisks']
        }
      ]
    },
    {
      predicate: (document: Document) => document.risks.specialRiskTypeIds.some(riskTypeId => riskTypeId !== DocumentRisksSpecialRiskOptionTypes.notApplicableId),
      properties: [
        {
          newValue: true,
          propertyPath: ['inspection', 'hasSpecialRisks']
        }
      ]
    },
    {
      predicate: (document: Document) => !document.risks.specialRiskTypeIds.some(id => id === DocumentRisksSpecialRiskOptionTypes.cookingDeviceId),
      properties: [
        {
          newValue: null,
          propertyPath: ['risks', 'hasTimerOrStoveGuard'],
        }
      ]
    }
  ];

  processBuildingRules(document: Document) {
    this.processRules(document, this.buildingRules);
  }

  processEvacuationRules(document: Document) {
    this.processRules(document, this.evacuationRules);
  }

  processAirTreatmentRules(document: Document) {
    this.processRules(document, this.airTreatmentRules);
  }

  processOpportunitiesRules(document: Document) {
    this.processRules(document, this.opportunitiesRules);
  }

  processRisksRules(document: Document) {
    this.processRules(document, this.risksRules);
  }

  private processRules(document: Document, updateRules: UpdateRule[]) {
    const updateDocumentProperty = (propertyPath: DocumentKeyof[], updateValue: any) => {
      const lastIndex = propertyPath.length - 1;
      const propertyToUpdate = propertyPath[lastIndex];
      const documentProperty = propertyPath.slice(0, lastIndex)
        .reduce((property: Document | any, propertyName: string) => property[propertyName], document);

      // documentProperty should be an object
      documentProperty[propertyToUpdate] = updateValue;
    }

    for (const rule of updateRules) {
      if (!rule.predicate(document))
        continue;

      for (const updateProperty of rule.properties) {
        if (Array.isArray(updateProperty.propertyPath[0])) {
          for (const propertyPath of updateProperty.propertyPath as DocumentKeyof[][]) {
            updateDocumentProperty(propertyPath, updateProperty.newValue);
          }
        } else {
          updateDocumentProperty(updateProperty.propertyPath as DocumentKeyof[], updateProperty.newValue);
        }
      }
    }
  }
}
