import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Assert } from 'src/app/shared';

export const allowedCharactersValidationKey = 'allowedCharacters';

export function allowedCharactersValidator(allowedCharacters: string[]): ValidatorFn {
  Assert.isNotNull(allowedCharacters, 'allowedCharacters');

  const characters = allowedCharacters.join('\\');
  const validationPattern = new RegExp(`^[\\w\\s${characters}]*$`, 'i');

  const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    Assert.isNotNull(control, 'control');

    const isValid = validationPattern.test(control.value);
    return !isValid ? { [allowedCharactersValidationKey]: true } : null;
  };

  return validator;
}
