export class UserPermission {
  id: string;
  name: string;

  constructor({ id, name }: Partial<UserPermission> = {}) {
    this.id = id;
    this.name = name;
  }
}

export interface IUserPermission {
  id: string;
  name: string;
}
