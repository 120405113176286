export const authenticationConfig = {
  api: {
    login: {
      url: 'login',
    },
    logout: {
      url: 'logout',
    },
  },
  store: {
    key: 'authentication',
  },
  routes: {
    login: 'loggain',
    register: 'registrera',
  },
  renewUntilTokenExpiredMinutes: 10,
};
