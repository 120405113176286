import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthenticationStoreService } from 'src/app/authentication/authentication-store';
import { coreConfig, LoggerService } from 'src/app/core';
import { DataSource, DataSourceService, DataSourceState, FieldSortOrder } from 'src/app/core/collections';
import { DeleteDocumentConfirmationModalComponent } from 'src/app/documents/delete-document-confirmation-modal/delete-document-confirmation-modal.component';
import { DocumentsApiService } from 'src/app/documents/documents-api.service';
import { DocumentsStoreService } from 'src/app/documents/documents-store';
import { documentsConfig } from 'src/app/documents/documents.config';
import { DocumentsService } from 'src/app/documents/documents.service';
import { Document } from 'src/app/documents/shared';
import { SelectOrganizationModalComponent } from 'src/app/organization/select-organization-modal/select-organization-modal.component';
import { Organization } from 'src/app/organization/shared';
import { projectConfig } from 'src/app/project/project.config';
import { required, validate } from 'src/app/shared';
import { v4 as uuid } from 'uuid';
import { DocumentSectionFilter } from '../../documents/shared';
import { DocumentUser } from '../../users-management/shared';
import { ConfirmationModalDialogData } from '../../core/shared/confirmation-modal-dialog-data';
import { ConfirmationModalComponent } from '../../core/components/confirmation-modal/confirmation-modal.component';
import moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentOrganization } from '../../users-management/shared/document-organization';
import { findIndex, sortBy, uniqBy } from 'lodash';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit {
  dataSource: DataSource<Document>;
  dataSourceState: DataSourceState<Document>;
  documentCreators: DocumentOrganization[];
  documentRevisers: DocumentOrganization[];
  hasEditPermissions$: Observable<boolean>;
  hasSuperAdminOrAuthorPermission$: Observable<boolean>;

  state: {
    isLoading: boolean;
    isFailed: boolean;
    showDeletedDocuments: boolean;
    sectionFilters: DocumentSectionFilter[];
  };

  constructor(
    private dataSourceService: DataSourceService,
    private documentsApiService: DocumentsApiService,
    private documentsStoreService: DocumentsStoreService,
    private documentsService: DocumentsService,
    private authenticationStoreService: AuthenticationStoreService,
    private loggerService: LoggerService,
    private dialog: MatDialog,
    private router: Router,
    private readonly snackBar: MatSnackBar,
  ) {
    this.dataSource = new DataSource<Document>();
    this.dataSourceState = new DataSourceState<Document>({
      filter: '',
      searchableFields: documentsConfig.filter.searchableFields,
      customFilter: (documents: Document[]) => {
        const filteredDocuments = this.filterDeletedDocuments(documents);
        return this.documentsService.filterDocumentSections(filteredDocuments, this.state.sectionFilters);
      },
      sortOrder: new FieldSortOrder(
        documentsConfig.list.defaultSortOrder.fieldName,
        documentsConfig.list.defaultSortOrder.sortOrder
      ),
      page: 0,
      pageSize: coreConfig.pagination.defaultPageSize,
    });

    this.state = {
      isLoading: false,
      isFailed: false,
      showDeletedDocuments: false,
      sectionFilters: [],
    };
  }

  ngOnInit(): void {
    this.hasEditPermissions$ = this.authenticationStoreService.hasEditPermission();
    this.hasSuperAdminOrAuthorPermission$ = this.authenticationStoreService.hasSuperAdminOrAuthorPermission();
    this.onTryLoadDocuments();
  }

  filterDeletedDocuments(documents: Document[]) {
    return documents.filter((document: Document) => this.state.showDeletedDocuments ? true : !document.deletedDate);
  }

  onFilterChanged(filter: string) {
    this.dataSourceState.page = 0;
    this.dataSourceState.filter = filter;
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
  }

  @validate
  onPageChanged(@required page: number) {
    this.dataSourceState.page = page;
    this.dataSource = this.dataSourceService.paginateDataSource(this.dataSource, this.dataSourceState);
  }

  @validate
  onPageSizeChanged(@required pageSize: number) {
    this.dataSourceState.page = 0;
    this.dataSourceState.pageSize = pageSize;
    this.dataSource = this.dataSourceService.paginateDataSource(this.dataSource, this.dataSourceState);
  }

  async onCreateNewDocument() {
    const hasEditPermission = await this.authenticationStoreService.hasEditPermission().pipe(take(1)).toPromise();

    if (!hasEditPermission) {
      return;
    }

    const dialogRef = this.dialog.open(SelectOrganizationModalComponent, {
      minWidth: 400,
    });

    dialogRef.afterClosed().subscribe((organization: Organization) => {
      if (organization) {
        this.createNewDocument(organization.id);
      }
    });
  }

  onSectionFilterChanged(sectionFilters: DocumentSectionFilter[]) {
    this.dataSourceState.page = 0;
    this.state.sectionFilters = sectionFilters;
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
  }

  onShowDeletedDocumentsChanged(showRemovedDocuments: boolean) {
    this.dataSourceState.page = 0;
    this.state.showDeletedDocuments = showRemovedDocuments;
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
  }

  async onTryLoadDocuments() {
    this.state.isLoading = true;
    this.state.isFailed = false;

    this.dataSource = new DataSource<Document>();

    try {
      const documents = await this.documentsApiService.getDocuments();
      this.dataSource = this.dataSourceService.createDataSource(documents, this.dataSourceState);
      await this.setDocumentUsers(documents);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to load documents', error);
    } finally {
      this.state.isLoading = false;
    }
  }

  onRestoreDocument(document: Document): void {
    const dialogData = new ConfirmationModalDialogData({
      title: 'Återställ dokumentet',
      message: `Är du säker på att du vill återställa ${this.getDocumentNameOrDefault(document)}?`,
      confirmButtonText: 'Återställ'
    });
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (result: boolean) => {
      if (result) {
        this.restoreDocument(document);
      }
    });
  }

  onMoveToBinDocument(document: Document): void {
    const dialogData = new ConfirmationModalDialogData({
      title: 'Flytta till papperskorgen',
      message: `Är du säker på att du vill flytta ${this.getDocumentNameOrDefault(document)} till papperskorgen?`,
      confirmButtonText: 'Ja'
    });
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (result: boolean) => {
      if (result) {
        this.moveToBinDocument(document);
      }
    });
  }

  onDeleteDocument(document: Document): void {
    const dialogRef = this.dialog.open(DeleteDocumentConfirmationModalComponent, {
      data: document,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.deleteDocument(document);
      }
    });
  }

  @validate
  private async restoreDocument(@required document: Document): Promise<void> {
    try {
      await this.documentsApiService.restoreDocument(document.id);
      document.deletedDate = null;
      this.showSnackBar(`${this.getDocumentNameOrDefault(document, 'Dokumentet')} återställt.`);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to restore the document', error);
    } finally {
      this.state.isLoading = false;
    }
  }

  @validate
  private async moveToBinDocument(@required document: Document): Promise<void> {
    try {
      await this.documentsApiService.moveToBinDocument(document.id);
      document.deletedDate = moment();
      // Used to hide moved to the bin docs if the checkbox `Show deactivated customers` is unchecked
      this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
      this.showSnackBar(`${this.getDocumentNameOrDefault(document, 'Dokumentet')} flyttat till papperskorgen.`);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to move the document to the bin', error);
    } finally {
      this.state.isLoading = false;
    }
  }

  @validate
  private async deleteDocument(@required document: Document): Promise<void> {
    try {
      await this.documentsApiService.deleteDocument(document.id);
      this.showSnackBar(`${this.getDocumentNameOrDefault(document, 'Dokumentet')} borttaget.`);

      const documentsComparator = (first: Document, second: Document) => first.id === second.id;
      this.dataSource = this.dataSourceService.deleteItemFromDataSource(
        this.dataSource,
        this.dataSourceState,
        document,
        documentsComparator
      );
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to delete the document', error);
    } finally {
      this.state.isLoading = false;
    }
  }

  private async createNewDocument(organizationId: uuid): Promise<void> {
    const documentId = uuid.v4();

    const document = await this.documentsApiService.createDocument(documentId, organizationId);
    this.documentsStoreService.addDocument(document);
    this.showSnackBar('Nytt dokument skapat.');

    this.router.navigate(['/', projectConfig.routes.project, document.id, projectConfig.routes.info]);
  }

  private getDocumentNameOrDefault(document: Document, defaultValue: string = 'dokumentet'): string {
    if (document.info.buildingName)
      return `"${document.info.buildingName}"`;

    return defaultValue;
  }

  private showSnackBar(@required message: string): void {
    this.snackBar.open(message, 'OK');
  }

  private async setDocumentUsers(documents: Document[]) {
    const isInternalUser = await this.authenticationStoreService.hasSuperAdminOrAuthorPermission().pipe(take(1)).toPromise();
    const documentsWithRevisers = documents.filter((document: Document) => document.reviserId);
    const creatorsDocuments = sortBy(
      uniqBy(documents, (document: Document) => document.creatorId),
      (document: Document) => document.creatorName);
    const revisersDocuments = sortBy(
      uniqBy(documentsWithRevisers, (document: Document) => document.reviserId),
      (document: Document) => document.reviserName);
    const uniqueOrganizations = uniqBy(documents, (document: Document) => document.organizationId);
    const organizations = sortBy(uniqueOrganizations, (document: Document) => document.organizationName)
      .map((document: Document) => ({key: document.organizationId, value: document.organizationName}) as KeyValuePair<string, string>);
    const creatorsOrganizations = organizations
      .filter((organization: KeyValuePair<string, string>) => findIndex(creatorsDocuments, x => x.creatorOrganizationId === organization.key) !== -1);
    const revisersOrganizations = organizations
      .filter((organization: KeyValuePair<string, string>) => findIndex(revisersDocuments, x => x.reviserOrganizationId === organization.key) !== -1);

    // Find docs created or revised by internal users
    const hasInternalDocumentCreators = documents.some(document => document.creatorOrganizationId === null);
    const hasInternalDocumentRevisers = documents.some(document => document.reviserOrganizationId === null && document.reviserId);

    if (hasInternalDocumentCreators)
      this.addInternalOrganization(creatorsOrganizations, isInternalUser);

    if (hasInternalDocumentRevisers)
      this.addInternalOrganization(revisersOrganizations, isInternalUser);

    this.setDocumentCreators(creatorsOrganizations, creatorsDocuments);
    this.setDocumentRevisers(revisersOrganizations, revisersDocuments);
  }

  private addInternalOrganization(organizations: KeyValuePair<string, string>[], isInternalUser: boolean) {
    const internalOrganization = {key: 'null', value: 'Säkerhetspartner'} as KeyValuePair<string, string>;
    if (isInternalUser) {
      organizations.unshift(internalOrganization);
    } else {
      organizations.push(internalOrganization);
    }
  }

  private setDocumentCreators(organizations: KeyValuePair<string, string>[], creatorsDocuments: Document[]) {
    this.documentCreators = organizations.map((organization: KeyValuePair<string, string>) => {
      const documentCreators = creatorsDocuments
        .filter((document: Document) => organization.key === String(document.creatorOrganizationId)) // String(document.creatorOrganizationId) - used to convert null to 'null' string.
        .map((document: Document) => new DocumentUser(document.creatorId, document.creatorName));

      return new DocumentOrganization(organization.value, documentCreators);
    });
  }

  private setDocumentRevisers(organizations: KeyValuePair<string, string>[], revisersDocuments: Document[]) {
    this.documentRevisers = organizations.map((organization: KeyValuePair<string, string>) => {
      const documentRevisers = revisersDocuments
        .filter((document: Document) => organization.key === String(document.reviserOrganizationId)) // String(document.reviserOrganizationId) - used to convert null to 'null' string.
        .map((document: Document) => new DocumentUser(document.reviserId, document.reviserName));

      return new DocumentOrganization(organization.value, documentRevisers);
    });
  }
}
