import { v4 as uuid } from 'uuid';
import { OrganizationStatus } from './organization-status';

export class OrganizationItem {
  readonly id: uuid;
  readonly name: string;
  readonly docsCount: number;
  readonly usersCount: number;
  readonly updatedDate: Date;
  status: OrganizationStatus;

  constructor({ id, name, status, docsCount, usersCount, updatedDate }: Partial<OrganizationItem> = {}) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.docsCount = docsCount || 0;
    this.usersCount = usersCount || 0;
    this.updatedDate = new Date(updatedDate);
  }
}
