import { KeyValueDiffers, Pipe, PipeTransform } from '@angular/core';
import { KeyValuePipe } from '@angular/common';
const unordered = (a, b) => 0;

@Pipe({
  name: 'unordKeyValue'
})
export class UnordKeyValuePipe implements PipeTransform {
  constructor(public differs: KeyValueDiffers){};

  transform(value: any, compareFn = unordered): unknown {
    const pipe =  new KeyValuePipe(this.differs);
    return pipe.transform(value, compareFn);
  }
}
