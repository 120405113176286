import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentTabContentComponent } from '../project-tab-container/project-tab-container.component';
import {
  Document, DocumentBuildingOccuredActivityOptionTypes,
  DocumentOpportunities,
  DocumentOpportunitiesSolarPanelsLocationOptionTypes,
  DocumentSectionType
} from 'src/app/documents/shared';
import { projectConfig } from '../project.config';
import { some } from 'lodash';
import { coreConfig } from 'src/app/core';
import { DocumentsService } from 'src/app/documents/documents.service';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { DocumentsValidationService } from 'src/app/documents/documents-validation.service';
import { DocumentSelectOptionType } from 'src/app/documents/shared/document-structure/document-select-option-type';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentUpdateValuesRuleService } from '../document-update-values-rule.service';

@Component({
  selector: 'app-project-tab-action-opportunities',
  templateUrl: './project-tab-action-opportunities.component.html',
  styleUrls: ['./project-tab-action-opportunities.component.scss'],
})
export class ProjectTabActionOpportunitiesComponent extends DocumentTabContentComponent implements OnInit {
  @ViewChild('tabForm')
  tabForm: NgForm;

  rescueServiceResponseTimeOptionTypes: StringMap<DocumentSelectOptionType>;
  opportunitiesAccessibilityOptionTypes: StringMap<DocumentSelectOptionType>;
  windAccessOptionTypes: StringMap<DocumentSelectOptionType>;
  solarPanelsLocationOptionTypes: StringMap<DocumentSelectOptionType>;
  extinguishingWaterAccessOptionTypes: StringMap<DocumentSelectOptionType>;

  isExtinguishingWaterAccessOtherEnabled: boolean;

  get isEmergencyServiceSwitchForSolarCellSystemAppliedEnabled(): boolean {
    return this.document.opportunities.solarPanelsLocationIds.some(id => id === DocumentOpportunitiesSolarPanelsLocationOptionTypes.facadeId ||
      id === DocumentOpportunitiesSolarPanelsLocationOptionTypes.ceilingId);
  }

  get isHasSpecialRequirementsForMaxDistanceOf50MEnabled(): boolean {
    return this.document.building.occuredBuildingActivityIds.some(id => id === DocumentBuildingOccuredActivityOptionTypes.vk5cId);
  }

  get previousPageName(): string {
    return projectConfig.routes.lifts;
  }

  get nextPageName(): string {
    return projectConfig.routes.risks;
  }

  // These getters and setters used to set MatFormField to error state.
  get opportunitiesKeyAccess(): boolean {
    return this.document.opportunities.isKeyAccess && this.document.opportunities.isKeyStoringOnObject;
  }

  set opportunitiesKeyAccess(value: boolean) { }

  private setTimeoutHandler: NodeJS.Timeout;

  constructor(
    dialog: MatDialog,
    snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private documentsValidationService: DocumentsValidationService,
    private documentsSectionDataService: DocumentsSectionDataService,
    private readonly documentUpdateValuesRuleService: DocumentUpdateValuesRuleService,
  ) {
    super(DocumentSectionType.opportunities, dialog, snackBar);
  }

  ngOnInit(): void {
    this.rescueServiceResponseTimeOptionTypes = this.documentsSectionDataService.getRescueServiceResponseTimeOptionTypes();
    this.opportunitiesAccessibilityOptionTypes = this.documentsSectionDataService.getOpportunitiesAccessibilityOptionTypes();
    this.windAccessOptionTypes = this.documentsSectionDataService.getWindAccessOptionTypes();
    this.solarPanelsLocationOptionTypes = this.documentsSectionDataService.getSolarPanelsLocationOptionTypes();
    this.extinguishingWaterAccessOptionTypes = this.documentsSectionDataService.getExtinguishingWaterAccessOptionTypes();
  }

  async onCompleteForm(isCompleted: boolean): Promise<void> {
    this.document.opportunities.isCompleted = isCompleted && this.document.opportunities.isValid;
    await this.saveFormData(true);
  }

  setDocument(document: Document): void {
    this.document = document;

    const isOtherSelected =
      !this.document ||
      some(
        this.document.opportunities.extinguishingWaterAccessIds,
        (id) => id === DocumentOpportunities.otherExtinguishingWaterAccessOptionId
      );
    this.isExtinguishingWaterAccessOtherEnabled = isOtherSelected;
    if (!isOtherSelected) {
      this.document.opportunities.extinguishingWaterAccessOther = null;
    }
  }

  onFormDataChanged(): void {
    clearTimeout(this.setTimeoutHandler);
    this.setTimeoutHandler = setTimeout(() => this.saveFormData(false), coreConfig.forms.saveTimeoutDelay);
  }

  private async saveFormData(formCompletedExplicitly: boolean): Promise<void> {
    if (!this.hasEditPermissions) {
      return;
    }

    const isCompletedBeforeChange = this.document.opportunities.isCompleted;
    this.documentUpdateValuesRuleService.processOpportunitiesRules(this.document);
    this.document.opportunities.isValid = this.documentsValidationService.isOpportunitiesValid(
      this.document.opportunities,
      this.document
    );
    this.document.opportunities.isCompleted = this.document.opportunities.isCompleted && this.document.opportunities.isValid;
    const partialDocument = {
      opportunities: new DocumentOpportunities({ ...this.document.opportunities }),
    } as Document;

    try {
      await this.documentsService.patchDocumentAndSave(this.document, partialDocument);
    } catch (ex) {
      this.showErrorModal('Ett fel uppstod när ändringarna skulle sparas.', ex);
      return;
    }

    if (formCompletedExplicitly || (isCompletedBeforeChange && !this.document.opportunities.isCompleted)) {
      this.showSectionCompletedSnackBar(DocumentSectionType.opportunities, this.document.opportunities.isCompleted);
    }
  }
}
