import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StringUtils } from 'src/app/shared';
import { coreConfig } from '../../core.config';
import { ConfirmationModalDialogData } from '../../shared/confirmation-modal-dialog-data';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  confirmationText: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public confirmationModalDialogData: ConfirmationModalDialogData
  ) {
    this.confirmationText = '';
  }

  get expectedConfirmationText(): string {
    return this.confirmationModalDialogData.confirmationText || coreConfig.confirm.confirmationText;
  }

  get isConfirmed(): boolean {
    if (!this.confirmationModalDialogData.useDoubleConfirmation) {
      return true;
    }
    const isConfirmed = StringUtils.equalsIgnoreCase(this.confirmationText, this.expectedConfirmationText);
    return isConfirmed;
  }

  onConfirm() {
    if (!this.isConfirmed)
      return;

    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
