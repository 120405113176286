<div class="d-flex justify-content-between mb-2">
  <h5 class="mt-1">Förvaltningsdokumentation</h5>
  <div class="d-flex justify-content-end">
    <div class="d-flex" *ngIf="hasEditPermissions">
      <div class="mr-3">
        <button
          class="btn btn-red ml-auto"
          type="button"
          (click)="onCreateNewDocument()"
          title="Skapa ny byggnad"
        >
          Skapa ny byggnad
        </button>
      </div>
      <div class="mr-3 vertical-delimiter"></div>
    </div>
    <div class="mr-3 align-self-end">
      <mat-checkbox (change)="onShowDeletedDocumentsChanged($event.checked)">
        Visa borttagna dokument
      </mat-checkbox>
    </div>
    <div class="mr-3">
      <input
        type="text"
        value=""
        (input)="onFilterChanged($event.target.value)"
        class="form-control"
        placeholder="Sök"
      />
    </div>
    <div>
      <button mat-flat-button class="button-border" (click)="onAddFilter()">
        <mat-icon>add</mat-icon> Lägg till filter
      </button>
    </div>
    <div>
      <mat-paginator #paginator [length]="itemsCount" [pageSize]="pageSize" [hidePageSize]="true" (page)="onPageChanged($event.pageIndex)">
      </mat-paginator>
    </div>
  </div>
</div>

<ng-container *ngFor="let filter of this.state.sectionfilters | keyvalue; trackBy: trackByItem">
  <app-dashboard-header-filter
    [filter]="filter.value"
    [documentCreators]="documentCreators"
    [documentRevisers]="documentRevisers"
    (sectionFilterChanged)="onSectionFilterChanged($event)"
    (sectionFilterDeleted)="onSectionFilterDeleted(filter.key)"
  ></app-dashboard-header-filter>
</ng-container>
