import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { required, validate } from '../shared';
import { appConfig } from '../app.config';
import { ApiService } from '../core/api';
import { trim } from 'lodash';
import { AuthDetails, IAuthDetails } from './shared';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService {
  private readonly http: HttpClient;
  private readonly apiService: ApiService;

  constructor(http: HttpClient, apiService: ApiService) {
    this.http = http;
    this.apiService = apiService;
  }

  @validate
  async login(@required login: string, @required password: string): Promise<AuthDetails> {
    const url = `${appConfig.api.baseUrl}/auth/user/token`;

    const body = {
      username: trim(login),
      password: password,
    };

    let authResponse: IAuthDetails;
    try {
      authResponse = await this.http.post<any>(url, body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const authDetails = this.createAuthDetails(authResponse);

    return authDetails;
  }

  @validate
  async renew(@required token: string): Promise<AuthDetails> {
    const url = `${appConfig.api.baseUrl}/auth/user/token/renew`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    let authResponse: IAuthDetails;
    try {
      authResponse = await this.http
        .post<any>(url, `"${token}"`, { headers: headers })
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const authDetails = this.createAuthDetails(authResponse);

    return authDetails;
  }

  private createAuthDetails(authResponse: IAuthDetails) {
    const expireDate: Date = new Date();
    expireDate.setSeconds(expireDate.getSeconds() + authResponse.expiresInMinutes * 60);

    const authDetails = new AuthDetails({
      accessToken: authResponse.accessToken,
      role: authResponse.role,
      username: authResponse.userName,
      userid: authResponse.userId,
      organizationId: authResponse.organizationId,
      expiresInMinutes: authResponse.expiresInMinutes,
      expireDate: expireDate,
    });

    return authDetails;
  }
}
