import { AuthenticationActions, AuthenticationActionType, SetReturnUrlAction } from './authentication.actions';
import { Assert } from 'src/app/shared';
import { AuthDetails } from '../shared/auth-details';

export interface AuthenticationState {
  isAuthenticated: boolean;
  authDetails: AuthDetails;
  returnUrl: string;
}

export const authenticationInitialState: AuthenticationState = {
  isAuthenticated: false,
  authDetails: null,
  returnUrl: null,
};

export function authenticationReducer(state: AuthenticationState, action: AuthenticationActions): AuthenticationState {
  Assert.isNotNull(action, 'action');

  switch (action.type) {
    case AuthenticationActionType.Authenticate:
      return {
        ...state,
        isAuthenticated: true,
        authDetails: action.authDetails,
      };

    case AuthenticationActionType.ExpireAuthentication:
      return {
        ...state,
        isAuthenticated: false,
        authDetails: null,
      };

    case AuthenticationActionType.SetReturnUrl:
      return setReturnUrlActionReducer(state, action);

    case AuthenticationActionType.UpdateUserName:
      return {
        ...state,
        authDetails: {
          ...state.authDetails,
          username: action.username,
        },
      };

    case AuthenticationActionType.UpdateUserRole:
      return {
        ...state,
        authDetails: {
          ...state.authDetails,
          role: action.role,
        },
      };
    default:
      return state;
  }
}

export function setReturnUrlActionReducer(state: AuthenticationState, action: SetReturnUrlAction): AuthenticationState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const newState: AuthenticationState = {
    ...state,
    returnUrl: action.returnUrl,
  };

  return newState;
}
