import { Pipe, PipeTransform } from '@angular/core';
import { includes } from 'lodash';

@Pipe({
  name: 'includes',
})
export class IncludesPipe implements PipeTransform {
  transform(values: string | object | any[], value: any): boolean {
    return includes(values, value);
  }
}
