import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {
  EmailWithSwedishCharactersValidatorDirective,
  ForbiddenCharactersValidatorDirective,
  MatErrorStateMatcherDirective,
  UniqueValidatorDirective,
} from './forms';
import { IsEmptyPipe } from './utils';
import { AllowedCharactersValidatorDirective } from './forms/allowed-characters-validator.directive';
import { AutoFocusDirective } from './forms/auto-focus.directive';
import { RequireLowerCaseLetterValidatorDirective } from './forms/require-lower-case-letter-validator.directive';
import { RequireUpperCaseLetterValidatorDirective } from './forms/require-upper-case-letter-validator.directive';
import { RequireNumberValidatorDirective } from './forms/require-number-validator.directive';
import { RequireSpecialCharacterValidatorDirective } from './forms/require-special-character-validator.directive';
import { RequireToMatchValidatorDirective } from './forms/require-to-match-validator.directive';
import { RequireNotToMatchValidatorDirective } from './forms/require-not-to-match-validator.directive';
import { IncludesPipe } from './utils/includes.pipe';
import { RequireSelectCheckboxGroupValidatorDirective } from './forms/require-select-checkbox-group-validator.directive';
import { TruncatePipe } from './utils/truncate.pipe';
import { MaxLengthValidatorDirective } from './forms/max-length-validator.directive';
import { MaxValidatorDirective } from './forms/max-validator.directive';
import { MinValidatorDirective } from './forms/min-validator.directive';
import { RequiredIfValidatorDirective } from './forms/required-if-validator.directive';
import { TimeAgoPipe } from './utils/time-ago.pipe';
import { AdHostDirective } from './ad-host.directive';
import { ResetValuesIfFalseDirective } from './forms/reset-values-if-false.directive';
import { DigitOnlyDirective } from './forms/digit-only.directive';
import { IsNotNullPipe } from './utils/is-not-null.pipe';
import { FloatDigitOnlyDirective } from './forms/float-digit-only.directive';
import { ConfirmDialogComponent } from './forms/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SortableTableHeaderComponent } from './tables';
import { ConfirmationRowComponent } from './tables/confirmation-row/confirmation-row.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { JoinPipe } from './utils/join.pipe';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { FormsModule } from '@angular/forms';
import { MaxListValidatorDirective } from './forms/max-list-validator.directive';
import { UnordKeyValuePipe } from './utils/unord-key-value.pipe';

@NgModule({
  declarations: [
    ForbiddenCharactersValidatorDirective,
    IsEmptyPipe,
    AllowedCharactersValidatorDirective,
    EmailWithSwedishCharactersValidatorDirective,
    AutoFocusDirective,
    RequireLowerCaseLetterValidatorDirective,
    RequireUpperCaseLetterValidatorDirective,
    RequireNumberValidatorDirective,
    RequireSpecialCharacterValidatorDirective,
    RequireToMatchValidatorDirective,
    RequireNotToMatchValidatorDirective,
    IncludesPipe,
    RequireSelectCheckboxGroupValidatorDirective,
    TruncatePipe,
    MaxLengthValidatorDirective,
    MinValidatorDirective,
    MaxValidatorDirective,
    MaxListValidatorDirective,
    RequiredIfValidatorDirective,
    TimeAgoPipe,
    AdHostDirective,
    ResetValuesIfFalseDirective,
    DigitOnlyDirective,
    IsNotNullPipe,
    FloatDigitOnlyDirective,
    ConfirmDialogComponent,
    ConfirmationRowComponent,
    SortableTableHeaderComponent,
    ErrorModalComponent,
    JoinPipe,
    UniqueValidatorDirective,
    ConfirmationModalComponent,
    MatErrorStateMatcherDirective,
    UnordKeyValuePipe,
  ],
  imports: [CommonModule, NgbModalModule, NgbTooltipModule, MatButtonModule, MatDialogModule, FormsModule],
  providers: [],
  exports: [
    ForbiddenCharactersValidatorDirective,
    IsEmptyPipe,
    AllowedCharactersValidatorDirective,
    EmailWithSwedishCharactersValidatorDirective,
    AutoFocusDirective,
    RequireLowerCaseLetterValidatorDirective,
    RequireUpperCaseLetterValidatorDirective,
    RequireNumberValidatorDirective,
    RequireSpecialCharacterValidatorDirective,
    RequireToMatchValidatorDirective,
    RequireNotToMatchValidatorDirective,
    IncludesPipe,
    RequireSelectCheckboxGroupValidatorDirective,
    TruncatePipe,
    MaxLengthValidatorDirective,
    MinValidatorDirective,
    MaxValidatorDirective,
    MaxListValidatorDirective,
    RequiredIfValidatorDirective,
    TimeAgoPipe,
    AdHostDirective,
    ResetValuesIfFalseDirective,
    DigitOnlyDirective,
    IsNotNullPipe,
    FloatDigitOnlyDirective,
    ConfirmDialogComponent,
    ConfirmationRowComponent,
    SortableTableHeaderComponent,
    ErrorModalComponent,
    JoinPipe,
    UniqueValidatorDirective,
    MatErrorStateMatcherDirective,
    UnordKeyValuePipe,
  ]
})
export class CoreModule {}
