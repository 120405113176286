import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentsApiService } from '../documents/documents-api.service';
import { DocumentFieldNote, DocumentSectionType } from '../documents/shared';
import { ProjectNoteModalComponent } from './project-note-modal/project-note-modal.component';
import { DocumentSectionNote } from './shared/document-section-note';
import { DocumentSectionNoteResult } from './shared/document-section-note-result';
import { v4 as uuid } from 'uuid';
import { DocumentNotesStoreService } from '../documents/documents-notes-store';
import { Unsubscribe } from '../core/decorators';

@Unsubscribe()
@Directive({
  selector: '[appSectionFieldNote]',
})
export class SectionFieldNoteDirective implements OnDestroy {
  @Input()
  documentId: uuid;

  @Input()
  sectionType: DocumentSectionType;

  @Input()
  sectionFieldId: string;

  @Input()
  notes: StringMap<DocumentFieldNote>;

  @Input()
  isViewOnly: boolean;

  constructor(
    private dialog: MatDialog,
    private documentsApiService: DocumentsApiService,
    private documentNotesStoreService: DocumentNotesStoreService
  ) {}

  @HostListener('click')
  onClick() {
    // keep timeout to allow close the tooltip
    setTimeout(() => {
      this.openNoteModel();
    }, 0);
  }

  ngOnDestroy(): void {}

  private openNoteModel() {
    const dialogRef = this.dialog.open(ProjectNoteModalComponent, {
      disableClose: true,
      data: new DocumentSectionNote({
        sectionType: this.sectionType,
        sectionFieldId: this.sectionFieldId,
        notes: this.notes,
        isViewOnly: this.isViewOnly,
      }),
    });

    dialogRef.afterClosed().subscribe(async (result: DocumentSectionNoteResult) => {
      if (result) {
        if (result.note) {
          const note = await this.documentsApiService.putDocumentFieldNoteById(
            this.documentId,
            result.fieldId,
            result.note
          );

          this.documentNotesStoreService.addDocumentNote(this.documentId, note);
        } else {
          const note = await this.documentsApiService.deleteDocumentFieldNoteById(this.documentId, result.fieldId);

          this.documentNotesStoreService.deleteDocumentNote(this.documentId, result.fieldId);
        }
      }
    });
  }
}
