import { Directive, Input } from '@angular/core';
import { Validators, Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { validate, required } from '../../shared';

@Directive({
  selector: '[appRequiredIf]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RequiredIfValidatorDirective, multi: true }],
})
export class RequiredIfValidatorDirective implements Validator {
  @Input('appRequiredIf')
  isEnabled: boolean;

  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    if (!this.isEnabled) {
      return null;
    }

    const result = Validators.required(control);

    return result;
  }
}
