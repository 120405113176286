export class DocumentInspectionConditions {
  // 1.1
  hasFunctionOfEscapeRoutesCondition: boolean;
  hasFunctionOfEscapeRoutesIndicativeMarkingCondition: boolean;
  hasFunctionOfEscapeRoutesAccessCondition: boolean;
  hasFunctionOfEscapeRoutesCommunicationEquipmentCondition: boolean;
  hasFunctionOfEscapeRoutesMarkingOfEvacuationSiteCondition: boolean;
  hasEvacuationPlanReadabilityMechanicalImpactCondition: boolean;

  // 2.1
  hasFireSpreadWithinFireCellsCondition: boolean;
  hasRegularControlOfChangeThroughWallCoveringsCondition: boolean;

  // 2.2
  hasFireSpreadBetweenFireCellsCondition: boolean;
  hasInstallationDeviceOnDoorsWithinFireCellBoundaryCondition: boolean;
  hasAutomaticDoorOpeningFunctionOnDoorsWithinFireCellBoundaryCondition: boolean;
  hasGlassSectionsWithinFireCellBoundaryCondition: boolean;
  hasVentilationDuctsCondition: boolean;
  hasGlandsForCablesPipesDuctsCondition: boolean;
  hasWallsAndLoadBearingStructuresCondition: boolean;

  // 3.1
  hasWarningMarkingSignageAndEmergencyLightingCondition: boolean;
  hasSignageEmergencyLightingIndicativeMarkingsCondition: boolean;
  hasSignageEmergencyLightingSeparateEmergencyLightinguminairesCondition: boolean;
  hasSignageEmergencyLightingEmergencyPowerSupplyBatteryBackupCondition: boolean;
  hasSignageEmergencyLightingEmergencyPowerSupplyReservePowerSupplyCondition: boolean;
  hasGeneralLightingStaircasesAndCorridorsInBuildingsWithMoreThanTwoStoreysCondition: boolean;
  hasGeneralLightingLightingOfIlluminatedSignsCondition: boolean;

  // 3.2
  hasAutomaticFireAlarmAndEvacuationAlarmCondition: boolean;

  // 3.3
  hasAutomaticWaterSprinklerSystemCondition: boolean;

  // 3.4
  hasFixedExtinguishingSystemsForIncreasedPropertyProtectionCondition: boolean;

  // 3.5
  hasFireFightingEquipmentForPersonsOnSiteCondition: boolean;

  // 3.6
  hasFireGasVentilationCondition: boolean;

  // 4.1
  hasVentilationSystemCondition: boolean;
  hasVentilationSystemAnnualReviewOfFireProtectionFunctionsCondition: boolean;
  hasVentilationSystemFireDamperCondition: boolean;
  hasVentilationSystemMistAndSmokeDuctsCondition: boolean;
  hasFanFunctionForFansInOperationCondition: boolean;

  // 5.1
  hasAccessingBuildingsCondition: boolean;
  hasAccessingBuildingsDrivewaysAndParkingSpacesForRescueServiceCondition: boolean;
  hasAccessingBuildingsBetKeyIsAppliedCondition: boolean;
  hasAccessingBuildingsRoofSurfacesCondition: boolean;

  // 5.2
  hasRescueElevatorCondition: boolean;

  // 5.3
  hasActionPlansCondition: boolean;

  // 5.4
  hasExtinguishingWaterCondition: boolean;
  hasExtinguishingWaterFireHydrantsForRescueServiceCondition: boolean;
  hasExtinguishingWaterFirePondAndParkingSpaceCondition: boolean;
  hasExtinguishingWaterSignageAndMarkingsCondition: boolean;

  // 5.5
  hasLaddersCondition: boolean;
  hasLaddersDryRiserLineCondition: boolean;
  hasLaddersPressurizedRiserLineAuditInspectionCondition: boolean;
  hasLaddersPressurizedRiserLineServiceAndMaintenanceCondition: boolean;
  hasLaddersConnectionAndOutletPointsForExtinguishingWaterCondition: boolean;
  hasLaddersSignageAndMarkingsCondition: boolean;

  // 6.2
  hasSpecialRisksCondition?: boolean;
  hasRisksHandlingFlammableGoodsCondition?: boolean;
  hasRisksHandlingLpgBottlesCondition?: boolean;
  hasRisksLaboratoryActivitiesCondition?: boolean;
  hasRisksBatteryChargingPointCondition?: boolean;
  hasRisksOpenLoadingDockCondition?: boolean;
  hasRisksChipSuctionCondition?: boolean;
  hasRisksExternalEditionsCondition?: boolean;
  hasRisksFireplaceCondition?: boolean;
  hasRisksHighVoltageCondition?: boolean;
  hasRisksOtherRiskCondition?: boolean;
  hasRisksFumeCupboardCondition?: boolean;
  hasRisksIndoorCisternStorageCondition?: boolean;
  hasRisksOutdoorCisternStorageCondition?: boolean;
  hasRisksFixedGasSystemsPipelinesCondition?: boolean;
  hasRisksBoilerRoomOver60vmCondition?: boolean;
  hasRisksRadioactiveSubstancesCondition?: boolean;
  hasRisksBatteryStorageDevicesCondition?: boolean;
  hasRisksHazardousChemicalsPersonEnvironmentCondition?: boolean;
  hasRisksMagnetismCondition?: boolean;
  hasRisksCookingDeviceCondition?: boolean;
  hasRisksChemicalStorageCondition?: boolean;
  hasRisksCentralStorageOfInfectiousWasteCondition?: boolean;

  // 6.3
  hasElectricalInstallationsCondition: boolean;
  hasElectricalInstallationsBatteryChargingPointsCanGenerateGasCondition: boolean;
  hasElectricalInstallationsSolarCellSystemStatusOfInstallationCondition: boolean;

  // 6.4
  hasExternalStorageFireCondition: boolean;
  hasExternalStorageFireContainersCompactorsWarehousesEnvironmentalStationsCondition: boolean;
  hasExternalStorageFireCargoBerthCondition: boolean;

  constructor(init?: Partial<DocumentInspectionConditions>) {
    Object.assign(this, init);
  }
}
