<mat-form-field floatLabel="always" class="w-100" title="{{ title }}">
  <mat-label>{{ title }}</mat-label>
  <input matInput [disabled]="true" class="d-none" />
  <ul class="check-list">
    <ng-container *ngFor="let key of selectedKeys">
      <li *ngIf="values && (key | isNotNull)" [innerHTML]="values[key]?.displayName"></li>
    </ng-container>
  </ul>
  <button
    mat-icon-button
    matSuffix
    *ngIf="notes && notes[sectionFieldId]"
    class="text-secondary-primary"
    appSectionFieldNote
    [ngbTooltip]="notes[sectionFieldId]?.note"
    [documentId]="documentId"
    [sectionType]="sectionType"
    [sectionFieldId]="sectionFieldId"
    [notes]="notes"
    [isViewOnly]="true"
    tooltipClass="medium-large-size-tooltip"
    container="body"
  >
    <mat-icon>attach_file</mat-icon>
  </button>
</mat-form-field>
