import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator } from '@angular/forms';
import { allowedCharactersValidator } from './allowed-characters.validator';
import { required, validate } from 'src/app/shared';

@Directive({
  selector: '[appAllowedCharacters]',
  providers: [{ provide: NG_VALIDATORS, useExisting: AllowedCharactersValidatorDirective, multi: true }],
})
export class AllowedCharactersValidatorDirective implements Validator {
  @Input('appAllowedCharacters')
  allowedCharacters: string[];

  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    const validator = allowedCharactersValidator(this.allowedCharacters);

    const result = validator(control);

    return result;
  }
}
