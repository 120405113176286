import { IDocumentItem } from './document';

export class DocumentLifts implements IDocumentItem {
  public static otherElevatorFireProtectionOptionId = 'other';

  isElevatorExist?: boolean;
  elevatorFireProtectionOptionIds: string[];
  elevatorFireProtectionOther?: string;
  liftSteeringFunctionId?: string;
  recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId?: string;
  liftFireproofWiringId?: string;
  liftsDesignDescription?: string;
  isCompleted: boolean;
  isValid: boolean;

  constructor({
    isElevatorExist,
    elevatorFireProtectionOptionIds,
    elevatorFireProtectionOther,
    liftSteeringFunctionId,
    recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId,
    liftFireproofWiringId,
    liftsDesignDescription,
    isCompleted,
    isValid,
  }: Partial<DocumentLifts> = {}) {
    this.isElevatorExist = isElevatorExist;
    this.elevatorFireProtectionOptionIds = elevatorFireProtectionOptionIds || [];
    this.elevatorFireProtectionOther = elevatorFireProtectionOther;
    this.liftSteeringFunctionId = liftSteeringFunctionId;
    this.recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId = recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId;
    this.liftFireproofWiringId = liftFireproofWiringId;
    this.liftsDesignDescription = liftsDesignDescription;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
