import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { requireUpperCaseLetterValidator } from './require-upper-case-letter.validator';
import { required, validate } from 'src/app/shared';

@Directive({
  selector: '[appRequireUpperCaseLetter]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RequireUpperCaseLetterValidatorDirective, multi: true }],
})
export class RequireUpperCaseLetterValidatorDirective {
  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    const validator = requireUpperCaseLetterValidator();

    const result = validator(control);

    return result;
  }
}
