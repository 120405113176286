import { Directive, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, Validators, ValidatorFn } from '@angular/forms';
import { validate, required } from '../../shared';

@Directive({
  selector: 'input[appMaxLength],matInput[appMaxLength]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxLengthValidatorDirective, multi: true }],
})
export class MaxLengthValidatorDirective implements Validator, OnChanges {
  // tslint:disable-next-line:no-input-rename
  @Input('appMaxLength')
  maxLength: number;

  private validator: ValidatorFn;

  public ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<MaxLengthValidatorDirective, SimpleChange>;
    const maxLengthChange = componentChanges.maxLength;

    if (maxLengthChange) {
      this.validator = Validators.maxLength(maxLengthChange.currentValue);
    }
  }

  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    if (!this.maxLength || !this.validator) {
      return null;
    }

    const validationError = this.validator(control);

    return validationError;
  }
}
