import { IDocumentItem } from './document';
import { Moment } from 'moment';

export class DocumentInfo implements IDocumentItem {
  buildingName?: string;
  objectNumber?: string;
  // reviewerId?: string;
  // reviewerName?: string;
  documentStatusId?: string;
  isManagementDrawingFireProtectionDrawnUp?: boolean;
  isEvacuationPlanDrawnUp?: boolean;
  isActionPlanDrawnUp?: boolean;
  propertyOwner?: string;
  siteVisitDate?: Moment;
  isCompleted: boolean;
  isValid: boolean;

  constructor({
    buildingName,
    objectNumber,
    // reviewerId,
    // reviewerName,
    documentStatusId,
    isManagementDrawingFireProtectionDrawnUp,
    isEvacuationPlanDrawnUp,
    isActionPlanDrawnUp,
    propertyOwner,
    siteVisitDate,
    isCompleted,
    isValid,
  }: Partial<DocumentInfo> = {}) {
    this.buildingName = buildingName;
    this.objectNumber = objectNumber;
    // this.reviewerId = reviewerId;
    // this.reviewerName = reviewerName;
    this.documentStatusId = documentStatusId;
    this.isManagementDrawingFireProtectionDrawnUp = isManagementDrawingFireProtectionDrawnUp;
    this.isEvacuationPlanDrawnUp = isEvacuationPlanDrawnUp;
    this.isActionPlanDrawnUp = isActionPlanDrawnUp;
    this.propertyOwner = propertyOwner;
    this.siteVisitDate = siteVisitDate;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
