<div class="organizations-page h-100 d-flex flex-column">
  <div>
    <app-organizations-table-header
      [itemsCount]="itemsCount"
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [isRefreshing]="isRefreshing"
      [hasSuperAdminPermissions]="hasSuperAdminPermission$ | async"
      (pageChanged)="onPageChanged($event)"
      (filterChanged)="onFilterChanged($event)"
      (showInactivateOrganizationsChanged)="onShowInactivateOrganizationsChanged($event)"
      (pageSizeChanged)="onPageSizeChanged($event)"
      (createOrganization)="onCreateOrganization()"
      (createInternalUser)="onCreateNewInternalUser()"
    >
    </app-organizations-table-header>
  </div>
  <div class="flex-grow-1">
    <mat-tab-group (selectedTabChange)="selectedTabChanged($event)">
      <mat-tab label="Kunder">
        <app-organizations-data
          [filter]="filter"
          [showDeactivatedOrganizations]="showDeactivatedOrganizations"
          [currentPage]="currentPage"
          [pageSize]="pageSize"
          [hasSuperAdminPermissions]="hasSuperAdminPermission$ | async"
          (itemsCountChanged)="onOrganizationsItemsCountChanged($event)"
          (currentPageChanged)="onCurrentPageChanged($event)"
        ></app-organizations-data>
      </mat-tab>
      <mat-tab label="Interna användare">
        <app-internal-users
          [filter]="filter"
          [currentPage]="currentPage"
          (itemsCountChanged)="onUsersItemsCountChanged($event)"
          (currentPageChanged)="onCurrentPageChanged($event)"
        ></app-internal-users>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
