import { ActionReducerMap } from '@ngrx/store';
import { AuthenticationState } from './authentication/authentication-store';
import { getMetaReducersProvider } from './core/store';
import { DocumentsState } from './documents/documents-store';
import { LayoutState } from './layout/layout-store';

export interface AppState {
  authentication: AuthenticationState;
  documents: DocumentsState;
  layout: LayoutState;
}

export const appReducer: ActionReducerMap<AppState> = {} as any;

export const metaReducersProvider = getMetaReducersProvider();
