import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organizations-page',
  templateUrl: './organizations-page.component.html',
  styleUrls: ['./organizations-page.component.scss'],
})
export class OrganizationsPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
