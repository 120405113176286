export class DocumentSelectOptionType {
  readonly id: string;
  readonly displayName: string;
  readonly isBlockerOption: boolean;
  isDisabled: boolean;

  constructor(id: string, displayName: string, isBlockerOption: boolean = false) {
    this.id = id;
    this.displayName = displayName;
    this.isBlockerOption = isBlockerOption;
    this.isDisabled = false;
  }
}
