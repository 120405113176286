import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SaveDataOptions } from 'src/app/core/shared/save-data-options-enum';

@Component({
  selector: 'app-discard-data-confirmation-modal',
  templateUrl: './discard-data-confirmation-modal.component.html',
  styleUrls: ['./discard-data-confirmation-modal.component.scss'],
})
export class DiscardDataConfirmationModalComponent {
  constructor(public dialogRef: MatDialogRef<DiscardDataConfirmationModalComponent>) {}

  onDoNotSave() {
    this.dialogRef.close(SaveDataOptions.DoNotSave);
  }

  onSave() {
    this.dialogRef.close(SaveDataOptions.Save);
  }

  onCancel() {
    this.dialogRef.close(SaveDataOptions.Cancel);
  }
}
