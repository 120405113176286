import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { DocumentsValidationService } from 'src/app/documents/documents-validation.service';
import { DocumentsService } from 'src/app/documents/documents.service';
import { Document, DocumentInfo, DocumentSectionType } from 'src/app/documents/shared';
import { DocumentTabContentComponent } from '../project-tab-container/project-tab-container.component';
import { projectConfig } from '../project.config';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { coreConfig } from 'src/app/core';
import { DocumentSelectOptionType } from 'src/app/documents/shared/document-structure/document-select-option-type';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-project-tab-info',
  templateUrl: './project-tab-info.component.html',
  styleUrls: ['./project-tab-info.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: coreConfig.matDateFormats },
  ],
})
export class ProjectTabInfoComponent extends DocumentTabContentComponent implements OnInit {
  @ViewChild('tabForm')
  tabForm: NgForm;

  documentStatusTypes: StringMap<DocumentSelectOptionType>;
  // documentReviewers: DocumentUser[] = [];
  canReviewDocument: boolean = false;

  get previousPageName(): string {
    return null;
  }

  get nextPageName(): string {
    return projectConfig.routes.object;
  }

  // get allDocumentReviewers(): DocumentUser[] {
  //   const currentReviewerExists = this.documentReviewers.some((reviewer: DocumentUser) => reviewer.id === this.document.info.reviewerId);
  //
  //   if (!currentReviewerExists && this.document.info.reviewerId) {
  //     this.documentReviewers.push(new DocumentUser(this.document.info.reviewerId, this.document.info.reviewerName));
  //     this.documentReviewers = this.documentReviewers.sort((a: DocumentUser, b: DocumentUser) => a.name.localeCompare(b.name));
  //   }
  //
  //   return this.documentReviewers;
  // }

  constructor(
    // private usersApiService: UsersApiService,
    // private authenticationStoreService: AuthenticationStoreService,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private documentsValidationService: DocumentsValidationService,
    private documentsSectionDataService: DocumentsSectionDataService,
  ) {
    super(DocumentSectionType.info, dialog, snackBar);
  }

  async ngOnInit(): Promise<void> {
    this.documentStatusTypes = this.documentsSectionDataService.getDocumentStatusTypes();
    // const authDetails = await this.authenticationStoreService.getAuthDetails().pipe(take(1)).toPromise();

    // if (authDetails.role === UserRole.superAdmin || authDetails.role === UserRole.author) {
    //   const userDetails = await this.usersApiService.getUserByEmail(authDetails.username);
    //   this.canReviewDocument = this.hasEditPermissions && userDetails.user.canReviewDocument;
    // }
    // this.setDocumentReviewers();
  }

  async onCompleteForm(isCompleted: boolean): Promise<void> {
    this.document.info.isCompleted = isCompleted && this.document.info.isValid;
    await this.saveFormData(true);
  }

  onFormDataChanged(): void {
    this.saveFormData(false);
  }

  // private async setDocumentReviewers() {
  //   if (!this.canReviewDocument)
  //     return;
  //
  //   const documentReviewers = await this.usersApiService.getDocumentReviewers();
  //   this.documentReviewers = documentReviewers.sort((a: DocumentUser, b: DocumentUser) => a.name.localeCompare(b.name));
  // }

  private async saveFormData(formCompletedExplicitly: boolean): Promise<void> {
    if (!this.hasEditPermissions) {
      return;
    }

    const isCompletedBeforeChange = this.document.info.isCompleted;
    this.document.info.isValid = this.documentsValidationService.isInfoValid(this.document.info, this.document);
    this.document.info.isCompleted = this.document.info.isCompleted && this.document.info.isValid;
    const partialDocument = {
      info: new DocumentInfo({ ...this.document.info }),
    } as Document;

    try {
      await this.documentsService.patchDocumentAndSave(this.document, partialDocument);
    } catch (ex) {
      this.showErrorModal('Ett fel uppstod när ändringarna skulle sparas.', ex);
      return;
    }

    // Show snackBar only when completed explicitly
    // or completed section becomes invalid.
    if (formCompletedExplicitly || (isCompletedBeforeChange && !this.document.info.isCompleted)) {
      this.showSectionCompletedSnackBar(DocumentSectionType.info, this.document.info.isCompleted);
    }
  }
}
