<h1 mat-dialog-title>Ta bort användare</h1>
<div mat-dialog-content>
  <div class="modal-body">
    <div>Är du säker på att du vill ta bort {{ user?.firstName }} {{ user?.lastName }}?</div>
    <div class="mt-4">
      <div class="form-inline">
        <label class="mr-2" for="confirmationText">
          <ng-container>Skriv </ng-container>
          "{{ expectedConfirmationText }}"
          <ng-container> för att bekräfta</ng-container>
        </label>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="confirmationText"
          name="confirmationText"
          id="confirmationText"
          cdkFocusInitial
          (keydown.enter)="onDelete()"
        />
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-raised-button (click)="onCancel()">Avbryt</button>
  <button class="delete-btn" mat-raised-button (click)="onDelete()" [disabled]="!isConfirmed">
    Radera
  </button>
</div>
