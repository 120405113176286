import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentTabContentComponent } from '../project-tab-container/project-tab-container.component';
import {
  Document,
  DocumentBuilding, DocumentBuildingFireTechnicalBuildingOptionTypes, DocumentBuildingLargeKitchenWithOptionTypes,
  DocumentBuildingOccuredActivityOptionTypes,
  DocumentSectionType
} from 'src/app/documents/shared';
import { projectConfig } from '../project.config';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { coreConfig } from 'src/app/core';
import { DocumentsService } from 'src/app/documents/documents.service';
import { DocumentsValidationService } from 'src/app/documents/documents-validation.service';
import { DocumentSelectOptionType } from 'src/app/documents/shared/document-structure/document-select-option-type';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentUpdateValuesRuleService } from '../document-update-values-rule.service';
import { DocumentSectionCompletionUpdateService } from '../document-section-completion-update.service';

@Component({
  selector: 'app-project-tab-building-description',
  templateUrl: './project-tab-building-description.component.html',
  styleUrls: ['./project-tab-building-description.component.scss'],
})
export class ProjectTabBuildingDescriptionComponent extends DocumentTabContentComponent implements OnInit {
  @ViewChild('tabForm')
  tabForm: NgForm;

  fireTechnicalBuildingClassTypes: StringMap<DocumentSelectOptionType>;
  dimensioningFireLoadTypes: StringMap<DocumentSelectOptionType>;
  culvertTypes: StringMap<DocumentSelectOptionType>;
  fireTechnicalSolutionTypes: StringMap<DocumentSelectOptionType>;
  occuredBuildingActivitiesTypes: StringMap<DocumentSelectOptionType>;
  largeKitchenWithOptionTypes: StringMap<DocumentSelectOptionType>;
  protectiveSolutionForLargeKitchenOptionTypes: StringMap<DocumentSelectOptionType>;
  meetingRoomOccursInFormOfOptionTypes: StringMap<DocumentSelectOptionType>;
  numberOfPeopleInMeetingRoomOptionTypes: StringMap<DocumentSelectOptionType>;

  get previousPageName(): string {
    return projectConfig.routes.object;
  }

  get nextPageName(): string {
    return projectConfig.routes.evacuation;
  }

  get DocumentBuilding(): typeof DocumentBuilding {
    return DocumentBuilding;
  }

  // These getters and setters used to set MatFormField to error state.
  get buildingHasBasementFloor(): boolean {
    return this.document.building.hasBasementFloor && this.document.building.isConsideredBasementFloorPlan;
  }

  set buildingHasBasementFloor(value: boolean) { }

  get buildingHasAtticFloor(): boolean {
    return this.document.building.hasAtticFloor && this.document.building.isConsideredAtticFloorPlan;
  }

  set buildingHasAtticFloor(value: boolean) { }

  get buildingHasEntresolplan(): boolean {
    return this.document.building.hasEntresolplan && this.document.building.isConsideredEntresolplan;
  }

  set buildingHasEntresolplan(value: boolean) { }

  get buildingHasSouterrainDesignPlan(): boolean {
    return this.document.building.hasSouterrainDesign && this.document.building.isConsideredSouterrainDesignPlan;
  }

  set buildingHasSouterrainDesignPlan(value: boolean) { }

  get isBr0AnalysisEstablishedEnabled(): boolean {
    return this.document.building.fireTechnicalBuildingClassId === DocumentBuildingFireTechnicalBuildingOptionTypes.br0Id;
  }

  get isLargeKitchenWithIdsEnabled(): boolean {
    return this.document.building.occuredBuildingActivityIds.some(id => id === DocumentBuildingOccuredActivityOptionTypes.largeKitchenId);
  }

  get isProtectiveSolutionForLargeKitchenIdsEnabled(): boolean {
    return this.document.building.occuredBuildingActivityIds.some(id => id === DocumentBuildingOccuredActivityOptionTypes.largeKitchenId) &&
      this.document.building.largeKitchenWithIds.some(id => id === DocumentBuildingLargeKitchenWithOptionTypes.withFryerId || id === DocumentBuildingLargeKitchenWithOptionTypes.withFryingTableId);
  }

  get isMeetingRoomOccursInFormOfIdsAndNumberOfPeopleInMeetingRoomIdsEnabled(): boolean {
    return this.document.building.occuredBuildingActivityIds.some(id => id === DocumentBuildingOccuredActivityOptionTypes.vk2bId ||
      id === DocumentBuildingOccuredActivityOptionTypes.vk2cId);
  }

  private setTimeoutHandler: NodeJS.Timeout;

  constructor(
    dialog: MatDialog,
    snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private documentsValidationService: DocumentsValidationService,
    private documentsSectionDataService: DocumentsSectionDataService,
    private documentUpdateValuesRuleService: DocumentUpdateValuesRuleService,
    private documentSectionCompletionUpdateService: DocumentSectionCompletionUpdateService,
  ) {
    super(DocumentSectionType.building, dialog, snackBar);
  }

  ngOnInit(): void {
    this.fireTechnicalBuildingClassTypes = this.documentsSectionDataService.getFireTechnicalBuildingClassTypes();
    this.dimensioningFireLoadTypes = this.documentsSectionDataService.getDimensioningFireLoadTypes();
    this.culvertTypes = this.documentsSectionDataService.getCulvertTypes();
    this.fireTechnicalSolutionTypes = this.documentsSectionDataService.getFireTechnicalSolutionTypes();
    this.occuredBuildingActivitiesTypes = this.documentsSectionDataService.getOccuredBuildingActivityTypes();
    this.largeKitchenWithOptionTypes = this.documentsSectionDataService.getLargeKitchenWithOptionTypes();
    this.protectiveSolutionForLargeKitchenOptionTypes = this.documentsSectionDataService.getProtectiveSolutionForLargeKitchenOptionTypes();
    this.meetingRoomOccursInFormOfOptionTypes = this.documentsSectionDataService.getMeetingRoomOccursInFormOfOptionTypes();
    this.numberOfPeopleInMeetingRoomOptionTypes = this.documentsSectionDataService.getNumberOfPeopleInMeetingRoomOptionTypes();
  }

  async onCompleteForm(isCompleted: boolean): Promise<void> {
    this.document.building.isCompleted = isCompleted && this.document.building.isValid;
    await this.saveFormData(true);
  }

  onFormDataChanged(): void {
    clearTimeout(this.setTimeoutHandler);
    this.setTimeoutHandler = setTimeout(() => this.saveFormData(false), coreConfig.forms.saveTimeoutDelay);
  }

  private async saveFormData(formCompletedExplicitly: boolean): Promise<void> {
    if (!this.hasEditPermissions) {
      return;
    }

    const isCompletedBeforeChange = this.document.building.isCompleted;
    this.documentUpdateValuesRuleService.processBuildingRules(this.document);
    this.document.building.isValid = this.documentsValidationService.isBuildingValid(this.document.building, this.document);
    this.document.building.isCompleted = this.document.building.isCompleted && this.document.building.isValid;
    const partialDocument = {
      building: new DocumentBuilding({ ...this.document.building }),
    } as Document;

    this.documentSectionCompletionUpdateService.checkAndUpdate(this.document, DocumentSectionType.building);
    try {
      await this.documentsService.patchDocumentAndSave(this.document, partialDocument);
    } catch (ex) {
      this.showErrorModal('Ett fel uppstod när ändringarna skulle sparas.', ex);
      return;
    }

    if (formCompletedExplicitly || (isCompletedBeforeChange && !this.document.building.isCompleted)) {
      this.showSectionCompletedSnackBar(DocumentSectionType.building, this.document.building.isCompleted);
    }
  }
}
