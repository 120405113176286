import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirmation-row',
  templateUrl: './confirmation-row.component.html',
  styleUrls: ['./confirmation-row.component.scss'],
})
export class ConfirmationRowComponent {
  @Input()
  isVisible: boolean;

  @Input()
  cssClass: string;

  @Output()
  readonly confirm: EventEmitter<void>;

  @Output()
  readonly cancel: EventEmitter<void>;

  constructor() {
    this.confirm = new EventEmitter();
    this.cancel = new EventEmitter();
  }

  onConfirm() {
    this.confirm.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
