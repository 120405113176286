import { NgModule, InjectionToken } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { documentNotesReducer, documentsInitialState, DocumentsNotesState } from './documents-notes.reducer';
import { StoreConfig } from '@ngrx/store/src/store_module';
import { featureStoreConfigFactory, StoreStateService } from 'src/app/core/store';
import { documentsConfig } from '../documents.config';

const featureStoreKey = documentsConfig.store.documentFieldNotesKey;

export const documentFieldNotesFeatureStoreKeyToken = new InjectionToken(
  `FeatureStoreKeyInjectionToken:${featureStoreKey}`
);
export const documentFieldNotesFeatureStoreInitialStateToken = new InjectionToken<DocumentsNotesState>(
  `FeatureStoreInitialStateInjectionToken:${featureStoreKey}`
);
export const documentFieldNotesStoreFeatueConfigToken = new InjectionToken<StoreConfig<DocumentsNotesState>>(
  `FeatureStoreConfigInjectionToken:${featureStoreKey}`
);

@NgModule({
  imports: [StoreModule.forFeature(featureStoreKey, documentNotesReducer, documentFieldNotesStoreFeatueConfigToken)],
  providers: [
    {
      provide: documentFieldNotesFeatureStoreKeyToken,
      useValue: featureStoreKey,
    },
    {
      provide: documentFieldNotesFeatureStoreInitialStateToken,
      useValue: documentsInitialState,
    },
    {
      provide: documentFieldNotesStoreFeatueConfigToken,
      deps: [
        documentFieldNotesFeatureStoreKeyToken,
        documentFieldNotesFeatureStoreInitialStateToken,
        StoreStateService,
      ],
      useFactory: featureStoreConfigFactory,
    },
  ],
})
export class DocumentFieldNotesStoreModule {}
