import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentTabContentComponent } from '../project-tab-container/project-tab-container.component';
import { Document, DocumentLifts, DocumentSectionType } from 'src/app/documents/shared';
import { projectConfig } from '../project.config';
import { some } from 'lodash';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { coreConfig } from 'src/app/core';
import { DocumentsService } from 'src/app/documents/documents.service';
import { DocumentsValidationService } from 'src/app/documents/documents-validation.service';
import { DocumentSelectOptionType } from 'src/app/documents/shared/document-structure/document-select-option-type';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DocumentSelectOptionGroupType
} from '../../documents/shared/document-structure/document-select-option-group-type';

@Component({
  selector: 'app-project-tab-lifts',
  templateUrl: './project-tab-lifts.component.html',
  styleUrls: ['./project-tab-lifts.component.scss'],
})
export class ProjectTabLiftsComponent extends DocumentTabContentComponent implements OnInit {
  @ViewChild('tabForm')
  tabForm: NgForm;

  elevatorFireProtectionOptionTypes: StringMap<DocumentSelectOptionType>;
  liftSteeringFunctionOptionTypes: StringMap<DocumentSelectOptionType>;
  recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneOptionTypes: StringMap<DocumentSelectOptionType>;
  liftFireproofWiringOptionTypes: StringMap<DocumentSelectOptionType>;

  isElevatorFireProtectionOtherEnabled: boolean;

  get previousPageName(): string {
    return projectConfig.routes.airtreatment;
  }

  get nextPageName(): string {
    return projectConfig.routes.opportunities;
  }

  private setTimeoutHandler: NodeJS.Timeout;

  constructor(
    dialog: MatDialog,
    snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private documentsValidationService: DocumentsValidationService,
    private documentsSectionDataService: DocumentsSectionDataService,
  ) {
    super(DocumentSectionType.lifts, dialog, snackBar);
  }

  ngOnInit(): void {
    this.elevatorFireProtectionOptionTypes = this.documentsSectionDataService.getElevatorFireProtectionOptionTypes();
    this.liftSteeringFunctionOptionTypes = this.documentsSectionDataService.getLiftSteeringFunctionOptionTypes();
    this.recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneOptionTypes =
      this.documentsSectionDataService.getRecallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneOccursOptionTypes();
    this.liftFireproofWiringOptionTypes = this.documentsSectionDataService.getLiftFireproofWiringOptionTypes();
  }

  async onCompleteForm(isCompleted: boolean): Promise<void> {
    this.document.lifts.isCompleted = isCompleted && this.document.lifts.isValid;
    await this.saveFormData(true);
  }

  setDocument(document: Document): void {
    this.document = document;

    const isOtherSelected =
      !this.document ||
      some(
        this.document.lifts.elevatorFireProtectionOptionIds,
        (id) => id === DocumentLifts.otherElevatorFireProtectionOptionId
      );
    this.isElevatorFireProtectionOtherEnabled = isOtherSelected;
    if (!isOtherSelected) {
      this.document.lifts.elevatorFireProtectionOther = null;
    }
  }

  onFormDataChanged(): void {
    clearTimeout(this.setTimeoutHandler);
    this.setTimeoutHandler = setTimeout(() => this.saveFormData(false), coreConfig.forms.saveTimeoutDelay);
  }

  private async saveFormData(formCompletedExplicitly: boolean): Promise<void> {
    if (!this.hasEditPermissions) {
      return;
    }

    const isCompletedBeforeChange = this.document.lifts.isCompleted;
    this.document.lifts.isValid = this.documentsValidationService.isLiftsValid(this.document.lifts, this.document);
    this.document.lifts.isCompleted = this.document.lifts.isCompleted && this.document.lifts.isValid;
    const partialDocument = {
      lifts: new DocumentLifts({ ...this.document.lifts }),
    } as Document;

    try {
      await this.documentsService.patchDocumentAndSave(this.document, partialDocument);
    } catch (ex) {
      this.showErrorModal('Ett fel uppstod när ändringarna skulle sparas.', ex);
      return;
    }

    if (formCompletedExplicitly || (isCompletedBeforeChange && !this.document.lifts.isCompleted)) {
      this.showSectionCompletedSnackBar(DocumentSectionType.lifts, this.document.lifts.isCompleted);
    }
  }
}
