import { UserRole } from 'src/app/authentication/shared/user-role';

export class UserDataRole {
  value: UserRole;
  viewValue: string;

  constructor({ value, viewValue }: Partial<UserDataRole> = {}) {
    this.value = value;
    this.viewValue = viewValue;
  }
}
