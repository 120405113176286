import { IDocumentItem } from './document';

export class DocumentSpread implements IDocumentItem {
  protectionAgainstFireOptionIds: string[];
  roofingOptionIds: string[];
  baseRoofingIds: string[];
  facadeMaterialOptionIds: string[];
  facadeMaterialOptionOther?: string;
  protectionAgainstSpreadDescription?: string;
  isCompleted: boolean;
  isValid: boolean;

  constructor({
    protectionAgainstFireOptionIds,
    roofingOptionIds,
    baseRoofingIds,
    facadeMaterialOptionIds,
    facadeMaterialOptionOther,
    protectionAgainstSpreadDescription,
    isCompleted,
    isValid,
  }: Partial<DocumentSpread> = {}) {
    this.protectionAgainstFireOptionIds = protectionAgainstFireOptionIds || [];
    this.roofingOptionIds = roofingOptionIds || [];
    this.baseRoofingIds = baseRoofingIds || [];
    this.facadeMaterialOptionIds = facadeMaterialOptionIds || [];
    this.facadeMaterialOptionOther = facadeMaterialOptionOther;
    this.protectionAgainstSpreadDescription = protectionAgainstSpreadDescription;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
