<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'SKYDD MOT UPPKOMST AV BRAND, SÄRSKILDA RISKER'"
    [documentId]="document?.id"
    [isCompleted]="document?.risks.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Uppvärmning sker genom"
              [values]="risksHeatingOptionTypes"
              [selectedKeys]="document.risks.risksHeatingTypeIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="risksHeatingTypeIds"
            ></app-multiselect-readonly-view>

            <mat-form-field floatLabel="always" class="w-100" title="Uppvärmning sker genom" *ngIf="hasEditPermissions">
              <mat-label i18n="@@project-specialRisks-risksHeatingTypeIds-label" title="Heating takes place through"
                >Uppvärmning sker genom</mat-label
              >

              <mat-select
                required
                [(ngModel)]="document.risks.risksHeatingTypeIds"
                #risksHeatingTypeIds="ngModel"
                name="risksHeatingTypeIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="risksHeatingOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let risksHeatingOptionType of risksHeatingOptionTypes | keyvalue"
                  [value]="risksHeatingOptionType.key"
                  [title]="risksHeatingOptionType.value.displayName"
                  [disabled]="risksHeatingOptionType.value.isDisabled"
                  >{{ risksHeatingOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[risksHeatingTypeIds.name]"-->
<!--                [class.text-secondary-primary]="notes[risksHeatingTypeIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[risksHeatingTypeIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="risksHeatingTypeIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Särskilda risker förekommer i form av"
              [values]="specialRiskOptionTypes"
              [selectedKeys]="document.risks.specialRiskTypeIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="specialRiskTypeIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Särskilda risker förekommer i form av"
              *ngIf="hasEditPermissions"
            >
              <mat-label
                i18n="@@project-specialRisks-specialRiskTypeIds-label"
                title="Special risks occur in the form of"
                >Särskilda risker förekommer i form av</mat-label
              >

              <mat-select
                required
                [(ngModel)]="document.risks.specialRiskTypeIds"
                #specialRiskTypeIds="ngModel"
                name="specialRiskTypeIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="specialRiskOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let specialRiskOptionType of specialRiskOptionTypes | unordKeyValue"
                  [value]="specialRiskOptionType.key"
                  [title]="specialRiskOptionType.value.displayName"
                  [disabled]="specialRiskOptionType.value.isDisabled"
                  >{{ specialRiskOptionType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[specialRiskTypeIds.name]"-->
<!--                [class.text-secondary-primary]="notes[specialRiskTypeIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[specialRiskTypeIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="specialRiskTypeIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Timer/Spisvakt">
              <mat-label>Timer/Spisvakt</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !isHasTimerOrStoveGuardEnabled"
                [(ngModel)]="document.risks.hasTimerOrStoveGuard"
                #hasTimerOrStoveGuard="ngModel"
                name="hasTimerOrStoveGuard"
              />
              <mat-radio-group
                class="d-block"
                name="hasTimerOrStoveGuardRadio"
                [disabled]="!hasEditPermissions || !isHasTimerOrStoveGuardEnabled"
                [(ngModel)]="document.risks.hasTimerOrStoveGuard"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                <mat-radio-button [value]="false">Nej</mat-radio-button>
              </mat-radio-group>
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-xl-6">
            <mat-form-field
              class="w-100"
              title="Utökad beskrivning avseende skydd mot uppkomst av brand och särskilda risker"
            >
              <mat-label
                i18n="@@project-specialRisks-specialRisksDescription-label"
                title="Extended description regarding protection against the occurrence of fire and special risks"
                >Utökad beskrivning avseende skydd mot uppkomst av brand och särskilda risker</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.risks.specialRisksDescription"
                #specialRisksDescription="ngModel"
                name="specialRisksDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[specialRisksDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[specialRisksDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[specialRisksDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="specialRisksDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
