<div class="users-page h-100 d-flex flex-column">
  <div>
    <app-users-table-header
      [itemsCount]="dataSource.filteredItems.length"
      [page]="dataSourceState.page"
      [pageSize]="dataSourceState.pageSize"
      [isRefreshing]="state.isRefreshing"
      [organizationId]="organizationId"
      [currentUserAuthDetails]="currentUserAuthDetails"
      [usersType]="usersType"
      (pageChanged)="onPageChanged($event)"
      (filterChanged)="onFilterChanged($event)"
      (pageSizeChanged)="onPageSizeChanged($event)"
      (managePermissions)="onManagePermissions()"
      (userCreated)="onUserCreated($event)"
      (refresh)="onRefreshUsers()"
    >
      <ng-content select="header"></ng-content>
    </app-users-table-header>
  </div>

  <div class="flex-grow-1">
    <div class="table-container users-page-table-container d-flex">
      <div class="text-center m-4 text-muted text-extra-large" *ngIf="state.isLoading">
        <i class="fas fa-spinner fa-pulse"></i>
      </div>

      <div class="text-center m-4 w-100 align-self-center" *ngIf="state.isFailed">
        <ng-container>Det gick inte att läsa in users.</ng-container>
        <div class="mt-2">
          <ng-container>Klick</ng-container>
          <button type="button" (click)="onTryLoadUsers()" class="btn btn-sm btn-outline-primary mx-1" title="Refresh">
            <i class="fas fa-sync-alt mr-1"></i>
            <ng-container>Uppdatera</ng-container>
          </button>
          <ng-container> att försöka igen.</ng-container>
        </div>
      </div>

      <app-users-table
        *ngIf="!state.isLoading && !state.isFailed"
        [isDisabled]="state.isDeleting || state.isActionProcessing"
        [items]="dataSource.paginatedItems"
        [sortOrder]="dataSourceState.sortOrder"
        [currentUserAuthDetails]="currentUserAuthDetails"
        [organizationId]="organizationId"
        [usersType]="usersType"
        (sortOrderChanged)="onSortOrderChanged($event)"
        (deleteUser)="onDeleteUser($event)"
        (userMoved)="onUserMoved($event)"
        (userEdited)="onUserEdited($event)"
      >
      </app-users-table>
    </div>
  </div>
</div>
