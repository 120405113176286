import { Injectable } from '@angular/core';
import { DocumentInspectionConditions } from './shared';
import { Document } from 'src/app/documents/shared';
import { DocumentsInspectionConditionsBuilder } from './documents-Inspection-conditions-builder';

@Injectable({
  providedIn: 'root',
})
export class DocumentsInspectionConditionsService {
  getDocumentInspectionConditions(document: Document): DocumentInspectionConditions {
    const builder = new DocumentsInspectionConditionsBuilder(document);
    const conditions = builder.build();

    return conditions;
  }
}
