export enum DocumentSectionInfo {
  buildingName = 'buildingName',
  objectNumber = 'objectNumber',
  creatorId = 'creatorId',
  reviserId = 'reviserId',
  documentStatusId = 'documentStatusId',
  isManagementDrawingFireProtectionDrawnUp = 'isManagementDrawingFireProtectionDrawnUp',
  isEvacuationPlanDrawnUp = 'isEvacuationPlanDrawnUp',
  isActionPlanDrawnUp = 'isActionPlanDrawnUp',
  propertyOwner = 'propertyOwner',
  siteVisitDate = 'siteVisitDate',
}

export enum DocumentSectionObject {
  propertyName = 'propertyName',
  municipality = 'municipality',
  area = 'area',
  areaM2 = 'areaM2',
  building = 'building',
  constructionYear = 'constructionYear',
  renovations = 'renovations',
  changesDescription = 'changesDescription',
}

export enum DocumentSectionBuilding {
  numberOfStoreys = 'numberOfStoreys',
  fireTechnicalBuildingClassId = 'fireTechnicalBuildingClassId',
  isBr0AnalysisEstablished = 'isBr0AnalysisEstablished',
  dimensioningFireLoadId = 'dimensioningFireLoadId',
  hasBasementFloor = 'hasBasementFloor',
  isConsideredBasementFloorPlan = 'isConsideredBasementFloorPlan',
  hasSeveralBasementFloors = 'hasSeveralBasementFloors',
  hasAtticFloor = 'hasAtticFloor',
  isConsideredAtticFloorPlan = 'isConsideredAtticFloorPlan',
  hasEntresolplan = 'hasEntresolplan',
  isConsideredEntresolplan = 'isConsideredEntresolplan',
  isConsideredSmallerEntresolplan = 'isConsideredSmallerEntresolplan',
  hasSouterrainDesign = 'hasSouterrainDesign',
  isConsideredSouterrainDesignPlan = 'isConsideredSouterrainDesignPlan',
  hasCulvert = 'hasCulvert',
  culvertTypeIds = 'culvertTypeIds',
  buildingConnectionWith = 'buildingConnectionWith',
  buildingIsIncludedInSameBuildingComplex = 'buildingIsIncludedInSameBuildingComplex',
  adjacentBuildings = 'adjacentBuildings',
  buildingBuiltTogetherViaCulvert = 'buildingBuiltTogetherViaCulvert',
  fireTechnicalSolutionTypeId = 'fireTechnicalSolutionTypeId',
  isOwnGuidelinesAmbitionsApplied = 'isOwnGuidelinesAmbitionsApplied',
  followingOwnGuidelineAmbitionsRelevant = 'followingOwnGuidelineAmbitionsRelevant',
  analyticalDimensioningDescription = 'analyticalDimensioningDescription',
  occuredBuildingActivityIds = 'occuredBuildingActivityIds',
  largeKitchenWithIds = 'largeKitchenWithIds',
  protectiveSolutionForLargeKitchenIds = 'protectiveSolutionForLargeKitchenIds',
  meetingRoomOccursInFormOfIds = 'meetingRoomOccursInFormOfIds',
  numberOfPeopleInMeetingRoomIds = 'numberOfPeopleInMeetingRoomIds',
  isPublicPremises = 'isPublicPremises',
  isAppliedTemporaryOvernightAccommodation = 'isAppliedTemporaryOvernightAccommodation',
  frameConstructionConsists = 'frameConstructionConsists',
  beamConstructionConsists = 'beamConstructionConsists',
  facadeConstructionConsists = 'facadeConstructionConsists',
  roofConstructionConsists = 'roofConstructionConsists',
  extendedBuildingDescription = 'extendedBuildingDescription',
}

export enum DocumentSectionEvacuation {
  isWindowRemoval = 'isWindowRemoval',
  isOnlyOneEscapeRoute = 'isOnlyOneEscapeRoute',
  isEvacuationRescueService = 'isEvacuationRescueService',
  isEvacuationFromAnotherFireExit = 'isEvacuationFromAnotherFireExit',
  isEvacuationFromAnotherPartOfBuilding = 'isEvacuationFromAnotherPartOfBuilding',
  isHorizontalEvacuation = 'isHorizontalEvacuation',
  isEvacuationViaTr1 = 'isEvacuationViaTr1',
  isOnlyEscapeRouteViaTr1 = 'isOnlyEscapeRouteViaTr1',
  isTemporaryEvacuationAFS20201 = 'isTemporaryEvacuationAFS20201',
  isReEntryRequirements = 'isReEntryRequirements',
  isEvacuationViaAutomaticHorizontalSlidingDoors = 'isEvacuationViaAutomaticHorizontalSlidingDoors',
  isEvacuationViaEvacuationElevator = 'isEvacuationViaEvacuationElevator',
  isEvacuationViaRescueElevator = 'isEvacuationViaRescueElevator',
  lockedDoorsIds = 'lockedDoorsIds',
  isEvacuationForDisabilitiesPeople = 'isEvacuationForDisabilitiesPeople',
  isAccessibleAndUsableHorizontalEvacuation = 'isAccessibleAndUsableHorizontalEvacuation',
  isCommunicationEquipmentEvacuation = 'isCommunicationEquipmentEvacuation',
  isEvacuationViaCommonPartOfSeparatedRoute = 'isEvacuationViaCommonPartOfSeparatedRoute',
  isMattressEvacuationOfBedriddenPatients = 'isMattressEvacuationOfBedriddenPatients',
  isClassifiedEscapeRoute = 'isClassifiedEscapeRoute',
  isEvacuationPlansInBuilding = 'isEvacuationPlansInBuilding',
  isImageRequirementsAccordingVk4 = 'isImageRequirementsAccordingVk4',
  extendedEvacuationDescription = 'extendedEvacuationDescription',
}

export enum DocumentSectionFire {
  isBuildingDividedIntoFireCells = 'isBuildingDividedIntoFireCells',
  isFireCellExceeds1250m2 = 'isFireCellExceeds1250m2',
  isMoreThanTwoPlanes = 'isMoreThanTwoPlanes',
  isSpecialFireTechnicalClass = 'isSpecialFireTechnicalClass',
  isSeveralBusinessClasses = 'isSeveralBusinessClasses',
  hasDoorsOrHatchesInFireSeparationConstruction = 'hasDoorsOrHatchesInFireSeparationConstruction',
  hasInstallationDeviceOnDoors = 'hasInstallationDeviceOnDoors',
  hasAutomaticOpeningOnDoors = 'hasAutomaticOpeningOnDoors',
  hasWindowsInFireRetardantConstruction = 'hasWindowsInFireRetardantConstruction',
  hasFireWindowAtAngle = 'hasFireWindowAtAngle',
  hasFireLowHighPart = 'hasFireLowHighPart',
  hasFireRetardantCeilingsFloors = 'hasFireRetardantCeilingsFloors',
  hasAirFireLock = 'hasAirFireLock',
  hasCoveredYardBalconies = 'hasCoveredYardBalconies',
  hasFireCellInAttic = 'hasFireCellInAttic',
  hasFireTechnicalSeparationOfAeratedEaves = 'hasFireTechnicalSeparationOfAeratedEaves',
  hasWashingAndGarbageChute = 'hasWashingAndGarbageChute',
  hasPneumaticTube = 'hasPneumaticTube',
  fireRequirementIds = 'fireRequirementIds',
  sectioningThroughFirewallIds = 'sectioningThroughFirewallIds',
  fireTechnicalClassFirewallIds = 'fireTechnicalClassFirewallIds',
  olderFireTechnicalClassIds = 'olderFireTechnicalClassIds',
  fireCellMeetTechnicalClassIds = 'fireCellMeetTechnicalClassIds',
  fireSpecialConditionsDescription = 'fireSpecialConditionsDescription',
  fireExtendedDescription = 'fireExtendedDescription',
}

export enum DocumentSectionSpread {
  protectionAgainstFireOptionIds = 'protectionAgainstFireOptionIds',
  roofingOptionIds = 'roofingOptionIds',
  baseRoofingIds = 'baseRoofingIds',
  facadeMaterialOptionIds = 'facadeMaterialOptionIds',
  facadeMaterialOptionOther = 'facadeMaterialOptionOther',
  protectionAgainstSpreadDescription = 'protectionAgainstSpreadDescription',
}

export enum DocumentSectionCapacity {
  fireCarryingCapacityId = 'fireCarryingCapacityId',
  isDocumentedConsiderationOfLoadCapacityWithHighFireLoad = 'isDocumentedConsiderationOfLoadCapacityWithHighFireLoad',
  isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem = 'isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem',
  isDocumentedConsiderationOfProtectionNeedsForBr0Building = 'isDocumentedConsiderationOfProtectionNeedsForBr0Building',
  loadBearingCapacityDescription = 'loadBearingCapacityDescription',
}

export enum DocumentSectionInstallations {
  markingsEmergencyLightingIds = 'markingsEmergencyLightingIds',
  powerSupplyEmergencyLightingIds = 'powerSupplyEmergencyLightingIds',
  markingsEmergencyLightingDescription = 'markingsEmergencyLightingDescription',
  isFireAlarmsOccur = 'isFireAlarmsOccur',
  hasFireAlarmsFormalRequirements = 'hasFireAlarmsFormalRequirements',
  hasFireAlarmsSeriesConnection = 'hasFireAlarmsSeriesConnection',
  fireAlarmsDescription = 'fireAlarmsDescription',
  isAutomaticFireAlarmsOccur = 'isAutomaticFireAlarmsOccur',
  hasAutomaticFireAlarmsFormalRequirements = 'hasAutomaticFireAlarmsFormalRequirements',
  hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning = 'hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning',
  isAutomaticFireAlarmOwnAmbition = 'isAutomaticFireAlarmOwnAmbition',
  coordinatedFunctionAutomaticFireAlarmRelevantIds = 'coordinatedFunctionAutomaticFireAlarmRelevantIds',
  automaticFireAlarmsMonitoringScopeIds = 'automaticFireAlarmsMonitoringScopeIds',
  automaticFireAlarmDetekteringIds = 'automaticFireAlarmDetekteringIds',
  automaticFireAlarmActivationIds = 'automaticFireAlarmActivationIds',
  automaticFireAlarmActivationOther = 'automaticFireAlarmActivationOther',
  automaticFireAlarmPowerSupplyIds = 'automaticFireAlarmPowerSupplyIds',
  automaticFireAlarmPowerSupplyOther = 'automaticFireAlarmPowerSupplyOther',
  automaticFireAlarmsReceiverIds = 'automaticFireAlarmsReceiverIds',
  automaticFireAlarmSpecialAdaptationIds = 'automaticFireAlarmSpecialAdaptationIds',
  automaticFireAlarmCurrentRegulations = 'automaticFireAlarmCurrentRegulations',
  automaticFireAlarmConstructionCertificate = 'automaticFireAlarmConstructionCertificate',
  automaticFireAlarmServiceOrientationDrawings = 'automaticFireAlarmServiceOrientationDrawings',
  locationOfFireProtectionPanelFireAlarmCenter = 'locationOfFireProtectionPanelFireAlarmCenter',
  locationOfInformationTableForFireAlarms = 'locationOfInformationTableForFireAlarms',
  locationOfAlarmStorageTablesForFireAlarms = 'locationOfAlarmStorageTablesForFireAlarms',
  automaticFireAlarmsDescription = 'automaticFireAlarmsDescription',
  isAlarmSignalingOccur = 'isAlarmSignalingOccur',
  hasEvacuationAlarmsFormalRequirements = 'hasEvacuationAlarmsFormalRequirements',
  hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning = 'hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning',
  isAlarmSignalingOwnAmbition = 'isAlarmSignalingOwnAmbition',
  hasEmergencyMicrophoneForEmergencyService = 'hasEmergencyMicrophoneForEmergencyService',
  alarmSignalingOccurIds = 'alarmSignalingOccurIds',
  alarmSignalPerceivedIds = 'alarmSignalPerceivedIds',
  evacuationAlarmsActivationIds = 'evacuationAlarmsActivationIds',
  evacuationAlarmsActivationOther = 'evacuationAlarmsActivationOther',
  evacuationAlarmsSignalIds = 'evacuationAlarmsSignalIds',
  evacuationAlarmsSpecialAdaptationIds = 'evacuationAlarmsSpecialAdaptationIds',
  alarmSignalingCurrentRegulations = 'alarmSignalingCurrentRegulations',
  alarmSignalingConstructionCertificate = 'alarmSignalingConstructionCertificate',
  locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel = 'locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel',
  evacuationAlarmsDescription = 'evacuationAlarmsDescription',
  isAutomaticExtinguishingSystemOccur = 'isAutomaticExtinguishingSystemOccur',
  hasAutomaticWaterSprinklerFormalRequirements = 'hasAutomaticWaterSprinklerFormalRequirements',
  isAutomaticExtinguishingSystemOwnAmbition = 'isAutomaticExtinguishingSystemOwnAmbition',
  automaticExtinguishingSystemOccurIds = 'automaticExtinguishingSystemOccurIds',
  monitoringAutomaticExtinguishingSystemIds = 'monitoringAutomaticExtinguishingSystemIds',
  automaticWaterSprinklerAdjustmentIds = 'automaticWaterSprinklerAdjustmentIds',
  automaticWaterSprinklerRiskClassIds = 'automaticWaterSprinklerRiskClassIds',
  automaticWaterSprinklerOther = 'automaticWaterSprinklerOther',
  extinguishingSystems = 'extinguishingSystems',
  extinguishingEquipmentFormIds = 'extinguishingEquipmentFormIds',
  extinguishingEquipmentFormOther = 'extinguishingEquipmentFormOther',
  isFireGasVentilationOccur = 'isFireGasVentilationOccur',
  hasFireGasVentilationFormalRequirements = 'hasFireGasVentilationFormalRequirements',
  isFireGasVentilationOwnAmbition = 'isFireGasVentilationOwnAmbition',
  premisesFireGasVentilationIds = 'premisesFireGasVentilationIds',
  premisesFireGasVentilationOther = 'premisesFireGasVentilationOther',
  fireGasVentilationDescription = 'fireGasVentilationDescription',
  isOtherFireTechnicalInstallationsOccur = 'isOtherFireTechnicalInstallationsOccur',
  hasOtherFireTechnicalInstallationsFormalRequirements = 'hasOtherFireTechnicalInstallationsFormalRequirements',
  isOtherFireTechnicalInstallationsOwnAmbition = 'isOtherFireTechnicalInstallationsOwnAmbition',
  otherFireTechnicalInstallationsDescription = 'otherFireTechnicalInstallationsDescription',
}

export enum DocumentSectionAirTreatment {
  fireGasesProtectionOptionIds = 'fireGasesProtectionOptionIds',
  fireGasesProtectionOther = 'fireGasesProtectionOther',
  isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs = 'isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs',
  fireControlVentilationSystemOptionIds = 'fireControlVentilationSystemOptionIds',
  isSmokeDuctsInside = 'isSmokeDuctsInside',
  hasDocumentedProtectionSolutionForImkanal = 'hasDocumentedProtectionSolutionForImkanal',
  isOperatingCardFlowChartFireProtectionFunctionAvailable = 'isOperatingCardFlowChartFireProtectionFunctionAvailable',
  airTreatmentDescription = 'airTreatmentDescription',
}

export enum DocumentSectionLifts {
  isElevatorExist = 'isElevatorExist',
  elevatorFireProtectionOptionIds = 'elevatorFireProtectionOptionIds',
  elevatorFireProtectionOther = 'elevatorFireProtectionOther',
  liftSteeringFunctionId = 'liftSteeringFunctionId',
  recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId = 'recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId',
  liftFireproofWiringId = 'liftFireproofWiringId',
  liftsDesignDescription = 'liftsDesignDescription',
}

export enum DocumentSectionOpportunities {
  rescueServiceResponseTimeId = 'rescueServiceResponseTimeId',
  opportunitiesAccessibilityId = 'opportunitiesAccessibilityId',
  isKeyAccess = 'isKeyAccess',
  isKeyStoringOnObject = 'isKeyStoringOnObject',
  locationOfKeyCabinet = 'locationOfKeyCabinet',
  isCommunicationEquipmentForEvacuationInBuilding = 'isCommunicationEquipmentForEvacuationInBuilding',
  windAccessId = 'windAccessId',
  solarPanelsLocationIds = 'solarPanelsLocationIds',
  isEmergencyServiceSwitchForSolarCellSystemApplied = 'isEmergencyServiceSwitchForSolarCellSystemApplied',
  locationOfEmergencyServiceSwitch = 'locationOfEmergencyServiceSwitch',
  hasBasementSpecialRequirementsAccess = 'hasBasementSpecialRequirementsAccess',
  hasSpecialRequirementsForMaxDistanceOf50M = 'hasSpecialRequirementsForMaxDistanceOf50M',
  hasRescueLiftInBuilding = 'hasRescueLiftInBuilding',
  isEscapeRouteAvailable = 'isEscapeRouteAvailable',
  isActionPlansAvailable = 'isActionPlansAvailable',
  isFireDustIsApplied = 'isFireDustIsApplied',
  hasAscensionLine = 'hasAscensionLine',
  isAscensionLinePressurized = 'isAscensionLinePressurized',
  extinguishingWaterAccessIds = 'extinguishingWaterAccessIds',
  extinguishingWaterAccessOther = 'extinguishingWaterAccessOther',
  rescueServicesInterventionDescription = 'rescueServicesInterventionDescription',
}

export enum DocumentSectionRisks {
  risksHeatingTypeIds = 'risksHeatingTypeIds',
  specialRiskTypeIds = 'specialRiskTypeIds',
  hasTimerOrStoveGuard = 'hasTimerOrStoveGuard',
  specialRisksDescription = 'specialRisksDescription',
}

export enum DocumentSectionProtectedObjects {
  worthyProtectedObjectTypeIds = 'worthyProtectedObjectTypeIds',
  protectedObjectsDescription = 'protectedObjectsDescription',
}

export enum DocumentSectionInspectionObjects {
  hasFunctionOfEscapeRoutes = 'hasFunctionOfEscapeRoutes',
  HasDoorsWindowsEscapeRotes = 'HasDoorsWindowsEscapeRotes',
  HasFunctionOfEscapeRoutesIndicativeMarking = 'HasFunctionOfEscapeRoutesIndicativeMarking',
  HasFunctionOfEscapeRoutesAccess = 'HasFunctionOfEscapeRoutesAccess',
}
