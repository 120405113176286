import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldSortOrder } from 'src/app/core/collections';
import { projectConfig } from 'src/app/project/project.config';
import { required } from 'src/app/shared';
import { Document } from '../../documents/shared';

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss'],
})
export class DashboardTableComponent {
  @Input()
  items: Document[];

  @Input()
  sortOrder: FieldSortOrder;

  @Input()
  isDisabled: boolean;

  @Input()
  hasEditPermissions: boolean;

  @Input()
  hasSuperAdminOrAuthorPermissions: boolean;

  @Output()
  readonly restoreDocument: EventEmitter<Document>;

  @Output()
  readonly moveToBinDocument: EventEmitter<Document>;

  @Output()
  readonly deleteDocument: EventEmitter<Document>;

  get projectRoute(): string {
    return projectConfig.routes.project;
  }

  constructor() {
    this.restoreDocument = new EventEmitter<Document>();
    this.moveToBinDocument = new EventEmitter<Document>();
    this.deleteDocument = new EventEmitter();
  }

  trackByItem(index: number, @required item: Document): string {
    return item.id;
  }

  onRestoreDocument(document: Document): void {
    this.restoreDocument.next(document);
  }

  onMoveToBinDocument(document: Document): void {
    this.moveToBinDocument.next(document);
  }

  onDeleteDocument(item: Document): void {
    this.deleteDocument.next(item);
  }
}
