import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentTabContentComponent } from '../project-tab-container/project-tab-container.component';
import {
  Document,
  DocumentBuildingFireTechnicalBuildingOptionTypes,
  DocumentCapacity,
  DocumentSectionType
} from 'src/app/documents/shared';
import { projectConfig } from '../project.config';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { DocumentsService } from 'src/app/documents/documents.service';
import { DocumentsValidationService } from 'src/app/documents/documents-validation.service';
import { DocumentSelectOptionType } from 'src/app/documents/shared/document-structure/document-select-option-type';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-project-tab-carrying-capacity',
  templateUrl: './project-tab-carrying-capacity.component.html',
  styleUrls: ['./project-tab-carrying-capacity.component.scss'],
})
export class ProjectTabCarryingCapacityComponent extends DocumentTabContentComponent implements OnInit {
  @ViewChild('tabForm')
  tabForm: NgForm;

  fireCarryingCapacityTypes: StringMap<DocumentSelectOptionType>;

  get previousPageName(): string {
    return projectConfig.routes.spread;
  }

  get nextPageName(): string {
    return projectConfig.routes.installations;
  }

  get isDocumentedConsiderationOfProtectionNeedsForBr0BuildingEnabled(): boolean {
    return this.document.building.fireTechnicalBuildingClassId === DocumentBuildingFireTechnicalBuildingOptionTypes.br0Id;
  }

  constructor(
    dialog: MatDialog,
    snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private documentsValidationService: DocumentsValidationService,
    private documentsSectionDataService: DocumentsSectionDataService,
  ) {
    super(DocumentSectionType.capacity, dialog, snackBar);
  }

  ngOnInit(): void {
    this.fireCarryingCapacityTypes = this.documentsSectionDataService.getFireCarryingCapacityTypes();
  }

  async onCompleteForm(isCompleted: boolean): Promise<void> {
    this.document.capacity.isCompleted = isCompleted && this.document.capacity.isValid;
    await this.saveFormData(true);
  }

  onFormDataChanged(): void {
    this.saveFormData(false);
  }

  private async saveFormData(formCompletedExplicitly: boolean): Promise<void> {
    if (!this.hasEditPermissions) {
      return;
    }

    const isCompletedBeforeChange = this.document.capacity.isCompleted;
    this.document.capacity.isValid = this.documentsValidationService.isCapacityValid(this.document.capacity, this.document);
    this.document.capacity.isCompleted = this.document.capacity.isCompleted && this.document.capacity.isValid;
    const partialDocument = {
      capacity: new DocumentCapacity({ ...this.document.capacity }),
    } as Document;

    try {
      await this.documentsService.patchDocumentAndSave(this.document, partialDocument);
    } catch (ex) {
      this.showErrorModal('Ett fel uppstod när ändringarna skulle sparas.', ex);
      return;
    }

    if (formCompletedExplicitly || (isCompletedBeforeChange && !this.document.capacity.isCompleted)) {
      this.showSectionCompletedSnackBar(DocumentSectionType.capacity, this.document.capacity.isCompleted);
    }
  }
}
