import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorModalComponent } from 'src/app/core/error-modal/error-modal.component';
import { ErrorModalDialogData } from 'src/app/core/shared/error-modal-dialog-data';
import { User } from '../shared';
import { UsersApiService } from '../users-api.service';
import { UsersManagementService } from '../users-management.service';

@Component({
  selector: 'app-reset-user-password-modal',
  templateUrl: './reset-user-password-modal.component.html',
  styleUrls: ['./reset-user-password-modal.component.scss'],
})
export class ResetUserPasswordModalComponent implements OnInit {
  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ResetUserPasswordModalComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private readonly usersApiService: UsersApiService,
    private readonly usersManagementService: UsersManagementService
  ) {
    this.state = {
      isLoading: false,
      isFailed: false,
    };
  }

  ngOnInit(): void {}

  async onResetPassword() {
    this.state.isLoading = true;

    try {
      const newPassword = this.usersManagementService.getGeneratedPassword();
      await this.usersApiService.resetPassword(this.user.id, newPassword);
      this.onClose();
    } catch (error) {
      this.onResetPasswordFailed(error);
    } finally {
      this.state.isLoading = false;
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onClose() {
    this.dialogRef.close(true);
  }

  private onResetPasswordFailed(error: any) {
    const errorModalDialogData = new ErrorModalDialogData({
      title: 'Ett fel uppstod vid återställning av ett lösenord.',
    });
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: errorModalDialogData,
    });
  }
}
