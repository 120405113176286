import { Directive, ElementRef, AfterContentInit, OnDestroy } from '@angular/core';
import { isNull } from 'lodash';
import { coreConfig } from '../core.config';

@Directive({
  selector: '[appAutoFocus]',
})
export class AutoFocusDirective implements AfterContentInit, OnDestroy {
  private element: ElementRef;
  private timeoutId: number;

  constructor(element: ElementRef) {
    this.element = element;
    this.timeoutId = null;
  }

  ngAfterContentInit() {
    this.focus();
  }

  ngOnDestroy() {
    this.element = null;
    this.resetTimeout();
  }

  focus() {
    this.resetTimeout();

    this.timeoutId = setTimeout(() => {
      if (this.element && this.element.nativeElement) {
        this.element.nativeElement.focus();
      }
    }, coreConfig.forms.autoFocusDelay) as any;
  }

  resetTimeout() {
    if (!isNull(this.timeoutId)) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
}
