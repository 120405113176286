import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, ExtraOptions } from '@angular/router';
import { authenticationConfig } from './authentication/authentication.config';
import { LoginPageComponent } from './authentication/login-page/login-page.component';
import { DashboardPageComponent } from './dashboard/dashboard-page/dashboard-page.component';
import { dashboardsConfig } from './dashboard/dashboards.config';
import { LayoutComponent } from './layout/layout/layout.component';
import { ProjectIdResolverService } from './project/project-id-resolver.service';
import { ProjectTabInfoComponent } from './project/project-tab-info/project-tab-info.component';
import { ProjectLayoutComponent } from './project/project-layout/project-layout.component';
import { ProjectTabObjectComponent } from './project/project-tab-object/project-tab-object.component';
import { ProjectTabActionOpportunitiesComponent } from './project/project-tab-action-opportunities/project-tab-action-opportunities.component';
import { ProjectTabAirTreatmentComponent } from './project/project-tab-air-treatment/project-tab-air-treatment.component';
import { ProjectTabBuildingDescriptionComponent } from './project/project-tab-building-description/project-tab-building-description.component';
import { ProjectTabBuildingSpreadComponent } from './project/project-tab-building-spread/project-tab-building-spread.component';
import { ProjectTabCarryingCapacityComponent } from './project/project-tab-carrying-capacity/project-tab-carrying-capacity.component';
import { ProjectTabContainerComponent } from './project/project-tab-container/project-tab-container.component';
import { ProjectTabEvacuationComponent } from './project/project-tab-evacuation/project-tab-evacuation.component';
import { ProjectTabFireInstallationsComponent } from './project/project-tab-fire-installations/project-tab-fire-installations.component';
import { ProjectTabFireProtectionComponent } from './project/project-tab-fire-protection/project-tab-fire-protection.component';
import { ProjectTabLiftsComponent } from './project/project-tab-lifts/project-tab-lifts.component';
import { ProjectTabProtectedObjectsComponent } from './project/project-tab-protected-objects/project-tab-protected-objects.component';
import { ProjectTabSpecialRisksComponent } from './project/project-tab-special-risks/project-tab-special-risks.component';
import { projectConfig } from './project/project.config';
import { ProjectTabInspectionComponent } from './project/project-tab-inspection/project-tab-inspection.component';
import { OrganizationsPageComponent } from './organization/organizations-page/organizations-page.component';
import { organizationConfig } from './organization/organization.config';
import { OrganizationPageComponent } from './organization/organization-page/organization-page.component';
import { UserProfileComponent } from './users-management/user-profile/user-profile.component';
import { UserRole } from './authentication/shared/user-role';
import { AuthenticationGuard } from './authentication/authentication.guard';

const routes: Routes = [
  { path: authenticationConfig.routes.login, component: LoginPageComponent },
  {
    path: 'projekt/:id',
    component: ProjectLayoutComponent,
    children: [
      { path: '', redirectTo: projectConfig.routes.info, pathMatch: 'full' },
      {
        path: projectConfig.routes.info,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabInfoComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.object,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabObjectComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.building,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabBuildingDescriptionComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.evacuation,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabEvacuationComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.fire,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabFireProtectionComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.spread,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabBuildingSpreadComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.capacity,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabCarryingCapacityComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.installations,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabFireInstallationsComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.airtreatment,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabAirTreatmentComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.lifts,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabLiftsComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.opportunities,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabActionOpportunitiesComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.risks,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabSpecialRisksComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.protectedObjects,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabProtectedObjectsComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
      {
        path: projectConfig.routes.inspection,
        component: ProjectTabContainerComponent,
        data: { componentType: ProjectTabInspectionComponent },
        resolve: {
          id: ProjectIdResolverService,
        },
      },
    ],
  },
  {
    path: 'anvandare',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: OrganizationsPageComponent,
        pathMatch: 'full',
        data: { roles: [UserRole.superAdmin] },
        canActivate: [AuthenticationGuard],
      },
      { path: `:${organizationConfig.routes.params.organizationId}`, component: OrganizationPageComponent },
    ],
  },
  {
    path: 'anvandarprofil',
    component: LayoutComponent,
    children: [{ path: '', component: UserProfileComponent, pathMatch: 'full' }],
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: DashboardPageComponent, pathMatch: 'full' },
      { path: dashboardsConfig.routes.dashboard, redirectTo: '', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'top',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
