import { createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { documentsConfig } from '../documents.config';
import { AppState } from 'src/app/app.reducer';
import { v4 as uuid } from 'uuid';
import { DocumentsNotesState } from './documents-notes.reducer';

export const selectDocumentsState: MemoizedSelector<AppState, DocumentsNotesState> = createFeatureSelector(
  documentsConfig.store.documentFieldNotesKey
);

export const selectNotesByDocumentId = createSelector(
  selectDocumentsState,
  (state: DocumentsNotesState, props: { documentId: uuid }) => state.byId[props.documentId] || {}
);

export const selectNoteByDocumentAndFieldId = createSelector(
  selectDocumentsState,
  (state: DocumentsNotesState, props: { documentId: uuid; fieldId: string }) => {
    const notes = state.byId[props.documentId] || {};
    return notes[props.fieldId];
  }
);
