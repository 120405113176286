import { Subject, Subscription } from 'rxjs';
import { PageRequest, PageResponse, PaginationEndpoint } from './page-response';
import { finalize } from 'rxjs/operators';

export default class PaginationDataSource<T> {
  private page = new Subject<PageRequest<T>>();
  // private sort: Subject<Sort<T>>;
  private data = new Subject<PageResponse<T>>();
  private loading = new Subject<boolean>();

  public data$ = this.data.asObservable();
  public loading$ = this.loading.asObservable();

  private pageSubscription: Subscription;

  constructor(private endpoint: PaginationEndpoint<T>) {
    this.pageSubscription = this.page.subscribe(pageRequest => {
      this.loading.next(true);
      this.endpoint(pageRequest)
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(response => this.data.next(response));
    });
  }

  disconnect(): void {
    this.pageSubscription.unsubscribe();
    this.loading.complete();
    this.data.complete();
  }

  // sortBy(sort: Sort<T>): void {
  //   // const lastSort = this.sort.getValue();
  //   // const nextSort = { ...lastSort, ...sort };
  //   this.sort.next(sort);
  // }

  fetch(page: PageRequest<T>): void {
    this.page.next(page);
    // this.sort.next(null);
  }
}
