import { IDocumentItem } from './document';

export class DocumentAirTreatment implements IDocumentItem {
  fireGasesProtectionOptionIds: string[];
  fireGasesProtectionOther?: string;
  isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs?: boolean;
  fireControlVentilationSystemOptionIds: string[];
  isSmokeDuctsInside?: boolean;
  hasDocumentedProtectionSolutionForImkanal?: boolean;
  isOperatingCardFlowChartFireProtectionFunctionAvailable?: boolean;
  airTreatmentDescription?: string;
  isCompleted: boolean;
  isValid: boolean;

  constructor({
    fireGasesProtectionOptionIds,
    isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs,
    fireGasesProtectionOther,
    fireControlVentilationSystemOptionIds,
    isSmokeDuctsInside,
    hasDocumentedProtectionSolutionForImkanal,
    isOperatingCardFlowChartFireProtectionFunctionAvailable,
    airTreatmentDescription,
    isCompleted,
    isValid,
  }: Partial<DocumentAirTreatment> = {}) {
    this.fireGasesProtectionOptionIds = fireGasesProtectionOptionIds || [];
    this.isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs = isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs;
    this.fireGasesProtectionOther = fireGasesProtectionOther;
    this.fireControlVentilationSystemOptionIds = fireControlVentilationSystemOptionIds || [];
    this.isSmokeDuctsInside = isSmokeDuctsInside;
    this.hasDocumentedProtectionSolutionForImkanal = hasDocumentedProtectionSolutionForImkanal;
    this.isOperatingCardFlowChartFireProtectionFunctionAvailable = isOperatingCardFlowChartFireProtectionFunctionAvailable;
    this.airTreatmentDescription = airTreatmentDescription;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
