<div mat-dialog-content style="width: 900px">
  <form #changePasswordForm="ngForm" novalidate (keydown.enter)="onPasswordChange()">
    <div class="modal-body pl-0">
      <section class="w-100" *ngIf="state.isLoading">
        <p class="font-weight-bold mb-4">Ändra lösenord ...</p>
        <div class="text-center m-4 text-muted text-extra-large">
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
      </section>

      <section *ngIf="!state.isLoading">
        <div class="pb-3">
          <div class="form-label-group floating-label-group mb-3">
            <input
              type="password"
              class="form-control mr-sm-2"
              name="currentPassword"
              placeholder="Nuvarande lösenord"
              [(ngModel)]="changePasswordDetails.currentPassword"
              #currentPassword="ngModel"
              [class.is-invalid]="(currentPassword.dirty || currentPassword.touched) && currentPassword.invalid"
              [readOnly]="state.isLoading"
              [minlength]="passwordMinLength"
              required
              cdkFocusInitial
              appFloatingLabelInput
            />
            <label for="currentPassword">Nuvarande lösenord</label>

            <div class="invalid-feedback" *ngIf="currentPassword?.errors">
              <div *ngIf="!!currentPassword.errors?.required">Ange ett förnamn</div>
              <div *ngIf="!!currentPassword.errors?.minlength">
                Får inte innehålla mindre än {{ passwordMinLength }} tecken.
              </div>
            </div>
          </div>
          <div class="form-label-group floating-label-group mb-3">
            <input
              type="password"
              class="form-control mr-sm-2"
              name="newPassword"
              placeholder="Nytt lösenord"
              [(ngModel)]="changePasswordDetails.newPassword"
              #newPassword="ngModel"
              [class.is-invalid]="(newPassword.dirty || newPassword.touched) && newPassword.invalid"
              [readOnly]="state.isLoading"
              [minlength]="passwordMinLength"
              required
              appFloatingLabelInput
            />
            <label for="newPassword">Nytt lösenord</label>

            <div class="invalid-feedback" *ngIf="newPassword.errors">
              <div *ngIf="!!newPassword.errors?.required">Ange ett efternamn</div>
              <div *ngIf="!!newPassword.errors?.minlength">
                Får inte innehålla mindre än {{ passwordMinLength }} tecken.
              </div>
            </div>
          </div>

          <div class="form-label-group floating-label-group mb-3">
            <input
              type="password"
              class="form-control mr-sm-2"
              name="repeatNewPassword"
              placeholder="Bekräfta nytt lösenord"
              [(ngModel)]="changePasswordDetails.repeatNewPassword"
              #repeatNewPassword="ngModel"
              [class.is-invalid]="
                (repeatNewPassword.dirty || repeatNewPassword.touched) &&
                (repeatNewPassword.invalid || !isNewPasswordEqual())
              "
              [readOnly]="state.isLoading"
              [minlength]="passwordMinLength"
              required
              appFloatingLabelInput
            />
            <label for="repeatNewPassword">Bekräfta nytt lösenord</label>

            <div class="invalid-feedback" *ngIf="repeatNewPassword.errors">
              <div *ngIf="!!repeatNewPassword.errors?.required">Ange ett efternamn</div>
              <div *ngIf="!!repeatNewPassword.errors?.minlength">
                Får inte innehålla mindre än {{ passwordMinLength }} tecken.
              </div>
            </div>
            <div
              class="invalid-feedback"
              *ngIf="
                !isNewPasswordEqual() &&
                (newPassword.dirty || newPassword.touched) &&
                (repeatNewPassword.dirty || repeatNewPassword.touched)
              "
            >
              <div>De angivna lösenorden matchar inte.</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </form>
</div>

<div mat-dialog-actions class="float-right">
  <button mat-raised-button (click)="onCancel()">Avbryt</button>
  <button
    class="save-btn"
    mat-raised-button
    (click)="onPasswordChange()"
    [disabled]="!canSubmitForm"
  >
    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="state.isLoading"></i>
    <ng-container>Spara</ng-container>
  </button>
</div>
