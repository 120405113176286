<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'Bärförmåga'"
    [documentId]="document?.id"
    [isCompleted]="document?.capacity.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Bärförmåga vid brand har"
              [values]="fireCarryingCapacityTypes"
              [selectedKeys]="[document.capacity.fireCarryingCapacityId]"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="fireCarryingCapacityId"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Bärförmåga vid brand har"
              *ngIf="hasEditPermissions"
            >
              <mat-label title="Carrying capacity in case of fire">Bärförmåga vid brand har</mat-label>

              <mat-select
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.capacity.fireCarryingCapacityId"
                #fireCarryingCapacityId="ngModel"
                name="fireCarryingCapacityId"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let fireCarryingCapacityType of fireCarryingCapacityTypes | keyvalue"
                  [value]="fireCarryingCapacityType.key"
                  [title]="fireCarryingCapacityType.value.displayName"
                  >{{ fireCarryingCapacityType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[fireCarryingCapacityId.name]"-->
<!--                [class.text-secondary-primary]="notes[fireCarryingCapacityId.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[fireCarryingCapacityId.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="fireCarryingCapacityId.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Dokumenterad beaktning av bärförmåga m.h.t. hög brandbelastning">
              <mat-label title="Dokumenterad beaktning av bärförmåga m.h.t. hög brandbelastning">Dokumenterad beaktning av bärförmåga m.h.t. hög brandbelastning</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.capacity.isDocumentedConsiderationOfLoadCapacityWithHighFireLoad"
                #isDocumentedConsiderationOfLoadCapacityWithHighFireLoad="ngModel"
                name="isDocumentedConsiderationOfLoadCapacityWithHighFireLoad"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="isDocumentedConsiderationOfLoadCapacityWithHighFireLoadRadio"
                  #isDocumentedConsiderationOfLoadCapacityWithHighFireLoadRadio="ngModel"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.capacity.isDocumentedConsiderationOfLoadCapacityWithHighFireLoad"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Dokumenterad beaktning av bärförmåga m.h.t. tekniskt byte vid förekomst av automatisk vattensprinkleranläggning">
              <mat-label>
                Dokumenterad beaktning av bärförmåga m.h.t. tekniskt byte vid förekomst av automatisk vattensprinkleranläggning
              </mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.capacity.isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem"
                #isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem="ngModel"
                name="isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystemRadio"
                  #isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystemRadio="ngModel"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.capacity.isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>
              </div>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Dokumenterad beaktning av utökat skyddsbehov för Br0-byggnad">
              <mat-label>
                Dokumenterad beaktning av utökat skyddsbehov för Br0-byggnad
              </mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !isDocumentedConsiderationOfProtectionNeedsForBr0BuildingEnabled"
                [(ngModel)]="document.capacity.isDocumentedConsiderationOfProtectionNeedsForBr0Building"
                #isDocumentedConsiderationOfProtectionNeedsForBr0Building="ngModel"
                name="isDocumentedConsiderationOfProtectionNeedsForBr0Building"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="isDocumentedConsiderationOfProtectionNeedsForBr0BuildingRadio"
                  #isDocumentedConsiderationOfProtectionNeedsForBr0BuildingRadio="ngModel"
                  [disabled]="!hasEditPermissions || !isDocumentedConsiderationOfProtectionNeedsForBr0BuildingEnabled"
                  [(ngModel)]="document.capacity.isDocumentedConsiderationOfProtectionNeedsForBr0Building"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>
              </div>
              <div *ngIf="!isDocumentedConsiderationOfProtectionNeedsForBr0BuildingEnabled" class="text-xs text-grey">
                Definiera brandteknisk byggnadsklass Br0 under fliken Byggnad- och verksamhetsbeskrivning - Brandteknisk byggnadsklass
              </div>
            </mat-form-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning av bärförmåga">
              <mat-label title="Extended description of load-bearing capacity"
              >Utökad beskrivning av bärförmåga</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.capacity.loadBearingCapacityDescription"
                #loadBearingCapacityDescription="ngModel"
                name="loadBearingCapacityDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[loadBearingCapacityDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[loadBearingCapacityDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[loadBearingCapacityDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="loadBearingCapacityDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
