// Building

export enum DocumentBuildingFireTechnicalBuildingOptionTypes {
  br0Id = '110-Br0',
  br1Id = '120-Br1',
  br2Id = '130-Br2',
  br3Id = '140-Br3'
}

export enum DocumentBuildingOccuredActivityOptionTypes {
  vk1Id = 'vk1',
  vk2aId = 'vk2a',
  vk2bId = 'vk2b',
  vk2cId = 'vk2c',
  vk3aId = 'vk3a',
  vk3bId = 'vk3b',
  vk4Id = 'vk4',
  vk5aId = 'vk5a',
  vk5bId = 'vk5b',
  vk5cId = 'vk5c',
  vk5dId = 'vk5d',
  vk6Id = 'vk6',
  separateBoilerRoomsId = 'separate-boiler-rooms',
  largeKitchenId = 'large-kitchen',
  archiveId = 'archive',
  garageId = 'garage',
  helicopterLandingId = 'helicopter-landing',
  permanentWorkplaceWithHotJobsId = 'permanent-workplace-with-hot-jobs',
  otherSpaceWithHighRisksId = 'other-spaces-with-high-risk',
  sensitiveProtectionPremisesId = 'sensitive-protection-premises',
  premisesAgricultureId = 'premises-agriculture',
  otherSpecialActivitiesId = 'other-special-activities',
}

export enum DocumentBuildingLargeKitchenWithOptionTypes {
  withFryerId = 'with-fryer',
  withFryingTableId = 'with-frying-table',
  withoutIncreasedProbabilityOfFireId = 'without-increased-probability-of-fire',
}

export enum DocumentBuildingProtectiveSolutionForLargeKitchenOptionTypes {
  analyticalDimensioningId = 'analytical-dimensioning',
  fireCellDivisionId = 'fire-cell-division',
  pointProtectionId = 'point-protection',
  missingId = 'missing'
}

export enum DocumentBuildingMeetingRoomOccursInFormOfOptionTypes {
  privateMeetingRoomId = 'private-meeting-room',
  groupOfPremisesWithFireCellId = 'group-of-premises-with-fire-cell'
}

export enum DocumentBuildingNumberOfPeopleInMeetingRoomOptionTypes {
  f151To300Id = '151-300',
  f301To600Id = '301-600',
  f601To1000Id = '601-1000',
  more1000Id = '>1000'
}

// Fire

export enum DocumentFireRequirementOptionTypes {
  requirement400El30Id = 'requirement-400-el-30',
  requirementBr1Building1200El60Id = 'requirement-br1-building-1200-el-60'
}

export enum DocumentFireSectioningThroughFirewallOptionTypes {
  betweenBuildingsId = 'between-buildings',
  ofLargeBuildingId = 'of-large-building',
  doesNotExistId = 'does-not-exist'
}

export enum DocumentFireTechnicalClassFirewallOptionTypes {
  rei60mId = '110-rei60-m',
  rei90mId = '120-rei90-m',
  rei120mId = '130-rei120-m',
  rei240mId = '140-rei240-m',
  unspecifiedId = '150-unspecified',
}

export enum DocumentFireCellMeetTechnicalClassOptionTypes {
  e15Id = 'e-15',
  ei15Id = 'ei-15',
  e30Id = 'e-30',
  ei30Id = 'ei-30',
  e60Id = 'e-60',
  ei60Id = 'ei-60',
  ei90Id = 'ei-90',
  ei120Id = 'ei-120',
  ei240Id = 'ei-240',
  unclear = 'unclear'
}

// Fire spread

export enum DocumentSpreadFacadeMaterialOptionTypes {
  otherId = 'other'
}

// Installations

export enum DocumentInstallationsAutomaticFireAlarmActivationOptionTypes {
  fireAlarmButtonsId = 'fire-alarm-buttons',
  detectorsId = 'detectors',
  extinguishingSystemId = 'extinguishing-system',
  otherId = 'other'
}

export enum DocumentInstallationsAutomaticFireAlarmPowerSupplyOptionTypes {
  batteryUnitId = 'battery-unit',
  chargingUnitId = 'charging-unit',
  otherId = 'other',
}

export enum DocumentInstallationsAlarmSignalingOccurOptionTypes {
  alarmForInternalOperationId = 'alarm-for-internal-operation',
  alarmForRescueServiceId= 'alarm-for-rescue-service',
  alarmForAttentionId = 'alarm-for-attention',
  evacuationAlarmId = 'evacuation-alarm',
}

export enum DocumentInstallationsEvacuationAlarmsActivationOptionTypes {
  automaticFireAlarmId = 'automatic-fire-alarm',
  automaticFireAlarmAndPushButtonsId = 'automatic-fire-alarm-and-push-buttons',
  alarmPushButtonsId = 'alarm-push-buttons',
  sprinklerSystemId = 'sprinkler-system',
  otherId = 'other',
}

export enum DocumentInstallationsAutomaticExtinguishingSystemOccurOptionTypes {
  automaticWaterSprinklerSystemId = 'automatic-water-sprinkler-system',
  residentialSprinklerId = 'residential-sprinkler',
  notApplicableId = 'not-applicable'
}

export enum DocumentInstallationsMarkingsEmergencyLightingOptionTypes {
  illuminatedWithEmergencyPowerId = '110-illuminated-with-emergency-power',
  illuminatedSignsId = '120-illuminated-signs',
  illuminatedFloorMarkingsId = '130-illuminated-floor-markings',
  separateEmergencyLightingId = '140-separate-emergency-lighting',
  doesNotExistId = '150-does-not-exist',
}

export enum DocumentInstallationsPowerSupplyEmergencyLightingOptionTypes {
  centralBatteryBackupId = 'central-battery-backup',
  localBatteryBackupId = 'local-battery-backup',
  reservePowerUnitId = 'reserve-power-unit',
  notEstablishedId = 'not-established',
}

export enum DocumentInstallationsExtinguishingEquipmentFormOptionTypes {
  handFireExtinguisherId = '110-hand-fire-extinguisher',
  indoorFireHydrantId = '120-indoor-fire-hydrant',
  fireBlanketId = '130-fire-blanket',
  doesNotExistId = 'does-not-exist',
  otherId = 'other',
}

// AirTreatment

export enum DocumentAirTreatmentFireGasesProtectionOptionTypes {
  separateVentilationSystemsForFireCellId = '110-separate-ventilation-systems',
  fireGasDamperAltFireId = '120-fire-gas-damper',
  fansInOperationInCaseOfFireId = '130-fans-in-operation',
  pressureReliefOfDuctSystemId = '140-pressure-relief',
  notConfirmedId = 'not-confirmed',
  doesNotExistId = 'does-not-exist',
  otherId = 'other',
}

export enum DocumentActionOpportunitiesAccessibilityOptionTypes {
  streetNetworkId = '110-street-network',
  streetNetworkAndSpecialEscapeRouteId = '120-special-escape-route',
  streetNetworkSpecialRescueRouteAndParkingSpaceId = '130-parking-space',
}

export enum DocumentActionOpportunitiesWindAccessOptionTypes {
  viaHatchesInTheCeilingId = '110-hatches',
  viaDoorsInTheFacadeGableId = '120-facade-doors',
  viaFireTechnicallySeparatedInternalAccessRoadId = '130-internal-access-road',
}

export enum DocumentActionOpportunitiesExtinguishingWaterAccessOptionTypes {
  municipalFireHydrantsId = '110-municipal-fire-hydrants',
  internalFireHydrantsId = '120-internal-fire-hydrants',
  doesNotExistId = '130-extinguishing-water-access-does-not-exist',
  otherOptionId = 'other',
}

export enum DocumentOpportunitiesSolarPanelsLocationOptionTypes {
  facadeId = 'facade',
  ceilingId = 'ceiling',
  doesNotExistId = 'does-not-exist',
}

export enum DocumentFlammableItemOptionsTypes {
  fixedGasSystemsPipelinesId = '110-fixed-gas-systems-pipelines',
  indoorsPlacedCisternsId = '120-indoors-placed-cisterns',
  outdoorCisternsId = '130-outdoor-cisterns',
  warningMarkingForActivitiesId = '140-warning-marking-for-activities',
}

export enum DocumentActionOpportunitiesSolarPanelsLocationOptionTypes {
  ceilingId = '110-ceiling',
  facadeId = '120-facade',
  doesNotExistId = '130-solar-panels-location-does-not-exist',
}

// Risks

export enum DocumentRisksSpecialRiskOptionTypes {
  otherRiskId = 'other-risk',
  batteryChargingPointId = 'battery-charging-point',
  batteryStorageDevicesId = 'battery-storage-devices',
  centralStorageOfInfectiousWasteId = 'central-storage-of-infectious-waste',
  indoorCisternStorageId = 'indoor-cistern-storage',
  outdoorCisternStorageId = 'outdoor-cistern-storage',
  fumeCupboardId = 'fume-cupboard',
  fireplaceId = 'fireplace',
  hazardousChemicalsPersonEnvironmentId = 'hazardous-chemicals-person-environment',
  fixedGasSystemsPipelinesId = 'fixed-gas-systems-pipelines',
  handlingFlammableGoodsId = 'handling-flammable-goods',
  handlingLpgBottlesId = 'handling-lpg-bottles',
  highVoltageId = 'high-voltage',
  chemicalStorageId = 'chemical-storage',
  laboratoryActivitiesId = 'laboratory-activities',
  magnetismId = 'magnetism',
  cookingDeviceId = 'cooking-device',
  boilerRoomOver60vmId = 'boiler-room-over-60-kw',
  radioactiveSubstancesId = 'radioactive-substances',
  chipSuctionId = 'chip-suction',
  externalEditionsId = 'external-editions',
  openLoadingDockId = 'open-loading-dock',
  notApplicableId = 'not-applicable'
}
