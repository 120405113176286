import { Subscription } from 'rxjs';

export function unsubscribeInService(): PropertyDecorator {
  // TODO: Replace with class decorator
  throw new Error('Not supported');
  return function decorate(target: any, key: string | symbol): void {
    const destroyFunction: Function = target['destroy'];
    const destroy: Function = function (): void {
      if (this) {
        const subscription: Subscription = this[key];
        if (subscription) {
          if (!subscription.unsubscribe) {
            throw new Error(`Subscription doesn't have unsubscribe().`);
          }
          subscription.unsubscribe();
          this[key] = null;
        }
        if (destroyFunction) {
          destroyFunction.call(this);
        }
      }
    };
    target['destroy'] = destroy;
  };
}
