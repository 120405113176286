<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'BYGGNADS-OCH VERKSAMHETSBESKRIVNING'"
    [documentId]="document?.id"
    [isCompleted]="document?.building.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Antal våningsplan">
              <mat-label title="Number Of Storeys">Antal våningsplan</mat-label>
              <input
                type="number"
                min="0"
                matInput
                appDigitOnly
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.numberOfStoreys"
                #numberOfStoreys="ngModel"
                name="numberOfStoreys"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[numberOfStoreys.name]"-->
<!--                [class.text-secondary-primary]="notes[numberOfStoreys.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[numberOfStoreys.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="numberOfStoreys.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Dimensionering brandbelastning"
              [values]="dimensioningFireLoadTypes"
              [selectedKeys]="[document.building.dimensioningFireLoadId]"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="dimensioningFireLoadId"
            ></app-multiselect-readonly-view>

            <mat-form-field class="w-100" title="Dimensionering brandbelastning" *ngIf="hasEditPermissions">
              <mat-label title="Dimensioning Fire Load">Dimensionering brandbelastning</mat-label>
              <mat-select
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.dimensioningFireLoadId"
                #dimensioningFireLoadId="ngModel"
                name="dimensioningFireLoadId"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let dimensioningFireLoadType of dimensioningFireLoadTypes | keyvalue"
                  [value]="dimensioningFireLoadType.key"
                  [title]="dimensioningFireLoadType.value.displayName"
                  [innerHtml]="dimensioningFireLoadType.value.displayName"
                ></mat-option>
              </mat-select>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[dimensioningFireLoadId.name]"-->
              <!--                [class.text-secondary-primary]="notes[dimensioningFireLoadId.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[dimensioningFireLoadId.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="dimensioningFireLoadId.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Brandteknisk byggnadsklass"
              [values]="fireTechnicalBuildingClassTypes"
              [selectedKeys]="[document.building.fireTechnicalBuildingClassId]"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="fireTechnicalBuildingClassId"
            ></app-multiselect-readonly-view>

            <mat-form-field class="w-100" title="Brandteknisk byggnadsklass" *ngIf="hasEditPermissions">
              <mat-label title="Fire technical building class">Brandteknisk byggnadsklass</mat-label>
              <mat-select
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.fireTechnicalBuildingClassId"
                #fireTechnicalBuildingClassId="ngModel"
                name="fireTechnicalBuildingClassId"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let fireTechnicalBuildingClassType of fireTechnicalBuildingClassTypes | keyvalue"
                  [value]="fireTechnicalBuildingClassType.key"
                  [title]="fireTechnicalBuildingClassType.value.displayName"
                >{{ fireTechnicalBuildingClassType.value.displayName }}</mat-option
                >
              </mat-select>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[fireTechnicalBuildingClassId.name]"-->
              <!--                [class.text-secondary-primary]="notes[fireTechnicalBuildingClassId.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[fireTechnicalBuildingClassId.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="fireTechnicalBuildingClassId.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Br0-analys finns upprättad">
              <mat-label>Br0-analys finns upprättad</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !isBr0AnalysisEstablishedEnabled"
                [(ngModel)]="document.building.isBr0AnalysisEstablished"
                #isBr0AnalysisEstablished="ngModel"
                name="isBr0AnalysisEstablished"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="isBr0AnalysisEstablishedRadio"
                  #isBr0AnalysisEstablishedRadio="ngModel"
                  [disabled]="!hasEditPermissions || !isBr0AnalysisEstablishedEnabled"
                  [(ngModel)]="document.building.isBr0AnalysisEstablished"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>
              </div>
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Källarplan">
              <mat-label title="Basement floor">Källarplan</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="buildingHasBasementFloor"
                #hasBasementFloor="ngModel"
                name="hasBasementFloor"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="hasBasementFloorRadio"
                  #hasBasementFloorRadio="ngModel"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.building.hasBasementFloor"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>

                <div class="d-flex ml-3">
                  <span class="text-medium mt-1" [class.text-disabled]="!document.building.hasBasementFloor"
                  >Betraktas som våningsplan:</span
                  >
                  <mat-radio-group
                    class="d-block ml-3"
                    name="isConsideredBasementFloorPlan"
                    #isConsideredBasementFloorPlan="ngModel"
                    [required]="document.building.hasBasementFloor"
                    [disabled]="!document.building.hasBasementFloor || !hasEditPermissions"
                    [(ngModel)]="document.building.isConsideredBasementFloorPlan"
                    (ngModelChange)="onFormDataChanged()"
                  >
                    <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                    <mat-radio-button [value]="false">Nej</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <!--              <div matSuffix class="d-flex">-->
              <!--                <button-->
              <!--                  mat-icon-button-->
              <!--                  *ngIf="hasEditPermissions || notes[hasBasementFloor.name]"-->
              <!--                  [class.text-secondary-primary]="notes[hasBasementFloor.name]"-->
              <!--                  appSectionFieldNote-->
              <!--                  [ngbTooltip]="notes[hasBasementFloor.name]?.note"-->
              <!--                  [documentId]="document?.id"-->
              <!--                  [sectionType]="sectionType"-->
              <!--                  [sectionFieldId]="hasBasementFloor.name"-->
              <!--                  [notes]="notes"-->
              <!--                  [isViewOnly]="!hasEditPermissions"-->
              <!--                  tooltipClass="medium-large-size-tooltip"-->
              <!--                  container="body"-->
              <!--                >-->
              <!--                  <mat-icon>attach_file</mat-icon>-->
              <!--                </button>-->

              <!--                <button-->
              <!--                  mat-icon-button-->
              <!--                  *ngIf="hasEditPermissions || notes[isConsideredBasementFloorPlan.name]"-->
              <!--                  [class.text-secondary-primary]="notes[isConsideredBasementFloorPlan.name]"-->
              <!--                  appSectionFieldNote-->
              <!--                  [ngbTooltip]="notes[isConsideredBasementFloorPlan.name]?.note"-->
              <!--                  [documentId]="document?.id"-->
              <!--                  [sectionType]="sectionType"-->
              <!--                  [sectionFieldId]="isConsideredBasementFloorPlan.name"-->
              <!--                  [notes]="notes"-->
              <!--                  [isViewOnly]="!hasEditPermissions"-->
              <!--                  tooltipClass="medium-large-size-tooltip"-->
              <!--                  container="body"-->
              <!--                >-->
              <!--                  <mat-icon>attach_file</mat-icon>-->
              <!--                </button>-->
              <!--              </div>-->
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" title="Vindsplan">
              <mat-label title="Attic">Vindsplan</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="buildingHasAtticFloor"
                #hasAtticFloor="ngModel"
                name="hasAtticFloor"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="hasAtticFloorRadio"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.building.hasAtticFloor"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>

                <div class="d-flex ml-3">
                  <span class="text-medium mt-1" [class.text-disabled]="!document.building.hasAtticFloor"
                  >Betraktas som våningsplan:</span
                  >
                  <mat-radio-group
                    class="d-block ml-3"
                    name="isConsideredAtticFloorPlan"
                    #isConsideredAtticFloorPlan="ngModel"
                    [required]="document.building.hasAtticFloor"
                    [disabled]="!document.building.hasAtticFloor || !hasEditPermissions"
                    [(ngModel)]="document.building.isConsideredAtticFloorPlan"
                    (ngModelChange)="onFormDataChanged()"
                  >
                    <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                    <mat-radio-button [value]="false">Nej</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <!--              <div matSuffix class="d-flex">-->
              <!--                <button-->
              <!--                  mat-icon-button-->
              <!--                  *ngIf="hasEditPermissions || notes[hasAtticFloor.name]"-->
              <!--                  [class.text-secondary-primary]="notes[hasAtticFloor.name]"-->
              <!--                  appSectionFieldNote-->
              <!--                  [ngbTooltip]="notes[hasAtticFloor.name]?.note"-->
              <!--                  [documentId]="document?.id"-->
              <!--                  [sectionType]="sectionType"-->
              <!--                  [sectionFieldId]="hasAtticFloor.name"-->
              <!--                  [notes]="notes"-->
              <!--                  [isViewOnly]="!hasEditPermissions"-->
              <!--                  tooltipClass="medium-large-size-tooltip"-->
              <!--                  container="body"-->
              <!--                >-->
              <!--                  <mat-icon>attach_file</mat-icon>-->
              <!--                </button>-->

              <!--                <button-->
              <!--                  mat-icon-button-->
              <!--                  *ngIf="hasEditPermissions || notes[isConsideredAtticFloorPlan.name]"-->
              <!--                  [class.text-secondary-primary]="notes[isConsideredAtticFloorPlan.name]"-->
              <!--                  appSectionFieldNote-->
              <!--                  [ngbTooltip]="notes[isConsideredAtticFloorPlan.name]?.note"-->
              <!--                  [documentId]="document?.id"-->
              <!--                  [sectionType]="sectionType"-->
              <!--                  [sectionFieldId]="isConsideredAtticFloorPlan.name"-->
              <!--                  [notes]="notes"-->
              <!--                  [isViewOnly]="!hasEditPermissions"-->
              <!--                  tooltipClass="medium-large-size-tooltip"-->
              <!--                  container="body"-->
              <!--                >-->
              <!--                  <mat-icon>attach_file</mat-icon>-->
              <!--                </button>-->
              <!--              </div>-->
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" title="Entresolplan">
              <mat-label title="Entresolplan">Entresolplan</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="buildingHasEntresolplan"
                #hasEntresolplan="ngModel"
                name="hasEntresolplan"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="hasEntresolplanRadio"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.building.hasEntresolplan"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>

                <div class="d-flex ml-3">
                  <span class="text-medium mt-1" [class.text-disabled]="!document.building.hasEntresolplan"
                  >Betraktas som våningsplan:</span
                  >
                  <mat-radio-group
                    class="d-block ml-3"
                    name="isConsideredEntresolplan"
                    #isConsideredEntresolplan="ngModel"
                    [required]="document.building.hasEntresolplan"
                    [disabled]="!document.building.hasEntresolplan || !hasEditPermissions"
                    [(ngModel)]="document.building.isConsideredEntresolplan"
                    (ngModelChange)="onFormDataChanged()"
                  >
                    <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                    <mat-radio-button [value]="false">Nej</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <!--              <div matSuffix class="d-flex">-->
              <!--                <button-->
              <!--                  mat-icon-button-->
              <!--                  *ngIf="hasEditPermissions || notes[hasEntresolplan.name]"-->
              <!--                  [class.text-secondary-primary]="notes[hasEntresolplan.name]"-->
              <!--                  appSectionFieldNote-->
              <!--                  [ngbTooltip]="notes[hasEntresolplan.name]?.note"-->
              <!--                  [documentId]="document?.id"-->
              <!--                  [sectionType]="sectionType"-->
              <!--                  [sectionFieldId]="hasEntresolplan.name"-->
              <!--                  [notes]="notes"-->
              <!--                  [isViewOnly]="!hasEditPermissions"-->
              <!--                  tooltipClass="medium-large-size-tooltip"-->
              <!--                  container="body"-->
              <!--                >-->
              <!--                  <mat-icon>attach_file</mat-icon>-->
              <!--                </button>-->

              <!--                <button-->
              <!--                  mat-icon-button-->
              <!--                  *ngIf="hasEditPermissions || notes[isConsideredEntresolplan.name]"-->
              <!--                  [class.text-secondary-primary]="notes[isConsideredEntresolplan.name]"-->
              <!--                  appSectionFieldNote-->
              <!--                  [ngbTooltip]="notes[isConsideredEntresolplan.name]?.note"-->
              <!--                  [documentId]="document?.id"-->
              <!--                  [sectionType]="sectionType"-->
              <!--                  [sectionFieldId]="isConsideredEntresolplan.name"-->
              <!--                  [notes]="notes"-->
              <!--                  [isViewOnly]="!hasEditPermissions"-->
              <!--                  tooltipClass="medium-large-size-tooltip"-->
              <!--                  container="body"-->
              <!--                >-->
              <!--                  <mat-icon>attach_file</mat-icon>-->
              <!--                </button>-->
              <!--              </div>-->
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" title="Suterrängutformning">
              <mat-label title="Suterrängutformning">Suterrängutformning</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="buildingHasSouterrainDesignPlan"
                #hasSouterrainDesign="ngModel"
                name="hasSouterrainDesign"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="hasSouterrainDesignRadio"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.building.hasSouterrainDesign"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>

                <div class="d-flex ml-3">
                  <span class="text-medium mt-1" [class.text-disabled]="!document.building.hasSouterrainDesign"
                  >Betraktas som våningsplan:</span
                  >
                  <mat-radio-group
                    class="d-block ml-3"
                    name="isConsideredSouterrainDesignPlan"
                    #isConsideredSouterrainDesignPlan="ngModel"
                    [required]="document.building.hasSouterrainDesign"
                    [disabled]="!document.building.hasSouterrainDesign || !hasEditPermissions"
                    [(ngModel)]="document.building.isConsideredSouterrainDesignPlan"
                    (ngModelChange)="onFormDataChanged()"
                  >
                    <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                    <mat-radio-button [value]="false">Nej</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <!--              <div matSuffix class="d-flex">-->
              <!--                <button-->
              <!--                  mat-icon-button-->
              <!--                  *ngIf="hasEditPermissions || notes[hasEntresolplan.name]"-->
              <!--                  [class.text-secondary-primary]="notes[hasEntresolplan.name]"-->
              <!--                  appSectionFieldNote-->
              <!--                  [ngbTooltip]="notes[hasEntresolplan.name]?.note"-->
              <!--                  [documentId]="document?.id"-->
              <!--                  [sectionType]="sectionType"-->
              <!--                  [sectionFieldId]="hasEntresolplan.name"-->
              <!--                  [notes]="notes"-->
              <!--                  [isViewOnly]="!hasEditPermissions"-->
              <!--                  tooltipClass="medium-large-size-tooltip"-->
              <!--                  container="body"-->
              <!--                >-->
              <!--                  <mat-icon>attach_file</mat-icon>-->
              <!--                </button>-->

              <!--                <button-->
              <!--                  mat-icon-button-->
              <!--                  *ngIf="hasEditPermissions || notes[isConsideredEntresolplan.name]"-->
              <!--                  [class.text-secondary-primary]="notes[isConsideredEntresolplan.name]"-->
              <!--                  appSectionFieldNote-->
              <!--                  [ngbTooltip]="notes[isConsideredEntresolplan.name]?.note"-->
              <!--                  [documentId]="document?.id"-->
              <!--                  [sectionType]="sectionType"-->
              <!--                  [sectionFieldId]="isConsideredEntresolplan.name"-->
              <!--                  [notes]="notes"-->
              <!--                  [isViewOnly]="!hasEditPermissions"-->
              <!--                  tooltipClass="medium-large-size-tooltip"-->
              <!--                  container="body"-->
              <!--                >-->
              <!--                  <mat-icon>attach_file</mat-icon>-->
              <!--                </button>-->
              <!--              </div>-->
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">

            <mat-form-field floatLabel="always" class="w-100" title="Fler än ett källarplan">
              <mat-label title="Fler än ett källarplan">Fler än ett källarplan</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !document.building.hasBasementFloor"
                [(ngModel)]="document.building.hasSeveralBasementFloors"
                #hasSeveralBasementFloors="ngModel"
                name="hasSeveralBasementFloors"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="hasSeveralBasementFloorsRadio"
                  #hasSeveralBasementFloorsRadio="ngModel"
                  [disabled]="!hasEditPermissions || !document.building.hasBasementFloor"
                  [(ngModel)]="document.building.hasSeveralBasementFloors"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>
              </div>
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" title="Betraktas som mindre entresolplan">
              <mat-label>Betraktas som mindre entresolplan</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions || !document.building.hasEntresolplan || document.building.isConsideredEntresolplan !== false"
                [(ngModel)]="document.building.isConsideredSmallerEntresolplan"
                #isConsideredSmallerEntresolplan="ngModel"
                name="isConsideredSmallerEntresolplan"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="isConsideredSmallerEntresolplanRadio"
                  #isConsideredSmallerEntresolplanRadio="ngModel"
                  [disabled]="!hasEditPermissions || !document.building.hasEntresolplan || document.building.isConsideredEntresolplan !== false"
                  [(ngModel)]="document.building.isConsideredSmallerEntresolplan"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>
              </div>
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" title="Kulvert">
              <mat-label title="Kulvert">Kulvert</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.hasCulvert"
                #hasCulvert="ngModel"
                name="hasCulvert"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="hasCulvertRadio"
                  #hasCulvertRadio="ngModel"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.building.hasCulvert"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>
              </div>
            </mat-form-field>

            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Typ av kulvert"
              [values]="culvertTypes"
              [selectedKeys]="document.building.culvertTypeIds"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="culvertTypeIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Typ av kulvert"
              *ngIf="hasEditPermissions"
            >
              <mat-label>Typ av kulvert</mat-label>

              <mat-select
                required
                [disabled]="!document.building.hasCulvert || !hasEditPermissions"
                [(ngModel)]="document.building.culvertTypeIds"
                #culvertTypeIds="ngModel"
                name="culvertTypeIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="culvertTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let culvertTypeId of culvertTypes | keyvalue"
                  [value]="culvertTypeId.key"
                  [title]="culvertTypeId.value.displayName"
                  [disabled]="culvertTypeId.value.isDisabled"
                >{{ culvertTypeId.value.displayName }}</mat-option
                >
              </mat-select>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[occuredBuildingActivityIds.name]"-->
              <!--                [class.text-secondary-primary]="notes[occuredBuildingActivityIds.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[occuredBuildingActivityIds.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="occuredBuildingActivityIds.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Byggnaden är sammanbyggd med">
              <mat-label title="The building is connected with">Byggnaden är sammanbyggd med</mat-label>
              <input
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.buildingConnectionWith"
                #buildingConnectionWith="ngModel"
                name="buildingConnectionWith"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[buildingConnectionWith.name]"-->
<!--                [class.text-secondary-primary]="notes[buildingConnectionWith.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[buildingConnectionWith.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="buildingConnectionWith.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>

            <mat-form-field class="w-100" title="Aktuell byggnadsdel ingår i samma byggnadskomplex tillsammans med följande byggnadsdelar">
              <mat-label title="Aktuell byggnadsdel ingår i samma byggnadskomplex tillsammans med följande byggnadsdelar">
                Aktuell byggnadsdel ingår i samma byggnadskomplex tillsammans med följande byggnadsdelar
              </mat-label>
              <input
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.buildingIsIncludedInSameBuildingComplex"
                #buildingIsIncludedInSameBuildingComplex="ngModel"
                name="buildingIsIncludedInSameBuildingComplex"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Intilliggande byggnader finns i form av">
              <mat-label title="Adjacent buildings exist in the form of"
                >Intilliggande byggnader finns i form av</mat-label
              >
              <input
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.adjacentBuildings"
                #adjacentBuildings="ngModel"
                name="adjacentBuildings"
                (blur)="onFormDataChanged()"
              />
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[adjacentBuildings.name]"-->
<!--                [class.text-secondary-primary]="notes[adjacentBuildings.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[adjacentBuildings.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="adjacentBuildings.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>

            <mat-form-field class="w-100" title="Aktuell byggnad/byggnadskomplex är sammanbyggd via kulvert med annan byggnad/byggnadskomplex inom fastigheten">
              <mat-label title="Aktuell byggnad/byggnadskomplex är sammanbyggd via kulvert med annan byggnad/byggnadskomplex inom fastigheten">
                Aktuell byggnad/byggnadskomplex är sammanbyggd via kulvert med annan byggnad/byggnadskomplex inom fastigheten
              </mat-label>
              <input
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.buildingBuiltTogetherViaCulvert"
                #buildingBuiltTogetherViaCulvert="ngModel"
                name="buildingBuiltTogetherViaCulvert"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Byggnadens brandtekniska lösning är projekterad med"
            >
              <mat-label title="The building's fire technical solution is designed with"
                >Byggnadens brandtekniska lösning är projekterad med</mat-label
              >
              <input
                matInput
                required
                class="d-none"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.fireTechnicalSolutionTypeId"
                #fireTechnicalSolutionTypeId="ngModel"
                name="fireTechnicalSolutionTypeId"
              />
              <mat-radio-group
                class="d-block"
                name="fireTechnicalSolutionTypeIdRadio"
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.fireTechnicalSolutionTypeId"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-radio-button
                  *ngFor="let fireTechnicalSolutionType of fireTechnicalSolutionTypes | keyvalue"
                  [value]="fireTechnicalSolutionType.key"
                  [title]="fireTechnicalSolutionType.value.displayName"
                  class="mr-3"
                  >{{ fireTechnicalSolutionType.value.displayName }}</mat-radio-button
                >
              </mat-radio-group>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[fireTechnicalSolutionTypeId.name]"-->
<!--                [class.text-secondary-primary]="notes[fireTechnicalSolutionTypeId.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[fireTechnicalSolutionTypeId.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="fireTechnicalSolutionTypeId.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Egna riktlinjer/ambitioner tillämpas">
              <mat-label>Egna riktlinjer/ambitioner tillämpas</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.isOwnGuidelinesAmbitionsApplied"
                #isOwnGuidelinesAmbitionsApplied="ngModel"
                name="isOwnGuidelinesAmbitionsApplied"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="isOwnGuidelinesAmbitionsAppliedRadio"
                  #isOwnGuidelinesAmbitionsAppliedRadio="ngModel"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.building.isOwnGuidelinesAmbitionsApplied"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Följande egna riktlinjer/ambitioner är aktuella">
              <mat-label>Följande egna riktlinjer/ambitioner är aktuella</mat-label>
              <textarea
                rows="10"
                required
                matInput
                [disabled]="!hasEditPermissions || !document.building.isOwnGuidelinesAmbitionsApplied"
                [(ngModel)]="document.building.followingOwnGuidelineAmbitionsRelevant"
                #followingOwnGuidelineAmbitionsRelevant="ngModel"
                name="followingOwnGuidelineAmbitionsRelevant"
                (blur)="onFormDataChanged()"
              ></textarea>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Beskrivning av analytisk dimensionering">
              <mat-label title="Description of analytical dimensioning"
              >Beskrivning av analytisk dimensionering</mat-label
              >
              <textarea
                rows="10"
                required
                matInput
                [disabled]="document.building.fireTechnicalSolutionTypeId !== DocumentBuilding.analyticalDimensioningId || !hasEditPermissions"
                [(ngModel)]="document.building.analyticalDimensioningDescription"
                #analyticalDimensioningDescription="ngModel"
                name="analyticalDimensioningDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[analyticalDimensioningDescription.name]"-->
              <!--                [class.text-secondary-primary]="notes[analyticalDimensioningDescription.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[analyticalDimensioningDescription.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="analyticalDimensioningDescription.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Publika lokaler">
              <mat-label>Publika lokaler</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.isPublicPremises"
                #isPublicPremises="ngModel"
                name="isPublicPremises"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="isPublicPremisesRadio"
                  #isPublicPremisesRadio="ngModel"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.building.isPublicPremises"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>
              </div>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field floatLabel="always" class="w-100" title="Inom byggnaden tillämpas tillfällig övernattning (skolverksamhet)">
              <mat-label>Inom byggnaden tillämpas tillfällig övernattning (skolverksamhet)</mat-label>
              <input
                matInput
                class="d-none"
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.isAppliedTemporaryOvernightAccommodation"
                #isAppliedTemporaryOvernightAccommodation="ngModel"
                name="isAppliedTemporaryOvernightAccommodation"
              />
              <div class="d-flex">
                <mat-radio-group
                  class="d-block"
                  name="isAppliedTemporaryOvernightAccommodationRadio"
                  #isAppliedTemporaryOvernightAccommodationRadio="ngModel"
                  [disabled]="!hasEditPermissions"
                  [(ngModel)]="document.building.isAppliedTemporaryOvernightAccommodation"
                  (ngModelChange)="onFormDataChanged()"
                >
                  <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                  <mat-radio-button [value]="false">Nej</mat-radio-button>
                </mat-radio-group>
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Följande verksamheter förekommer i byggnaden"
              [values]="occuredBuildingActivitiesTypes"
              [selectedKeys]="document.building.occuredBuildingActivityIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="occuredBuildingActivityIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Följande verksamheter förekommer i byggnaden"
              *ngIf="hasEditPermissions"
            >
              <mat-label title="The following activities occur in the building"
              >Följande verksamheter förekommer i byggnaden</mat-label
              >

              <mat-select
                required
                [(ngModel)]="document.building.occuredBuildingActivityIds"
                #occuredBuildingActivityIds="ngModel"
                name="occuredBuildingActivityIds"
                multiple
                [appDocumentNotApplicableSelectOptions]="occuredBuildingActivitiesTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let occuredBuildingActivitiesType of occuredBuildingActivitiesTypes | keyvalue"
                  [value]="occuredBuildingActivitiesType.key"
                  [title]="occuredBuildingActivitiesType.value.displayName"
                  [disabled]="occuredBuildingActivitiesType.value.isDisabled"
                >{{ occuredBuildingActivitiesType.value.displayName }}</mat-option
                >
              </mat-select>
              <!--              <button-->
              <!--                mat-icon-button-->
              <!--                matSuffix-->
              <!--                *ngIf="hasEditPermissions || notes[occuredBuildingActivityIds.name]"-->
              <!--                [class.text-secondary-primary]="notes[occuredBuildingActivityIds.name]"-->
              <!--                appSectionFieldNote-->
              <!--                [ngbTooltip]="notes[occuredBuildingActivityIds.name]?.note"-->
              <!--                [documentId]="document?.id"-->
              <!--                [sectionType]="sectionType"-->
              <!--                [sectionFieldId]="occuredBuildingActivityIds.name"-->
              <!--                [notes]="notes"-->
              <!--                [isViewOnly]="!hasEditPermissions"-->
              <!--                tooltipClass="medium-large-size-tooltip"-->
              <!--                container="body"-->
              <!--              >-->
              <!--                <mat-icon>attach_file</mat-icon>-->
              <!--              </button>-->
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Storkök med"
              [values]="largeKitchenWithOptionTypes"
              [selectedKeys]="document.building.largeKitchenWithIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="largeKitchenWithIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Storkök med"
            >
              <mat-label>Storkök med</mat-label>

              <mat-select
                required
                [(ngModel)]="document.building.largeKitchenWithIds"
                #largeKitchenWithIds="ngModel"
                name="largeKitchenWithIds"
                multiple
                [disabled]="!hasEditPermissions || !isLargeKitchenWithIdsEnabled"
                [appDocumentNotApplicableSelectOptions]="largeKitchenWithOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let largeKitchenWithOptionType of largeKitchenWithOptionTypes | unordKeyValue"
                  [value]="largeKitchenWithOptionType.key"
                  [title]="largeKitchenWithOptionType.value.displayName"
                  [disabled]="largeKitchenWithOptionType.value.isDisabled"
                >{{ largeKitchenWithOptionType.value.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Skyddslösning för storkök med förhöjd sannolikhet för uppkomst av brand"
              [values]="protectiveSolutionForLargeKitchenOptionTypes"
              [selectedKeys]="document.building.protectiveSolutionForLargeKitchenIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="protectiveSolutionForLargeKitchenIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Skyddslösning för storkök med förhöjd sannolikhet för uppkomst av brand"
            >
              <mat-label>Skyddslösning för storkök med förhöjd sannolikhet för uppkomst av brand</mat-label>

              <mat-select
                required
                [(ngModel)]="document.building.protectiveSolutionForLargeKitchenIds"
                #protectiveSolutionForLargeKitchenIds="ngModel"
                name="protectiveSolutionForLargeKitchenIds"
                multiple
                [disabled]="!hasEditPermissions || !isProtectiveSolutionForLargeKitchenIdsEnabled"
                [appDocumentNotApplicableSelectOptions]="protectiveSolutionForLargeKitchenOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let protectiveSolutionForLargeKitchenOptionType of protectiveSolutionForLargeKitchenOptionTypes | unordKeyValue"
                  [value]="protectiveSolutionForLargeKitchenOptionType.key"
                  [title]="protectiveSolutionForLargeKitchenOptionType.value.displayName"
                  [disabled]="protectiveSolutionForLargeKitchenOptionType.value.isDisabled"
                >{{ protectiveSolutionForLargeKitchenOptionType.value.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Samlingslokal förekommer i form av"
              [values]="meetingRoomOccursInFormOfOptionTypes"
              [selectedKeys]="document.building.meetingRoomOccursInFormOfIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="meetingRoomOccursInFormOfIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Samlingslokal förekommer i form av"
            >
              <mat-label>Samlingslokal förekommer i form av</mat-label>

              <mat-select
                required
                [(ngModel)]="document.building.meetingRoomOccursInFormOfIds"
                #meetingRoomOccursInFormOfIds="ngModel"
                name="meetingRoomOccursInFormOfIds"
                multiple
                [disabled]="!hasEditPermissions || !isMeetingRoomOccursInFormOfIdsAndNumberOfPeopleInMeetingRoomIdsEnabled"
                [appDocumentNotApplicableSelectOptions]="meetingRoomOccursInFormOfOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let meetingRoomOccursInFormOfOptionType of meetingRoomOccursInFormOfOptionTypes | unordKeyValue"
                  [value]="meetingRoomOccursInFormOfOptionType.key"
                  [title]="meetingRoomOccursInFormOfOptionType.value.displayName"
                  [disabled]="meetingRoomOccursInFormOfOptionType.value.isDisabled"
                >{{ meetingRoomOccursInFormOfOptionType.value.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Dimensionerande personantal inom samlingslokal"
              [values]="numberOfPeopleInMeetingRoomOptionTypes"
              [selectedKeys]="document.building.numberOfPeopleInMeetingRoomIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="numberOfPeopleInMeetingRoomIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Dimensionerande personantal inom samlingslokal"
            >
              <mat-label>Dimensionerande personantal inom samlingslokal</mat-label>

              <mat-select
                required
                [(ngModel)]="document.building.numberOfPeopleInMeetingRoomIds"
                #numberOfPeopleInMeetingRoomIds="ngModel"
                name="numberOfPeopleInMeetingRoomIds"
                multiple
                [disabled]="!hasEditPermissions || !isMeetingRoomOccursInFormOfIdsAndNumberOfPeopleInMeetingRoomIdsEnabled"
                [appDocumentNotApplicableSelectOptions]="numberOfPeopleInMeetingRoomOptionTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let numberOfPeopleInMeetingRoomOptionType of numberOfPeopleInMeetingRoomOptionTypes | unordKeyValue"
                  [value]="numberOfPeopleInMeetingRoomOptionType.key"
                  [title]="numberOfPeopleInMeetingRoomOptionType.value.displayName"
                  [disabled]="numberOfPeopleInMeetingRoomOptionType.value.isDisabled"
                >{{ numberOfPeopleInMeetingRoomOptionType.value.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Stommens konstruktion utgörs översiktligt av">
              <mat-label title="Stommens konstruktion utgörs översiktligt av">Stommens konstruktion utgörs översiktligt av</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.frameConstructionConsists"
                #frameConstructionConsists="ngModel"
                name="frameConstructionConsists"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>

            <mat-form-field class="w-100" title="Fasadens konstruktion utgörs översiktligt av">
              <mat-label title="Fasadens konstruktion utgörs översiktligt av">Fasadens konstruktion utgörs översiktligt av</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.facadeConstructionConsists"
                #facadeConstructionConsists="ngModel"
                name="facadeConstructionConsists"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Bjälklagets konstruktion utgörs översiktligt av">
              <mat-label title="Bjälklagets konstruktion utgörs översiktligt av">Bjälklagets konstruktion utgörs översiktligt av</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.beamConstructionConsists"
                #beamConstructionConsists="ngModel"
                name="beamConstructionConsists"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>

            <mat-form-field class="w-100" title="Yttertakets konstruktion utgörs översiktligt av">
              <mat-label title="Yttertakets konstruktion utgörs översiktligt av">Yttertakets konstruktion utgörs översiktligt av</mat-label>
              <input
                matInput
                required
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.roofConstructionConsists"
                #roofConstructionConsists="ngModel"
                name="roofConstructionConsists"
                (blur)="onFormDataChanged()"
              />
            </mat-form-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad byggnads- och verksamhetsbeskrivning">
              <mat-label title="Extended building and business description"
                >Utökad byggnads- och verksamhetsbeskrivning</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.building.extendedBuildingDescription"
                #extendedBuildingDescription="ngModel"
                name="extendedBuildingDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[extendedBuildingDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[extendedBuildingDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[extendedBuildingDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="extendedBuildingDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
