<div class="table-container organizations-page-table-container d-flex">
  <div class="text-center m-4 text-muted text-extra-large w-100 align-self-center" *ngIf="state.isLoading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>

  <div class="text-center m-4 w-100 align-self-center" *ngIf="state.isFailed">
    <ng-container> Det gick inte att läsa in organizations.</ng-container>
    <div class="mt-2">
      <ng-container>Klick </ng-container>
      <button type="button" (click)="onTryLoadOrganizations()" class="btn btn-sm btn-outline-primary" title="Refresh">
        <i class="fas fa-sync-alt"></i>
        <ng-container> Uppdatera</ng-container>
      </button>
      <ng-container> att försöka igen.</ng-container>
    </div>
  </div>

  <app-organizations-table
    *ngIf="!state.isLoading && !state.isFailed"
    [isDisabled]="state.isDeactivating"
    [items]="dataSourceItems"
    [sortOrder]="pageRequest.sort"
    [hasSuperAdminPermissions]="hasSuperAdminPermissions"
    (sortOrderChanged)="onSortOrderChanged($event)"
    (activateOrganization)="onActivateOrganization($event)"
    (deactivateOrganization)="onDeactivateOrganization($event)"
    (organizationUpdated)="onOrganizationUpdated($event)"
  >
  </app-organizations-table>
</div>
