import { ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { reduce, trimEnd, trimStart } from 'lodash';
import { StringUtils } from './string-utils';

export class RouterUtils {
  static getRouteParamRecursively(name: string, route: ActivatedRouteSnapshot): string {
    if (!route) {
      return null;
    }

    let value: string;
    if (route.paramMap.has(name)) {
      value = route.paramMap.get(name);
    } else {
      value = RouterUtils.getRouteParamRecursively(name, route.parent);
    }

    return value;
  }

  static convertParamMapToStringMap(params: ParamMap): StringMap<string | string[]> {
    if (!params) {
      return {};
    }

    const convertedParams = reduce(
      params.keys,
      (result: StringMap<string | string[]>, key: string) => {
        const values = params.getAll(key);
        result[key] = values && values.length > 1 ? values : values[0];
        return result;
      },
      {}
    );

    return convertedParams;
  }

  static equalsRoutesIgnoreQueryParams(first: string, second: string) {
    const trimmedFirstRoute = RouterUtils.trimRouteExtras(first);
    const trimmedSecondRoute = RouterUtils.trimRouteExtras(second);

    const areEquals = StringUtils.equalsIgnoreCase(trimmedFirstRoute, trimmedSecondRoute);

    return areEquals;
  }

  private static trimRouteExtras(route: string) {
    let result = !StringUtils.isNullOrEmpty(route) ? route : '';

    result = result.trim();

    const queryParamsSeparatorIndex = result.indexOf('?');
    if (queryParamsSeparatorIndex !== -1) {
      result = result.substr(0, queryParamsSeparatorIndex);
    }

    const hashIndex = result.indexOf('#');
    if (hashIndex !== -1) {
      result = result.substr(0, hashIndex);
    }

    result = trimEnd(result, '/');
    result = trimStart(result, '/');

    return result;
  }
}
