<div class="dashboard-page h-100 d-flex flex-column">
  <div>
    <app-dashboard-header
      [documentCreators]="documentCreators"
      [documentRevisers]="documentRevisers"
      [itemsCount]=dataSource.filteredItems.length
      [page]="dataSourceState.page"
      [pageSize]="dataSourceState.pageSize"
      [hasEditPermissions]="hasEditPermissions$ | async"
      (createNewDocument)="onCreateNewDocument()"
      (pageChanged)="onPageChanged($event)"
      (pageSizeChanged)="onPageSizeChanged($event)"
      (filterChanged)="onFilterChanged($event)"
      (sectionFilterChanged)="onSectionFilterChanged($event)"
      (showDeletedDocumentsChanged)="onShowDeletedDocumentsChanged($event)"
    ></app-dashboard-header>
  </div>
  <div class="flex-grow-1">
    <div class="table-container dashboard-page-table-container d-flex">
      <div class="text-center m-4 text-muted text-extra-large w-100 align-self-center" *ngIf="state.isLoading">
        <i class="fas fa-spinner fa-pulse"></i>
      </div>

      <div class="text-center m-4 w-100 align-self-center" *ngIf="state.isFailed">
        <ng-container>Det gick inte att läsa in dokument.</ng-container>
        <div class="mt-2">
          <ng-container>Klick</ng-container
          ><button
            type="button"
            (click)="onTryLoadDocuments()"
            class="btn btn-sm btn-outline-primary mx-1"
            title="Refresh"
          >
            <i class="fa fa-sync-alt mr-1"></i><ng-container>Uppdatera</ng-container></button
          ><ng-container>att försöka igen.</ng-container>
        </div>
      </div>

      <app-dashboard-table
        *ngIf="!state.isLoading && !state.isFailed"
        [items]="dataSource.paginatedItems"
        [isDisabled]="state.isLoading"
        [hasEditPermissions]="hasEditPermissions$ | async"
        [hasSuperAdminOrAuthorPermissions]="hasSuperAdminOrAuthorPermission$ | async"
        (restoreDocument)="onRestoreDocument($event)"
        (moveToBinDocument)="onMoveToBinDocument($event)"
        (deleteDocument)="onDeleteDocument($event)"
      ></app-dashboard-table>
    </div>
  </div>
</div>
