import { Action } from '@ngrx/store';

export enum LayoutActionType {
  OpenLeftMenu = '[Layout] OpenLeftMenu',
}

export class OpenLeftMenuAction implements Action {
  readonly type = LayoutActionType.OpenLeftMenu;

  isLeftMenuOpened: boolean;

  constructor(isLeftMenuOpened: boolean) {
    this.isLeftMenuOpened = isLeftMenuOpened;
  }
}

export type LayoutActions = OpenLeftMenuAction;
