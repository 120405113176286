<div class="d-flex justify-content-between mb-2">
  <h5 class="mt-1">{{ pageTitle }}</h5>
  <div class="d-flex justify-content-end">
    <div class="mr-4">
      <input
        type="text"
        value=""
        (input)="onFilterChanged($event.target.value)"
        class="form-control"
        placeholder="Sök"
      />
    </div>
    <div>
      <button mat-flat-button class="button-border manage-btn mr-2" (click)="onManagePermissions()">
        Hantera läsarrättigheter
      </button>
      <button
        mat-flat-button
        class="button-border manage-btn"
        appEditUserTrigger
        [usersType]="usersType"
        [organizationId]="organizationId"
        [currentUserAuthDetails]="currentUserAuthDetails"
        (created)="onUserCreated($event)"
      >
        Skapa ny användare
      </button>
    </div>
    <div>
      <mat-paginator
        [pageIndex]="page"
        [length]="itemsCount"
        [pageSize]="pageSize"
        [hidePageSize]="true"
        (page)="onPageChanged($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
