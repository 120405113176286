export class DomUtils {
  static getSublingsBetweenTwoElements(firstElement: Node, secondElement: Node): Node[] {
    if (!firstElement) {
      throw new Error('First element must be defined.');
    }
    if (!secondElement) {
      throw new Error('Second element must be defined.');
    }

    const sublings: Node[] = [];

    let currentElement: Node = firstElement.nextSibling;
    while (currentElement && currentElement !== secondElement) {
      sublings.push(currentElement);
      currentElement = currentElement.nextSibling;
    }

    return sublings;
  }

  static scrollTo(selector: string, offset: number = 0): void {
    if (!selector) {
      throw new Error('Selector must be defined.');
    }

    const scrollingElement = document.scrollingElement || document.documentElement;
    if (scrollingElement) {
      // IE might need a polyfill here
      const target = document.querySelector(selector) as HTMLElement;
      if (target) {
        scrollingElement.scrollTop = this.getTopOffset(target) + offset;
      }
    }
  }

  static getTopOffset(element: HTMLElement): number {
    let offsetTop = 0;
    let currentElement = element;
    while (currentElement) {
      offsetTop += currentElement.offsetTop;
      currentElement = currentElement.offsetParent as HTMLElement;
    }

    return offsetTop;
  }

  static scrollElementTo(selector: string, offset: number = 0) {
    const scrollingElement = document.querySelector(selector) as HTMLElement;
    if (scrollingElement) {
      scrollingElement.scrollTop = offset;
    }
  }
}
