import { Injectable } from '@angular/core';
import _ from 'lodash';
import { isEmpty, map } from 'lodash';
import moment from 'moment';
import { ListResponse } from '../core/api';
import { validate, required, Assert } from '../shared';
import { DocumentsValidationService } from './documents-validation.service';
import {
  Document,
  DocumentInfo,
  DocumentObject,
  DocumentBuilding,
  DocumentEvacuation,
  DocumentFire,
  DocumentSpread,
  DocumentCapacity,
  DocumentInstallations,
  DocumentAirTreatment,
  DocumentLifts,
  DocumentOpportunities,
  DocumentRisks,
  DocumentProtectedObjects,
  Renovation,
  DocumentFieldNote,
} from './shared';
import {
  IDocumentAirTreatmentServer,
  IDocumentBuildingServer,
  IDocumentCapacityServer,
  IDocumentEvacuationServer,
  IDocumentFieldNoteServerRequest,
  IDocumentFieldNoteServerResponse,
  IDocumentFireServer,
  IDocumentInfoServer,
  IDocumentInspectionServer,
  IDocumentInstallationsServer,
  IDocumentLiftsServer,
  IDocumentObjectServer,
  IDocumentOpportunitiesServer,
  IDocumentProtectedObjectsServer,
  IDocumentRisksServer,
  IDocumentSpreadServer,
  IGetDocumentServer,
  IRenovationServer,
  ISaveDocumentServer,
} from './shared/document-server-interfaces';
import { MathUtils } from '../shared/utils/math-utils';
import { DocumentInspection } from './shared/document/document-inspection';

@Injectable({
  providedIn: 'root',
})
export class DocumentsApiMapperService {
  constructor(private readonly documentsValidationService: DocumentsValidationService) {}

  @validate
  mapDocumentsResponse(@required response: ListResponse<IGetDocumentServer>): Document[] {
    Assert.isNotNull(response.list, 'response.list');

    if (response.list.length === 0) {
      return [];
    }

    const documents = map(response.list, (document: IGetDocumentServer) => this.mapDocumentResponse(document));

    return documents;
  }

  @validate
  mapDocumentResponse(@required documentResponse: IGetDocumentServer): Document {
    const deletedDate = documentResponse.deletedDate ? moment.utc(documentResponse.deletedDate) : null;
    const revisedDate = documentResponse.revisedDate ? moment.utc(documentResponse.revisedDate) : null;
    const document = new Document({
      id: documentResponse.id,
      organizationId: documentResponse.organizationId,
      organizationName: documentResponse.organizationName,
      creatorId: documentResponse.creatorId,
      creatorName: documentResponse.creatorName,
      creatorOrganizationId: documentResponse.creatorOrganizationId,
      reviserId: documentResponse.reviserId,
      reviserName: documentResponse.reviserName,
      reviserOrganizationId: documentResponse.reviserOrganizationId,
      isPublished: documentResponse.isPublished,
      createdDate: moment.utc(documentResponse.createdDate),
      updatedDate: moment.utc(documentResponse.updatedDate),
      revisedDate: revisedDate,
      deletedDate: deletedDate,
      info: this.mapDocumentInfoResponse(documentResponse.info),
      object: this.mapDocumentObjectResponse(documentResponse.object),
      building: this.mapDocumentBuildingResponse(documentResponse.building),
      evacuation: this.mapDocumentEvacuationResponse(documentResponse.evacuation),
      fire: this.mapDocumentFireResponse(documentResponse.fire),
      spread: this.mapDocumentSpreadResponse(documentResponse.spread),
      capacity: this.mapDocumentCapacityResponse(documentResponse.capacity),
      installations: this.mapDocumentInstallationsResponse(documentResponse.installations),
      airtreatment: this.mapDocumentAirTreatmentResponse(documentResponse.airTreatment),
      lifts: this.mapDocumentLiftsResponse(documentResponse.lifts),
      opportunities: this.mapDocumentOpportunitiesResponse(documentResponse.opportunities),
      risks: this.mapDocumentRisksResponse(documentResponse.risks),
      protectedObjects: this.mapDocumentProtectedObjectsResponse(documentResponse.protectedObjects),
      inspection: this.mapDocumentInspectionResponse(documentResponse.inspection),
    });

    // Validate document objects after document creation
    document.info.isValid = this.documentsValidationService.isInfoValid(document.info, document);
    document.object.isValid = this.documentsValidationService.isObjectValid(document.object, document);
    document.building.isValid = this.documentsValidationService.isBuildingValid(document.building, document);
    document.evacuation.isValid = this.documentsValidationService.isEvacuationValid(document.evacuation, document);
    document.fire.isValid = this.documentsValidationService.isFireValid(document.fire, document);
    document.spread.isValid = this.documentsValidationService.isSpreadValid(document.spread, document);
    document.capacity.isValid = this.documentsValidationService.isCapacityValid(document.capacity, document);
    document.installations.isValid = this.documentsValidationService.isInstallationsValid(document.installations, document);
    document.airtreatment.isValid = this.documentsValidationService.isAirTreatmentValid(document.airtreatment, document);
    document.lifts.isValid = this.documentsValidationService.isLiftsValid(document.lifts, document);
    document.opportunities.isValid = this.documentsValidationService.isOpportunitiesValid(document.opportunities, document);
    document.risks.isValid = this.documentsValidationService.isRisksValid(document.risks, document);
    document.protectedObjects.isValid = this.documentsValidationService.isProtectedObjectsValid(document.protectedObjects, document);
    document.inspection.isValid = this.documentsValidationService.isInspectionValid(document.inspection, document);

    return document;
  }

  @validate
  mapDocumentForServer(@required document: Document): ISaveDocumentServer {
    const body = {
      id: document.id,
      organizationId: document.organizationId,
      info: this.mapDocumentInfoForServer(document.info),
      object: this.mapDocumentObjectForServer(document.object),
      building: this.mapDocumentBuildingForServer(document.building),
      evacuation: this.mapDocumentEvacuationForServer(document.evacuation),
      fire: this.mapDocumentFireForServer(document.fire),
      spread: this.mapDocumentSpreadForServer(document.spread),
      capacity: this.mapDocumentCapacityForServer(document.capacity),
      installations: this.mapDocumentInstallationsForServer(document.installations),
      airTreatment: this.mapDocumentAirTreatmentForServer(document.airtreatment),
      lifts: this.mapDocumentLiftsForServer(document.lifts),
      opportunities: this.mapDocumentOpportunitiesForServer(document.opportunities),
      risks: this.mapDocumentRisksForServer(document.risks),
      protectedObjects: this.mapDocumentProtectedObjectsForServer(document.protectedObjects),
      inspection: this.mapDocumentInspectionForServer(document.inspection),
    } as ISaveDocumentServer;

    return body;
  }

  @validate
  mapDocumentFieldNotesResponse(@required response: IDocumentFieldNoteServerResponse[]): StringMap<DocumentFieldNote> {
    if (response.length === 0) {
      return {};
    }

    const documents = map(response, (document: IDocumentFieldNoteServerResponse) => {
      const fieldNote = this.mapDocumentFieldNoteResponse(document);

      return fieldNote;
    });

    const res = _.keyBy(documents, 'fieldId') as StringMap<DocumentFieldNote>;
    return res;
  }

  @validate
  mapDocumentFieldNotesForServer(@required notes: DocumentFieldNote[]): IDocumentFieldNoteServerRequest[] {
    if (!notes) {
      return [];
    }

    const notesBody = map(notes, (note) => this.mapDocumentFieldNoteForServer(note));

    return notesBody;
  }

  @validate
  mapDocumentFieldNoteForServer(@required note: DocumentFieldNote): IDocumentFieldNoteServerRequest {
    if (!note) {
      return null;
    }

    const noteBody = {
      fieldId: note.fieldId,
      note: note.note,
    } as IDocumentFieldNoteServerRequest;

    return noteBody;
  }

  @validate
  mapDocumentFieldNoteResponse(@required response: IDocumentFieldNoteServerResponse): DocumentFieldNote {
    const documentFieldNote = new DocumentFieldNote({
      fieldId: response.fieldId,
      note: response.note,
      authorName: response.authorName,
      updateDate: moment.utc(response.updateDate),
    });

    return documentFieldNote;
  }

  @validate
  private mapDocumentInfoResponse(@required infoResponse: IDocumentInfoServer): DocumentInfo {
    const documentInfo = new DocumentInfo({
      buildingName: infoResponse.buildingName,
      objectNumber: infoResponse.objectNumber,
      // reviewerId: infoResponse.reviewerId,
      // reviewerName: infoResponse.reviewerName,
      documentStatusId: infoResponse.documentStatusId,
      isManagementDrawingFireProtectionDrawnUp: infoResponse.isManagementDrawingFireProtectionDrawnUp,
      isEvacuationPlanDrawnUp: infoResponse.isEvacuationPlanDrawnUp,
      isActionPlanDrawnUp: infoResponse.isActionPlanDrawnUp,
      propertyOwner: infoResponse.propertyOwner,
      siteVisitDate: moment.utc(infoResponse.siteVisitDate),
      isCompleted: infoResponse.isCompleted || false,
    });

    return documentInfo;
  }

  @validate
  private mapDocumentObjectResponse(@required objectResponse: IDocumentObjectServer): DocumentObject {
    const documentObject = new DocumentObject({
      propertyName: objectResponse.propertyName,
      municipality: objectResponse.municipality,
      area: objectResponse.area,
      areaM2: MathUtils.convertNumberToString(objectResponse.areaM2),
      building: objectResponse.building,
      constructionYear: objectResponse.constructionYear,
      renovations: this.mapDocumentObjectRenovationsResponse(objectResponse.renovations),
      changesDescription: objectResponse.changesDescription,
      isCompleted: objectResponse.isCompleted || false,
    });

    return documentObject;
  }

  @validate
  private mapDocumentBuildingResponse(@required buildingResponse: IDocumentBuildingServer): DocumentBuilding {
    const buildingObject = new DocumentBuilding({
      numberOfStoreys: buildingResponse.numberOfStoreys,
      fireTechnicalBuildingClassId: buildingResponse.fireTechnicalBuildingClassId,
      isBr0AnalysisEstablished: buildingResponse.isBr0AnalysisEstablished,
      dimensioningFireLoadId: buildingResponse.dimensioningFireLoadId,
      hasBasementFloor: buildingResponse.hasBasementFloor,
      isConsideredBasementFloorPlan: buildingResponse.isConsideredBasementFloorPlan,
      hasSeveralBasementFloors: buildingResponse.hasSeveralBasementFloors,
      hasAtticFloor: buildingResponse.hasAtticFloor,
      isConsideredAtticFloorPlan: buildingResponse.isConsideredAtticFloorPlan,
      hasEntresolplan: buildingResponse.hasEntresolplan,
      isConsideredEntresolplan: buildingResponse.isConsideredEntresolplan,
      isConsideredSmallerEntresolplan: buildingResponse.isConsideredSmallerEntresolplan,
      hasSouterrainDesign: buildingResponse.hasSouterrainDesign,
      isConsideredSouterrainDesignPlan: buildingResponse.isConsideredSouterrainDesignPlan,
      hasCulvert: buildingResponse.hasCulvert,
      culvertTypeIds: buildingResponse.culvertTypeIds,
      buildingConnectionWith: buildingResponse.buildingConnectionWith,
      buildingIsIncludedInSameBuildingComplex: buildingResponse.buildingIsIncludedInSameBuildingComplex,
      adjacentBuildings: buildingResponse.adjacentBuildings,
      buildingBuiltTogetherViaCulvert: buildingResponse.buildingBuiltTogetherViaCulvert,
      fireTechnicalSolutionTypeId: buildingResponse.fireTechnicalSolutionTypeId,
      isOwnGuidelinesAmbitionsApplied: buildingResponse.isOwnGuidelinesAmbitionsApplied,
      followingOwnGuidelineAmbitionsRelevant: buildingResponse.followingOwnGuidelineAmbitionsRelevant,
      analyticalDimensioningDescription: buildingResponse.analyticalDimensioningDescription,
      occuredBuildingActivityIds: buildingResponse.occuredBuildingActivityIds,
      largeKitchenWithIds: buildingResponse.largeKitchenWithIds,
      protectiveSolutionForLargeKitchenIds: buildingResponse.protectiveSolutionForLargeKitchenIds,
      meetingRoomOccursInFormOfIds: buildingResponse.meetingRoomOccursInFormOfIds,
      numberOfPeopleInMeetingRoomIds: buildingResponse.numberOfPeopleInMeetingRoomIds,
      isPublicPremises: buildingResponse.isPublicPremises,
      isAppliedTemporaryOvernightAccommodation: buildingResponse.isAppliedTemporaryOvernightAccommodation,
      frameConstructionConsists: buildingResponse.frameConstructionConsists,
      beamConstructionConsists: buildingResponse.beamConstructionConsists,
      facadeConstructionConsists: buildingResponse.facadeConstructionConsists,
      roofConstructionConsists: buildingResponse.roofConstructionConsists,
      extendedBuildingDescription: buildingResponse.extendedBuildingDescription,
      isCompleted: buildingResponse.isCompleted || false,
    });

    return buildingObject;
  }

  @validate
  private mapDocumentEvacuationResponse(@required evacuationResponse: IDocumentEvacuationServer): DocumentEvacuation {
    const evacuationObject = new DocumentEvacuation({
      isWindowRemoval: evacuationResponse.isWindowRemoval,
      isOnlyOneEscapeRoute: evacuationResponse.isOnlyOneEscapeRoute,
      isEvacuationRescueService: evacuationResponse.isEvacuationRescueService,
      isEvacuationFromAnotherFireExit: evacuationResponse.isEvacuationFromAnotherFireExit,
      isEvacuationFromAnotherPartOfBuilding: evacuationResponse.isEvacuationFromAnotherPartOfBuilding,
      isHorizontalEvacuation: evacuationResponse.isHorizontalEvacuation,
      isEvacuationViaTr1: evacuationResponse.isEvacuationViaTr1,
      isOnlyEscapeRouteViaTr1: evacuationResponse.isOnlyEscapeRouteViaTr1,
      isTemporaryEvacuationAFS20201: evacuationResponse.isTemporaryEvacuationAFS20201,
      isReEntryRequirements: evacuationResponse.isReEntryRequirements,
      isEvacuationViaAutomaticHorizontalSlidingDoors: evacuationResponse.isEvacuationViaAutomaticHorizontalSlidingDoors,
      isEvacuationViaEvacuationElevator: evacuationResponse.isEvacuationViaEvacuationElevator,
      isEvacuationViaRescueElevator: evacuationResponse.isEvacuationViaRescueElevator,
      lockedDoorsIds: evacuationResponse.lockedDoorsIds,
      isEvacuationForDisabilitiesPeople: evacuationResponse.isEvacuationForDisabilitiesPeople,
      isAccessibleAndUsableHorizontalEvacuation: evacuationResponse.isAccessibleAndUsableHorizontalEvacuation,
      isCommunicationEquipmentEvacuation: evacuationResponse.isCommunicationEquipmentEvacuation,
      isEvacuationViaCommonPartOfSeparatedRoute: evacuationResponse.isEvacuationViaCommonPartOfSeparatedRoute,
      isMattressEvacuationOfBedriddenPatients: evacuationResponse.isMattressEvacuationOfBedriddenPatients,
      isClassifiedEscapeRoute: evacuationResponse.isClassifiedEscapeRoute,
      isEvacuationPlansInBuilding: evacuationResponse.isEvacuationPlansInBuilding,
      isImageRequirementsAccordingVk4: evacuationResponse.isImageRequirementsAccordingVk4,
      extendedEvacuationDescription: evacuationResponse.extendedEvacuationDescription,
      isCompleted: evacuationResponse.isCompleted || false,
    });

    return evacuationObject;
  }

  @validate
  private mapDocumentFireResponse(@required fireResponse: IDocumentFireServer): DocumentFire {
    const fireObject = new DocumentFire({
      isBuildingDividedIntoFireCells: fireResponse.isBuildingDividedIntoFireCells,
      isFireCellExceeds1250m2: fireResponse.isFireCellExceeds1250m2,
      isMoreThanTwoPlanes: fireResponse.isMoreThanTwoPlanes,
      isSpecialFireTechnicalClass: fireResponse.isSpecialFireTechnicalClass,
      isSeveralBusinessClasses: fireResponse.isSeveralBusinessClasses,
      hasDoorsOrHatchesInFireSeparationConstruction: fireResponse.hasDoorsOrHatchesInFireSeparationConstruction,
      hasInstallationDeviceOnDoors: fireResponse.hasInstallationDeviceOnDoors,
      hasAutomaticOpeningOnDoors: fireResponse.hasAutomaticOpeningOnDoors,
      hasWindowsInFireRetardantConstruction: fireResponse.hasWindowsInFireRetardantConstruction,
      hasFireWindowAtAngle: fireResponse.hasFireWindowAtAngle,
      hasFireLowHighPart: fireResponse.hasFireLowHighPart,
      hasFireRetardantCeilingsFloors: fireResponse.hasFireRetardantCeilingsFloors,
      hasAirFireLock: fireResponse.hasAirFireLock,
      hasCoveredYardBalconies: fireResponse.hasCoveredYardBalconies,
      hasFireCellInAttic: fireResponse.hasFireCellInAttic,
      hasFireTechnicalSeparationOfAeratedEaves: fireResponse.hasFireTechnicalSeparationOfAeratedEaves,
      hasWashingAndGarbageChute: fireResponse.hasWashingAndGarbageChute,
      hasPneumaticTube: fireResponse.hasPneumaticTube,
      fireRequirementIds: fireResponse.fireRequirementIds,
      sectioningThroughFirewallIds: fireResponse.sectioningThroughFirewallIds,
      fireTechnicalClassFirewallIds: fireResponse.fireTechnicalClassFirewallIds,
      olderFireTechnicalClassIds: fireResponse.olderFireTechnicalClassIds,
      fireCellMeetTechnicalClassIds: fireResponse.fireCellMeetTechnicalClassIds,
      fireSpecialConditionsDescription: fireResponse.fireSpecialConditionsDescription,
      fireExtendedDescription: fireResponse.fireExtendedDescription,
      isCompleted: fireResponse.isCompleted || false,
    });

    return fireObject;
  }

  @validate
  private mapDocumentSpreadResponse(@required spreadResponse: IDocumentSpreadServer): DocumentSpread {
    const spreadObject = new DocumentSpread({
      protectionAgainstFireOptionIds: spreadResponse.protectionAgainstFireOptionIds,
      roofingOptionIds: spreadResponse.roofingOptionIds,
      baseRoofingIds: spreadResponse.baseRoofingIds,
      facadeMaterialOptionIds: spreadResponse.facadeMaterialOptionIds,
      facadeMaterialOptionOther: spreadResponse.facadeMaterialOptionOther,
      protectionAgainstSpreadDescription: spreadResponse.protectionAgainstSpreadDescription,
      isCompleted: spreadResponse.isCompleted || false,
    });

    return spreadObject;
  }

  @validate
  private mapDocumentCapacityResponse(@required capacityResponse: IDocumentCapacityServer): DocumentCapacity {
    const capacityObject = new DocumentCapacity({
      fireCarryingCapacityId: capacityResponse.fireCarryingCapacityId,
      isDocumentedConsiderationOfLoadCapacityWithHighFireLoad: capacityResponse.isDocumentedConsiderationOfLoadCapacityWithHighFireLoad,
      isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem: capacityResponse.isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem,
      isDocumentedConsiderationOfProtectionNeedsForBr0Building: capacityResponse.isDocumentedConsiderationOfProtectionNeedsForBr0Building,
      loadBearingCapacityDescription: capacityResponse.loadBearingCapacityDescription,
      isCompleted: capacityResponse.isCompleted || false,
    });

    return capacityObject;
  }

  @validate
  private mapDocumentInstallationsResponse(
    @required installationsResponse: IDocumentInstallationsServer
  ): DocumentInstallations {
    const installationsObject = new DocumentInstallations({
      markingsEmergencyLightingIds: installationsResponse.markingsEmergencyLightingIds,
      powerSupplyEmergencyLightingIds: installationsResponse.powerSupplyEmergencyLightingIds,
      markingsEmergencyLightingDescription: installationsResponse.markingsEmergencyLightingDescription,
      isFireAlarmsOccur: installationsResponse.isFireAlarmsOccur,
      hasFireAlarmsFormalRequirements: installationsResponse.hasFireAlarmsFormalRequirements,
      hasFireAlarmsSeriesConnection: installationsResponse.hasFireAlarmsSeriesConnection,
      fireAlarmsDescription: installationsResponse.fireAlarmsDescription,
      isAutomaticFireAlarmsOccur: installationsResponse.isAutomaticFireAlarmsOccur,
      hasAutomaticFireAlarmsFormalRequirements: installationsResponse.hasAutomaticFireAlarmsFormalRequirements,
      hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning: installationsResponse.hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning,
      isAutomaticFireAlarmOwnAmbition: installationsResponse.isAutomaticFireAlarmOwnAmbition,
      coordinatedFunctionAutomaticFireAlarmRelevantIds: installationsResponse.coordinatedFunctionAutomaticFireAlarmRelevantIds,
      automaticFireAlarmCurrentRegulations: installationsResponse.automaticFireAlarmCurrentRegulations,
      automaticFireAlarmsMonitoringScopeIds: installationsResponse.automaticFireAlarmsMonitoringScopeIds,
      automaticFireAlarmDetekteringIds: installationsResponse.automaticFireAlarmDetekteringIds,
      automaticFireAlarmPowerSupplyIds: installationsResponse.automaticFireAlarmPowerSupplyIds,
      automaticFireAlarmPowerSupplyOther: installationsResponse.automaticFireAlarmPowerSupplyOther,
      automaticFireAlarmActivationIds: installationsResponse.automaticFireAlarmActivationIds,
      automaticFireAlarmActivationOther: installationsResponse.automaticFireAlarmActivationOther,
      automaticFireAlarmsReceiverIds: installationsResponse.automaticFireAlarmsReceiverIds,
      automaticFireAlarmSpecialAdaptationIds: installationsResponse.automaticFireAlarmSpecialAdaptationIds,
      locationOfFireProtectionPanelFireAlarmCenter: installationsResponse.locationOfFireProtectionPanelFireAlarmCenter,
      locationOfInformationTableForFireAlarms: installationsResponse.locationOfInformationTableForFireAlarms,
      locationOfAlarmStorageTablesForFireAlarms: installationsResponse.locationOfAlarmStorageTablesForFireAlarms,
      automaticFireAlarmConstructionCertificate: installationsResponse.automaticFireAlarmConstructionCertificate,
      automaticFireAlarmServiceOrientationDrawings: installationsResponse.automaticFireAlarmServiceOrientationDrawings,
      automaticFireAlarmsDescription: installationsResponse.automaticFireAlarmsDescription,

      isAlarmSignalingOccur: installationsResponse.isAlarmSignalingOccur,
      hasEvacuationAlarmsFormalRequirements: installationsResponse.hasEvacuationAlarmsFormalRequirements,
      hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning: installationsResponse.hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning,
      isAlarmSignalingOwnAmbition: installationsResponse.isAlarmSignalingOwnAmbition,
      hasEmergencyMicrophoneForEmergencyService: installationsResponse.hasEmergencyMicrophoneForEmergencyService,
      alarmSignalingOccurIds: installationsResponse.alarmSignalingOccurIds,
      alarmSignalPerceivedIds: installationsResponse.alarmSignalPerceivedIds,
      evacuationAlarmsActivationIds: installationsResponse.evacuationAlarmsActivationIds,
      evacuationAlarmsActivationOther: installationsResponse.evacuationAlarmsActivationOther,
      evacuationAlarmsSignalIds: installationsResponse.evacuationAlarmsSignalIds,
      evacuationAlarmsSpecialAdaptationIds: installationsResponse.evacuationAlarmsSpecialAdaptationIds,
      alarmSignalingCurrentRegulations: installationsResponse.alarmSignalingCurrentRegulations,
      alarmSignalingConstructionCertificate: installationsResponse.alarmSignalingConstructionCertificate,
      locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel: installationsResponse.locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel,
      evacuationAlarmsDescription: installationsResponse.evacuationAlarmsDescription,

      isAutomaticExtinguishingSystemOccur: installationsResponse.isAutomaticExtinguishingSystemOccur,
      hasAutomaticWaterSprinklerFormalRequirements: installationsResponse.hasAutomaticWaterSprinklerFormalRequirements,
      isAutomaticExtinguishingSystemOwnAmbition: installationsResponse.isAutomaticExtinguishingSystemOwnAmbition,
      automaticExtinguishingSystemOccurIds: installationsResponse.automaticExtinguishingSystemOccurIds,
      monitoringAutomaticExtinguishingSystemIds: installationsResponse.monitoringAutomaticExtinguishingSystemIds,
      automaticWaterSprinklerAdjustmentIds: installationsResponse.automaticWaterSprinklerAdjustmentIds,
      automaticWaterSprinklerRiskClassIds: installationsResponse.automaticWaterSprinklerRiskClassIds,
      automaticWaterSprinklerOther: installationsResponse.automaticWaterSprinklerOther,
      extinguishingSystems: installationsResponse.extinguishingSystems,
      extinguishingEquipmentFormIds: installationsResponse.extinguishingEquipmentFormIds,
      extinguishingEquipmentFormOther: installationsResponse.extinguishingEquipmentFormOther,
      isFireGasVentilationOccur: installationsResponse.isFireGasVentilationOccur,
      hasFireGasVentilationFormalRequirements: installationsResponse.hasFireGasVentilationFormalRequirements,
      isFireGasVentilationOwnAmbition: installationsResponse.isFireGasVentilationOwnAmbition,
      premisesFireGasVentilationIds: installationsResponse.premisesFireGasVentilationIds,
      premisesFireGasVentilationOther: installationsResponse.premisesFireGasVentilationOther,
      fireGasVentilationDescription: installationsResponse.fireGasVentilationDescription,
      isOtherFireTechnicalInstallationsOccur: installationsResponse.isOtherFireTechnicalInstallationsOccur,
      hasOtherFireTechnicalInstallationsFormalRequirements:
        installationsResponse.hasOtherFireTechnicalInstallationsFormalRequirements,
      isOtherFireTechnicalInstallationsOwnAmbition: installationsResponse.isOtherFireTechnicalInstallationsOwnAmbition,
      otherFireTechnicalInstallationsDescription: installationsResponse.otherFireTechnicalInstallationsDescription,
      isCompleted: installationsResponse.isCompleted || false,
    });

    return installationsObject;
  }

  @validate
  private mapDocumentAirTreatmentResponse(
    @required airtreatmentResponse: IDocumentAirTreatmentServer
  ): DocumentAirTreatment {
    const airtreatmentObject = new DocumentAirTreatment({
      fireGasesProtectionOptionIds: airtreatmentResponse.fireGasesProtectionOptionIds,
      isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs: airtreatmentResponse.isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs,
      fireGasesProtectionOther: airtreatmentResponse.fireGasesProtectionOther,
      fireControlVentilationSystemOptionIds: airtreatmentResponse.fireControlVentilationSystemOptionIds,
      isSmokeDuctsInside: airtreatmentResponse.isSmokeDuctsInside,
      hasDocumentedProtectionSolutionForImkanal: airtreatmentResponse.hasDocumentedProtectionSolutionForImkanal,
      isOperatingCardFlowChartFireProtectionFunctionAvailable: airtreatmentResponse.isOperatingCardFlowChartFireProtectionFunctionAvailable,
      airTreatmentDescription: airtreatmentResponse.airTreatmentDescription,
      isCompleted: airtreatmentResponse.isCompleted || false,
    });

    return airtreatmentObject;
  }

  @validate
  private mapDocumentLiftsResponse(@required liftsResponse: IDocumentLiftsServer): DocumentLifts {
    const liftsObject = new DocumentLifts({
      isElevatorExist: liftsResponse.isElevatorExist,
      elevatorFireProtectionOptionIds: liftsResponse.elevatorFireProtectionOptionIds,
      elevatorFireProtectionOther: liftsResponse.elevatorFireProtectionOther,
      liftSteeringFunctionId: liftsResponse.liftSteeringFunctionId,
      recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId: liftsResponse.recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId,
      liftFireproofWiringId: liftsResponse.liftFireproofWiringId,
      liftsDesignDescription: liftsResponse.liftsDesignDescription,
      isCompleted: liftsResponse.isCompleted || false,
    });

    return liftsObject;
  }

  @validate
  private mapDocumentOpportunitiesResponse(
    @required opportunitiesResponse: IDocumentOpportunitiesServer
  ): DocumentOpportunities {
    const opportunitiesObject = new DocumentOpportunities({
      rescueServiceResponseTimeId: opportunitiesResponse.rescueServiceResponseTimeId,
      opportunitiesAccessibilityId: opportunitiesResponse.opportunitiesAccessibilityId,
      isKeyAccess: opportunitiesResponse.isKeyAccess,
      isKeyStoringOnObject: opportunitiesResponse.isKeyStoringOnObject,
      locationOfKeyCabinet: opportunitiesResponse.locationOfKeyCabinet,
      isCommunicationEquipmentForEvacuationInBuilding: opportunitiesResponse.isCommunicationEquipmentForEvacuationInBuilding,
      windAccessId: opportunitiesResponse.windAccessId,
      solarPanelsLocationIds: opportunitiesResponse.solarPanelsLocationIds,
      isEmergencyServiceSwitchForSolarCellSystemApplied: opportunitiesResponse.isEmergencyServiceSwitchForSolarCellSystemApplied,
      locationOfEmergencyServiceSwitch: opportunitiesResponse.locationOfEmergencyServiceSwitch,
      hasBasementSpecialRequirementsAccess: opportunitiesResponse.hasBasementSpecialRequirementsAccess,
      hasSpecialRequirementsForMaxDistanceOf50M: opportunitiesResponse.hasSpecialRequirementsForMaxDistanceOf50M,
      hasRescueLiftInBuilding: opportunitiesResponse.hasRescueLiftInBuilding,
      isEscapeRouteAvailable: opportunitiesResponse.isEscapeRouteAvailable,
      isActionPlansAvailable: opportunitiesResponse.isActionPlansAvailable,
      isFireDustIsApplied: opportunitiesResponse.isFireDustIsApplied,
      hasAscensionLine: opportunitiesResponse.hasAscensionLine,
      isAscensionLinePressurized: opportunitiesResponse.isAscensionLinePressurized,
      extinguishingWaterAccessIds: opportunitiesResponse.extinguishingWaterAccessIds,
      extinguishingWaterAccessOther: opportunitiesResponse.extinguishingWaterAccessOther,
      rescueServicesInterventionDescription: opportunitiesResponse.rescueServicesInterventionDescription,
      isCompleted: opportunitiesResponse.isCompleted || false,
    });

    return opportunitiesObject;
  }

  @validate
  private mapDocumentRisksResponse(@required risksResponse: IDocumentRisksServer): DocumentRisks {
    const risksObject = new DocumentRisks({
      risksHeatingTypeIds: risksResponse.risksHeatingTypeIds,
      specialRiskTypeIds: risksResponse.specialRiskTypeIds,
      hasTimerOrStoveGuard: risksResponse.hasTimerOrStoveGuard,
      specialRisksDescription: risksResponse.specialRisksDescription,
      isCompleted: risksResponse.isCompleted || false,
    });

    return risksObject;
  }

  @validate
  private mapDocumentProtectedObjectsResponse(
    @required protectedObjectsResponse: IDocumentProtectedObjectsServer
  ): DocumentProtectedObjects {
    const protectedObjectsObject = new DocumentProtectedObjects({
      worthyProtectedObjectTypeIds: protectedObjectsResponse.worthyProtectedObjectTypeIds,
      protectedObjectsDescription: protectedObjectsResponse.protectedObjectsDescription,
      isCompleted: protectedObjectsResponse.isCompleted || false,
    });

    return protectedObjectsObject;
  }

  private mapDocumentInspectionResponse(inspectionResponse: IDocumentInspectionServer): DocumentInspection {
    const inspection = new DocumentInspection({
      ...inspectionResponse,
      isCompleted: inspectionResponse?.isCompleted || false,
    });

    return inspection;
  }

  @validate
  private mapDocumentObjectRenovationsResponse(@required objectRenovationsResponse: IRenovationServer[]): Renovation[] {
    if (!objectRenovationsResponse || isEmpty(objectRenovationsResponse)) {
      return [];
    }

    const renovations = map(
      objectRenovationsResponse,
      (renovationResponse) =>
        new Renovation({
          year: renovationResponse.year,
          remedy: renovationResponse.remedy,
        })
    );

    return renovations;
  }

  // Mappers For Server
  private mapDocumentInfoForServer(info: DocumentInfo): IDocumentInfoServer {
    if (!info) {
      return null;
    }

    const documentInfoBody = {
      buildingName: info.buildingName,
      objectNumber: info.objectNumber,
      // reviewerId: info.reviewerId,
      documentStatusId: info.documentStatusId,
      isManagementDrawingFireProtectionDrawnUp: info.isManagementDrawingFireProtectionDrawnUp,
      isEvacuationPlanDrawnUp: info.isEvacuationPlanDrawnUp,
      isActionPlanDrawnUp: info.isActionPlanDrawnUp,
      propertyOwner: info.propertyOwner,
      siteVisitDate: moment(info.siteVisitDate).utc(true).toISOString(),
      isCompleted: info.isCompleted,
    } as IDocumentInfoServer;

    return documentInfoBody;
  }

  private mapDocumentObjectForServer(object: DocumentObject): IDocumentObjectServer {
    if (!object) {
      return null;
    }

    const objectBody = {
      propertyName: object.propertyName,
      municipality: object.municipality,
      area: object.area,
      areaM2: MathUtils.convertStringToNumber(object.areaM2),
      building: object.building,
      constructionYear: object.constructionYear,
      renovations: object.renovations,
      changesDescription: object.changesDescription,
      isCompleted: object.isCompleted,
    } as IDocumentObjectServer;

    return objectBody;
  }

  private mapDocumentBuildingForServer(building: DocumentBuilding): IDocumentBuildingServer {
    if (!building) {
      return null;
    }

    const buildingBody = {
      numberOfStoreys: building.numberOfStoreys,
      fireTechnicalBuildingClassId: building.fireTechnicalBuildingClassId,
      isBr0AnalysisEstablished: building.isBr0AnalysisEstablished,
      dimensioningFireLoadId: building.dimensioningFireLoadId,
      hasBasementFloor: building.hasBasementFloor,
      isConsideredBasementFloorPlan: building.isConsideredBasementFloorPlan,
      hasSeveralBasementFloors: building.hasSeveralBasementFloors,
      hasAtticFloor: building.hasAtticFloor,
      isConsideredAtticFloorPlan: building.isConsideredAtticFloorPlan,
      hasEntresolplan: building.hasEntresolplan,
      isConsideredEntresolplan: building.isConsideredEntresolplan,
      isConsideredSmallerEntresolplan: building.isConsideredSmallerEntresolplan,
      hasSouterrainDesign: building.hasSouterrainDesign,
      isConsideredSouterrainDesignPlan: building.isConsideredSouterrainDesignPlan,
      hasCulvert: building.hasCulvert,
      culvertTypeIds: building.culvertTypeIds,
      buildingConnectionWith: building.buildingConnectionWith,
      buildingIsIncludedInSameBuildingComplex: building.buildingIsIncludedInSameBuildingComplex,
      adjacentBuildings: building.adjacentBuildings,
      buildingBuiltTogetherViaCulvert: building.buildingBuiltTogetherViaCulvert,
      fireTechnicalSolutionTypeId: building.fireTechnicalSolutionTypeId,
      isOwnGuidelinesAmbitionsApplied: building.isOwnGuidelinesAmbitionsApplied,
      followingOwnGuidelineAmbitionsRelevant: building.followingOwnGuidelineAmbitionsRelevant,
      analyticalDimensioningDescription: building.analyticalDimensioningDescription,
      occuredBuildingActivityIds: building.occuredBuildingActivityIds,
      largeKitchenWithIds: building.largeKitchenWithIds,
      protectiveSolutionForLargeKitchenIds: building.protectiveSolutionForLargeKitchenIds,
      meetingRoomOccursInFormOfIds: building.meetingRoomOccursInFormOfIds,
      numberOfPeopleInMeetingRoomIds: building.numberOfPeopleInMeetingRoomIds,
      isPublicPremises: building.isPublicPremises,
      isAppliedTemporaryOvernightAccommodation: building.isAppliedTemporaryOvernightAccommodation,
      frameConstructionConsists: building.frameConstructionConsists,
      beamConstructionConsists: building.beamConstructionConsists,
      facadeConstructionConsists: building.facadeConstructionConsists,
      roofConstructionConsists: building.roofConstructionConsists,
      extendedBuildingDescription: building.extendedBuildingDescription,
      isCompleted: building.isCompleted,
    } as IDocumentBuildingServer;

    return buildingBody;
  }

  private mapDocumentEvacuationForServer(evacuation: DocumentEvacuation): IDocumentEvacuationServer {
    if (!evacuation) {
      return null;
    }

    const evacuationBody = {
      isWindowRemoval: evacuation.isWindowRemoval,
      isOnlyOneEscapeRoute: evacuation.isOnlyOneEscapeRoute,
      isEvacuationRescueService: evacuation.isEvacuationRescueService,
      isEvacuationFromAnotherFireExit: evacuation.isEvacuationFromAnotherFireExit,
      isEvacuationFromAnotherPartOfBuilding: evacuation.isEvacuationFromAnotherPartOfBuilding,
      isHorizontalEvacuation: evacuation.isHorizontalEvacuation,
      isEvacuationViaTr1: evacuation.isEvacuationViaTr1,
      isOnlyEscapeRouteViaTr1: evacuation.isOnlyEscapeRouteViaTr1,
      isTemporaryEvacuationAFS20201: evacuation.isTemporaryEvacuationAFS20201,
      isReEntryRequirements: evacuation.isReEntryRequirements,
      isEvacuationViaAutomaticHorizontalSlidingDoors: evacuation.isEvacuationViaAutomaticHorizontalSlidingDoors,
      isEvacuationViaEvacuationElevator: evacuation.isEvacuationViaEvacuationElevator,
      isEvacuationViaRescueElevator: evacuation.isEvacuationViaRescueElevator,
      lockedDoorsIds: evacuation.lockedDoorsIds,
      isEvacuationForDisabilitiesPeople: evacuation.isEvacuationForDisabilitiesPeople,
      isAccessibleAndUsableHorizontalEvacuation: evacuation.isAccessibleAndUsableHorizontalEvacuation,
      isCommunicationEquipmentEvacuation: evacuation.isCommunicationEquipmentEvacuation,
      isEvacuationViaCommonPartOfSeparatedRoute: evacuation.isEvacuationViaCommonPartOfSeparatedRoute,
      isMattressEvacuationOfBedriddenPatients: evacuation.isMattressEvacuationOfBedriddenPatients,
      isClassifiedEscapeRoute: evacuation.isClassifiedEscapeRoute,
      isEvacuationPlansInBuilding: evacuation.isEvacuationPlansInBuilding,
      isImageRequirementsAccordingVk4: evacuation.isImageRequirementsAccordingVk4,
      extendedEvacuationDescription: evacuation.extendedEvacuationDescription,
      isCompleted: evacuation.isCompleted,
    } as IDocumentEvacuationServer;

    return evacuationBody;
  }

  private mapDocumentFireForServer(fire: DocumentFire): IDocumentFireServer {
    if (!fire) {
      return null;
    }

    const fireBody = {
      isBuildingDividedIntoFireCells: fire.isBuildingDividedIntoFireCells,
      isFireCellExceeds1250m2: fire.isFireCellExceeds1250m2,
      isMoreThanTwoPlanes: fire.isMoreThanTwoPlanes,
      isSpecialFireTechnicalClass: fire.isSpecialFireTechnicalClass,
      isSeveralBusinessClasses: fire.isSeveralBusinessClasses,
      hasDoorsOrHatchesInFireSeparationConstruction: fire.hasDoorsOrHatchesInFireSeparationConstruction,
      hasInstallationDeviceOnDoors: fire.hasInstallationDeviceOnDoors,
      hasAutomaticOpeningOnDoors: fire.hasAutomaticOpeningOnDoors,
      hasWindowsInFireRetardantConstruction: fire.hasWindowsInFireRetardantConstruction,
      hasFireWindowAtAngle: fire.hasFireWindowAtAngle,
      hasFireLowHighPart: fire.hasFireLowHighPart,
      hasFireRetardantCeilingsFloors: fire.hasFireRetardantCeilingsFloors,
      hasAirFireLock: fire.hasAirFireLock,
      hasCoveredYardBalconies: fire.hasCoveredYardBalconies,
      hasFireCellInAttic: fire.hasFireCellInAttic,
      hasFireTechnicalSeparationOfAeratedEaves: fire.hasFireTechnicalSeparationOfAeratedEaves,
      hasWashingAndGarbageChute: fire.hasWashingAndGarbageChute,
      hasPneumaticTube: fire.hasPneumaticTube,
      fireRequirementIds: fire.fireRequirementIds,
      sectioningThroughFirewallIds: fire.sectioningThroughFirewallIds,
      fireTechnicalClassFirewallIds: fire.fireTechnicalClassFirewallIds,
      olderFireTechnicalClassIds: fire.olderFireTechnicalClassIds,
      fireCellMeetTechnicalClassIds: fire.fireCellMeetTechnicalClassIds,
      fireSpecialConditionsDescription: fire.fireSpecialConditionsDescription,
      fireExtendedDescription: fire.fireExtendedDescription,
      isCompleted: fire.isCompleted,
    } as IDocumentFireServer;

    return fireBody;
  }

  private mapDocumentSpreadForServer(spread: DocumentSpread): IDocumentSpreadServer {
    if (!spread) {
      return null;
    }

    const spreadBody = {
      protectionAgainstFireOptionIds: spread.protectionAgainstFireOptionIds,
      roofingOptionIds: spread.roofingOptionIds,
      baseRoofingIds: spread.baseRoofingIds,
      facadeMaterialOptionIds: spread.facadeMaterialOptionIds,
      facadeMaterialOptionOther: spread.facadeMaterialOptionOther,
      protectionAgainstSpreadDescription: spread.protectionAgainstSpreadDescription,
      isCompleted: spread.isCompleted,
    } as IDocumentSpreadServer;

    return spreadBody;
  }

  private mapDocumentCapacityForServer(capacity: DocumentCapacity): IDocumentCapacityServer {
    if (!capacity) {
      return null;
    }

    const capacityBody = {
      fireCarryingCapacityId: capacity.fireCarryingCapacityId,
      isDocumentedConsiderationOfLoadCapacityWithHighFireLoad: capacity.isDocumentedConsiderationOfLoadCapacityWithHighFireLoad,
      isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem: capacity.isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem,
      isDocumentedConsiderationOfProtectionNeedsForBr0Building: capacity.isDocumentedConsiderationOfProtectionNeedsForBr0Building,
      loadBearingCapacityDescription: capacity.loadBearingCapacityDescription,
      isCompleted: capacity.isCompleted,
    } as IDocumentCapacityServer;

    return capacityBody;
  }

  private mapDocumentInstallationsForServer(installations: DocumentInstallations): IDocumentInstallationsServer {
    if (!installations) {
      return null;
    }

    const installationsBody = {
      markingsEmergencyLightingIds: installations.markingsEmergencyLightingIds,
      powerSupplyEmergencyLightingIds: installations.powerSupplyEmergencyLightingIds,
      markingsEmergencyLightingDescription: installations.markingsEmergencyLightingDescription,
      isFireAlarmsOccur: installations.isFireAlarmsOccur,
      hasFireAlarmsFormalRequirements: installations.hasFireAlarmsFormalRequirements,
      hasFireAlarmsSeriesConnection: installations.hasFireAlarmsSeriesConnection,
      fireAlarmsDescription: installations.fireAlarmsDescription,
      isAutomaticFireAlarmsOccur: installations.isAutomaticFireAlarmsOccur,
      hasAutomaticFireAlarmsFormalRequirements: installations.hasAutomaticFireAlarmsFormalRequirements,
      hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning: installations.hasAutomaticFireAlarmFormalRequirementsAccordingToAnalyticalDimensioning,
      isAutomaticFireAlarmOwnAmbition: installations.isAutomaticFireAlarmOwnAmbition,
      coordinatedFunctionAutomaticFireAlarmRelevantIds: installations.coordinatedFunctionAutomaticFireAlarmRelevantIds,
      automaticFireAlarmCurrentRegulations: installations.automaticFireAlarmCurrentRegulations,
      automaticFireAlarmsMonitoringScopeIds: installations.automaticFireAlarmsMonitoringScopeIds,
      automaticFireAlarmDetekteringIds: installations.automaticFireAlarmDetekteringIds,
      automaticFireAlarmPowerSupplyIds: installations.automaticFireAlarmPowerSupplyIds,
      automaticFireAlarmPowerSupplyOther: installations.automaticFireAlarmPowerSupplyOther,
      automaticFireAlarmActivationIds: installations.automaticFireAlarmActivationIds,
      automaticFireAlarmActivationOther: installations.automaticFireAlarmActivationOther,
      automaticFireAlarmsReceiverIds: installations.automaticFireAlarmsReceiverIds,
      automaticFireAlarmSpecialAdaptationIds: installations.automaticFireAlarmSpecialAdaptationIds,
      automaticFireAlarmsDescription: installations.automaticFireAlarmsDescription,
      locationOfFireProtectionPanelFireAlarmCenter: installations.locationOfFireProtectionPanelFireAlarmCenter,
      locationOfInformationTableForFireAlarms: installations.locationOfInformationTableForFireAlarms,
      locationOfAlarmStorageTablesForFireAlarms: installations.locationOfAlarmStorageTablesForFireAlarms,
      automaticFireAlarmConstructionCertificate: installations.automaticFireAlarmConstructionCertificate,
      automaticFireAlarmServiceOrientationDrawings: installations.automaticFireAlarmServiceOrientationDrawings,

      isAlarmSignalingOccur: installations.isAlarmSignalingOccur,
      hasEvacuationAlarmsFormalRequirements: installations.hasEvacuationAlarmsFormalRequirements,
      hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning: installations.hasAlarmSignalingFormalRequirementsAccordingToAnalyticalDimensioning,
      isAlarmSignalingOwnAmbition: installations.isAlarmSignalingOwnAmbition,
      hasEmergencyMicrophoneForEmergencyService: installations.hasEmergencyMicrophoneForEmergencyService,
      alarmSignalingOccurIds: installations.alarmSignalingOccurIds,
      alarmSignalPerceivedIds: installations.alarmSignalPerceivedIds,
      evacuationAlarmsActivationIds: installations.evacuationAlarmsActivationIds,
      evacuationAlarmsActivationOther: installations.evacuationAlarmsActivationOther,
      evacuationAlarmsSignalIds: installations.evacuationAlarmsSignalIds,
      evacuationAlarmsSpecialAdaptationIds: installations.evacuationAlarmsSpecialAdaptationIds,
      alarmSignalingCurrentRegulations: installations.alarmSignalingCurrentRegulations,
      alarmSignalingConstructionCertificate: installations.alarmSignalingConstructionCertificate,
      locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel: installations.locationOfDevicesSilenceAlarmSignalingWhenActivatingPersonnel,
      evacuationAlarmsDescription: installations.evacuationAlarmsDescription,

      isAutomaticExtinguishingSystemOccur: installations.isAutomaticExtinguishingSystemOccur,
      hasAutomaticWaterSprinklerFormalRequirements: installations.hasAutomaticWaterSprinklerFormalRequirements,
      isAutomaticExtinguishingSystemOwnAmbition: installations.isAutomaticExtinguishingSystemOwnAmbition,
      automaticExtinguishingSystemOccurIds: installations.automaticExtinguishingSystemOccurIds,
      monitoringAutomaticExtinguishingSystemIds: installations.monitoringAutomaticExtinguishingSystemIds,
      automaticWaterSprinklerAdjustmentIds: installations.automaticWaterSprinklerAdjustmentIds,
      automaticWaterSprinklerRiskClassIds: installations.automaticWaterSprinklerRiskClassIds,
      automaticWaterSprinklerOther: installations.automaticWaterSprinklerOther,
      extinguishingSystems: installations.extinguishingSystems,
      extinguishingEquipmentFormIds: installations.extinguishingEquipmentFormIds,
      extinguishingEquipmentFormOther: installations.extinguishingEquipmentFormOther,
      isFireGasVentilationOccur: installations.isFireGasVentilationOccur,
      hasFireGasVentilationFormalRequirements: installations.hasFireGasVentilationFormalRequirements,
      isFireGasVentilationOwnAmbition: installations.isFireGasVentilationOwnAmbition,
      premisesFireGasVentilationIds: installations.premisesFireGasVentilationIds,
      premisesFireGasVentilationOther: installations.premisesFireGasVentilationOther,
      fireGasVentilationDescription: installations.fireGasVentilationDescription,
      isOtherFireTechnicalInstallationsOccur: installations.isOtherFireTechnicalInstallationsOccur,
      hasOtherFireTechnicalInstallationsFormalRequirements:
        installations.hasOtherFireTechnicalInstallationsFormalRequirements,
      isOtherFireTechnicalInstallationsOwnAmbition: installations.isOtherFireTechnicalInstallationsOwnAmbition,
      otherFireTechnicalInstallationsDescription: installations.otherFireTechnicalInstallationsDescription,
      isCompleted: installations.isCompleted,
    } as IDocumentInstallationsServer;

    return installationsBody;
  }

  private mapDocumentAirTreatmentForServer(airtreatment: DocumentAirTreatment): IDocumentAirTreatmentServer {
    if (!airtreatment) {
      return null;
    }

    const airtreatmentBody = {
      fireGasesProtectionOptionIds: airtreatment.fireGasesProtectionOptionIds,
      isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs: airtreatment.isDocumentedAnalyticalDimensioningOfPressureReliefViaCalculationOccurs,
      fireGasesProtectionOther: airtreatment.fireGasesProtectionOther,
      fireControlVentilationSystemOptionIds: airtreatment.fireControlVentilationSystemOptionIds,
      isSmokeDuctsInside: airtreatment.isSmokeDuctsInside,
      hasDocumentedProtectionSolutionForImkanal: airtreatment.hasDocumentedProtectionSolutionForImkanal,
      isOperatingCardFlowChartFireProtectionFunctionAvailable: airtreatment.isOperatingCardFlowChartFireProtectionFunctionAvailable,
      airTreatmentDescription: airtreatment.airTreatmentDescription,
      isCompleted: airtreatment.isCompleted,
    } as IDocumentAirTreatmentServer;

    return airtreatmentBody;
  }

  private mapDocumentLiftsForServer(lifts: DocumentLifts): IDocumentLiftsServer {
    if (!lifts) {
      return null;
    }

    const liftsBody = {
      isElevatorExist: lifts.isElevatorExist,
      elevatorFireProtectionOptionIds: lifts.elevatorFireProtectionOptionIds,
      elevatorFireProtectionOther: lifts.elevatorFireProtectionOther,
      liftSteeringFunctionId: lifts.liftSteeringFunctionId,
      recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId: lifts.recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId,
      liftFireproofWiringId: lifts.liftFireproofWiringId,
      liftsDesignDescription: lifts.liftsDesignDescription,
      isCompleted: lifts.isCompleted,
    } as IDocumentLiftsServer;

    return liftsBody;
  }

  private mapDocumentOpportunitiesForServer(opportunities: DocumentOpportunities): IDocumentOpportunitiesServer {
    if (!opportunities) {
      return null;
    }

    const opportunitiesBody = {
      rescueServiceResponseTimeId: opportunities.rescueServiceResponseTimeId,
      opportunitiesAccessibilityId: opportunities.opportunitiesAccessibilityId,
      isKeyAccess: opportunities.isKeyAccess,
      isKeyStoringOnObject: opportunities.isKeyStoringOnObject,
      locationOfKeyCabinet: opportunities.locationOfKeyCabinet,
      isCommunicationEquipmentForEvacuationInBuilding: opportunities.isCommunicationEquipmentForEvacuationInBuilding,
      windAccessId: opportunities.windAccessId,
      solarPanelsLocationIds: opportunities.solarPanelsLocationIds,
      isEmergencyServiceSwitchForSolarCellSystemApplied: opportunities.isEmergencyServiceSwitchForSolarCellSystemApplied,
      locationOfEmergencyServiceSwitch: opportunities.locationOfEmergencyServiceSwitch,
      hasBasementSpecialRequirementsAccess: opportunities.hasBasementSpecialRequirementsAccess,
      hasSpecialRequirementsForMaxDistanceOf50M: opportunities.hasSpecialRequirementsForMaxDistanceOf50M,
      hasRescueLiftInBuilding: opportunities.hasRescueLiftInBuilding,
      isEscapeRouteAvailable: opportunities.isEscapeRouteAvailable,
      isActionPlansAvailable: opportunities.isActionPlansAvailable,
      isFireDustIsApplied: opportunities.isFireDustIsApplied,
      hasAscensionLine: opportunities.hasAscensionLine,
      isAscensionLinePressurized: opportunities.isAscensionLinePressurized,
      extinguishingWaterAccessIds: opportunities.extinguishingWaterAccessIds,
      extinguishingWaterAccessOther: opportunities.extinguishingWaterAccessOther,
      rescueServicesInterventionDescription: opportunities.rescueServicesInterventionDescription,
      isCompleted: opportunities.isCompleted,
    } as IDocumentOpportunitiesServer;

    return opportunitiesBody;
  }

  private mapDocumentRisksForServer(risks: DocumentRisks): IDocumentRisksServer {
    if (!risks) {
      return null;
    }

    const risksBody = {
      risksHeatingTypeIds: risks.risksHeatingTypeIds,
      specialRiskTypeIds: risks.specialRiskTypeIds,
      hasTimerOrStoveGuard: risks.hasTimerOrStoveGuard,
      specialRisksDescription: risks.specialRisksDescription,
      isCompleted: risks.isCompleted,
    } as IDocumentRisksServer;

    return risksBody;
  }

  private mapDocumentProtectedObjectsForServer(
    protectedObjects: DocumentProtectedObjects
  ): IDocumentProtectedObjectsServer {
    if (!protectedObjects) {
      return null;
    }

    const protectedObjectsBody = {
      worthyProtectedObjectTypeIds: protectedObjects.worthyProtectedObjectTypeIds,
      protectedObjectsDescription: protectedObjects.protectedObjectsDescription,
      isCompleted: protectedObjects.isCompleted,
    } as IDocumentProtectedObjectsServer;

    return protectedObjectsBody;
  }

  private mapDocumentInspectionForServer(inspection: DocumentInspection): IDocumentInspectionServer {
    if (!inspection) {
      return null;
    }

    const inspectionBody = {
      ...inspection,
    } as IDocumentInspectionServer;

    return inspectionBody;
  }
}
