import { IDocumentItem } from './document';

export class DocumentCapacity implements IDocumentItem {
  fireCarryingCapacityId: string;
  isDocumentedConsiderationOfLoadCapacityWithHighFireLoad?: boolean;
  isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem?: boolean;
  isDocumentedConsiderationOfProtectionNeedsForBr0Building?: boolean;
  loadBearingCapacityDescription?: string;
  isCompleted: boolean;
  isValid: boolean;

  constructor({
    fireCarryingCapacityId,
    isDocumentedConsiderationOfLoadCapacityWithHighFireLoad,
    isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem,
    isDocumentedConsiderationOfProtectionNeedsForBr0Building,
    loadBearingCapacityDescription,
    isCompleted,
    isValid,
  }: Partial<DocumentCapacity> = {}) {
    this.fireCarryingCapacityId = fireCarryingCapacityId;
    this.isDocumentedConsiderationOfLoadCapacityWithHighFireLoad = isDocumentedConsiderationOfLoadCapacityWithHighFireLoad;
    this.isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem = isDocumentedConsiderationOfLoadCapacityWithTechnicalReplacementOfAutomaticWaterSprinklerSystem;
    this.isDocumentedConsiderationOfProtectionNeedsForBr0Building = isDocumentedConsiderationOfProtectionNeedsForBr0Building;
    this.loadBearingCapacityDescription = loadBearingCapacityDescription;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
