<div class="login-page full-screen-page">
  <div class="login-page-container">
    <div class="row justify-content-center login-page-full-height">
      <div class="col login-page-content full-screen-page-content align-self-center">
        <div class="login-form-container">
          <app-login-form (loggedIn)="onLoggedIn()" (logInFailed)="onLogInFailed()"></app-login-form>
        </div>
      </div>
      <div class="background"></div>
    </div>
  </div>
</div>
