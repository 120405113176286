import { IDocumentItem } from './document';

export class DocumentRisks implements IDocumentItem {
  risksHeatingTypeIds: string[];
  specialRiskTypeIds: string[];
  hasTimerOrStoveGuard?: boolean;
  specialRisksDescription?: string;
  isCompleted: boolean;
  isValid: boolean;

  constructor({
    risksHeatingTypeIds,
    specialRiskTypeIds,
    hasTimerOrStoveGuard,
    specialRisksDescription,
    isCompleted,
    isValid,
  }: Partial<DocumentRisks> = {}) {
    this.risksHeatingTypeIds = risksHeatingTypeIds || [];
    this.specialRiskTypeIds = specialRiskTypeIds || [];
    this.hasTimerOrStoveGuard = hasTimerOrStoveGuard;
    this.specialRisksDescription = specialRisksDescription;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
