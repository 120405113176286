import { validate, required } from '../required';
import moment, { unitOfTime } from 'moment';

export class DateUtils {
  @validate
  static formatTime(
    @required timeInMiliseconds: number,
    @required labels: { step: number; label: string; pluralLabel?: string }[],
    format: string = '{0} {1}',
    fixed: number = 0
  ) {
    if (labels.length === 0) {
      throw new Error('Labels cannot be empty.');
    }

    let value = timeInMiliseconds;
    let labelIndex = 0;
    while (labelIndex < labels.length - 1 && value >= labels[labelIndex].step) {
      value /= labels[labelIndex].step;
      labelIndex += 1;
    }

    const formattedValue = Math.abs(value).toFixed(fixed);
    const label =
      Math.abs(value) > 1 && labels[labelIndex].pluralLabel ? labels[labelIndex].pluralLabel : labels[labelIndex].label;
    const formattedTime = format.format(formattedValue, label);

    return formattedTime;
  }

  // Adds extra check for null/undefined to moment.isSame
  static isSame(
    first: Date | string | moment.Moment,
    second: Date | string | moment.Moment,
    granularity?: unitOfTime.StartOf
  ): boolean {
    if (!first && !second) {
      return true;
    }

    const isSame = moment(first).isSame(second, granularity);

    return isSame;
  }
}
