import { UserDetails } from './shared/user-details';

export const usersConfig = {
  delete: {
    confirmationText: 'radera',
  },
  api: {
    users: {
      url: 'users',
      password: 'password',
      resetPassword: 'resetPassword',
      documentReviewers: 'document-reviewers',
    },
  },
  filter: {
    searchableFields: ['user.email', 'user.firstName', 'user.lastName', 'stats.docsCount'] as FieldsOf<UserDetails>,
  },
  list: {
    minRefreshTimeout: 1000,
  },
  profile: {
    validation: {
      name: {
        maxLength: 64,
        allowedCharacters: ['-', '_', '.', 'å', 'ä', 'ö', 'é'],
      },
      email: {
        maxLength: 64,
      },
      password: {
        minLength: 6,
      },
    },
  },
  forms: {
    maskedInput: {
      autoHideDelay: 15, // seconds
    },
  },
  password: {
    generation: {
      characters: {
        lowercase: 'abcdefghijklmnopqrstuvwxyz',
        uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        numbers: '0123456789',
      },
    },
    validation: {
      minLength: 10,
      maxLength: 64,
      temporaryMaxLength: 16,
      forbiddenCharacters: ['\\'],
      specialCharacters: [
        '!',
        '#',
        '$',
        '%',
        '&',
        '(',
        ')',
        '*',
        '+',
        ',',
        '-',
        '.',
        '/',
        ':',
        ';',
        '<',
        '=',
        '>',
        '?',
        '@',
        '[',
        ']',
        '^',
        '_',
        '{',
        '|',
        '}',
        '~',
        '£',
      ],
    },
  },
};
