import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { projectConfig } from '../project.config';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Assert } from 'src/app/shared';
import { forEach } from 'lodash';
import { DocumentFieldNote, DocumentGeneralSectionField, DocumentSectionType } from 'src/app/documents/shared';
import { MatDialog } from '@angular/material/dialog';
import { ProjectNoteModalComponent } from '../project-note-modal/project-note-modal.component';
import { DocumentSectionNote } from '../shared/document-section-note';
import { DocumentsApiService } from 'src/app/documents/documents-api.service';
import { DocumentSectionNoteResult } from '../shared/document-section-note-result';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../core/forms/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-project-tab-header',
  templateUrl: './project-tab-header.component.html',
  styleUrls: ['./project-tab-header.component.scss'],
})
export class ProjectTabHeaderComponent implements OnChanges {
  @Input()
  isCompleted: boolean;

  @Input()
  isDocumentCompleted: boolean;

  @Input()
  isPublished: boolean;

  @Input()
  documentId: uuid;

  @Input()
  header: string;

  @Input()
  previousPageName: string;

  @Input()
  nextPageName: string;

  @Input()
  formToValidate: NgForm;

  @Input()
  disabled: boolean;

  @Input()
  sectionType: DocumentSectionType;

  @Input()
  notes: StringMap<DocumentFieldNote>;

  @Output()
  readonly completeForm: EventEmitter<boolean>;

  @Output()
  readonly publishDocument: EventEmitter<boolean>;

  generalSectionField: DocumentGeneralSectionField;

  get isPublishEnabled(): boolean {
    return this.isDocumentCompleted || this.isPublished;
  }

  get publishTitleText(): string {
    if (this.isPublishEnabled) {
      return this.isPublished ? 'Avpublicera dokumentet' : 'Publicera dokumentet';
    }
    return 'Endast klarmarkerat dokument kan publiceras';
  }

  constructor(
    private readonly router: Router,
    private dialog: MatDialog,
    private documentsApiService: DocumentsApiService,
    private readonly projectSectionDataService: DocumentsSectionDataService
  ) {
    this.completeForm = new EventEmitter();
    this.publishDocument = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<ProjectTabHeaderComponent, SimpleChange>;

    const filterChange = componentChanges.sectionType;
    if (!filterChange || !this.sectionType) {
      return;
    }

    this.generalSectionField = this.projectSectionDataService.getGeneralSectionFieldTypeBySectionType(this.sectionType);
  }

  onPreviousPage(): void {
    if (this.previousPageName) {
      this.router.navigate(this.getMenuRoute(this.previousPageName));
    }
  }

  onNextPage(): void {
    if (this.nextPageName) {
      this.router.navigate(this.getMenuRoute(this.nextPageName));
    }
  }

  onPublish(publish: boolean): void {
    const publishTitle = 'Publicera dokumentet';
    const unpublishTitle = 'Avpublicera dokumentet';
    const publishText = 'Alla kommande ändringar räknas som revideringar av dokumentet. Är du säker på att du vill publicera detta dokument?';
    const unpublishText = 'Revideringslistan kommer att rensas. Är du säker på att vill avpublicera detta dokument?';
    const dialogModel = new ConfirmDialogModel(publish ? publishTitle : unpublishTitle, publish ? publishText : unpublishText);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '550px',
      data: dialogModel
    });

    dialogRef.afterClosed().subscribe(async (isConfirmed: boolean) => {
      if (isConfirmed) {
       await this.documentsApiService.publishDocument(this.documentId, publish);
       this.publishDocument.emit(publish);
      }
    });
  }

  onSwitchComplete(): void {
    Assert.isNotNull(this.formToValidate.form, 'formToValidate.form');

    this.formToValidate.form.markAsTouched();
    forEach(this.formToValidate.controls, (control) => {
      control.markAsTouched();
    });

    if (this.formToValidate.valid) {
      this.completeForm.next(!this.isCompleted);
    }
  }

  onOpenNoteModel() {
    const dialogRef = this.dialog.open(ProjectNoteModalComponent, {
      data: new DocumentSectionNote({
        sectionType: this.sectionType,
        isViewOnly: this.disabled,
      }),
    });

    dialogRef.afterClosed().subscribe((result: DocumentSectionNoteResult) => {
      if (result) {
        this.documentsApiService.putDocumentFieldNoteById(this.documentId, result.fieldId, result.note);
      }
    });
  }

  private getMenuRoute(menuName: string): string[] {
    const route = ['/', projectConfig.routes.project, this.documentId, menuName];

    return route;
  }
}
