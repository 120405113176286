import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  ExpireAuthenticationAction,
  SetReturnUrlAction,
  AuthenticateAction,
  UpdateUserNameAction,
  UpdateUserRoleAction,
} from './authentication.actions';
import {
  selectReturnUrl,
  selectAccessToken,
  selectAuthDetails,
  selectUserRole,
  selectHasEditPermission,
  selectHasSuperAdminPermission,
  selectHasSuperAdminOrAuthorPermission,
} from './authentication.selectors';
import { AppState } from 'src/app/app.reducer';
import { AuthDetails } from '../shared/auth-details';
import { validate, required } from 'src/app/shared';
import { UserRole } from '../shared/user-role';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationStoreService {
  private readonly store: Store<AppState>;

  constructor(store: Store<AppState>) {
    this.store = store;
  }

  @validate
  authenticate(@required authDetails: AuthDetails) {
    this.store.dispatch(new AuthenticateAction(authDetails));
  }

  expireAuthentication() {
    this.store.dispatch(new ExpireAuthenticationAction());
  }

  getAuthDetails(): Observable<AuthDetails> {
    const authDetails$ = this.store.select(selectAuthDetails);

    return authDetails$;
  }

  setAuthDetailsNewUserName(@required userName: string) {
    this.store.dispatch(new UpdateUserNameAction(userName));
  }

  setAuthDetailsNewUserRole(@required role: UserRole) {
    this.store.dispatch(new UpdateUserRoleAction(role));
  }

  getAccessToken(): Observable<string> {
    const selectAccessToken$ = this.store.select(selectAccessToken);

    return selectAccessToken$;
  }

  setReturnUrl(returnUrl: string) {
    this.store.dispatch(new SetReturnUrlAction(returnUrl));
  }

  getReturnUrl(): Observable<string> {
    const selectReturnUrl$ = this.store.select(selectReturnUrl);

    return selectReturnUrl$;
  }

  getUserRole(): Observable<UserRole> {
    const selectUserRole$ = this.store.select(selectUserRole);

    return selectUserRole$;
  }

  hasEditPermission(): Observable<boolean> {
    const selectHasEditPermisstion$ = this.store.select(selectHasEditPermission);

    return selectHasEditPermisstion$;
  }

  hasSuperAdminPermission(): Observable<boolean> {
    const selectHasSuperAdminPermisstion$ = this.store.select(selectHasSuperAdminPermission);

    return selectHasSuperAdminPermisstion$;
  }

  hasSuperAdminOrAuthorPermission(): Observable<boolean> {
    const selectHasSuperAdminOrAuthorPermission$ = this.store.select(selectHasSuperAdminOrAuthorPermission);

    return selectHasSuperAdminOrAuthorPermission$;
  }
}
