import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { appConfig } from 'src/app/app.config';
import { AuthenticationStoreService } from 'src/app/authentication/authentication-store';
import { authenticationConfig } from 'src/app/authentication/authentication.config';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { AuthDetails } from 'src/app/authentication/shared';
import { UserRole } from 'src/app/authentication/shared/user-role';
import { ConfirmationModalComponent } from 'src/app/core/components/confirmation-modal/confirmation-modal.component';
import { Unsubscribe } from 'src/app/core/decorators';
import { ConfirmationModalDialogData } from 'src/app/core/shared/confirmation-modal-dialog-data';
import { organizationConfig } from 'src/app/organization/organization.config';
import { LayoutStoreService } from '../layout-store';

@Unsubscribe()
@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit, OnDestroy {
  get homeRoute(): [string] {
    return [appConfig.routes.home];
  }

  currentUserAuthDetails: AuthDetails;
  private unsubscribeAll: Subject<any>;

  get UserRole() {
    return UserRole;
  }

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly layoutStoreService: LayoutStoreService,
    private readonly authenticationStoreService: AuthenticationStoreService,
    private readonly dialog: MatDialog,
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.authenticationStoreService
      .getAuthDetails()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(async (details: AuthDetails) => {
        this.currentUserAuthDetails = details;
      });
  }

  ngOnDestroy(): void {}

  onOpenManagementOrganization(): void {
    if (this.currentUserAuthDetails.role === UserRole.superAdmin) {
      this.router.navigate(['/', organizationConfig.routes.organizations]);
      return;
    }
    this.router.navigate(['/', organizationConfig.routes.organizations, this.currentUserAuthDetails.organizationId]);
  }

  onOpenUserProfile(): void {
    this.router.navigate(['/', 'anvandarprofil']);
  }

  onSignOut(): void {
    const confirmationModalDialogData = new ConfirmationModalDialogData({
      title: 'Logga ut',
      message: 'Är du säker på att du vill logga ut?',
      confirmButtonText: 'Logga ut',
    });
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: confirmationModalDialogData,
    });

    dialogRef.afterClosed().subscribe((signOut: boolean) => {
      if (signOut) {
        this.signOut();
      }
    });
  }

  private signOut(): void {
    this.authenticationService.expireAuthentication();
    this.router.navigate(['/', authenticationConfig.routes.login]);
  }
}
