export class ConfirmationModalDialogData {
  title: string;
  message: string;
  confirmationText: string;
  useDoubleConfirmation: boolean;
  confirmButtonText: string;

  constructor({ title, message, confirmationText, useDoubleConfirmation, confirmButtonText }: Partial<ConfirmationModalDialogData> = {}) {
    this.title = title;
    this.message = message;
    this.confirmationText = confirmationText;
    this.useDoubleConfirmation = useDoubleConfirmation;
    this.confirmButtonText = confirmButtonText;
  }
}
