export class ChangePasswordDetails {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;

  constructor({ currentPassword, newPassword, repeatNewPassword }: Partial<ChangePasswordDetails> = {}) {
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
    this.repeatNewPassword = repeatNewPassword;
  }
}

export interface IChangePasswordDetails {
  currentPassword: string;
  newPassword: string;
  userId: string;
}
