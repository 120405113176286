import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AuthenticationState } from './authentication.reducer';
import { authenticationConfig } from '../authentication.config';
import { AppState } from 'src/app/app.reducer';
import { UserRole } from '../shared/user-role';

export const selectAuthenticationState: MemoizedSelector<AppState, AuthenticationState> = createFeatureSelector(
  authenticationConfig.store.key
);

export const selectAuthDetails = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.authDetails
);

export const selectAccessToken = createSelector(selectAuthenticationState, (state: AuthenticationState) =>
  state.authDetails ? state.authDetails.accessToken : null
);

export const selectReturnUrl = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.returnUrl
);

export const selectUserRole = createSelector(selectAuthenticationState, (state: AuthenticationState) =>
  state.authDetails ? state.authDetails.role : null
);

export const selectHasEditPermission = createSelector(selectAuthenticationState, (state: AuthenticationState) =>
  state.authDetails
    ? state.authDetails.role === UserRole.superAdmin ||
      state.authDetails.role === UserRole.author ||
      state.authDetails.role === UserRole.customerAdmin ||
      state.authDetails.role === UserRole.customerAuthor
    : false
);

export const selectHasSuperAdminPermission = createSelector(selectAuthenticationState, (state: AuthenticationState) =>
  state.authDetails ? state.authDetails.role === UserRole.superAdmin : false
);

export const selectHasSuperAdminOrAuthorPermission = createSelector(selectAuthenticationState, (state: AuthenticationState) =>
  state.authDetails ? state.authDetails.role === UserRole.superAdmin || state.authDetails.role === UserRole.author : false
);
