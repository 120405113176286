import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { validate, required } from 'src/app/shared';
import { DocumentFieldNote } from '../shared';
import { v4 as uuid } from 'uuid';
import {
  AddDocumentNoteAction,
  AddDocumentNotesAction,
  DeleteDocumentNoteAction,
  ResetDocumentNotesAction,
} from './documents-notes.actions';
import { selectNoteByDocumentAndFieldId, selectNotesByDocumentId } from './documents-notes.selectors';

@Injectable({
  providedIn: 'root',
})
export class DocumentNotesStoreService {
  private readonly store: Store<AppState>;

  constructor(store: Store<AppState>) {
    this.store = store;
  }

  @validate
  addDocumentNote(@required documentId: uuid, @required note: DocumentFieldNote) {
    this.store.dispatch(new AddDocumentNoteAction(documentId, note));
  }

  @validate
  addDocumentNotes(@required documentId: uuid, @required notes: StringMap<DocumentFieldNote>) {
    this.store.dispatch(new AddDocumentNotesAction(documentId, notes));
  }

  @validate
  deleteDocumentNote(@required documentId: uuid, @required fieldId: string) {
    this.store.dispatch(new DeleteDocumentNoteAction(documentId, fieldId));
  }

  resetDocumentsNotes() {
    this.store.dispatch(new ResetDocumentNotesAction());
  }

  @validate
  getNotesByDocumentId(@required documentId: uuid): Observable<StringMap<DocumentFieldNote>> {
    const selectNotesByDocumentId$ = this.store.select(selectNotesByDocumentId, { documentId: documentId });

    return selectNotesByDocumentId$;
  }

  @validate
  getNoteByDocumentAndFieldId(@required documentId: uuid, @required fieldId: string): Observable<DocumentFieldNote> {
    const selectDocumentById$ = this.store.select(selectNoteByDocumentAndFieldId, {
      documentId: documentId,
      fieldId: fieldId,
    });

    return selectDocumentById$;
  }
}
