import { v4 as uuid } from 'uuid';
import { OrganizationStatus } from './organization-status';

export class Organization {
  readonly id: uuid;
  readonly externalId: number;
  readonly name: string;
  readonly status: OrganizationStatus;

  constructor({ id, externalId, name, status }: Partial<Organization> = {}) {
    this.id = id;
    this.externalId = externalId;
    this.name = name;
    this.status = status;
  }
}
