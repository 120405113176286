import { NgModule, InjectionToken } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { documentsReducer, documentsInitialState, DocumentsState } from './documents.reducer';
import { StoreConfig } from '@ngrx/store/src/store_module';
import { featureStoreConfigFactory, StoreStateService } from 'src/app/core/store';
import { documentsConfig } from '../documents.config';

const featureStoreKey = documentsConfig.store.documentsKey;

export const documentsFeatureStoreKeyToken = new InjectionToken(`FeatureStoreKeyInjectionToken:${featureStoreKey}`);
export const documentsFeatureStoreInitialStateToken = new InjectionToken<DocumentsState>(
  `FeatureStoreInitialStateInjectionToken:${featureStoreKey}`
);
export const documentsStoreFeatueConfigToken = new InjectionToken<StoreConfig<DocumentsState>>(
  `FeatureStoreConfigInjectionToken:${featureStoreKey}`
);

@NgModule({
  imports: [StoreModule.forFeature(featureStoreKey, documentsReducer, documentsStoreFeatueConfigToken)],
  providers: [
    {
      provide: documentsFeatureStoreKeyToken,
      useValue: featureStoreKey,
    },
    {
      provide: documentsFeatureStoreInitialStateToken,
      useValue: documentsInitialState,
    },
    {
      provide: documentsStoreFeatueConfigToken,
      deps: [documentsFeatureStoreKeyToken, documentsFeatureStoreInitialStateToken, StoreStateService],
      useFactory: featureStoreConfigFactory,
    },
  ],
})
export class DocumentsStoreModule {}
