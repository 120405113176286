import { DocumentSectionType, DocumentSectionRoute } from '../documents/shared';

export const projectConfig = {
  routes: {
    project: 'projekt',
    projectWithId: 'projekt/:id',
    params: {
      documentId: 'id',
    },

    info: DocumentSectionRoute[DocumentSectionType.info],
    object: DocumentSectionRoute[DocumentSectionType.object],
    building: DocumentSectionRoute[DocumentSectionType.building],
    evacuation: DocumentSectionRoute[DocumentSectionType.evacuation],
    fire: DocumentSectionRoute[DocumentSectionType.fire],
    spread: DocumentSectionRoute[DocumentSectionType.spread],
    capacity: DocumentSectionRoute[DocumentSectionType.capacity],
    installations: DocumentSectionRoute[DocumentSectionType.installations],
    airtreatment: DocumentSectionRoute[DocumentSectionType.airtreatment],
    lifts: DocumentSectionRoute[DocumentSectionType.lifts],
    opportunities: DocumentSectionRoute[DocumentSectionType.opportunities],
    risks: DocumentSectionRoute[DocumentSectionType.risks],
    protectedObjects: DocumentSectionRoute[DocumentSectionType.protectedObjects],
    inspection: DocumentSectionRoute[DocumentSectionType.inspection],
  },
  object: {
    renovationYear: {
      minValue: 1900,
    },
  },
};
