<div>
  <div class="sticky-top">
    <div class="header d-flex">
      <div class="flex-grow-1">
        <h4>
          <a
            *ngIf="currentUserAuthDetails?.role === UserRole.superAdmin"
            [routerLink]="['/anvandare']"
            class="btn btn-sm btn-outline-light-purple mr-2"
            title="Tillbaka"
          >
            <i class="fas fa-angle-left mr-1"></i>
            <ng-container>Tillbaka</ng-container>
          </a>

          <span>
            <ng-container *ngIf="state.isLoading">
              <i class="fas fa-spinner fa-pulse"></i>
            </ng-container>

            <ng-container *ngIf="state.isFailed">
              <span class="mr-1">Failed to load organization.</span>

              <span class="mr-1">Click</span>
              <button
                type="button"
                (click)="onTryLoadOrganization()"
                class="btn btn-xs btn-outline-primary mr-1"
                title="Refresh"
              >
                <i class="fas fa-sync-alt mr-1"></i>
                <ng-container>Refresh</ng-container>
              </button>
              <ng-container>to try again.</ng-container>
            </ng-container>

            <ng-container *ngIf="!state.isLoading && !state.isFailed" class="ml-2"
              >{{ organization?.name }} - Användare <ng-container *ngIf="isInactive">(Inaktiverad)</ng-container>
            </ng-container>
          </span>
        </h4>
      </div>
<!--      <div>-->
<!--        <div ngbDropdown *ngIf="organizationId" class="d-inline-block" placement="bottom-right">-->
<!--          <button class="btn btn-text actions-toggle" title="Actions" ngbDropdownToggle>-->
<!--            <i class="fas fa-cog fa-spin-hover text-large"></i>-->
<!--          </button>-->
<!--          <div ngbDropdownMenu>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>

  <hr class="m-0" />

  <app-users
    [organizationId]="organizationId"
    [usersType]="UsersType.Customers"
    (userCreated)="onUserCreated()"
    (userDeleted)="onUserDeleted()"
  >
  </app-users>
</div>
