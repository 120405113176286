<div class="table-wrapper w-100">
  <table class="dashboard-table table data-table alt-table-white table-align-middle">
    <tbody *ngIf="items | isEmpty">
      <tr>
        <td class="text-center text-muted p-5" title="Nothing to display">
          Inget att visa.
        </td>
      </tr>
    </tbody>

    <tbody>
      <ng-container *ngFor="let item of items; trackBy: trackByItem">
        <tr class="clickable-table-row" [ngClass]="{'table-danger': item.deletedDate}">
          <td class="alt-table-title-column text-truncate">
            <div class="data-table-title-column-container" [routerLink]="[projectRoute, item.id]">
              {{ item.info.buildingName || '(Inget namn ännu)' }}
            </div>
            <div class="alt-table-column-subheader slim-subheader" [routerLink]="[projectRoute, item.id]">
              <span class="font-italic mr-1">Upprättad av:</span>
              <ng-container>
                {{ item.creatorName }} - {{ item.createdDate | date:'dd/MM-yyyy' }}<ng-container *ngIf="item.reviserName">.</ng-container>
              </ng-container>
              <ng-container *ngIf="item.reviserName">
                <span class="font-italic mr-1">Senast reviderad av:</span>
                <ng-container>{{ item.reviserName }} - {{ item.revisedDate | date:'dd/MM-yyyy' }}</ng-container>
              </ng-container>
            </div>
          </td>
          <td [routerLink]="[projectRoute, item.id]" class="w-25 text-small" *ngIf="hasEditPermissions">
            {{ item.organizationName }}
          </td>
          <td [routerLink]="[projectRoute, item.id]" class="icon-column">
            <span
              *ngIf="item.isPublished"
              class="material-icons text-valid icon-published"
              title="Dokumentet publicerat"
            >
              publish
            </span>
          </td>
          <td [routerLink]="[projectRoute, item.id]" class="icon-column">
            <span
              *ngIf="item.isCompleted"
              class="material-icons text-valid font-weight-bold icon-done"
              title="Dokumentet slutfört"
            >
              done
            </span>
          </td>
          <td class="icon-column">
            <a href="/api/report/{{ item.id }}" mat-icon-button target="_blank" title="Exportera rapport">
              <mat-icon fontSet="fas" fontIcon="fa-file-word" class="report-icon"></mat-icon>
            </a>
          </td>
          <td *ngIf="item.deletedDate && hasEditPermissions" class="text-left no-break icon-column">
            <button
              mat-button
              mat-icon-button
              title="Återställ"
              (click)="onRestoreDocument(item)"
            >
              <mat-icon>restore_from_trash</mat-icon>
            </button>
            <button
              *ngIf="hasSuperAdminOrAuthorPermissions"
              mat-button
              mat-icon-button
              title="Ta bort dokumentet"
              (click)="onDeleteDocument(item)"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          </td>
          <td *ngIf="!item.deletedDate && hasEditPermissions" class="text-left no-break icon-column">
            <button
              mat-button
              mat-icon-button
              title="Flytta till papperskorgen"
              (click)="onMoveToBinDocument(item)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
