import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UserRole } from 'src/app/authentication/shared/user-role';
import { UsersType } from 'src/app/authentication/shared/users-type';
import { validate, required } from 'src/app/shared';
import { OrganizationUsersManagementService } from '../organization-users-management.service';
import { Organization } from '../shared';
import { coreConfig } from '../../core';
import { Observable } from 'rxjs';
import { AuthenticationStoreService } from 'src/app/authentication/authentication-store/authentication-store.service';

enum TabIndex {
  Organizations = 0,
  InternalUsers = 1,
}

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
})
export class OrganizationsComponent implements OnInit {
  filter: string;
  showDeactivatedOrganizations: boolean;
  currentPage: number;
  itemsCount: number;
  pageSize: number;
  isRefreshing: boolean;

  hasSuperAdminPermission$: Observable<boolean>;

  organizationsItemsCount: number;
  usersItemsCount: number;
  selectedTabIndex: number;

  @Output()
  readonly created: EventEmitter<Organization>;

  get UserRole() {
    return UserRole;
  }

  get UsersType() {
    return UsersType;
  }

  constructor(
    private readonly organizationUsersManagementService: OrganizationUsersManagementService,
    private readonly authenticationStoreService: AuthenticationStoreService
  ) {
    this.created = new EventEmitter();
    this.selectedTabIndex = TabIndex.Organizations;
    this.updateItemsCount();
    this.pageSize = coreConfig.pagination.defaultPageSize;
  }

  ngOnInit(): void {
    this.hasSuperAdminPermission$ = this.authenticationStoreService.hasSuperAdminPermission();
  }

  @validate
  onPageChanged(@required page: number) {
    this.currentPage = page;
  }

  onFilterChanged(filter: string) {
    this.filter = filter;
  }

  onShowInactivateOrganizationsChanged(show: boolean) {
    this.showDeactivatedOrganizations = show;
  }

  @validate
  onPageSizeChanged(@required pageSize: number) {
    this.pageSize = pageSize;
  }

  onCreateOrganization() {
    this.organizationUsersManagementService.createOrganization();
  }

  onCreateNewInternalUser() {
    this.organizationUsersManagementService.createInternalUser();
  }

  selectedTabChanged(event: MatTabChangeEvent) {
    this.currentPage = 0;
    this.selectedTabIndex = event.index;
    this.updateItemsCount();
  }

  onOrganizationsItemsCountChanged(itemsCount: number) {
    this.organizationsItemsCount = itemsCount;
    this.updateItemsCount();
  }

  onUsersItemsCountChanged(itemsCount: number) {
    this.usersItemsCount = itemsCount;
    this.updateItemsCount();
  }

  onCurrentPageChanged(currentPage: number) {
    this.currentPage = currentPage;
  }

  private updateItemsCount() {
    if (this.selectedTabIndex === TabIndex.Organizations) {
      this.itemsCount = this.organizationsItemsCount;
    }
    if (this.selectedTabIndex === TabIndex.InternalUsers) {
      this.itemsCount = this.usersItemsCount;
    }
  }
}
