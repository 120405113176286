import { Directive, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { validate, required } from '../../shared';
import { isEmpty } from 'lodash';

@Directive({
  selector: '[appMaxList]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxListValidatorDirective, multi: true }]
})
export class MaxListValidatorDirective implements OnChanges, Validator {
  @Input('appMaxList')
  maxList: number[] | null;

  private validator: ValidatorFn;

  ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<MaxListValidatorDirective, SimpleChange>;
    const maxListChange = componentChanges.maxList;

    if (maxListChange) {
      this.validator = this.getValidator(maxListChange.currentValue);
    }
  }

  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    if (!this.maxList || isEmpty(this.maxList) || !this.validator)
      return null;

    const validationError = this.validator(control);

    return validationError;
  }

  private getValidator(maxList: number[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      for (const maxVal of maxList) {
        if (control.value > maxVal)
          return { maxList: true };
      }
      return null;
    }
  }
}
