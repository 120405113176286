import { Document } from './document';
import { DocumentInfo } from './document-info';
import { DocumentObject } from './document-object';
import { DocumentBuilding } from './document-building';
import { DocumentEvacuation } from './document-evacuation';
import { DocumentFire } from './document-fire';
import { DocumentSpread } from './document-spread';
import { DocumentCapacity } from './document-capacity';
import { DocumentInstallations } from './document-installations';
import { DocumentAirTreatment } from './document-airtreatment';
import { DocumentLifts } from './document-lifts';
import { DocumentOpportunities } from './document-opportunities';
import { DocumentRisks } from './document-risks';
import { DocumentProtectedObjects } from './document-protected-objects';
import { DocumentInspection } from './document-inspection';

export type DocumentKeyof = keyof Document
  | keyof DocumentInfo
  | keyof DocumentObject
  | keyof DocumentBuilding
  | keyof DocumentEvacuation
  | keyof DocumentFire
  | keyof DocumentSpread
  | keyof DocumentCapacity
  | keyof DocumentInstallations
  | keyof DocumentAirTreatment
  | keyof DocumentLifts
  | keyof DocumentOpportunities
  | keyof DocumentRisks
  | keyof DocumentProtectedObjects
  | keyof DocumentInspection;
