import { SortOrder } from '../core/collections';
import { Document } from './shared';

export const documentsConfig = {
  api: {
    documents: {
      url: 'documents',
    },
    organizationDocuments: {
      url: 'organization',
    },
    documentNotes: {
      url: 'notes',
    },
    publish: {
      url: 'publish',
    },
    moveToBin: {
      url: 'move-to-bin',
    },
    restore: {
      url: 'restore',
    }
  },
  routes: {
    documents: 'documents',
  },
  store: {
    documentsKey: 'documents',
    documentFieldNotesKey: 'notes',
  },
  filter: {
    searchableFields: ['info.buildingName'] as FieldsOf<Document>,
  },
  list: {
    defaultSortOrder: {
      fieldName: 'modifiedDate' as FieldOf<Document>,
      sortOrder: SortOrder.desc,
    },
  },
  delete: {
    confirmationText: 'radera',
  },
};
