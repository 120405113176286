import { Directive } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, AbstractControl } from '@angular/forms';
import { validate, required } from 'src/app/shared';
import { requireSelectCheckboxGroupValidator } from './require-select-checkbox-group.validator';

@Directive({
  selector: '[appRequireSelectCheckboxGroup]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RequireSelectCheckboxGroupValidatorDirective, multi: true }],
})
export class RequireSelectCheckboxGroupValidatorDirective {
  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    const validator = requireSelectCheckboxGroupValidator();

    const result = validator(control);

    return result;
  }
}
