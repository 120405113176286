import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoggerService } from 'src/app/core';
import { ErrorModalComponent } from 'src/app/core/error-modal/error-modal.component';
import { ErrorModalDialogData } from 'src/app/core/shared/error-modal-dialog-data';
import { Assert, required } from 'src/app/shared';
import { UserDetails } from 'src/app/users-management/shared';
import { UsersApiService } from 'src/app/users-management/users-api.service';
import { UsersManagementService } from 'src/app/users-management/users-management.service';
import { usersConfig } from 'src/app/users-management/users.config';
import { OrganizationApiService } from '../organization-api.service';
import { organizationConfig } from '../organization.config';
import { Organization, OrganizationActivate } from '../shared';
import { WorkerApiService } from '../../shared/worker/worker-api.service';
import { WorkerState } from '../../shared/worker/worker-state';
import { WorkerName } from '../../shared/worker/worker-name';
import { MatOptionSelectionChange } from '@angular/material/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-create-organization-form',
  templateUrl: './create-organization-form.component.html',
  styleUrls: ['./create-organization-form.component.scss'],
})
export class CreateOrganizationFormComponent implements OnInit {
  @ViewChild('createOrganizationForm')
  createOrganizationForm: NgForm;

  @Output()
  readonly created: EventEmitter<Organization>;

  @Output()
  readonly canceled: EventEmitter<void>;

  name: string;

  user: UserDetails;
  organizations: Organization[];
  organizationName: string | Organization;
  filteredOrganizations: Organization[] = [];

  organization: Organization;
  workerState: WorkerState;

  state: {
    isLoading: boolean;
    isLoadingUpdateOrganizations: boolean;
    activateWithoutUser: boolean;
    uniqueEmail: {
      isUnique: boolean;
      organizationName: string;
    }
  };

  get nameMaxLength() {
    return organizationConfig.create.validation.name.maxLength;
  }

  get nameAllowedCharacters() {
    return organizationConfig.create.validation.name.allowedCharacters;
  }

  get userNameMaxLength() {
    return usersConfig.profile.validation.name.maxLength;
  }

  get userNameAllowedCharacters() {
    return usersConfig.profile.validation.name.allowedCharacters;
  }

  get emailMaxLength() {
    return usersConfig.profile.validation.email.maxLength;
  }

  get canSubmitForm(): boolean {
    if (!this.createOrganizationForm)
      return false;

    return !this.state.isLoading && !this.state.isLoadingUpdateOrganizations
      && this.createOrganizationForm.valid && !this.createOrganizationForm.pristine;
  }

  constructor(
    private readonly organizationApiService: OrganizationApiService,
    private readonly workerApiService: WorkerApiService,
    private readonly loggerService: LoggerService,
    private readonly dialog: MatDialog,
    private readonly usersManagementService: UsersManagementService,
    private readonly usersApiService: UsersApiService
  ) {
    this.created = new EventEmitter();
    this.canceled = new EventEmitter();

    this.state = {
      isLoading: false,
      isLoadingUpdateOrganizations: false,
      activateWithoutUser: false,
      uniqueEmail: {
        isUnique: false,
        organizationName: null,
      },
    };
    this.user = new UserDetails();
    this.workerState = new WorkerState();
  }

  ngOnInit(): void {
    this.loadNewOrganizations();
    this.loadWorkerState();
  }

  private async loadNewOrganizations() {
    this.state.isLoading = true;
    this.organizations = await this.organizationApiService.getNewOrganizations();
    this.filteredOrganizations = this.organizations.slice()
      .sort((a, b) => a.name.localeCompare(b.name));
    this.state.isLoading = false;
  }

  filterOrganizations() {
    // this.organizationName could be Organization type when selects an organization from the list
    if (typeof this.organizationName !== 'string')
      return;

    const name = this.organizationName.toLowerCase();
    this.filteredOrganizations = this.organizations.filter((organizationItem: Organization) => organizationItem.name.toLowerCase().includes(name));

    this.organization = null;
    this.createOrganizationForm.controls['organizationId'].setErrors({ required: true });
  }

  organizationDisplayFn(organization: Organization): string {
    return organization ? organization.name : '';
  }

  onOrganizationSelectionChange(event: MatOptionSelectionChange) {
    this.organization = event.source.value;
  }

  private async loadWorkerState() {
    this.workerState = await this.workerApiService.getState(WorkerName.SynchronizeOrganizationsWorker);
  }

  onCancel() {
    this.canceled.emit();
  }

  async onEmailChanged() {
    const email = this.user.user.email;

    if (!email)
      return;

    const uniqueEmail = await this.usersApiService.uniqueEmail(email);
    this.state.uniqueEmail.isUnique = uniqueEmail.isUnique;
    this.state.uniqueEmail.organizationName = uniqueEmail.organizationName;

    if (!this.state.uniqueEmail.isUnique) {
      this.createOrganizationForm.controls['email'].setErrors({ emailExists: true });
    }
  }

  async onSynchronizeOrganizations() {
    this.state.isLoadingUpdateOrganizations = true;

    try {
      this.workerState = await this.organizationApiService.synchronizeOrganizations();
      await this.loadNewOrganizations();
    } catch (error) {
      this.showError(error, 'Det gick inte att synkronisera kunder.');
      await this.loadWorkerState();
    } finally {
      this.state.isLoadingUpdateOrganizations = false;
    }
  }

  async onCreate() {
    if (!this.canSubmitForm)
      return;

    this.state.isLoading = true;
    this.state.uniqueEmail.isUnique = true;

    try {
      await this.activateOrganization(this.organization.id);

      this.created.emit(this.organization);
    } catch (error) {
      // Do nothing
    } finally {
      this.state.isLoading = false;
    }
  }

  async activateOrganization(organizationId: string) {
    try {
      let organizationActivate;
      if (this.state.activateWithoutUser) {
        organizationActivate = this.collectOrganizationActivateWithoutUser(organizationId);
      } else {
        organizationActivate = this.collectOrganizationActivate(organizationId);
      }
      await this.organizationApiService.activateOrganization(organizationActivate);
    } catch (error) {
      this.showError(error, 'Det gick inte att skapa användare, försök igen senare.');
      throw error;
    }
  }

  private collectOrganizationActivate(organizationId: string): OrganizationActivate {
    Assert.isNotNull(this.user, 'user');

    const password = this.usersManagementService.getGeneratedPassword();
    const organizationActivate = new OrganizationActivate({
      organizationId,
      activateWithoutUser: this.state.activateWithoutUser,
      firstName: this.user.user.firstName,
      lastName: this.user.user.lastName,
      email: this.user.user.email,
      password
    });

    return organizationActivate;
  }

  private collectOrganizationActivateWithoutUser(organizationId: string): OrganizationActivate {
    const organizationActivate = new OrganizationActivate({
      organizationId,
      activateWithoutUser: this.state.activateWithoutUser,
    });

    return organizationActivate;
  }

  private showError(@required error: any, @required message: string) {
    this.loggerService.error(message, error);
    const errorModalDialogData = new ErrorModalDialogData({ title: message });
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: errorModalDialogData,
    });
  }
}
