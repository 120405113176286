import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, ListResponse } from '../core/api';
import { required, validate } from '../shared';
import { documentsConfig } from './documents.config';
import { Document, DocumentFieldNote } from './shared';
import { v4 as uuid } from 'uuid';
import { DocumentsApiMapperService } from './documents-api-mapper.service';
import { IDocumentFieldNoteServerResponse } from './shared/document-server-interfaces';

@Injectable({
  providedIn: 'root',
})
export class DocumentsApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly apiService: ApiService,
    private readonly documentsApiMapperService: DocumentsApiMapperService
  ) {}

  @validate
  async getDocuments(): Promise<Document[]> {
    const url = `api/${documentsConfig.api.documents.url}`;
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    let response: ListResponse<any>;
    try {
      response = await this.http.get<any>(noCacheUrl).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const documents = this.documentsApiMapperService.mapDocumentsResponse(response);

    return documents;
  }

  @validate
  async getDocumentsByOrganizationId(organizationId: string): Promise<Document[]> {
    const url = `api/${documentsConfig.api.documents.url}/${documentsConfig.api.organizationDocuments.url}/${organizationId}`;
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    let response: ListResponse<any>;
    try {
      response = await this.http.get<any>(noCacheUrl).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const documents = this.documentsApiMapperService.mapDocumentsResponse(response);

    return documents;
  }

  @validate
  async getDocumentById(@required documentId: uuid): Promise<Document> {
    const url = `api/${documentsConfig.api.documents.url}/${documentId}`;
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    let document: Document;
    try {
      const response = await this.http.get<any>(noCacheUrl).toPromise();
      document = this.documentsApiMapperService.mapDocumentResponse(response);
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    return document;
  }

  @validate
  async createDocument(@required id: uuid, @required organizationId: uuid): Promise<Document> {
    const url = `api/${documentsConfig.api.documents.url}`;

    const body = {
      id: id,
      organizationId: organizationId,
    };

    let document: Document;
    try {
      const response = await this.http.post<any>(url, body).toPromise();
      document = this.documentsApiMapperService.mapDocumentResponse(response);
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    return document;
  }

  @validate
  async patchDocument(@required document: Document): Promise<void> {
    const url = `api/${documentsConfig.api.documents.url}/${document.id}`;

    const body = this.documentsApiMapperService.mapDocumentForServer(document);

    try {
      await this.http.patch(url, body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async publishDocument(@required id: uuid, @required isPublish: boolean): Promise<void> {
    const url = `api/${documentsConfig.api.documents.url}/${id}/${documentsConfig.api.publish.url}`;

    try {
      await this.http.post(url, { id, isPublish }).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async moveToBinDocument(@required documentId: uuid): Promise<void> {
    const url = `api/${documentsConfig.api.documents.url}/${documentId}/${documentsConfig.api.moveToBin.url}`;

    try {
      await this.http.post(url, null).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async restoreDocument(@required documentId: uuid): Promise<void> {
    const url = `api/${documentsConfig.api.documents.url}/${documentId}/${documentsConfig.api.restore.url}`;

    try {
      await this.http.post(url, null).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async deleteDocument(@required documentId: uuid): Promise<void> {
    const url = `api/${documentsConfig.api.documents.url}/${documentId}`;

    try {
      await this.http.delete(url).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async getDocumentFieldNotes(@required documentId: uuid): Promise<StringMap<DocumentFieldNote>> {
    const url = `api/${documentsConfig.api.documents.url}/${documentId}/${documentsConfig.api.documentNotes.url}`;
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    let response: IDocumentFieldNoteServerResponse[];
    try {
      response = await this.http.get<IDocumentFieldNoteServerResponse[]>(noCacheUrl).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const documentNotes = this.documentsApiMapperService.mapDocumentFieldNotesResponse(response);

    return documentNotes;
  }

  @validate
  async putDocumentFieldNoteById(
    @required documentId: uuid,
    fieldId: string,
    note: string
  ): Promise<DocumentFieldNote> {
    const url = `api/${documentsConfig.api.documents.url}/${documentId}/${documentsConfig.api.documentNotes.url}/${fieldId}`;
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    // const notesBody = this.documentsApiMapperService.mapDocumentFieldNoteForServer(note);

    const notesBody = {
      note: note,
    };

    let response: IDocumentFieldNoteServerResponse;
    try {
      response = await this.http.put<IDocumentFieldNoteServerResponse>(noCacheUrl, notesBody).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    const documentNote = this.documentsApiMapperService.mapDocumentFieldNoteResponse(response);

    return documentNote;
  }

  @validate
  async deleteDocumentFieldNoteById(@required documentId: uuid, fieldId: string): Promise<void> {
    const url = `api/${documentsConfig.api.documents.url}/${documentId}/${documentsConfig.api.documentNotes.url}/${fieldId}`;
    const noCacheUrl = this.apiService.setNoCacheQueryParam(url);

    try {
      await this.http.delete(noCacheUrl).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }
}
