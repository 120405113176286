import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { uniqueValidator } from './unique.validator';
import { validate, required } from 'src/app/shared';

@Directive({
  selector: '[appUnique]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UniqueValidatorDirective, multi: true }],
})
export class UniqueValidatorDirective implements Validator {
  @Input('appUnique')
  values: string[];

  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    const validator = uniqueValidator(this.values);

    const result = validator(control);

    return result;
  }
}
