import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectOrganizationModalComponent } from './select-organization-modal/select-organization-modal.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from '../core/core.module';
import { OrganizationsPageComponent } from './organizations-page/organizations-page.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { OrganizationsTableComponent } from './organizations-table/organizations-table.component';
import { OrganizationsTableHeaderComponent } from './organizations-table-header/organizations-table-header.component';
import { OrganizationsTableRowComponent } from './organizations-table-row/organizations-table-row.component';
import {
  NgbPaginationModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbTooltipModule,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { UsersManagementModule } from '../users-management/users-management.module';
import { UpdateOrganizationTriggerDirective } from './update-organization-trigger.directive';
import { UpdateOrganizationFormComponent } from './update-organization-form/update-organization-form.component';
import { UpdateOrganizationModalComponent } from './update-organization-modal/update-organization-modal.component';
import { CreateOrganizationModalComponent } from './create-organization-modal/create-organization-modal.component';
import { DeleteOrganizationModalComponent } from './delete-organization-modal/delete-organization-modal.component';
import { CreateOrganizationFormComponent } from './create-organization-form/create-organization-form.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OrganizationComponent } from './organization/organization.component';
import { OrganizationPageComponent } from './organization-page/organization-page.component';
import { ManagePermissionsModalComponent } from './manage-permissions-modal/manage-permissions-modal.component';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { OrganizationsDataComponent } from './organizations-data/organizations-data.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DiscardDataConfirmationModalComponent } from './discard-data-confirmation-modal/discard-data-confirmation-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ActivateOrganizationModalComponent } from './activate-organization-modal/activate-organization-modal.component';

@NgModule({
  declarations: [
    SelectOrganizationModalComponent,
    OrganizationsPageComponent,
    OrganizationsComponent,
    OrganizationsTableComponent,
    OrganizationsTableHeaderComponent,
    OrganizationsTableRowComponent,
    UpdateOrganizationTriggerDirective,
    CreateOrganizationFormComponent,
    UpdateOrganizationFormComponent,
    CreateOrganizationModalComponent,
    ActivateOrganizationModalComponent,
    DeleteOrganizationModalComponent,
    UpdateOrganizationModalComponent,
    OrganizationPageComponent,
    OrganizationComponent,
    ManagePermissionsModalComponent,
    OrganizationsDataComponent,
    DiscardDataConfirmationModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    NgbPaginationModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    UsersManagementModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatListModule,
    MatTabsModule,
    MatCheckboxModule,
    MatAutocompleteModule,
  ],
  exports: [
    OrganizationsPageComponent,
    SelectOrganizationModalComponent,
    CreateOrganizationModalComponent,
    DeleteOrganizationModalComponent,
    UpdateOrganizationModalComponent,
    OrganizationPageComponent,
  ]
})
export class OrganizationModule {}
