<div
  class="confirmation-row"
  [ngClass]="cssClass"
  [class.show-slide-in]="isVisible"
  [class.hide-slide-out]="!isVisible"
>
  <div class="confirmation-message row">
    <div class="col-auto d-flex flex-row content-container text-truncate">
      <ng-content></ng-content>
    </div>

    <div class="col-3 text-nowrap my-auto">
      <button type="button" class="btn btn-light ml-4" (click)="onCancel()">Cancel</button>

      <button type="button" class="btn btn-outline-light ml-2" (click)="onConfirm()">Confirm</button>
    </div>
  </div>
</div>
