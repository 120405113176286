import { StoreStateService } from './store-state.service';
import { Assert } from 'src/app/shared';
import { ActionReducer, Action, MetaReducer } from '@ngrx/store';

export function getSaveStateMetaReducer<T>(storeStateService: StoreStateService): MetaReducer<T> {
  Assert.isNotNull(storeStateService, 'store');

  const saveStateMetaReducer: MetaReducer<T> = (reducer: ActionReducer<T>): ActionReducer<T> => (
    state: T,
    action: Action
  ) => {
    Assert.isNotNull(reducer, 'reducer');
    Assert.isNotNull(action, 'action');

    const updatedState = reducer(state, action);
    storeStateService.saveState(updatedState);

    return updatedState;
  };

  return saveStateMetaReducer;
}

export function getMetaReducers<T>(storeStateService: StoreStateService): MetaReducer<T>[] {
  Assert.isNotNull(storeStateService, 'storeStateService');

  const saveStateMetaReducer = getSaveStateMetaReducer<T>(storeStateService);

  return [saveStateMetaReducer];
}
