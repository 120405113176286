import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbPaginationModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbTooltipModule,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../core/core.module';
import { EditUserFormComponent } from './edit-user-form/edit-user-form.component';
import { EditUserModalComponent } from './edit-user-modal/edit-user-modal.component';
import { EditUserTriggerDirective } from './edit-user-trigger.directive';
import { DeleteUserModalComponent } from './delete-user-modal/delete-user-modal.component';
import { UsersTableHeaderComponent } from './users-table-header/users-table-header.component';
import { UsersTableRowComponent } from './users-table-row/users-table-row.component';
import { UsersTableComponent } from './users-table/users-table.component';
import { UsersComponent } from './users/users.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SharedModule } from '../shared/components/shared.module';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { ChangePasswordTriggerDirective } from './change-password-trigger.directive';
import { InternalUsersComponent } from './internal-users/internal-users.component';
import { ResetUserPasswordModalComponent } from './reset-user-password-modal/reset-user-password-modal.component';
import { ResetPasswordTriggerDirective } from './reset-password-trigger.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    UsersComponent,
    UsersTableComponent,
    UsersTableHeaderComponent,
    UsersTableRowComponent,
    DeleteUserModalComponent,
    EditUserModalComponent,
    EditUserFormComponent,
    EditUserTriggerDirective,
    UserProfileComponent,
    ChangePasswordModalComponent,
    ChangePasswordFormComponent,
    ChangePasswordTriggerDirective,
    InternalUsersComponent,
    ResetUserPasswordModalComponent,
    ResetPasswordTriggerDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    NgbPaginationModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    NgbDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    SharedModule,
    MatCheckboxModule,
  ],
  exports: [
    UsersComponent,
    EditUserTriggerDirective,
    UserProfileComponent,
    InternalUsersComponent,
    EditUserFormComponent,
    ResetPasswordTriggerDirective,
  ]
})
export class UsersManagementModule {}
