<section class="tab-item-layout" *ngIf="document">
  <app-project-tab-header
    [header]="'SÄRSKILT SKYDDSVÄRDA OBJEKT'"
    [documentId]="document?.id"
    [isCompleted]="document?.protectedObjects.isCompleted"
    [isDocumentCompleted]="document?.isCompleted"
    [isPublished]="document?.isPublished"
    [previousPageName]="previousPageName"
    [nextPageName]="nextPageName"
    [formToValidate]="tabForm"
    [disabled]="!hasEditPermissions"
    [sectionType]="sectionType"
    [notes]="notes"
    (publishDocument)="onPublishDocument($event)"
    (completeForm)="onCompleteForm($event)"
  ></app-project-tab-header>

  <main>
    <form #tabForm="ngForm" novalidate [class.readonly-container]="!hasEditPermissions">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <app-multiselect-readonly-view
              *ngIf="!hasEditPermissions"
              title="Särskilt skyddsvärda objekt förekommer i form av"
              [values]="worthyProtectedObjectTypes"
              [selectedKeys]="document.protectedObjects.worthyProtectedObjectTypeIds"
              [notes]="notes"
              [documentId]="document?.id"
              [sectionType]="sectionType"
              sectionFieldId="worthyProtectedObjectTypeIds"
            ></app-multiselect-readonly-view>

            <mat-form-field
              floatLabel="always"
              class="w-100"
              title="Särskilt skyddsvärda objekt förekommer i form av"
              *ngIf="hasEditPermissions"
            >
              <mat-label
                title="Particularly worthy of protection objects occur in the form of"
                >Särskilt skyddsvärda objekt förekommer i form av</mat-label
              >

              <mat-select
                [(ngModel)]="document.protectedObjects.worthyProtectedObjectTypeIds"
                #worthyProtectedObjectTypeIds="ngModel"
                name="worthyProtectedObjectTypeIds"
                required
                multiple
                [appDocumentNotApplicableSelectOptions]="worthyProtectedObjectTypes"
                (ngModelChange)="onFormDataChanged()"
              >
                <mat-option
                  *ngFor="let worthyProtectedObjectType of worthyProtectedObjectTypes | keyvalue"
                  [value]="worthyProtectedObjectType.key"
                  [title]="worthyProtectedObjectType.value.displayName"
                  [disabled]="worthyProtectedObjectType.value.isDisabled"
                  >{{ worthyProtectedObjectType.value.displayName }}</mat-option
                >
              </mat-select>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[worthyProtectedObjectTypeIds.name]"-->
<!--                [class.text-secondary-primary]="notes[worthyProtectedObjectTypeIds.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[worthyProtectedObjectTypeIds.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="worthyProtectedObjectTypeIds.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field class="w-100" title="Utökad beskrivning avseende särskilt skyddsvärda objekt">
              <mat-label
                title="Extended description regarding objects that are particularly worthy of protection"
                >Utökad beskrivning avseende särskilt skyddsvärda objekt</mat-label
              >
              <textarea
                rows="10"
                matInput
                [disabled]="!hasEditPermissions"
                [(ngModel)]="document.protectedObjects.protectedObjectsDescription"
                #protectedObjectsDescription="ngModel"
                name="protectedObjectsDescription"
                (blur)="onFormDataChanged()"
              ></textarea>
<!--              <button-->
<!--                mat-icon-button-->
<!--                matSuffix-->
<!--                *ngIf="hasEditPermissions || notes[protectedObjectsDescription.name]"-->
<!--                [class.text-secondary-primary]="notes[protectedObjectsDescription.name]"-->
<!--                appSectionFieldNote-->
<!--                [ngbTooltip]="notes[protectedObjectsDescription.name]?.note"-->
<!--                [documentId]="document?.id"-->
<!--                [sectionType]="sectionType"-->
<!--                [sectionFieldId]="protectedObjectsDescription.name"-->
<!--                [notes]="notes"-->
<!--                [isViewOnly]="!hasEditPermissions"-->
<!--                tooltipClass="medium-large-size-tooltip"-->
<!--                container="body"-->
<!--              >-->
<!--                <mat-icon>attach_file</mat-icon>-->
<!--              </button>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </main>
</section>
