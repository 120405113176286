import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator } from '@angular/forms';
import { required, validate } from 'src/app/shared';
import { emailWithSwedishCharactersValidator } from './email-with-swedish-characters.validator';

@Directive({
  selector: '[appEmailWithSwedishCharacters]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailWithSwedishCharactersValidatorDirective, multi: true }],
})
export class EmailWithSwedishCharactersValidatorDirective implements Validator {
  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    const validator = emailWithSwedishCharactersValidator();

    const result = validator(control);

    return result;
  }
}
