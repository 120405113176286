import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { projectConfig } from '../project.config';
import { v4 as uuid } from 'uuid';
import { DocumentsStoreService } from 'src/app/documents/documents-store';
import { Observable, Subscription } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';
import { Document, DocumentSectionName, DocumentSectionRoute, IDocumentItem } from 'src/app/documents/shared';
import { DocumentSectionType } from 'src/app/documents/shared/document-structure/document-section';
import { forEach } from 'lodash';
import { OnDestroy } from '@angular/core';
import { required, validate } from 'src/app/shared';
import { take } from 'rxjs/operators';
import { LayoutStoreService } from '../../layout/layout-store';

export class ProjectMenu {
  readonly id: DocumentSectionType;
  readonly displayName: string;
  isComplete: boolean;

  constructor(id: DocumentSectionType, displayName: string, isComplete: boolean = false) {
    this.id = id;
    this.displayName = displayName;
    this.isComplete = isComplete;
  }
}

@Unsubscribe()
@Component({
  selector: 'app-project-navigation',
  templateUrl: './project-navigation.component.html',
  styleUrls: ['./project-navigation.component.scss'],
})
export class ProjectNavigationComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  documentId: uuid;

  isLeftMenuOpened$: Observable<boolean>;
  menus: ProjectMenu[];
  document: Document;

  private documentsSubscription: Subscription;

  constructor(private documentsStoreService: DocumentsStoreService, private readonly layoutStoreService: LayoutStoreService,) {
    this.documentId = null;
    this.menus = [];
  }

  async ngOnInit(): Promise<void> {
    this.menus = [
      new ProjectMenu(DocumentSectionType.info, DocumentSectionName[DocumentSectionType.info]),
      new ProjectMenu(DocumentSectionType.object, DocumentSectionName[DocumentSectionType.object]),
      new ProjectMenu(DocumentSectionType.building, DocumentSectionName[DocumentSectionType.building]),
      new ProjectMenu(DocumentSectionType.evacuation, DocumentSectionName[DocumentSectionType.evacuation]),
      new ProjectMenu(DocumentSectionType.fire, DocumentSectionName[DocumentSectionType.fire]),
      new ProjectMenu(DocumentSectionType.spread, DocumentSectionName[DocumentSectionType.spread]),
      new ProjectMenu(DocumentSectionType.capacity, DocumentSectionName[DocumentSectionType.capacity]),
      new ProjectMenu(DocumentSectionType.installations, DocumentSectionName[DocumentSectionType.installations]),
      new ProjectMenu(DocumentSectionType.airtreatment, DocumentSectionName[DocumentSectionType.airtreatment]),
      new ProjectMenu(DocumentSectionType.lifts, DocumentSectionName[DocumentSectionType.lifts]),
      new ProjectMenu(DocumentSectionType.opportunities, DocumentSectionName[DocumentSectionType.opportunities]),
      new ProjectMenu(DocumentSectionType.risks, DocumentSectionName[DocumentSectionType.risks]),
      new ProjectMenu(DocumentSectionType.protectedObjects, DocumentSectionName[DocumentSectionType.protectedObjects]),
      new ProjectMenu(DocumentSectionType.inspection, DocumentSectionName[DocumentSectionType.inspection]),
    ];

    if (this.documentId) {
      const document: Document = await this.documentsStoreService
        .getDocumentById(this.documentId)
        .pipe(take(1))
        .toPromise();
      this.onDocumentChanged(document);
    }

    this.isLeftMenuOpened$ = this.layoutStoreService.getIsLeftMenuOpened();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<ProjectNavigationComponent, SimpleChange>;
    const documentIdChange = componentChanges.documentId;

    if (documentIdChange) {
      this.documentIdChanged(this.documentId);
    }
  }

  ngOnDestroy(): void {}

  getMenuRoute(menuId: string): string[] {
    const route = ['/', projectConfig.routes.project, this.documentId, DocumentSectionRoute[menuId]];

    return route;
  }

  async onMenuClick(): Promise<void> {
    const currentState = await this.layoutStoreService.getIsLeftMenuOpened().pipe(take(1)).toPromise();
    this.layoutStoreService.openLeftMenu(!currentState);
  }

  @validate
  trackByItem(index: number, @required item: ProjectMenu): string {
    return item.id;
  }

  private documentIdChanged(documentId: uuid): void {
    this.cancelDocumentSubscription();

    this.documentsSubscription = this.documentsStoreService
      .getDocumentById(documentId)
      .subscribe((document: Document) => this.onDocumentChanged(document));
  }

  private onDocumentChanged(document: Document): void {
    this.document = document;
    if (!document) {
      forEach(this.menus, (menu) => (menu.isComplete = false));
      return;
    }

    const mapDocument: StringMap<IDocumentItem> = this.document as any;
    forEach(this.menus, (menu) => {
      const section = mapDocument[menu.id];
      if (section) {
        menu.isComplete = section.isCompleted;
      }
    });
  }

  private cancelDocumentSubscription(): void {
    if (!this.documentsSubscription) {
      return;
    }

    try {
      this.documentsSubscription.unsubscribe();
    } catch {
      // ignore
    } finally {
      this.documentsSubscription = null;
    }
  }
}
