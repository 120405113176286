import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { required, RouterUtils, validate } from '../shared';
import { projectConfig } from './project.config';

@Injectable({
  providedIn: 'root',
})
export class ProjectIdResolverService {
  @validate
  resolve(@required route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const documentId = RouterUtils.getRouteParamRecursively(projectConfig.routes.params.documentId, route);
    return of(documentId);
  }
}
