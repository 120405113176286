import { Injectable } from '@angular/core';
import { Document, DocumentSectionName, DocumentSectionType, IDocumentItem } from '../documents/shared';
import { DocumentsValidationService } from '../documents/documents-validation.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DocumentSectionCompletionUpdateService {

  private readonly documentValidateFunctions: StringMap<(documentSection: IDocumentItem, document: Document) => boolean> = {};

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly documentsValidationService: DocumentsValidationService
  ) {
    this.documentValidateFunctions[DocumentSectionType.info] = this.documentsValidationService.isInfoValid;
    this.documentValidateFunctions[DocumentSectionType.building] = this.documentsValidationService.isBuildingValid;
    this.documentValidateFunctions[DocumentSectionType.evacuation] = this.documentsValidationService.isEvacuationValid;
    this.documentValidateFunctions[DocumentSectionType.capacity] = this.documentsValidationService.isCapacityValid;
    this.documentValidateFunctions[DocumentSectionType.fire] = this.documentsValidationService.isFireValid;
    this.documentValidateFunctions[DocumentSectionType.opportunities] = this.documentsValidationService.isOpportunitiesValid;
    this.documentValidateFunctions[DocumentSectionType.risks] = this.documentsValidationService.isRisksValid;
    this.documentValidateFunctions[DocumentSectionType.inspection] = this.documentsValidationService.isInspectionValid;
  }

  checkAndUpdate(document: Document, excludeSection: DocumentSectionType) {
    const mapDocument: StringMap<IDocumentItem> = document as any;
    const documentSectionIds = Object.values(DocumentSectionType).filter(section => section !== excludeSection);

    for (const sectionId of documentSectionIds) {
      const section = mapDocument[sectionId];

      if (!section || !section.isCompleted)
        continue;

      const validateFn = this.documentValidateFunctions[sectionId];

      // Skip if there isn't validate function. We didn't add all validate functions yet.
      // TODO: Add later.
      if (!validateFn)
        continue;

      const isValid = validateFn(section, document);

      if (isValid)
        continue;

      section.isValid = false;
      section.isCompleted = false;

      this.snackBar.open(`${DocumentSectionName[sectionId]} är inte klarmarkerad.`, 'OK');
    }
  }
}
