import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserDetails } from '../shared';
import { validate, required } from 'src/app/shared';
import { DeleteUserModalComponent } from '../delete-user-modal/delete-user-modal.component';
import { AuthDetails } from 'src/app/authentication/shared';
import { UserRole } from 'src/app/authentication/shared/user-role';
import { UsersType } from 'src/app/authentication/shared/users-type';
import { MatDialog } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-users-table-row]',
  templateUrl: './users-table-row.component.html',
  styleUrls: ['./users-table-row.component.scss'],
})
export class UsersTableRowComponent {
  private readonly dialog: MatDialog;

  @Input()
  item: UserDetails;

  @Input()
  isDisabled: boolean;

  @Input()
  currentUserAuthDetails: AuthDetails;

  @Input()
  organizationId: string;

  @Input()
  usersType: UsersType;

  @Output()
  readonly deleteUser: EventEmitter<UserDetails>;

  @Output()
  readonly userMoved: EventEmitter<UserDetails>;

  @Output()
  readonly userEdited: EventEmitter<UserDetails>;

  constructor(dialog: MatDialog) {
    this.dialog = dialog;

    this.deleteUser = new EventEmitter();
    this.userMoved = new EventEmitter();
    this.userEdited = new EventEmitter();
  }

  @validate
  onDeleteUser(@required item: UserDetails) {
    const dialogRef = this.dialog.open(DeleteUserModalComponent, {
      data: item.user,
    });
    dialogRef.afterClosed().subscribe((deleteUser: boolean) => {
      if (deleteUser) {
        this.deleteUser.emit(item);
      }
    });
  }

  @validate
  isDeleteUserDenied(@required item: UserDetails) {
    return !item.stats || item.stats.docsCount > 0;
  }

  onUserEdited(details: UserDetails): void {
    this.userEdited.next(details);
  }

  getUserRoleName(role: UserRole): string {
    switch (role) {
      case UserRole.author:
      case UserRole.customerAuthor:
        return 'Författare';
      case UserRole.customerReader:
        return 'Läsare';
      case UserRole.customerAdmin:
        return 'Administratör';
      case UserRole.superAdmin:
        return 'Systemadministratör';
    }
  }
}
