import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Assert } from 'src/app/shared';

export const requireSpecialCharacterValidationKey = 'requireSpecialCharacter';

export function requireSpecialCharacterValidator(specialCharacters?: string[]): ValidatorFn {
  let validationPattern: RegExp;
  if (specialCharacters && specialCharacters.length > 0) {
    const characters = specialCharacters.join('\\');
    validationPattern = new RegExp(`(?=.*[${characters}])`);
  } else {
    validationPattern = new RegExp('[^a-zA-Z0-9]');
  }

  const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    Assert.isNotNull(control, 'control');

    const isValid = validationPattern.test(control.value);
    return !isValid ? { [requireSpecialCharacterValidationKey]: true } : null;
  };

  return validator;
}
