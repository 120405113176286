import { Injectable } from '@angular/core';
import _ from 'lodash';
import { UserRole } from '../authentication/shared/user-role';
import { PasswordCharacters } from '../core/password/password-characters';
import { PasswordSettings } from '../core/password/password-settings';
import { PasswordUtils } from '../core/password/password-utils';
import { Assert, required, validate } from '../shared';
import { User, UserDetails, UserStats } from './shared';
import { ChangePasswordDetails, IChangePasswordDetails } from './shared/change-password-details';
import { IUserPermission, UserPermission } from './shared/user-permission';
import { usersConfig } from './users.config';

@Injectable({
  providedIn: 'root',
})
export class UsersManagementService {
  constructor() {}

  public getGeneratedPassword(): string {
    const settings = new PasswordSettings({
      characters: new PasswordCharacters({
        lowercase: usersConfig.password.generation.characters.lowercase,
        uppercase: usersConfig.password.generation.characters.uppercase,
        numbers: usersConfig.password.generation.characters.numbers,
      }),
      minLength: usersConfig.password.validation.minLength,
      maxLength: usersConfig.password.validation.temporaryMaxLength,
    });

    const password = PasswordUtils.generateRandomPassword(settings);
    return password;
  }

  @validate
  public mapUserDetailsWithPassToFormData(@required userDetails: UserDetails, @required password: string): any {
    Assert.isNotNull(userDetails.user, 'response.user');

    const body = {
      firstName: userDetails.user.firstName,
      lastName: userDetails.user.lastName,
      email: userDetails.user.email,
      password: password,
      organizationId: userDetails.user.organizationId,
      roleName: userDetails.role,
      permissionsIds: userDetails.permissionsIds,
      canReviewDocument: userDetails.user.canReviewDocument,
    };

    return body;
  }

  @validate
  public mapUserDetailsResponse(@required response: any): UserDetails {
    const user = this.mapUserResponse(response.user);
    const stats = this.mapStatsResponse(response.stats);

    const userDetails = new UserDetails({
      user: user,
      role: response.userRole as UserRole,
      stats: stats,
      permissionsIds: response.permissionsIds || [],
    });

    return userDetails;
  }

  @validate
  public mapUserResponse(@required response: any): User {
    const user = new User({
      id: response.id,
      firstName: response.firstName,
      lastName: response.lastName,
      email: response.email,
      organizationId: response.organizationId,
      canReviewDocument: response.canReviewDocument,
    });

    return user;
  }

  @validate
  public mapChangeUserPasswordToDto(
    @required changePasswordDetails: ChangePasswordDetails,
    @required userId: string
  ): IChangePasswordDetails {
    const passwordDetails = {
      userId: userId,
      currentPassword: changePasswordDetails.currentPassword,
      newPassword: changePasswordDetails.newPassword,
    } as IChangePasswordDetails;

    return passwordDetails;
  }

  @validate
  public mapStatsResponse(@required response: any): UserStats {
    const stats = new UserStats({
      docsCount: response.docsCount,
    });

    return stats;
  }

  public mapUserPermissionsResponse(permissions: any): UserPermission[] {
    if (_.isEmpty(permissions)) {
      return [];
    }
    const userPermissions = _.map(permissions, (item) => this.mapToUserPermission(item));

    return userPermissions;
  }

  public mapToUserPermission(userPermissionDto: IUserPermission): UserPermission {
    if (_.isEmpty(userPermissionDto)) {
      return;
    }

    const userPermission = new UserPermission({
      id: userPermissionDto.id,
      name: userPermissionDto.name,
    });

    return userPermission;
  }

  @validate
  public mapUserDetailsToFormData(@required userDetails: UserDetails) {
    Assert.isNotNull(userDetails.user, 'response.user');

    const body = {
      id: userDetails.user.id,
      firstName: userDetails.user.firstName,
      lastName: userDetails.user.lastName,
      email: userDetails.user.email,
      canReviewDocument: userDetails.user.canReviewDocument,
      roleName: userDetails.role,
      permissionsIds: userDetails.permissionsIds,
    };

    return body;
  }

  @validate
  public mapResetUserPassToFormData(@required userId: string, @required newPassword: string): any {
    const body = {
      userId: userId,
      newPassword: newPassword,
    };

    return body;
  }
}
