import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentTabContentComponent } from '../project-tab-container/project-tab-container.component';
import { Document, DocumentProtectedObjects, DocumentSectionType } from 'src/app/documents/shared';
import { projectConfig } from '../project.config';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { DocumentsService } from 'src/app/documents/documents.service';
import { DocumentsValidationService } from 'src/app/documents/documents-validation.service';
import { DocumentSelectOptionType } from 'src/app/documents/shared/document-structure/document-select-option-type';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-project-tab-protected-objects',
  templateUrl: './project-tab-protected-objects.component.html',
  styleUrls: ['./project-tab-protected-objects.component.scss'],
})
export class ProjectTabProtectedObjectsComponent extends DocumentTabContentComponent implements OnInit {
  @ViewChild('tabForm')
  tabForm: NgForm;

  worthyProtectedObjectTypes: StringMap<DocumentSelectOptionType>;

  get previousPageName(): string {
    return projectConfig.routes.risks;
  }

  get nextPageName(): string {
    return projectConfig.routes.inspection;
  }

  constructor(
    dialog: MatDialog,
    snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private documentsValidationService: DocumentsValidationService,
    private documentsSectionDataService: DocumentsSectionDataService,
  ) {
    super(DocumentSectionType.protectedObjects, dialog, snackBar);
  }

  ngOnInit(): void {
    this.worthyProtectedObjectTypes = this.documentsSectionDataService.getWorthyProtectedObjectOptionTypes();
  }

  async onCompleteForm(isCompleted: boolean): Promise<void> {
    this.document.protectedObjects.isCompleted = isCompleted && this.document.protectedObjects.isValid;
    await this.saveDocument(true);
  }

  onFormDataChanged(): void {
    this.saveDocument(false);
  }

  private async saveDocument(formCompletedExplicitly: boolean): Promise<void> {
    if (!this.hasEditPermissions) {
      return;
    }

    const isCompletedBeforeChange = this.document.protectedObjects.isCompleted;
    this.document.protectedObjects.isValid = this.documentsValidationService.isProtectedObjectsValid(
      this.document.protectedObjects,
      this.document
    );
    this.document.protectedObjects.isCompleted = this.document.protectedObjects.isCompleted && this.document.protectedObjects.isValid;
    const partialDocument = {
      protectedObjects: new DocumentProtectedObjects({ ...this.document.protectedObjects }),
    } as Document;

    try {
      await this.documentsService.patchDocumentAndSave(this.document, partialDocument);
    } catch (ex) {
      this.showErrorModal('Ett fel uppstod när ändringarna skulle sparas.', ex);
      return;
    }

    if (formCompletedExplicitly || (isCompletedBeforeChange && !this.document.protectedObjects.isCompleted)) {
      this.showSectionCompletedSnackBar(DocumentSectionType.protectedObjects, this.document.protectedObjects.isCompleted);
    }
  }
}
