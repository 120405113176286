<div mat-dialog-content>
  <form #createOrganizationForm="ngForm" novalidate (keydown.enter)="onCreate()">
    <div class="modal-body pl-0">
      <div class="row">
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>Kund</mat-label>
            <input
              type="text"
              placeholder="Välj en kund"
              matInput
              required
              [matAutocomplete]="auto"
              name="organizationId"
              [disabled]="state.isLoading"
              [(ngModel)]="organizationName"
              (ngModelChange)="filterOrganizations()"
              #organizationId="ngModel"
              [ngClass]="{ 'is-invalid': organizationId.dirty && organizationId.invalid }"
            >
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="organizationDisplayFn"
            >
                <mat-option
                  *ngFor="let organizationItem of filteredOrganizations"
                  [value]="organizationItem"
                  (onSelectionChange)="onOrganizationSelectionChange($event)"
                >
                  {{ organizationItem.name }}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="organizationId.errors">
              <div *ngIf="!!organizationId.errors?.required">Välj en kund</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col p-0 pb-4 ml-1 align-self-center">
          <button
            type="button"
            mat-raised-button
            class="manage-btn"
            [disabled]="state.isLoadingUpdateOrganizations"
            (click)="onSynchronizeOrganizations()"
          >
            Uppdatera kunder
            <mat-icon *ngIf="!state.isLoadingUpdateOrganizations">refresh</mat-icon>
            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="state.isLoadingUpdateOrganizations"></i>
          </button>
        </div>
        <div class="col p-0 pb-4 align-self-center text-small">
          <div *ngIf="workerState.lastFinishedDate">
            <mat-icon class="text-valid align-middle" aria-hidden="false">check</mat-icon>
            <div class="d-inline-block align-middle ml-1">
              <strong>Senast uppdaterad:</strong>
              <div>{{ workerState.lastFinishedDate | date:'short' }}</div>
            </div>
          </div>
          <div *ngIf="workerState.lastFailedDate">
            <mat-icon class="align-middle" aria-hidden="false" color="warn">error_outline</mat-icon>
            <div class="d-inline-block align-middle ml-1">
              <strong>Misslyckad synk:</strong>
              <div>{{ workerState.lastFailedDate | date:'short' }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col pr-1">Lägg till administratör för kund</div>
        <div class="col pl-1">
          <mat-checkbox [(ngModel)]="state.activateWithoutUser" name="activateWithoutUser">Aktivera kund utan administratör</mat-checkbox>
        </div>
      </div>

      <section>
        <div class="row">
          <div class="col pr-1">
            <mat-form-field class="w-100">
              <mat-label>Förnamn</mat-label>
              <input
                matInput
                required
                [disabled]="state.isLoading || state.activateWithoutUser"
                id="firstName"
                [(ngModel)]="user.user.firstName"
                #firstName="ngModel"
                name="firstName"
                [appAllowedCharacters]="nameAllowedCharacters"
                [maxlength]="nameMaxLength"
                [ngClass]="{ 'is-invalid': firstName.dirty && firstName.invalid }"
                placeholder="Förnamn"
              />
              <mat-error *ngIf="firstName.errors">
                <div *ngIf="!!firstName.errors?.required">Ange ett förnamn</div>
                <div *ngIf="!!firstName.errors?.maxlength">Får inte vara mer än {{ nameMaxLength }} tecken.</div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col pl-1">
            <mat-form-field class="w-100">
              <mat-label>Efternamn</mat-label>
              <input
                matInput
                required
                [disabled]="state.isLoading || state.activateWithoutUser"
                id="lastName"
                [(ngModel)]="user.user.lastName"
                #lastName="ngModel"
                name="lastName"
                [maxlength]="nameMaxLength"
                [ngClass]="{ 'is-invalid': lastName.dirty && lastName.invalid }"
                [appAllowedCharacters]="nameAllowedCharacters"
                placeholder="Efternamn"
              />
              <mat-error *ngIf="lastName.errors">
                <div *ngIf="!!lastName.errors?.required">Ange ett efternamn</div>
                <div *ngIf="!!lastName.errors?.maxlength">Får inte vara mer än {{ nameMaxLength }} tecken.</div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <mat-form-field class="w-100">
          <mat-label>E-postadress</mat-label>
          <input
            matInput
            required
            [disabled]="state.isLoading || state.activateWithoutUser"
            [(ngModel)]="user.user.email"
            (change)="onEmailChanged()"
            #email="ngModel"
            name="email"
            [maxlength]="emailMaxLength"
            [class.is-invalid]="(email.dirty || email.touched) && email.invalid"
            placeholder="E-postadress"
            email
          />
          <mat-error>
            <div *ngIf="!!email.errors?.required">Ange en e-postadress</div>
            <div *ngIf="!!email.errors?.maxlength">Får inte vara mer än {{ emailMaxLength }} tecken.</div>
            <div *ngIf="!!email.errors?.email">E-postadress är ogiltig</div>
            <div *ngIf="!!email.errors?.emailExists">E-postadressen används redan hos {{ state.uniqueEmail.organizationName }}</div>
          </mat-error>
        </mat-form-field>
        <div class="alert alert-primary d-none" role="alert">Ett autogenererat lösenord skickas till användaren.</div>
      </section>
    </div>
  </form>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-raised-button (click)="onCancel()">Avbryt</button>
  <button
    class="activate-btn"
    mat-raised-button
    (click)="onCreate()"
    cdkFocusInitial
    [disabled]="!canSubmitForm"
  >
    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="state.isLoading"></i>Aktivera
  </button>
</div>
