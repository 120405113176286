export class DocumentFieldNote {
  readonly fieldId: string;
  readonly note?: string;
  readonly authorName?: string;
  readonly updateDate?: moment.Moment;

  constructor({ fieldId, note, authorName, updateDate }: Partial<DocumentFieldNote> = {}) {
    this.fieldId = fieldId;
    this.note = note;
    this.authorName = authorName;
    this.updateDate = updateDate;
  }
}
