import { IDocumentItem } from './document';

export class DocumentEvacuation implements IDocumentItem {
  isWindowRemoval?: boolean;
  isOnlyOneEscapeRoute?: boolean;
  isEvacuationRescueService?: boolean;
  isEvacuationFromAnotherFireExit?: boolean;
  isEvacuationFromAnotherPartOfBuilding?: boolean;
  isHorizontalEvacuation?: boolean;
  isEvacuationViaTr1?: boolean;
  isOnlyEscapeRouteViaTr1?: boolean;
  isTemporaryEvacuationAFS20201?: boolean;
  isReEntryRequirements?: boolean;
  isEvacuationViaAutomaticHorizontalSlidingDoors?: boolean;
  isEvacuationViaEvacuationElevator?: boolean;
  isEvacuationViaRescueElevator?: boolean;
  lockedDoorsIds?: string[];
  isEvacuationForDisabilitiesPeople?: boolean;
  isAccessibleAndUsableHorizontalEvacuation?: boolean;
  isCommunicationEquipmentEvacuation?: boolean;
  isEvacuationViaCommonPartOfSeparatedRoute?: boolean;
  isMattressEvacuationOfBedriddenPatients?: boolean;
  isClassifiedEscapeRoute?: boolean;
  isEvacuationPlansInBuilding?: boolean;
  isImageRequirementsAccordingVk4?: boolean;
  extendedEvacuationDescription?: string;
  isCompleted: boolean;
  isValid: boolean;

  constructor({
    isWindowRemoval,
    isOnlyOneEscapeRoute,
    isEvacuationRescueService,
    isEvacuationFromAnotherFireExit,
    isEvacuationFromAnotherPartOfBuilding,
    isHorizontalEvacuation,
    isEvacuationViaTr1,
    isOnlyEscapeRouteViaTr1,
    isTemporaryEvacuationAFS20201,
    isReEntryRequirements,
    isEvacuationViaAutomaticHorizontalSlidingDoors,
    isEvacuationViaEvacuationElevator,
    isEvacuationViaRescueElevator,
    lockedDoorsIds,
    isEvacuationForDisabilitiesPeople,
    isAccessibleAndUsableHorizontalEvacuation,
    isCommunicationEquipmentEvacuation,
    isEvacuationViaCommonPartOfSeparatedRoute,
    isMattressEvacuationOfBedriddenPatients,
    isClassifiedEscapeRoute,
    isEvacuationPlansInBuilding,
    isImageRequirementsAccordingVk4,
    extendedEvacuationDescription,
    isCompleted,
    isValid,
  }: Partial<DocumentEvacuation> = {}) {
    this.isWindowRemoval = isWindowRemoval;
    this.isOnlyOneEscapeRoute = isOnlyOneEscapeRoute;
    this.isEvacuationRescueService = isEvacuationRescueService;
    this.isEvacuationFromAnotherFireExit = isEvacuationFromAnotherFireExit;
    this.isEvacuationFromAnotherPartOfBuilding = isEvacuationFromAnotherPartOfBuilding;
    this.isHorizontalEvacuation = isHorizontalEvacuation;
    this.isEvacuationViaTr1 = isEvacuationViaTr1;
    this.isOnlyEscapeRouteViaTr1 = isOnlyEscapeRouteViaTr1;
    this.isTemporaryEvacuationAFS20201 = isTemporaryEvacuationAFS20201;
    this.isReEntryRequirements = isReEntryRequirements;
    this.isEvacuationViaAutomaticHorizontalSlidingDoors = isEvacuationViaAutomaticHorizontalSlidingDoors;
    this.isEvacuationViaEvacuationElevator = isEvacuationViaEvacuationElevator;
    this.isEvacuationViaRescueElevator = isEvacuationViaRescueElevator;
    this.lockedDoorsIds = lockedDoorsIds || [];
    this.isEvacuationForDisabilitiesPeople = isEvacuationForDisabilitiesPeople;
    this.isAccessibleAndUsableHorizontalEvacuation = isAccessibleAndUsableHorizontalEvacuation;
    this.isCommunicationEquipmentEvacuation = isCommunicationEquipmentEvacuation;
    this.isEvacuationViaCommonPartOfSeparatedRoute = isEvacuationViaCommonPartOfSeparatedRoute;
    this.isMattressEvacuationOfBedriddenPatients = isMattressEvacuationOfBedriddenPatients;
    this.isClassifiedEscapeRoute = isClassifiedEscapeRoute;
    this.isEvacuationPlansInBuilding = isEvacuationPlansInBuilding;
    this.isImageRequirementsAccordingVk4 = isImageRequirementsAccordingVk4;
    this.extendedEvacuationDescription = extendedEvacuationDescription;
    this.isCompleted = isCompleted;
    this.isValid = isValid;
  }
}
