import { PasswordCharacters } from './password-characters';

export class PasswordSettings {
  readonly minLength: number;
  readonly maxLength: number;
  readonly characters: PasswordCharacters;

  constructor({ minLength, maxLength, characters }: Partial<PasswordSettings> = {}) {
    this.minLength = minLength;
    this.maxLength = maxLength;
    this.characters = new PasswordCharacters(characters);
  }
}
