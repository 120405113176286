import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { required, validate } from '../shared';
import { appConfig } from '../app.config';
import { ApiService } from '../core/api';
import { Permission } from './shared/permission';

@Injectable({
  providedIn: 'root',
})
export class PermissionsApiService {
  private readonly http: HttpClient;
  private readonly apiService: ApiService;

  constructor(http: HttpClient, apiService: ApiService) {
    this.http = http;
    this.apiService = apiService;
  }

  @validate
  async createPermissionGroup(@required permission: Permission): Promise<Permission> {
    const url = `${appConfig.api.baseUrl}/DocumentPermissions`;

    try {
      return await this.http.post<Permission>(url, permission).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async updatePermissionGroup(@required permission: Permission): Promise<Permission> {
    const url = `${appConfig.api.baseUrl}/DocumentPermissions`;

    try {
      return await this.http.put<Permission>(url, permission).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async deletePermissionGroup(@required id: string) {
    const url = `${appConfig.api.baseUrl}/DocumentPermissions/${id}`;

    try {
      return await this.http.delete(url).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async getPermissionGroups(@required organizationId: string): Promise<Permission[]> {
    const url = `${appConfig.api.baseUrl}/DocumentPermissions/${organizationId}`;

    try {
      return await this.http.get<Permission[]>(url).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }
}
