<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light header-background border-bottom">
    <div class="container-fluid">
      <a class="mr-5 py-0" [routerLink]="['/']">
        <img src="assets/images/sakerhetspartner_logo.svg" class="menu-logo" alt="Säkerhetspartner" title="Till startsidan" />
      </a>
      <a class="navbar-brand mr-5 top-menu-item" [routerLink]="['/']"><i class="fas fa-home mr-1"></i>Hem</a>
      <a
        *ngIf="
          currentUserAuthDetails?.role === UserRole.customerAdmin || currentUserAuthDetails?.role === UserRole.superAdmin
        "
        class="navbar-brand mr-5 top-menu-item"
        (click)="onOpenManagementOrganization()"
        ><i class="fas fa-user-friends mr-1"></i>Användare</a
      >

      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse">
        <!-- <app-login-menu></app-login-menu> -->
      </div>

      <div class="justify-content-end navbar-nav align-items-center">
        <app-current-user-name class="username-display"></app-current-user-name>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onOpenUserProfile()">
            <mat-icon>account_circle</mat-icon>
            <span>Användarprofil</span>
          </button>
          <button mat-menu-item (click)="onSignOut()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logga ut</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </nav>
</header>
