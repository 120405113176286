import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { coreConfig } from 'src/app/core';
import { Unsubscribe } from 'src/app/core/decorators';
import { required, validate } from 'src/app/shared';
import { MatPaginator } from '@angular/material/paginator';

@Unsubscribe()
@Component({
  selector: 'app-organizations-table-header',
  templateUrl: './organizations-table-header.component.html',
  styleUrls: ['./organizations-table-header.component.scss'],
})
export class OrganizationsTableHeaderComponent implements OnInit, OnDestroy {
  @Input()
  itemsCount: number;

  @Input()
  currentPage: number;

  @Input()
  pageSize: number;

  @Input()
  hasSuperAdminPermissions: boolean;

  @Input()
  isRefreshing: boolean;

  @Output()
  readonly pageChanged: EventEmitter<number>;

  @Output()
  readonly filterChanged: EventEmitter<string>;

  @Output()
  readonly showInactivateOrganizationsChanged: EventEmitter<boolean>;

  @Output()
  readonly pageSizeChanged: EventEmitter<number>;

  @Output()
  readonly createOrganization: EventEmitter<void>;

  @Output()
  readonly createInternalUser: EventEmitter<void>;

  @Output()
  readonly refresh: EventEmitter<void>;

  @ViewChild('paginator') paginator: MatPaginator;

  state: {
    filter$: Subject<string>;
  };

  get pageSizeOptions(): number[] {
    return coreConfig.pagination.pageSizeOptions;
  }

  get paginatorMaxSize(): number {
    return coreConfig.pagination.paginatorMaxSize;
  }

  private filterSubscription: Subscription;

  constructor() {
    this.state = {
      filter$: new Subject(),
    };

    this.pageChanged = new EventEmitter();
    this.filterChanged = new EventEmitter();
    this.showInactivateOrganizationsChanged = new EventEmitter<boolean>();
    this.pageSizeChanged = new EventEmitter();
    this.createOrganization = new EventEmitter();
    this.refresh = new EventEmitter();
    this.createInternalUser = new EventEmitter();
  }

  ngOnInit(): void {
    this.filterSubscription = this.state.filter$
      .pipe(debounceTime(coreConfig.filter.textFilterDebounceTime), distinctUntilChanged())
      .subscribe((filter: string) => {
        this.filterChanged.emit(filter);
        this.resetPaginatorPageIndex();
      });
  }

  ngOnDestroy(): void {}

  onFilterChanged(filter: string) {
    this.state.filter$.next(filter);
  }

  onChangeShowInactivateOrganizations(checked: boolean) {
    this.showInactivateOrganizationsChanged.emit(checked);
  }

  @validate
  onPageChanged(@required page: number) {
    this.pageChanged.emit(page);
  }

  @validate
  onPageSizeChanged(@required pageSize: number) {
    this.pageSizeChanged.emit(pageSize);
  }

  onCreateOrganization() {
    this.createOrganization.emit();
  }

  onRefresh() {
    this.refresh.next();
  }

  onCreateNewInternalUser() {
    this.createInternalUser.next();
  }

  private resetPaginatorPageIndex(): void {
    // New result will be returned, so reset paginator page index
    this.paginator.firstPage();
  }
}
