<tr class="clickable-table-row organizations-table-row" [ngClass]="{'table-danger': isInactive}">
  <td class="alt-table-title-column">
    <div class="data-table-title-column-container" [routerLink]="getOrganizationRouterLink(item)">
      <a [routerLink]="getOrganizationRouterLink(item)" [title]="item.name" class="text-reset"
        ><i class="fas fa-building mr-1"></i>{{ item.name }}</a
      >
    </div>
  </td>
  <td [routerLink]="getOrganizationRouterLink(item)">
    <span class="badge badge-secondary text-medium" [ngbTooltip]="usersCountTooltipContent">
      {{ item?.usersCount }}
    </span>

    <ng-template #usersCountTooltipContent>
      <span class="mr-1"> {{ item?.usersCount }} Användare </span>
    </ng-template>
  </td>

  <td [routerLink]="getOrganizationRouterLink(item)">
    <span class="badge badge-secondary text-medium" [ngbTooltip]="docsCountTooltipContent">
      {{ item?.docsCount }}
    </span>

    <ng-template #docsCountTooltipContent>
      <span class="mr-1"> {{ item?.docsCount }} Dokument </span>
    </ng-template>
  </td>

  <td [routerLink]="getOrganizationRouterLink(item)">
    <span> {{ item?.updatedDate | date:'dd/MM-yyyy HH:mm' }} </span>
  </td>

  <td *ngIf="hasSuperAdminPermissions" class="text-right">
    <div *ngIf="isInactive" class="d-flex">
      <button
        mat-button
        matSuffix
        mat-icon-button
        class="mr-1"
        [class.disabled-element]="isDisabled"
        (click)="onActivateOrganization(item)"
        title="Återaktivera"
      >
        <mat-icon>autorenew</mat-icon>
      </button>
    </div>
    <div *ngIf="isActive" class="d-flex">
      <button
        mat-button
        matSuffix
        mat-icon-button
        class="mr-1 text-danger"
        [class.disabled-element]="isDisabled"
        (click)="onDeactivateOrganization(item)"
        title="Inaktivera"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </td>
</tr>
