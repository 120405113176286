import { OnDestroy, Type } from '@angular/core';
import { ComponentFactoryResolver } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ComponentRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdHostDirective } from 'src/app/core/ad-host.directive';
import { Unsubscribe } from 'src/app/core/decorators';
import { DocumentsStoreService } from 'src/app/documents/documents-store';
import { Document, DocumentFieldNote, DocumentSectionName, DocumentSectionType } from 'src/app/documents/shared';
import { required, validate } from 'src/app/shared';
import { projectConfig } from '../project.config';
import { v4 as uuid } from 'uuid';
import { DocumentsApiService } from 'src/app/documents/documents-api.service';
import { AuthenticationStoreService } from 'src/app/authentication/authentication-store';
import { DocumentNotesStoreService } from 'src/app/documents/documents-notes-store';
import { take } from 'rxjs/operators';
import { DocumentsService } from 'src/app/documents/documents.service';
import { ErrorModalDialogData } from '../../core/shared/error-modal-dialog-data';
import { ErrorModalComponent } from '../../core/error-modal/error-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export class DocumentTabContentComponent {
  hasEditPermissions: boolean;
  sectionType: DocumentSectionType;
  document: Document;
  notes: StringMap<DocumentFieldNote>;

  protected readonly dialog: MatDialog;
  protected readonly snackBar: MatSnackBar;

  get DocumentSectionType(): typeof DocumentSectionType {
    return DocumentSectionType;
  }

  constructor(
    sectionType: DocumentSectionType,
    dialog: MatDialog,
    snackBar: MatSnackBar,
  ) {
    this.sectionType = sectionType;
    this.dialog = dialog;
    this.snackBar = snackBar;
  }

  setDocument(document: Document): void {
    this.document = document;
  }

  setNotes(notes: StringMap<DocumentFieldNote>): void {
    this.notes = notes;
  }

  onPublishDocument(isPublished: boolean) {
    this.document.isPublished = isPublished;
    const text = isPublished ? 'publicerad' : 'avpublicerad';
    this.snackBar.open(`Dokumentet ${text}`, 'OK');
  }

  @validate
  protected showErrorModal(@required message: string, error: any) {
    const errorModalDialogData = new ErrorModalDialogData({ title: message });
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: errorModalDialogData,
    });
  }

  @validate
  protected showSectionCompletedSnackBar(@required section: DocumentSectionType, @required isCompleted: boolean) {
    const text = isCompleted ? 'klarmarkerad.' : 'är inte klarmarkerad.';
    this.snackBar.open(`${DocumentSectionName[section]} ${text}`, 'OK');
  }
}

@Unsubscribe()
@Component({
  selector: 'app-project-tab-container',
  templateUrl: './project-tab-container.component.html',
  styleUrls: ['./project-tab-container.component.scss'],
})
export class ProjectTabContainerComponent implements OnInit, OnDestroy {
  @ViewChild(AdHostDirective, { static: true })
  adHost: AdHostDirective;

  private documentsSubscription: Subscription;
  private documentNotesSubscription: Subscription;
  private routeDataSubscription: Subscription;
  private hasEditPermissionSubscription: Subscription;

  private componentRef: ComponentRef<any>;
  private hasEditPermissions: boolean;

  constructor(
    private route: ActivatedRoute,
    private documentsStoreService: DocumentsStoreService,
    private documentsApiService: DocumentsApiService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private authenticationStoreService: AuthenticationStoreService,
    private documentNotesStoreService: DocumentNotesStoreService,
    private documentsService: DocumentsService
  ) {}

  ngOnInit() {
    this.routeDataSubscription = this.route.data.subscribe((routeData: Data) => this.onRouteDataChanged(routeData));
    this.hasEditPermissionSubscription = this.authenticationStoreService
      .hasEditPermission()
      .subscribe((hasEditPermissions: boolean) => this.onHasEditPermissionsChanged(hasEditPermissions));
  }

  ngOnDestroy(): void {}

  @validate
  private async onRouteDataChanged(@required routeData: Data) {
    this.cancelDocumentSubscription();
    this.cancelDocumentNotesSubscription();

    const componentType = routeData.componentType;
    if (componentType) {
      this.loadComponent(componentType);
    }

    const documentId: uuid = routeData[projectConfig.routes.params.documentId] || null;
    if (documentId) {
      this.documentIdChanged(documentId);
    }
  }

  @validate
  private onHasEditPermissionsChanged(@required hasEditPermissions: boolean) {
    this.hasEditPermissions = hasEditPermissions;
    if (!this.componentRef) {
      return;
    }

    const component = this.componentRef.instance as DocumentTabContentComponent;
    component.hasEditPermissions = hasEditPermissions;
  }

  private async documentIdChanged(documentId: uuid): Promise<void> {
    // take from cache
    this.documentsSubscription = this.documentsStoreService
      .getDocumentById(documentId)
      .subscribe((cacheDocument: Document) => {
        if (cacheDocument)
          this.onDocumentChanged(cacheDocument);
      });

    // this.documentNotesSubscription = this.documentNotesStoreService
    //   .getNotesByDocumentId(documentId)
    //   .subscribe((cacheNotes: StringMap<DocumentFieldNote>) => this.onDocumentNotesChanged(cacheNotes));

    // + refresh from backend
    try {
      const document = await this.documentsApiService.getDocumentById(documentId);
      const cacheDocument = await this.documentsStoreService.getDocumentById(documentId).pipe(take(1)).toPromise();
      const temporaryDocument = this.documentsService.mergeServerAndCachedDocuments(document, cacheDocument);
      this.documentsStoreService.addDocument(temporaryDocument);

      if (document.deletedDate) {
        this.onHasEditPermissionsChanged(false);
      }
    } catch {
      // TODO: show an error on UI
    }

    // + refresh from backend
    // try {
    //   const documentNotes = await this.documentsApiService.getDocumentFieldNotes(documentId);
    //   this.documentNotesStoreService.addDocumentNotes(documentId, documentNotes);
    // } catch {
    //   // TODO: show an error on UI
    // }
  }

  @validate
  private loadComponent(@required componentType: Type<DocumentTabContentComponent>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent<DocumentTabContentComponent>(componentFactory);
    const component = this.componentRef.instance as DocumentTabContentComponent;
    component.hasEditPermissions = this.hasEditPermissions;
  }

  private onDocumentChanged(document: Document) {
    if (!this.componentRef) {
      return;
    }

    const component = this.componentRef.instance as DocumentTabContentComponent;
    component.setDocument(document);
  }

  private onDocumentNotesChanged(notes: StringMap<DocumentFieldNote>) {
    if (!this.componentRef) {
      return;
    }

    const component = this.componentRef.instance as DocumentTabContentComponent;
    component.setNotes(notes);
  }

  private cancelDocumentSubscription() {
    if (!this.documentsSubscription) {
      return;
    }

    try {
      this.documentsSubscription.unsubscribe();
    } catch {
      // ignore
    } finally {
      this.documentsSubscription = null;
    }
  }

  private cancelDocumentNotesSubscription() {
    if (!this.documentNotesSubscription) {
      return;
    }

    try {
      this.documentNotesSubscription.unsubscribe();
    } catch {
      // ignore
    } finally {
      this.documentNotesSubscription = null;
    }
  }
}
