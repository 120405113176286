import { Directive, OnChanges, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { Validator, ValidatorFn, Validators, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { validate, required } from '../../shared';
import { isNumber } from 'lodash';

@Directive({
  selector: 'input[appMin]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true }],
})
export class MinValidatorDirective implements Validator, OnChanges {
  // tslint:disable-next-line:no-input-rename
  @Input('appMin')
  min: number;

  private validator: ValidatorFn;

  public ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<MinValidatorDirective, SimpleChange>;
    const minChange = componentChanges.min;

    if (minChange) {
      this.validator = Validators.min(minChange.currentValue);
    }
  }

  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    if (!isNumber(this.min) || !this.validator) {
      return null;
    }

    const validationError = this.validator(control);

    return validationError;
  }
}
