import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
  OnChanges,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { coreConfig } from 'src/app/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { validate, required } from 'src/app/shared';
import { Unsubscribe } from 'src/app/core/decorators';
import { UserDetails } from '../shared';
import { AuthDetails } from 'src/app/authentication/shared';
import { UserRole } from 'src/app/authentication/shared/user-role';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Data } from '@angular/router';
import { UsersType } from 'src/app/authentication/shared/users-type';

@Unsubscribe()
@Component({
  selector: 'app-users-table-header',
  templateUrl: './users-table-header.component.html',
  styleUrls: ['./users-table-header.component.scss'],
})
export class UsersTableHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  itemsCount: number;

  @Input()
  page: number;

  @Input()
  pageSize: number;

  @Input()
  isRefreshing: boolean;

  @Input()
  organizationId: string;

  @Input()
  currentUserAuthDetails: AuthDetails;

  @Input()
  usersType: UsersType;

  @Output()
  readonly pageChanged: EventEmitter<number>;

  @Output()
  readonly filterChanged: EventEmitter<string>;

  @Output()
  readonly managePermissions: EventEmitter<void>;

  @Output()
  readonly pageSizeChanged: EventEmitter<number>;

  @Output()
  readonly userCreated: EventEmitter<UserDetails>;

  @Output()
  readonly refresh: EventEmitter<void>;

  state: {
    filter$: Subject<string>;
  };

  pageTitle: string;

  get pageSizeOptions(): number[] {
    return coreConfig.pagination.pageSizeOptions;
  }

  get paginatorMaxSize(): number {
    return coreConfig.pagination.paginatorMaxSize;
  }

  get UserRole() {
    return UserRole;
  }

  private filterSubscription: Subscription;
  private routeDataSubscription: Subscription;

  constructor(private route: ActivatedRoute) {
    this.state = {
      filter$: new Subject(),
    };
    this.pageTitle = 'Användare';

    this.pageChanged = new EventEmitter();
    this.filterChanged = new EventEmitter();
    this.pageSizeChanged = new EventEmitter();
    this.userCreated = new EventEmitter();
    this.refresh = new EventEmitter();
    this.managePermissions = new EventEmitter();
  }

  ngOnInit(): void {
    this.filterSubscription = this.state.filter$
      .pipe(debounceTime(coreConfig.filter.textFilterDebounceTime), distinctUntilChanged())
      .subscribe((filter: string) => this.filterChanged.emit(filter));
    this.routeDataSubscription = this.route.data.subscribe((routeData: Data) => this.onRouteDataChanged(routeData));
  }

  ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<UsersTableHeaderComponent, SimpleChange>;
    const pageChange = componentChanges.page;

    if (pageChange) {
      this.page = pageChange.currentValue - 1;
    }
  }

  ngOnDestroy(): void {}

  onFilterChanged(filter: string) {
    this.state.filter$.next(filter);
  }

  @validate
  onPageChanged(@required page: PageEvent) {
    this.pageChanged.emit(page.pageIndex + 1);
  }

  @validate
  onPageSizeChanged(@required pageSize: number) {
    this.pageSizeChanged.emit(pageSize);
  }

  @validate
  onUserCreated(@required userDetails: UserDetails) {
    this.userCreated.emit(userDetails);
  }

  onRefresh() {
    this.refresh.next();
  }

  onManagePermissions() {
    this.managePermissions.next();
  }

  @validate
  private async onRouteDataChanged(@required routeData: Data) {
    const pageTitle = routeData.pageTitle;
    if (!pageTitle) {
      this.pageTitle = 'Användare';
      return;
    }
    this.pageTitle = pageTitle;
  }
}
