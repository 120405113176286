import { Component, Output, EventEmitter } from '@angular/core';
import { AuthenticationApiService } from '../authentication-api.service';
import { LoggerService } from 'src/app/core/logger.service';
import { AuthenticationError } from '../shared';
import { Assert, validate, required } from 'src/app/shared';
import { AuthenticationStoreService } from '../authentication-store';
import { StatusCode, ErrorResponse } from 'src/app/core/api';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  private readonly authenticationApiService: AuthenticationApiService;
  private readonly authenticationStoreService: AuthenticationStoreService;
  private readonly loggerService: LoggerService;

  @Output()
  readonly loggedIn: EventEmitter<void>;

  @Output()
  readonly logInFailed: EventEmitter<void>;

  credentials: {
    username: string;
    password: string;
  };

  state: {
    isLoading: boolean;
    authError?: AuthenticationError;
  };

  get AuthenticationError() {
    return AuthenticationError;
  }

  constructor(
    authenticationApiService: AuthenticationApiService,
    authenticationStoreService: AuthenticationStoreService,
    loggerService: LoggerService
  ) {
    this.authenticationApiService = authenticationApiService;
    this.authenticationStoreService = authenticationStoreService;
    this.loggerService = loggerService;

    this.loggedIn = new EventEmitter();
    this.logInFailed = new EventEmitter();

    this.credentials = {
      username: '',
      password: '',
    };

    this.state = {
      isLoading: false,
      authError: null,
    };
  }

  async onLogin() {
    Assert.isNotNull(this.credentials, 'credentials');

    this.state = {
      isLoading: true,
      authError: null,
    };

    try {
      const authDetails = await this.authenticationApiService.login(
        this.credentials.username,
        this.credentials.password
      );
      this.authenticationStoreService.authenticate(authDetails);

      this.onLoginSuccess();
    } catch (error) {
      this.onLoginFailed(error);
    }
  }

  @validate
  hasAuthenticationErrorType(@required error: AuthenticationError): boolean {
    const hasError = this.state.authError === error;

    return hasError;
  }

  private onLoginSuccess() {
    this.loggedIn.emit();
  }

  private onLoginFailed(error?: ErrorResponse) {
    const authError =
      error && error.status === StatusCode.Unauthorized
        ? AuthenticationError.invalidCredentials
        : AuthenticationError.unexpectedError;

    this.state = {
      isLoading: false,
      authError: authError,
    };

    this.logInFailed.next();
  }
}
