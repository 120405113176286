import { DocumentUser } from './document-user';

export class DocumentOrganization {
  readonly organizationName: string;
  readonly documentUsers: DocumentUser[];

  constructor(organizationName: string, documentUsers: DocumentUser[]) {
    this.organizationName = organizationName;
    this.documentUsers = documentUsers;
  }
}
