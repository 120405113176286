import { Directive, ElementRef, HostListener } from '@angular/core';
import { includes } from 'lodash';
import { coreConfig } from '../core.config';

@Directive({
  selector: '[appFloatDigitOnly]',
})
export class FloatDigitOnlyDirective {
  private allowedDelimiters = [',', '.'];
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  inputElement: HTMLElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || (!includes(this.allowedDelimiters, e.key) && isNaN(Number(e.key)))) {
      e.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  deleteInputStream(data: { target: { value: string } }) {
    data.target.value = data.target.value.replace(/[.,]/g, ',');
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData('text/plain');
    const clearNumberValue = pastedInput.replace(/[^0-9.,]/g, '');
    const valueWithProperDelimiter = clearNumberValue.replace(/[.,]/g, coreConfig.forms.decimalDelimiter);
    const normalizedNumberValue = this.removeAllButLast(valueWithProperDelimiter, coreConfig.forms.decimalDelimiter);

    document.execCommand('insertText', false, normalizedNumberValue);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer.getData('text');
    this.inputElement.focus();

    const clearNumberValue = textData.replace(/[^0-9.,]/g, '');
    const valueWithProperDelimiter = clearNumberValue.replace(/[.,]/g, coreConfig.forms.decimalDelimiter);
    const normalizedNumberValue = this.removeAllButLast(valueWithProperDelimiter, coreConfig.forms.decimalDelimiter);

    document.execCommand('insertText', false, normalizedNumberValue);
  }

  private removeAllButLast(value, delimiter): string {
    const parts = value.split(delimiter);
    if (parts.length < 2) {
      return value;
    }

    return parts.slice(0, -1).join('') + delimiter + parts.slice(-1);
  }
}
