import { DocumentFieldType } from './document-field-type';
import { DocumentSelectOptionType } from './document-select-option-type';
import { DocumentSelectOptionGroupType } from './document-select-option-group-type';

export enum DocumentSectionType {
  common = 'common',
  info = 'info',
  object = 'object',
  building = 'building',
  evacuation = 'evacuation',
  fire = 'fire',
  spread = 'spread',
  capacity = 'capacity',
  installations = 'installations',
  airtreatment = 'airtreatment',
  lifts = 'lifts',
  opportunities = 'opportunities',
  risks = 'risks',
  protectedObjects = 'protectedObjects',
  inspection = 'inspection',
}

export const DocumentSectionRoute: StringMap<string> = {
  [DocumentSectionType.info]: 'info',
  [DocumentSectionType.object]: 'objekt',
  [DocumentSectionType.building]: 'byggnads-och-verksamhetsbeskrivning',
  [DocumentSectionType.evacuation]: 'utrymning',
  [DocumentSectionType.fire]: 'skydd-mot-brand',
  [DocumentSectionType.spread]: 'spridning-mellan-byggnader',
  [DocumentSectionType.capacity]: 'barformaga',
  [DocumentSectionType.installations]: 'brandtekniska-installationer',
  [DocumentSectionType.airtreatment]: 'luftbehandling',
  [DocumentSectionType.lifts]: 'hissar',
  [DocumentSectionType.opportunities]: 'insatsmojligheter',
  [DocumentSectionType.risks]: 'brand-sarskilda-risker',
  [DocumentSectionType.protectedObjects]: 'skyddsvarda-objekt',
  [DocumentSectionType.inspection]: 'systematiska-brandskyddskontroller',
}

export const DocumentSectionName: StringMap<string> = {
  [DocumentSectionType.info]: 'Projektinfo',
  [DocumentSectionType.object]: 'Objekt',
  [DocumentSectionType.building]: 'Byggnads- och verksamhetsbeskrivning',
  [DocumentSectionType.evacuation]: 'Utrymning',
  [DocumentSectionType.fire]: 'Skydd mot brand',
  [DocumentSectionType.spread]: 'Spridning mellan byggnader',
  [DocumentSectionType.capacity]: 'Bärförmåga',
  [DocumentSectionType.installations]: 'Brandtekniska installationer',
  [DocumentSectionType.airtreatment]: 'Luftbehandling',
  [DocumentSectionType.lifts]: 'Hissar',
  [DocumentSectionType.opportunities]: 'Insatsmöjligheter',
  [DocumentSectionType.risks]: 'Brand, särskilda risker',
  [DocumentSectionType.protectedObjects]: 'Skyddsvärda objekt',
  [DocumentSectionType.inspection]: 'Systematiska brandskyddskontroller',
};

export enum DocumentGeneralSectionField {
  info = 'general_info',
  object = 'general_object',
  building = 'general_building',
  evacuation = 'general_evacuation',
  fire = 'general_fire',
  spread = 'general_spread',
  capacity = 'general_capacity',
  installations = 'general_installations',
  airtreatment = 'general_airtreatment',
  lifts = 'general_lifts',
  opportunities = 'general_opportunities',
  risks = 'general_risks',
  protectedObjects = 'general_protectedObjects',
  inspection = 'general_inspection',
}

export class DocumentSection {
  readonly sectionId: string;
  readonly displayName: string;
  readonly fields: DocumentSectionField[];

  constructor({ sectionId, displayName, fields }: Partial<DocumentSection> = {}) {
    this.sectionId = sectionId;
    this.displayName = displayName;
    this.fields = fields;
  }
}

export class DocumentSectionField {
  readonly fieldId: string;
  readonly displayName: string;
  readonly type: DocumentFieldType;
  // Some values can be obtained from loaded documents.
  values: StringMap<DocumentSelectOptionGroupType | DocumentSelectOptionType>;

  constructor({ fieldId, displayName, type, values }: Partial<DocumentSectionField> = {}) {
    this.fieldId = fieldId;
    this.displayName = displayName;
    this.type = type;
    this.values = values || null;
  }
}

export class DocumentSectionFieldValue {
  readonly valueId: string;
  readonly displayName: string;

  constructor({ valueId, displayName }: Partial<DocumentSectionFieldValue> = {}) {
    this.valueId = valueId;
    this.displayName = displayName;
  }
}
