<h1 mat-dialog-title>
  <ng-container> Är du säker på att du vill ta bort {{ documentName }}?</ng-container>
</h1>
<mat-dialog-content class="mat-typography">
  <form novalidate (keydown.enter)="onDelete()">
    <div>
      <div class="form-inline">
        <label class="mr-2 text-medium-large" for="confirmationText">
          <ng-container>Skriv </ng-container>
          "{{ expectedConfirmationText }}"
          <ng-container> för att bekräfta</ng-container>
        </label>
        <mat-form-field appearance="fill" floatLabel="never" class="flex-grow-1">
          <input
            type="text"
            matInput
            [(ngModel)]="confirmationText"
            name="confirmationText"
            id="confirmationText"
            cdkFocusInitial
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-raised-button color="warn" (click)="onDelete()" [disabled]="!isConfirmed">Radera</button>
</mat-dialog-actions>
