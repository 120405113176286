import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { isNull } from 'lodash';
import { DataSource, DataSourceService, DataSourceState, FieldSortOrder } from 'src/app/core/collections';
import { Organization } from '../../../organization/shared';
import { sharedConfig } from '../shared.config';

@Component({
  selector: 'app-select-organization',
  templateUrl: './select-organization.component.html',
  styleUrls: ['./select-organization.component.scss'],
})
export class SelectOrganizationComponent implements OnInit, OnChanges {
  @ViewChild(NgbDropdown, { static: true })
  readonly dropdown: NgbDropdown;

  @Input()
  organizations: Organization[];

  @Input()
  selectedOrganization: Organization;

  @Input()
  placeholder: string;

  @Input()
  isReadonly: boolean;

  @Input()
  isDisabled: boolean;

  @Output()
  readonly changed: EventEmitter<Organization>;

  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  dataSource: DataSource<Organization>;
  dataSourceState: DataSourceState<Organization>;

  constructor(private dataSourceService: DataSourceService) {
    this.organizations = [];
    this.dataSource = new DataSource<Organization>();

    this.dataSourceState = new DataSourceState<Organization>({
      filter: '',
      searchableFields: [],
      sortOrder: new FieldSortOrder(
        sharedConfig.comboboxes.organizations.sortOrder.fieldName,
        sharedConfig.comboboxes.organizations.sortOrder.sortOrder
      ),
      page: 0,
      pageSize: Number.MAX_VALUE,
    });

    this.state = {
      isLoading: false,
      isFailed: false,
    };

    this.changed = new EventEmitter();
  }

  ngOnInit() {
    if (this.isReadonly) {
      return;
    }
    if (!this.organizations) {
      this.organizations = [];
    }

    this.dataSource = new DataSource<Organization>();
    this.dataSource = this.dataSourceService.createDataSource(this.organizations, this.dataSourceState);
  }

  ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<SelectOrganizationComponent, SimpleChange>;
    const organizationsChange = componentChanges.organizations;

    if (!organizationsChange) {
      return;
    }
    const organizations = organizationsChange.currentValue as Organization[];
    if (_.isEmpty(organizations)) {
      return;
    }
    this.dataSource = this.dataSourceService.createDataSource(organizations, this.dataSourceState);
  }

  trackByItem(index: number, item: Organization): number {
    return item.id;
  }

  onFilterChanged() {
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
  }

  onItemSelected(organization: Organization) {
    this.selectedOrganization = organization;

    this.dropdown.close();
    this.changed.next(organization);
  }

  onDropdownOpenChanged(isOpen: boolean) {
    if (!isOpen) {
      return;
    }

    this.dataSourceState.filter = '';
    this.onFilterChanged();
  }
}
