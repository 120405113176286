import { Action } from '@ngrx/store';
import { Assert } from 'src/app/shared';
import { Document } from '../shared';
import { v4 as uuid } from 'uuid';

export enum DocumentsActionType {
  Add = '[Documents] Add',
  Delete = '[Documents] Delete',
  Reset = '[Documents] Reset',
}

export class AddDocumentAction implements Action {
  readonly type = DocumentsActionType.Add;

  readonly item: Document;

  constructor(item: Document) {
    Assert.isNotNull(item, 'item');
    this.item = item;
  }
}

export class DeleteDocumentAction implements Action {
  readonly type = DocumentsActionType.Delete;

  readonly itemId: uuid;

  constructor(itemId: uuid) {
    Assert.isNotNull(itemId, 'itemId');
    this.itemId = itemId;
  }
}

export class ResetDocumentsAction implements Action {
  readonly type = DocumentsActionType.Reset;
}

export type DocumentsActions = AddDocumentAction | DeleteDocumentAction | ResetDocumentsAction;
