import { validate, required } from '../required';
import { Assert } from './assert';
import { CancelablePromise } from '../cancelable-promise';

export class PromiseUtils {
  @validate
  static delay(@required timeout: number): Promise<void> {
    Assert.isTrue(timeout >= 0, 'timeout', '"{0}" must be a positive number.');

    const promise = new Promise<void>((resolve: Function) => setTimeout(() => resolve(), timeout));

    return promise;
  }

  @validate
  static makeCancelable<T>(@required promise: Promise<T>): CancelablePromise<T> {
    let isCanceled = false;
    let isRejectPromiseOnCancel: boolean;

    const wrappedPromise: Promise<T> = new Promise((resolve: Function, reject: Function) => {
      promise.then(
        (result: T) => {
          if (isCanceled) {
            if (isRejectPromiseOnCancel) {
              reject({ isCanceled: true });
            }
          } else {
            resolve(result);
          }
        },
        (error: Error) => {
          if (isCanceled) {
            if (isRejectPromiseOnCancel) {
              reject({ isCanceled: true });
            }
          } else {
            reject(error);
          }
        }
      );
    });

    const cancelablePromise: CancelablePromise<T> = {
      promise: wrappedPromise,
      cancel: (isRejectOnCancel: boolean = true) => {
        isCanceled = true;
        isRejectPromiseOnCancel = isRejectOnCancel;
      },
    };

    return cancelablePromise;
  }

  @validate
  static final<T, TResult = T>(
    @required promise: Promise<T>,
    @required callback: (result: T) => TResult
  ): Promise<TResult> {
    return promise.then(callback, callback);
  }
}
