import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectNavigationComponent } from './project-navigation/project-navigation.component';
import { ProjectTabInfoComponent } from './project-tab-info/project-tab-info.component';
import { FormsModule } from '@angular/forms';
import { ProjectLayoutComponent } from './project-layout/project-layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProjectNoteModalComponent } from './project-note-modal/project-note-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LayoutModule } from '../layout/layout.module';
import { ProjectTabObjectComponent } from './project-tab-object/project-tab-object.component';
import { CoreModule } from '../core/core.module';
import { ProjectTabContainerComponent } from './project-tab-container/project-tab-container.component';
import { ProjectTabHeaderComponent } from './project-tab-header/project-tab-header.component';
import { ProjectTabBuildingDescriptionComponent } from './project-tab-building-description/project-tab-building-description.component';
import { ProjectTabEvacuationComponent } from './project-tab-evacuation/project-tab-evacuation.component';
import { ProjectTabFireProtectionComponent } from './project-tab-fire-protection/project-tab-fire-protection.component';
import { ProjectTabBuildingSpreadComponent } from './project-tab-building-spread/project-tab-building-spread.component';
import { ProjectTabCarryingCapacityComponent } from './project-tab-carrying-capacity/project-tab-carrying-capacity.component';
import { ProjectTabFireInstallationsComponent } from './project-tab-fire-installations/project-tab-fire-installations.component';
import { ProjectTabAirTreatmentComponent } from './project-tab-air-treatment/project-tab-air-treatment.component';
import { ProjectTabLiftsComponent } from './project-tab-lifts/project-tab-lifts.component';
import { ProjectTabActionOpportunitiesComponent } from './project-tab-action-opportunities/project-tab-action-opportunities.component';
import { ProjectTabSpecialRisksComponent } from './project-tab-special-risks/project-tab-special-risks.component';
import { ProjectTabProtectedObjectsComponent } from './project-tab-protected-objects/project-tab-protected-objects.component';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MultiselectReadonlyViewComponent } from './multiselect-readonly-view/multiselect-readonly-view.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SectionFieldNoteDirective } from './section-field-note.directive';
import { DocumentNotApplicableSelectOptionsDirective } from './document-not-applicable-select-options.directive';
import { ProjectTabInspectionComponent } from './project-tab-inspection/project-tab-inspection.component';
import { DocumentRadioButtonComponent } from './document-radio-button/document-radio-button.component';

@NgModule({
  declarations: [
    ProjectNavigationComponent,
    ProjectTabInfoComponent,
    ProjectLayoutComponent,
    ProjectNoteModalComponent,
    ProjectTabObjectComponent,
    ProjectTabContainerComponent,
    ProjectTabHeaderComponent,
    ProjectTabBuildingDescriptionComponent,
    ProjectTabEvacuationComponent,
    ProjectTabFireProtectionComponent,
    ProjectTabBuildingSpreadComponent,
    ProjectTabCarryingCapacityComponent,
    ProjectTabFireInstallationsComponent,
    ProjectTabAirTreatmentComponent,
    ProjectTabLiftsComponent,
    ProjectTabActionOpportunitiesComponent,
    ProjectTabSpecialRisksComponent,
    ProjectTabProtectedObjectsComponent,
    MultiselectReadonlyViewComponent,
    SectionFieldNoteDirective,
    DocumentNotApplicableSelectOptionsDirective,
    ProjectTabInspectionComponent,
    DocumentRadioButtonComponent,
  ],
  imports: [
      BrowserModule,
      CoreModule,
      CommonModule,
      FormsModule,
      MatFormFieldModule,
      MatSelectModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatInputModule,
      MatRadioModule,
      MatButtonModule,
      MatDialogModule,
      MatIconModule,
      NgbTooltipModule,
      RouterModule,
      LayoutModule,
      MomentDateModule,
  ],
  exports: [
    ProjectTabInfoComponent,
    ProjectTabObjectComponent,
    ProjectNoteModalComponent,
    ProjectTabContainerComponent,
    ProjectTabBuildingDescriptionComponent,
    ProjectTabEvacuationComponent,
    ProjectTabFireProtectionComponent,
    ProjectTabBuildingSpreadComponent,
    ProjectTabCarryingCapacityComponent,
    ProjectTabFireInstallationsComponent,
    ProjectTabAirTreatmentComponent,
    ProjectTabLiftsComponent,
    ProjectTabActionOpportunitiesComponent,
    ProjectTabSpecialRisksComponent,
    ProjectTabProtectedObjectsComponent,
    ProjectTabInspectionComponent,
    SectionFieldNoteDirective,
    DocumentNotApplicableSelectOptionsDirective,
    DocumentRadioButtonComponent,
  ]
})
export class ProjectModule {}
