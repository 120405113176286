import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentTabContentComponent } from '../project-tab-container/project-tab-container.component';
import {
  Document,
  DocumentRisks,
  DocumentRisksSpecialRiskOptionTypes,
  DocumentSectionType
} from 'src/app/documents/shared';
import { projectConfig } from '../project.config';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { DocumentsService } from 'src/app/documents/documents.service';
import { DocumentsValidationService } from 'src/app/documents/documents-validation.service';
import { DocumentSelectOptionType } from 'src/app/documents/shared/document-structure/document-select-option-type';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentUpdateValuesRuleService } from '../document-update-values-rule.service';
import { DocumentSectionCompletionUpdateService } from '../document-section-completion-update.service';

@Component({
  selector: 'app-project-tab-special-risks',
  templateUrl: './project-tab-special-risks.component.html',
  styleUrls: ['./project-tab-special-risks.component.scss'],
})
export class ProjectTabSpecialRisksComponent extends DocumentTabContentComponent implements OnInit {
  @ViewChild('tabForm')
  tabForm: NgForm;

  risksHeatingOptionTypes: StringMap<DocumentSelectOptionType>;
  specialRiskOptionTypes: StringMap<DocumentSelectOptionType>;

  get previousPageName(): string {
    return projectConfig.routes.opportunities;
  }

  get nextPageName(): string {
    return projectConfig.routes.protectedObjects;
  }

  get isHasTimerOrStoveGuardEnabled(): boolean {
    return this.document.risks.specialRiskTypeIds.some(id => id === DocumentRisksSpecialRiskOptionTypes.cookingDeviceId);
  }

  constructor(
    dialog: MatDialog,
    snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private documentsValidationService: DocumentsValidationService,
    private documentsSectionDataService: DocumentsSectionDataService,
    private readonly documentUpdateValuesRuleService: DocumentUpdateValuesRuleService,
    private documentSectionCompletionUpdateService: DocumentSectionCompletionUpdateService,
  ) {
    super(DocumentSectionType.risks, dialog, snackBar);
  }

  ngOnInit(): void {
    this.risksHeatingOptionTypes = this.documentsSectionDataService.getRisksHeatingOptionTypes();
    this.specialRiskOptionTypes = this.documentsSectionDataService.getSpecialRiskOptionTypes();
  }

  async onCompleteForm(isCompleted: boolean): Promise<void> {
    this.document.risks.isCompleted = isCompleted && this.document.risks.isValid;
    await this.saveDocument(true);
  }

  onFormDataChanged(): void {
    this.saveDocument(false);
  }

  private async saveDocument(formCompletedExplicitly: boolean): Promise<void> {
    if (!this.hasEditPermissions) {
      return;
    }

    const isCompletedBeforeChange = this.document.risks.isCompleted;
    this.documentUpdateValuesRuleService.processRisksRules(this.document);
    this.document.risks.isValid = this.documentsValidationService.isRisksValid(this.document.risks, this.document);
    this.document.risks.isCompleted = this.document.risks.isCompleted && this.document.risks.isValid;
    const partialDocument = {
      risks: new DocumentRisks({ ...this.document.risks }),
    } as Document;

    this.documentSectionCompletionUpdateService.checkAndUpdate(this.document, DocumentSectionType.risks);
    try {
      await this.documentsService.patchDocumentAndSave(this.document, partialDocument);
    } catch (ex) {
      this.showErrorModal('Ett fel uppstod när ändringarna skulle sparas.', ex);
      return;
    }

    if (formCompletedExplicitly || (isCompletedBeforeChange && !this.document.risks.isCompleted)) {
      this.showSectionCompletedSnackBar(DocumentSectionType.risks, this.document.risks.isCompleted);
    }
  }
}
