import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { UserDetails } from './shared/user-details';
import { EditUserModalComponent } from './edit-user-modal/edit-user-modal.component';
import { validate, required } from 'src/app/shared';
import { Router } from '@angular/router';
import { AuthDetails } from '../authentication/shared';
import { MatDialog } from '@angular/material/dialog';
import { EditUserDialogData } from './shared/edit-user-dialog-data';
import { UsersType } from '../authentication/shared/users-type';
import { organizationConfig } from '../organization/organization.config';

@Directive({
  selector: '[appEditUserTrigger]',
})
export class EditUserTriggerDirective {
  private readonly dialog: MatDialog;
  private readonly router: Router;

  @Input()
  organizationId: string;

  @Input()
  redirectToOrganization: boolean;

  @Input()
  currentUserAuthDetails: AuthDetails;

  @Input()
  isEditMode: boolean;

  @Input()
  editedUserDetails: UserDetails;

  @Input()
  usersType: UsersType;

  @Output()
  readonly created: EventEmitter<UserDetails>;

  @Output()
  readonly edited: EventEmitter<UserDetails>;

  constructor(dialog: MatDialog, router: Router) {
    this.dialog = dialog;
    this.router = router;

    this.created = new EventEmitter();
    this.edited = new EventEmitter();
  }

  @HostListener('click')
  onClick() {
    this.createUser(this.organizationId);
  }

  private createUser(organizationId: string) {
    const editUsedDialogData = new EditUserDialogData({
      currentUserAuthDetails: this.currentUserAuthDetails,
      editedUserDetails: this.editedUserDetails,
      isEditMode: this.isEditMode,
      organizationId: this.isEditMode ? this.editedUserDetails.user.organizationId : organizationId,
      usersType: this.usersType,
    });
    const dialogRef = this.dialog.open(EditUserModalComponent, {
      data: editUsedDialogData,
    });
    dialogRef.afterClosed().subscribe((userDetails: UserDetails) => {
      if (userDetails) {
        this.onUserCreated(userDetails);
      }
    });
  }

  @validate
  private onUserCreated(@required userDetails: UserDetails) {
    if (!this.isEditMode) {
      this.created.next(userDetails);
    } else {
      this.edited.next(userDetails);
    }

    if (this.redirectToOrganization) {
      this.router.navigate(['/', organizationConfig.routes.organizations, userDetails.user.organizationId]);
    }
  }
}
