import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { required, validate } from '../shared';
import { ResetUserPasswordModalComponent } from './reset-user-password-modal/reset-user-password-modal.component';
import { User } from './shared';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
  selector: '[appResetPasswordTrigger]',
})
export class ResetPasswordTriggerDirective {

  @Input()
  user: User;

  @Output()
  readonly passwordReset: EventEmitter<void>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
  ) {
    this.passwordReset = new EventEmitter();
  }

  @HostListener('click')
  onClick() {
    if (this.user) {
      this.resetPassword(this.user);
    }
  }

  @validate
  resetPassword(@required user: User) {
    const dialogRef = this.dialog.open(ResetUserPasswordModalComponent, {
      data: user,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.onPasswordReset();
      }
    });
  }

  @validate
  private onPasswordReset() {
    this.snackBar.open('Lösenord återställt.', 'OK');
    this.passwordReset.next();
  }
}
