import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { coreConfig } from 'src/app/core';
import { required, validate } from 'src/app/shared';
import { DocumentSectionFilter } from '../../documents/shared';
import { v4 as uuid } from 'uuid';
import { omit, values } from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
import { DocumentOrganization } from '../../users-management/shared/document-organization';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent implements OnInit {

  @ViewChild('paginator') paginator: MatPaginator;

  @Input()
  documentCreators: DocumentOrganization[];

  @Input()
  documentRevisers: DocumentOrganization[];

  @Input()
  itemsCount: number;

  @Input()
  page: number;

  @Input()
  pageSize: number;

  @Input()
  hasEditPermissions: boolean;

  @Output()
  readonly createNewDocument: EventEmitter<void>;

  @Output()
  readonly pageChanged: EventEmitter<number>;

  @Output()
  readonly pageSizeChanged: EventEmitter<number>;

  @Output()
  readonly filterChanged: EventEmitter<string>;

  @Output()
  readonly sectionFilterChanged: EventEmitter<DocumentSectionFilter[]>;

  @Output()
  readonly showDeletedDocumentsChanged: EventEmitter<boolean>;

  state: {
    filter$: Subject<string>;
    sectionCounterArray: number[];
    sectionfilters: StringMap<DocumentSectionFilter>;
  };

  get pageSizeOptions(): number[] {
    return coreConfig.pagination.pageSizeOptions;
  }

  get paginatorMaxSize(): number {
    return coreConfig.pagination.paginatorMaxSize;
  }

  constructor() {
    this.state = {
      filter$: new Subject(),
      sectionCounterArray: [],
      sectionfilters: {},
    };

    this.createNewDocument = new EventEmitter<void>();
    this.filterChanged = new EventEmitter();
    this.pageChanged = new EventEmitter();
    this.pageSizeChanged = new EventEmitter();
    this.sectionFilterChanged = new EventEmitter();
    this.showDeletedDocumentsChanged = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    this.state.filter$
      .pipe(debounceTime(coreConfig.filter.textFilterDebounceTime), distinctUntilChanged())
      .subscribe((filter: string) => this.filterChanged.emit(filter));
  }

  onCreateNewDocument() {
    this.createNewDocument.emit();
  }

  onFilterChanged(filter: string) {
    this.state.filter$.next(filter);
  }

  onSectionFilterChanged(filter: DocumentSectionFilter) {
    this.state.sectionfilters[filter.id] = filter;

    const filters = values(this.state.sectionfilters);
    this.sectionFilterChanged.next(filters);
  }

  onSectionFilterDeleted(filterId: string) {
    this.state.sectionfilters = omit(this.state.sectionfilters, filterId);

    const filters = values(this.state.sectionfilters);
    this.sectionFilterChanged.next(filters);
  }

  onShowDeletedDocumentsChanged(isChecked: boolean) {
    this.showDeletedDocumentsChanged.emit(isChecked);
  }

  @validate
  onPageChanged(@required page: number) {
    this.pageChanged.emit(page);
  }

  @validate
  onPageSizeChanged(@required pageSize: number) {
    this.pageSizeChanged.emit(pageSize);
  }

  onAddFilter(): void {
    const filterId = uuid.v4();

    this.state.sectionfilters = {
      ...this.state.sectionfilters,
      [filterId]: new DocumentSectionFilter({
        id: filterId,
      }),
    };
  }

  @validate
  trackByItem(index: number, @required item: { key: string; value: DocumentSectionFilter }): string {
    return item.value.id;
  }
}
