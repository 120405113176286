import {
  AddDocumentNoteAction,
  AddDocumentNotesAction,
  DeleteDocumentNoteAction,
  DocumentsNotesActions,
  DocumentsNotesActionType,
  ResetDocumentNotesAction,
} from './documents-notes.actions';
import { Assert } from 'src/app/shared';
import { cloneDeep, omit, union } from 'lodash';
import { DocumentFieldNote } from '../shared';

// First level ID - DocumentId
// Second level ID - DocumentFieldNoteId
export interface DocumentsNotesState {
  allIds: string[];
  byId: StringMap<StringMap<DocumentFieldNote>>;
}

export const documentsInitialState: DocumentsNotesState = {
  allIds: [],
  byId: {},
};

export function documentNotesReducer(state: DocumentsNotesState, action: DocumentsNotesActions): DocumentsNotesState {
  Assert.isNotNull(action, 'action');

  switch (action.type) {
    case DocumentsNotesActionType.AddNote:
      return addDocumentNoteActionReducer(state, action);
    case DocumentsNotesActionType.AddNotes:
      return addDocumentNotesActionReducer(state, action);
    case DocumentsNotesActionType.DeleteNote:
      return deleteDocumentNoteActionReducer(state, action);
    case DocumentsNotesActionType.Reset:
      return resetDocumentsActionReducer(state, action);

    default:
      return state;
  }
}

export function addDocumentNoteActionReducer(
  state: DocumentsNotesState,
  action: AddDocumentNoteAction
): DocumentsNotesState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const notes = state.byId[action.documentId] || {};

  const newState: DocumentsNotesState = {
    ...state,
    allIds: union(state.allIds, [action.documentId]),
    byId: {
      ...state.byId,
      [action.documentId]: {
        ...notes,
        [action.item.fieldId]: action.item,
      },
    },
  };

  return newState;
}

export function addDocumentNotesActionReducer(
  state: DocumentsNotesState,
  action: AddDocumentNotesAction
): DocumentsNotesState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const notes = state.byId[action.documentId] || {};

  const newState: DocumentsNotesState = {
    ...state,
    allIds: union(state.allIds, [action.documentId]),
    byId: {
      ...state.byId,
      [action.documentId]: {
        ...action.items,
      },
    },
  };

  return newState;
}

export function deleteDocumentNoteActionReducer(
  state: DocumentsNotesState,
  action: DeleteDocumentNoteAction
): DocumentsNotesState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const notes = state.byId[action.documentId] || {};
  const notesWithoutItem = omit(notes, action.fieldId);

  const newState: DocumentsNotesState = {
    ...state,
    byId: {
      ...state.byId,
      [action.documentId]: notesWithoutItem,
    },
  };

  return newState;
}

export function resetDocumentsActionReducer(
  state: DocumentsNotesState,
  action: ResetDocumentNotesAction
): DocumentsNotesState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const newState: DocumentsNotesState = cloneDeep(documentsInitialState);

  return newState;
}
