import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LoggerService } from 'src/app/core';
import { Assert, required, validate } from 'src/app/shared';
import { ErrorResponse, StatusCode } from 'src/app/core/api';
import { ErrorModalComponent } from 'src/app/core/error-modal/error-modal.component';
import { User, UserDetails, UserStats } from '../shared';
import { UserWithPass } from '../shared/user-with-pass';
import { UsersApiService } from '../users-api.service';
import { usersConfig } from '../users.config';
import { UserRole } from 'src/app/authentication/shared/user-role';
import { AuthDetails } from 'src/app/authentication/shared';
import _ from 'lodash';
import { AuthenticationStoreService } from 'src/app/authentication/authentication-store';
import { UsersType } from 'src/app/authentication/shared/users-type';
import { UserDataRole } from '../shared/user-data-role';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalDialogData } from 'src/app/core/shared/error-modal-dialog-data';
import { UsersManagementService } from '../users-management.service';
import { PermissionsApiService } from 'src/app/authentication/permissions-api.service';
import { UserPermission } from '../shared/user-permission';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-edit-user-form',
  templateUrl: './edit-user-form.component.html',
  styleUrls: ['./edit-user-form.component.scss'],
})
export class EditUserFormComponent implements OnInit {
  @ViewChild('createUserForm') createUserForm: NgForm;

  @Input()
  organizationId: string;

  @Input()
  currentUserAuthDetails: AuthDetails;

  @Input()
  isEditMode: boolean;

  @Input()
  editedUserDetails: UserDetails | null;

  @Input()
  usersType: UsersType;

  userDetails: UserDetails;
  hasSuperAdminPermissions: boolean;

  state: {
    isLoading: boolean;
    isFailed: boolean;
    uniqueEmail: {
      isUnique: boolean;
      organizationName: string;
    }
  };

  availableRoles: UserDataRole[];
  availablePermissions: UserPermission[];

  @Output()
  readonly userCreated: EventEmitter<UserWithPass>;

  @Output()
  readonly userEdited: EventEmitter<UserDetails>;

  @Output()
  readonly cancel: EventEmitter<void>;

  get nameMaxLength() {
    return usersConfig.profile.validation.name.maxLength;
  }

  get nameAllowedCharacters() {
    return usersConfig.profile.validation.name.allowedCharacters;
  }

  get emailMaxLength() {
    return usersConfig.profile.validation.email.maxLength;
  }

  get UserRole() {
    return UserRole;
  }

  get showCheckboxCanReviewDocument() {
    return this.hasSuperAdminPermissions && this.usersType === UsersType.InternalUsers;
  }

  get canSubmitForm(): boolean {
    if (!this.createUserForm)
      return;

    return !this.state.isLoading && this.createUserForm.valid && !this.createUserForm.pristine;
  }

  constructor(
    private readonly usersApiService: UsersApiService,
    private readonly loggerService: LoggerService,
    private readonly authenticationStoreService: AuthenticationStoreService,
    private readonly dialog: MatDialog,
    private readonly usersManagementService: UsersManagementService,
    private readonly permissionsApiService: PermissionsApiService
  ) {
    this.userCreated = new EventEmitter();
    this.userEdited = new EventEmitter();
    this.cancel = new EventEmitter();

    this.state = {
      isLoading: false,
      isFailed: false,
      uniqueEmail: {
        isUnique: false,
        organizationName: null,
      },
    };

    this.userDetails = new UserDetails();
    this.availableRoles = [];
    this.availablePermissions = [];
  }

  async ngOnInit(): Promise<void> {
    if (this.organizationId) {
      const permissions = await this.permissionsApiService.getPermissionGroups(this.organizationId);
      this.availablePermissions = this.usersManagementService.mapUserPermissionsResponse(permissions);
    }
    if (this.isEditMode) {
      this.userDetails = _.cloneDeep(this.editedUserDetails);
    }

    if (this.usersType === UsersType.InternalUsers) {
      this.availableRoles = [
        new UserDataRole({ value: UserRole.author, viewValue: 'Författare' }),
        new UserDataRole({ value: UserRole.superAdmin, viewValue: 'Systemadministratör' }),
      ];
    }
    if (this.usersType === UsersType.Customers) {
      this.availableRoles = [
        new UserDataRole({ value: UserRole.customerReader, viewValue: 'Läsare' }),
        new UserDataRole({ value: UserRole.customerAuthor, viewValue: 'Författare' }),
        new UserDataRole({ value: UserRole.customerAdmin, viewValue: 'Administratör' }),
      ];
    }

    if (!this.isEditMode) {
      this.userDetails.role = _.first(this.availableRoles)?.value;
    }

    this.hasSuperAdminPermissions = this.currentUserAuthDetails.role === UserRole.superAdmin;
  }

  async onEmailChanged() {
    const email = this.userDetails.user.email;

    if (!email || email.toUpperCase() === this.editedUserDetails?.user.email.toUpperCase())
      return;

    const uniqueEmail = await this.usersApiService.uniqueEmail(email);
    this.state.uniqueEmail.isUnique = uniqueEmail.isUnique;
    this.state.uniqueEmail.organizationName = uniqueEmail.organizationName;

    if (!this.state.uniqueEmail.isUnique) {
      this.createUserForm.controls['email'].setErrors({ emailExists: true });
    }
  }

  async onSaveUser() {
    if (!this.canSubmitForm)
      return;

    if (!this.isEditMode) {
      await this.onCreateUser();
      return;
    }
    this.onEditUser();
  }

  async onCreateUser() {
    this.state.isLoading = true;
    this.state.uniqueEmail.isUnique = true;

    try {
      const userDetails = this.collectUserDetails();
      const password = this.usersManagementService.getGeneratedPassword();

      const id = await this.usersApiService.createUser(userDetails, password);
      const newUserDetails = this.cloneUserDetails(id, userDetails);

      const userWithPass = new UserWithPass({ userDetails: newUserDetails, password: password });

      this.userCreated.emit(userWithPass);
    } catch (error) {
      this.onCreateUserFailed(error);
    } finally {
      this.state.isLoading = false;
    }
  }

  async onEditUser() {
    this.state.isLoading = true;
    this.state.uniqueEmail.isUnique = true;

    try {
      const userDetails = this.collectUserDetails();

      const id = await this.usersApiService.editUser(userDetails);
      const newUserDetails = this.cloneUserDetails(id, userDetails);

      if (newUserDetails.user.id === this.currentUserAuthDetails.userid) {
        this.authenticationStoreService.setAuthDetailsNewUserName(this.userDetails.user.email);
        this.authenticationStoreService.setAuthDetailsNewUserRole(this.userDetails.role);
      }

      this.userEdited.emit(newUserDetails);
    } catch (error) {
      this.onEditUserFailed(error);
    } finally {
      this.state.isLoading = false;
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  private collectUserDetails(): UserDetails {
    Assert.isNotNull(this.userDetails, 'user');

    const user = new User({
      ...this.userDetails.user,
    });
    if (this.userDetails.role === UserRole.superAdmin || this.userDetails.role === UserRole.author) {
      user.organizationId = null;
    } else {
      user.organizationId = this.organizationId;
    }

    let permissionsIds: string[] = [];
    if (this.userDetails.role === UserRole.customerReader) {
      permissionsIds = this.userDetails.permissionsIds;
    }

    const userDetails = new UserDetails({
      user: user,
      role: this.userDetails.role,
      stats: new UserStats(),
      permissionsIds: permissionsIds,
    });

    return userDetails;
  }

  @validate
  private cloneUserDetails(@required id: string, @required userDetails: UserDetails): UserDetails {
    const newUser = new User({
      ...userDetails.user,
      id: id,
    });

    const newUserDetails = new UserDetails({
      user: newUser,
      role: userDetails.role,
      stats: new UserStats({
        ...userDetails.stats,
      }),
      permissionsIds: userDetails.permissionsIds,
    });

    return newUserDetails;
  }

  private onCreateUserFailed(error: any) {
    const isErrorHandled = this.tryHandleError(error);
    if (!isErrorHandled) {
      this.loggerService.error('Failed to create user', error);
      this.openErrorModal('Det gick inte att skapa användare, försök igen senare.');
    }
  }

  private onEditUserFailed(error: any) {
    const isErrorHandled = this.tryHandleError(error);
    if (!isErrorHandled) {
      this.loggerService.error('Failed to edit user', error);
      this.openErrorModal('Det gick inte att redigera användaren');
    }
  }

  private tryHandleError(error: any): boolean {
    if (error && error instanceof ErrorResponse) {
      switch (error.status) {
        case StatusCode.Conflict:
          this.state.uniqueEmail.isUnique = false;
          return true;
        case StatusCode.Unauthorized:
          this.onCancel();
          return true;
      }
    }
    return false;
  }

  @validate
  private openErrorModal(@required title: string) {
    const errorModalDialogData = new ErrorModalDialogData({ title: title });
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: errorModalDialogData,
    });
  }
}
