import { SortOrder } from 'src/app/core/collections';
import { Organization } from 'src/app/organization/shared';

export const sharedConfig = {
  comboboxes: {
    organizations: {
      searchableFields: ['name'] as FieldsOf<Organization>,
      sortOrder: {
        fieldName: 'name' as FieldOf<Organization>,
        sortOrder: SortOrder.asc,
      },
    },
  },
};
