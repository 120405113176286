import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
  name: 'isNotNull',
})
export class IsNotNullPipe implements PipeTransform {
  transform(value: any): boolean {
    const isNilValue = isNil(value);

    return !isNilValue;
  }
}
