export class BooleanUtils {
  /**
   * Parse boolean value from string.
   * @param value - String value.
   * @returns Parsed boolean value.
   */
  public static parse(value: string): boolean {
    if (value === undefined) {
      throw new Error('Cannot convert empty string to boolean.');
    }

    switch (value.toLowerCase()) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        throw Error(`Cannot convert string "${value}" to boolean.`);
    }
  }
}
