import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationStoreService } from '../authentication-store';
import { Subscription } from 'rxjs';
import { AuthDetails } from '../shared';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-current-user-name',
  templateUrl: './app-current-user-name.component.html',
  styleUrls: ['./app-current-user-name.component.scss'],
})
export class AppCurrentUserNameComponent implements OnInit, OnDestroy {
  private readonly authenticationStoreService: AuthenticationStoreService;
  private getAuthDetailsSubscription: Subscription;

  username: string;

  constructor(authenticationStoreService: AuthenticationStoreService) {
    this.authenticationStoreService = authenticationStoreService;
  }

  async ngOnInit() {
    const authDetails: AuthDetails = await this.authenticationStoreService.getAuthDetails().pipe(take(1)).toPromise();
    this.onAuthDetailsChanged(authDetails);

    this.getAuthDetailsSubscription = this.authenticationStoreService
      .getAuthDetails()
      .subscribe((details: AuthDetails) => this.onAuthDetailsChanged(details));
  }

  ngOnDestroy() {
    this.unsubscribeFromControlValueChanges();
  }

  private onAuthDetailsChanged(authDetails: AuthDetails) {
    this.username = authDetails && authDetails.username ? authDetails.username : '';
  }

  private unsubscribeFromControlValueChanges() {
    if (this.getAuthDetailsSubscription) {
      try {
        this.getAuthDetailsSubscription.unsubscribe();
      } catch {
        // ignore
      }
      this.getAuthDetailsSubscription = null;
    }
  }
}
