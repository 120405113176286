import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthenticationStoreService } from 'src/app/authentication/authentication-store';
import { AuthDetails } from 'src/app/authentication/shared';
import { UserRole } from 'src/app/authentication/shared/user-role';
import { LoggerService } from 'src/app/core';
import { ErrorModalComponent } from 'src/app/core/error-modal/error-modal.component';
import { ErrorModalDialogData } from 'src/app/core/shared/error-modal-dialog-data';
import { required, validate } from 'src/app/shared';
import { UserDetails } from '../shared';
import { UsersApiService } from '../users-api.service';
import { usersConfig } from '../users.config';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  state: {
    isLoading: boolean;
    isFailed: boolean;
    isEmailAlreadyExists: boolean;
  };

  user: UserDetails;
  editedUser: UserDetails;
  currentUserAuthDetails: AuthDetails;

  private unsubscribeAll: Subject<any>;

  get nameMaxLength() {
    return usersConfig.profile.validation.name.maxLength;
  }

  get nameAllowedCharacters() {
    return usersConfig.profile.validation.name.allowedCharacters;
  }

  get emailMaxLength() {
    return usersConfig.profile.validation.email.maxLength;
  }

  get UserRole() {
    return UserRole;
  }

  constructor(
    private authenticationStoreService: AuthenticationStoreService,
    private usersApiService: UsersApiService,
    private loggerService: LoggerService,
    private dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
  ) {
    this.state = {
      isLoading: false,
      isFailed: false,
      isEmailAlreadyExists: false,
    };
    this.user = new UserDetails();
    this.editedUser = new UserDetails();
    this.unsubscribeAll = new Subject();
  }

  async ngOnInit(): Promise<void> {
    this.currentUserAuthDetails = await this.authenticationStoreService.getAuthDetails().pipe(take(1)).toPromise();
    this.loadUser(this.currentUserAuthDetails.username);

    this.authenticationStoreService
      .getAuthDetails()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(async (details: AuthDetails) => {
        this.currentUserAuthDetails = details;
        if (this.currentUserAuthDetails) {
          this.loadUser(this.currentUserAuthDetails.username);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  async onSaveUser(): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      await this.usersApiService.editUser(this.editedUser);
      this.user = _.cloneDeep(this.editedUser);
      this.authenticationStoreService.setAuthDetailsNewUserName(this.user.user.email);
      this.onUserChanged();
    } catch (error) {
      this.state.isFailed = true;
      this.onChangeUserFailed(error);
    } finally {
      this.state.isLoading = false;
    }
  }

  hasUserChanged(): boolean {
    const hasUserChanged = _.isEqual(this.user, this.editedUser);
    return !hasUserChanged;
  }

  private onChangeUserFailed(error: any) {
    this.loggerService.error('Failed to update user', error);
    this.openErrorModal('Det gick inte att uppdatera användaren');
  }

  private onUserChanged() {
    this.snackBar.open('Ändringar sparade.', 'OK');
  }

  @validate
  private openErrorModal(@required title: string) {
    const errorModalDialogData = new ErrorModalDialogData({ title: title });
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: errorModalDialogData,
    });
  }

  private async loadUser(email: string): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      this.user = await this.usersApiService.getUserByEmail(email);
      this.editedUser = _.cloneDeep(this.user);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to load user', error);
    } finally {
      this.state.isLoading = false;
    }
  }
}
