import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { Document, DocumentFieldNote, DocumentSectionType } from 'src/app/documents/shared';

@Component({
  selector: 'app-document-radio-button',
  templateUrl: './document-radio-button.component.html',
  styleUrls: ['./document-radio-button.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocumentRadioButtonComponent),
      multi: true,
    },
  ],
})
export class DocumentRadioButtonComponent implements ControlValueAccessor, OnInit {
  @Input()
  fieldName: string;

  @Input()
  hasEditPermissions: boolean;

  @Input()
  sectionType: DocumentSectionType;

  @Input()
  document: Document;

  @Input()
  notes: StringMap<DocumentFieldNote>;

  @Input('name')
  noteName: string;

  @Input()
  isMasterConditionTrue: boolean;

  get value() {
    return this._value;
  }

  set value(v) {
    this._value = v;
    this.onChange(this._value);
    this.onTouched();
  }

  private _value;

  constructor() {}

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.onChange(this.value);
  }

  ngOnInit(): void {}

  writeValue(obj: any): void {
    // When isMasterConditionTrue is true the radio button is disabled and set to true/checked value.
    // So we need to update (will override) the value of the parent element.
    this._value = this.isMasterConditionTrue ? this.isMasterConditionTrue : obj;
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  onChange: any = () => {};

  onTouched: any = () => {};
}
