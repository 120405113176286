import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { requireNumberValidator } from './require-number.validator';
import { required, validate } from 'src/app/shared';

@Directive({
  selector: '[appRequireNumber]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RequireNumberValidatorDirective, multi: true }],
})
export class RequireNumberValidatorDirective {
  @validate
  validate(@required control: AbstractControl): ValidationErrors | null {
    const validator = requireNumberValidator();

    const result = validator(control);

    return result;
  }
}
