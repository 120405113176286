import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StringUtils } from 'src/app/shared';
import { organizationConfig } from '../organization.config';
import { Organization } from '../shared';

@Component({
  selector: 'app-delete-organization-modal',
  templateUrl: './delete-organization-modal.component.html',
  styleUrls: ['./delete-organization-modal.component.scss'],
})
export class DeleteOrganizationModalComponent {
  confirmationText: string;

  constructor(
    public dialogRef: MatDialogRef<DeleteOrganizationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public organization: Organization
  ) {
    this.confirmationText = '';
  }

  get expectedConfirmationText(): string {
    return organizationConfig.deactivate.confirmationText;
  }

  get isConfirmed(): boolean {
    const isConfirmed = StringUtils.equalsIgnoreCase(this.confirmationText, this.expectedConfirmationText);
    return isConfirmed;
  }

  onDelete() {
    if (!this.isConfirmed)
      return;

    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
