import { Injectable } from '@angular/core';
import _, { includes, isNil, without } from 'lodash';
import { forEach, isEmpty } from 'lodash';
import { DocumentFieldType } from './shared/document-structure/document-field-type';
import { DocumentSectionFilter } from './shared/document-structure/document-section-filter';
import {
  Document,
  DocumentBuilding,
  DocumentEvacuation,
  DocumentFire,
  DocumentInstallations,
  DocumentLifts,
  DocumentOpportunities,
  DocumentRisks,
} from './shared/document';
import moment from 'moment';
import { DocumentSectionType, ExtinguishingSystem, Renovation } from './shared';
import { DocumentsStoreService } from './documents-store';
import { DocumentsApiService } from './documents-api.service';
import { DocumentsSectionDataService } from './documents-section-data.service';
import { FilterPredicate } from './shared/document-structure/filter-predicate';
import { MathUtils } from '../shared/utils/math-utils';
import { required, validate } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(
    private documentsStoreService: DocumentsStoreService,
    private documentsApiService: DocumentsApiService,
    private documentsSectionDataService: DocumentsSectionDataService
  ) {}

  async patchDocumentAndSave(fullDocument: Document, partialDocument: Document) {
    // Save a full document to the local storage
    // user is working with temporary version during all session
    const documentForStore = this.mergeDocumentProperties(fullDocument, partialDocument);
    this.documentsStoreService.addDocument(documentForStore);

    try {
      const patchedDocumentForBackend = this.replaceDocumentProperties(fullDocument, partialDocument);
      // filter out all temporary data (send only valid state)
      const filterPatchedDocumentForBackend = this.filterDocumentPropertiesForServer(patchedDocumentForBackend);
      await this.documentsApiService.patchDocument(filterPatchedDocumentForBackend);
    } catch (ex) {
      throw ex;
    }
  }

  filterDocumentSections(items: Document[], filters: DocumentSectionFilter[]): Document[] {
    if (isEmpty(filters)) {
      return items;
    }

    let resultItems: Document[] = items;
    forEach(filters, (filter: DocumentSectionFilter) => {
      resultItems = this.filterDocumentsSectionsByFilter(resultItems, filter);
    });

    return resultItems;
  }

  private replaceDocumentProperties(fullDocument: Document, partialDocument: Document): Document {
    const patchDocument = new Document({
      ...fullDocument,
      info: partialDocument.info ? partialDocument.info : fullDocument.info,
      object: partialDocument.object ? partialDocument.object : fullDocument.object,
      building: partialDocument.building ? partialDocument.building : fullDocument.building,
      evacuation: partialDocument.evacuation ? partialDocument.evacuation : fullDocument.evacuation,
      fire: partialDocument.fire ? partialDocument.fire : fullDocument.fire,
      spread: partialDocument.spread ? partialDocument.spread : fullDocument.spread,
      capacity: partialDocument.capacity ? partialDocument.capacity : fullDocument.capacity,
      installations: partialDocument.installations ? partialDocument.installations : fullDocument.installations,
      airtreatment: partialDocument.airtreatment ? partialDocument.airtreatment : fullDocument.airtreatment,
      lifts: partialDocument.lifts ? partialDocument.lifts : fullDocument.lifts,
      opportunities: partialDocument.opportunities ? partialDocument.opportunities : fullDocument.opportunities,
      risks: partialDocument.risks ? partialDocument.risks : fullDocument.risks,
      protectedObjects: partialDocument.protectedObjects ? partialDocument.protectedObjects : fullDocument.protectedObjects,
      inspection: partialDocument.inspection ? partialDocument.inspection : fullDocument.inspection,
    });

    return patchDocument;
  }

  private filterDocumentPropertiesForServer(document: Document): Document {
    const patchDocument = new Document({
      ...document,
      building: document.building,
      evacuation: this.filterEvacuationDocumentForServer(document.evacuation),
      fire: document.fire,
      installations: this.filterInstallationsDocumentForServer(document.installations),
      lifts: this.filterLiftsDocumentForServer(document.lifts),
      opportunities: this.filterOpportunitiesDocumentForServer(document.opportunities),
      risks: this.filterRisksDocumentForServer(document.risks),
    });

    return patchDocument;
  }

  private filterEvacuationDocumentForServer(evacuation: DocumentEvacuation): DocumentEvacuation {
    if (!evacuation || evacuation.isEvacuationForDisabilitiesPeople) {
      // if isEvacuationForDisabilitiesPeople then return as is
      return evacuation;
    }

    const newEvacuation = new DocumentEvacuation({
      ...evacuation,
      isCommunicationEquipmentEvacuation: null,
    });

    return newEvacuation;
  }

  private filterLiftsDocumentForServer(lifts: DocumentLifts): DocumentLifts {
    if (!lifts || lifts.isElevatorExist) {
      // if elevator exists then return as is
      return lifts;
    }

    const newLifts = new DocumentLifts({
      isElevatorExist: lifts.isElevatorExist,
      isCompleted: lifts.isCompleted,
      isValid: lifts.isValid,
    });

    return newLifts;
  }

  private filterInstallationsDocumentForServer(installations: DocumentInstallations): DocumentInstallations {
    if (!installations) {
      return installations;
    }

    const newInstallations = new DocumentInstallations({
      ...installations,

      hasFireAlarmsSeriesConnection: installations.isFireAlarmsOccur
        ? installations.hasFireAlarmsSeriesConnection
        : null,

      coordinatedFunctionAutomaticFireAlarmRelevantIds: installations.isAutomaticFireAlarmsOccur
        ? installations.coordinatedFunctionAutomaticFireAlarmRelevantIds
        : null,
      automaticFireAlarmsMonitoringScopeIds: installations.isAutomaticFireAlarmsOccur
        ? installations.automaticFireAlarmsMonitoringScopeIds
        : null,
      automaticFireAlarmDetekteringIds: installations.isAutomaticFireAlarmsOccur
        ? installations.automaticFireAlarmDetekteringIds
        : null,
      automaticFireAlarmsReceiverIds: installations.isAutomaticFireAlarmsOccur
        ? installations.automaticFireAlarmsReceiverIds
        : null,
      automaticFireAlarmSpecialAdaptationIds: installations.isAutomaticFireAlarmsOccur
        ? installations.automaticFireAlarmSpecialAdaptationIds
        : null,

      alarmSignalPerceivedIds: installations.isAlarmSignalingOccur
        ? installations.alarmSignalPerceivedIds
        : null,
      evacuationAlarmsActivationIds: installations.isAlarmSignalingOccur
        ? installations.evacuationAlarmsActivationIds
        : null,
      evacuationAlarmsSignalIds: installations.isAlarmSignalingOccur ? installations.evacuationAlarmsSignalIds : null,
      evacuationAlarmsSpecialAdaptationIds: installations.isAlarmSignalingOccur
        ? installations.evacuationAlarmsSpecialAdaptationIds
        : null,

      monitoringAutomaticExtinguishingSystemIds: installations.isAutomaticExtinguishingSystemOccur
        ? installations.monitoringAutomaticExtinguishingSystemIds
        : null,
      automaticWaterSprinklerAdjustmentIds: installations.isAutomaticExtinguishingSystemOccur
        ? installations.automaticWaterSprinklerAdjustmentIds
        : null,
      automaticWaterSprinklerRiskClassIds: installations.isAutomaticExtinguishingSystemOccur
        ? installations.automaticWaterSprinklerRiskClassIds
        : null,
      automaticWaterSprinklerOther: installations.isAutomaticExtinguishingSystemOccur
        ? installations.automaticWaterSprinklerOther
        : null,

      premisesFireGasVentilationIds: installations.isFireGasVentilationOccur
        ? installations.premisesFireGasVentilationIds
        : null,
      premisesFireGasVentilationOther: installations.isFireGasVentilationOccur
        ? installations.premisesFireGasVentilationOther
        : null,
    });

    return newInstallations;
  }

  private filterOpportunitiesDocumentForServer(opportunities: DocumentOpportunities): DocumentOpportunities {
    if (!opportunities) {
      return opportunities;
    }

    const newOpportunities = new DocumentOpportunities({
      ...opportunities,
      isAscensionLinePressurized: opportunities.hasAscensionLine ? opportunities.isAscensionLinePressurized : null,
      isKeyStoringOnObject: opportunities.isKeyAccess ? opportunities.isKeyStoringOnObject : null,
    });

    return newOpportunities;
  }

  private filterRisksDocumentForServer(risks: DocumentRisks): DocumentRisks {
    if (!risks) {
      return risks;
    }

    const newRisks = new DocumentRisks({ ...risks });

    return newRisks;
  }

  private mergeDocumentProperties(fullDocument: Document, partialDocument: Document): Document {
    const patchDocument = new Document({
      ...fullDocument,
      info: partialDocument.info ? partialDocument.info : fullDocument.info,
      object: partialDocument.object ? partialDocument.object : fullDocument.object,
      building: partialDocument.building ? partialDocument.building : fullDocument.building,
      evacuation: partialDocument.evacuation ? partialDocument.evacuation : fullDocument.evacuation,
      fire: partialDocument.fire ? partialDocument.fire : fullDocument.fire,
      spread: partialDocument.spread ? partialDocument.spread : fullDocument.spread,
      capacity: partialDocument.capacity ? partialDocument.capacity : fullDocument.capacity,
      installations: partialDocument.installations ? partialDocument.installations : fullDocument.installations,
      airtreatment: partialDocument.airtreatment ? partialDocument.airtreatment : fullDocument.airtreatment,
      lifts: partialDocument.lifts ? partialDocument.lifts : fullDocument.lifts,
      opportunities: partialDocument.opportunities ? partialDocument.opportunities : fullDocument.opportunities,
      risks: partialDocument.risks ? partialDocument.risks : fullDocument.risks,
      protectedObjects: partialDocument.protectedObjects
        ? partialDocument.protectedObjects
        : fullDocument.protectedObjects,
      inspection: partialDocument.inspection ? partialDocument.inspection : fullDocument.inspection,
    });

    return patchDocument;
  }

  @validate
  mergeServerAndCachedDocuments(@required serverDocument: Document, cacheDocument: Document): Document {
    if (!cacheDocument) {
      return serverDocument;
    }
    const tempararyDocument = new Document({
      ...serverDocument,
      building: this.mergeBuildingDocumentForCache(serverDocument.building, cacheDocument.building),
      evacuation: this.mergeEvacuationDocumentForCache(serverDocument.evacuation, cacheDocument.evacuation),
      fire: this.mergeFireDocumentForCache(serverDocument.fire, cacheDocument.fire),
      lifts: this.mergeLiftsDocumentForCache(serverDocument.lifts, cacheDocument.lifts),
      installations: this.mergeInstallationsDocumentForCache(serverDocument.installations, cacheDocument.installations),
      opportunities: this.mergeOpportunitiesDocumentForCache(serverDocument.opportunities, cacheDocument.opportunities),
    });

    return tempararyDocument;
  }

  private mergeBuildingDocumentForCache(
    @required serverBuildingDocument: DocumentBuilding,
    cacheBuildingDocument: DocumentBuilding
  ): DocumentBuilding {
    const newBuilding = new DocumentBuilding({
      ...serverBuildingDocument,
      isConsideredBasementFloorPlan: serverBuildingDocument.hasBasementFloor
        ? serverBuildingDocument.isConsideredBasementFloorPlan
        : cacheBuildingDocument.isConsideredBasementFloorPlan,
      isConsideredAtticFloorPlan: serverBuildingDocument.hasAtticFloor
        ? serverBuildingDocument.isConsideredAtticFloorPlan
        : cacheBuildingDocument.isConsideredAtticFloorPlan,
      isConsideredEntresolplan: serverBuildingDocument.hasEntresolplan
        ? serverBuildingDocument.isConsideredEntresolplan
        : cacheBuildingDocument.isConsideredEntresolplan,
    });

    return newBuilding;
  }

  private mergeEvacuationDocumentForCache(
    @required serverEvacuationDocument: DocumentEvacuation,
    cacheEvacuationDocument: DocumentEvacuation
  ): DocumentEvacuation {
    if (serverEvacuationDocument.isEvacuationForDisabilitiesPeople) {
      // if isEvacuationForDisabilitiesPeople then return as is
      return serverEvacuationDocument;
    }

    const newEvacuation = new DocumentEvacuation({
      ...serverEvacuationDocument,
      isCommunicationEquipmentEvacuation: cacheEvacuationDocument.isCommunicationEquipmentEvacuation,
    });

    return newEvacuation;
  }

  private mergeFireDocumentForCache(
    @required serverFireDocument: DocumentFire,
    cacheFireDocument: DocumentFire
  ): DocumentFire {
    if (serverFireDocument.isBuildingDividedIntoFireCells) {
      // if isBuildingDividedIntoFireCells then return as is
      return serverFireDocument;
    }

    const tempararyFireDocument = new DocumentFire({
      ...serverFireDocument,
      isMoreThanTwoPlanes: cacheFireDocument.isMoreThanTwoPlanes,
      isSpecialFireTechnicalClass: cacheFireDocument.isSpecialFireTechnicalClass,
      isSeveralBusinessClasses: cacheFireDocument.isSeveralBusinessClasses,
      hasDoorsOrHatchesInFireSeparationConstruction: cacheFireDocument.hasDoorsOrHatchesInFireSeparationConstruction,
      hasInstallationDeviceOnDoors: cacheFireDocument.hasInstallationDeviceOnDoors,
      hasAutomaticOpeningOnDoors: cacheFireDocument.hasAutomaticOpeningOnDoors,
      hasWindowsInFireRetardantConstruction: cacheFireDocument.hasWindowsInFireRetardantConstruction,
      hasFireWindowAtAngle: cacheFireDocument.hasFireWindowAtAngle,
      hasFireLowHighPart: cacheFireDocument.hasFireLowHighPart,
      hasFireRetardantCeilingsFloors: cacheFireDocument.hasFireRetardantCeilingsFloors,
      hasAirFireLock: cacheFireDocument.hasAirFireLock,
      hasCoveredYardBalconies: cacheFireDocument.hasCoveredYardBalconies,
      hasFireCellInAttic: cacheFireDocument.hasFireCellInAttic,
      sectioningThroughFirewallIds: cacheFireDocument.sectioningThroughFirewallIds,
      olderFireTechnicalClassIds: cacheFireDocument.olderFireTechnicalClassIds,
      fireCellMeetTechnicalClassIds: cacheFireDocument.fireCellMeetTechnicalClassIds,
      fireSpecialConditionsDescription: cacheFireDocument.fireSpecialConditionsDescription,
      fireExtendedDescription: cacheFireDocument.fireExtendedDescription,
    });

    return tempararyFireDocument;
  }

  private mergeLiftsDocumentForCache(
    @required serverLiftsDocument: DocumentLifts,
    cacheLiftsDocument: DocumentLifts
  ): DocumentLifts {
    if (serverLiftsDocument.isElevatorExist) {
      // if elevator exists then return as is
      return serverLiftsDocument;
    }

    const tempararyLiftsDocument = new DocumentLifts({
      ...serverLiftsDocument,
      elevatorFireProtectionOptionIds: cacheLiftsDocument.elevatorFireProtectionOptionIds,
      elevatorFireProtectionOther: cacheLiftsDocument.elevatorFireProtectionOther,
      liftSteeringFunctionId: cacheLiftsDocument.liftSteeringFunctionId,
      recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId: cacheLiftsDocument.recallFunctionViaFireAlarmControlsElevatorToEvacuationPlaneId,
      liftFireproofWiringId: cacheLiftsDocument.liftFireproofWiringId,
      liftsDesignDescription: cacheLiftsDocument.liftsDesignDescription,
    });

    return tempararyLiftsDocument;
  }

  private mergeInstallationsDocumentForCache(
    @required serverInstallationsDocument: DocumentInstallations,
    cacheInstallationsDocument: DocumentInstallations
  ): DocumentInstallations {
    const tempararyLiftsDocument = new DocumentInstallations({
      ...serverInstallationsDocument,
      hasFireAlarmsSeriesConnection: serverInstallationsDocument.isFireAlarmsOccur
        ? serverInstallationsDocument.hasFireAlarmsSeriesConnection
        : cacheInstallationsDocument.hasFireAlarmsSeriesConnection,

      coordinatedFunctionAutomaticFireAlarmRelevantIds: serverInstallationsDocument.isAutomaticFireAlarmsOccur
        ? serverInstallationsDocument.coordinatedFunctionAutomaticFireAlarmRelevantIds
        : cacheInstallationsDocument.coordinatedFunctionAutomaticFireAlarmRelevantIds,
      automaticFireAlarmsMonitoringScopeIds: serverInstallationsDocument.isAutomaticFireAlarmsOccur
        ? serverInstallationsDocument.automaticFireAlarmsMonitoringScopeIds
        : cacheInstallationsDocument.automaticFireAlarmsMonitoringScopeIds,
      automaticFireAlarmDetekteringIds: serverInstallationsDocument.isAutomaticFireAlarmsOccur
        ? serverInstallationsDocument.automaticFireAlarmDetekteringIds
        : cacheInstallationsDocument.automaticFireAlarmDetekteringIds,
      automaticFireAlarmsReceiverIds: serverInstallationsDocument.isAutomaticFireAlarmsOccur
        ? serverInstallationsDocument.automaticFireAlarmsReceiverIds
        : cacheInstallationsDocument.automaticFireAlarmsReceiverIds,
      automaticFireAlarmSpecialAdaptationIds: serverInstallationsDocument.isAutomaticFireAlarmsOccur
        ? serverInstallationsDocument.automaticFireAlarmSpecialAdaptationIds
        : cacheInstallationsDocument.automaticFireAlarmSpecialAdaptationIds,

      alarmSignalPerceivedIds: serverInstallationsDocument.isAlarmSignalingOccur
        ? serverInstallationsDocument.alarmSignalPerceivedIds
        : cacheInstallationsDocument.alarmSignalPerceivedIds,
      evacuationAlarmsActivationIds: serverInstallationsDocument.isAlarmSignalingOccur
        ? serverInstallationsDocument.evacuationAlarmsActivationIds
        : cacheInstallationsDocument.evacuationAlarmsActivationIds,
      evacuationAlarmsSignalIds: serverInstallationsDocument.isAlarmSignalingOccur
        ? serverInstallationsDocument.evacuationAlarmsSignalIds
        : cacheInstallationsDocument.evacuationAlarmsSignalIds,
      evacuationAlarmsSpecialAdaptationIds: serverInstallationsDocument.isAlarmSignalingOccur
        ? serverInstallationsDocument.evacuationAlarmsSpecialAdaptationIds
        : cacheInstallationsDocument.evacuationAlarmsSpecialAdaptationIds,

      monitoringAutomaticExtinguishingSystemIds: serverInstallationsDocument.isAutomaticExtinguishingSystemOccur
        ? serverInstallationsDocument.monitoringAutomaticExtinguishingSystemIds
        : cacheInstallationsDocument.monitoringAutomaticExtinguishingSystemIds,
      automaticWaterSprinklerAdjustmentIds: serverInstallationsDocument.isAutomaticExtinguishingSystemOccur
        ? serverInstallationsDocument.automaticWaterSprinklerAdjustmentIds
        : cacheInstallationsDocument.automaticWaterSprinklerAdjustmentIds,
      automaticWaterSprinklerRiskClassIds: serverInstallationsDocument.isAutomaticExtinguishingSystemOccur
        ? serverInstallationsDocument.automaticWaterSprinklerRiskClassIds
        : cacheInstallationsDocument.automaticWaterSprinklerRiskClassIds,
      automaticWaterSprinklerOther: serverInstallationsDocument.isAutomaticExtinguishingSystemOccur
        ? serverInstallationsDocument.automaticWaterSprinklerOther
        : cacheInstallationsDocument.automaticWaterSprinklerOther,

      premisesFireGasVentilationIds: serverInstallationsDocument.isFireGasVentilationOccur
        ? serverInstallationsDocument.premisesFireGasVentilationIds
        : cacheInstallationsDocument.premisesFireGasVentilationIds,
      premisesFireGasVentilationOther: serverInstallationsDocument.isFireGasVentilationOccur
        ? serverInstallationsDocument.premisesFireGasVentilationOther
        : cacheInstallationsDocument.premisesFireGasVentilationOther,
    });

    return tempararyLiftsDocument;
  }

  private mergeOpportunitiesDocumentForCache(
    @required serverOpportunitiesDocument: DocumentOpportunities,
    cacheOpportunitiesDocument: DocumentOpportunities
  ): DocumentOpportunities {
    if (serverOpportunitiesDocument.isKeyAccess) {
      // if isKeyAccess then return as is
      return serverOpportunitiesDocument;
    }

    const newOpportunities = new DocumentOpportunities({
      ...serverOpportunitiesDocument,
      isKeyStoringOnObject: cacheOpportunitiesDocument.isKeyStoringOnObject,
    });

    return newOpportunities;
  }

  private filterDocumentsSectionsByFilter(items: Document[], filter: DocumentSectionFilter): Document[] {
    const result = _.filter(items, (item: Document) => {
      const mapDocument: StringMap<any> = item as any;
      const section: StringMap<any> = filter.sectionId === DocumentSectionType.common ? item : mapDocument[filter.sectionId];

      if (filter.type === DocumentFieldType.string) {
        return this.filterStringValue(section[filter.fieldId], filter.value);
      }

      if (filter.type === DocumentFieldType.number) {
        return this.filterNumberValue(section[filter.fieldId], filter.value, filter.predicate);
      }

      if (filter.type === DocumentFieldType.boolean) {
        return this.filterBooleanValue(section[filter.fieldId], filter.value);
      }

      if (filter.type === DocumentFieldType.dropdown || filter.type === DocumentFieldType.groupDropdown) {
        return this.filterDropdownValue(section[filter.fieldId], filter.value);
      }

      if (filter.type === DocumentFieldType.multiple) {
        return this.filterMultipleValue(section[filter.fieldId], filter.value);
      }

      if (filter.type === DocumentFieldType.date) {
        return this.filterDateValue(section[filter.fieldId], filter.value);
      }

      if (filter.type === DocumentFieldType.extinguishingSystems) {
        return this.filterExtinguishingSystemsValue(section[filter.fieldId], filter.value);
      }

      if (filter.type === DocumentFieldType.renovation) {
        return this.filterRenovationValue(section[filter.fieldId], filter.value);
      }

      return true;
    });

    return result;
  }

  private filterStringValue(value: any, filter: any): boolean {
    if (isEmpty(filter)) {
      return true;
    }

    const stringValue: string = (value as string) || '';
    const filterValue: string = (filter as string) || '';

    return includes(stringValue.toLowerCase(), filterValue.toLowerCase());
  }

  private filterNumberValue(value: any, filter: any, predicate: FilterPredicate): boolean {
    if (isNil(filter)) {
      return true;
    }
    const strNumberValue: string = ((value as string) || '') + '';
    const strFilterValue: string = ((filter as string) || '') + '';

    const numberValue: number = MathUtils.convertStringToNumber(strNumberValue);
    const filterValue: number = MathUtils.convertStringToNumber(strFilterValue);

    if (predicate === FilterPredicate.Greater) {
      return numberValue >= filterValue;
    }

    if (predicate === FilterPredicate.Less) {
      return numberValue <= filterValue;
    }

    return false;
  }

  private filterBooleanValue(value: any, filter: any): boolean {
    if (isNil(filter)) {
      return true;
    }

    const booleanValue: boolean = value as boolean;
    const filterValue: boolean = filter as boolean;

    return booleanValue === filterValue;
  }

  private filterDropdownValue(documentFieldValue: any, dropdownValue: any): boolean {
    if (isNil(dropdownValue)) {
      return true;
    }

    return String(documentFieldValue) === String(dropdownValue);
  }

  private filterMultipleValue(value: any, filter: any): boolean {
    if (isEmpty(filter)) {
      return true;
    }

    const multipleValue: string[] = value as string[];
    const filterValue: string[] = filter as string[];

    const result = without(filterValue, ...multipleValue);

    return isEmpty(result);
  }

  private filterDateValue(value: any, filter: any): boolean {
    if (isNil(filter)) {
      return true;
    }

    const dateValue: moment.Moment = moment(value);
    const filterValue: moment.Moment = moment(filter);

    return dateValue.isSame(filterValue, 'day');
  }

  private filterExtinguishingSystemsValue(value: any, filter: any): boolean {
    if (isNil(filter)) {
      return true;
    }

    const extinguishingSystems: ExtinguishingSystem[] = value as ExtinguishingSystem[];
    const filterValue: string = filter as string;

    const extinguishingSystemTypes = this.documentsSectionDataService.getExtinguishingSystemOptionTypes();

    const result = _.filter(extinguishingSystems, (extinguishingSystem: ExtinguishingSystem) => {
      const extinguishingSystemName: string =
        extinguishingSystemTypes[extinguishingSystem.extinguishingSystemId]?.displayName || '';

      return (
        includes(extinguishingSystem.extinguishingSystemInvestment.toLowerCase(), filterValue.toLowerCase()) ||
        includes(extinguishingSystemName.toLowerCase(), filterValue.toLowerCase())
      );
    });

    return !isEmpty(result);
  }

  private filterRenovationValue(value: any, filter: any): boolean {
    if (isNil(filter)) {
      return true;
    }

    const renovationValues: Renovation[] = value as Renovation[];
    const filterValue: string = filter as string;

    const result = _.filter(renovationValues, (renovationValue: Renovation) => {
      return (
        includes(renovationValue.remedy.toLowerCase(), filterValue.toLowerCase()) ||
        includes(renovationValue.year.toString(), filterValue.toLowerCase())
      );
    });

    return !isEmpty(result);
  }
}
