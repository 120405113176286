import { Injectable } from '@angular/core';
import { AuthenticationStoreService } from './authentication-store';
import { take } from 'rxjs/operators';
import { validate, required } from '../shared';
import { CoreStoreService } from '../core/store';
import { DocumentsStoreService } from '../documents/documents-store';
import { DocumentNotesStoreService } from '../documents/documents-notes-store';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private authenticationStoreService: AuthenticationStoreService,
    private coreStoreService: CoreStoreService,
    private documentsStoreService: DocumentsStoreService,
    private documentNotesStoreService: DocumentNotesStoreService
  ) {}

  expireAuthentication() {
    this.authenticationStoreService.expireAuthentication();
    this.coreStoreService.resetState();
    this.documentsStoreService.resetDocuments();
    this.documentNotesStoreService.resetDocumentsNotes();
  }

  @validate
  async setReturnUrl(@required returnUrl: string, isForceUpdate: boolean = false) {
    const existingReturnUrl = await this.getReturnUrl();

    if (isForceUpdate || !existingReturnUrl) {
      this.authenticationStoreService.setReturnUrl(returnUrl);
    }
  }

  clearReturnUrl() {
    this.authenticationStoreService.setReturnUrl(null);
  }

  async getReturnUrl(): Promise<string> {
    const returnUrl = this.authenticationStoreService.getReturnUrl().pipe(take(1)).toPromise();

    return returnUrl;
  }
}
