import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentTabContentComponent } from '../project-tab-container/project-tab-container.component';
import {
  Document,
  DocumentInstallations,
  DocumentInstallationsAutomaticFireAlarmActivationOptionTypes,
  DocumentInstallationsAutomaticFireAlarmPowerSupplyOptionTypes,
  DocumentInstallationsEvacuationAlarmsActivationOptionTypes,
  DocumentInstallationsExtinguishingEquipmentFormOptionTypes,
  DocumentSectionType,
  ExtinguishingSystem
} from 'src/app/documents/shared';
import { projectConfig } from '../project.config';
import { filter, intersection, isNil, some } from 'lodash';
import { required, validate } from 'src/app/shared';
import { DocumentsSectionDataService } from 'src/app/documents/documents-section-data.service';
import { coreConfig } from 'src/app/core';
import { DocumentsService } from 'src/app/documents/documents.service';
import { DocumentsValidationService } from 'src/app/documents/documents-validation.service';
import { DocumentSelectOptionType } from 'src/app/documents/shared/document-structure/document-select-option-type';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-project-tab-fire-installations',
  templateUrl: './project-tab-fire-installations.component.html',
  styleUrls: ['./project-tab-fire-installations.component.scss'],
})
export class ProjectTabFireInstallationsComponent extends DocumentTabContentComponent implements OnInit {
  @ViewChild('tabForm')
  tabForm: NgForm;

  data: {
    extinguishingSystemId: string;
    extinguishingSystemInvestment: string;
  };

  markingsEmergencyLightingOptionTypes: StringMap<DocumentSelectOptionType>;
  powerSupplyEmergencyLightingOptionTypes: StringMap<DocumentSelectOptionType>;
  coordinatedFunctionAutomaticFireAlarmRelevantOptionTypes: StringMap<DocumentSelectOptionType>;
  automaticFireAlarmsMonitoringScopeOptionTypes: StringMap<DocumentSelectOptionType>;
  automaticFireAlarmDetekteringOptionTypes: StringMap<DocumentSelectOptionType>;
  automaticFireAlarmActivationOptionTypes: StringMap<DocumentSelectOptionType>;
  automaticFireAlarmPowerSupplyOptionTypes: StringMap<DocumentSelectOptionType>;
  automaticFireAlarmsReceiverOptionTypes: StringMap<DocumentSelectOptionType>;
  automaticFireAlarmSpecialAdaptationOptionTypes: StringMap<DocumentSelectOptionType>;
  alarmSignalingOccurOptionTypes: StringMap<DocumentSelectOptionType>;
  evacuationAlarmsPerceivedOptionTypes: StringMap<DocumentSelectOptionType>;
  evacuationAlarmsActivationOptionTypes: StringMap<DocumentSelectOptionType>;
  evacuationAlarmsSignalOptionTypes: StringMap<DocumentSelectOptionType>;
  evacuationAlarmsSpecialAdaptationOptionTypes: StringMap<DocumentSelectOptionType>;
  automaticExtinguishingSystemOccurOptionTypes: StringMap<DocumentSelectOptionType>;
  automaticWaterSprinklerMonitoringOptionTypes: StringMap<DocumentSelectOptionType>;
  automaticWaterSprinklerAdjustmentOptionTypes: StringMap<DocumentSelectOptionType>;
  automaticWaterSprinklerRiskClassOptionTypes: StringMap<DocumentSelectOptionType>;
  extinguishingSystemOptionTypes: StringMap<DocumentSelectOptionType>;
  extinguishingEquipmentFormOptionTypes: StringMap<DocumentSelectOptionType>;
  premisesFireGasVentilationOptionTypes: StringMap<DocumentSelectOptionType>;

  get previousPageName(): string {
    return projectConfig.routes.capacity;
  }

  get nextPageName(): string {
    return projectConfig.routes.airtreatment;
  }

  get isExtinguishingSystemValid(): boolean {
    const isValid = !isNil(this.data.extinguishingSystemId) && !!this.data.extinguishingSystemInvestment;

    return isValid;
  }

  isExtinguishingEquipmentFormOtherEnabled: boolean;
  isPremisesFireGasVentilationOtherEnabled: boolean;
  isPowerSupplyEmergencyLightingEnabled: boolean;
  isAutomaticFireAlarmActivationOtherEnabled: boolean;
  isAutomaticFireAlarmPowerSupplyOtherEnabled: boolean;
  isEvacuationAlarmsActivationOtherEnabled: boolean;

  private setTimeoutHandler: NodeJS.Timeout;

  constructor(
    dialog: MatDialog,
    snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private documentsValidationService: DocumentsValidationService,
    private documentsSectionDataService: DocumentsSectionDataService,
  ) {
    super(DocumentSectionType.installations, dialog, snackBar);

    this.data = {
      extinguishingSystemId: null,
      extinguishingSystemInvestment: null,
    };
  }

  ngOnInit(): void {
    this.markingsEmergencyLightingOptionTypes = this.documentsSectionDataService.getMarkingsEmergencyLightingOptionTypes();
    this.powerSupplyEmergencyLightingOptionTypes = this.documentsSectionDataService.getPowerSupplyEmergencyLightingOptionTypes();
    this.coordinatedFunctionAutomaticFireAlarmRelevantOptionTypes = this.documentsSectionDataService.getCoordinatedFunctionAutomaticFireAlarmRelevantOptionTypes();
    // tslint:disable-next-line:max-line-length
    this.automaticFireAlarmsMonitoringScopeOptionTypes = this.documentsSectionDataService.getAutomaticFireAlarmsMonitoringScopeOptionTypes();
    this.automaticFireAlarmDetekteringOptionTypes = this.documentsSectionDataService.getAutomaticFireAlarmDetekteringOptionTypes();
    this.automaticFireAlarmActivationOptionTypes = this.documentsSectionDataService.getAutomaticFireAlarmActivationOptionTypes();
    this.automaticFireAlarmPowerSupplyOptionTypes = this.documentsSectionDataService.getAutomaticFireAlarmPowerSupplyOptionTypes();
    this.automaticFireAlarmsReceiverOptionTypes = this.documentsSectionDataService.getAutomaticFireAlarmsReceiverOptionTypes();
    // tslint:disable-next-line:max-line-length
    this.automaticFireAlarmSpecialAdaptationOptionTypes = this.documentsSectionDataService.getAutomaticFireAlarmSpecialAdaptationOptionTypes();
    this.alarmSignalingOccurOptionTypes = this.documentsSectionDataService.getAlarmSignalingOccurOptionTypes();
    this.evacuationAlarmsPerceivedOptionTypes = this.documentsSectionDataService.getEvacuationAlarmsPerceivedOptionTypes();
    this.evacuationAlarmsActivationOptionTypes = this.documentsSectionDataService.getEvacuationAlarmsActivationOptionTypes();
    this.evacuationAlarmsSignalOptionTypes = this.documentsSectionDataService.getEvacuationAlarmsSignalOptionTypes();
    this.evacuationAlarmsSpecialAdaptationOptionTypes = this.documentsSectionDataService.getEvacuationAlarmsSpecialAdaptationOptionTypes();
    this.automaticExtinguishingSystemOccurOptionTypes = this.documentsSectionDataService.getAutomaticExtinguishingSystemOccurOptionTypes();
    this.automaticWaterSprinklerMonitoringOptionTypes = this.documentsSectionDataService.getAutomaticWaterSprinklerMonitoringOptionTypes();
    this.automaticWaterSprinklerAdjustmentOptionTypes = this.documentsSectionDataService.getAutomaticWaterSprinklerAdjustmentOptionTypes();
    this.automaticWaterSprinklerRiskClassOptionTypes = this.documentsSectionDataService.getAutomaticWaterSprinklerRiskClassOptionTypes();
    this.extinguishingSystemOptionTypes = this.documentsSectionDataService.getExtinguishingSystemOptionTypes();
    this.extinguishingEquipmentFormOptionTypes = this.documentsSectionDataService.getExtinguishingEquipmentFormOptionTypes();
    this.premisesFireGasVentilationOptionTypes = this.documentsSectionDataService.getPremisesFireGasVentilationOptionTypes();
  }

  async onCompleteForm(isCompleted: boolean): Promise<void> {
    this.document.installations.isCompleted = isCompleted && this.document.installations.isValid;
    await this.saveDocumentObject(this.document.installations, true);
  }

  setDocument(document: Document): void {
    this.document = document;

    this.updatePowerSupplyEmergencyLightingEnabled();
    this.updateExtinguishingEquipmentFormOther();
    this.updatePremisesFireGasVentilationOther();
    this.updateAutomaticFireAlarmActivationOther();
    this.updateAutomaticFireAlarmPowerSupplyOther();
    this.updateEvacuationAlarmsActivationOther();
  }

  onAddExtinguishingSystem(event: Event): void {
    event.preventDefault();
    if (!this.isExtinguishingSystemValid) {
      return;
    }

    const extinguishingSystem = new ExtinguishingSystem({
      extinguishingSystemId: this.data.extinguishingSystemId,
      extinguishingSystemInvestment: this.data.extinguishingSystemInvestment,
    });

    const installations = new DocumentInstallations({
      ...this.document.installations,
      extinguishingSystems: [...this.document.installations.extinguishingSystems, extinguishingSystem],
    });

    installations.isValid = this.documentsValidationService.isInstallationsValid(this.document.installations, this.document);
    installations.isCompleted = installations.isCompleted && installations.isValid;

    this.saveDocumentObject(installations, false);

    this.data.extinguishingSystemId = null;
    this.data.extinguishingSystemInvestment = null;
  }

  @validate
  onDeleteExtinguishingSystem(@required extinguishingSystem: ExtinguishingSystem): void {
    if (!this.document) {
      return;
    }

    const extinguishingSystems = filter(
      this.document.installations.extinguishingSystems,
      (item) => item !== extinguishingSystem
    );

    const installations = new DocumentInstallations({
      ...this.document.installations,
      extinguishingSystems: extinguishingSystems,
    });

    installations.isValid = this.documentsValidationService.isInstallationsValid(this.document.installations, this.document);
    installations.isCompleted = installations.isCompleted && installations.isValid;

    this.saveDocumentObject(installations, false);
  }

  onFormDataChanged(): void {
    clearTimeout(this.setTimeoutHandler);
    this.setTimeoutHandler = setTimeout(() => this.saveFormData(), coreConfig.forms.saveTimeoutDelay);
  }

  private saveFormData() {
    this.saveDocumentObject(this.document.installations, false);
  }

  private async saveDocumentObject(installations: DocumentInstallations, formCompletedExplicitly: boolean): Promise<void> {
    if (!this.hasEditPermissions) {
      return;
    }

    const isCompletedBeforeChange = installations.isCompleted;
    installations.isValid = this.documentsValidationService.isInstallationsValid(this.document.installations, this.document);
    installations.isCompleted = installations.isCompleted && installations.isValid;
    const partialDocument = {
      installations: installations,
    } as Document;

    try {
      await this.documentsService.patchDocumentAndSave(this.document, partialDocument);
    } catch (ex) {
      this.showErrorModal('Ett fel uppstod när ändringarna skulle sparas.', ex);
      return;
    }

    if (formCompletedExplicitly || (isCompletedBeforeChange && !this.document.installations.isCompleted)) {
      this.showSectionCompletedSnackBar(DocumentSectionType.installations, this.document.installations.isCompleted);
    }
  }

  private updatePowerSupplyEmergencyLightingEnabled(): void {
    this.isPowerSupplyEmergencyLightingEnabled =
      !this.document ||
      intersection(this.document.installations.markingsEmergencyLightingIds, [
        DocumentInstallations.illuminatedWithEmergencyPowerId,
        DocumentInstallations.separateEmergencyLightingId,
      ]).length > 0;

    if (!this.isPowerSupplyEmergencyLightingEnabled) {
      this.document.installations.powerSupplyEmergencyLightingIds = [];
    }
  }

  private updateExtinguishingEquipmentFormOther(): void {
    const isOtherExtinguishingEquipmentFormSelected =
      !this.document ||
      some(
        this.document.installations.extinguishingEquipmentFormIds,
        (id) => id === DocumentInstallationsExtinguishingEquipmentFormOptionTypes.otherId
      );

    this.isExtinguishingEquipmentFormOtherEnabled = isOtherExtinguishingEquipmentFormSelected;
    if (!isOtherExtinguishingEquipmentFormSelected) {
      this.document.installations.extinguishingEquipmentFormOther = null;
    }
  }

  private updatePremisesFireGasVentilationOther(): void {
    const isOtherPremisesFireGasVentilationSelected =
      !this.document ||
      some(
        this.document.installations.premisesFireGasVentilationIds,
        (id) => id === DocumentInstallations.otherPremisesFireGasVentilationOptionId
      );

    this.isPremisesFireGasVentilationOtherEnabled = isOtherPremisesFireGasVentilationSelected;
    if (!isOtherPremisesFireGasVentilationSelected) {
      this.document.installations.premisesFireGasVentilationOther = null;
    }
  }

  private updateAutomaticFireAlarmPowerSupplyOther(): void {
    this.isAutomaticFireAlarmPowerSupplyOtherEnabled = this.document.installations
      .automaticFireAlarmPowerSupplyIds.some(id => id === DocumentInstallationsAutomaticFireAlarmPowerSupplyOptionTypes.otherId);

    if (!this.isAutomaticFireAlarmPowerSupplyOtherEnabled) {
      this.document.installations.automaticFireAlarmPowerSupplyOther = null;
    }
  }

  private updateAutomaticFireAlarmActivationOther(): void {
    this.isAutomaticFireAlarmActivationOtherEnabled = this.document.installations
      .automaticFireAlarmActivationIds.some(id => id === DocumentInstallationsAutomaticFireAlarmActivationOptionTypes.otherId);

    if (!this.isAutomaticFireAlarmActivationOtherEnabled) {
      this.document.installations.automaticFireAlarmActivationOther = null;
    }
  }

  private updateEvacuationAlarmsActivationOther(): void {
    this.isEvacuationAlarmsActivationOtherEnabled = this.document.installations
      .evacuationAlarmsActivationIds.some(id => id === DocumentInstallationsEvacuationAlarmsActivationOptionTypes.otherId);

    if (!this.isEvacuationAlarmsActivationOtherEnabled) {
      this.document.installations.evacuationAlarmsActivationOther = null;
    }
  }
}
